import { Component, OnInit, ViewChild } from '@angular/core';
import { Title/*, Meta */ } from '@angular/platform-browser';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { AuthService } from '../shared/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { MyProfileService } from '../services/my-profile.service';
import { PartnerPreferenceService } from '../services/partner-preference.service';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { CommonService } from '../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
// unused import
// import * as $ from 'jquery';
// import * as moment from 'moment';

@Component({
  selector: 'app-suggested-matches',
  templateUrl: './suggested-matches.component.html',
  styleUrls: ['./suggested-matches.component.css']
})
export class SuggestedMatchesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  value: number = 18;
  highValue: number = 100;
  options: Options = {
    floor: 18,
    ceil: 90,
  };

  minValue: number = 0;
  maxValue: number = 230;
  options2: Options = {
    floor: 100,
    ceil: 230,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return +value + ' cm';
        case LabelType.High:
          return +value + ' cm';
        default:
          return '';
      }
    },
  };

  hidePageSize = true;

  minValue3: number = 0;
  maxValue3: number = 150;
  options3: Options = {
    floor: 70,
    ceil: 150,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return +value + ' lb';
        case LabelType.High:
          return +value + ' lb';
        default:
          return '';
      }
    },
  };


  minValue4: number = 0;
  maxValue4: number = 100000000000;
  options4: Options = {
    floor: 0,
    ceil: 100000000000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "$" + value;
        case LabelType.High:
          return "$" + value;
        default:
          return '';
      }
    }
  };

  inboxLoader: boolean = false;
  showPagination: boolean;

  TotalRecord: any;
  profileList: any = [];
  contentLoader: boolean = false;
  user_id: string;
  suggestedMatches: any;

  matches: any = [];
  Elitematches: any = [];
  PremiumMatches: any = [];
  partnerPreferenceMatches: any;
  count: any;
  countMatched: any;
  EliteTotalRecord: number = 0;
  PremiumTotalRecord: any;
  currentPage: number = 1;
  pageSize: number = 8;
  lastPage: any;

  sortByMatchesh: any = 'all';
  filterValue: string | null = null;
  sortByUser: any = 'all';
  package_id: any = '';

  constructor(private partnerPreferenceService: PartnerPreferenceService,
    private titleService: Title,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private myProfileService: MyProfileService,
    private authService: AuthService,
    private CommonService: CommonService,
    private toastrService: ToastrService,) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.titleService.setTitle('Suggested Matches | eternalstrings.com');
    this.user_id = this.cookieService.get('user_id');

    this.route.queryParams.subscribe(params => {
      if (params['sort_by']) {
        this.filterValue = decodeURIComponent(params['sort_by']);
        if (params['sort_by'] && (params['sort_by'] == 'suggested' || params['sort_by'] == 'premium' || params['sort_by'] == 'elite')) {
          this.sortByMatchesh = params['sort_by']
        }
      }
    });

    if (this.sortByMatchesh == 'suggested') {
      this.package_id = '';
    } else if (this.sortByMatchesh == 'premium') {
      this.package_id = 2;
    } else if (this.sortByMatchesh == 'elite') {
      this.package_id = 3;
    }

    this.getSuggestedMatchesByPartnerPreference();

  }

  getSuggestedMatchesByPartnerPreference() {
    let data = {
      user_id: this.user_id,
      pageNo: this.currentPage,
      itemPerPage: this.pageSize,
      package_id: this.package_id
    }
    this.inboxLoader = true;
    this.myProfileService.getSuggestedMatchesByPartnerPreference(data).subscribe(
      (result) => {
        if (result.status) {
          // this.currentPage = result.pages.current_page 
          this.TotalRecord = result.pages.total
          this.profileList = result.pages.data;

          this.currentPage = result.pages.current_page;
          this.lastPage = result.pages.last_page;
          if (this.TotalRecord >= this.pageSize) {
            this.showPagination = true
          } else {
            this.showPagination = false
          }
          if (this.profileList.length > 0) {
            // document why this block is empty
          }
        } else {
          this.toastrService.error(result.message);
        }
        this.inboxLoader = false;
      }, (error) => {                              //Error callback
        this.inboxLoader = false;
      })
  }

  public handlePage(e: any) {
    window.scrollTo(0, 0);
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.getSuggestedMatchesByPartnerPreference();
  }

  ViewProfile(id, name) {
    localStorage.setItem("pageFrom", "Suggested Matches");

    let encrypted_id: any = this.CommonService.encryptData(id);
    encrypted_id = encrypted_id.toString().replaceAll('=', 'Ar1t').replaceAll('/', 'FGH').replaceAll('+', 'THD');
    let url = `${environment.baseURL}` + 'detail/' + encrypted_id
    window.open(url, '_blank');
  }

  sortedBy(event: any) {
    const selectedValue = event.target.value;
    this.sortByMatchesh = selectedValue;
    this.currentPage = 1;
    if (this.sortByMatchesh == 'suggested') {
      this.package_id = '';
    } else if (this.sortByMatchesh == 'premium') {
      this.package_id = 2;
    } else if (this.sortByMatchesh == 'elite') {
      this.package_id = 3;
    }
    this.getSuggestedMatchesByPartnerPreference();
  }
}
