import {Component,OnInit,} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {FormBuilder/*,FormGroup,FormControl,Validators,*/} from '@angular/forms';
import { RegisterService } from '../services/register.service';
import { CookieService } from 'ngx-cookie-service';
import { PartnerPreferenceService } from '../services/partner-preference.service';
import { MyProfileService } from '../services/my-profile.service';
import { AuthService } from '../shared/auth.service';
// unused import
// import * as moment from 'moment';
// import * as $ from 'jquery';
import { HttpClient/*, HttpHeaders */} from '@angular/common/http';
import { NotifService } from '../services/notif.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.css'],
})
export class MyDashboardComponent implements OnInit {
  divbox: any;
  aboutYou = true;
  details: any = [];
  age: any;
  year: any;

  user_id: string;
  options = {
    timeOut: 0,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    preventDuplicates: true,
  };
  createdBy: string;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private registerService: RegisterService,
    private cookieService: CookieService,
    private partnerPreferenceService: PartnerPreferenceService,
    private myProfileService: MyProfileService,
    private authService: AuthService,
    private http: HttpClient,
    private notifService: NotifService,
    private titleService: Title,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('My Dashboard | eternalstrings.com');
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
    this.user_id = this.cookieService.get('user_id');
    let data = {
      user_id: this.user_id,
    };
    this.registerService.getUser(data).subscribe((res: any) => {
      if (res?.userDetails?.firstName && res?.userDetails?.lastName
      ) {
        this.createdBy = res.userDetails.firstName + ' ' + res.userDetails.lastName;
      }
    });
  }

  loader() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
  }

}
