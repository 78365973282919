import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-honeymoon-destinations',
  templateUrl: './honeymoon-destinations.component.html',
  styleUrls: ['./honeymoon-destinations.component.css']
})
export class HoneymoonDestinationsComponent implements OnInit {

  carouselItems: any = [
    {
      title: 'Montreal: A Honeymoon Haven',
      location: 'Montreal',
      image: 'assets/images/honeymoon/montreal.jpg',
      story: "Montreal, Canada's charming European-style city, is a top honeymoon destination. Old Montreal offers picturesque cobblestone streets, horse-drawn carriages, and beautiful 17th and 18th-century architecture for romantic strolls. The city's renowned French-Canadian cuisine can be enjoyed at various romantic restaurants, bistros, and rooftop terraces.<br><br>Cultural experiences are also in abundance with museums, theaters, and art galleries to explore. Outdoor activities such as cycling and boat rides on the St. Lawrence River also offer scenic views for honeymooners. With a mix of romantic ambiance, delicious food, and cultural and outdoor activities, Montreal is a perfect destination for a romantic and memorable honeymoon."
    },
    {
      title: 'Switzerland: A Honeymoon Destination of Enchantment',
      location: 'Switzerland',
      image: 'assets/images/honeymoon/switzerland.jpg',
      story: "Switzerland, with its picturesque alpine landscapes and charming towns, is a top honeymoon destination for newlyweds. The Matterhorn, a famous peak that sits on the border of Switzerland and Italy, offers breathtaking views and romantic hiking or cable car rides. Lucerne, with its charming medieval architecture and serene lakeside setting, is perfect for romantic boat rides and strolls. St. Moritz, a luxurious ski resort, offers high-end shopping, dining, and spa experiences. The country also offers plenty of outdoor activities such as skiing, hiking, and biking for an adventurous honeymoon. Switzerland offers a perfect blend of romance and adventure for a honeymoon to remember."
    },
    {
      title: 'Paris: A Honeymoon Destination of Romance and Culture',
      location: 'Paris',
      image: 'assets/images/honeymoon/paris.jpg',
      story: "Paris, the City of Love, is an ideal destination for newlyweds seeking a romantic and cultural honeymoon experience. The city offers iconic landmarks, art, delicious food, and picturesque parks and gardens that will make your honeymoon unforgettable.<br>One of the must-see landmarks in Paris is the Eiffel Tower. The tower offers a stunning view of the city and is the perfect spot for a romantic stroll or elevator ride to the top for a panoramic view. Another popular destination is the Louvre Museum, home to famous masterpieces like the Mona Lisa. Take a stroll through the galleries and admire the art together.<br>Take a romantic boat ride on the Seine River and enjoy the beautiful scenery of the city, from the stunning architecture to the charming bridges. Paris is also known for its delicious food and wine, treat your partner to a romantic dinner at one of the city's many fine-dining restaurants or bistros.<br>For a more intimate and romantic experience, take a stroll through the city's many parks and gardens. The Luxembourg Gardens is a popular spot, known for its beautiful gardens, ponds, and statues. Take a picnic and enjoy the peaceful ambiance of the gardens."
    },
    {
      title: 'The Maldives: A Tropical Honeymoon Paradise',
      location: 'Maldives',
      image: 'assets/images/honeymoon/maldives.jpg',
      story: "The Maldives, a tropical paradise located in the Indian Ocean, is a top honeymoon destination for couples. Known for its crystal-clear waters, white sandy beaches, and stunning coral reefs, the Maldives offers a romantic and secluded getaway.<br>One of the most popular activities in the Maldives is to explore the stunning coral reefs. Take a romantic snorkeling or diving excursion to see the vibrant marine life and colorful coral. The Maldives is also home to many luxury resorts that offer overwater bungalows, providing a unique and romantic experience. Enjoy an intimate dinner on your own private deck, with the sound of the waves in the background.<br>Take a romantic sunset cruise and enjoy the stunning views of the Indian Ocean. The Maldives is also known for its beautiful beaches, take a romantic walk on the white sandy beaches, and enjoy the sound of the waves. The Maldives also offers a variety of water sports, such as paddleboarding, windsurfing, and kite-surfing. Take a romantic lesson together and enjoy the beautiful scenery while trying something new.<br>In conclusion, the Maldives is a tropical paradise and a perfect honeymoon destination for couples. The Maldives offers crystal-clear waters, white sandy beaches, stunning coral reefs, and luxury resorts, all of which make for a romantic and secluded getaway. Whether you're looking for a relaxing holiday, or an action-packed adventure, the Maldives has something for every type of honeymooner."
    },
    {
      title: 'Goa: A Romantic Escape to Beaches and Culture',
      location: 'Goa',
      image: 'assets/images/honeymoon/goa.jpg',
      story: "Goa, located on the western coast of India, is a top honeymoon destination for couples. Known for its beautiful beaches, delicious food, and unique culture, Goa offers a perfect blend of relaxation and adventure for a romantic getaway.<br>One of the main attractions in Goa is its beaches. Take a romantic walk on the soft white sandy beaches, and enjoy the sound of the waves. The beaches offer a variety of activities such as sunbathing, swimming, and watersports like parasailing, jet skiing, and banana boat rides.<br>The culture of Goa is a unique blend of Indian and Portuguese influences. The city offers a rich history, and you can explore the colonial architecture and visit the many temples and churches in the area. Take a romantic stroll through the charming streets, visit the local markets and try the delicious seafood.<br>Goa is also known for its nightlife, with a variety of clubs, bars, and beach shacks to choose from. Take a romantic dance together under the stars or enjoy a romantic dinner at one of the many beach shacks."
    }
  ];
  honeyDest: number = 0;

  constructor() { }

  ngOnInit(): void {
    // document why this method 'ngOnInit' is empty
  }

  openModal(i: number) {
    this.honeyDest = i;
  }

}
