import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { MyProfileService } from '../services/my-profile.service';
import { RegisterNxtService } from '../services/register-nxt.service';
import { NotifService } from '../services/notif.service';
import { Router } from '@angular/router';
import { ImageCroppedEvent/*, LoadedImage*/, Dimensions, ImageTransform } from 'ngx-image-cropper';
// unused import
// import { base64ToFile } from '../Directives/Image-cropper/utils/blob.utils';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-profile-page1',
  templateUrl: './profile-page1.component.html',
  styleUrls: ['./profile-page1.component.css']
})
export class ProfilePage1Component implements OnInit {
  imageUploadForm: FormGroup;
  jsonObj: any;
  user_id: any;
  imageSrc = '/assets/images/temp-profile-picture.png';

  is_uploaded = false;
  totalMediaLength: any;
  totalMediaSize: any;
  imgURL: any;
  fileUpload: any;
  fileUploadCount: any;
  pasteEvnetStatus: boolean;

  options = {
    timeOut: 0,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    preventDuplicates: true,
  };

  // image code 
  imageChangedEvent: any = '';
  croppedImage: any = '/assets/images/temp-profile-picture.png';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  // before sonar
  // cimg: Event | any;
  cimg: any;
  imageOriginalName: string;
  imgSelected: boolean = false;
  Uploaded: boolean = false;
  //aspectRatio :number= 1/1

  constructor(public fb: FormBuilder,
    private http: HttpClient,
    private cookieService: CookieService,
    private myprofileService: MyProfileService,
    private registerNxtservice: RegisterNxtService,
    private notifService: NotifService,
    private router: Router,
    private toastrService: ToastrService,
    private commonService: CommonService,
  ) {
    this.imageUploadForm = this.fb.group({
      image: null,
    })
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.user_id = this.cookieService.get('user_id');
    this.getUserDetails();
  }

  getUserDetails() {
    this.registerNxtservice.getUserDetails({ user_id: this.user_id })
      .subscribe((data: any) => {
        if (data?.userDetails?.profile_photo) {
          this.is_uploaded = true;
          this.imgSelected = true;
          this.croppedImage = data.userDetails.profile_photo;
        }
      })
  }

  uploadFile(event) {
    this.imgSelected = true;
    this.imageOriginalName = event.target.files[0].name;
    this.fileType = event.target.files[0].type;
    this.imageChangedEvent = event;
    event = this.cimg;
    this.is_uploaded = true;
    if (event?.target && (event.target as HTMLInputElement).files) {
      const file_array = (event.target as HTMLInputElement).files;
      if ((event.target as HTMLInputElement).files!.length == 1) {
        let fileDetails = file_array![0];
        this.totalMediaSize = fileDetails.size;
        if (this.totalMediaSize <= '5000000') {
          if (fileDetails.type == 'image/png' || fileDetails.type == 'image/jpeg' || fileDetails.type == 'image/webp' || fileDetails.type == 'image/x-icon' || fileDetails.type == 'image/bmp' || fileDetails.type == 'image/jpg') {
            let reader = new FileReader();
            reader.readAsDataURL(fileDetails);
            reader.onload = (evn: any) => {
              this.croppedImage = evn.target.result;
            }
            this.fileUpload = fileDetails;
          } else {
            this.is_uploaded = false;
            this.toastrService.error('Error!', 'Only jpg, jpeg, png, bmp, gif files are allowed to upload');
          }
        } else {
          this.is_uploaded = false;
          this.toastrService.error('Error!', 'Total File size should be smaller than 5 MB.!');
          this.notifService.error('Error!', "File can't be uploaded. Total File size should be smaller than 5 MB.", this.options);
        }
      } else {
        this.is_uploaded = false;
        this.toastrService.error('Error!', 'Unable to upload more than one file at a time!');
        this.notifService.error('Error!', "Unable to upload more than one file at a time.", this.options);
      }
    } else {
      this.is_uploaded = false;
      this.notifService.error('Error!', "File can't be uploaded. Only Image is allowed!", this.options);
    }
  }

  uploadFile1(event: Event) {

    this.is_uploaded = true;
    if (event?.target && (event.target as HTMLInputElement).files) {
      const file_array = (event.target as HTMLInputElement).files;
      this.totalMediaLength = file_array!.length;
      if (this.totalMediaLength == 1) {
        let x = 0;
        let fileDetails = file_array![0];

        this.totalMediaSize = fileDetails.size;

        if (this.totalMediaSize <= '52428800') {
          if (fileDetails.type == 'image/png' || fileDetails.type == 'image/jpeg' || fileDetails.type == 'image/webp' || fileDetails.type == 'image/x-icon' || fileDetails.type == 'image/bmp' || fileDetails.type == 'image/jpg') {
            let $this_func = this;
            let getCustomerSyncFlag = function (callback) {
              $this_func.setupReader(fileDetails);
              let SyncFlag = 1;
              callback(null, SyncFlag);
            }

            getCustomerSyncFlag(function (err, SyncFlag) {
              x = SyncFlag;
            });

          } else {
            this.totalMediaSize -= fileDetails.size;
            this.totalMediaLength = this.totalMediaLength - 1;
            if (fileDetails.type.includes("image/")) {
              // unused code
              // this.toastr.error("Images can't be uploaded. Only jpg, jpeg, png, webp, ico, bmp and gif files are allowed!", 'Error!');
            } else {
              console.log()
               // unused code
              // this.toastr.error("File can't be uploaded. Only Images are allowed!", 'Error!');
            }
          }
        } else {
          this.totalMediaSize -= fileDetails.size;
           // unused code
          // this.toastr.error("File can't be uploaded. Total File size should be smaller than 5 MB.", 'Error!');
        }
      } else {
        this.totalMediaLength -= file_array!.length;
         // unused code
        // this.toastr.error("Unable to upload more than one file at a time.", 'Error!');
      }
    } else {
       // unused code
      // this.toastr.error("File can't be uploaded. Only Image is allowed!", 'Error!');
    }
  }

  //Setup Reader method for converting img into base64 for preview(D)
  setupReader(file) {
    try {
      let fileReader = new FileReader();
      let $this = this;
      if (file.type.match('image')) {
        fileReader.onload = function () {
          $this.imgURL = { url: fileReader.result, size: file.size, type: 'image', name: file.name };
          $this.fileUpload = file;
          // // console.log('file',file);
          $this.fileUploadCount = $this.fileUpload.length;
          $this.pasteEvnetStatus = true;
        };
        fileReader.readAsDataURL(file);
      }

    } catch (error) {
       // unused code
      //// console.log(error);
    }
  }

  submitForm() {
    const formData: any = new FormData();
    formData.append("image", this.croppedImage);
    formData.append("user_id", this.user_id);
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };
    console.log(httpOptions);
    this.myprofileService.uploadImage(formData).subscribe(
      (result) => {
        if (result.status) {
          this.notifService.success('Image Uploaded', result.message, this.options);
          this.registerNxtservice.getUserDetails({ user_id: this.user_id })
            .subscribe((data: any) => {
              if (data?.userDetails.profile_photo) {
                this.is_uploaded = true;
                this.Uploaded = true;
                this.croppedImage = data.userDetails.profile_photo;
                this.commonService.updateProfilePhoto(this.croppedImage);
                this.toastrService.success('Successfully!', 'Image Uploaded!');

              }
            }) 
        } else {
          this.toastrService.error('Error!', 'Image not Uploaded!');
          this.notifService.error('Image not Uploaded', result.message, this.options);
        }
      })
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  fileType: any;
  imageCropped(event: ImageCroppedEvent) {
    let tempImage: any = event.base64;

    fetch(tempImage)
      .then(res => res.blob())
      .then(blob => {
        this.croppedImage = new File([blob], this.imageOriginalName, { type: this.fileType })
      })
  }

  imageLoaded() {
    this.showCropper = true;
    // // console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    //  // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    //  // console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  next() {
    // before sonar
    // if (this.imgSelected == true) {
    //   if (this.is_uploaded == true) {
        if (this.imgSelected) {
          if (this.is_uploaded) {
        this.router.navigate(['PartnerPreferences']);
      }
    }
  }


}



