import { Component, OnInit, ViewChild,/* ViewEncapsulation, HostListener*/ } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm, } from '@angular/forms';
import { RegisterService } from '../services/register.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../shared/auth.service';
import { Title/*, Meta */ } from '@angular/platform-browser';
// unused import
// import { isNull } from '@angular/compiler/src/output/output_ast';
// import { symbolName } from 'typescript';
import { NotificationsService } from 'angular2-notifications';
import { TokenService } from '../shared/token.service';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from '../admin/adminServices/admin.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})

export class RegisterComponent implements OnInit {
  [x: string]: any;
  @ViewChild('registerInNgForm') registerInNgForm: NgForm;

  errorMessage: string;
  errors: any;
  fname: any;
  submitted = false;
  havePermission: boolean = true;
  profileIsVerified: boolean = false;

  phoneCode: any = 0;
  errms: any;
  verifyMsg: any;
  registerInForm = new FormGroup({
    user_id: new FormControl(''),
    profile_for: new FormControl('', Validators.required),
    first_name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25), Validators.pattern('^[a-zA-Z ]+$')]),
    last_name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25), Validators.pattern('^[a-zA-Z ]+$')]),
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
    country_code: new FormControl('', Validators.required),
    mobile_no: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]),
    gender: new FormControl('', Validators.required),
    languages: new FormControl('', Validators.required),
    religion: new FormControl('', Validators.required),
    caste: new FormControl(''),
    country_number_code: new FormControl(''),
    have_permission: new FormControl('')
  })

  isEditable = true;
  dropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings = {};
  disableButton: any = false;
  minNumberLength: boolean = false;
  maxNumberLength: boolean = false;

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
  });

  user: any;
  registerForm: any;
  sy: string;
  jsonObj: any;
  user_logged_in: boolean = false;
  otherLanguage: boolean = false;
  otherReligion: boolean = false;
  cntryId: any;
  countries: any;
  states: any;
  country: any;
  relation: any;
  relation_name: any;
  profile_for: boolean = true;
  detail: any;
  // name: any | string;
  name: any;
  verify: boolean = false;
  verifyProfile: boolean = false;
  isProfileVerified: boolean = false;
  disable_m: boolean;
  disable_f: boolean;
  disable_g: boolean;

  sendVerificationButton = "Send Verification";
  readonly notifyOptions = {
    timeOut: 5000
  };
  isLoggedIn: any;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private registerService: RegisterService,
    private cookieService: CookieService,
    private authService: AuthService,
    private titleService: Title,
    private toastrService: ToastrService,
    private notificationsService: NotificationsService,
    public token: TokenService,
    private adminService: AdminService,
    private scroller: ViewportScroller,
  ) {
    this.isLoggedIn = this.token.isLoggedIn();
    if (this.isLoggedIn) {
      // unused code
      //this.router.navigate(['matches']);
    }
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.titleService.setTitle('Sign-Up | eternalstrings.com');

    this.authService.getCountryList().subscribe(
      (result) => {
        console.log('registerInForm after countries ::', this.registerInForm)
        this.countries = result.data;
        // before sonar
        // if (this.countries && this.countries[0] && this.countries[0].id) {
        if (this.countries?.[0]?.id) {
          this.phoneCode = this.countries[0].id;
          this.registerInForm.patchValue({ 'country_code': this.countries[0].id });
          this.registerInForm.patchValue({ 'country_number_code': this.countries[0].country_code });
        }
        const aa = this.registerForm;
        console.log('registerInForm after countries', aa)
        let dd = this.cookieService.get('user_id');
        this.f.user_id.setValue(dd);
        let data = {
          user_id: dd
        }
        this.registerService.getUserDetails(data)
          .subscribe((x: any) => {
            if (x.userDetails != 'user data not found') {
              if (x?.userDetails) {
                console.log('sdfsdfsd', x.userDetails)
                this.detail = x.userDetails
                let register_id = this.details?.register_done;
                localStorage.setItem('register_id', register_id);
                if (this.detail.register_done <= 0) {
                  this.f.first_name.setValue(this.detail.first_name);
                  this.f.last_name.setValue(this.detail.last_name);
                } else {
                  this.f.profile_for.setValue(this.detail.profile_for);

                  this.f.gender.setValue(this.detail.gender);
                  if (this.detail.religion != '') {
                    // console.log('this')
                    if (this.detail.religion == "Buddhist") {
                      this.f.religion.setValue("Buddhist")
                    } else if (this.detail.religion == "Christian") {
                      this.f.religion.setValue("Christian")
                    } else if (this.detail.religion == "Hindu") {
                      this.f.religion.setValue("Hindu")
                    } else if (this.detail.religion == "Jain") {
                      this.f.religion.setValue("Jain")
                    } else if (this.detail.religion == "Muslim") {
                      this.f.religion.setValue("Muslim")
                    } else if (this.detail.religion == "Parsi") {
                      this.f.religion.setValue("Parsi")
                    } else if (this.detail.religion == "Sikh") {
                      this.f.religion.setValue("Sikh")
                    } else if (this.detail.religion == "NoReligion" || this.detail.religion == "No Religion") {
                      this.f.religion.setValue("No Religion")
                    } else if (this.detail.religion == "AnyReligion") {
                      this.f.religion.setValue("AnyReligion")
                    } else {
                      this.otherReligion = true;
                      this.f.religion.setValue(this.detail.religion)
                    }
                  }

                  // language 
                  if (this.detail.languages != '') {
                    if (this.detail.languages == "Bengali") {
                      this.f.languages.setValue("Bengali")
                    } else if (this.detail.languages == "English") {
                      this.f.languages.setValue("English")
                    } else if (this.detail.languages == "Gujarati") {
                      this.f.languages.setValue("Gujarati")
                    } else if (this.detail.languages == "Hindi") {
                      this.f.languages.setValue("Hindi")
                    } else if (this.detail.languages == "Marathi") {
                      this.f.languages.setValue("Marathi")
                    } else if (this.detail.languages == "Punjabi") {
                      this.f.languages.setValue("Punjabi")
                    } else if (this.detail.languages == "Telugu") {
                      this.f.languages.setValue("Telugu")
                    } else {
                      this.otherLanguage = true;
                      this.f.languages.setValue(this.detail.languages)
                    }
                  }
                  if (this.detail.profile_for != "Self") {
                    console.log('not self')
                    this.profile_for = false;
                    this.f.have_permission.setValue(this.detail.have_permission);
                    if (this.detail.have_permission == true) {
                      this.profileIsVerified = true;

                      this.f.first_name.setValue(this.detail.first_name);
                      this.f.last_name.setValue(this.detail.last_name);
                      this.f.email.setValue(this.detail.email);
                      this.f.country_code.setValue(this.detail.country_code);
                      this.f.mobile_no.setValue(this.detail.mobile_no);
                      this.countries.forEach((country) => {
                        if (this.detail.country_code == country.country_code) {
                          this.phoneCode = country.id;
                        }
                      });
                    }
                    console.log(this.f.country_number_code.value)
                  } else {
                    console.log('else ', this.detail)

                    if (this.detail) {
                      this.f.have_permission.setValue(this.detail.have_permission);
                      this.f.first_name.setValue(this.detail.first_name);
                      this.f.last_name.setValue(this.detail.last_name);
                      this.f.email.setValue(this.detail.email);
                      this.f.country_code.setValue(this.detail.country_code);
                      this.f.mobile_no.setValue(this.detail.mobile_no);
                      this.countries.forEach((country) => {
                        if (this.detail.country_code == country.country_code) {
                          this.phoneCode = country.id;
                        }
                      });
                    }
                  }


                  this.f.caste.setValue(this.detail.caste);
                  if (this.detail.profile_for == "Brother" || this.detail.profile_for == "Son") {
                    this.f.gender.setValue("Male");
                    this.disable_m = false;
                    this.disable_f = true;
                    this.disable_g = true;
                  } else if (this.detail.profile_for == "Daughter" || this.detail.profile_for == "Sister") {
                    this.disable_m = true;
                    this.disable_f = false;
                    this.disable_g = true;
                    this.f.gender.setValue("Female");
                  } else {
                    this.disable_g = false;
                    this.f.gender.setValue(this.detail.gender);
                  }
                  if (this.detail.country_number_code == null || this.detail.country_number_code == '') {
                    this.f.country_number_code.setValue(this.detail.country_number_code);
                  }
                }
              }
            }
            console.log('getUserDetails : form data', this.f)
          });
      });
  }

  OnchangeCountryCode(event) {
    console.log(event);
    this.registerInForm.patchValue({ 'countryCode': event.id });
    this.registerInForm.patchValue({ 'countryNumberCode': event.country_code });
    this.registerInForm.patchValue({ 'country_number_code': event.country_code });
  }

  permission() {
    if (this.registerInForm.value.have_permission == true) {
      this.profileIsVerified = true;
    } else {
      this.profileIsVerified = false;
      this.f.first_name.setValue("");
      this.f.last_name.setValue("");
      this.f.email.setValue("");
      this.f.country_code.setValue("");
      this.f.mobile_no.setValue("");
    }
  }

  getStateList(data) {
    this.authService.getStateList(data).subscribe(
      (result) => {
        this.states = result.data;
        // before sonar
        // if (result && result.data && result.data[0] && result.data[0]['country_id']) {
        if (result?.data[0]['country_id']) {
          this.country = result.data[0]['country_id']
        }
      },
      (error) => {
      },
      () => {
        // unused code
        // this.countries = result;
      }
    );
  }

  profileFor(event) {
    let eventValue = "";
    // before sonar
    // if (event && event.target && event.target.value) {
    if (event?.target.value) {
      eventValue = event.target.value
    } else {
      eventValue = event;
    }
    if (eventValue == "Self") {
      this.profile_for = true;
      this.disable_g = false;
      this.f.gender.setValue("");
      this.relation = ""
      this.relation_name = ""
      let dd = this.cookieService.get('user_id');
      this.f.user_id.setValue(dd);
      let dataa = {
        user_id: dd
      }
      this.registerService.getUser(dataa)
        .subscribe((res: any) => {
          if (res?.userDetails) {
            let SelfValue = res.userDetails
            this.f.first_name.setValue(SelfValue.firstName);
            this.f.last_name.setValue(SelfValue.lastName);
            this.cntryId = SelfValue.countryCode;
            this.f.email.setValue(SelfValue.email);
            this.f.country_code.setValue(SelfValue.countryCode);
            this.f.country_number_code.setValue(SelfValue.countryNumberCode);
            this.f.mobile_no.setValue(SelfValue.mobileNo);
            this.phoneCode = SelfValue.countryCode;
          }
        });
      if (this.detail) {
        this.cntryId = this.detail.living_in_country;
      }
      this.verify = false;
      this.verifyProfile = false;
    } else {
      this.profile_for = false;
      this.profileIsVerified = false;
      this.relation_name = eventValue
      this.relation = eventValue + "'s";
      if (eventValue == "Brother" || eventValue == "Son") {
        this.f.gender.setValue("Male");

        this.disable_m = false;
        this.disable_f = true;
        this.disable_g = true;
      } else if (eventValue == "Daughter" || eventValue == "Sister") {
        this.disable_m = true;
        this.disable_f = false;
        this.disable_g = true;
        this.f.gender.setValue("Female");
      } else {
        this.disable_g = false;
        this.f.gender.setValue("");
      }

      if (this.detail?.register_done) {
        if (this.detail.register_done >= 1) {
          if (this.detail.profile_for == eventValue) {
            this.f.first_name.setValue(this.detail.first_name);
            this.f.last_name.setValue(this.detail.last_name);
            this.f.email.setValue(this.detail.email);
            this.f.country_code.setValue(this.detail.country_code);
            this.f.mobile_no.setValue(this.detail.mobile_no);
            this.cntryId = this.detail.living_in_country;
            this.f.have_permission.setValue(this.detail.have_permission);
            this.countries.forEach((country) => {
              if (this.detail.country_code == country.id) {
                this.phoneCode = country.id;
              }
            });
          } else {
            this.profileIsVerified = false;
            this.f.first_name.setValue("");
            this.f.last_name.setValue("");
            this.f.email.setValue("");
            this.f.country_code.setValue(38);
            this.f.country_number_code.setValue("+1");
            this.f.mobile_no.setValue("");
            this.f.have_permission.setValue("");
            this.phoneCode = 38;
          }
        }
      } else {
        this.profileIsVerified = false;
        this.f.first_name.setValue("");
        this.f.last_name.setValue("");
        this.f.email.setValue("");
        this.f.country_code.setValue(38);
        this.f.country_number_code.setValue("+1");
        this.f.mobile_no.setValue("");
        this.phoneCode = 38;

      }
      console.log('this.f', this.f)
      this.verify = true;
      this.verifyProfile = true;
    }
  }

  get f() {
    return this.registerInForm.controls;
  }

  checkLanguage() {
    if (this.registerInForm.value.languages == "OtherLanguages") {
      this.f.languages.setValue("");
      this.otherLanguage = true;
    } else {
      this.otherLanguage = false;
    }
  }

  checkReligion() {
    if (this.registerInForm.value.religion == "Other") {
      this.f.religion.setValue("");
      this.otherReligion = true;
    } else {
      this.otherReligion = false;
    }
  }

  register() {
    this.submitted = true;
    if (this.registerInForm.invalid) {
      this.scrollToFirstInvalidControl();
      return;
    }
    if (this.registerInForm.value.profile_for == "Self") {
      const frmdata = {
        user_id: this.registerInForm.value.user_id,
        profile_for: this.registerInForm.value.profile_for,
        first_name: this.registerInForm.value.first_name.trim(),
        last_name: this.registerInForm.value.last_name.trim(),
        email: this.registerInForm.value.email.trim(),
        country_code: this.registerInForm.value.country_code,
        mobile_no: this.registerInForm.value.mobile_no.trim(),
        gender: this.registerInForm.value.gender,
        languages: this.registerInForm.value.languages.trim(),
        religion: this.registerInForm.value.religion.trim(),
        country_number_code: this.registerInForm.value.country_number_code,
        caste: this.registerInForm.value.caste ? this.registerInForm.value.caste.trim() : '',
      }
      console.log('frmdata before submit', frmdata)
      this.errors = false;

      this.registerService.registerOne(frmdata).subscribe(
        (result) => {
          if (result?.status) {
            this.disableButton = true;
            this.registerInForm.reset();
            this.router.navigate(['registerNxt']);
          } else {
            this.toastrService.error(result.message);
          }
        },
        (error) => {
          this.errors = error.error;
          this.disableButton = false;
          this.toastrService.error(error.message);
        },
      );
    } else {
      const frmdata = {
        user_id: this.registerInForm.value.user_id,
        have_permission: this.registerInForm.value.have_permission,
        profile_for: this.registerInForm.value.profile_for.trim(),
        first_name: this.registerInForm.value.first_name.trim(),
        last_name: this.registerInForm.value.last_name.trim(),
        email: this.registerInForm.value.email.trim(),
        country_code: this.registerInForm.value.country_code,
        mobile_no: this.registerInForm.value.mobile_no.trim(),
        gender: this.registerInForm.value.gender,
        languages: this.registerInForm.value.languages.trim(),
        religion: this.registerInForm.value.religion.trim(),
        country_number_code: this.registerInForm.value.country_number_code,
        caste: this.registerInForm.value.caste ? this.registerInForm.value.caste.trim() : '',
      }
      console.log('frmdata before submit', frmdata)

      if (frmdata.have_permission == true) {
        this.havePermission = true;
        this.registerService.registerOne(frmdata).subscribe(
          (result) => {
            if (result?.status) {
              this.disableButton = true;
              this.registerInForm.reset();
              this.router.navigate(['registerNxt']);
            } else {
              this.toastrService.error(result.message);
            }
            this.disableButton = false;
          },
          (error) => {
            this.errors = error.error;
            this.disableButton = false;
            this.toastrService.error(error.message);
          },
          () => {
          }
        );
      } else {
        this.havePermission = false;
        this.disableButton = false;
      }
    }
  }

  keyPressNumeric(event) {
    const pattern = /\D/g;
    if (this.registerInForm.value.mobile_no.length < 6) {
      this.minNumberLength = true;
      this.maxNumberLength = false;
    } else if (this.registerInForm.value.mobile_no.length > 15) {
      this.minNumberLength = false;
      this.maxNumberLength = true;
    } else {
      this.minNumberLength = false;
      this.maxNumberLength = false;
    }
    if (!pattern.test(event.target.value) && (this.registerInForm.value.mobile_no.length > 6 && this.registerInForm.value.mobile_no.length < 16)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumeric(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    console.log(newText, 'newText');
  }

  private scrollToFirstInvalidControl() {
    let form = document.getElementById('register'); // <-- your formID
    let firstInvalidControl = form!.getElementsByClassName('ng-invalid')[0];
    firstInvalidControl.scrollIntoView();
    (firstInvalidControl as HTMLElement).focus();

    if (this.registerInForm.value.languages == "" && this.registerInForm.controls['gender'].valid && this.registerInForm.controls['mobile_no'].valid && this.registerInForm.controls['country_code'].valid
      && this.registerInForm.controls['email'].valid && this.registerInForm.controls['last_name'].valid && this.registerInForm.controls['first_name'].valid && this.registerInForm.controls['profile_for'].valid) {
      this.scroller.scrollToAnchor("scrollOnLanguages");
      console.log("scrollOnLanguages");
      return;
    }
  }
  // .......content managment code start....
  //before sonarqube
  // NameSpaceNotAllowed(event: any) {
  //   var RegEx = new RegExp(/^([a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"])*$/g);
  //   var str = event.target.value
  //   if (RegEx.test(str)) {
  //     return event.target.value
  //   } else {
  //     var nameString = str.replace(/\s+/g, ' ');
  //     var nameString = str.replace(/[^a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"]+/g, ' ');
  //     event.target.value = nameString.replace(/^\s+/g, "");
  //   }
  // }

  //after sonarqube
  NameSpaceNotAllowed(event: any) {
    let RegEx = new RegExp(/^([a-zA-Z\d!@#$&()\-.+,%*=~[\]{}\\<>?'":])*$/g);
    let str = event.target.value;

    if (RegEx.test(str)) {
      return event.target.value;
    } else {
      let nameString = str.replace(/\s+/g, ' ');
      nameString = nameString.replace(/[^a-zA-Z\d!@#$&()\-.+,%*=\|~[\]{}\\<>\?'";]+/g, ' ');
      event.target.value = nameString.replace(/^\s+/g, "");
    }
  }

  omit_special_char(event) {
    let k;
    k = event.charCode;  //k = event.keyCode; (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  pasteText(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedNumber = clipboardData.getData('text');
    let newNumber = pastedNumber.replace(/[^a-zA-Z]/g, "");
    this.registerInForm.patchValue({ 'first_name': newNumber });
    setTimeout(() => {
      this.f.first_name.setValue(newNumber);
    }, 100);
  }

  pasteText1(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedNumber = clipboardData.getData('text');
    let newNumber = pastedNumber.replace(/[^a-zA-Z]/g, "");
    this.registerInForm.patchValue({ 'last_name': newNumber });
    setTimeout(() => {
      this.f.last_name.setValue(newNumber);
    }, 100);
  }

  // .......content managment code end....
}
