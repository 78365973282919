<div class="w-100 bg-maroon" *ngIf="!isLoggedIn">
  <app-header></app-header>
</div>
<div class="page-header">
  <div class="container">
    <div class="row">
      <div class="col-12 ">
        <div class="page-caption">
        </div>
      </div>
    </div>
  </div>
</div>

<section class="container my-4 px-3">

  <div class="card p-4 rounded-3 min_content_height_202 mt-120">
    <div class="my-3">
      <h3 class="text-center fw-bold text-danger mb-5">PRIVACY POLICY</h3>
    </div>

    <h4 class="fw-bold mb-2">PRIVACY NOTICE</h4>
    <p class="fw-bold  ">Last updated July 03, 2024</p>

    <p class="lh-lg font-9-rem mt-4">Eternal Strings is a matrimony platform providing matchmaking services (“Service”).
      In order to do provide the services, you (User) required to submit certain personal information which is displayed
      on the Site on behalf of you to find a prospective match. Thus, you provide your consent to collect, process, and
      share of your personal information with other user/members in order to provide the service.</p>

    <p class="lh-lg font-9-rem mt-4">This policy discloses the privacy practices of Jub We Met Inc with the brand name
      Eternal Strings. (“Eternal Strings, “us”, “our”, or “we”) regarding how we collect, store, disclose, and use your
      information which may be directly submitted voluntarily by you, Not directly submitted by you and/or Information
      procured from others.</p>

    <p class="lh-lg font-9-rem mt-4">This includes usage by Eternal Strings, our subsidiaries and corporate affiliates,
      through our services including but not limiting to our Websites and Applications (as defined in the Terms and
      Condition of Service or Agreement).</p>

    <p class="lh-lg font-9-rem mb-0">
      YOUR USE OF OUR SERVICES IS SUBJECT TO THIS PRIVACY POLICY AND THE APPLICABLE TERMS AND CONDITIONS OF SERVICE. BY
      USING OUR SERVICES, YOU ARE ACCEPTING THE PRACTICES SET OUT IN THIS PRIVACY POLICY AND THE APPLICABLE TERMS AND
      CONDITIONS OF THIS SERVICE.
    </p>

    <p class="lh-lg font-9-rem mb-0">This privacy notice applies to information collected by our Website. You will be
      notified:
    </p>
    <ul type="square" class="lh-lg font-9-rem ">
      <li>When your profile information has been shared with another user for the purpose of selecting your match.
      </li>
      <li>What choices are available to you regarding the use of your information.</li>
      <li>The security procedures in place to protect your information from misuse.</li>
      <li>How you can correct any inaccuracies in the information.</li>
    </ul>

    <p class="lh-lg font-9-rem mb-0"> By using our services you hereby agree to this Privacy Policy and the Applicable
      Terms and Conditions. We reserve the right to modify our Privacy Policy at any time. We will post any modification
      or changes on our website and on any other places we deem appropriate. Please review this policy periodically.</p>

    <ol class="lh-lg font-9-rem " type="1">
      <li> <a class="default_link" href="Privacy/#heading1">Age Restriction</a></li>
      <li> <a class="default_link" href="Privacy/#heading2"> Personal Information- How We Use It</a></li>
      <li> <a class="default_link" href="Privacy/#heading3">Personal Information- How We Collect and Safeguard It</a>
      </li>
      <li> <a class="default_link" href="Privacy/#heading4"> Match Notifications</a></li>
      <li> <a class="default_link" href="Privacy/#heading5">Disclosure of your Personal Information</a></li>
      <li> <a class="default_link" href="Privacy/#heading6">Security and Personal Information Storage</a></li>
      <li> <a class="default_link" href="Privacy/#heading7">“Do Not Track” requests</a></li>
      <li> <a class="default_link" href="Privacy/#heading8">Access From Other Sites</a></li>
      <li> <a class="default_link" href="Privacy/#heading9">Modifying or Deleting Your Personal Information</a>
      </li>
      <li> <a class="default_link" href="Privacy/#heading10">Dispute Resolutions</a></li>

      <li> <a class="default_link" href="Privacy/#heading12">Contact US</a></li>
      <li> <a class="default_link" href="Privacy/#heading13">Effective Date</a></li>
    </ol>



    <div class="w-100" id="heading1"></div>
    <h4 class=" mb-2 mt-5 fw-bold"> 1. Age Restriction</h4>
    <p class="lh-lg font-9-rem mt-2">You must be above the age of majority and of a marriageable age as per your
      country’s jurisdiction to use our Services. We will purge any information received from users we believe to be
      underage. Please notify us of any person you believe to have misrepresented their eligibility to us as specified
      below.</p>


    <div class="w-100" id="heading2"></div>
    <h4 class=" mb-2 mt-5 fw-bold"> 2. Personal Information- How We Use It</h4>
    <p class="lh-lg font-9-rem mt-2">
      We use your personal information to: set up and manage “Your Profile” on our site; provide our Services; display
      the appropriate information to your matches, including user name, photos, videos, and other content. We process
      transactions; relay information including order confirmation, refund and renewal processes, notifying of matches,
      Service announcements, and Admin messages. We will advise you of special promos, newsletters, new products,
      discounts, special events, and third party offers; track market trends and utilize the latest consumer research
      and allow access to our forums and various social media platforms; conduct accounting, auditing, billing,
      reconciliation, and collection; respond to and prevent potential liabilities, claims, prohibited and/or criminal
      activity comply with and enforce legal requirements, agreements, and policies; use for any other purpose with your
      consent.
    </p>
    <p class="lh-lg font-9-rem mt-2">
      We may endeavour to communicate with you by telephone, e-mail or text message (including to any wireless number
      you may provide). For example, you may sign up for a text messaging service (“SMS Verification) which enables
      Eternal Strings to verify your phone number via a confirmed text message and display such verification in your
      public account (as a security measure for your matches’ benefit).
    </p>

    <p class="lh-lg font-9-rem mt-2">You may change or delete your number from your account preferences pages or request
      removal from our contact list if you receive a call or text message from us. To opt out of the SMS Verification
      service, please send us an e-mail at any time. However, please note that opting out of receiving telephone calls
      will not stop us from calling you regarding an outstanding balance. </p>

    <p class="lh-lg font-9-rem mt-2"> You also agree to allow us to anonymously use your information and experiences to
      continue our research into successful relationships. However, all your responses will be kept anonymous and no
      personal information will be published without your written consent.</p>

    <p class="lh-lg font-9-rem mt-2">
      Just Visiting If you visit our site without registering with us, we may collect the following data: your IP
      address; the website that referred you; the sites that you visit from our website; the date and time of your
      visit; your browser settings; and your Operating System.
    </p>

    <p class="lh-lg font-9-rem mt-2">
      California Privacy Rights California users have the following additional rights under Consumer Privacy Act, and
      have the right to be free from unlawful discrimination for exercising these rights.
    </p>

    <p class="lh-lg font-9-rem mt-2">
      You have the right to request that we disclose certain information and explain how we collected, used, and shared
      your personal information over the past 12 months.

    </p>

    <p class="lh-lg font-9-rem mt-2">
      You have the right to request we delete personal information that we collected from you, subject to some
      exceptions.
    </p>

    <p class="lh-lg font-9-rem mt-2">
      California’s “Shine the Light” law (Civil Code section 1798.83) requires we respond to questions from California
      customers about our practices related to disclosing personal information to third parties for the third parties’
      direct marketing purposes. If you wish to find out about any rights you may have under California Civil Code
      section 1798.83, you can write to us at
      <a routerLink="/home" target="_blank" class="default_link fw-600"> eternalstrings.com </a>
    </p>


    <div class="w-100" id="heading3"></div>
    <h4 class=" mb-2 mt-5 fw-bold"> 3. Personal Information- How We Collect and Safeguard It</h4>
    <p class="lh-lg font-9-rem  mt-2">In order to provide our Services, we collect personal information about you that
      can be used to identify you as an individual. What information we collect, process and use, depends on how and why
      you use our Services. </p>
    <p class="lh-lg font-9-rem  fst-italic mt-2"> Personal Information Supplied By You.</p>
    <p class="lh-lg font-9-rem  mt-2"> When you register or subscribe to any of our Services, you will need to divulge a
      variety of information about you, including your contact information (name, email address, phone number(s),
      mailing address, date of birth, dating preferences, etc.) We also require answers to our <strong>Compatibility
        Questionnaire</strong>, which we use to develop your personality profile to match you with other suitable
      singles. You will also provide us your photos that you agree we may make available to your matches and Eternal
      Strings Services.
    </p>
    <p class="lh-lg font-9-rem  mt-2">We may also collect your name and contact information and any correspondence s
      with us, if you contact us. If you are a <strong>Diamond</strong> or <strong>Platinum</strong> Member, you will
      also be able to communicate with suitable matches via video messages in selected countries. If you utilize the
      video chat options, you acknowledge and agree that the matches you communicate with will have access to video of
      you. Since Eternal Strings uses a third-party provider to enable the video chat feature, you hereby agree that
      Eternal Strings will transfer your IP address and profile ID to this service provider strictly for the purpose of
      providing the service.</p>
    <p class="lh-lg font-9-rem  mt-2">
      We may also require your name, address, phone number, email address and payment options including but not
      necessarily limited to credit or debit card information and associated account information when we process your
      purchases of Eternal Strings Services. To delete such payment information, please use the “Contact Us” section as
      directed.
    </p>
    <p class="lh-lg font-9-rem  fst-italic mt-2">Private or Sensitive Information.</p>

    <p class="lh-lg font-9-rem  mt-2">While registering and setting up your profile, you may provide us with information
      considered “private” or “sensitive” in your jurisdiction. This would include but is not limited to, religion,
      ethnicity, and political views. This information will only be used by Eternal Strings to provide the services to
      you. By providing the information, you agree to our storing, processing, and usage for the above purpose. You may
      change or update your profile information at any time or select the “Not specified” option in response to any
      question requestion such information.</p>
    <p class="lh-lg font-9-rem  mt-2"> Automatically Collected Personal Information.</p>
    <p class="lh-lg font-9-rem  mt-2"> When you access our website for any reason, we automatically collect information
      on how you access and use our Website and Services. At this point we will collect your IP address, browser type,
      Internet Service Provider (ISP), referring and exit pages, platform type, date/time stamps, and number of clicks.
      We may also collect information on the device used to access our site (ie mobile device ID, model, and
      manufacturer), and operating system, and version information. This information is used collectively and
      exclusively to analyze trends, administer our Services, prevent fraud, understand our users’ interactions with our
      Services, gather demographic information about our users to better tailor our visitors’ involvement with our
      Services, and show them continent pertaining to their preferences or that we feel might interest them. We do not
      share this information with third parties.</p>


    <p class="lh-lg font-9-rem mt-2"> Cookies and Web Trackers.</p>
    <p class="lh-lg font-9-rem mt-2">We use “cookies” to track information of people who are accessing or using our
      Services. Cookies are miniscule files placed on the user’s computer that allow us to count the number of visitors
      to our site and distinguish repeat visitors from new ones. They also facilitate us saving user preferences and
      tracking user trends. Our Website requires cookies for proper operation so it is imperative that you set your
      computer to accept cookies to utilize our Services. Some cookies are automatically erased at the end of your
      browser session (session cookies), while other cookies may be stored for a predetermined amount of time
      (temporary) and still others may be stored permanently on your device. These cookies will not name you, but merely
      distinguish your device. Almost all websites utilize cookie technology. </p>


    <p class="lh-lg font-9-rem  mt-2">We use cookies to: identify or certify our users to ensure our Service is secure;
      to temporarily store certain user entries and; to store certain user preferences, such as preferred language.</p>
    <p class="lh-lg font-9-rem  mt-2">We use analytic cookies to record usage data to ascertain which users visited
      which pages and evaluate this information to continue to improve our Services. </p>
    <p class="lh-lg font-9-rem  mt-2"> Third Party Advertising Cookies</p>

    <p class="lh-lg font-9-rem  mt-2">We allow third parties to collect our users’ data through advertising cookies that
      allow us and the third parties to provide your interest-based advertising based on your usage—eg which banner or
      subpages you’ve clicked on. </p>

    <p class="lh-lg font-9-rem  mt-2"> Deactivating or Erasing Cookies</p>
    <p class="lh-lg font-9-rem  mt-2">You can deactivate or erase cookies on your device via your browser settings.
      Please note that doing so may prevent certain features on our Website from working properly. It also might curtail
      usage of some of our Service. </p>


    <div class="w-100" id="heading4"></div>
    <h4 class="mb-2 mt-5 fw-bold"> 4. Match Notifications </h4>
    <p class="lh-lg font-9-rem mt-2"> We will send you Match Notifications informing you when someone sends you a
      message or for other Service-related matters. These notifications can be turned off at the device level if you
      wish to opt out of receiving the messages.</p>


    <div class="w-100" id="heading5"></div>
    <h4 class="mb-2 mt-5 fw-bold"> 5. Disclosure of your Personal Information</h4>
    <p class="lh-lg font-9-rem fst-italic mt-2"> Eternal Strings may disclose your profile information to your
      prospective matches. This may include photos, last log-in date, whether you are currently online, etc. We will
      never share your contact information with your matches without your consent. </p>

    <p class="lh-lg font-9-rem mt-2">We may share your personal information with current and future companies that are
      part of our network or organizations for the purposes described in this Privacy Policy, including with your
      consent; with affiliated and unaffiliated service providers to help us deliver our Services, subject to
      confidentiality agreements, including but not exclusive to, customer care agents; technology assistants; service
      fulfilment; form processing; website management and hosting; information technology and security; email and
      newsletter delivery; advertisement partners and corporate sponsors; auditing; collection agencies; and credit card
      processing.</p>
    <p class="lh-lg font-9-rem  mt-2">We may also disclose your information to the appropriate authorities if we deem it
      necessary to prevent physical, financial, or other harm, injury, or loss, including to protect against fraud or
      credit risk.</p>
    <p class="lh-lg font-9-rem  mt-2"> We may also share your information to comply with legal, governmental or judicial
      authorities as required by law, such as in response to a legal subpoena or investigation of suspected illegal or
      illicit activities, or where we may believe in good faith that users may be engaged in illicit or illegal
      activities. Or if we are required by law to enable a network partner to comply with applicable laws.</p>
    <p class="lh-lg font-9-rem  mt-2">Disclosure of your information may also occur with necessary third parties in
      connection with or during negotiations for, a merger, acquisition, asset sale or other similar business
      transactions involving the transference of assets or functions where personal information is transferred or shared
      as part of business assets. </p>






    <div class="w-100" id="heading6"></div>
    <h4 class="mb-2 mt-5 fw-bold">6. Security and Personal Information Storage</h4>
    <p class="lh-lg font-9-rem mt-2">The security of your files is of the utmost importance to Eternal Strings. We use
      firewalls, digital certificates, Security Socket Layer (SSL) and encryption technology during credit card
      transactions and administrative access to site data as well as other proprietary security measures used in all
      storage and transfers of user information.</p>

    <p class="lh-lg font-9-rem mt-2">We shall keep and store your personal information only as long as you use our
      Service and also as permitted/required by applicable law. We delete or anonymize your information upon deletion of
      your account, unless the same is required for to comply with legal obligations, fraud prevention, take actions we
      deem necessary to protect the integrity of our website or our users, to resolve disputes, to enforce our
      agreements, to support business operations, and to continue to develop and improve our Services. We retain
      information for better services, and we only use the information to analyse about the use of our Services, not to
      specifically analyse personal characteristics about you. We retain your information for as long as it is required
      to fulfil the purpose(s) for which it was collected and in compliance with jurisdictional laws. Your consent to
      such purpose(s) remains valid after termination of your relationship with us. <strong>After 24 months of
        inactivity of your Membership, we will delete your personal information unless we have a valid reason to retain
        such information. </strong>
    </p>

    <p class="lh-lg font-9-rem  mt-2"><strong>Your personal information may be processed and stored outside of the
        jurisdiction or country in which you reside, by us, an affiliate, or an unaffiliated service provider for the
        purposes set out in this Privacy Policy and, under the laws of these other jurisdictions, in certain
        circumstances foreign courts, law enforcement agencies or regulatory agencies may be entitled to access your
        personal information.
      </strong></p>

    <div class="w-100" id="heading7"></div>
    <h4 class="mb-2 mt-5 fw-bold">7. “Do Not Track” requests</h4>
    <p class="lh-lg font-9-rem   mt-2"> We do not process or respond to web browsers “Do Not Track” signals or other
      similar requests to disable online tracking of users who visit our Websites. You may disable cookies as discussed
      above at your own discretion.</p>

    <div class="w-100" id="heading8"></div>
    <h4 class="mb-2 mt-5 fw-bold">8. Access From Other Sites</h4>
    <p class="lh-lg font-9-rem fst-italic mt-2"> You may be able to access your account or content of your account from
      third party websites, such as social networking sites (eg Facebook), by way of various applications. These
      services will be able to authenticate your identity and allow you to pre-populate our registration form with your
      name and email address. The privacy policies and practices in connection with the information you disclose on such
      sites may vary from the practices of Eternal Strings as set forth in this Privacy Policy. We are not responsible
      for the privacy policies and practice of any other providing access to or linking to or from our Services. Should
      you choose to use Facebook or Apple login feature or any other social network feature for your Eternal Strings
      account, you should not permit other persons to use your Facebook or Apple accounts or give other persons your
      login information for those websites. See Terms & Conditions regarding “Exclusive Use”.</p>



    <div class="w-100" id="heading9"></div>
    <h4 class="mb-2 mt-5 fw-bold">9. Modifying or Deleting Your Personal Information</h4>
    <p class="lh-lg font-9-rem  mt-2">Eternal Strings users may update certain information (such as match settings,
      billing account/payment method, and email address) by logging into your account and making the changes directly.
      Eternal Strings shall not be held liable for any information not being modified or deleted for any reason
      whatsoever.</p>
    <p class="lh-lg font-9-rem  mt-2">Other personal information updates (such as date of birth changes) must be
      processed by our customer care service for verification and to protect other members.</p>
    <p class="lh-lg font-9-rem  mt-2"> To delete your personal account information, please access our FAQ’s and follow
      the steps outlined under the relevant account deletion FAQ.</p>


    <div class="w-100" id="heading10"></div>
    <h4 class="mb-2 mt-5 fw-bold">10. Dispute Resolutions</h4>
    <p class="lh-lg font-9-rem  mt-2"> For any privacy or data use concern not covered, please contact us at the address
      or e-mail address listed below.</p>


    <div class="w-100" id="heading12"></div>
    <h4 class="mb-2 mt-5 fw-bold">11. Contact US</h4>
    <p class="lh-lg font-9-rem  mt-2"> For any questions or concerns about our Privacy Policy or our Privacy Practices,
      contact us by e-mail at <a href="mailto:info@eternalstrings.com" class="default_link">info@eternalstrings.com</a>
    </p>

    <div class="w-100" id="heading13"></div>
    <h4 class="mb-2 mt-5 fw-bold">12. Effective Date</h4>
    <p class="lh-lg font-9-rem  mt-2">July 03, 2024 </p>


  </div>


</section>


<app-footer></app-footer>
