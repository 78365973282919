import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private forgotPasswordSendSMSUrl = `${environment.apiURL}/admin/forgotPasswordSendSMS`;
  private adminApi = `${environment.apiURL}/admin`;
  private getIsUserVaildUrl = `${environment.apiURL}/admin/isUrlActive`;
  private resetpasswordUrl = `${environment.apiURL}/admin/forgotPassword`;

  constructor(private http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) { }

  getAdminData() {
    const temp = localStorage.getItem('Remember_me');
    if (temp == null) {
      //  document why this block is empty
    } else if (temp == 'true') {
      return JSON.parse(localStorage.getItem('adminuser'));
    } else {
      let adminuser = localStorage.getItem('adminuser');
      if (adminuser == null) {
        // console.log(adminuser, "hello admin user on null")
        localStorage.removeItem('adminuser');
        localStorage.removeItem('Remember_me');
        sessionStorage.removeItem('adminuser');
        this.router.navigate(['/admin'])
      } else {
        // console.log(adminuser, "hello admin user on not null")

        return JSON.parse(adminuser);
      }
    }
  }

  getUserList(data: any): Observable<any> {
    return this.http.post(this.adminApi + `/getUserList`, data);
  }

  dashboardUserLogin(user: any): Observable<any> {
    return this.http.post(this.adminApi + `/dashboardUserLogin`, user);
  }
  adminLogin(user: any): Observable<any> {
    return this.http.post(this.adminApi + `/adminLogin`, user);
  }

  forgotPasswordSendMail(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/forgotPasswordSendMail`, user);
  }

  resetPassword(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/resetPassword`, data);
  }

  getIsUserVaild(user: any): Observable<any> {
    return this.http.post<any>(this.getIsUserVaildUrl, user);
  }

  actionOnProfile(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/actionOnProfile`, data);
  }

  // deleteProfile(data: any): Observable<any> {
  //   return this.http.post(`${environment.apiURL}/auth/deleteProfile1`, data);
  // }

  forgotPasswordSendSMS(user: any): Observable<any> {
    return this.http.post(this.forgotPasswordSendSMSUrl, user);
  }

  changePasswordAdmin(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/changePasswordAdmin`, data);

  }
  // forgotPasswordSendSMS(user: any): Observable<any> {
  //   return this.http.post(`${environment.apiURL}/admin/forgotPasswordSendSMS`,user);
  // }
  // createRole(user_name: any): Observable<any> {
  //   return this.http.post('createRole', 'AdminController@createRole',user_name);
  // } 

  addSystemUser(adminuser: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/addSystemUser`, adminuser);
  }

  createBlog(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/createBlog`, data);
  }
  updateBlog(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/updateBlog`, data);
  }

  actionOnProfilePhoto(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/actionOnProfilePhoto`, data);
  }

  deleteBlog(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/deleteBlog`, data);
  }

  getPartnerDetails(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getPartnerDetails`, user);
  }

  userProfilePreview(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/userProfilePreview`, user);
  }

  PartnerPreference(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setPartnerPreference`, user);
  }

  getUserDetail(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getUserDetail`, user);
  }

  getBlogDetailsById(id: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getBlogDetailsById`, id);
  }

  getBlogDetailsByTitle(id: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getBlogDetailsByTitle`, id);
  }

  getAdminDashboardData(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getAdminDashboardData`, data);
  }

  getProfileCountByCountries(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getProfileCountByCountries`, data);
  }

  getProfileCountByLanguages(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getProfileCountByLanguages`, data);
  }

  getProfileCountByAgeRange(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getProfileCountByAgeRange`, data);
  }

  getProfileCountByMonths(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getProfileCountByMonths`, data);
  }

  getMonthlyRevenuByCountryYear(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getMonthlyRevenuByCountryYear`, data);
  }

  getYearlyRevenuByCountry(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getYearlyRevenuByCountry`, data);
  }

  getProfileCountByAnnualIncomeByRange(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getProfileCountByAnnualIncomeByRange`, data);
  }

  getProfileDeletedByMonth(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getProfileDeletedByMonth`, data);
  }

  // User edit Apis start
  setHobbiesDetails(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setHobbiesDetails`, data);
  }

  setFamilyDetails(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setFamilyDetails`, data);
  }

  setAstroDetails(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setAstroDetails`, data);
  }

  setReligionView(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setReligionView`, data);
  }

  setBasicAndLifestyle(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setBasicAndLifestyle`, data);
  }

  setAboutYou(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setAboutYou`, data);
  }

  setContactDetails(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setContactDetails`, data);
  }

  setLocationDetails(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setLocationDetails`, data);
  }

  setEducationDetails(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setEducationDetails`, data);
  }

  // User edit Apis end

  // partner prefrence edit Apis start
  setBasicAndLifestyleOfPartner(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setBasicAndLifestyleOfPartner`, data);
  }

  setReligiousViewOfPartner(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setReligiousViewOfPartner`, data);
  }

  setEducationAndCareerOfPartner(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setEducationAndCareerOfPartner`, data);
  }

  setFamilyDetailsOfPartner(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setFamilyDetailsOfPartner`, data);
  }

  setLocationOfPartner(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setLocationOfPartner`, data);
  }


  // partner prefrence edit Apis end

  uploadImage(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/image-upload`, user);
  }

  uploadCoverImage(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/cover-upload`, user);
  }

  actionOnUserManagement(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/actionOnUserManagement`, data);
  }

  getManagementUserList(data: any): Observable<any> {

    return this.http.post(`${environment.apiURL}/admin/getManagementUserList`, data);
  }

  getUserRoleList(data: any): Observable<any> {

    return this.http.post(`${environment.apiURL}/admin/getUserRoleList`, data);
  }

  getSystemUserDetails(data: any): Observable<any> {

    return this.http.post(`${environment.apiURL}/admin/getSystemUserDetails`, data);
  }

  updateSystemUser(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/updateSystemUser`, data);
  }

  // getUserRoleList(data: any): Observable<any> {
  //   return this.http.post(`${environment.apiURL}/admin/getUserRoleList`, data);
  // }

  getUserDepartmentList(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getUserDepartmentList`, data);
  }

  stepSendMail(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/stepSendMail`, user);
  }

  setHandoverDetails(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/setHandoverDetails`, user);
  }

  updateProfileDetails(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/updateProfileDetails`, data);
  }

  getUser(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getUser`, user);
  }
  registerOne(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/registerOne`, user);
  }
  registerTwo(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/registerTwo`, user);
  }


  registerThree(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/registerThree`, user);
  }

  registerFour(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/registerFour`, user);
  }

  registerFive(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/registerFive`, user);
  }

  getDeletedProfileList(data: any): Observable<any> {
    return this.http.post(this.adminApi + `/getDeletedProfileList`, data);
  }
  getDeletedProfileList22(data: any): Observable<any> {
    return this.http.post(this.adminApi + `/getDeletedProfileList22`, data);
  }

  getBlockAndReportedByList(data: any): Observable<any> {
    return this.http.post(this.adminApi + `/getBlockAndReportedByList`, data);
  }
  getPhotoReviewUserList(data: any): Observable<any> {
    return this.http.post(this.adminApi + `/getPhotoReviewUserList`, data);
  }

  getUserTransactionsList(data: any): Observable<any> {
    return this.http.post(this.adminApi + `/getUserTransactionsList`, data);
  }

  getDeletedAccountList(data: any): Observable<any> {
    return this.http.post(this.adminApi + `/getDeletedAccountList`, data);
  }

  sendAlertMailToUsers(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/sendAlertMailToUsers`, user);
  }

  userPaymentByAdmin(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/userPaymentByAdmin`, user);
  }

  extendMembershipByAdmin(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/extendMembershipByAdmin`, user);
  }

  addFAQ(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/addFAQ`, user);
  }

  deleteFAQ(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/deleteFAQ`, user);
  }

  getFAQList(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getFAQList`, user);
  }

  getFAQDetail(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getFAQDetail`, user);
  }



  getFAQCategoryList(): Observable<any> {
    return this.http.get(`${environment.apiURL}/admin/getFAQCategoryList`);
  }

  getAllFAQCategoryList(): Observable<any> {
    return this.http.get(`${environment.apiURL}/admin/getAllFAQCategoryList`);
  }

  addFAQCategory(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/addFAQCategory`, user);
  }

  deleteFAQCategory(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/deleteFAQCategory`, user);
  }

  sendAlertMailToAllUsers(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/sendAlertMailToAllUsers`, user);
  }

  sendNonMandatoryAlertMailToAllUsers(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/sendNonMandatoryAlertMailToAllUsers`, user);
  }

  getAlertMailList(data: any): Observable<any> {
    return this.http.post(this.adminApi + `/getAlertMailList`, data);
  }


  addEvent(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/addEvent`, user);
  }

  deleteEvent(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/deleteEvent`, user);
  }

  getEventList(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getEventList`, user);
  }

  getEventDetail(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getEventDetail`, user);
  }



  getEventCategoryList(): Observable<any> {
    return this.http.get(`${environment.apiURL}/admin/getEventCategoryList`);
  }

  getAllEventCategoryList(): Observable<any> {
    return this.http.get(`${environment.apiURL}/admin/getAllEventCategoryList`);
  }

  addEventCategory(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/addEventCategory`, user);
  }

  deleteEventCategory(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/deleteEventCategory`, user);
  }

  actionOnExtendMembershipByAdmin(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/actionOnExtendMembershipByAdmin`, user);
  }

  getNumberOfMarriagesList(data: any): Observable<any> {
    return this.http.post(this.adminApi + `/getNumberOfMarriagesList`, data);
  }
  getMarriagesList(data: any): Observable<any> {
    return this.http.post(this.adminApi + `/getMarriagesList`, data);
  }

  getExtendPlanUserList(data: any): Observable<any> {
    return this.http.post(this.adminApi + `/getExtendPlanUserList`, data);
  }

  getExtendPlanUserListCount(data: any): Observable<any> {
    return this.http.post(this.adminApi + `/getExtendPlanUserListCount`, data);
  }


  updateAdminRolePermission(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/updateAdminRolePermission`, user);
  }

  getAdminRolePermission(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getAdminRolePermission`, user);
  }

  addBlogCategory(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/addBlogCategory`, user);
  }
  updateBlogCategory(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/updateBlogCategory`, user);
  }
  getBlogsList(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getBlogsList`, user);
  }
  deleteBlogCategory(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/deleteBlogCategory`, user);
  }

  addStory(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/addStory`, data);
  }
  deleteStory(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/deleteStory`, data);
  }
  getStoryDetail(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getStoryDetail`, data);
  }
  getStoryList(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getStoryList`, data);
  }

  // updatestory(data: any): Observable<any> {
  //   return this.http.post(`${environment.apiURL}/admin/updatestory`, data);
  // }


  addHoneymoonDestination(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/addHoneymoonDestination`, data);
  }
  deleteHoneymoonDestination(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/deleteHoneymoonDestination`, data);
  }
  getHoneymoonDestinationDetail(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getHoneymoonDestinationDetail`, data);
  }
  getHoneymoonDestinationList(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getHoneymoonDestinationList`, data);
  }

  sendAlertMailToUser(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/sendAlertMailToUser`, user);
  }

  updateFaqCategory(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/updateFaqCategory`, user);
  }

  actionOnMailAlert(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/actionOnMailAlert`, data);
  }
  sendForApprove(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/sendForApprove`, user);
  }
  // get the media list
  getMediaList(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getMediaList`, data);
  }
  uploadMedia(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/uploadMedia`, data);
  }

  actionOnMedia(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/actionOnMedia`, data);
  }
  
  getSuspeciousAlertMailList(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/getSuspeciousAlertMailList`, data);
  }

  actionOnSuspeciousAlertList(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/admin/actionOnSuspeciousAlertMList`, data);
    // return this.http.post(`${environment.apiURL}/admin/deleteBlog`, data);
  }

}
