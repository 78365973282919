<div class="w-100 bg-maroon" *ngIf="!isLoggedIn">
  <app-header></app-header>
</div>
<div class="page-header">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
        <div class="page-caption">
        </div>
      </div>
    </div>
  </div>
</div>


<section class="container my-4 px-3">
  <div class="card p-4 shadow-sm rounded-5  min_content_height_202 mt-120">

    <div class="my-3">
      <h3 class="text-center fw-bold text-danger">Terms and Conditions</h3>
    </div>

    <h6 class="fw-bold mb-0">Last updated March 16, 2023</h6>

    <p class="mt-4 lh-lg font-9-rem">
      This End-User License Agreement (“Agreement”) is a legal agreement between you (either an individual or a single entity and referred to as “You” or “User”) and Eternal Strings a brand of Jub We Met Inc. with the website eternalstrings.com (“Eternal Strings”).
    </p>

    <p class=" lh-lg font-9-rem fw-bold">
      IF YOU WISH TO USE THE ETERNAL STRINGS SYSTEM YOU MUST CLICK “I AGREE” SIGNIFYING YOU ACCEPT THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT AGREE, YOU MAY NOT USE ETERNAL STRINGS.
    </p>

    <p class="mt-4 lh-lg font-9-rem">In consideration of the mutual promises and covenants contained in this Agreement, You and Eternal Strings hereby agree to be bound by the following:</p>
    <p class="mt-2 lh-lg font-9-rem">Eternal Strings is a bespoke matrimony and matchmaking platform that provides matchmaking services and connects South Asian’s globally. This Agreement sets out the legally binding terms for the User access our website, services and/or membership. This Agreement may be amended and/or modified by Eternal Strings from time to time.</p>

    <div class="w-100 my-3">
      <hr>
    </div>


    <h4 class="text-danger mt-5 fw-bold">ACCEPTANCE & REGISTRATION</h4>
    <p class="mt-4 lh-lg font-9-rem">
      This End-User License Agreement (“Agreement”) is a legal agreement between you (either an individual or a single entity and referred to as “You” or “User”) and Eternal Strings a brand of Jub We Met Inc. with the website eternalstrings.com (“Eternal Strings”).
    </p>
    <p class="mt-4 lh-lg font-9-rem">
      Member means a person who voluntarily submits information to Eternal Strings whether such person uses the service as a free member, or a paid member. User means an individual who actively wants to avial the services and uses Eternal Strings to promote their profile for matchmaking services. By using the Eternal Strings and/or eternalstrings.com, you agree to be bound by this Agreement.
    </p>
    <p class="mt-4 lh-lg font-9-rem">
      By using the platform and service, you consent to Eternal Strings to collect, process, use, display and submit your personal information including but not limited to sensitive and/or personal data, financial data, identity proofs submitted by you or any person you authorise on your behalf. You understand and acknowledge that Eternal Strings shall use the said information to provide the service and to enable other prospective matches to contact you. If you have any objection in collecting and/or processing your personal data, we advise you not to register with our site. If you want to withdraw this consent or if you have any objection in continuous collection or storage of your personal details, you must discontinue using our service and delete your account by sending an email from your registered email address to <a href="mailto:gailbains@telus.net">gailbains@telus.net</a>.
    </p>
    <p class="mt-4 lh-lg font-9-rem">
      Eternal Strings Trusted User Identification means the identification name(s), number(s) and/or password(s) provided by Eternal Strings to a User to access the Eternal Strings Systems under a User Account.
    </p>
    <p class="mt-4 lh-lg font-9-rem">
      User Account means an account established by Eternal Strings for a User. If an individual is creating the profile on behalf of friend or sibling or children or family member, the person making such profile hereby agree that they have the required permission/consent/authority from the particular person whose profile is created as a User. Impersonation is a crime and punishable offence under law and Eternal Strings Prohibits any such activity.
    </p>
    <p class="mt-4 lh-lg font-9-rem">
      User Profile means the recorded profile for a User based on the information provided by the User or a person authorised to make the profile on behalf of the User to Eternal Strings.
    </p>
    <p class="mt-4 lh-lg font-9-rem">
      Website means the website located at www.eternalstrings.com operated by Eternal Strings.
    </p>
    <p class="mt-4 lh-lg font-9-rem">
      Any individual can access the website and create a User Profile to be enabled to use the site as a User. However, to avail the services and promote their profile on the platform, and connect to the prospective matches, the User has to take the membership mentioned on the site eternalstrings.com. Eternal Strings reserves sole right of admission and provide membership to individuals for the platform.
    </p>
    <p class="mt-4 lh-lg font-9-rem">
      The platform is for personal use of individuals interested in finding a suitable match for themselves. The platform cannot be used by any individual for commercial purpose.
    </p>
    <!-- <ol type="A" class="lh-lg font-9-rem ">
      <li class="lh-lg">
        “Eternalstrings Trusted User Identification” means the identification name(s), number(s) and/or password(s) provided by Eternalstrings to a User to access the Eternalstrings Systems under a User Account;
      </li>
      <li class="lh-lg">
        “User Account” means an account established by Eternalstrings for a User;
      </li>
      <li class="lh-lg">
        “User Profile” means the recorded profile for a User based on the information provided by the User to Eternalstrings; and
      </li>
      <li class="lh-lg">
        “Website” means the website located at www.eternalstrings.com operated by Eternalstrings.
      </li>
    </ol> -->



    <h4 class="text-danger mt-5 fw-bold">ELIGIBILITY & RIGHT TO USE</h4>
    <p class="mt-4 lh-lg font-9-rem">To register yourself as a User and avail the services as a member, you represent and warrant that:</p>
    <ul class="lh-g font-9-rem ">
      <li class="lh-lg">
        you are at least 18 years of age for females and 21 years for males in India. 18 years and above for Canada. If you reside in any other country, you the age criteria for marriageable age of that country shall apply.
      </li>
      <li class="lh-lg">
        you have the legal capacity, right, consent, authority and free will to enter into this Agreement; submit the information. 
      </li>
      <li class="lh-lg">
        you are not prohibited or prevented by any applicable law for the time being in force or any order or decree or injunction from any court, tribunal or any such competent authority restraining you from entering into matrimony.
      </li>
      <li class="lh-lg">
        you can enter and form a legal & binding contract with Eternal Strings.
      </li>
      <li class="lh-lg">
        you are not a person who is barred from using the service under the laws of the India, Canada or any other applicable jurisdictions.
      </li>
      <li class="lh-lg">
        you have not been previously suspended or removed from any such or similar service(s).
      </li>

      
      <li class="lh-lg">you will comply with this Agreement and all applicable local, state, national and international laws, rules, and regulations.
      </li>
      <li class="lh-lg">you have the capabilities, right and authority to enter this Agreement and to obey all Terms and Conditions of this Agreement.
      </li>
      <li class="lh-lg">you have never been convicted of a felony or indictable offense (or crime of similar severity), a sex crime, or any crime involving violence, and that you are not required to register as a sex offender with any state, federal or local sex offender registry.
      </li>
      <li class="lh-lg">you are not affiliated with, or employed by, any of our competitors.
      </li>
      <li class="lh-lg">your intention is to find a life partner. 
      </li>
      <li class="lh-lg">you shall not use the platform for any commercial use.
      </li>
      <li class="lh-lg">while using eternalstrings.com or Eternal Strings you acknowledge that the match search results shown are based on the partner preference and details submitted by you.
      </li>
      <li class="lh-lg">you grant Eternal Strings the reservation of right to share information with third parties for promotions and offers exclusive for its members. Eternal Strings shall obtain prior consent before sharing such information,
      </li>
    </ul>






    <h4 class="text-danger mt-5 fw-bold">SYSTEM LICENSE</h4>
    <p class=" lh-lg font-9-rem ">Eternal Strings allows you a personal, worldwide, royalty-free, non-assignable, nonexclusive, non-transferable, revocable, and non-sublicensable license to access and use the service and access the platform. This limited license is for the sole purpose of letting you access the services benefits as intended by Eternal Strings and permitted by this Agreement. This license and any authorization to use the Service are automatically cancelled in the event you do any of the following:</p>
    <ul class="lh-g font-9-rem ">
      <li class="lh-lg">use the Service or any content contained in the Service for any commercial purposes without our written consent.
      </li>
      <li class="lh-lg">copy, modify, transmit, create any derivative works from, make use of, or reproduce in any way any copyrighted material, images, trademarks, trade names, service marks, or other intellectual property, content, or proprietary information accessible through the service without Eternal Strings’ prior written consent.
      </li>
      <li class="lh-lg">express or imply that any statements you make are endorsed by Eternal Strings.
      </li>
      <li class="lh-lg">use any robot, bot, spider, crawler, scraper, site search/retrieval application, proxy or other manual or automatic device, method, or process to access, retrieve, index, data mine, or in any way reproduce or circumvent the navigational structure or presentation of the service or its contents.
      </li>
      <li class="lh-lg">use the Service in any way that could interfere with, disrupt, or negatively affect the service or the servers or networks connected to the service.
      </li>
      <li class="lh-lg">upload viruses or other malicious code or otherwise compromise the security of the Service.
      </li>
      <li class="lh-lg">forge headers or otherwise manipulate identifiers to disguise the origin of any information transmitted to or through the service.
      </li>
      <li class="lh-lg">use meta tags or code or other devices containing any reference to Eternal Strings or the service (or any trademark, trade name, service mark, logo, or slogan of Eternal Strings) to direct any person to any other website for any purpose.
      </li>
      <li class="lh-lg">modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile, or otherwise disassemble any portion of the service, or cause others to do so.
      </li>
      <li class="lh-lg">use or develop any third-party applications that interact with the service or other members’ content or information without our written consent.
      </li>
      <li class="lh-lg">use, access, or publish the Eternal Strings application programming interface without our written consent.
      </li>
      <li class="lh-lg">probe, scan or test the vulnerability of our Service or any system or network.
      </li>
      <li class="lh-lg">encourage or promote any activity that violates this Agreement.
      </li>
      <li class="lh-lg">upon the expiry of membership resulting to termination of this agreement.
      </li>
      <li class="lh-lg">Upon termination of the Agreement due to contravention of any terms and conditions mentioned in the Agreement.
      </li>
    </ul>
    <p class=" lh-lg font-9-rem ">Eternal Strings may investigate and take any available legal action in response to illegal or unauthorized uses of the Service, including but not limiting to termination of your account. Any software that we provide you may automatically download and install upgrades, updates, or other new features. You may be able to adjust these automatic downloads through your device’s settings.</p>



    <h4 class="text-danger mt-5 fw-bold">PROPRIETARY RIGHTS</h4>
    <p class="lh-lg font-9-rem">Eternal Strings owns and retains all proprietary rights in the eternalstrings.com Site and the Service. The Site contains the copyrighted material, trademarks, and other proprietary information of Eternal Strings, and/or its licensors.</p>
    <p class="lh-lg font-9-rem">Except for that information which is in the public domain such as member profile or for which permission has been obtained from the user, you cannot copy, modify, publish, transmit, distribute, perform, display, or sell any such proprietary information. Further, you acknowledge that the information which is in the public domain cannot be used for commercial use by you.</p>
    <p class="lh-lg font-9-rem">Any such act or an attempted act on your part shall constitute a violation of this Agreement and your membership is liable to be terminated forthwith by Eternal Strings without refund of any of your unused Subscription fees.</p>
    <p class="lh-lg font-9-rem">Eternal Strings reserves the right to take legal action (civil and/or criminal) wherever applicable for any violations.</p>
 



    <h4 class="text-danger mt-5 fw-bold">ACCOUNT AND PASSWORDS</h4>
    <p class="lh-lg font-9-rem">The User covenants and agrees with Eternal Strings as follows:</p>

    <ul class="lh-g font-9-rem ">
      <li class="lh-lg">the User will provide Eternal Strings with accurate and complete information as required by Eternal Strings to complete the User Profile.
      </li>
      <li class="lh-lg">the User shall not create multiple profiles of the same person.
      </li>
      <li class="lh-lg">the User will notify Eternal Strings of any change in the information provided to Eternal Strings or of any misuse or potential misuse of the Eternal Strings Trusted User Identification or User Profile within seven (7) days of such changes or information becoming known to the User;
      </li>
      <li class="lh-lg">the User consents to Eternal Strings using and storing any information the User provides to Eternal Strings to fulfil the purposes of this Agreement;
      </li>
      <li class="lh-lg">the User acknowledges and agrees that Eternal Strings has the right, but not the obligation, to validate any information the User discloses to Eternal Strings;
      </li>
      <li class="lh-lg">the User covenants that all information the User provides to Eternal Strings under this Agreement, including any statements made verifying the identity of the User is true, accurate and complete. Eternal Strings shall not be liable for any statements not being accurate or complete.
      </li>
      <li class="lh-lg">the User confirms that the User, if a person, is not a minor in the User’s jurisdiction.
      </li>
      <li class="lh-lg">The User acknowledges and agrees that the establishment of a User Account and User Profile does not entitle the User to use any software application or service provided by Eternal Strings or by any other third party other than the Eternal Strings System. 
      </li>
      <li class="lh-lg">If the User wishes to obtain access to any other software application or service, the User must enter into a separate agreement with the respective software application or service provider. Without limiting the generality of the foregoing, the User acknowledges and agrees that the User is solely responsible to ensure that the software applications or services are suitable for the User’s needs and that each of the software applications or service providers have agreed to deal with the User’s information in a manner acceptable to the User.
      </li>
      <li class="lh-lg">the User covenants shall take all reasonable steps to preclude any third party access to the confidential User Profile or the Eternal Strings Trusted User Identification including, without limitation: 
      </li>
      <li class="lh-lg">maintaining, in strict confidence, any password that limits access to the Eternal Strings System or is used to establish the User’s identity in communications with Eternal Strings;
      </li>
      <li class="lh-lg">ensuring the Eternal Strings System is turned off when not in use or in attendance by the User.
      </li>
      <li class="lh-lg">the User acknowledges and agrees that Eternal Strings, in its sole discretion, may:
        <ul class="lh-g font-9-rem">
          <li class="lh-lg">refuse to establish a User Profile and/or a User Account; or</li>
          <li class="lh-lg">cancel or suspend the User Account and/or access to the Eternal Strings System, if Eternal Strings reasonably believes that the Eternal Strings System has been, or may be, misused by the User or any third party who has obtained access to the Eternal Strings System.</li>
        </ul>
      </li>
      <li class="lh-lg">The User shall notify Eternal Strings immediately if the User becomes aware of any unauthorized use of the whole or any part of the Eternal Strings System, the User Account, the User Profile or the Eternal Strings Trusted User Identification.</li>
    </ul>
    






    <h4 class="text-danger mt-5 fw-bold">ETERNAL STRINGS OBLIGATIONS</h4>
    <p class="lh-lg font-9-rem">Eternal Strings in shall make efforts in the following:</p>
    <ul class="lh-g font-9-rem">
      <li class="lh-lg">upon receipt of satisfactory User information, establish a User Account and a User Profile.
      </li>
      <li class="lh-lg">provide the User with the Eternal Strings Trusted User Identification to enable the User to access the Eternal Strings System.
      </li>
      <li class="lh-lg">provide the User with information from time to time regarding the Eternal Strings System and software applications and services of Eternal Strings’ associated suppliers; and
      </li>
      <li class="lh-lg">subject to the terms of this Agreement, protect any information that the User provides in a manner consistent with applicable legislation and in accordance with Eternal Strings privacy policies as amended from time to time which are available for review on the site eternalstrings.com.
      </li>
      <li class="lh-lg">Eternal Strings may, at its discretion, store data and records generated by Users on the Eternal Strings System.  Notwithstanding the foregoing, the User shall be responsible for all data and records generated by the User including, without limitation, the backup and storage of such data and records.  Eternal Strings shall not be responsible for any lost or corrupted data or records generated by Users and stored on the Eternal Strings System.
      </li>
    </ul>



    <h4 class="text-danger mt-5 fw-bold">DISCLAIMER AND LIMITATION OF LIABILITY</h4>
    <p class="lh-lg font-9-rem">Eternal strings disclaims any and all representations and warranties with respect to the eternal strings system, either express or implied, including, but not limited to, third party infringement, implied warranties of merchantability and fitness for a particular purpose.  The user acknowledges that it has not relied on any representation or warranty made by eternal strings or any other person.  The eternal strings system is provided on an “as is” basis with no warranty, or representation express or implied and eternal strings has no obligation to provide maintenance, support, updates, enhancements or modifications with respect to the eternal strings system to the user.</p>
    <p class="lh-lg font-9-rem">In no event shall eternal strings be liable for any damages suffered or incurred by the user or any other person or entity including, without limitation, any special, incidental, indirect, direct, punitive or consequential damages whatsoever (including, without limitation, damages for loss of business profits, loss of revenue, business interruption, loss of business information, or any other pecuniary loss) arising out of the use of or inability to use the eternal strings system, even if eternal strings has been advised of the possibility of such damages.  In no event shall eternal strings’ aggregate liability to the user for all claims relating to the service exceed the amount paid by the user to eternal strings. The user acknowledges and agrees that the limitations of liability set out above are fair and reasonable in the commercial circumstances of this agreement and that eternal strings would not have entered into this agreement nor provided access to the eternal strings system but for the user’s agreement to limit eternal strings’ liability in the manner, and to the extent, provided for herein. </p>
    <p class="lh-lg font-9-rem">
      Eternal Strings shall bear no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of User and/or member communications or any communications by Eternal Strings to its Members.
    </p>
    <p class="lh-lg font-9-rem">
      Eternal Strings expressly disclaims any liability and/or responsibility as a result of any content and/or listing posted on the eternalstrings.com by any members or the users of the site or any third party. Eternal Strings shall not assume any responsibility or liability for any illegal content posted on the site and/or the platform by any members, users or any third party. All liability, whether civil or criminal arising out of any such content that is posted on the site will be of that member/User/third party who has posted such content. Eternal Strings reserves its right to claim damages from such member/User/third party that it may suffer as a result of such Content Posted on the site. Eternal Strings does not claim ownership of content you submit or make available for inclusion on the Service.
    </p>




    <h4 class="text-danger mt-5 fw-bold">ENDORSEMENTS, COMMUNICATION AND LINKS TO EXTERNAL SITES</h4>
    <p class=" lh-lg font-9-rem ">
      Eternal Strings is not responsible for the contents of any third party sites or services, any links contained in third party sites or services, or any changes or updates to third party sites or services.  Eternal Strings provides these links and access to third party sites and services to the User only as a convenience and the including of any link or access does not imply an endorsement by Eternal Strings of the third party site or service. 
    </p>
    <p class=" lh-lg font-9-rem ">
Eternal Strings may send emails by itself or through its group sites. You agree to receive them. You can unsubscribe them by clicking on the unsubscribe button,
    </p>
    <p class=" lh-lg font-9-rem ">
Nothing in this Agreement, nor the License shall constitute or imply any promise to, or intention to make any purchase of products or services by either party or its affiliates or any commitment by either party or its affiliates with respect to the present or future marketing of any product or service or any commitment to enter into any other business relationship.
</p>

    <h4 class="text-danger mt-5 fw-bold">SECURITY</h4>
    <p class=" lh-lg font-9-rem ">
      In an event of any unauthorized access, use and/or disclosure of the account and/or any other breach of security the User shall inform Eternal Strings with immediate effect.
    </p>
    <p class=" lh-lg font-9-rem ">
      The User shall be responsible for maintaining the confidentiality of the login credentials they make during the registration process. The User shall solely responsible for all activities that occur under the User’s account. The User shall ensure all precautionary measures from their side including but not limited logging out from the account at the end of each session. Eternal Strings shall not be held liable.
    </p>


    
    <h4 class="text-danger mt-5 fw-bold">DISPUTE AMONG MEMBERS</h4>
    <p class=" lh-lg font-9-rem ">
      You shall be solely responsible for your interactions, communications and/or advertisements with other members and/or prospective match members. 
    </p>
    <p class="lh-lg font-9-rem">
      Eternal Strings reserves the right, but shall have no obligation, to resolve and/or monitor disputes between you and other members.
    </p>
    <p class="lh-lg font-9-rem">
      Eternal Strings reserves the right to take appropriate action against errant members. However, Eternal Strings shall not be obliged to share such action with other members, including but not limited to complainants. Eternal Strings expressly disclaims any responsibility or liability for any transactions or interactions between the members inter-se.
    </p>




    <h4 class="text-danger mt-5 fw-bold">INDEMNITY AND REMEDIES</h4>
    <p class=" lh-lg font-9-rem ">
      The User shall defend, indemnify and save Eternal Strings and its respective directors, officers, employees, agents, affiliates, shareholders or any combination thereof harmless from all and/or any claims, costs (including settlement and legal or other professional fees on a solicitor and own client basis), damages, expenses, losses, and liability arising out of or relating to, either directly or indirectly, the User’s use of the Eternal Strings System, any breach by the User of its obligations under this Agreement, any unauthorized alteration, modification, adjustment or enhancement made by the User to the Eternal Strings System or the misuse of a Eternal Strings Trusted User Identification, a User Account or information recorded in a User Profile by the User.    </p>
    <p class=" lh-lg font-9-rem ">
      The User agrees that, in addition to any other rights or remedies Eternal Strings may have for any breach of this Agreement, Eternal Strings has the right to an injunction or other equitable relief in any court of competent jurisdiction enjoining a threatened or actual breach of this Agreement by the User.
    </p>
    
    
    <h4 class="text-danger mt-5 fw-bold">FEES AND PAYMENT ACCOUNTS</h4>
    <p class=" lh-lg font-9-rem ">
      Users may pay monies into the Users Payment Account by the methods set out on the payment page of the website and fees are due on a monthly recurring basis. Eternal Strings reserves the right to withhold the delivery and/or suspend the User’s access to these Eternal Strings System should the User be in default of payment.
    </p>


    <h4 class="text-danger mt-5 fw-bold">RENEWALS</h4>
    <p class=" lh-lg font-9-rem ">
      If you pay for a subscription by payment method identified on our service, and you do not cancel your subscription as set forth at least 24 hours prior to the end of the subscription term, your subscription will automatically be extended at the end of each term for the same duration as the previous subscription term selected, plus all applicable taxes. You acknowledge that your subscriptions is subject to automatic renewals and you consent to and accept responsibility for all recurring charges to your selected payment method, based on this automatic renewal feature without further authorization from you and without further notice except as required by law. You further acknowledge that the amount of the recurring charge may change if the applicable tax rates change or if you are notified that there will be an increase in the applicable subscription fees.
    </p>


    

    <h4 class="text-danger mt-5 fw-bold">REFUNDS</h4>
    <p class=" lh-lg font-9-rem ">
      You shall abide by all of the terms and conditions of this Agreement and use the service in conformity with all applicable laws. If at any time, Eternal Strings is of the opinion or has any reason to believe that you have made any misrepresentation or violated any provisions of the Agreement and/or applicable laws, Eternal Strings reserves the right revoke and terminate your membership and/or any other service without any prior intimation. Further, Eternal Strings shall not be liable to any refund to you of any of your unutilized services and/or membership fee.
    </p>
    <p class=" lh-lg font-9-rem ">
      All charges for purchases are final and non-refundable, and there are no refunds or credits for partially used periods. We may make an exception if a refund for a subscription offering is requested within fourteen days of the transaction date, or if the laws applicable in your jurisdiction provide for refunds.

For subscribers residing in the EU or European Economic Area - in accordance with local law, you are entitled to a full refund without stating the reason during the 14 days after the subscription begins.
Please note that this 14-day period commences when the subscription starts. To request a refund, submit your request to Eternal Strings Customer Service.

    </p>


    <h4 class="text-danger mt-5 fw-bold">FUTURE AMENDMENT OF TERMS</h4>
    <p class=" lh-lg font-9-rem ">
      The User acknowledges that Eternal Strings may modify the terms and conditions of this Agreement in its sole discretion at any time by posting a revised version of the Agreement on the Website. The User’s continued use of the Eternal Strings System and/or Eternal Strings Services shall be evidence of the User’s acceptance of any such modified terms and conditions of this Agreement.    </p>


    <h4 class="text-danger mt-5 fw-bold">TERM AND TERMINATION</h4>
    <p class=" lh-lg font-9-rem ">
      The Agreement will be effective, valid and subsisting as long as you use our services and/or website. Certain provisions of this Agreement shall remain effective even on termination.    </p>
    <p class=" lh-lg font-9-rem ">
      The User may terminate their membership for any reason, by deleting the User profile from the site eternalstrings.com or writing to Eternal Strings, for any reason by providing the other party with a written termination notice (“Termination Notice”). Upon receipt of a Termination Notice by the User, Eternal Strings shall within seven (7) days proceed to close the User’s account. In an event, the User terminates their membership, he/she shall not be entitled to a refund of any unutilized subscription fee paid.
    </p>
    <p class="lh-lg font-9-rem">
      Eternal Strings may terminate your access to the site and/or your membership for any reason mentioned in the Agreement. In an event, the User terminates their membership, he/she shall not be entitled to a refund of any unutilized subscription fee paid.
    </p>
    <p class="lh-lg font-9-rem">
      Promptly upon termination of this Agreement, but in any event not less than one (1) day after termination, the User shall return to Eternal Strings or, upon the written instructions of Eternal Strings, destroy any and all copies of the Eternal Strings System in the User’s possession or control and confirm such destruction in writing to Eternal Strings.
    </p>


    <h4 class="text-danger mt-5 fw-bold">NOTICE</h4>
    <p class=" lh-lg font-9-rem ">
      Any notice required or permitted to be given under this Agreement shall be in writing and shall be effective upon its delivery by courier or delivery by electronic mail to the last civic address or e-mail address provided by the recipient to the other party. 
    </p>


    <h4 class="text-danger mt-5 fw-bold">SURVIVAL</h4>
    <p class=" lh-lg font-9-rem ">
      The User acknowledges, covenants and agrees that due to the proprietary nature of the Eternal Strings System, the provisions of this Agreement, including, without limitation section Disclaimer and Limitation of Liability and section Indemnity and Remedies of this Agreement, will continue in full force and effect notwithstanding the termination of this Agreement for any reason.    </p>


    <h4 class="text-danger mt-5 fw-bold">ASSIGNMENT</h4>
    <p class=" lh-lg font-9-rem ">
      The User will not assign this Agreement, either directly or indirectly, without the prior written consent of Eternal Strings which may be withheld by Eternal Strings for any reason in its sole and unfettered discretion.
No subcontract entered into by the User with any third party will: relieve the User of the obligations under this Agreement; or create any obligation or liability to any such subcontractor upon Eternal Strings.

    </p>


    <h4 class="text-danger mt-5 fw-bold">GOVERNING LAW</h4>
    <p class=" lh-lg font-9-rem ">
      In the event of any dispute involving Eternal Strings and its services, you unconditionally accept that all such disputes will be governed and construed in accordance with the laws of India applicable therein if your location is India when you signed up. The parties agree that the Courts of the New Delhi shall have exclusive jurisdiction to hear and make any judicial determination on any issue arising in this case.
    </p>


    <h4 class="text-danger mt-5 fw-bold">ENUREMENT</h4>
    <p class=" lh-lg font-9-rem ">
      The Agreement will be for the benefit of and binding upon the heirs, executors, representatives, administrators, successors and permitted assigns of the User.
    </p>
    <p class=" lh-lg font-9-rem ">
      In the event of any dispute involving Eternal Strings and its services, you unconditionally accept that all such disputes will be governed and construed in accordance with the laws of the Province of British Columbia and the laws of Canada applicable therein if your location is Canada when you signed up. The parties agree that the Courts of the Province of British Columbia shall have exclusive jurisdiction to hear and make any judicial determination on any issue arising in this case.
    </p>
    <p class=" lh-lg font-9-rem ">
      The Parties agree that they shall try to resolve the matter by arbitration. However, any statute or law to the contrary, and to the maximum extent permitted by applicable law, any dispute must be filed within 1 months of the date on which the incident giving rise to the dispute occurred. Failure to file an arbitration claim as described above, within the applicable time limit constitutes an absolute and unconditional waiver of such claim .
    </p>
    <p class=" lh-lg font-9-rem ">
      For members residing in the U.S.A., EU, or European Economic Area or elsewhere where our arbitration agreement is prohibited by law, the laws of the Province of British Columbia and the laws of Canada, excluding conflict of laws rules, will apply to any disputes arising out of or relating to this Agreement or the Service. The parties have required that this Agreement and all documents relating thereto be drawn-up in English. 
    </p>


    <h4 class="text-danger mt-5 fw-bold">SEVERABILITY</h4>
    <p class="lh-lg font-9-rem ">
      If a court of competent jurisdiction concludes that any provision of this Agreement is illegal, invalid or unenforceable, then it shall be severed from this Agreement and the remaining provisions shall remain in full force and effect.     
    </p>
    <p class="lh-lg font-9-rem ">
      After accepting the Agreement and becoming a member of Eternal Strings, you accept the terms. This agreement contains the entire agreement between you and Eternal Strings regarding the use of the site and/or the service. If any provision of this Agreement is held invalid, the remaining Agreement shall continue in full force and effect.
    </p>


    <h4 class="text-danger mt-5 fw-bold">WAIVER</h4>

    <p class="lh-lg font-9-rem ">
      The failure of Eternal Strings to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision.
    </p>

    <h4 class="text-danger mt-5 fw-bold">BINDING EFFECT</h4>
    <p class="lh-lg font-9-rem ">
      By clicking the I AGREE button set out below and/or by accepting delivery of the Eternal Strings System, copying, accessing or otherwise using the Eternal Strings System, the User represents and warrants to Eternal Strings that the User has all requisite power and authority to enter into this Agreement and has all necessary power and authority to perform the obligations of the User as set out herein.
    </p>


    <h4 class="text-danger mt-5 fw-bold">ENUREMENT</h4>
    <p class="lh-lg font-9-rem ">
      The Agreement will be for the benefit of and binding upon the heirs, executors, representatives, administrators, successors and permitted assigns of the User.
    </p>



    <h4 class="text-danger mt-5 fw-bold">ENTIRE AGREEMENT</h4>
    <p class="lh-lg font-9-rem ">
      This Agreement constitutes the entire understanding between the parties hereto and supersedes all previous communications, representations, and understandings, oral or written, between the parties, with respect to the subject matter of this Agreement.
    </p>
    <p class="lh-lg font-9-rem ">
      By becoming a member of Eternal Strings and/or using the services, you unconditionally and irrevocably confirm that you have read and understood the above provisions. You agree to abide by them. If you are not agreeing to any of the above terms and conditions please refrain yourself from registering on the site or using the services. This Agreement is an electronic document. This electronic record is generated by a computer system and does not require any physical or digital signatures.
    </p>

    <p class="lh-lg font-9-rem ">
      Eternal Strings and/or erternalstrings.com  is a trademark of Jub We Met Inc.      
    </p>

























  </div>


</section>


<app-footer></app-footer>