<div class="special-someone pt-3 bg-white pb-4">
  <div class="container">
    <h2 class="pt-4 text-center">Success Stories</h2>
    <p class="text-center head-p">Beautiful memories</p>
    <div class="seperator-line centered"></div>
    <div *ngIf="!inboxLoader">
      <div class="row mt-5 g-4  owl-carousel">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let item of carouselItems;let i = index">
            <ng-template carouselSlide>
              <div class="item cursor-pointer">
                <div (click)="openStory(i)" class="col-md-4_rm " data-bs-toggle="modal" data-bs-target="#storiesModal0">
                  <div class="card mycard">
                    <!-- <div  *ngIf="item.success_story_image == null || item.success_story_image == ''" class="ratio-1 overflow-hidden">
                      <img src="{{item.blog_media[0].media_path}}" alt="" class="image-cover card-img-top">
                    </div>
                    <div *ngIf="item.success_story_image !== null || item.success_story_image !== ''" class="ratio-1 overflow-hidden">
                      <img src="{{item.success_story_image}}" alt="" class="image-cover card-img-top">
                    </div> -->

                    <div class="ratio-1 overflow-hidden">
                      <ng-container *ngIf="item.success_story_image == null || item.success_story_image == ''">
                        <img src="{{item.blog_media[0].media_path}}" alt="Description of couple image"
                          title="Couple Image" class="image-cover card-img-top">
                      </ng-container>
                      <!-- </div> -->
                      <!-- <div  class="ratio-1 overflow-hidden"> -->
                      <ng-container *ngIf="item.success_story_image != null || item.success_story_image != ''">
                        <img *ngIf="item?.success_story_image_thumb == null || item?.success_story_image_thumb == ''"
                          src="{{item.success_story_image}}" alt="Description of couple image" title="Couple Image"
                          class="image-cover card-img-top">
                        <img *ngIf="item?.success_story_image_thumb != null || item?.success_story_image_thumb != ''"
                          src="{{item.success_story_image_thumb}}" alt="Description of couple image"
                          title="Couple Image" class="image-cover card-img-top">
                      </ng-container>
                    </div>

                    <div class="card-body text-center">
                      <h5 class="card-title text-danger hover-scale" data-bs-toggle="modal"
                        data-bs-target="#storiesModal0" [title]="item?.blog_title">{{item.blog_title |
                          limit : 30  }}</h5>
                      <div style="
                      height: 23px;">
                        <a *ngIf="item.location!='' && item.location!= 'null'" href="javascript:void(0);"
                          class="card-link text-dark">
                          {{item.location}}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
    <div class="row mt-5 g-4" *ngIf="inboxLoader">
      <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let item of [0,1,2,3]">
          <ng-template carouselSlide>
            <div class="item cursor-pointer">
              <div class="col-md-4_rm animated-background">
                <div class="card mycard">
                  <div class="ratio-1 overflow-hidden">
                    <img src="assets/images/default-profile-picture.svg" alt="Description of default image"
                      title="Default Image" class="image-cover card-img-top animated-background-name">
                  </div>

                  <div class="card-body text-center">
                    <!-- <h5 class="card-title text-danger hover-scale" </h5> -->
                    <span class="blank-box mt-3 animated-background-name"></span>
                    <div style="
                      height: 23px;">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>

    <div class="row center" *ngIf="carouselItems.length==0 && (!inboxLoader)"
      style="min-height: calc(123vh - 550px);text-align: center">
      <!-- <strong>{{message}}</strong> -->
      <strong>no story found</strong>
    </div>

  </div>
</div>

<div class="modal fade" id="storiesModal0" tabindex="-1" aria-labelledby="storiesModal1Label" aria-hidden="true">
  <div class="modal-dialog width-90 modal-fullscreen1 modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-1000 rounded-10 position-relative overflow-hidden">
      <div class="modal-header text-white bg-maroon border-0 pt-3 pb-1">
        <h6 class="modal-title">Success Stories: Beautiful memories</h6>
        <button type="button" class="btn position-absolute top-0 right-0 m-2 center w-30-h-30 white-cross"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>
      </div>
      <div class="modal-body p-0 col-12 px-0 mb-3 px-3 row">
        <div class="col-md-5 col-sm-12 story_wpr-new_rm mt-3">
          <!-- <div class="ratio-1 overflow-hidden"> -->
          <!-- <img *ngIf="coupleImg2 == null || coupleImg2 == '' " src="{{coupleImg}}" alt="Description of couple image" title="Couple Image" class="image-cover-new card-img-top">
              <img *ngIf="coupleImg2 != null || coupleImg2 != ''" src="{{coupleImg2}}" alt="Description of couple image" title="Couple Image" class="image-cover-new card-img-top"> -->

          <ng-container *ngIf="!coupleImg2 || coupleImg2 == null || coupleImg2 == ''">
            <img src="{{coupleImg}}" alt="Description of couple image" title="Couple Image"
              class="image-cover-new card-img-top">
          </ng-container>

          <ng-container *ngIf="coupleImg2 && coupleImg2 != null && coupleImg2 != ''">
            <img src="{{coupleImg2}}" alt="Description of couple image1" title="Couple Image"
              class="image-cover-new card-img-top">
          </ng-container>

          <!-- </div> -->
        </div>
        <div class="col-md-7 col-sm-12 story_wpr mt-3">
          <div class="clearfix">
            <p><b>{{title}}</b></p>
            <p *ngIf="location!='' && location!='null' " class="float-right">{{location}}</p>
          </div>
          <!-- <div class="mt-3" [innerHTML]="story | limit: limitStory">
            </div> -->

          <div class="lh-base pb-3 text-start right-margin" *ngIf="carouselItems[couple]?.sanitizedContent"
            [innerHTML]="carouselItems[couple].sanitizedContent"></div>

          <!-- <ng-container *ngIf="story.length>limitStory">
              <a href="javascript:void(0)" class="text-danger" *ngIf="limitStory == defaultStoryLen" (click)="readMore()">read
                more</a>
            </ng-container>
              <a href="javascript:void(0)" class="text-danger" *ngIf="limitStory > defaultStoryLen" (click)="readLess()">read
                  less</a> -->
        </div>
      </div>
      <div class="modal-footer row mx-0 py-1 bg-maroon w-100">
        <div class="col-12 ">
          <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
              class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                class="fas fa-long-arrow-alt-left"></i> Go
              to eternalstrings.com</a> </p>
        </div>
      </div>
    </div>
  </div>
</div>
