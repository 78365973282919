<div class="bg-maroon w-100">
  <app-login-header></app-login-header>
</div>

<div class="container border border-1 shadow-sm my-4 p-0 position-relative">
  <nav class="position-sticky z-index-60 top-70">
    <div class="nav nav-tabs d-flex align-items-center justify-content-between p-2 bg-warning position-sticky"
      id="nav-tab" role="tablist">
      <button class="my-2 py-2 px-3 btn-outline-danger active rounded-0 border-0 flex-auto rounded-pill mx-1"
        id="nav-dashboard-tab" data-bs-toggle="tab" data-bs-target="#nav-dashboard" type="button" role="tab"
        aria-controls="nav-dashboard" aria-selected="true" (click)="getAllRequest()">
        Inbox ({{ profileList.length }})
      </button>

      <button class="my-2 py-2 px-3 btn-outline-danger rounded-0 border-0 flex-auto rounded-pill mx-1" id="nav-home-tab"
        data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
        aria-selected="true" (click)="getSentRequest()">
        Sent ({{ sendprofileList.length }})
      </button>

      <button class="my-2 py-2 px-3 btn-outline-danger rounded-0 border-0 flex-auto rounded-pill mx-1"
        id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab"
        aria-controls="nav-profile" aria-selected="false" (click)="getFollowUpRequest()">
        Follow Up ({{FollowUpProfileList.length}})
      </button>

      <button class="my-2 py-2 px-3 btn-outline-danger rounded-0 border-0 flex-auto rounded-pill mx-1"
        id="nav-cancelled-tab" data-bs-toggle="tab" data-bs-target="#nav-cancelled" type="button" role="tab"
        aria-controls="nav-cancelled" aria-selected="false" (click)="getCancelledRequest()">
        Cancelled ({{ cancelledprofileList.length }})
      </button>

      <button class="my-2 py-2 px-3 btn-outline-danger rounded-0 border-0 flex-auto rounded-pill mx-1" id="nav- ed-tab"
        data-bs-toggle="tab" data-bs-target="#nav-blocked" type="button" role="tab" aria-controls="nav-blocked"
        aria-selected="false" (click)="blocked_List()">
        Blocked ({{ blockedList.length }})
      </button>
    </div>
  </nav>

  <div class="tab-content p-3 bg-white" id="nav-tabContent">
    <!-- -----------------------start-First tabs----------------- -->
    <div class="tab-pane fade show active" id="nav-dashboard" role="tabpanel" aria-labelledby="nav-dashboard-tab">
      <div class="row">
        <div class="col-12">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Inbox
          </h5>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-6">
          <p class="mb-0 fw-bold text-danger">
            All Requests ({{ profileList.length }})
          </p>
        </div>
        <div class="col-6 text-end">
          <div class="dropdown">
            <div class="btn btn-outline-danger btn-sm rounded-3" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fas fa-filter"></i> Filters
            </div>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: Filters == 'AllRequests' }" id=""
                (click)="FiltersBy($event, 'AllRequests')">
                All Requests
              </li>
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: Filters == 'received' }" id="received"
                (click)="FiltersBy($event, 'received')">
                <i class="fas fa-duotone fa-inbox"></i> received
              </li>
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: Filters == 'accept_by_me' }" id="accept_by_me"
                (click)="FiltersBy($event, 'accept_by_me')">
                <i class="fas fa-light fa-envelope-open"></i> Accepted by me
              </li>
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: Filters == 'accept_by_them' }" id="accept_by_them"
                (click)="FiltersBy($event, 'accept_by_them')">
                <i class="fas fa-envelope-open-text"></i> Accepted by them
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div class="row" *ngIf="profileList.length> 0">
          <div class="col-12 col-sm-6 col-lg-6 my-3" *ngFor="let profile of profileList; let i= index">
            <div class="row shadow-sm border border-1 bg-light h-100 mx-0 overflow-hidden position-relative">
              <div class="col-12 col-lg-4 px-0">
                <div class="ratio-1 card-image position-relative">
                  <img class="image-cover" *ngIf="!profile?.profile_photo" src="assets/images/banner-7.jpg" alt="" />
                  <img src="{{ profile?.profile_photo }}" alt="" *ngIf="profile?.profile_photo"
                    class="card-img-top image-cover" alt="assets/images/banner-7.jpg" />
                  <button class="position-absolute w-30-h-30 center btn-blue rounded-circle top-0 left-0 m-2 border-0"
                    type="button" data-bs-toggle="modal" data-bs-target="#dateLogModal" placement="bottom"
                    ngbTooltip="History" (click)="getHistoryData(profile.user_id)">
                    <i class="fas fa-info"></i>
                  </button>
                </div>
              </div>
              <div class="col-12 col-lg-8 py-2">
                <div class="row h-100">
                  <div class="col-12 h-100 d-flex flex-column">
                    <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0 card-nav">
                      <div class="col-8 px-0">
                        <h6 class="text-danger fw-bold mb-1" *ngIf="!!profile.display_name">
                          {{ profile.display_name }}
                        </h6>
                        <h6 class="text-danger fw-bold mb-1" *ngIf="!profile.display_name">
                          {{ profile.unique_key }}
                        </h6>

                        <div class="d-flex align-items-center">
                          <div class="p-xsm mb-0 text-success" *ngIf="profile.is_logged_in">
                            <div class="flash d-inline-block me-1">
                              <div class="">
                                <i class="fas fa-globe"></i>
                              </div>
                            </div>
                            <div class="d-inline-block">Online</div>
                          </div>
                          <div class="p-xsm mb-0 text-success" *ngIf="!profile.is_logged_in">
                            <div class="flash d-inline-block me-1">
                              <div class="">
                                <i class="fas fa-globe" style="color: grey"></i>
                              </div>
                            </div>
                            <div class="d-inline-block">Offline</div>
                          </div>

                          <div class="ms-2">
                            <img src="assets/images/icon/{{
                              profile.package_icon
                              }}" class="width-30" alt="" placement="top" ngbTooltip="Elite Member"
                              *ngIf="profile.package_id == 3" />
                            <img src="assets/images/icon/{{
                              profile.package_icon
                              }}" class="width-30" alt="" placement="top" ngbTooltip="Premium Member"
                              *ngIf="profile.package_id == 2" />
                          </div>
                        </div>
                      </div>

                      <div class="col-4 px-0 text-end">
                        <div class="d-flex align-items-center justify-content-end">
                          <div class="me-2 d-inline-block">
                            <button *ngIf="
                              user_id != profile.profile_id &&
                              profile.is_accepted == 1
                              " type="button" placement="bottom" ngbTooltip="Accepted by them"
                              class="w-30-h-30 center btn-blue rounded-circle border-0">
                              <i class="fas fa-envelope-open-text"></i>
                            </button>

                            <button *ngIf="
                              user_id == profile.profile_id &&
                              profile.is_accepted == 1
                              " type="button" placement="bottom" ngbTooltip="Accepted by me"
                              class="w-30-h-30 center btn-blue rounded-circle border-0">
                              <i class="fas fa-light fa-envelope-open"></i>
                            </button>

                            <button *ngIf="
                              user_id == profile.profile_id &&
                              profile.is_accepted == 0
                              " type="button" placement="bottom" ngbTooltip="Received"
                              class="w-30-h-30 center btn-blue rounded-circle border-0">
                              <i class="fas fa-duotone fa-inbox"></i>
                            </button>
                          </div>
                          <div class="dropdown d-inline-block">
                            <div class="ms-auto center btn w-30-h-30 rounded-circle btn-danger border-0" type="button"
                              id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="fas fa-ellipsis-v"></i>
                            </div>
                            <ul class="dropdown-menu py-1 border border-1 border-danger"
                              aria-labelledby="dropdownMenuButton1">
                              <li *ngIf="
                                user_id == profile.profile_id &&
                                (profile.is_accepted != 1  && profile.package_id>1)
                                " placement="top" data-bs-toggle="modal" data-bs-target="#RespondModal"
                                ngbTooltip="Connect Now" (click)="getRespondData(profile.user_id)"
                                class="dropdown-item p-sm px-2" id="Respond">
                                <i class="fas fa-reply"></i> Respond
                              </li>
                              <li *ngIf="
                                user_id == profile.profile_id &&
                                (profile.is_accepted != 1 && profile.package_id==1 ) 
                                " placement="top" data-bs-toggle="modal" data-bs-target="#upgradeModal"
                                ngbTooltip="Connect Now" (click)="getRespondData(profile.user_id)"
                                class="dropdown-item p-sm px-2" id="Respond">
                                <i class="fas fa-reply"></i> Respond
                              </li>
                              <li
                                *ngIf="user_id == profile.profile_id && (profile.is_accepted==0 && profile.is_declined==0)"
                                (click)="
                                doActionOnRequests('decline', profile.user_id)
                                " class="dropdown-item p-sm px-2" id="Decline">
                                <i class="fas fa-ban"></i> Decline
                              </li>
                              <li (click)="
                                doActionOnRequests('block', profile.user_id)
                                " class="dropdown-item p-sm px-2" id="Block">
                                <i class="fas fa-user-lock"></i> Block
                              </li>
                              <li (click)="
                                doActionOnRequests(
                                'block_and_report',
                                profile.user_id
                                )
                                " class="dropdown-item p-sm px-2" id="Block&Report">
                                <i class="fas fa-user-clock"></i> Block & Report
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row py-2">
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile.dob | ageConvertPipe }},
                          {{ profile?.height | heightConverter : "Ft" }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.working_as | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.marital_status }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.current_city | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.religion | limit : 18
                          }}<span *ngIf="profile.caste">, {{ profile?.caste | limit : 18 }}</span>
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.stateName | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.living_in_country_name | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-12">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile.description | limit : 75 }} &nbsp;
                            <a href="javascript:void(0)" (click)="ViewProfile(profile.user_id)" target="_blank"
                            class="text-danger" rel="noopener">
                            View Profile</a>
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div *ngIf="profileList.length == 0" class="text-center" style="min-height: calc(125vh - 550px)">
        <strong>No data available</strong>
      </div>
      <div class="row mt-3">
        <div *ngIf="TotalRecord> 0" class="col-12 justify-content-center">
          <mat-paginator #paginator [pageSizeOptions]="[8]" [length]="TotalRecord" showFirstLastButtons
            [hidePageSize]="hidePageSize" aria-label="Select page of periodic elements" (page)="handlePage($event)"
            [pageSize]="pageSize">
          </mat-paginator>
        </div>
      </div>
    </div>
    <!------------------------ end-First-tab ----------------------->

    <!-- -----------------------start-Second tabs----------------- -->
    <div class="tab-pane fade" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
      <div class="row">
        <div class="col-12">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Sent
          </h5>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <p class="mb-0 fw-bold text-danger">
            All Requests ({{ sendprofileList.length }})
          </p>
        </div>
        <div class="col-6 text-end">
          <div class="dropdown">
            <div class="btn btn-outline-danger px-2 rounded-3 py-0" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fas fa-filter"></i> Filters
            </div>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: sendFilters == 'All' }" id=""
                (click)="sendFiltersBy($event, 'All')">
                All
              </li>
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: sendFilters == 'viewed' }" id="viewed"
                (click)="sendFiltersBy($event, 'viewed')">
                <i class="fas fa-eye"></i> Viewed
              </li>
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: sendFilters == 'not_viewed' }" id="not_viewed"
                (click)="sendFiltersBy($event, 'not_viewed')">
                <i class="fas fa-eye-slash"></i> Not Viewed
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div *ngIf="sendprofileList.length == 0" class="text-center" style="min-height: calc(125vh - 550px)">
          <strong>No data available</strong>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-lg-6 my-3" *ngFor="let profile of sendprofileList; let i= index">
            <div class="row shadow-sm border border-1 bg-light h-100 mx-0 overflow-hidden position-relative">
              <div class="col-12 col-lg-4 px-0">
                <div class="ratio-1 card-image position-relative">
                  <img class="image-cover" src="assets/images/banner-7.jpg" *ngIf="!profile?.profile_photo" alt="" />
                  <img src="{{ profile?.profile_photo }}" *ngIf="profile?.profile_photo"
                    class="card-img-top image-cover" alt="assets/images/banner-7.jpg" />

                  <button class="position-absolute w-30-h-30 center btn-blue rounded-circle top-0 left-0 m-2 border-0"
                    type="button" data-bs-toggle="modal" data-bs-target="#dateLogModal" placement="bottom"
                    ngbTooltip="History" (click)="getHistoryData(profile.user_id)">
                    <i class="fas fa-info"></i>
                  </button>
                </div>
              </div>
              <div class="col-12 col-lg-8 py-2">
                <div class="row h-100">
                  <div class="col-12 h-100 d-flex flex-column">
                    <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0 card-nav">
                      <div class="col-8 px-0">
                        <h6 class="text-danger fw-bold mb-1" *ngIf="!!profile.display_name">
                          {{ profile.display_name }}
                        </h6>
                        <h6 class="text-danger fw-bold mb-1" *ngIf="!profile.display_name">
                          {{ profile.unique_key }}
                        </h6>

                        <div class="d-flex align-items-center">
                          <div class="p-xsm mb-0 text-success" *ngIf="profile.is_logged_in">
                            <div class="flash d-inline-block me-1">
                              <div class="">
                                <i class="fas fa-globe"></i>
                              </div>
                            </div>
                            <div class="d-inline-block">Online</div>
                          </div>
                          <div class="p-xsm mb-0 text-success" *ngIf="!profile.is_logged_in">
                            <div class="flash d-inline-block me-1">
                              <div class="">
                                <i class="fas fa-globe" style="color: grey"></i>
                              </div>
                            </div>
                            <div class="d-inline-block">Offline</div>
                          </div>

                          <div class="ms-2">
                            <img src="assets/images/icon/{{
                              profile.package_icon
                              }}" class="width-30" alt="" placement="top" ngbTooltip="Elite Member"
                              *ngIf="profile.package_id == 3" />
                            <img src="assets/images/icon/{{
                              profile.package_icon
                              }}" class="width-30" alt="" placement="top" ngbTooltip="Premium Member"
                              *ngIf="profile.package_id == 2" />
                          </div>
                        </div>
                      </div>

                      <div class="col-4 px-0 text-end">
                        <div class="d-flex align-items-center justify-content-end">
                          <div class="me-2 d-inline-block">
                            <button *ngIf="!!profile.viewed_by" type="button" placement="bottom" ngbTooltip="Viewed"
                              class="w-30-h-30 center btn-blue rounded-circle border-0">
                              <i class="fas fa-eye"></i>
                            </button>

                            <button *ngIf="!profile.viewed_by" type="button" placement="bottom" ngbTooltip="Not Viewed"
                              class="w-30-h-30 center btn-blue rounded-circle border-0">
                              <i class="fas fa-eye-slash"></i>
                            </button>
                          </div>

                          <div class="dropdown">
                            <div class="ms-auto center btn w-30-h-30 rounded-circle btn-danger border-0" type="button"
                              id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="fas fa-ellipsis-v"></i>
                            </div>
                            <ul class="dropdown-menu py-1 border border-1 border-danger"
                              aria-labelledby="dropdownMenuButton1">
                              <li class="dropdown-item p-sm px-2" (click)="
                                doActionOnRequestsSend(
                                'cancel',
                                profile.user_id
                                )
                                " id="cancel">
                                <i class="fas fa-times-circle"></i> Cancel
                              </li>
                              <li class="dropdown-item p-sm px-2" (click)="
                                doActionOnRequestsSend(
                                'nudge',
                                profile.user_id
                                )
                                " id="nudge">
                                Nudge
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row py-2">
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile.dob | ageConvertPipe }},
                          {{ profile?.height | heightConverter : "Ft" }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.working_as | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.marital_status }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.current_city | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.religion | limit : 18
                          }}<span *ngIf="profile.caste">, {{ profile?.caste | limit : 18 }}</span>
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.stateName | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.living_in_country_name | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-12">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile.description | limit : 75 }} &nbsp;
                            <a href="javascript:void(0)" (click)="ViewProfile(profile.user_id)" target="_blank"
                            class="text-danger" rel="noopener">View Profile</a>
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div *ngIf="TotalRecord> 0" class="col-12 justify-content-center">
            <mat-paginator #paginator [pageSizeOptions]="[8]" [length]="TotalRecord" showFirstLastButtons
              [hidePageSize]="hidePageSize" aria-label="Select page of periodic elements" (page)="handlePage($event)"
              [pageSize]="pageSize">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>

    <!-- -----------------------End-Second tabs----------------- -->

    <!-- -----------------------Start-Third tabs----------------- -->
    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
      <div class="row">
        <div class="col-12">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Follow-Up
          </h5>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-6">
          <p class="mb-0 fw-bold text-danger">
            All Requests ({{ FollowUpProfileList.length }})
          </p>
        </div>
        <div class="col-6 text-end">
          <div class="dropdown">
            <div class="btn btn-outline-danger px-2 rounded-3 py-0" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fas fa-filter"></i> Filters
            </div>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: follow_upFilters == 'follow_up' }" id=""
                (click)="follow_upFiltersBy($event, 'follow_up')">
                <i class="fas fa-question-circle"></i> Follow-Up
              </li>
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: follow_upFilters == 'cancelled' }" id="cancelled"
                (click)="follow_upFiltersBy($event, 'cancelled')">
                Cancelled
              </li>
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: follow_upFilters == 're_submit' }" id="re_submit"
                (click)="follow_upFiltersBy($event, 're_submit')">
                <i class="fas fa-retweet"></i> Re-Submit
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <div class="row" *ngIf="FollowUpProfileList.length> 0">
          <div class="col-12 col-sm-6 col-lg-6 my-3" *ngFor="let profile of FollowUpProfileList; let i= index">
            <div class="row shadow-sm border border-1 bg-light h-100 mx-0 overflow-hidden position-relative">
              <div class="col-12 col-lg-4 px-0">
                <div class="ratio-1 card-image position-relative">
                  <img class="image-cover" src="assets/images/banner-7.jpg" *ngIf="!profile?.profile_photo" alt="" />
                  <img src="{{ profile?.profile_photo }}" *ngIf="profile?.profile_photo"
                    class="card-img-top image-cover" alt="assets/images/banner-7.jpg" />

                  <button class="position-absolute w-30-h-30 center btn-blue rounded-circle top-0 left-0 m-2 border-0"
                    type="button" data-bs-toggle="modal" data-bs-target="#dateLogModal" placement="bottom"
                    ngbTooltip="History" (click)="getHistoryData(profile.user_id)">
                    <i class="fas fa-info"></i>
                  </button>
                </div>
              </div>
              <div class="col-12 col-lg-8 py-2">
                <div class="row h-100">
                  <div class="col-12 h-100 d-flex flex-column">
                    <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0 card-nav">
                      <div class="col-8 px-0">
                        <h6 class="text-danger fw-bold mb-1" *ngIf="!!profile.display_name">
                          {{ profile.display_name }}
                        </h6>
                        <h6 class="text-danger fw-bold mb-1" *ngIf="!profile.display_name">
                          {{ profile.unique_key }}
                        </h6>

                        <div class="d-flex align-items-center">
                          <div class="p-xsm mb-0 text-success" *ngIf="profile.is_logged_in">
                            <div class="flash d-inline-block me-1">
                              <div class="">
                                <i class="fas fa-globe"></i>
                              </div>
                            </div>
                            <div class="d-inline-block">Online</div>
                          </div>
                          <div class="p-xsm mb-0 text-success" *ngIf="!profile.is_logged_in">
                            <div class="flash d-inline-block me-1">
                              <div class="">
                                <i class="fas fa-globe" style="color: grey"></i>
                              </div>
                            </div>
                            <div class="d-inline-block">Offline</div>
                          </div>

                          <div class="ms-2">
                            <img src="assets/images/icon/{{
                              profile.package_icon
                              }}" class="width-30" alt="" placement="top" ngbTooltip="Elite Member"
                              *ngIf="profile.package_id == 3" />
                            <img src="assets/images/icon/{{
                              profile.package_icon
                              }}" class="width-30" alt="" placement="top" ngbTooltip="Premium Member"
                              *ngIf="profile.package_id == 2" />
                          </div>
                        </div>
                      </div>

                      <div class="col-4 px-0 text-end">
                        <div class="d-flex align-items-center justify-content-end">
                          <div class="me-2 d-inline-block">
                            <button type="button" placement="bottom" ngbTooltip="Follow up"
                              class="w-30-h-30 center btn-blue rounded-circle border-0">
                              <i class="fas fa-question"></i>
                            </button>
                          </div>

                          <div class="dropdown">
                            <div class="ms-auto center btn w-30-h-30 rounded-circle btn-danger border-0" type="button"
                              id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="fas fa-ellipsis-v"></i>
                            </div>
                            <ul class="dropdown-menu py-1 border border-1 border-danger"
                              aria-labelledby="dropdownMenuButton1">
                              <li class="dropdown-item p-sm px-2" (click)="
                                doActionOnRequestsFollow(
                                'follow_cancel',
                                profile.user_id
                                )
                                " id="follow_cancel">
                                <i class="fas fa-times-circle"></i> Cancel
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row py-2">
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile.dob | ageConvertPipe }},
                          {{ profile?.height | heightConverter : "Ft" }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.working_as | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.marital_status }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.current_city | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.religion | limit : 18
                          }}<span *ngIf="profile.caste">, {{ profile?.caste | limit : 18 }}</span>
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.stateName | limit : 20 }}
                        </p>
                      </div>

                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.living_in_country_name | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-12">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile.description | limit : 75 }} &nbsp;
                            <a href="javascript:void(0)" (click)="ViewProfile(profile.user_id)" target="_blank"
                            class="text-danger" rel="noopener">View Profile</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="FollowUpProfileList.length  == 0" class="text-center" style="min-height: calc(125vh - 550px)">
          <strong>No data available</strong>
        </div>
        <div class="row mt-3">
          <div *ngIf="TotalRecord> 0" class="col-12 justify-content-center">
            <mat-paginator #paginator [pageSizeOptions]="[8]" [length]="TotalRecord" showFirstLastButtons
              [hidePageSize]="hidePageSize" aria-label="Select page of periodic elements" (page)="handlePage($event)"
              [pageSize]="pageSize">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>

    <!-- -----------------------end-Third tabs---------------- -->

    <!-- -----------------------Start-Fourth tabs----------------- -->
    <div class="tab-pane fade" id="nav-cancelled" role="tabpanel" aria-labelledby="nav-cancelled-tab">
      <div class="row">
        <div class="col-12">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Cancelled
          </h5>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <p class="mb-0 fw-bold text-danger">
            All Requests ({{ cancelledprofileList.length }})
          </p>
        </div>
        <div class="col-6 text-end">
          <div class="dropdown">
            <div class="btn btn-outline-danger px-2 rounded-3 py-0" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fas fa-filter"></i> Filters
            </div>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: cancelledFilters == 'AllCancelled' }" id=""
                (click)="cancelledFiltersBy($event, 'AllCancelled')">
                All
              </li>
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: cancelledFilters == 'cancelled_by_me' }"
                id="cancelled_by_me" (click)="cancelledFiltersBy($event, 'cancelled_by_me')">
                <i class="fas fa-user-minus"></i> Cancelled by Me
              </li>
              <li class="p-sm dropdown-item" [ngClass]="{
                Filters: cancelledFilters == 'automatically_cancelled'
                }" id="automatically_cancelled" (click)="cancelledFiltersBy($event, 'automatically_cancelled')">
                <i class="fas fa-user-slash"></i> Automatically Cancelled
              </li>
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: cancelledFilters == 'cancelled_by_them' }"
                id="cancelled_by_them" (click)="cancelledFiltersBy($event, 'cancelled_by_them')">
                <i class="fas fa-user-times"></i> Cancelled by them
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <div class="row">
          <div class="col-12 col-sm-6 col-lg-6 my-3" *ngFor="let profile of cancelledprofileList; let i= index">
            <div class="row shadow-sm border border-1 bg-light h-100 mx-0 overflow-hidden position-relative">
              <div class="col-12 col-lg-4 px-0">
                <div class="ratio-1 card-image position-relative">
                  <img class="image-cover" src="assets/images/banner-7.jpg" *ngIf="!profile?.profile_photo" alt="" />
                  <img src="{{ profile?.profile_photo }}" *ngIf="profile?.profile_photo"
                    class="card-img-top image-cover" alt="assets/images/banner-7.jpg" />

                  <button class="position-absolute w-30-h-30 center btn-blue rounded-circle top-0 left-0 m-2 border-0"
                    type="button" data-bs-toggle="modal" data-bs-target="#dateLogModal" placement="bottom"
                    ngbTooltip="History" (click)="getHistoryData(profile.user_id)">
                    <i class="fas fa-info"></i>
                  </button>
                </div>
              </div>
              <div class="col-12 col-lg-8 py-2">
                <div class="row h-100">
                  <div class="col-12 h-100 d-flex flex-column">
                    <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0 card-nav">
                      <div class="col-8 px-0">
                        <h6 class="text-danger fw-bold mb-1" *ngIf="!!profile.display_name">
                          {{ profile.display_name }}
                        </h6>
                        <h6 class="text-danger fw-bold mb-1" *ngIf="!profile.display_name">
                          {{ profile.unique_key }}
                        </h6>

                        <div class="d-flex align-items-center">
                          <div class="p-xsm mb-0 text-success" *ngIf="profile.is_logged_in">
                            <div class="flash d-inline-block me-1">
                              <div class="">
                                <i class="fas fa-globe"></i>
                              </div>
                            </div>
                            <div class="d-inline-block">Online</div>
                          </div>
                          <div class="p-xsm mb-0 text-success" *ngIf="!profile.is_logged_in">
                            <div class="flash d-inline-block me-1">
                              <div class="">
                                <i class="fas fa-globe" style="color: grey"></i>
                              </div>
                            </div>
                            <div class="d-inline-block">Offline</div>
                          </div>

                          <div class="ms-2">
                            <img src="assets/images/icon/{{
                              profile.package_icon
                              }}" class="width-30" alt="" placement="top" ngbTooltip="Elite Member"
                              *ngIf="profile.package_id == 3" />
                            <img src="assets/images/icon/{{
                              profile.package_icon
                              }}" class="width-30" alt="" placement="top" ngbTooltip="Premium Member"
                              *ngIf="profile.package_id == 2" />
                          </div>
                        </div>
                      </div>

                      <div class="col-4 px-0 text-end">
                        <div class="d-flex align-items-center justify-content-end">
                          <div class="me-2 d-inline-block">
                            <button *ngIf="profile.cancelled_by == 'Me'" type="button" placement="bottom"
                              ngbTooltip="Cancelled by me" class="w-30-h-30 center btn-blue rounded-circle border-0">
                              <i class="fas fa-user-minus"></i>
                            </button>

                            <button *ngIf="profile.cancelled_by == 'Automatic'" type="button" placement="bottom"
                              ngbTooltip="Automatic Cancelled"
                              class="w-30-h-30 center btn-blue rounded-circle border-0">
                              <i class="fas fa-user-slash"></i>
                            </button>

                            <button *ngIf="profile.cancelled_by == 'Them'" type="button" placement="bottom"
                              ngbTooltip="Cancelled by them" class="w-30-h-30 center btn-blue rounded-circle border-0">
                              <i class="fas fa-user-times"></i>
                            </button>
                          </div>

                          <div class="dropdown">
                            <div class="ms-auto center btn w-30-h-30 rounded-circle btn-danger border-0" type="button"
                              id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="fas fa-ellipsis-v"></i>
                            </div>
                            <ul class="dropdown-menu py-1 border border-1 border-danger"
                              aria-labelledby="dropdownMenuButton1" *ngIf="profile.cancelled_by == 'Me'">
                              <li class="dropdown-item p-sm px-2" (click)="
                                doActionOnRequestscancelled(
                                'un_cancel',
                                profile.user_id
                                )
                                " id="un_cancel">
                                Un-Cancel
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row py-2">
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile.dob | ageConvertPipe }},
                          {{ profile?.height | heightConverter : "Ft" }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.working_as | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.marital_status }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.current_city | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.religion | limit : 18
                          }}<span *ngIf="profile.caste">, {{ profile?.caste | limit : 18 }}</span>
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.stateName | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.living_in_country_name | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-12">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile.description | limit : 75 }} &nbsp;
                            <a href="javascript:void(0)" (click)="ViewProfile(profile.user_id)" target="_blank"
                            class="text-danger" rel="noopener">View Profile</a>
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="cancelledprofileList.length == 0" class="text-center" style="min-height: calc(125vh - 550px)">
          <strong>No data available</strong>
        </div>
        <div class="row mt-3">
          <div *ngIf="TotalRecord> 0" class="col-12 justify-content-center">
            <mat-paginator #paginator [pageSizeOptions]="[8]" [length]="TotalRecord" showFirstLastButtons
              [hidePageSize]="hidePageSize" aria-label="Select page of periodic elements" (page)="handlePage($event)"
              [pageSize]="pageSize">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>

    <!-- -----------------------end-fourth tabs----------------- -->

    <!-- -----------------------Start-Fifth-tabs----------------- -->
    <div class="tab-pane fade" id="nav-blocked" role="tabpanel" aria-labelledby="nav-blocked-tab">
      <div class="row">
        <div class="col-12">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Blocked
          </h5>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-6">
          <p class="mb-0 fw-bold text-danger">
            All Requests ({{ blockedList.length }})
          </p>
        </div>
        <div class="col-6 text-end">
          <div class="dropdown">
            <div class="btn btn-outline-danger px-2 rounded-3 py-0" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fas fa-filter"></i> Filters
            </div>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: blockFilters == 'block_and_report' }"
                id="block_and_report" (click)="blockFiltersBy($event, 'block_and_report')">
                <i class="fas fa-user-clock"></i> Block & Report
              </li>
              <li class="p-sm dropdown-item" [ngClass]="{ Filters: blockFilters == 'unblock' }" id="unblock"
                (click)="blockFiltersBy($event, 'unblock')">
                <i class="fas fa-unlock-alt"></i> Unblock
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <div class="row" *ngIf="blockedList.length> 0">
          <div class="col-12 col-sm-6 col-lg-6 my-3" *ngFor="let profile of blockedList; let i= index">
            <div class="row shadow-sm border border-1 bg-light h-100 mx-0 overflow-hidden position-relative">
              <div class="col-12 col-lg-4 px-0">
                <div class="ratio-1 card-image position-relative">
                  <img class="image-cover" src="assets/images/banner-7.jpg" *ngIf="!profile?.profile_photo" alt="" />
                  <img src="{{ profile?.profile_photo }}" alt="" *ngIf="profile?.profile_photo"
                    class="card-img-top image-cover" alt="assets/images/banner-7.jpg" />

                  <button class="position-absolute w-30-h-30 center btn-blue rounded-circle top-0 left-0 m-2 border-0"
                    type="button" data-bs-toggle="modal" data-bs-target="#dateLogModal" placement="bottom"
                    ngbTooltip="History" (click)="getHistoryData(profile.user_id)">
                    <i class="fas fa-info"></i>
                  </button>
                </div>
              </div>
              <div class="col-12 col-lg-8 py-2">
                <div class="row h-100">
                  <div class="col-12 h-100 d-flex flex-column">
                    <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0 card-nav">
                      <div class="col-8 px-0">
                        <h6 class="text-danger fw-bold mb-1" *ngIf="!!profile.display_name">
                          {{ profile.display_name }}
                        </h6>
                        <h6 class="text-danger fw-bold mb-1" *ngIf="!profile.display_name">
                          {{ profile.unique_key }}
                        </h6>

                        <div class="d-flex align-items-center">
                          <div class="p-xsm mb-0 text-success" *ngIf="profile.is_logged_in">
                            <div class="flash d-inline-block me-1">
                              <div class="">
                                <i class="fas fa-globe"></i>
                              </div>
                            </div>
                            <div class="d-inline-block">Online</div>
                          </div>
                          <div class="p-xsm mb-0 text-success" *ngIf="!profile.is_logged_in">
                            <div class="flash d-inline-block me-1">
                              <div class="">
                                <i class="fas fa-globe" style="color: grey"></i>
                              </div>
                            </div>
                            <div class="d-inline-block">Offline</div>
                          </div>

                          <div class="ms-2">
                            <img src="assets/images/icon/{{
                              profile.package_icon
                              }}" class="width-30" alt="" placement="top" ngbTooltip="Elite Member"
                              *ngIf="profile.package_id == 3" />
                            <img src="assets/images/icon/{{
                              profile.package_icon
                              }}" class="width-30" alt="" placement="top" ngbTooltip="Premium Member"
                              *ngIf="profile.package_id == 2" />
                          </div>
                        </div>
                      </div>

                      <div class="col-4 px-0 text-end">
                        <div class="d-flex align-items-center justify-content-end">
                          <div class="me-2 d-inline-block">
                            <button *ngIf="profile.action == 'block'" type="button" placement="bottom"
                              ngbTooltip="Block" class="w-30-h-30 center btn-blue rounded-circle border-0">
                              <i class="fas fa-user-lock"></i>
                            </button>

                            <button *ngIf="profile.action == 'block_and_report'" type="button" placement="bottom"
                              ngbTooltip="Block & Report" class="w-30-h-30 center btn-blue rounded-circle border-0">
                              <i class="fas fa-user-clock"></i>
                            </button>

                            <button *ngIf="profile.action == 'un_block'" type="button" placement="bottom"
                              ngbTooltip="Unblock" class="w-30-h-30 center btn-blue rounded-circle border-0">
                              <i class="fas fa-unlock-alt"></i>
                            </button>
                          </div>

                          <div class="dropdown">
                            <div class="ms-auto center btn w-30-h-30 rounded-circle btn-danger border-0" type="button"
                              id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="fas fa-ellipsis-v"></i>
                            </div>
                            <ul class="dropdown-menu py-1 border border-1 border-danger"
                              aria-labelledby="dropdownMenuButton1">
                              <li class="dropdown-item p-sm px-2" (click)="
                                doActionOnRequestsBlocked(
                                'block_and_report',
                                profile.user_id
                                )
                                " id="block_and_report">
                                <i class="fas fa-user-clock"></i> Block & Report
                              </li>
                              <li class="dropdown-item p-sm px-2" (click)="
                                doActionOnRequestsBlocked(
                                'un_block',
                                profile.user_id
                                )
                                " id="un_block">
                                <i class="fas fa-unlock-alt"></i> Unblock
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row py-2">
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile.dob | ageConvertPipe }},
                          {{ profile?.height | heightConverter : "Ft" }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.working_as | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.marital_status }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.current_city | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.religion | limit : 18
                          }}<span *ngIf="profile.caste">, {{ profile?.caste | limit : 18 }}</span>
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.stateName | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-6">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile?.living_in_country_name | limit : 20 }}
                        </p>
                      </div>
                      <div class="col-12">
                        <p class="p-sm mb-2 text-muted">
                          {{ profile.description | limit : 75 }} &nbsp;
                            <a href="javascript:void(0)" (click)="ViewProfile(profile.user_id)" target="_blank"
                            class="text-danger" rel="noopener">View Profile</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="cancelledprofileList.length == 0" class="text-center" style="min-height: calc(125vh - 550px)">
          <strong>No data available</strong>
        </div>
        <div class="row mt-3">
          <div *ngIf="TotalRecord> 0" class="col-12 justify-content-center">
            <mat-paginator #paginator [pageSizeOptions]="[8]" [length]="TotalRecord" showFirstLastButtons
              [hidePageSize]="hidePageSize" aria-label="Select page of periodic elements" (page)="handlePage($event)"
              [pageSize]="pageSize">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>

    <!-- -----------------------end-Fifth-tabs----------------- -->
  </div>
  <!-- end tabs content -->
</div>
<app-login-footer></app-login-footer>

<!-- Modal -->
<div class="modal fade" id="dateLogModal" tabindex="-1" aria-labelledby="dateLogModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">
      <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-3 center w-30-h-30 z-index-99"
        data-bs-dismiss="modal" aria-label="Close">
        <i class="fas fa-times"></i>
      </button>

      <div class="modal-body p-4">
        <h5 class="mb-4 fw-600" id="dateLogModalLabel">Date Log</h5>

        <div class="row border border-2 border-danger border-end-0 border-top-0 border-bottom-0 mx-0 rounded">
          <div class="col-12 p-3 position-relative" *ngIf="isInbox &&
            user_id == historyProfileId">
            <h6 class="fw-600 mb-0">Inbox</h6>
            <p class="p-xsm mb-0"><small>{{isInbox_date}}</small></p>
            <div class="timeline-btn"></div>
          </div>

          <div class="col-12 p-3 position-relative" *ngIf="isSend &&
            user_id != historyProfileId">
            <h6 class="fw-600 mb-0">Sent</h6>
            <p class="p-xsm mb-0"><small>{{isSend_date}}</small></p>
            <div class="timeline-btn"></div>
          </div>

          <div class="col-12 p-3 position-relative" *ngIf="isFollowUp">
            <h6 class="fw-600 mb-0">Follow -up</h6>
            <p class="p-xsm mb-0"><small>{{isFollowUp_date}}</small></p>
            <div class="timeline-btn"></div>
          </div>

          <div class="col-12 p-3 position-relative" *ngIf="isCancelled">
            <h6 class="fw-600 mb-0">Cancelled</h6>
            <p class="p-xsm mb-0"><small>{{isCancelled_date}}</small></p>
            <div class="timeline-btn"></div>
          </div>

          <div class="col-12 p-3 position-relative" *ngIf="isBlock">
            <h6 class="fw-600 mb-0">Block</h6>
            <p class="p-xsm mb-0"><small>{{isBlock_date}}</small></p>
            <div class="timeline-btn"></div>
          </div>

          <div class="col-12 p-3 position-relative" *ngIf="isUnblock">
            <h6 class="fw-600 mb-0">Unblock</h6>
            <p class="p-xsm mb-0"><small>29/01/2000</small></p>
            <div class="timeline-btn"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="RespondModal" tabindex="-1" aria-labelledby="RespondModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">
      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <div class="w-150 h-150 mx-auto border border-1 border-danger rounded-3 mb-2">
          <img src="{{ RespondProfilephoto }}" alt="" class="image-cover p-1 rounded-3" />
        </div>
        <h5 class="text-danger mb-4">{{ Responddisplayname }}</h5>

        <p class="p-sm mb-4">
          Do you want to connect with {{ Responddisplayname }} ?”
        </p>
        <button class="btn btn-outline-danger" data-bs-dismiss="modal" aria-label="Close">
          No
        </button>
        <button class="btn btn-danger ms-2" data-bs-dismiss="modal"
          (click)="doActionOnRequestsRespond('accept', Responduserid)">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="upgradeModal" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo.svg" alt="" width="180" class="mb-4">

        <p class="p-sm mb-4">
          “Upgrade Now to start communicating with {{Responddisplayname}}.”
        </p>

        <button class="btn btn-danger rounded-pill " (click)="Upgrade('userid', user_id)">Upgrade Now
        </button>
      </div>

    </div>
  </div>
</div>