import { HttpHandler, HttpRequest, HttpInterceptor, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';


// @Injectable({
//     provideIn:'root' 
// })
@Injectable()
export class HttperrorInterceptorService {
  constructor(private toastr: ToastrService,
    private router: Router,
    private cookieService: CookieService,
    private spinner: NgxSpinnerService) { }

  intercept(request: HttpRequest<any>,next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
        if (error.status == 401 && error.error.message == 'Unauthenticated.') {
          this.spinner.hide();
          this.cookieService.deleteAll();

          localStorage.removeItem('package_id')
          localStorage.removeItem('register_id')
          localStorage.removeItem('Time-Zone')
          localStorage.removeItem('profile_ids')
          localStorage.removeItem('matchedProfilesCount')

          document.cookie = 'auth_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          if (this.router.url != '/home') {
            this.toastr.error("Session expired! Please login again.");
            setTimeout(() => {
              window.location.href = `${environment.baseURL}` + 'home';
            }, 1000);
          }
        } else if (error.error.message == 'Unauthenticated.') {
          this.spinner.hide();
          this.cookieService.deleteAll();

          localStorage.removeItem('package_id')
          localStorage.removeItem('register_id')
          localStorage.removeItem('Time-Zone')
          localStorage.removeItem('profile_ids')
          localStorage.removeItem('matchedProfilesCount')

          this.router.navigate(['home'])
          if (this.router.url != '/home') {
            this.toastr.error("Session expired! Please login again.");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        } else if (error.status == 404) {
          setTimeout(() => {
            this.spinner.hide();
            console.log("Something went wrong, Please try again later")
          }, 1000)
        } else if (error.status == 0 && error.statusText == 'Unknown Error') {
          setTimeout(() => {
            this.spinner.hide();
            console.log("Something went wrong, Please check your internet connection please network", error)
            console.log("Something went wrong, Please check your internet connection please network")
          }, 10000)
        } else if (error.status == 500 && error.statusText == 'OK') {
          setTimeout(() => {
            this.spinner.hide();
          }, 1000)
        } else {

           // unused code
          // var errors = error.error;
          // console.log('unkwon error!!', error.status, errors, typeof (errors))
          // if (error && errors && errors.email) {
          //   this.toastr.error('Error!', 'The email has already been taken');
          // } else if (error && errors && errors.mobileNo) {
          //   this.toastr.error('Error!', 'The mobile no has already been taken');
          // } else if (error && error.error) {
          //   const keys = Object.keys(errors);
          //   var erorrArray = [];
          //   for (const key of keys) {
          //     const value = errors[key];
          //     console.log(`Key: ${key}, Value: ${value}`);
          //     erorrArray.push(`${value}`);
          //   }
          //   this.toastr.error('Error!', erorrArray[0]);
          // }
          // this.toastr.error('Error!', 'Not Signup!');

        }
        return throwError(error.error)
      })
    ) as Observable<HttpEvent<any>>;
  }
}