<div class="w-100 bg-maroon">
  <app-login-header></app-login-header>
</div>
<div class="container mx-auto mx-width-800">

  <div class="accordion d-inline-block w-100" id="accordionPanelsStayOpenExample">
    
    <div class="shadow-sm mx-0 my-4 bg-white row py-4 rounded">
      <div class="col-12">
        <h5 class="text-danger text-center fw-bold mb-0">Edit Personal Profile</h5>
      </div>
    </div>

    <div class="accordion-item rounded-3 shadow-sm border-0  p-2">
      <div
        class="accordion-header py-0 rounded-3 border border-1  bg-light d-flex justify-content-between align-items-center"
        id="panelsStayOpen-headingOne">
        <div class="ps-1 fw-bold mb-0 fs-5 text-danger">
          Basic Info
        </div>
        <div class="border-0 px-2 py-2 accordion-btn" type="button" data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
          <i class="fas fa-sort-down fs-3 me-2"></i>
        </div>
      </div>
      <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
        aria-labelledby="panelsStayOpen-headingOne">
        <div class="accordion-body px-0">

          <div class="row mt-2">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Profile created
                by</label>
            </div>
            <div class="col-12 col-md-9">
              <select class="form-select" aria-label="Default select example" required>
                <option selected value="1">Select</option>
                <option value="1">Self</option>
                <option value="3">Brother</option>
                <option value="3">Daughter</option>
                <option value="3">Friend</option>
                <option value="3">Relative</option>
                <option value="3">Sister</option>
                <option value="2">Son</option>
              </select>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Gender</label>
            </div>
            <div class="col-12 col-md-9">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                  value="option1">
                <label class="form-check-label" for="inlineRadio1">Male</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                  value="option2">
                <label class="form-check-label" for="inlineRadio2">Female</label>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="Birth1" class="text-muted mb-1 mb-md-0 form-label">Date of Birth</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="date" class="form-control " id="Birth1" placeholder="Enter">
            </div>
          </div>
          <div class="row">
            <div class="col-3"></div>
            <div class="col-12 col-md-9">
              <p class="p-xsm mt-1 text-muted mb-0">Birth date changes may require an ID Proof. You would have limited
                access to your account till your ID is verified, which may take upto 8 hours</p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Marital Status</label>
            </div>
            <div class="col-12 col-md-9">
              <select class="form-select" aria-label="Default select example" required>
                <option value="volvo" selected>Select</option>
                <option value="3">Annulled</option>
                <option value="3"> Awaiting Divorce </option>
                <option value="2">Divorced</option>
                <option value="1">Single</option>
                <option value="3">Widowed</option>
              </select>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="height1" class="text-muted form-label mb-1 mb-md-0">Height <span class="p-xsm">(Feet and Inches)</span>
              </label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="height1" placeholder="Enter" required>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Health
                Information</label>
            </div>
            <div class="col-12 col-md-9">
              <select class="form-select" aria-label="Default select example" required>
                <option selected value="1">Select</option>
                <option value="3">No Health Problems</option>
                <option value="3">Diabetes</option>
                <option value="2">Heart Ailments</option>
                <option value="3">High BP</option>
                <option value="3">HIV positive</option>
                <option value="3">Low BP</option>
                <option value="3">Other</option>
              </select>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 col-md-3 my-auto">
            </div>
            <div class="col-12 col-md-9">
              <textarea class="form-control" id=" " rows="3"></textarea>
              <p class="p-xsm">Max characters. 100 Words</p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="Disability1" class="text-muted form-label mb-1 mb-md-0">Any Disability?</label>
            </div>
            <div class="col-12 col-md-9">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                <label class="form-check-label" for="inlineCheckbox1">None</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                <label class="form-check-label" for="inlineCheckbox2">Physical Disability </label>
              </div>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 col-md-3 my-auto">
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>



          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Blood Group</label>
            </div>
            <div class="col-12 col-md-9">
              <select class="form-select" aria-label="Default select example" required>
                <option selected value="1">Select</option>
                <option value="3">Don't Know</option>
                <option value="3">A+</option>
                <option value="3">A-</option>
                <option value="3">B+</option>
                <option value="3">B-</option>
                <option value="3">AB+</option>
                <option value="3">AB-</option>
                <option value="3">O+</option>
                <option value="3">O-</option>
              </select>
            </div>
          </div>

        </div>
      </div>
    </div>





    <div class="accordion-item rounded-3 shadow-sm mt-4 border-0 p-2">
      <div
        class="accordion-header py-0 rounded-3 border border-1 bg-light d-flex justify-content-between align-items-center"
        id="panelsStayOpen-headingTwo">
        <div class="ps-1 fw-bold mb-0 fs-5 text-danger"> Religious Background </div>
        <div class="border-0 px-2 py-2 accordion-btn" type="button" data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
          <i class="fas fa-sort-down fs-3 me-2"></i>
        </div>
      </div>
      <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show"
        aria-labelledby="panelsStayOpen-headingTwo">
        <div class="accordion-body px-0">

          <div class="row mt-2">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Religion</label>
            </div>
            <div class="col-12 col-md-9">
              <select class="form-select" aria-label="Default select example" required>
                <option selected>Select</option>
                <option value="3">Any</option>
                <option value="3">Buddhist</option>
                <option value="3">Christian</option>
                <option value="1">Hindu</option>
                <option value="3">Jain</option>
                <option value="2">Muslim</option>
                <option value="3">Parsi</option>
                <option value="3">Sikh</option>
                <option value="3">No Religion</option>
                <option value="3">Other</option>
              </select>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 col-md-3 my-auto">
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Language
                Preference</label>
            </div>
            <div class="col-12 col-md-9">
              <select class="form-select" aria-label="Default select example" required>
                <option selected>Select</option>
                <option value="3">Bengali</option>
                <option value="2">English</option>
                <option value="3">Gujarati</option>
                <option value="1">Hindi</option>
                <option value="3">Marathi</option>
                <option value="2">Punjabi</option>
                <option value="3">Tamil</option>
                <option value="3">Telugu</option>
                <option value="3">Other</option>
              </select>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 col-md-3 my-auto">
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>


          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Caste</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>


          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Sub Caste</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 col-md-3 my-auto">
            </div>
            <div class="col-12 col-md-9">
              <div class="form-check form-check-inline p-sm">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                <label class="form-check-label" for="inlineCheckbox1">Not particular about my Partner's Caste / Sect
                  (Caste No Bar)</label>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1"
                class="text-muted form-label mb-1 mb-md-0 d-flex align-items-center"> Gothra/Gothram <span
                  class="btn p-0 my-0 mx-2 border-0" placement="top"
                  Favorite="In Hindu society the term Gothra means clan. In almost all Hindu families, marriage within the same gothra is not preferred.">
                  <i class="fas fa-question-circle fs-5 p-0 m-0 text-danger"></i> </span></label>

            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>

        </div>
      </div>
    </div>




    <div class="accordion-item rounded-3 shadow-sm mt-4 border-0 p-2">
      <div
        class="accordion-header py-0 rounded-3 border border-1 bg-light d-flex justify-content-between align-items-center"
        id="panelsStayOpen-headingThree">
        <div class="ps-1 fw-bold mb-0 fs-5 text-danger"> Family</div>
        <div class="border-0 px-2 py-2 accordion-btn" type="button" data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="true"
          aria-controls="panelsStayOpen-collapseThree">
          <i class="fas fa-sort-down fs-3 me-2"></i>
        </div>
      </div>
      <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse show"
        aria-labelledby="panelsStayOpen-headingThree">
        <div class="accordion-body px-0">

          <div class="row mt-2">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label mb-1 mb-md-0">Father's Status</label>
            </div>
            <div class="col-12 col-md-9">
              <select class="form-select" id="FatherStatus" aria-label="Default select example" required>
                <option selected>Select</option>
                <option value="3">Business</option>
                <option value="3">Employed</option>
                <option value="3">Homemaker</option>
                <option value="3">Not Employed</option>
                <option value="1">Retired</option>
                <option value="2">Passed Away</option>
              </select>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label p-sm mb-1 mb-md-0">With</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label p-sm mb-1 mb-md-0">As</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label mb-1 mb-md-0">Mother's Status</label>
            </div>
            <div class="col-12 col-md-9">
              <select class="form-select" id="FatherStatus" aria-label="Default select example" required>
                <option selected>Select</option>
                <option value="3">Business</option>
                <option value="3">Employed</option>
                <option value="3">Homemaker</option>
                <option value="3">Not Employed</option>
                <option value="1">Retired</option>
                <option value="2">Passed Away</option>
              </select>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label p-sm mb-1 mb-md-0">With</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label p-sm mb-1 mb-md-0">As</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label mb-1 mb-md-0">Family Location</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 col-md-3 my-auto">
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label mb-1 mb-md-0">Native Place</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label mb-1 mb-md-0">No. of Siblings</label>
            </div>
            <div class="col-12 col-md-9">
              <div class="row row-cols-2 gx-5 text-center">
                <div class="col d-flex">
                  <div class="">
                    <div class="w-40 ms-auto border border-1 rounded-3">
                      <i class="fas fa-male fs-2 text-primary"></i>
                    </div>
                  </div>
                  <div class="mx-2">
                    <input type="text" class="form-control text-center" id="exampleFormControlInput1" placeholder="0">
                    <p class="p-sm mb-0">Not married</p>
                  </div>

                  <div class="">
                    <input type="text" class="form-control text-center" id="exampleFormControlInput1" placeholder="0">
                    <p class="p-sm mb-0">Married</p>
                  </div>
                </div>

                <div class="col d-flex">
                  <div class="">
                    <div class="w-40 ms-auto border border-1 rounded-3">
                      <i class="fas fa-female fs-2 text-danger"></i>
                    </div>
                  </div>
                  <div class="mx-2">
                    <input type="text" class="form-control text-center" id="exampleFormControlInput1" placeholder="0">
                    <p class="p-sm mb-0">Not married</p>
                  </div>

                  <div class="">
                    <input type="text" class="form-control text-center" id="exampleFormControlInput1" placeholder="0">
                    <p class="p-sm mb-0">Married</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Family Type</label>
            </div> 
            <div class="col-12 col-md-9">
              <div class="form-check form-check-inline ">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="FamilyType1" value="option1">
                <label class="form-check-label" for="FamilyType1">Nuclear  <span class="btn p-0 mx-1 my-0" placement="top"
                  Favorite="A couple and their dependent children, regarded as a basic social unit"><i
                    class="fas fa-question-circle fs-5 text-danger"></i></span></label>
              </div>
            
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="FamilyType2" value="option2">
                <label class="form-check-label" for="FamilyType2">Joint   <span class="btn p-0 mx-1 my-0" placement="top"
                  Favorite="An extended family, typically consisting of three or more generations and their spouses, living together as a single household."><i
                    class="fas fa-question-circle fs-5 text-danger"></i></span></label>
              </div> 
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Family values </label>
            </div>
            <div class="col-12 col-md-9">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="Familyvalues1"
                  value="option1">
                <label class="form-check-label" for="Familyvalues1">Traditional </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="Familyvalues2"
                  value="option2">
                <label class="form-check-label" for="Familyvalues2">Moderate </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="Familyvalues3"
                  value="option2">
                <label class="form-check-label" for="Familyvalues3">Liberal </label>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label mb-1 mb-md-0">Family Affluence</label>
            </div>
            <div class="col-12 col-md-9">
              <select class="form-select" id="FatherStatus" aria-label="Default select example" required>
                <option selected>Select</option>
                <option value="3">Affluent</option>
                <option value="2">Upper Middle Class</option>
                <option value="3">Middle Class</option>
                <option value="3">Lower Middle Class</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>





    <div class="accordion-item rounded-3 shadow-sm mt-4 border-0 p-2">
      <div
        class="accordion-header py-0 rounded-3 border border-1 bg-light d-flex justify-content-between align-items-center"
        id="panelsStayOpen-headingFourth">
        <div class="ps-1 fw-bold mb-0 fs-5 text-danger"> Astro Details </div>
        <div class="border-0 px-2 py-2 accordion-btn" type="button" data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseFourth" aria-expanded="true"
          aria-controls="panelsStayOpen-collapseFourth">
          <i class="fas fa-sort-down fs-3 me-2"></i>
        </div>
      </div>
      <div id="panelsStayOpen-collapseFourth" class="accordion-collapse collapse show"
        aria-labelledby="panelsStayOpen-headingFourth">
        <div class="accordion-body px-0">


          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="ManglikChevvaiDosham" class="text-muted form-label mb-1 mb-md-0"> Manglik <span
                  class="btn p-0 mx-1 my-0" placement="top"
                  Favorite="
                Manglik/ Chevvai Dosham is determined by the position of the stars at the time of your birth. In case of Hindu marriages it is generally preferred that a Manglik person marries a Manglik person."><i
                    class="fas fa-question-circle fs-5 text-danger"></i></span> </label>
            </div>
            <div class="col-12 col-md-9">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="FamilyType1" value="option1">
                <label class="form-check-label" for="FamilyType1">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="FamilyType2" value="option2">
                <label class="form-check-label" for="FamilyType2">No</label>
              </div>

            </div>
          </div>


          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="ManglikChevvaiDosham" class="d-flex text-muted form-label mb-1 mb-md-0">Nakshatra <span
                  class="btn p-0 mx-1 my-0" placement="top"
                  Favorite="A Nakshatra is one of 27 sectors along the ecliptic. Their names are related to the most prominent asterisms in the respective sectors."><i
                    class="fas fa-question-circle fs-5 text-danger"></i></span> </label>
            </div>
            <div class="col-12 col-md-9">
              <select class="form-select" aria-label="Default select example" required>
                <option selected>Select</option>
                <option value="3">Ardra</option>
                <option value="3">Anuradha</option>
                <option value="3">Ashwini</option>
                <option value="3">Aslesa</option>
                <option value="3">Bharani</option>
                <option value="3">Dhanistha</option>
                <option value="3">Hasta</option>
                <option value="3">Jyestha</option>
                <option value="3">Krittika</option>
                <option value="3">Magha</option>
                <option value="3">Mrigasira</option>
                <option value="3">Mula</option>
                <option value="3">Punarvasu</option>
                <option value="3">Purva Bhadrapada</option>
                <option value="3">Pusya</option>
                <option value="3">Purva Phalguni</option>
                <option value="3">Purva Ashadha</option>
                <option value="3">Revati</option>
                <option value="3">Rohini</option>
                <option value="3">Shravana</option>
                <option value="3">Shatabhishak</option>
                <option value="3">Swati</option>
                <option value="3">Uttara Ashadha</option>
                <option value="3">Uttara Bhadrapada</option>
                <option value="3">Uttara Phalguni</option>
                <option value="3">Vishakha</option>




              </select>
            </div>
          </div>






        </div>
      </div>
    </div>



    <div class="accordion-item rounded-3 shadow-sm mt-4 border-0 p-2">
      <div
        class="accordion-header py-0 rounded-3 border border-1 bg-light d-flex justify-content-between align-items-center"
        id="panelsStayOpen-headingFifth">
        <div class="ps-1 fw-bold mb-0 fs-5 text-danger"> Education & Career </div>
        <div class="border-0 px-2 py-2 accordion-btn" type="button" data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseFifth" aria-expanded="true"
          aria-controls="panelsStayOpen-collapseFifth">
          <i class="fas fa-sort-down fs-3 me-2"></i>
        </div>
      </div>
      <div id="panelsStayOpen-collapseFifth" class="accordion-collapse collapse show"
        aria-labelledby="panelsStayOpen-headingFifth">
        <div class="accordion-body px-0">


          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label mb-1 mb-md-0">Qualification</label>
            </div>
            <div class="col-12 col-md-9">
              <select class="form-select" aria-label="Default select example">
                <option value="volvo" selected>Select</option>
                <option value="1">High School</option>
                <option value="1"> Diploma</option>
                <option value="1"> Bachelor</option>
                <option value="1"> Masters</option>
                <option value="1"> Ph.D</option>
                <option value="1">Others</option>
              </select>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 col-md-3 my-auto">
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control " id="Birth1" placeholder="Enter">
            </div>
          </div>



          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label mb-1 mb-md-0">College Attended</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control " id="Birth1" placeholder="Enter">
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label mb-1 mb-md-0">Working With</label>
            </div>
            <div class="col-12 col-md-9">
              <select class="form-select" aria-label="Default select example">
                <option value="1">Business / Self Employed</option>
                <option value="1">Government / Public Sector</option>
                <option value="1">Private Company</option>
                <option value="1">Not Working</option>
              </select>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label mb-1 mb-md-0">Working As</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label mb-1 mb-md-0">Company Name</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>


          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="FatherStatus" class="text-muted form-label mb-1 mb-md-0">Annual Income</label>
            </div>
            <div class="col-12 col-md-9">
              <div class="input-group runded-0">
                <span class="input-group-text p-0" id="basic-addon1">
                  <select class="form-select width-80 border-0 rounded-0 px-2" aria-label="Default select example">
                    <option selected>Select</option>
                    <option value="1">AED</option>
                    <option value="2">AUD</option>
                    <option value="3">CAD</option>
                    <option value="3">CHF</option>
                    <option value="3">EUR</option>
                    <option value="3">GBP</option>
                    <option value="3">INR</option>
                    <option value="3">USD</option>
                  </select>
                </span>
                <input type="text" class="form-control rounded-0" placeholder="Enter" aria-label="username"
                  aria-describedby="basic-addon1">
              </div>
              <div class="form-check form-check-inline text-muted p-sm">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                <label class="form-check-label" for="inlineCheckbox1">Keep this private <i
                    class="fas fa-lock"></i></label>
              </div>
            </div>

          </div>
        </div>


      </div>
    </div>


    <div class="accordion-item rounded-3 mt-4 shadow-sm border-0 p-2">
      <div
        class="accordion-header py-0 rounded-3 border border-1 bg-light d-flex justify-content-between align-items-center"
        id="panelsStayOpen-headingSixth">
        <div class="ps-1 fw-bold mb-0 fs-5 text-danger"> Lifestyle </div>
        <div class="border-0 px-2 py-2 accordion-btn" type="button" data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseSixth" aria-expanded="true"
          aria-controls="panelsStayOpen-collapseSixth">
          <i class="fas fa-sort-down fs-3 me-2"></i>
        </div>
      </div>
      <div id="panelsStayOpen-collapseSixth" class="accordion-collapse collapse show"
        aria-labelledby="panelsStayOpen-headingSixth">
        <div class="accordion-body px-0">

          <div class="row mt-3">
            <div class="col-12 mt-3">
              <h6 class="fw-bold"> Food Preference </h6>
              <div class="select-box">
                <div class="form-check form-check-inline p-0 me-3 mb-3">
                  <input class="form-check-input" type="radio" name="FoodPreference" id="Veg" value="Veg">
                  <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Veg">Veg</label>
                </div>
                <div class="form-check form-check-inline p-0 me-3 mb-3">
                  <input class="form-check-input" type="radio" name="FoodPreference" id="NonVeg" value="NonVeg">
                  <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="NonVeg">Non veg</label>
                </div>
                <div class="form-check form-check-inline p-0 me-3 mb-3">
                  <input class="form-check-input" type="radio" name="FoodPreference" id="Occasionally"
                    value="Occasionally">
                  <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                    for="Occasionally">Occasionally
                    Non-Veg</label>
                </div>
                <div class="form-check form-check-inline p-0 me-3 mb-3">
                  <input class="form-check-input" type="radio" name="FoodPreference" id="Eggetarian"
                    value="Eggetarian">
                  <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                    for="Eggetarian">Eggetarian</label>
                </div>
                <div class="form-check form-check-inline p-0 me-3 mb-3">
                  <input class="form-check-input" type="radio" name="FoodPreference" id="Pescatarian"
                    value="Pescatarian">
                  <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                    for="Pescatarian">Pescatarian</label>
                </div>
                <div class="form-check form-check-inline p-0 me-3 mb-3">
                  <input class="form-check-input" type="radio" name="FoodPreference" id="JainFood" value="JainFood">
                  <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="JainFood">Jain</label>
                </div>
                <div class="form-check form-check-inline p-0 me-3 mb-3">
                  <input class="form-check-input" type="radio" name="FoodPreference" id="Vegan" value="Vegan">
                  <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Vegan">Vegan</label>
                </div>
                <div class="form-check form-check-inline p-0 me-3 mb-3">
                  <input class="form-check-input" type="radio" name="FoodPreference" id="OtherFood" value="OtherFood">
                  <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                    for="OtherFood">Other</label>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>



    <div class="accordion-item rounded-3 shadow-sm mt-4 border-0 p-2">
      <div
        class="accordion-header py-0 rounded-3 border border-1 bg-light d-flex justify-content-between align-items-center"
        id="panelsStayOpen-headingSeven">
        <div class="ps-1 fw-bold mb-0 fs-5 text-danger"> Location of Individual </div>
        <div class="border-0 px-2 py-2 accordion-btn" type="button" data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="true"
          aria-controls="panelsStayOpen-collapseSeven">
          <i class="fas fa-sort-down fs-3 me-2"></i>
        </div>
      </div>
      <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse show"
        aria-labelledby="panelsStayOpen-headingSeven">
        <div class="accordion-body px-0">

          <div class="row mt-2">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Country Living In</label>
            </div>
            <div class="col-12 col-md-9">
              <select class="form-select" aria-label="Default select example" required>
                <option value="1">Select</option>
                <option value="1">America</option>
                <option value="1">Australia</option>
                <option value="1">Canada</option>
                <option value="1">China</option>
                <option value="1"> India</option>
                <option value="1">France</option>
                <option value="2">Germany</option>
                <option value="2">Other</option>
              </select>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 col-md-3 my-auto">
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control " id="Birth1" placeholder="Enter">
            </div>
          </div>


          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">State/Province</label>
            </div>
            <div class="col-12 col-md-9">
              <select class="form-select" aria-label="Default select example">
                <option value="1">Select</option>
                <option value="1">M.P.</option>
                <option value="1"> Punjab</option>
                <option value="1">Bihar</option>
                <option value="1">U.P.</option>
                <option value="1">Goa</option>
              </select>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">City</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Grew Up In</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Ethnic Origin</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 col-md-3 my-auto">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 mb-md-0">Zip/Pin/Postal Code</label>
            </div>
            <div class="col-12 col-md-9">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
            </div>
          </div>
 

        </div>
      </div>
    </div>





    <div class="accordion-item rounded-3 shadow-sm mt-4 border-0 p-2">
      <div
        class="accordion-header py-0 rounded-3 border border-1 bg-light d-flex justify-content-between align-items-center"
        id="panelsStayOpen-headingEight">
        <div class="ps-1 fw-bold mb-0 fs-5 text-danger"> More About Yourself, Partner and Family</div>
        <div class="border-0 px-2 py-2 accordion-btn" type="button" data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseEight" aria-expanded="true"
          aria-controls="panelsStayOpen-collapseEight">
          <i class="fas fa-sort-down fs-3 me-2"></i>
        </div>
      </div>
      <div id="panelsStayOpen-collapseEight" class="accordion-collapse collapse show"
        aria-labelledby="panelsStayOpen-headingEight">
        <div class="accordion-body px-0">

          <div class="row mt-2">
            <div class="col-12">
              <p class="p-sm mb-0">
                This section will help you make a strong impression on your potential partner. So, express yourself.
              </p>
              <p class="p-sm mb-0"> (NOTE: This section will be screened everytime you update it. Allow upto 1 business
                day.
                for it
                to go live. )</p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <label for="exampleFormControlInput1" class="text-muted form-label mb-1 p-xsm mb-md-0">Personality, Partner Expectations etc.</label>

              <textarea class="form-control" id=" " rows="6"></textarea>
              <p class="p-xsm">(Min characters - 50, Max characters - 1000)</p>
            </div>
          </div>



        </div>
      </div>
    </div>







    <div class="row mx-0 my-4 bg-white rounded border border-1 shadow-sm text-center">
      <div class="py-3 px-2 col-12">
      <a href="javascript:void(0)" routerLink="/myProfile"> <button type="button" class="btn shadow-sm btn-danger fs-5">Submit </button></a> 
        <button type="button" class="ms-2 btn btn-outline-light text-danger border border-1 shadow-sm fs-5"> Cancel </button>
      </div>
    </div>

  </div>




</div>
<app-login-footer></app-login-footer>
