import { Component, OnInit, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { Title/*, Meta*/ } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { NotifService } from '../services/notif.service';
import { TokenService } from '../shared/token.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators/*, NgForm,*/ } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from 'src/app/services/register.service';
import { AuthService } from '../shared/auth.service';
// unused import
// import * as $ from 'jquery';

@Component({
  selector: 'app-login-home',
  templateUrl: './login-home.component.html',
  styleUrls: ['./login-home.component.css']
})
export class LoginHomeComponent implements OnInit {
  @ViewChild('customHtml') customHtml: TemplateRef<any>;
  @ViewChild('myVideo') myVideo: ElementRef;

  isLoggedIn: any;
  user_id: number;
  age_from: any = [];
  age_to: any = [];
  partnerOtherReligion: boolean = false;

  partnerReligion = [
    { id: '1', name: 'Buddhist' },
    { id: '2', name: 'Christian' },
    { id: '3', name: 'Hindu' },
    { id: '4', name: 'Jain' },
    { id: '5', name: 'Muslim' },
    { id: '6', name: 'Parsi' },
    { id: '7', name: 'Sikh' },
    { id: '8', name: 'No Religion' },
    { id: '9', name: 'Any Religion' },
    { id: '10', name: 'Other' },
  ];

  selectedReligion: any = [];

  SearchProfileForm = new FormGroup({
    looking_for: new FormControl('', [Validators.required]),
    age_from: new FormControl('', Validators.required),
    age_to: new FormControl('', Validators.required),
    religion: new FormControl(''),
    otherReligion: new FormControl(''),
    languages: new FormControl(''),
    languagesOther: new FormControl(''),

  })

  isVideoLoaded: boolean = false;
  package_id: number = 0;

  contactForm: FormGroup;
  wordCount: any;
  words: any;
  tellUsMore: any;
  @ViewChild("text") text: ElementRef;
  exceedLimit: boolean = false;
  submitted = false;
  disableButton: any = false;
  errors: any;
  buttonClicked = false;
  buttonDisable: boolean;
  btnLable: string = 'Send Mail';
  phoneNo: number;
  userDetails: any = [];
  dd: any;
  phoneCode: any;
  full_name: any;
  // countryCode:any;
  formattedValue: any;

  constructor(
    private notifService: NotifService,
    private cookieService: CookieService,
    private router: Router,
    public token: TokenService,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private registerservice: RegisterService,
    private authService: AuthService,

    private toastrService: ToastrService,
  ) {
    this.isLoggedIn = this.token.isLoggedIn();
    this.user_id = parseInt(this.cookieService.get('user_id'));
    this.package_id = parseInt(localStorage.getItem('package_id'));

    this.contactForm = this.formBuilder.group({
      email_from: [''],
      // email_to: ['dummy@gmail.com'],
      // mobileNo: ['' ],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.titleService.setTitle('Home | eternalstrings.com | Real People, Real Relations');
    this.dd = this.cookieService.get('user_id');

    for (let i = 18; i <= 90; i++) {
      this.age_from.push({ id: i });
    }
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit')
    this.isVideoLoaded = true;
    // unused code
    // this.myVideo.nativeElement.play();
    this.playVideo();
  }

  playVideo() {
    const media = this.myVideo.nativeElement;
    media.muted = true; // without this line it's not working although I have "muted" in HTML
    media.loop = true; // without this line it's not working although I have "muted" in HTML
    media.play();
  }

  toggleVideo() {
    console.log('toggleVideo')
    this.myVideo.nativeElement.play();
  }

  get f() {
    return this.SearchProfileForm.controls;
  }

  onChangeAgeFrom(age: any) {
    this.age_to = [];
    for (let i = age.target.value; i <= 90; i++) {
      this.f.age_to.setValue('');
      this.age_to.push({ id: i });
    }
  }

  checkAge() {
    if (this.SearchProfileForm.value.age_to <= this.SearchProfileForm.value.age_from) {
      this.f.age_from.setValue(18);
    }
  }

  onSelectReligion() {
    let partnerReligionSelected = this.selectedReligion.toString();
    let religionArr = partnerReligionSelected.split(',');
    religionArr.forEach((element: any) => {
      if (element != '') {
        if (element != 'Any Religion') {
          this.removeElement1('Any Religion');
          if (element == 'Other') {
            this.partnerOtherReligion = true;
          }
        } else {
          this.selectedReligion = [];
          this.selectedReligion.push(element)
        }
      }
    });

  }

  removeElement1(data: any) {
    const index: number = this.selectedReligion.indexOf(data);
    if (index !== -1) {
      this.selectedReligion.splice(0, 1);
    }
  }


  // contact popup code
  getUser() {
    if (localStorage.getItem('register_id')) {
      let data = {
        user_id: this.dd
      }
      this.registerservice.getUser(data).subscribe(
        (res: any) => {
          if (res?.status) {
            this.userDetails = res['userDetails'];
            console.log(this.userDetails, 'userDetails');
            console.log(res, 'response', this.userDetails.country);
            this.contactForm.controls['email_from'].patchValue(this.userDetails?.email);
            this.full_name = `${this.userDetails.firstName} ${this.userDetails.lastName}`;
            const mobileNo = this.userDetails.mobileNo;
            const countryCode = this.userDetails.countryNumberCode;
            this.formattedValue = `${countryCode}-${mobileNo}`;
          }
        }
      )
    }
  }

  get f1() {
    return this.contactForm.controls;
  }

  pasteNumeric6(event) {
    setTimeout(() => {
      this.wordCount = this.contactForm.value.message.split(/\s+/);
      this.words = this.wordCount.length;
      if (this.words >= 100 && event.code != 'Backspace' && event.code != 'Delete') {
        this.wordCount = this.wordCount.slice(0, 100);
        this.contactForm.patchValue({ message: this.wordCount.join(" ") });
        this.wordError();
      }
    }, 500);
  }

  pos = "";
  compare() {
    let pre = this.contactForm.value;
    console.log(pre, this.contactForm.value)
    setTimeout(() => {
      this.pos = this.contactForm.value;
      if (pre == this.pos) {
        console.log("change", "itime")
      }
    }, 500);
    if (pre == this.pos) {
      console.log("change", "out-time")
    }
  }

  wordError() {
    if (this.words > 99) {
      this.exceedLimit = true;
    } else {
      this.exceedLimit = false;
    }
  }


  wordCounter(event) {
    this.wordCount = this.contactForm.value.message.split(/\s+/);
    this.words = this.wordCount.length;
    if (this.words >= 100 && event.code != 'Backspace' && event.code != 'Delete') {
      this.wordError();
      event.preventDefault();
    } else {
      this.wordError();
    }
  }

  // wordCounter(event) {
  //   // Get the current value from the input field
  //   let para = this.contactForm.value.message || '';
  
  //   // Split the text into words and count them
  //   let wordsArray = para.trim().split(/\s+/).filter(word => word !== '');
  //   this.words = wordsArray.length;
  
  //   // Check if word count exceeds 100
  //   if (this.words >= 101) {
  //     this.wordError();
  
  //     // Handle key events
  //     if (event.code === 'Backspace' || event.code === 'Delete') {
  //       // Update word count immediately after the key is processed
  //       setTimeout(() => {
  //         let updatedPara = this.contactForm.value.message || '';
  //         let updatedWordsArray = updatedPara.trim().split(/\s+/).filter(word => word !== '');
  //         this.words = updatedWordsArray.length;
  //       }, 0);
  //     }
      
  //     event.preventDefault();
  //     return false;
  //   }
  // }
  
  Submit() {
    this.submitted = true;
    console.log('this.contactForm', this.contactForm)
    if (this.contactForm.invalid) {
      this.disableButton = false;
      //return;
    } else {
      this.errors = false;
      let formData = new FormData();
      formData.append("user_id ", this.dd)
      formData.append("full_name", this.full_name)
      formData.append('mobile_no', this.formattedValue);
      formData.append("email", this.contactForm.value.email_from)
      // formData.append("email_to", this.contactForm.value.email_to)
      formData.append("subject", this.contactForm.value.subject)
      formData.append("message", this.contactForm.value.message)
      this.btnLable = 'Mail sending...';
      this.buttonClicked = true;
      console.log('this.contactForm', this.contactForm);
      this.authService.contactAdmin(formData).subscribe(
        (res: any) => {
          this.btnLable = 'Send Mail';
          this.buttonClicked = false;
          this.buttonDisable = false;
          console.log('API Response:', res);
          document.getElementById("close_mailbox").click();
          if (res.status) {
            this.contactForm.reset();
            this.toastrService.success(res.message);
          } else {
            this.toastrService.error(res.message);
          }
          this.submitted = false;
        },
        (error) => {
          this.btnLable = 'Send Mail';
          this.buttonClicked = false;
          console.log('Error:', error);
          this.toastrService.error('An error occurred while processing the request.');
          this.buttonDisable = false;
        }
      );
    }
  }

}
