<div class="w-100 bg-maroon" *ngIf="!isLoggedIn">
  <app-header></app-header>
</div>
<div class="page-header"> 
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-caption">
        </div>
      </div>
    </div>
  </div>
</div>

<section class="container my-4 px-3">

  <div class="card p-4 rounded-5 min_content_height_202 mt-120">
    <div class="my-3">
      <h3 class="text-center fw-bold text-danger">PAYMENT POLICY</h3>
    </div>
    <h6 class="fw-bold mb-5">Last updated March 6, 2023</h6> 

    <p class="lh-lg font-9-rem mb-3">
      Jub We Met and or Eternal Strings may offer products and services for purchase on the Site, App (“In-App Purchase”). If you choose to make an In-App Purchase, you acknowledge and agree that additional terms, disclosed to you at the point of purchase, may apply, and that such additional terms are incorporated herein by reference.
    </p>

    <p class="lh-lg font-9-rem mb-3">
      You may purchase our Services with your credit card. Once you have chosen your preferred membership, you authorize us to charge your account. If payment is not received by us from your chosen Payment Method, you agree to promptly pay all amounts due upon demand by us.
    </p>

    <p class="lh-lg font-9-rem mb-3">
      <strong>Subscriptions and Auto-Renewal:</strong> Jub We Met and or Eternal Strings may offer some services as automatically renewing subscriptions, e.g., one-month subscription, or three-month subscription etc <strong class="text-uppercase">IF YOU PURCHASE AN AUTOMATICALLY RENEWING SUBSCRIPTION, YOUR SUBSCRIPTION WILL RENEW AT THE END OF THE PERIOD AT JUB WE MET AND OR ETERNAL STRINGS, THEN-CURRENT PRICE FOR SUCH SUBSCRIPTIONS WILL BE CHARGED. TO AVOID CHARGES FOR A NEW SUBSCRIPTION</strong> period, you must cancel, as described below, before the end of the subscription period. Deleting your account or deleting the application from your device does not cancel your subscription. If there is a change in price for the Services to which you have subscribed, you will be provided an opportunity to cancel or continue at the new rate. If you do not cancel your subscription after Jub We Met and or Eternal Strings has raised the price, you agree that you will be charged at the new subscription price.
    </p>


    <p class="lh-lg font-9-rem mb-3">
      <strong>Cancelling Subscriptions:</strong>    If you cancel your subscription, you may use your subscription until the end of the period you last paid for, but (i) you will not be eligible for a prorated refund, (ii) your subscription will not be renewed when that period expires and (iii) you will then no longer be able to use services enabled by your subscription.
    </p>


    <p class="lh-lg font-9-rem mb-3">
      <strong>Free Trials:</strong> If you sign up for a free trial and do not cancel, you may convert your trial into a paid subscription and your Payment Method will be charged at the then-current price for such subscription. Once you convert your free trial to a paid subscription, your paid subscription will continue to automatically renew at the end of each period, and your Payment Method will be charged, until you cancel. To avoid charges for a new subscription period, you must cancel before the end of the then-current subscription period as described above. 
    </p>


    <p class="lh-lg font-9-rem mb-3">
    <strong> 
      Refunds. Generally, all charges for purchases are non-refundable, and there are no refunds or credits for partially used periods.
      </strong>
    </p>
    <p class="lh-lg font-9-rem mb-3">
      For all other purchases: please contact customer support with your membership number (you can find the membership number in the order confirmation email.) You may also email a signed and dated notice which states that you, the buyer, are canceling this agreement, or words of similar effect. 
    </p>
    <p class="lh-lg font-9-rem mb-3">
      All applicable Sales Taxes required by law must be paid at time of purchase.
    </p>


    <p class="lh-lg font-9-rem mb-3">
      <strong>YOUR PAYMENT METHOD INFORMATION</strong> <br>
      <small class="text-muted">
        You may edit your Payment Method information by using the Account Settings. If a payment is not successfully processed, due to expiration, insufficient funds, or otherwise, you remain responsible for any uncollected amounts and authorize us to continue billing the Payment Method, as it may be updated. This may result in a change to your payment billing dates.
      </small> <br><br>
      <small class="text-muted">
        In addition, you authorize us to obtain updated or replacement expiration dates and card numbers for your credit card as provided by your credit card issuer. The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the financial institution, credit card issuer, or other provider of your chosen Payment Method. 
      </small>
    </p>


  </div>


</section>


<app-footer></app-footer>
