
<div class="container border border-1 shadow-sm my-4 p-0 position-relative">

  <nav class="position-sticky z-index-60 top-70">
    <div class="nav nav-tabs d-flex align-items-center justify-content-between p-2 bg-warning" id="nav-tab"
      role="tablist">

      <button class="my-2 py-2 px-3 btn-outline-danger active border-0 flex-1 rounded-pill mx-1" type="button"
        routerLink="/myProfile"> My
        Dashboard
      </button>

      <button class="my-2 py-2 px-3 btn-outline-danger border-0 flex-1 rounded-pill mx-1" routerLink="/myInfo"> My
        Profile </button>
      <button class="my-2 py-2 px-3 btn-outline-danger border-0 flex-1 rounded-pill mx-1" type="button"
        routerLink="/myPhotoGallery"> My
        Photo
        Gallery
      </button>
    </div>
  </nav>



  <div class="p-3 bg-white">
    <div class="tab-content" id="nav-tabContent">

      <!-- ----------start-main tabs----------------- -->



      <div class="tab-pane fade show active" id="nav-dashboard-2" role="tabpanel" aria-labelledby="nav-dashboard-2-tab">

        <div class="row">
          <div class="order-sm-1 col-12 col-sm-6 col-lg-3 my-3">
            <div class="border border-1 border-danger overflow-hidden rounded-5 shadow-2 h-100">
              <div class="ratio-2 position-relative">
                <img src="assets/images/family-group-photo.jpg" class="w-100 h-100 image-cover" alt="" type="button"
                  data-bs-toggle="modal" data-bs-target="#exampleModal2">
                <div class="position-absolute btn-danger btn btn-pos w-25-h-25 rounded-circle d-flex align-items-center
                  justify-content-center">
                  <i class="fas fa-plus text-light fs-6"></i>
                </div>
                <div type="button" class="position-absolute w-65-h-65 rounded-circle bottom-0 profile-circle"
                  data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <img src="assets/images/Girl-modal.jpg" class="image-cover rounded-circle" alt="">
                </div>
              </div>
              <h6 class="fw-bold text-center mt-4 pt-3 mb-1 fs-5"> John Doe <small class="p-sm">
                  (JWM123456789)</small></h6>
              <p class="p-sm text-center mb-1"> 22 / 5' 11", Hindu / Agarwal</p>
              <p class="p-sm text-center mb-1">Indore, Madhya Pradesh, India </p>
            </div>
          </div>

          <div class="order-sm-3 order-lg-1 col-12 col-sm-12 col-lg-7 my-3">
            <div class="border border-1 border-danger overflow-hidden rounded-5 shadow-2 h-100">
              <div class="d-flex px-2 py-4 flex-wrap align-items-center justify-content-evenly">
                <div class="mt-3 mb-2 px-2 mx-2 width-150 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                  border-bottom-0 border-start-0 border-end-0">
                  <div
                    class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                    <i class="fas fa-user-clock fs-5 up-2 cursor-pointer text-danger"></i>
                  </div>
                  <p class="mb-0 mt-4 text-center fw-bold">50</p>
                  <small class="mt-1 mb-2 text-center lh-sm d-block">Pending Invitations</small>
                </div>

                <div class="mt-3 mb-2 px-2 mx-2 width-150 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                  border-bottom-0 border-start-0 border-end-0">
                  <div
                    class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                    <i class="fas fa-user-check fs-5 up-2 cursor-pointer text-danger"></i>
                  </div>
                  <p class="mb-0 mt-4 text-center fw-bold">1.6K</p>
                  <small class="mt-1 mb-2 text-center lh-sm d-block">Accepted Invitations</small>
                </div>

                <div class="mt-3 mb-2 px-2 mx-2 width-150 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                  border-bottom-0 border-start-0 border-end-0">
                  <div
                    class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                    <i class="fas fa-users fs-5 up-2 cursor-pointer text-danger"></i>
                  </div>
                  <p class="mb-0 mt-4 text-center fw-bold">999+</p>
                  <small class="mt-1 mb-2 text-center lh-sm d-block">Recent Visitors</small>
                </div>

                <div class="mt-3 mb-2 px-2 mx-2 width-150 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                  border-bottom-0 border-start-0 border-end-0">
                  <div
                    class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                    <i class="fas fa-eye fs-5 up-2 cursor-pointer text-danger"></i>
                  </div>
                  <p class="mb-0 mt-4 text-center fw-bold">65</p>
                  <small class="mt-1 mb-2 text-center lh-sm d-block">Contacts Viewed</small>
                </div>

                <div class="mt-3 mb-2 px-2 mx-2 width-150 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                  border-bottom-0 border-start-0 border-end-0">
                  <div
                    class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                    <i class="fas fa-comment-dots fs-5 up-2 cursor-pointer text-danger"></i>
                  </div>
                  <p class="mb-0 mt-4 text-center fw-bold">78</p>
                  <small class="mt-1 mb-2 text-center lh-sm d-block">Chats initiated</small>
                </div>

                <div class="mt-3 mb-2 px-2 mx-2 width-150 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                  border-bottom-0 border-start-0 border-end-0">
                  <div
                    class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                    <i class="fas fa-user-friends fs-5 up-2 cursor-pointer text-danger"></i>
                  </div>
                  <p class="mb-0 mt-4 text-center fw-bold">255</p>
                  <small class="mt-1 mb-2 text-center lh-sm d-block">Preferred Members</small>
                </div>


              </div>
            </div>
          </div>
          <div class="order-sm-2 order-lg-3 col-12 col-sm-6 col-lg-2 my-3">
            <div class="border border-1 border-danger h-100 overflow-hidden rounded-5 shadow-2 text-center">
              <div class="py-2 cursor-pointer">
                <circle-progress [percent]="80" [radius]="60" [outerStrokeWidth]="8" [innerStrokeWidth]="3"
                  [outerStrokeColor]="'#b51c36'" [innerStrokeColor]="'#ffcd04'" [animation]="true"
                  [animationDuration]="1000" [titleColor]="'#b51c36'" [responsive]="true" [units]="'%'"
                  [unitsColor]="'#b51c36'" [titleFontWeight]="'600'" [subtitle]="'Profile Completed'"></circle-progress>
              </div>
              <button class="btn btn-sm btn-danger p-sm d-block mb-3 mx-auto">Complete
                Now</button>


            </div>
          </div>

          <div class="order-sm-4 col-12 col-md-7 my-3">
            <div class="border border-1 border-danger h-100 overflow-hidden rounded-5 shadow-2 px-3 py-2">
              <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
                Suggested Matches </h5>
              <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner d-flex center">
                  <div class="carousel-item row w-100 mx-0 rounded-3 shadow-sm border border-1 bg-light mt-2 active">
                    <div class="col-12 mt-2 w-100 px-0 d-flex">
                      <div class="ps-2 w-180 h-180 flex-none">
                        <img class="image-cover rounded-3" src="assets/images/banner-7.jpg" alt="">
                      </div>
                      <div class="row w-100 mx-0">
                        <div class="col-12">
                          <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0">
                            <div class="col-6 px-0">
                              <h5 class="text-danger mb-0 fw-bold">Anouskq P</h5>
                            </div>
                            <div class="col-6 px-0 text-end">
                              <div class="dropdown">
                                <div class="ms-auto d-flex align-items-center justify-content-center btn w-25-h-25
                                  text-center border border-1 rounded-circle border-danger p-1" type="button"
                                  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="fas fa-caret-down text-danger h-5"></i>
                                </div>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                  <li class="p-sm dropdown-item"> Add to Shortlist
                                  </li>
                                  <li class="p-sm dropdown-item"> Don't show again
                                  </li>
                                  <li class="p-sm dropdown-item"> Block Profile </li>
                                  <li class="p-sm dropdown-item"> Report Profile/Photo
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="row py-2">
                            <div class="col-6">
                              <p class="p-sm mb-2 text-muted text-break">24 yrs, 5' 6"</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-2 text-muted text-break">Single</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-2 text-muted text-break">Hindu, Karana</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-2 text-muted text-break">Indore, India</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-0 text-muted text-break">Odia</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-0 text-muted text-break">Software
                                Consultant </p>
                            </div>
                            <div class="col-12 pt-2">
                              <p class="text-muted text-break pt-2 p-sm mb-0">Hello, I'm
                                glad you are interested in my daughter's profile. She is
                                pursuing her Masters in Data Sc... <a href="javascript:void(0)" routerLink="/details"
                                  class="text-danger">More</a>
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="row mx-0 pb-2 mt-2 w-100">
                      <div class="col-12text-center d-flex justify-content-between align-items-center">
                        <h6 class="my-auto text-center fw-bold text-muted text-break">Like this
                          profile?
                        </h6>
                        <div class="text-center">
                          <a href="javascript:void(0)" routerLink="/matches" class="flex-none btn p-1 border border-2 border-danger w-40-h-40 rounded-circle
                            overflow-hidden">
                            <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden
                              d-flex align-items-center justify-content-center">
                              <i class="fas fa-check fs-6"></i>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item row w-100 mx-0 rounded-3 shadow-sm border border-1 bg-light mt-2">
                    <div class="col-12 mt-2 w-100 px-0 d-flex">
                      <div class="ps-2 w-180 h-180 flex-none">
                        <img class="image-cover rounded-3" src="assets/images/banner-7.jpg" alt="">
                      </div>
                      <div class="row w-100 mx-0">
                        <div class="col-12">
                          <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0">
                            <div class="col-6 px-0">
                              <h5 class="text-danger mb-0 fw-bold">Anouskq P</h5>
                            </div>
                            <div class="col-6 px-0 text-end">
                              <div class="dropdown">
                                <div class="ms-auto d-flex align-items-center justify-content-center btn w-25-h-25
                                  text-center border border-1 rounded-circle border-danger p-1" type="button"
                                  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="fas fa-caret-down text-danger h-5"></i>
                                </div>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                  <li class="p-sm dropdown-item"> Add to Shortlist
                                  </li>
                                  <li class="p-sm dropdown-item"> Don't show again
                                  </li>
                                  <li class="p-sm dropdown-item"> Block Profile</li>
                                  <li class="p-sm dropdown-item"> Report Profile/Photo
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="row py-2">
                            <div class="col-6">
                              <p class="p-sm mb-2 text-muted text-break">24 yrs, 5' 6"</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-2 text-muted text-break">Single</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-2 text-muted text-break">Hindu, Karana</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-2 text-muted text-break">Indore, India</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-0 text-muted text-break">Odia</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-0 text-muted text-break">Software
                                Consultant </p>
                            </div>
                            <div class="col-12 pt-2">
                              <p class="text-muted text-break pt-2 p-sm mb-0">Hello, I'm
                                glad you are interested in my daughter's profile. She is
                                pursuing her Masters in Data Sc... <a href="javascript:void(0)" routerLink="/details"
                                  class="text-danger">More</a>
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="row mx-0 pb-2 mt-2 w-100">
                      <div class="col-12text-center d-flex justify-content-between align-items-center">
                        <h6 class="my-auto text-center fw-bold text-muted text-break">Like this
                          profile?
                        </h6>
                        <div class="text-center">
                          <a href="javascript:void(0)" routerlink="/matches" class="flex-none btn p-1 border border-2 border-danger w-40-h-40 rounded-circle
                            overflow-hidden">
                            <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden
                              d-flex align-items-center justify-content-center">
                              <i class="fas fa-check fs-6"></i>
                            </div>
                          </a>
                        </div>


                      </div>
                    </div>
                  </div>
                  <div class="carousel-item row w-100 mx-0 rounded-3 shadow-sm border border-1 bg-light mt-2">
                    <div class="col-12 mt-2 w-100 px-0 d-flex">
                      <div class="ps-2 w-180 h-180 flex-none">
                        <img class="image-cover rounded-3" src="assets/images/banner-7.jpg" alt="">
                      </div>
                      <div class="row w-100 mx-0">
                        <div class="col-12">
                          <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0">
                            <div class="col-6 px-0">
                              <h5 class="text-danger mb-0 fw-bold">Anouskq P</h5>
                            </div>
                            <div class="col-6 px-0 text-end">
                              <div class="dropdown">
                                <div class="ms-auto d-flex align-items-center justify-content-center btn w-25-h-25
                                  text-center border border-1 rounded-circle border-danger p-1" type="button"
                                  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="fas fa-caret-down text-danger h-5"></i>
                                </div>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                  <li class="p-sm dropdown-item">Add to Shortlist
                                  </li>
                                  <li class="p-sm dropdown-item"> Don't show again
                                  </li>
                                  <li class="p-sm dropdown-item"> Block Profile</li>
                                  <li class="p-sm dropdown-item"> Report Profile/Photo
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="row py-2">
                            <div class="col-6">
                              <p class="p-sm mb-2 text-muted text-break">24 yrs, 5' 6"</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-2 text-muted text-break">Single</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-2 text-muted text-break">Hindu, Karana</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-2 text-muted text-break">Indore, India</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-0 text-muted text-break">Odia</p>
                            </div>
                            <div class="col-6">
                              <p class="p-sm mb-0 text-muted text-break">Software
                                Consultant </p>
                            </div>
                            <div class="col-12 pt-2">
                              <p class="text-muted text-break pt-2 p-sm mb-0">Hello, I'm
                                glad you are interested in my daughter's profile. She is
                                pursuing her Masters in Data Sc... <a href="javascript:void(0)" routerLink="/details"
                                  class="text-danger">More</a>
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="row mx-0 pb-2 mt-2 w-100">
                      <div class="col-12text-center d-flex justify-content-between align-items-center">
                        <h6 class="my-auto text-center fw-bold text-muted text-break">Like this
                          profile?
                        </h6>
                        <div class="text-center">
                          <a href="javascript:void(0)" routerlink="/matches" class="flex-none btn p-1 border border-2 border-danger w-40-h-40 rounded-circle
                            overflow-hidden">
                            <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden
                              d-flex align-items-center justify-content-center">
                              <i class="fas fa-check fs-6"></i>
                            </div>
                          </a>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
                <div class="center mt-3">
                  <button class="p-0 border-0 mx-2 position-static" type="button"
                    data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <i class="fas fa-caret-square-left text-danger fs-1"></i>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="p-0 border-0 mx-2 position-static" type="button"
                    data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <i class="fas fa-caret-square-right text-danger fs-1"></i>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>

              </div>

            </div>
          </div>

          <div class="order-sm-5 col-12 col-md-5 my-3">
            <div class="border border-1 border-danger h-100 overflow-hidden rounded-5 shadow-2 px-3 py-2">
              <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
                Communications <span class="ms-2 btn-outline-danger btn rounded-3 px-2 py-0" routerLink="/inbox">
                  949</span></h5>

              <div class="row mt-2 mx-0">

                <div class="col-12 py-2 alert alert-success d-flex align-items-center overflow-hidden rounded-3 mb-2"
                  routerLink="/detail">
                  <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                    <img src="assets/images/Girl-modal.jpg" class="image-cover" alt="">
                  </div>
                  <div class="flex-grow-1 ms-2 overflow-hidden">
                    <div class="d-flex align-items-center justify-content-between mb-1">
                      <h6 class="mb-0 fw-bold">Request Accpected</h6>
                      <small class="text-muted text-break p-sm">9 Min. Ago</small>
                    </div>
                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                      adipisicing elit. Odio, cumque? </div>
                  </div>
                </div>

                <div class="col-12 py-2 alert alert-danger d-flex align-items-center overflow-hidden rounded-3 mb-2"
                  routerLink="/detail">
                  <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                    <img src="assets/images/Girl-modal.jpg" class="image-cover" alt="">
                  </div>
                  <div class="flex-grow-1 ms-2 overflow-hidden">
                    <div class="d-flex align-items-center justify-content-between mb-1">
                      <h6 class="mb-0 fw-bold">Request Canceled</h6>
                      <small class="text-muted text-break p-sm">29 Min. Ago</small>
                    </div>
                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                      adipisicing elit. Odio, cumque? </div>
                  </div>
                </div>

                <div class="col-12 py-2 alert alert-primary d-flex align-items-center overflow-hidden rounded-3 mb-2"
                  routerLink="/detail">
                  <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                    <img src="assets/images/Girl-modal.jpg" class="image-cover" alt="">
                  </div>
                  <div class="flex-grow-1 ms-2 overflow-hidden">
                    <div class="d-flex align-items-center justify-content-between mb-1">
                      <h6 class="mb-0 fw-bold">Follow Request</h6>
                      <small class="text-muted text-break p-sm"> 3 hours Ago</small>
                    </div>
                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                      adipisicing elit. Odio, cumque? </div>
                  </div>
                </div>

                <div class="col-12 py-2 alert alert-primary d-flex align-items-center overflow-hidden rounded-3 mb-2"
                  routerLink="/detail">
                  <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                    <img src="assets/images/Girl-modal.jpg" class="image-cover" alt="">
                  </div>
                  <div class="flex-grow-1 ms-2 overflow-hidden">
                    <div class="d-flex align-items-center justify-content-between mb-1">
                      <h6 class="mb-0 fw-bold">Follow Request</h6>
                      <small class="text-muted text-break p-sm"> 3 hours Ago</small>
                    </div>
                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                      adipisicing elit. Odio, cumque? </div>
                  </div>
                </div>

              </div>

            </div>


          </div>

        </div>

        <div class="row">
          <div class="col-12 col-md-6 my-3">
            <div class="border border-1 border-danger h-100 overflow-hidden rounded-5 shadow-2 px-3 py-2">
              <p class="mb-0 mt-2 fw-bold"> Premium Matches <span
                  class="ms-2 btn-outline-danger btn rounded-3 px-2 py-0">
                  949</span></p>

              <div class="row mt-3 px-2 rounded-3 overflow-hidden">
                <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                  <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                    <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt="">
                  </div>
                  <div class="px-2">
                    <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                        class="p-xsm btn bg-danger text-light rounded-2 p-1"><i class="fas fa-crown"></i> Premium
                        +</span>
                    </p>
                    <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                      Bengaluru Software Professional (Others)</p>
                  </div>
                  <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                    <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                      align-items-center justify-content-center">
                      <i class="fas fa-check fs-4"></i>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                  <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                    <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt="">
                  </div>
                  <div class="px-2">
                    <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                        class="p-xsm btn bg-danger text-light rounded-2 p-1"><i class="fas fa-crown"></i> Premium
                        +</span>
                    </p>
                    <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                      Bengaluru Software Professional (Others)</p>
                  </div>
                  <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                    <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                      align-items-center justify-content-center">
                      <i class="fas fa-check fs-4"></i>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                  <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                    <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt="">
                  </div>

                  <div class="px-2">
                    <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                        class="btn p-xsm bg-danger text-light rounded-2 p-1"><i class="fas fa-crown"></i> Premium
                        +</span>
                    </p>
                    <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                      Bengaluru Software Professional (Others)</p>
                  </div>
                  <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                    <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                      align-items-center justify-content-center">
                      <i class="fas fa-check fs-4"></i>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                  <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                    <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt="">
                  </div>
                  <div class="px-2">
                    <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                        class="btn p-xsm bg-danger text-light rounded-2 p-1"><i class="fas fa-crown"></i> Premium
                        +</span>
                    </p>
                    <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                      Bengaluru Software Professional (Others)</p>
                  </div>
                  <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                    <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                      align-items-center justify-content-center">
                      <i class="fas fa-check fs-4"></i>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                  <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                    <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt="">
                  </div>
                  <div class="px-2">
                    <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                        class="p-xsm btn bg-danger text-light rounded-2 p-1"><i class="fas fa-crown"></i> Premium
                        +</span>
                    </p>
                    <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                      Bengaluru Software Professional (Others)</p>
                  </div>
                  <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                    <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                      align-items-center justify-content-center">
                      <i class="fas fa-check fs-4"></i>
                    </div>
                  </div>
                </div>
                <div class="col-12 border border-1 text-center p-2">
                  <a href="javascript:void(0)" routerLink="/matches" class="btn btn-danger btn-sm">View All</a>
                </div>
              </div>
            </div>
          </div>


          <div class="col-12 col-md-6 my-3">
            <div class="border border-1 border-danger h-100 overflow-hidden rounded-5 shadow-2 px-3 py-2">
              <p class="mb-0 mt-2 fw-bold"> Member You May Like<span
                  class="ms-2 btn-outline-danger btn rounded-3 px-2 py-0">
                  999+</span></p>

              <div class="row mt-3 px-2 rounded-3 overflow-hidden">
                <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                  <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                    <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt="">
                  </div>
                  <div class="px-2">
                    <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                        class="p-xsm btn bg-danger text-light rounded-2 p-1"><i class="fas fa-crown"></i> Premium
                        +</span>
                    </p>
                    <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                      Bengaluru Software Professional (Others)</p>
                  </div>
                  <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                    <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                      align-items-center justify-content-center">
                      <i class="fas fa-check fs-4"></i>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                  <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                    <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt="">
                  </div>

                  <div class="px-2">
                    <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                        class="btn p-xsm bg-danger text-light rounded-2 p-1">Just Joined</span>
                    </p>
                    <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                      Bengaluru Software Professional (Others)</p>
                  </div>

                  <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                    <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                      align-items-center justify-content-center">
                      <i class="fas fa-check fs-4"></i>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                  <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                    <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt="">
                  </div>

                  <div class="px-2">
                    <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span>
                    </p>
                    <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                      Bengaluru Software Professional (Others)</p>
                  </div>

                  <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                    <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                      align-items-center justify-content-center">
                      <i class="fas fa-check fs-4"></i>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                  <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                    <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt="">
                  </div>

                  <div class="px-2">
                    <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                        class="p-xsm btn bg-danger text-light rounded-2 p-1">Just Joined</span>
                    </p>
                    <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                      Bengaluru Software Professional (Others)</p>
                  </div>

                  <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                    <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                      align-items-center justify-content-center">
                      <i class="fas fa-check fs-4"></i>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                  <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                    <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt="">
                  </div>

                  <div class="px-2">
                    <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span>
                    </p>
                    <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                      Bengaluru Software Professional (Others)</p>
                  </div>

                  <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                    <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                      align-items-center justify-content-center">
                      <i class="fas fa-check fs-4"></i>
                    </div>
                  </div>
                </div>
                <div class="col-12 border border-1 text-center p-2">
                  <a href="javascript:void(0)" class="btn btn-danger btn-sm">View All</a>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>



      <!-- tab-one -->















    </div>
  </div>

</div>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fall" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<app-login-footer></app-login-footer>
<!-- modals -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content rounded-5 border border-1 border-warning">
      <div class="modal-header pb-0 border-0">
        <h5 class="modal-title mb-0 fw-bold" id="exampleModalLabel">John Deo <small class="p-sm">S8585555</small></h5>
        <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close"> <i
            class="fas fa-times fs-4 text-danger"></i> </button>
      </div>
      <div class="modal-body">
        <div class="ratio-1">
          <img src="assets/images/Girl-modal.jpg" class="image-cover rounded-5 border border-1 border-warning" alt="">
        </div>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content rounded-5 border border-1 border-warning">
      <div class="modal-header pb-0 border-0">
        <h5 class="modal-title mb-0 fw-bold" id="exampleModalLabel"> John Deo <small class="p-sm">S8585555</small></h5>
        <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close"> <i
            class="fas fa-times fs-4 text-danger"></i> </button>
      </div>
      <div class="modal-body">
        <div class="ratio-2">
          <img src="assets/images/family-group-photo.jpg" class="image-cover rounded-5 border border-1 border-warning"
            alt="">
        </div>
      </div>
    </div>
  </div>
</div>




<!-- modal -->
<div class="modal fade" id="guidelinesModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content position-relative">
      <button type="button" class="btn-close position-absolute top-0 right-0 m-3 z-index-99" data-bs-dismiss="modal"
        aria-label="Close"></button>

      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-sm-12">
            <h5 class="p-0 fw-bold text-muted"> Photos Guidelines </h5>
            <div class="row my-3">
              <div class="col-12 d-flex align-items-center">
                <div class="">
                  <small><i class="fas fa-check text-success"></i> Close Up </small>
                  <div class="w-85-h-85">
                    <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                      src="assets/images/close-up-view.png" alt="">
                  </div>
                </div>

                <div class="mx-3">
                  <small><i class="fas fa-check text-success"></i> Half View </small>
                  <div class="w-85-h-85">
                    <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                      src="assets/images/half-view.png" alt="">
                  </div>
                </div>
                <div class="">
                  <small><i class="fas fa-check text-success"></i> Full View </small>
                  <div class="w-85-h-85">
                    <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                      src="assets/images/full-view.png" alt="">
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div class="col-12 pb-4">
            <p class="lh-lg p-sm fw-bold px-3 mb-0"> <i class="fas fa-check text-success"></i> Do's </p>
            <ul class="">
              <li class="lh-lg p-sm">Your profile photo should be front-facing and your entire photo
                should be visible. </li>
              <li class="lh-lg p-sm">Ensure that your photo is recent and not with a group.</li>
              <li class="lh-lg p-sm">You can upload upto 20 photos to your gallery.</li>
              <li class="lh-lg p-sm">Each photo must be less than 5 MB in size and must be in one of the
                following
                formats: jpg, gif, png, bmp .</li>
            </ul>

            <p class="h-lg p-sm fw-bold px-3 mb-0"> <i class="fas fa-times text-danger"></i> Don'ts</p>
            <ul class="">
              <li class="lh-lg p-sm"> Watermarked, digitally enhanced, morphed photos or photos with your
                personal information will be rejected. </li>
              <li class="lh-lg p-sm"> Irrelevant photographs will lead to deactivation of your profile and
                membership. </li>
              <li class="lh-lg p-sm"> The Picture should not be Blurry. </li>
              <li class="lh-lg p-sm"> Do not upload vulgar or nude images. </li>


            </ul>
          </div>
          <div class="col-12 rounded mx-auto py-2 px-3 border border-1 shadow-sm bg-light">
            <p class="p-sm mb-0 text-center"> <span class="fs-5 fw-bold text-danger">*</span> Photos will be
              screened, and added to your profile within 5 business days.</p>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
