import { Component, OnInit, ViewChild } from '@angular/core';
import { Title/*, Meta */} from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterService } from '../services/register.service';
import { MyProfileService } from '../services/my-profile.service';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm/*, EmailValidator, */} from '@angular/forms';
import { AuthService } from '../shared/auth.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
// unused import
// import { MatInputModule } from '@angular/material/input';
import { DatePipe } from '@angular/common';
import { CommonService } from '../services/common.service';
import { HttpClient } from '@angular/common/http';
import { AuthStateService } from '../shared/auth-state.service';
import { TokenService } from '../shared/token.service';




@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})

export class SettingComponent implements OnInit {
  //account setting (my account) code........

  @ViewChild('resetPasswordNgForm') resetPasswordNgForm: NgForm;
  submitted = false;
  submitted1 = false;
  user_id: any;
  isEditContactDetail: boolean = false;
  disabledBox: boolean = true;
  accountData: any = {};
  //accountData:any=[];
  // accountData: any = '';
  //accountData:any[]=[];
  //change password code...........
  showPassword: boolean = false;
  showPassword1: boolean = false;
  showPassword2: boolean = false;

  passmatch: boolean = true;
  sBtn: boolean = false;
  passwordBtn: boolean = false;
  pwmatch: boolean = false;
  errormsg: string
  passvalid: boolean;
  err: boolean;
  // errmsg: string;
  errmsg1: string;
  //submitted =false;
  //source$: Observable<any>;
  deleteForm: any;
  contactForm: any;
  resetPasswordForm: any;
  user: any = {}
  userData: any;
  hideUnhideBtn: boolean = false;
  // contactForm = new FormGroup ({
  //   alternate_email: new FormControl('', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
  //   alternate_mobile_no:new FormControl('',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
  //   //alternate_mobile_no:new FormControl('', [Validators.required, Validators.pattern("^[0-9/+/ ]{15}$")]),
  // });
  // resetPasswordForm= new FormGroup({
  //   //currentPassword :new FormControl('',[Validators.required]),
  //   newPassword: new FormControl('', [Validators.required,Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{6,15}'),Validators.minLength(8)]),
  //   ConfirmPassword: new FormControl('', [Validators.required]),
  // });
  //my account code......................end.................
  userSettingForm1: any;
  userSettingForm2: any;
  userSettingForm3: any;
  userSettingForm4: any;
  userSettingForm5: any;
  userSettingForm6: any;
  userSettingForm7: any;
  userSettingForm8: any;
  userSettingForm9: any;
  userSettingForm10: any;
  userSettingForm11: any;
  userSettingForm12: any;
  userSettingOtp: any;
  userSettingOtp2: any;
  deletProfileAccountForm: any;


  // privacy code.........................
  DisplayName: string;
  displayname: boolean = false;
  phonNumber: boolean = false;
  emailId: boolean = false;
  photos: boolean = false;
  astro_detail: boolean = false;
  viewBy: boolean = false;
  myshortlist: boolean = false;
  myfavourit: boolean = false;
  annualIncome: boolean = false;
  profilePrivacy: boolean = false;
  subscribe: boolean = false;
  userDetails: any;
  phonNumberstr: string = '';
  emailIdstr: string = '';
  photosstr: string = '';
  dobstr: string = '';
  viewBystr: string = '';
  loaderData: boolean = false;
  myshortliststr: string = ' ';
  myfavouritstr: string = '';
  annualIncomestr: string = '';
  subscribestr: string = 'Subscribed';
  subscribe1: boolean = false;

  annual_income_visiblity_id: any;
  display_name_id: any;
  dob_id: any;
  email_id: any;
  my_favourite_id: any;
  my_shortlist_id: any;
  phone_id: any;
  photo_visiblity_id: any;
  subscribe_for_update_id: any;
  viewed_by_others_id: any;

  Type_of_alerts: boolean = false;
  Frequency_of_alerts: boolean = false;
  text1: any = [];
  sms_otp_login: boolean = false;
  email_otp_login: boolean = false;
  no_otp_login: boolean = false;
  alertEdit = true;

  // displayNameForm = new FormGroup({
  //   first_name: new FormControl('', Validators.required),
  //   last_name: new FormControl('', Validators.required),
  // }) 
  displayId: number;

  hideTillDate: any = "";

  // privacy code........................

  //delete myAccount code.................
  textContent: string = "";
  NewPassword: any;
  userDetailsSetting: any;
  selectedIds: any = [];
  delData: { user_id: any; password: any; };
  myAccount: { user_id: any; alternate_email: any; alternate_mobile_no: any; };
  active_plan: any = [];
  expired_plan: any = [];
  // subscribeUpdate: boolean = false;
  dateString: string | null;
  formattedDate: string;
  reason: string;
  hideSubmit: boolean = true;
  dNameStr: any;
  dNameStr1: any;

  min: any;
  yesterday = new Date();
  showPasswordMsg: boolean = false;
  showPasswordMsg1: boolean = false;
  dateRequired: boolean = true;
  tillDate: any;
  Experience: any = "";
  Feedback: any = "";
  blockReason: any = ""

  campaignOne = new FormGroup({
    start: new FormControl(new Date(2023, 4, 23)),
    // end: new FormControl(new Date(year, month, 16)),
  });
  emailValid: boolean = true;
  passRequired: boolean = true;
  ipAddress: any;

  tabAleart: any;
  tabHide: any;
  alert_types: any;
  alert_types_arr: any = [];
  frequency_of_alert: any = '';
  minNumberLength: boolean = false;
  maxNumberLength: boolean = false;
  countries: any = [];
  phoneCode: any;
  deleteBy: any;

  deletedProfileList: any = [];
  currentActivePlan: any;
  remainingDays: number;
  plan_extended_history: any = [];
  blankUserDetail: boolean = false;
  registerDone: any;

  constructor(
    private auth: AuthStateService,
    public token: TokenService,
    private titleService: Title,
    private cookieService: CookieService,
    private registerService: RegisterService,
    private myProfileService: MyProfileService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private commonService: CommonService,
    private http: HttpClient,
  ) {
    this.yesterday.setDate(this.yesterday.getDate() - 0);
    this.contactForm = new FormGroup({
      alternate_email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
      alternate_mobile_no: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]),
      countryCode: new FormControl('', [Validators.required, Validators.maxLength(10)]),
    });

    this.resetPasswordForm = new FormGroup({
      newPassword: new FormControl('', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{6,15}'), Validators.minLength(8)]),
      ConfirmPassword: new FormControl('', [Validators.required]),
    });

    this.user_id = parseInt(this.cookieService.get('user_id'));
    this.deleteForm = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      password: new FormControl('', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{6,15}'), Validators.minLength(8)]),
      matchPassword: new FormControl('', Validators.required),
    });

    this.user_id = parseInt(this.cookieService.get('user_id'));
    this.userSettingForm1 = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      display_name: new FormControl('Hide my full name', Validators.required),
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      unique_key: new FormControl('', Validators.required),
    });

    this.user_id = parseInt(this.cookieService.get('user_id'));
    this.userSettingForm2 = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      contact_detail: new FormControl('Only by Permissions', Validators.required),
    });

    this.userSettingForm3 = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      email: new FormControl('', Validators.required),
    });

    this.userSettingForm4 = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      photo_visiblity: new FormControl('Visible to all members', Validators.required),

    });

    this.userSettingForm5 = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      astro_detail: new FormControl('Only by Permissions', Validators.required),
    });

    this.userSettingForm6 = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      viewed_by_others: new FormControl('Let other Members know that I have visited their Profile.', Validators.required),
    });

    this.userSettingForm7 = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      my_shortlist: new FormControl('Let other Members know that I have Shortlisted their Profile.', Validators.required),
    });

    this.userSettingForm8 = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      my_favourite: new FormControl('Let other Members know that I have Favourite their Profile.', Validators.required),
    });

    this.userSettingForm9 = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      annual_income_visiblity: new FormControl('Visible to all members', Validators.required),
    });

    this.userSettingForm10 = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      subscribe_for_update: new FormControl('false', Validators.required),
    });

    this.userSettingForm11 = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      frequency_of_alert: new FormControl('', Validators.required),
    });

    this.userSettingForm12 = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      type_of_alert: new FormControl('', Validators.required),
    });
    this.userSettingOtp = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      action: new FormControl('', Validators.required),
    });

    this.userSettingOtp2 = new FormGroup({
      user_id: new FormControl(this.user_id, Validators.required),
      action: new FormControl(false, Validators.required),
    });

    this.deletProfileAccountForm = new FormGroup({
      delete: new FormControl('', Validators.required),
    });

  }

  ngOnInit(): void {

    this.tabHide = localStorage.getItem('hideProfile');
    if (this.tabHide == 'hideProfile') {
      window.scroll(0, 0);
      localStorage.removeItem('hideProfile');
    } else {
      window.scroll(0, 0);
    }

    this.tabAleart = localStorage.getItem('tabAleart');
    if (this.tabAleart == 'aleart') {
      window.scroll(0, 0);
      localStorage.removeItem('tabAleart');
    } else {
      window.scroll(0, 0);
    }

    this.titleService.setTitle('My Settings | eternalstrings.com');
    let url = window.location.href
    let urlArray = url.split('reset-password/')
    console.log(urlArray)
    this.user_id = parseInt(this.cookieService.get('user_id'));
    this.userDetailData();
    this.userDetail();
    this.getAlertTypes();
    this.getDeletedProfilesByUserId();

    this.authService.getCountryList().subscribe(
      (result) => {
        this.countries = result.data;
        this.countries.forEach((country) => {
          if (this.userData?.countryNumberCode && this.userData.countryNumberCode == country.country_code) {
            this.phoneCode = country.country_code
          }
        });
      })

    let getUserPlansReq = {
      user_id: this.user_id,
    };
    this.spinner.show();
    this.myProfileService.getUserPlans(getUserPlansReq).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.expired_plan = res.expired_plan;
        this.active_plan = res.active_plan;
        this.active_plan = this.removeDuplicates(this.active_plan);
        this.active_plan.forEach(element => {
          if (element.active_plan_id) {
            console.log(this.active_plan, "active plan", element)
            this.currentActivePlan = element
            this.getPlanExtendedData();
          }
        });
        const dateTime1 = new Date(this.currentActivePlan?.payment_date);
        const dateTime2 = new Date(this.currentActivePlan?.end_date);
        const currentDate = new Date();
        // Calculate the difference days month
        const differenceInMilliseconds = dateTime2.getTime() - dateTime1.getTime();
        const dayDifference = differenceInMilliseconds / (1000 * 60 * 60 * 24);

        // Calculate the remaining days, 
        const timeDifference = dateTime2.getTime() - currentDate.getTime();
        const remainingDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        console.log(dayDifference, "month days ", remainingDays, "remaiming days")
        this.remainingDays = remainingDays
      }
    }, (error) => {
      if (error?.message) {
        this.toastrService.error(error.message);
      } else {
        //  // console.log(error);
      }
      this.spinner.hide();

    });
  }

  getPlanExtendedData() {
    let data = {
      user_id: this.user_id,
      active_transaction_id: this.currentActivePlan.id,
    };
    this.spinner.show();
    this.myProfileService.getPlanExtendedData(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.plan_extended_history = res.plan_extended_history;

      }
    }, (error) => {
      if (error?.message) {
        this.toastrService.error(error.message);
      } else {
        //  // console.log(error);
      }
      this.spinner.hide();

    });
  }

  removeDuplicates(arr) {
    let uniqueIds = new Set();
    return arr.filter(obj => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  }

  getAlertTypes() {
    this.myProfileService.getAlertTypes().subscribe(
      (result) => {
        if (result.status) {
          this.alert_types = result.alert_types;
          this.getUserSettings();
        } else {

          this.getUserSettings();
        }
      }, (error) => {
        this.getUserSettings();
      })
  }

  EditPartnerPrefrence() {
    localStorage.setItem('selectedTabpp', 'PartnerPrefrence');
    this.router.navigate(['myInfo']);
  }

  EditPrivacyOption() {
    $('#v-pills-profile-tab').click();
    this.editphonNumber();
  }

  unHideAccount() {
    let data = {
      user_id: this.user_id,
      hide_profile_till: this.dateString
    }
    this.myProfileService.unHideProfile(data).subscribe((res: any) => {
      if (res.status) {
        this.toastrService.success(res.message);
        this.checkAccount();
        let str = this.userDetailsSetting.hide_profile_till
        console.log(str.substring(0,9))     
      } else {
        this.toastrService.error(res.message);
      }
    });
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  checkAccount() {
    this.userDetailData();
    this.getUserSettings();
    if (this.userDetailsSetting.hide_profile_till && this.userDetails.is_hidden == 1) {
      this.dateRequired = true;
      const parts = this.tillDate.split("/");
      let mm = parseInt(parts[1]) - 1
      this.dateF.start.setValue(new Date(parseInt(parts[0]), mm, parseInt(parts[2])))
    } else {
      this.tillDate = ""
      this.dateF.start.setValue(new Date(""))
    }
  }

  hideAccount() {
    const date3 = new Date();
    let data = {
      user_id: this.user_id,
      hide_profile_till: this.dateString,
      offset: date3.getTimezoneOffset()
    }
    this.myProfileService.hideProfile(data).subscribe((res: any) => {
      this.userDetails = res;
      if (res.status) {
        this.dateRequired = true;
        this.toastrService.success(res.message);
        this.checkAccount();
      } else {
        this.toastrService.error(res.message);
      }
    });
  }

  deleteReason(event) {
    this.reason = event.target.value
    if (event.target.value != "Other") {
      this.hideSubmit = true;
    } else {
      this.hideSubmit = false;
    }
  }

  deleteReasonsExperience() {
    this.reason = "Found match on Eternal Strings?"
  }

  deleteReasonsFeedback() {
    this.reason = "Changed your mind?"
  }

  deleteReasonsBlockReason() {
    this.reason = "Unsatisfactory experience on Eternal Strings, Misuse"
    // this.blockReason = 
  }

  deleteOtherReason(textContent) {
    this.reason = 'Other,' + '' + textContent;
  }

  // before sonar
  // resetDeleteForm() {
  //     this.Experience = "",
  //     this.Feedback = "",
  //     this.blockReason = "",
  //     this.deleteForm.reset();
  // }

// after sonar
  resetDeleteForm() {
    this.Experience = "";
    this.Feedback = "";
    this.blockReason = "";
    this.deleteForm.reset();
}

  deleteProfile() {
    let data = {
      user_id: this.user_id,
      reason: this.reason,
      experience: this.Experience,
      feedback: this.Feedback,
      block_reason: this.blockReason,
      password: this.deleteForm.value.password,
      action: this.deleteBy
    }
    this.myProfileService.deleteProfile(data).subscribe((res: any) => {
      if (res.status) {
        this.toastrService.success(res.message);
        if (this.deleteBy == 'delete_profile') {
          this.resetDeleteForm();
          this.commonService.updateProfilePhoto('assets/images/default-profile-picture.svg');
          this.commonService.setRegisterDoneStep(0);
          this.router.navigate(['register']);
        } else if (this.deleteBy == 'delete_account') {
          this.auth.setAuthState(false);
          this.token.removeToken();
          // console.log('this.cookieService.get', this.cookieService.get('auth_token'))
          this.cookieService.delete('auth_token');
          this.delete_cookie('auth_token');
          this.router.navigate(['home']);
          this.spinner.hide();
        }
      } else {
        this.toastrService.error(res.message);
      }
    });
  }

  delete_cookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  getDetailpass() {
    // console.log(this.deleteForm.value.password)
  }

  onDateChange(event): void {
    let Ndate = event.value
    this.dateString = new DatePipe('en-US').transform(Ndate, 'dd-MM-yyyy');
    this.dateRequired = false
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    // Only highligh dates inside the month view.
    if (view === 'month') {
      const date = cellDate.getDate();
      // Highlight the 1st and 20th day of each month.
      return date === 1 || date === 20 ? 'example-custom-date-class' : '';
    }
    return '';
  };

  userDetail() {
    let data1 = {
      user_id: this.user_id,
    };
    this.spinner.show();
    this.registerService.getUser(data1).subscribe((res: any) => {
      this.userData = res.userDetails;

      this.phoneCode = '';
      this.countries.forEach((country) => {
        if (this.userData.countryNumberCode == country.country_code) {
          this.phoneCode = country.country_code
        }
      });
      this.spinner.hide();
    })
  }

  userDetailData() {
    let data1 = {
      user_id: this.user_id,
    };
    this.spinner.show();
    this.registerService.getUserDetails(data1).subscribe((res: any) => {
      if (res.status) {
        this.userDetails = res.userDetails;
        let stepDone = this.userDetails.register_done;
        // console.log(res, "user dataaaa", res.userDetails, "sagag", this.registerDone)
        if (stepDone == 7) {
          this.registerDone = true;
        } else {
          this.registerDone = false;
        }
        if (this.userDetails.is_hidden == 1) {
          this.hideUnhideBtn = true;
        } else {
          this.hideUnhideBtn = false;
        }

        this.DisplayName = this.userDetails.display_name;
        this.dNameStr1 = this.userDetails.unique_key;
        this.spinner.hide();
        this.blankUserDetail = false;
      } else {
        this.blankUserDetail = true;
      }
    })
  }

  activePlanNow(user_id, package_id, transaction_id) {
    let activePlanNowReq = {
      user_id: user_id,
      package_id: package_id,
      transaction_id: transaction_id,
    };
    this.spinner.show();
    this.myProfileService.activePlanNow(activePlanNowReq).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.toastrService.success(res.message);
        this.getUserPlans()
      }
    }, (error) => {
      if (error?.message) {
        this.toastrService.error(error.message);
      } else {
        console.log(error);
      }
      this.spinner.hide();
    });
  }

  getUserPlans() {
    let getUserPlansReq = {
      user_id: this.user_id,
    };
    this.myProfileService.getUserPlans(getUserPlansReq).subscribe((res: any) => {
      if (res.status) {
        this.expired_plan = res.expired_plan;
        this.active_plan = res.active_plan;
      }
    }, (error) => {
      if (error?.message) {
        this.toastrService.error(error.message);
      } else {
        console.log(error);
      }
    });
  }

  getTabInfo(event) {
    // console.log(event.target.id)
    window.scroll(0, 0);
  }

  saveAccount() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }
    let number = this.contactForm.value.countryCode + "-" + this.contactForm.value.alternate_mobile_no
    let myAccount = {
      user_id: this.user_id,
      alternate_email: this.contactForm.value.alternate_email ? this.contactForm.value.alternate_email : '',
      alternate_mobile_no: number,
    }
    this.myProfileService.updateUserSettings(myAccount).subscribe((res: any) => {
      if (res.status) {
        this.toastrService.success(res.message);
        this.getUserSettings();
        this.isEditContactDetail = false;
      } else {
        this.toastrService.error(res.message);
      }
    });
  }

  disabled() {
    this.resetPasswordForm.reset();
    this.passvalid = true;
    this.errormsg = ""
    this.err = true;
    this.disabledBox = true;
    this.isEditContactDetail = false;
    this.emailValid = true;
    this.contactForm.reset();
    this.userDetail();
    this.minNumberLength = false;
    this.maxNumberLength = false;
  }

  enableBox() {
    this.disabledBox = !this.disabledBox;
  }

  editContactDetail() {
    this.isEditContactDetail = true;
    if (this.userDetails.first_name != '' && this.userDetails.first_name != null) {
      this.displayNameF.first_name.setValue(this.userDetailsSetting.first_name);
    }

    if (this.userDetailsSetting.alternate_email != '' && this.userDetailsSetting.alternate_email != null) {
      this.g.alternate_email.setValue(this.userDetailsSetting.alternate_email)
    }
    if (this.userDetailsSetting.alternate_mobile_no != '' && this.userDetailsSetting.alternate_mobile_no != null) {
      if (this.userDetailsSetting.alternate_mobile_no.includes("-")) {
        let dd = this.userDetailsSetting.alternate_mobile_no.split('-');
        this.countries.forEach((country) => {
          if (dd[0] == country.country_code) {
            this.phoneCode = country.country_code
          }
        });
        this.g.countryCode.setValue(dd[0]);
        this.g.alternate_mobile_no.setValue(dd[1])
      } else {
        this.g.alternate_mobile_no.setValue(this.userDetailsSetting.alternate_mobile_no)
      }
    }
  }

  //change password code...........
  matchPassword(event: any) {
    if (this.resetPasswordForm.value.newPassword && this.resetPasswordForm.value.ConfirmPassword) {
      this.showPasswordMsg1 = true;
      let np = (this.resetPasswordForm.value.newPassword);
      let cp = (this.resetPasswordForm.value.ConfirmPassword);
      if (np == cp) {
        this.passmatch = true;
        this.sBtn = true;
        this.passwordBtn = true
        return true;
      } else {
        this.passmatch = false;
        this.sBtn = false;
        this.showPasswordMsg1 = true;
        this.passwordBtn = false;
        return false;
      }
    } else {
      this.showPasswordMsg1 = false;
    }
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }

  showHidePassword1() {
    this.showPassword1 = !this.showPassword1;
  }

  showHidePassword2() {
    this.showPassword2 = !this.showPassword2;
  }

  get g() {
    return this.contactForm.controls;
  }

  get dateF() {
    return this.campaignOne.controls;
  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  emailCheck(event) {
    // before sonar
    // let regex = new RegExp('^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
    // after sonar
    let regex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let ch1 = regex.test(event.target.value);
    if (event.target.value) {
      // before sonar
      // if (ch1 == true) {
        // after sonar
        if (ch1) {
        this.emailValid = true;
      } else {
        this.emailValid = false;
      }
    } else {
      this.emailValid = false;
    }
  }

  validatePassword(event: any) {
    if ((event.target.value).length >= 8) {
      let regex = new RegExp(/^(?!.* )(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*?[#?!@$%^&*-]).{8,15}$/)
      let ch1 = regex.test(event.target.value);
      // before sonar
      // if (ch1 == true) {
        // after sonar
      if (ch1) {
        this.passvalid = true;
        this.errormsg = ""
        this.matchPassword(ch1)
      } else {
        this.passvalid = false;
        this.err = false;
        this.errormsg = "Password must have a minimum of 8 characters including at least one Capital, one number, and one special character"
      }
    } else {
      this.errormsg = "Password must have a minimum of 8 characters including at least one Capital, one number, and one special character"
      this.passvalid = false;
    }
  }

  resetPasswordDone() {
    // before sonar
    // if (this.passvalid == true) {
    //   if (this.passmatch == true) {
    //     if (this.sBtn == true) {
      // after sonar
          if (this.passvalid) {
            if (this.passmatch) {
              if (this.sBtn) {
          this.submitted = true;
          let data = {
            user_id: this.user_id,
            password: this.resetPasswordForm.value.newPassword,
          }
          this.myProfileService.updatePassword(data).subscribe((res: any) => {
            if (res.status == true) {
              this.resetPasswordForm.reset();
              this.disabled();
              this.toastrService.success(res.message);
            }
            if (res.status == false) {
              this.errmsg1 = res.message;
            }
          }, (error) => {
            console.error('API call failed:', error);
            this.toastrService.error('error caught in component!');
          });
        }
      } else {
        this.submitted = false;
      }
    } else {
      this.submitted = false;
      this.resetPasswordForm.reset();
    }
  }

  loginsettingsEmail(event: any) {
    if (event.target.id == 'no_otp_login') {
      let data = {
        user_id: this.user_id,
        sms_otp_login: false,
        email_otp_login: false,
        no_otp_login: true
      }
      this.myProfileService.updateUserSettings(data).subscribe((res: any) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();

        } else {
          this.toastrService.error(res.message);
        }
      });
    } else if (event.target.id == 'sms_otp_login') {
      let data1 = {
        user_id: this.user_id,
        sms_otp_login: true,
        email_otp_login: false,
        no_otp_login: false
      }
      this.myProfileService.updateUserSettings(data1).subscribe((res: any) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();

        } else {
          this.toastrService.error(res.message);
        }
      });
    } else if (event.target.id == 'email_otp_login') {
      let data2 = {
        user_id: this.user_id,
        email_otp_login: true,
        sms_otp_login: false,
        no_otp_login: false
      }
      this.myProfileService.updateUserSettings(data2).subscribe((res: any) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();

        } else {
          this.toastrService.error(res.message);
        }
      });
    }
  }

  loginsettingsEmail2(event: any) {
    if (event.target.value == 'false') {
      let data = {
        user_id: this.user_id,
        sms_otp_login: false,
        email_otp_login: false,
        no_otp_login: true,
        otp_login: false
      }
      this.myProfileService.updateUserSettings(data).subscribe((res: any) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();

        } else {
          this.toastrService.error(res.message);
        }
      });
    } else if (event.target.value == 'true') {
      let data1 = {
        user_id: this.user_id,
        sms_otp_login: true,
        email_otp_login: true,
        no_otp_login: false,
        otp_login: true
      }
      this.myProfileService.updateUserSettings(data1).subscribe((res: any) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();

        } else {
          this.toastrService.error(res.message);
        }
      });
    }
  }

  // privacy code....................................
  get displayNameF() {
    return this.userSettingForm1.controls;
  }

  get phonF() {
    return this.userSettingForm2.controls;
  }

  get emailF() {
    return this.userSettingForm3.controls;
  }

  get photo_visiblityF() {
    return this.userSettingForm4.controls;
  }

  get dobF() {
    return this.userSettingForm5.controls;
  }

  get viewed_by_othersF() {
    return this.userSettingForm6.controls;
  }

  get my_shortlistF() {
    return this.userSettingForm7.controls;
  }

  get my_favouriteF() {
    return this.userSettingForm8.controls;
  }

  get annual_income_visiblityF() {
    return this.userSettingForm9.controls;
  }

  get subscribe_for_updateF() {
    return this.userSettingForm10.controls;
  }

  get frequency_of_alertsF() {
    return this.userSettingForm11.controls;
  }

  get type_of_alertsF() {
    return this.userSettingForm12.controls;
  }

  get userSettingOtpF() {
    return this.userSettingOtp.controls;
  }

  get userSettingOtpF2() {
    return this.userSettingOtp2.controls;
  }

  editDisplay() {
    this.displayname = true;
    if (this.userDetails.first_name != '') {
      this.displayNameF.first_name.setValue(this.userDetails.first_name);
    }

    if (this.userDetails.last_name != '') {
      this.displayNameF.last_name.setValue(this.userDetails.last_name);
    }
    this.displayNameF.display_name.setValue(this.dNameStr);
    this.loaderData = false;
  }

  //  change(DisplayName){
  //   if (DisplayName=='DisplayName1') {
  //     this.DisplayName=this.userDetails.first_name + ' ' + this.userDetails.last_name[0];
  //     this.display_name_id = '1' ;

  //   } else if (DisplayName=='DisplayName2') {
  //     this.DisplayName= this.userDetails.last_name + ' ' + this.userDetails.first_name[0];
  //     this.display_name_id = '2' ;
  //   } else if (DisplayName=='DisplayName3') {
  //     this.display_name_id = '3' ;
  //     this.DisplayName=this.userDetails.unique_key;
  //     this.displayId = 3;

  //   }

  //  }


  cancelDisplay() {
    this.displayname = false;
  }

  //  saveDisplay() {
  //    // this.userSettings();
  //     this.displayname=false;
  //   }

  saveUserSettingForm1() {
    this.displayname = false;
    let data = this.userSettingForm1.value;
    data.unique_key = this.userDetails?.unique_key;

    this.myProfileService.updateUserSettings(data).subscribe(
      (result) => {
        if (result.status) {
          this.displayname = false;
          this.toastrService.success(result.message);
          this.getUserSettings();
          this.loaderData = false;
        } else {
          this.toastrService.error(result.message);
        }
      }, (error) => {
      })
  }

  editphonNumber() {
    this.phonNumber = true;
    this.phonF.contact_detail.setValue(this.phonNumberstr)
    this.loaderData = false;
  }

  cancelphonNumber() {
    this.phonNumber = false;
  }

  saveUserSettingForm2() {
    this.phonNumber = false;
    let data = this.userSettingForm2.value;
    this.myProfileService.updateUserSettings(data).subscribe(
      (res) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();
          this.loaderData = false;
        } else {
          this.toastrService.error(res.message);
        }
      });
  }

  editemailId() {
    this.emailId = true;
    this.getUserSettings();
    this.loaderData = false;
  }
  cancelemailId() {
    this.emailId = false;
  }

  saveUserSettingForm3() {
    this.emailId = false;
    let data = this.userSettingForm3.value;
    this.myProfileService.updateUserSettings(data).subscribe(
      (res) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();
          this.loaderData = false;
        } else {
          this.toastrService.error(res.message);
        }
      });
  }

  editphotos() {
    this.photos = true;
    this.photo_visiblityF.photo_visiblity.setValue(this.photosstr);
    this.loaderData = false;
  }

  cancelphotos() {
    this.photos = false;
  }

  saveUserSettingForm4() {
    this.photos = false;
    let data = this.userSettingForm4.value;
    this.myProfileService.updateUserSettings(data).subscribe(
      (res) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();
          this.loaderData = false;
        } else {
          this.toastrService.error(res.message);
        }
      });
  }

  editastro_detail() {
    this.astro_detail = true;
    this.dobF.astro_detail.setValue(this.dobstr);
    this.loaderData = false;
  }

  cancelastro_detail() {
    this.astro_detail = false;
  }

  saveUserSettingForm5() {
    this.astro_detail = false;
    let data = this.userSettingForm5.value;
    this.myProfileService.updateUserSettings(data).subscribe(
      (res) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();
          this.loaderData = false;
        } else {
          this.toastrService.error(res.message);
        }
      });
  }

  editviewBy() {
    this.viewBy = true;
    this.viewed_by_othersF.viewed_by_others.setValue(this.viewBystr);
    this.loaderData = false;
  }

  cancelviewBy() {
    this.viewBy = false;
  }
  
  saveUserSettingForm6() {
    this.viewBy = false;
    let data = this.userSettingForm6.value;
    this.myProfileService.updateUserSettings(data).subscribe(
      (res) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();
          this.loaderData = false;
        } else {
          this.toastrService.error(res.message);
        }
      });
  }

  editmyshortlist() {
    this.myshortlist = true;
    this.my_shortlistF.my_shortlist.setValue(this.myshortliststr);
    this.loaderData = false;
  }

  cancelmyshortlist() {
    this.myshortlist = false;
  }

  saveUserSettingForm7() {
    this.myshortlist = false;
    let data = this.userSettingForm7.value;
    this.myProfileService.updateUserSettings(data).subscribe(
      (res) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();
          this.loaderData = false;
        } else {
          this.toastrService.error(res.message);
        }
      });
  }

  editmyfavourit() {
    this.myfavourit = true;
    this.my_favouriteF.my_favourite.setValue(this.myfavouritstr);
    this.loaderData = false;
  }

  cancelmyfavourit() {
    this.myfavourit = false;
  }

  saveUserSettingForm8() {
    this.myfavourit = false;
    let data = this.userSettingForm8.value;
    this.myProfileService.updateUserSettings(data).subscribe(
      (res) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();
          this.loaderData = false;
        } else {
          this.toastrService.error(res.message);
        }
      });
  }

  editannualIncome() {
    this.annualIncome = true;
    this.annual_income_visiblityF.annual_income_visiblity.setValue(this.annualIncomestr);
    this.loaderData = false;
  }

  cancelannualIncome() {
    this.annualIncome = false;
  }

  saveUserSettingForm9() {
    this.annualIncome = false;
    let data = this.userSettingForm9.value;

    this.myProfileService.updateUserSettings(data).subscribe(
      (res) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();
          this.loaderData = false;
        } else {
          this.toastrService.error(res.message);
        }
      });
  }

  editsubscribe() {
    this.subscribe = true;
    this.subscribe_for_updateF.subscribe_for_update.setValue(this.userDetailsSetting.subscribe_for_update);
    this.loaderData = false;
  }

  cancelsubscribe() {
    this.subscribe = false;
  }

  saveUserSettingForm10() {
    this.subscribe = false;
    let data = {
      user_id: this.user_id,
      subscribe_for_update: this.userSettingForm10.value.subscribe_for_update
    }
    this.myProfileService.updateUserSettings(data).subscribe(
      (res) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();
          this.loaderData = false;
        } else {
          this.toastrService.error(res.message);
        }
      });
  }

  saveUserSettingForm11() {
    this.Frequency_of_alerts = false;
    let data = this.userSettingForm11.value;
    this.myProfileService.updateUserSettings(data).subscribe(
      (res) => {
        if (res.status) {
          this.toastrService.success(res.message);
          this.getUserSettings();
          this.loaderData = false;
        } else {
          this.toastrService.error(res.message);
        }
      });
  }

  cancelUserSettingForm11() {
    this.Frequency_of_alerts = false;
  }

  editfrequency_of_alerts() {
    this.Frequency_of_alerts = true;
    this.getUserSettings();
    this.loaderData = false;
  }

  edittype_of_alerts() {
    this.Type_of_alerts = true;
    this.userSettingForm12.reset();
    this.getAlertTypes();
  }


  checkboxId(event) {
    let Sid = (event.target.value)
    if (event.target.checked) {
      this.selectedIds.push(parseInt(Sid));
    } else {
      this.selectedIds = this.selectedIds.filter(m => m != Sid);
    }
    console.log(event.target.value, this.selectedIds, typeof (Sid), 'balram', Sid)
  }

  saveUserSettingForm12() {
    if (this.selectedIds == '') {
      this.toastrService.error("This is not complete yet, Please check any Alert");
      return;
    } else {
      this.Type_of_alerts = false;
      let data = {
        user_id: this.user_id,
        alert_types: this.selectedIds
      }
      console.log(this.selectedIds, 'this.selectedIds');
      this.myProfileService.updateUserSettings(data).subscribe(
        (res) => {
          if (res.status) {
            this.toastrService.success(res.message);
            this.getUserSettings();
            this.loaderData = false;
          } else {
            this.toastrService.error(res.message);
          }
        });
    }
  }

  cancelUserSettingForm12() {
    this.Type_of_alerts = false;
  }

  getDetailSE() {
    this.getUserSettings();
    this.loaderData = false;
  }

  getUserSettings() {
    this.loaderData = true;
    this.alert_types_arr = [];
    this.selectedIds = [];
    this.frequency_of_alert = '';
    let data = {
      user_id: this.user_id,
    }

    this.myProfileService.getUserSettings(data).subscribe((res: any) => {
      if (res.status) {
        this.userDetailsSetting = res.userSettings;
        this.loaderData = false;
        if (!!this.userDetailsSetting) {
          // 1 DisplayName
          if (this.userDetailsSetting.display_name != null && this.userDetailsSetting.display_name != '') {
            this.dNameStr = this.userDetailsSetting.display_name;
          } else {
            this.dNameStr = 'Hide my full name';
            this.displayNameF.display_name.setValue('Hide my full name');
          }
          //2 contact         
          if (this.userDetailsSetting.contact_detail != null && this.userDetailsSetting.contact_detail != '') {
            this.phonNumberstr = this.userDetailsSetting.contact_detail;
            this.phonF.contact_detail.setValue(this.userDetailsSetting.contact_detail);
          } else {
            this.phonNumberstr = "Only by Permissions";
            this.phonF.contact_detail.setValue('Only by Permissions');
          }
          //email          
          if (this.userDetailsSetting.email != null && this.userDetailsSetting.email != '') {
            this.emailIdstr = this.userDetailsSetting.email;
          } else {
            this.emailIdstr = "Both";
          }
          //3 photo_visiblity
          if (this.userDetailsSetting.photo_visiblity != null && this.userDetailsSetting.photo_visiblity != '') {
            this.photosstr = this.userDetailsSetting.photo_visiblity;
            this.photo_visiblityF.photo_visiblity.setValue(this.userDetailsSetting.photo_visiblity);
          } else {
            this.photosstr = "Visible to all members";
            this.photo_visiblityF.photo_visiblity.setValue('Visible to all members');
          }
          //4 astro_detail
          if (this.userDetailsSetting.astro_detail != null && this.userDetailsSetting.astro_detail != '') {
            this.dobstr = this.userDetailsSetting.astro_detail;
            this.dobF.astro_detail.setValue(this.userDetailsSetting.astro_detail);
          } else {
            this.dobstr = "Only by Permissions";
            this.photo_visiblityF.photo_visiblity.setValue('Only by Permissions');
          }
          //5 viewed_by_others 
          if (this.userDetailsSetting.viewed_by_others != null && this.userDetailsSetting.viewed_by_others != '') {
            this.viewBystr = this.userDetailsSetting.viewed_by_others;
            this.viewed_by_othersF.viewed_by_others.setValue(this.userDetailsSetting.viewed_by_others);
          } else {
            this.viewBystr = "Let other Members know that I have visited their Profile.";
            this.viewed_by_othersF.viewed_by_others.setValue('Let other Members know that I have visited their Profile.');
          }
          //6 my_shortlist
          if (this.userDetailsSetting.my_shortlist != null && this.userDetailsSetting.my_shortlist != '') {
            this.myshortliststr = this.userDetailsSetting.my_shortlist;
            this.my_shortlistF.my_shortlist.setValue(this.userDetailsSetting.my_shortlist);
          } else {
            this.myshortliststr = "Let other Members know that I have Shortlisted their Profile.";
            this.my_shortlistF.my_shortlist.setValue('Let other Members know that I have Shortlisted their Profile.');
          }
          //7 my_favourite 
          if (this.userDetailsSetting.my_favourite != null && this.userDetailsSetting.my_favourite != '') {
            this.myfavouritstr = this.userDetailsSetting.my_favourite;
            this.my_favouriteF.my_favourite.setValue(this.userDetailsSetting.my_favourite);
          } else {
            this.myfavouritstr = "Let other Members know that I have Favourite their Profile.";
            this.my_favouriteF.my_favourite.setValue('Let other Members know that I have Favourite their Profile.');
          }
          //8 annual_income_visiblity
          if (this.userDetailsSetting.annual_income_visiblity != null && this.userDetailsSetting.annual_income_visiblity != '') {
            this.annualIncomestr = this.userDetailsSetting.annual_income_visiblity;
            this.annual_income_visiblityF.annual_income_visiblity.setValue(this.userDetailsSetting.annual_income_visiblity);
          } else {
            this.annualIncomestr = "Visible to all members";
            this.annual_income_visiblityF.annual_income_visiblity.setValue('Visible to all members');
          }
          if (this.userDetailsSetting.subscribe_for_update == 1 || this.userDetailsSetting.subscribe_for_update == "true") {
            this.subscribestr = 'Unsubscribe';
            this.subscribe_for_updateF.subscribe_for_update.setValue(true);
          } else {
            this.subscribestr = 'Subscribe';
            this.subscribe_for_updateF.subscribe_for_update.setValue(false);
          }
          // privacy 9 point end      

          if (this.userDetailsSetting.hide_profile_till != "" && this.userDetailsSetting.hide_profile_till != null && this.userDetailsSetting.hide_profile_till != "0000-00-00 00:00:00") {
            this.hideTillDate = this.userDetailsSetting.hide_profile_till
            this.tillDate = new DatePipe('en-US').transform(this.userDetailsSetting.hide_profile_till, 'YYYY/MM/dd');
          } else {
            this.hideTillDate = ""
          }

          if (this.userDetailsSetting.otp_login == "1") {
            this.userSettingOtpF2.action.setValue('true');
            this.email_otp_login = true;
            this.sms_otp_login = true;
          } else {
            this.userSettingOtpF2.action.setValue('false');
            this.no_otp_login = true;
            this.sms_otp_login = false;
            this.email_otp_login = false;
          }

          if (this.userDetailsSetting.otp_login == "0") {
            localStorage.removeItem('device_verified');
            const device_verified_for = localStorage.getItem('device_verified_for');
            if (device_verified_for != null && device_verified_for == this.user_id) {
              localStorage.removeItem('device_verified_for');
            }
          }

          if (this.userDetailsSetting.subscribe_for_update == "true" || this.userDetailsSetting.subscribe_for_update == "1") {
            this.subscribe_for_updateF.subscribe_for_update.setValue(true);
          } else {
            this.subscribe_for_updateF.subscribe_for_update.setValue(false);
          }

          if (this.userDetailsSetting.frequency_of_alert == 1) {
            $('#DisplayName21').prop('checked', true);
            this.frequency_of_alert = 'Instantly';
          } else if (this.userDetailsSetting.frequency_of_alert == 2) {
            $('#DisplayName22').prop('checked', true);
            this.frequency_of_alert = 'End of each day';
          } else if (this.userDetailsSetting.frequency_of_alert == 3) {
            $('#DisplayName23').prop('checked', true);
            this.frequency_of_alert = 'Every other day';
          } else if (this.userDetailsSetting.frequency_of_alert == 4) {
            $('#DisplayName24').prop('checked', true);
            this.frequency_of_alert = 'Weekly';
          } else if (this.userDetailsSetting.frequency_of_alert == 5) {
            $('#DisplayName25').prop('checked', true);
            this.frequency_of_alert = 'Biweekly';
          } else if (this.userDetailsSetting.frequency_of_alert == 0) {
            $('#DisplayName26').prop('checked', true);
            this.frequency_of_alert = 'No notifications';
          } else if (this.userDetailsSetting.frequency_of_alert == '' || this.userDetailsSetting.frequency_of_alert == null) {
            $('#DisplayName21').prop('checked', true);
            this.frequency_of_alert = 'Instantly';
            console.log()
          }

          if (this.userDetailsSetting.alert_types
            && this.userDetailsSetting.alert_types
              .length > 0) {
            this.text1 = (this.userDetailsSetting.alert_types).split(',').map(Number);
            this.selectedIds = [];
            this.alert_types_arr = [];

            for (const [key, value] of this.alert_types.entries()) {
               console.log(`Key: ${key}, Value: ${value}`);
              for (const [key1, value1] of this.text1.entries()) {
                console.log(`Key1: ${key1}, Value1: ${value1}`);
                if (this.alert_types[key].id == value1) {
                  this.alert_types[key].isChecked = true;
                  this.alert_types_arr.push(this.alert_types[key].alert_name);
                  this.selectedIds.push(value1);
                }
              }
            }
          } else if (this.userDetailsSetting.alert_types
            .length == 0) {
              console.log('else if')
            this.userSettingForm12.reset();
            this.text1 = [];
            this.selectedIds = [];
            this.alert_types_arr = [];

            for (const [key, value] of this.alert_types.entries()) {
              console.log(`Key: ${key}, Value: ${value}`);
              for (const [key1, value1] of this.text1.entries()) {
                console.log(`Key1: ${key1}, Value1: ${value1}`);
                if (this.alert_types[key].id == value1) {
                  this.alert_types[key].isChecked = true;
                  this.alert_types_arr.push(this.alert_types[key].alert_name);
                  this.selectedIds.push(value1);
                }
              }
            }
          } else {
            console.log('else case of this.userDetailsSetting.alert_types')
          }
        } else {
          this.dNameStr = this.dNameStr1;
          this.phonNumberstr = "Only by Permissions";
          this.photosstr = "Visible to all members";
          this.dobstr = "Only by Permissions";
          this.viewBystr = "Let other Members know that I have visited their Profile.";
          this.myshortliststr = "Let other Members know that I have Shortlisted their Profile.";
          this.myfavouritstr = "Let other Members know that I have Favourite their Profile.";
          this.annualIncomestr = "Visible to all members";
          this.subscribestr = 'Subscribe';
          this.displayNameF.unique_key.setValue(this.dNameStr1);
          this.phonF.contact_detail.setValue('Only by Permissions');
          this.photo_visiblityF.photo_visiblity.setValue('Visible to all members');
          this.dobF.astro_detail.setValue('Only by Permissions');
          this.viewed_by_othersF.viewed_by_others.setValue('Let other Members know that I have visited their Profile.');
          this.my_shortlistF.my_shortlist.setValue('Let other Members know that I have Shortlisted their Profile.');
          this.my_favouriteF.my_favourite.setValue('Let other Members know that I have Favourite their Profile.');
          this.annual_income_visiblityF.annual_income_visiblity.setValue('Visible to all members');
          this.subscribe_for_updateF.subscribe_for_update.setValue(false);
          $('#off_otp').prop('checked', true);
        }
      }
      else {
        this.toastrService.error(res.message);
        this.loaderData = false;
      }

    }, (error) => {
      this.loaderData = false;
    })

  }

  get h() {
    return this.deleteForm.controls;
  }

  matchPassword1(event: any) {
    if (this.deleteForm.value.password != '') {
      this.passRequired = false;
    } else {
      this.passRequired = true;

    }
    if (this.deleteForm.value.password && this.deleteForm.value.matchPassword) {
      this.showPasswordMsg = true;
      if (this.deleteForm.value.password) {

        let np = (this.deleteForm.value.password);
        let cp = (this.deleteForm.value.matchPassword);
        if (np == cp) {
          this.pwmatch = true;
          this.sBtn = true;
          return true;
        } else {
          this.pwmatch = false;
          this.sBtn = false;
          return false;
        }
      }
    } else {
      this.showPasswordMsg = false;
    }

  }

  keyPressNumeric(event) {
    const pattern = /\D/g;
    if (this.contactForm.value.alternate_mobile_no.length < 6) {
      this.minNumberLength = true;
      this.maxNumberLength = false;
    } else if (this.contactForm.value.alternate_mobile_no.length > 15) {
      this.minNumberLength = false;
      this.maxNumberLength = true;
    } else {
      this.minNumberLength = false;
      this.maxNumberLength = false;
    }
    if (!pattern.test(event.target.value) && (this.contactForm.value.alternate_mobile_no.length > 6 && this.contactForm.value.alternate_mobile_no.length < 16)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumeric(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.contactForm.patchValue({ 'alternate_mobile_no': newText });
  }

  OnchangeCountryCode(event) {
    if (event) {
      this.contactForm.patchValue({ 'countryCode': event.country_code });
      this.contactForm.patchValue({ 'countryNumberCode': event.country_code });
    }
  }


  deleteSetting(event: any) {
    if (event.target.id == 'delete_account') {
      this.deleteBy = event.target.id;
    } else if (event.target.id == 'delete_profile') {
      console.log()
      this.deleteBy = event.target.id;
    }
  }

  editProfile() {
    localStorage.setItem('selectedTab', 'aboutYou');
    this.router.navigate(['myInfo']);
  }

  getDeletedProfilesByUserId() {
    let data = {
      user_id: this.user_id,
    }
    this.myProfileService.getDeletedProfilesByUserId(data).subscribe((res: any) => {
      if (res.status) {
        this.deletedProfileList = res.profileList;
      }
      else {
        //unused code
        // this.toastrService.error(res.message);
        this.loaderData = false;
      }
    }, (error) => {
      this.loaderData = false;
    })
  }

  hidecancel() {
    this.dateRequired = true;
  }

}
