import { Component, OnInit, ViewChild/*, ViewEncapsulation */} from '@angular/core';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup,FormControl,Validators, NgForm,} from '@angular/forms';
import { SteptwoService } from '../services/steptwo.service';
import { CookieService } from 'ngx-cookie-service';
import { Title/*, Meta */} from '@angular/platform-browser';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-steptwo',
  templateUrl: './steptwo.component.html',
  styleUrls: ['./steptwo.component.css']
})
export class SteptwoComponent implements OnInit {

  @ViewChild('registerInNgForm') registerInNgForm: NgForm;

  errorMessage: string;
  errors: any;
  isReadOnly:boolean=false;
   stepTwoForm= new FormGroup({
    // firstname: new FormControl('', [Validators.required, Validators.email]),
    user_id: new FormControl(''),
    qualification: new FormControl('',Validators.required),
    college_name: new FormControl(''),
    nature_of_work: new FormControl('',Validators.required),
    working_as: new FormControl(''),
    company_name: new FormControl(''),
    income_currency: new FormControl(''),
    annual_income: new FormControl(''),
  
   })
  jsonObj: any;
  submitted: boolean=false;
  buttonDisable: boolean=false;
  notWorking: boolean=true;
  name: string;
  currencyCode :any;
  countries: any;
  otherProfessionArea: boolean =false;
  otherWork: boolean=false;
  otherQualification: boolean=false;
   an :any;
  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private steptwoservices: SteptwoService,
    private cookieService: CookieService,
    private titleService: Title ,
    private authService: AuthService,

  ) { }

  ngOnInit(): void {
    window.scroll(0,0);
    this.titleService.setTitle('Step 2');

    let dd = this.cookieService.get('user_id');
    this.f.user_id.setValue(dd);
        let data ={
        user_id:dd
      }

      this.authService.getCountryList().subscribe(
        (result) => {
            this.countries = result.data;
            //before sonarqube
            // if(this.countries && this.countries[0] && this.countries[101].id) {
              if(this?.countries[0] && this.countries[101].id) {
              // unused code
           //   this.phoneCode = this.countries[0].id;
            // this.currencyCode =  this.countries[0].id;
            }})
    this.steptwoservices.getUserDetails(data)
    .subscribe((res:any) => { 
      
      if(res?.userDetails){
          let detail=res.userDetails 
          this.name=detail.first_name +" "+detail.last_name
          if (detail.register_done<=3){
            this.f.user_id.setValue(dd);
          }else{
          if(detail.qualification!=""){
            if (detail.qualification=="High School" ||detail.qualification=="Some College" ||detail.qualification=="Some University" ||detail.qualification=="Associate Degree"|| detail.qualification=="Associate's Degree" || detail.qualification=="Associate`s Degree"|| detail.qualification=="Bachelor Degree" ||detail.qualification=="Bachelor's Degree" ||detail.qualification=="Bachelor`s Degree" ||detail.qualification=="Bachelor`s Degree" ||detail.qualification=="Master Degree" ||detail.qualification=="Master's Degree" ||detail.qualification=="Graduate Degree" ||detail.qualification=="PhD/Post Doctoratest Doctoral" ||detail.qualification=="PhD/Post Doctorate"||detail.qualification=="PhD/Post Doctoratest Doctoral" ||detail.qualification=="Post Doctoral" ){
              this.f.qualification.setValue(detail.qualification)
            }else {
              this.otherQualification = true;
              this.f.qualification.setValue(detail.qualification)
            }
          }
          this.f.college_name.setValue(detail.college_name);
         
        if(detail.nature_of_work!=""){
          if (detail.nature_of_work=="Business" ||detail.nature_of_work=="Government Company" ||detail.nature_of_work=="Self Employed" || detail.nature_of_work=="Private Company"){
            this.f.nature_of_work.setValue(detail.nature_of_work)
          }else if(detail.nature_of_work=="Not Working"){
            this.notWorking = true;
            this.f.nature_of_work.setValue(detail.nature_of_work)
          }else {
            this.otherWork = true;
            this.f.nature_of_work.setValue(detail.nature_of_work)
          }
        }
         
          if(detail.nature_of_work != "Not Working"){
            this.notWorking = true; 
            if(detail.working_as!=""){
              if (detail.working_as=="Accounting - Banking & Finance"){
                this.f.working_as.setValue(detail.working_as)
              }else if(detail.working_as=="Administration & HR"){
                this.f.working_as.setValue(detail.working_as)
              }else if(detail.working_as=="Airline & Aviation"){
                this.f.working_as.setValue(detail.working_as)
              }else if(detail.working_as=="Agriculture"){
                this.f.working_as.setValue(detail.working_as)
              }else if(detail.working_as=="Beauty - Fashion & Jewellery Designers"){
                this.f.working_as.setValue(detail.working_as)
              }else if(detail.working_as=="Sales & Marketing"){
                this.f.working_as.setValue(detail.working_as)
              }else if(detail.working_as=="Merchant Navy"){
               this.f.working_as.setValue(detail.working_as)
              }else {
                this.otherProfessionArea = true;
                this.f.working_as.setValue(detail.working_as)
              }
            }
             if(detail && detail.income_currency != '' && detail.income_currency !=null ) {
                this.currencyCode = parseInt(detail.income_currency);
              } else if(detail.living_in_country) {
                this.currencyCode =parseInt(detail.living_in_country);
              } else {
                this.currencyCode=38;
              }

            this.f.company_name.setValue(detail.company_name);
            this.f.annual_income.setValue(detail.annual_income);
          } else {
            this.notWorking = false;
          }    
        }
     }
    });
  }

  get f() {
    return this.stepTwoForm.controls;
  }
  
  checkProfessionArea(){
    if(this.stepTwoForm.value.working_as=="Other"){
      this.f.working_as.setValue("");
    this.otherProfessionArea = true;
    }else {
      this.otherProfessionArea =false;
    }
  }

  educationCheck(){
    if(this.stepTwoForm.value.qualification=="Other"){
      this.f.qualification.setValue("");
    this.otherQualification = true;
    }else {
      this.otherQualification =false;
    }
  }

  hider(){
        this.notWorking = true; 

  }

  NatureOfWork(){
    let workVal = this.f.nature_of_work.value;
    if(workVal !="Other"){
      this.otherWork = false;
      if(workVal == "Not Working"){
        this.notWorking = false; 
        this.f.company_name.setValue("");
        this.f.income_currency.setValue("");
        this.f.annual_income.setValue("");
      }else{
        this.notWorking = true; 
      }
    }else{
      this.f.nature_of_work.setValue("");
      this.otherWork = true;
    } 
  }

  stepTwo(){
    this.submitted = true;
    this.buttonDisable = true;
    if (this.stepTwoForm.invalid) {
      this.buttonDisable = false ;
      this.scrollToFirstInvalidControl();
      return;
     }
    if (!this.f.income_currency.value){
      this.f.annual_income.setValue("");
    }


  let frmdata ={
    user_id:this.f.user_id.value,
    qualification: this.f.qualification.value,
    college_name: this.f.college_name.value ? this.f.college_name.value.trim() : '',
    nature_of_work: this.f.nature_of_work.value,
    working_as: this.f.working_as.value,
    income_currency: this.f.income_currency.value,
    annual_income: this.f.annual_income.value,
    company_name:this.f.company_name.value ? this.f.company_name.value.trim() : '',
  }
    this.errors = false;
    this.steptwoservices.registerFour(frmdata).subscribe(
      (result) => {
        if(result?.status){
          this.buttonDisable = true;
            this.stepTwoForm.reset();
            this.router.navigate(['stepDone']);
        }
      },
      (error) => {
        this.errors = error.error;
        this.buttonDisable = false;
      },
    );
  }

   OnchangeCountryCode(event) {
    this.stepTwoForm.patchValue({'income_currency': event.id});
  }
  
  //before sonarqube
//   private scrollToFirstInvalidControl() {
//     let form = document.getElementById('stepTwo'); // <-- your formID
//     let firstInvalidControl = form!.getElementsByClassName('ng-invalid')[0];
//     firstInvalidControl.scrollIntoView();
//     (firstInvalidControl as HTMLElement).focus();
// }

  //after sonarqube
  private scrollToFirstInvalidControl() {
    let form = document.querySelector<HTMLElement>('#step');
    if (form) {
      let firstInvalidControl = form.querySelector('.ng-invalid');

      if (firstInvalidControl) {
        (firstInvalidControl as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'center' });
        (firstInvalidControl as HTMLElement).focus();
      }
    }
  }

// .......content managment code start....
//before sonarqube
// NameSpaceNotAllowed(event: any) {
//   var RegEx = new RegExp(/^([a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"])*$/g);
//   var str = event.target.value
//   if (RegEx.test(str)) {
//     return event.target.value
//   } else {
//     var nameString = str.replace(/\s+/g, ' ');
//     var nameString = str.replace(/[^a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"]+/g, ' ');
//     event.target.value = nameString.replace(/^\s+/g, "");
//   }
// }

  //after sonarqube
  NameSpaceNotAllowed(event: any) {
    let RegEx = new RegExp(/^([a-zA-Z\d!@#$&()\-.+,%*=~[\]{}\\<>?'":])*$/g);
    let str = event.target.value;

    if (RegEx.test(str)) {
      return event.target.value;
    } else {
      let nameString = str.replace(/\s+/g, ' ');
      nameString = nameString.replace(/[^a-zA-Z\d!@#$&()\-.+,%*=\|~[\]{}\\<>\?'";]+/g, ' ');
      event.target.value = nameString.replace(/^\s+/g, "");
    }
  }

keyPressNumeric(event) {
  const pattern = /\D/g;
  if (!pattern.test(event.target.value)) {
    event.preventDefault();
    return true;
  } else {
    event.target.value = event.target.value.replace(/\D/g, "");
    event.preventDefault();
    return false;
  }
}

pasteNumeric(event) {
  let clipboardData = event.clipboardData || (<any>window).clipboardData;
  let pastedText = clipboardData.getData('text');
  let newText = pastedText.replace(/\D/g, "");
  this.stepTwoForm.patchValue({'annual_income': newText});
}

// .......content managment code end....
}
