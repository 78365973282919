<section class="my-5 mx-auto mx-width-900 min-screen-height" *ngIf="!contentLoader">

  <div class="row mt-136">
    <div class="col-12 my-3">


      <h2 class="text-center">
        <!-- <a href="javascript:void(0);" routerLink="/upgrade" (click)="backLocation()">
          <div class="btn btn-danger w-35-h-35_rm mx-2" ngbTooltip="Back">Back
          </div>
        </a> -->
        Select package from <span class="fw-bold text-danger">{{packages_obj[package_id]}}</span>
      </h2>
      <button style="display:none;" id="openModal2" data-bs-target="#deleteModal1" data-bs-toggle="modal">modal</button>

      <h4 class=" mt-2 text-center myDIV" *ngIf="CurrentPlanDetail" style="width: fit-content;margin-left: 36%;">Current
        package :
        <span class="text-danger ">{{CurrentPlanDetail?.package_name}} </span>
      </h4>
    </div>
  </div>
  <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 px-3 g-4 justify-content-center">
    <ng-container *ngFor="let package of packages; let i= index">
      <div class="col">
        <div class="card h-100 border border-1 shadpw-sm rounded-10 card-hover">
          <div class="card-body text-center">
            <ng-container *ngIf="package?.content_display==0">
              <p class="my-0 text-danger fw-bold lh-sm mb-2"
                style="font-size: 2.5em; font-weight: bold; margin-bottom: 10px;"><span
                  *ngIf="package?.currency=='CAD'">CA</span> <span
                  *ngIf="package?.currency=='USD'">US</span>{{package?.country_currency}} {{package?.amount}}</p>
              <p class="font-weight-600 p-sm text-muted"><span><del>{{package?.country_currency}}
                    {{package?.package_price}}</del></span>, <span>{{package?.country_currency}}
                  {{package?.discount_price}} <b>Off</b></span></p>
              <h5>for {{package?.duration}}</h5>

              <ng-container *ngIf="purchasePlan == 'upgrade'">

                <button class="btn btn-danger rounded-pill px-4 my-3 py-2 fw-bold fs-5"
                  *ngIf="this.CurrentPlanDetail == '' || this.CurrentPlanDetail == undefined"
                  (click)="startPayment(package?.id, package?.package_id, package?.stripe_item_id ,package)">
                  Continue </button>


                <button class="btn btn-danger rounded-pill px-4 my-3 py-2 fw-bold fs-5"
                  *ngIf="this.CurrentPlanDetail != '' && this.CurrentPlanDetail != undefined" (click)="startPayment(package?.id, package?.package_id, package?.stripe_item_id
                  ,package)" data-bs-target="#deleteModal1" data-bs-toggle="modal">
                  Continue</button>
              </ng-container>
              <button class="btn btn-danger rounded-pill px-4 my-3 py-2 fw-bold fs-5" *ngIf="purchasePlan == 'renewal'"
                (click)="startPayment1(package)">
                Continue</button>
            </ng-container>
            <div [innerHTML]="package?.package_feature" class="mx-auto text-start lh-lg mt-3">

            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>


<section class="my-5 mx-auto mx-width-900 min-screen-height" *ngIf="contentLoader">
  <div class="row mt-136">
    <div class="col-12 my-3">
      <h2 class="text-center">
        <h5 style="margin-left: 250px;"><span class="blank-box1 mt-3 animated-background-name"><span
              style="display:none ;">animation</span></span></h5>
      </h2>
    </div>
  </div>
  <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 px-3 g-4 justify-content-center">
    <ng-container *ngFor="let number of [0,1]">
      <div class="col">
        <div class="card h-100 border border-1 shadpw-sm rounded-10 card-hover">
          <div class="card-body text-center">
            <h4 class="card-title fw-bold mb-4 text-danger" style="margin-left: 50px;"><span
                class="blank-box1 mt-3 animated-background-name"><span style="display:none ;">animation</span></span>
            </h4>
            <ng-container>
              <div class="text-center">
                <h6 style="margin-left: 50px;"><span class="blank-box2 mt-3 animated-background-name"><span
                      style="display:none ;">animation</span></span></h6>
                <h5 style="margin-left: 50px;"><span class="blank-box1 mt-3 animated-background-name"><span
                      style="display:none ;">animation</span></span></h5>
              </div>
              <h1 style="margin-left: 30px;"><span class="blank-box23 mt-3 animated-background-name"><span
                    style="display:none ;">animation</span></span></h1>
            </ng-container>
            <div class="mx-auto text-start lh-lg mt-3"><span class="blank-box mt-3 animated-background-name"></span>
              <span class="blank-box mt-3 animated-background-name"></span>
              <span class="blank-box mt-3 animated-background-name"></span>
              <span class="blank-box mt-3 animated-background-name"></span>
              <span class="blank-box mt-3 animated-background-name"></span>
              <span class="blank-box mt-3 animated-background-name"></span>

            </div>
          </div>
        </div>
      </div>
    </ng-container>


  </div>
  <button style="display:none;" id="openModal2" data-bs-target="#deleteModal1" data-bs-toggle="modal"></button>
  <button style="display:none;" id="openDiscountModal" data-bs-target="#deleteModal1" data-bs-toggle="modal"></button>



</section>


<div class="w-100 bg-maroon">
  <app-footer></app-footer>
</div>


<div class="modal fade" id="deleteModal1" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">
        <div href="javascript:void(0)">
          <a href="javascript:void(0)" class="text-decoration-none" style="color:black">

            <p class=" mt-2 text-center" style="display: block;font-size: 1.5em;  margin-bottom: 6px;">Total Amount:
              <span class="text-danger fw-bold"> <span *ngIf="planDetails?.currency=='CAD'">CA</span> <span
                  *ngIf="planDetails?.currency=='USD'">US</span>{{planDetails?.country_currency}}
                {{planDetails?.amount}}
              </span>
            </p>

            <p class=" mt-2 text-center" style="display: block;font-size: 1.5em;  margin-bottom: 6px;"
              *ngIf="remainingAmount">
              Balance from the previous Plan
              :
              <span class="text-danger fw-bold" *ngIf="planDetails?.amount != amount"><span
                  *ngIf="planDetails?.currency=='CAD'">CA</span> <span
                  *ngIf="planDetails?.currency=='USD'">US</span>{{planDetails?.country_currency}}
                {{remainingAmount}} </span>
              <span class="text-danger fw-bold" *ngIf="planDetails?.amount == amount"><span
                  *ngIf="planDetails?.country==38">CA</span>{{planDetails?.country_currency}}
                00 </span>
              <p>


              <p class=" mt-2 text-center" style="display: block;font-size: 1.5em;  margin-bottom: 6px;">You need to
                Pay:
                <span class="text-danger fw-bold" *ngIf="planDetails?.amount != amount"><span
                    *ngIf="planDetails?.currency=='CAD'">CA</span> <span
                    *ngIf="planDetails?.currency=='USD'">US</span>{{planDetails?.country_currency}}
                  {{planDetails?.amount -
                  remainingAmount }} </span>
                <span class="text-danger fw-bold" *ngIf="planDetails?.amount == amount"><span
                    *ngIf="planDetails?.currency=='CAD'">CA</span> <span
                    *ngIf="planDetails?.currency=='USD'">US</span>{{planDetails?.country_currency}}
                  {{planDetails?.amount }}</span>
              <p>
          </a>

          <button class="btn btn-danger rounded-pill mt-5" data-bs-dismiss="modal" aria-label="Close"
            (click)="confirmPayment()">Upgrade Now </button>
        </div>

      </div>

    </div>
  </div>
</div>