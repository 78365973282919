<header class="navbar-dark bg-transparent p-0" (scroll)="onscroll()" [ngClass]="navbarfixed?'fixed':'open_cls nofixed'">
  <div class="px-3 mx-auto max-width-1200">
    <nav class="navbar navbar-expand-md">
      <!-- <a class="navbar-brand scroll-logo-none mr-5" href="javascript:void(0);" routerLink="/home"><img
          src="assets/images/main_logo.svg" alt=""></a> -->
          <a class="navbar-brand scroll-logo-none mr-5" href="javascript:void(0);" routerLink="/home" title="home"><img
            src="assets/images/main_logo.webp" alt="Description of eternalstrings logo" title="ETERNALSTRINGS LOGO"></a>
            <link rel="preload" as="image" href="assets/images/main_logo.webp"/>
      <!-- <a class="navbar-brand scroll-logo mr-5" href="javascript:void(0);" routerLink="/home"><img
          src="assets/images/second_main_logo.svg" alt=""></a> -->
          <a class="navbar-brand scroll-logo mr-5" href="javascript:void(0);" routerLink="/home" title="home"><img
            src="assets/images/main_logo.webp" alt="Description of eternalstrings logo" title="ETERNALSTRINGS LOGO"></a>
            <link rel="preload" as="image" href="assets/images/main_logo.webp"/>
      <button class="navbar-toggler p-1 border border-2 border-white" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
        aria-label="Toggle navigation">
        <i class="fas fa-bars text-white fs-3"></i>
      </button>
      <div class="collapse navbar-collapse rounded-10 p-3 p-lg-0" id="navbarNavDropdown">
        <ul class="navbar-nav m-auto">
          <li class="nav-item my-2">
            <a class="nav-link mx-2 fw-bold text-white" routerLinkActive="active" routerLink="/home" title="Home"> Home
            </a>
          </li>
          <li class="nav-item my-2">
            <a class="nav-link mx-2 fw-bold text-white" routerLinkActive="active" routerLink="/about"  title="About"> About
              Us </a>
          </li>
          <ng-container >
          <li class="nav-item my-2">
            <a class="nav-link mx-2 fw-bold text-white" routerLinkActive="active" routerLink="/blog"  title="Blog"> Blog </a>
          </li>

        </ng-container>
        </ul>
        <div class="form-inline my-2 my-lg-0">
          <button style="display:none;" id="openLoginModal" data-bs-target="#loginModal"
            data-bs-toggle="modal"></button>
          <button class="btn fw-bold btn-light my-2 my-sm-0 mx-1" data-bs-toggle="modal" data-bs-target="#loginModal"
            type="button" (click)="changemodel1()">Login</button>
          <button class="btn fw-bold btn-outline-light my-2 my-sm-0 mx-1" href="javascript:void(0);"
            routerLink="/registerDone" role="button" type="submit" title="Sign-Up">Sign-Up </button>
        </div>

      </div>
    </nav>
  </div>
</header>

<button style="display:none;" id="openModal2" data-bs-target="#deleteModal1" data-bs-toggle="modal"></button>

<!---------------- Login-Modal------------ -->

<div class="modal fade" id="deleteModal1" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close"  id="deleteModalupgade">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo_webp.webp" alt="Description of eternalstrings logo" title="ETERNALSTRINGS LOGO" width="200" class="mb-6">
        <div href="javascript:void(0)" routerLink="/registerDone" data-bs-toggle="modal" data-bs-dismiss="modal" title="registerDone">
          <a href="javascript:void(0)" routerLink="/registerDone" class="text-decoration-none" data-bs-toggle="modal"
            data-bs-dismiss="modal" style="color:black" title="registerDone">

            <h4 class="mt-2"> Sign Up for free</h4>
            <h5 class=" ">Start finding your life partner</h5>

          </a>
        </div>

      </div>

    </div>
  </div>
</div>
<div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
      <div class="modal-header border-0 pt-3 bg-maroon mb-3">
        <img src="assets/images/second_main_logo_webp.webp" class="mx-auto" alt="Description of eternalstrings logo" title="ETERNALSTRINGS LOGO" width="130px">
        <button type="button" class="btn position-absolute top-0 right-0 m-2 center w-30-h-30 white-cross"
        id="closeLoginModal"
        data-bs-dismiss="modal" (click)="close()" aria-label="Close">
            <i class="fas fa-times fs-5"></i>
          </button>
      </div>
      <div class="modal-body p-0" *ngIf="!showmodel && !showmodelOtp">
        <div class="col-12 px-0">
          <div *ngIf="errors?.email" class="alert alert-danger mt-3">
            {{ errors?.email }}
          </div>
          <div *ngIf="errors?.password" class="alert alert-danger mt-3">
            {{ errors?.password }}
          </div>
          <div *ngIf="errors?.error" class="alert alert-danger mt-3">
            {{ errors?.error }}
          </div>
          <form #signInNgForm="ngForm" [formGroup]="signInForm">
            <div class="mb-3 px-3">
              <label for="exampleFormControlInput1" class="form-label">Mobile No. / Email ID</label>
              <input type="text" maxLength="100" class="form-control" id="exampleFormControlInput1" placeholder="Enter"
                [formControlName]="'email'">
              <div *ngIf="submitted && f.email.errors">
                <div *ngIf="f.email.errors.required">
                  <div class="form-label mb-2 py-1 fw-bold text-danger">This Field is required</div>
                </div>
              </div>
            </div>

            <div class="mb-3 px-3">
              <label for="exampleFormControlInput2" class="form-label">Password</label>
              <div class="position-relative">
                <input [type]=" showPassword ? 'text' : 'password' " class="form-control pe-5"
                  id="exampleFormControlInput2" placeholder="Enter" [formControlName]="'password'">
                <div class="position-absolute eye-btn right-0 text-danger">
                  <div class="position-absolute eye-btn right-0 text-danger" (click)='showHidePassword()'>
                    <div *ngIf="showPassword"> <i class="fa fa-eye"></i> </div>
                    <div *ngIf="!showPassword"><i class="fa fa-eye-slash"></i></div>
                  </div>
                </div>
              </div>
              <div>
                <div class="form-label mb-2 py-1 fw-bold text-danger">{{errmsg}}</div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-6 my-auto">
                <div class="form-check d-flex m-0 p-0 align-items-center">
                  <input class="checkbox" type="checkbox" id="flexCheckDefault" [formControlName]="'remember_me'">
                  <label class="ms-2 form-check-label d-flex align-items-center" for="flexCheckDefault">
                    Remember me <span class="btn p-0 my-0 mx-1 border-0 mt-1" placement="top"
                      ngbTooltip="We recommend that you do not use this feature if you are signing in from a shared computer.">
                      <i class="fas fa-question-circle fs-5 p-0 m-0 text-danger"></i> </span>
                  </label>
                </div>
              </div>
              <div class="col-6 ps-0 text-end">
                <button type="submit" class="btn btn-danger px-3" [disabled]="signInForm!.invalid"
                  (click)="login()">Login</button>
                <button style="display:none;" id="openModalOtp" data-bs-target="#upgradeModalOtp"
                  data-bs-toggle="modal"></button>
              </div>
              <div class="col-12 mt-2 text-end">
                <a href="javascript:void(0);" class="text-decoration-underline text-danger"
                  (click)="changemodel()">Forgot Password?</a>
              </div>
              <div class="col-12 mt-3 py-2 bg-maroon w-100">
                <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                    class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                      class="fas fa-long-arrow-alt-left"></i> Go
                    to eternalstrings.com</a> </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- otp modal start -->
      <div class="modal-body p-0" *ngIf="showmodelOtp">
        <div class="col-12 px-0">
          <div *ngIf="errors?.email" class="alert alert-danger mt-3">
            {{ errors?.email }}
          </div>
          <div *ngIf="errors?.otp" class="alert alert-danger mt-3">
            {{ errors?.password }}
          </div>
          <div *ngIf="errors?.error" class="alert alert-danger mt-3">
            {{ errors?.error }}
          </div>
          <form #signInNgForm="ngForm" [formGroup]="otpForm" (keydown.enter)="$event.preventDefault()">

            <div class="mb-3 px-3">

              <div *ngIf="verify_by == 'sms'">
                <div class="form-label mb-2 py-1  text-success">
                  Eternal Strings <span *ngIf="!resendBtn">sent</span> <span *ngIf="!!resendBtn">resent</span> a text
                  message with a verification code to
                  {{encryMobile}}
                </div>
              </div>
              <div *ngIf="verify_by == 'email'">
                <div class="form-label mb-2 py-1  text-success">
                  Eternal Strings <span *ngIf="!resendBtn">sent</span> <span *ngIf="!!resendBtn">resent</span> an email
                  to {{encryEmail}}
                </div>
              </div>
              <div class="form-label mb-2 py-1 "> Enter the Verificaton
                Code
                <span class=" fw-bold text-danger">
                  {{ displayTime }}
                </span>
              </div>
              <div class="position-relative">
                <input class="form-control border-0 pe-5  " id="exampleFormControlInputOtp" placeholder="- - - - - -"
                  [formControlName]="'otp'" autocomplete="" maxlength="6"
                  [ngClass]="{ 'is-invalid': otpSubmitted && otpf.otp.errors }" (input)="keyPressNumericMobile($event)"
                  (paste)="pasteNumericMobileTo($event)">

                <div *ngIf="otpSubmitted && otpf.otp.errors">
                  <div *ngIf="otpf.otp.errors.minlength">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">
                      OTP Number must be 6 digits
                    </div>
                  </div>
                  <div *ngIf="otpf.otp.errors.maxlength">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">
                      OTP Number must be 6 digits
                    </div>
                  </div>
                </div>
                <div *ngIf="otpResposce == false">
                  <div class="form-label mb-2 py-1 fw-bold text-danger">
                    {{errmsgotp}}
                  </div>
                </div>
                <div *ngIf="!otpSubmitted">
                  <div *ngIf="minNumberLength ">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">
                      OTP Number must be 6 digits
                    </div>
                  </div>
                  <div *ngIf="maxNumberLength">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">
                      OTP Number must be 6 digits
                    </div>
                  </div>
                </div>
                <div class="position-absolute eye-btn right-0 text-danger">
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-12 my-auto">
                <div class="form-check d-flex m-0 p-0 align-items-center">
                  <input class="checkbox" type="checkbox" id="flexCheckDefault" [formControlName]="'device_verified'">
                  <label class="ms-2 form-check-label d-flex align-items-center" for="flexCheckDefault">
                    Don't ask again on this device <span class="btn p-0 my-0 mx-1 border-0 mt-1" placement="top">
                    </span>
                  </label>
                </div>
              </div>
              <div class="col-12 ps-3  mt-2">Didn't get it?
                <button type="submit" class=" btn btn-danger px-2" [disabled]="!!resendBtn"
                  (click)="ResendOneTimePassword()">
                  Resend</button>
              </div>
              <div *ngIf="tryAnotherWayLink" class="col-12 ps-2  mt-2">
                <a type="submit" class="  px-2" (click)="tryAnotherWay()">
                  Try another way</a>
              </div>
              <div class="col-12 mt-3 py-2 bg-maroon w-100">

                <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                    class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                      class="fas fa-long-arrow-alt-left"></i> Go
                    to eternalstrings.com</a> </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- otp modal end -->

      <div class="modal-body p-0" *ngIf="showmodel && !showmodelOtp ">
        <ul class="nav nav-tabs" id="myTab" role="">
          <li class="w-50" role="presentation">
            <button class="btn-outline-danger btn w-100 rounded-0 active" id="home-tab" data-bs-toggle="tab"
              data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Email
              ID</button>
          </li>
          <li class="w-50" role="presentation">
            <button class="btn-outline-danger btn w-100 rounded-0" id="profile-tab" data-bs-toggle="tab"
              data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Mobile
              No.</button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div class="col-12 px-0">
              <div *ngIf="errors?.email" class="alert alert-danger mt-3">
                {{ errors?.email }}
              </div>
              <div *ngIf="mailsent" class="alert alert-success mt-3">
                {{ mailsentmsg}}
              </div>

              <form #forgotpasswordNgForm="ngForm" [formGroup]="forgotpasswordForm">
                <div class="my-3 px-3">
                  <label for="exampleFormControlInput1" class="form-label fw-bold"> Email ID</label>
                  <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter"
                    [formControlName]="'email'">
                </div>
                <div class="row mx-0">
                  <div class="col-12 text-end">
                    <button type="submit" class="btn btn-danger px-3" [disabled]="forgotpasswordForm!.invalid"
                      (click)="forgotpassword()">Send
                      mail</button>
                  </div>
                  <div class="col-12 mt-2 text-end">
                    <a href="javascript:void(0);" class="text-decoration-underline text-danger"
                      (click)="changemodel()">Login</a>
                  </div>

                </div>
              </form>
            </div>
          </div>
          <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div class="col-12 px-0">
              <div *ngIf="errors?.email" class="alert alert-danger mt-3">
                {{ errors?.email }}
              </div>
              <div *ngIf="mailsent" class="alert alert-success mt-3">
                {{ mailsentmsg}}
              </div>
              <form #forgotpasswordNgForm1="ngForm" [formGroup]="forgotpasswordForm1">
                <div class="my-3 px-3">
                  <div class="row">
                    <label for="Mobile" class="form-label mb-2 py-1 fw-bold h6">Mobile Number <span class="text-danger">
                        *</span></label>
                    <div class="col-12 col-md-5 mb-md-3 pe-md-0">
                      <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="id"
                        [formControlName]="'countryCode'" [(ngModel)]="phoneCode" placeholder="Select Country Code"
                        dropdownPosition="auto" [virtualScroll]="true" (change)="OnchangeCountryCode($event)">
                        <ng-template ng-label-tmp let-item="item">
                          <img height="20" width="20" src="assets/images/flag/Flag_of_{{item.country_name |
                                                        replaceSpaceWithUnderscore}}.svg" alt="" style="margin-right:5px;" />
                          <b> {{item.country_name}}</b>{{item.country_code}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                          <img height="20" width="20" src="assets/images/flag/Flag_of_{{item.country_name |
                                                        replaceSpaceWithUnderscore}}.svg" alt="" style="margin-right:5px;" />
                          <b> {{item.country_name}}</b>{{item.country_code}}
                        </ng-template>
                      </ng-select>

                    </div>
                    <div class="col-12 col-md-7">
                      <input type="text" class="form-control" id="Mobile" placeholder="Enter" maxlength="15"
                        [formControlName]="'mobileNo'" (keypress)="keyPressNumeric($event)" style="height: 50px;"
                        [ngClass]="{
                                                'is-invalid': submitted && s.mobileNo.errors }">
                      <div *ngIf="submitted && s.mobileNo.errors">
                        <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile number is
                          required</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mx-0">
                  <div class="col-12 text-end">
                    <button type="submit" class="btn btn-danger px-3" [disabled]="forgotpasswordForm1!.invalid"
                      (click)="forgotpassword1()">Send
                      SMS</button>
                  </div>
                  <div class="col-12 mt-2 text-end">
                    <a href="javascript:void(0);" class="text-decoration-underline text-danger"
                      (click)="changemodel()">Login</a>
                  </div>

                </div>
              </form>
            </div>
          </div>
          <div class="col-12 mt-3 py-2 bg-maroon w-100">
            <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                class="text-light" id="closeforgotpasswordModal" data-bs-dismiss="modal" aria-label="Close"><i
                  class="fas fa-long-arrow-alt-left"></i> Go to
                eternalstrings.com</a> </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- Registered modal -->
<div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-width-400 rounded-10">
      <div class="modal-header border-0 pt-3 pb-1">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h5 class="px-2 mb-3 text-danger text-center"> Start Setting Up the Account </h5>

        <div class="col-12 mb-3">
          <label for="exampleFormControlInput1" class="form-label">Profile For</label>
          <select class="form-select" aria-label="Default select example" required>
            <option selected value="1">Select</option>
            <option value="1">Self</option>
            <option value="3">Brother</option>
            <option value="3">Daughter</option>
            <option value="3">Friend</option>
            <option value="3">Relative</option>
            <option value="3">Sister</option>
            <option value="2">Son</option>
          </select>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6 mb-3">
            <label for="exampleFormControlInput1" class="form-label">First Name</label>
            <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <label for="exampleFormControlInput1" class="form-label">Last Name</label>
            <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
          </div>
        </div>

        <div class="col-12 mb-3">
          <label for="exampleFormControlInput1" class="form-label">Gender</label><br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
            <label class="form-check-label" for="inlineRadio1">Male</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
            <label class="form-check-label" for="inlineRadio2">Female</label>
          </div>
        </div>

        <div class="col-12 mb-1">
          <label for="exampleFormControlInput1" class="form-label">Language preference</label>
          <select class="form-select" aria-label="Default select example" required>
            <option selected>Select</option>
            <option value="3">Bengali</option>
            <option value="2">English</option>
            <option value="3">Gujarati</option>
            <option value="1">Hindi</option>
            <option value="3">Marathi</option>
            <option value="2">Punjabi</option>
            <option value="3">Tamil</option>
            <option value="3">Telugu</option>
            <option value="3">Other</option>
          </select>
        </div>

        <div class="col-12 mb-3">
          <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
        </div>

        <div class="col-12 mb-1">
          <label for="exampleFormControlInput1" class="form-label">Religious views</label>
          <select class="form-select" aria-label="Default select example" required>
            <option selected>Select</option>
            <option value="3">Buddhist</option>
            <option value="3">Christian</option>
            <option value="1">Hindu</option>
            <option value="3">Jain</option>
            <option value="2">Muslim</option>
            <option value="3">Parsi</option>
            <option value="3">Sikh</option>
            <option value="3">No Religion</option>
            <option value="3">Other</option>
          </select>
        </div>

        <div class="col-12 mb-3">
          <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
        </div>

        <div class="col-12 mb-3">
          <label for="exampleFormControlInput1" class="form-label">Caste </label>
          <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
        </div>

      </div>
      <div class="modal-footer py-2 border-0">
        <div class="row w-100">
          <div class="col-6 text-start px-0">
          </div>
          <div class="col-6 text-end px-0">
            <button class="btn btn-danger" data-bs-target="#exampleModalToggle1" data-bs-toggle="modal"
              data-bs-dismiss="modal">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- modal-2 -->
<div class="modal fade" id="exampleModalToggle1" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2"
  tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-width-400 rounded-10">
      <div class="modal-header border-0 pt-3 pb-1">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h5 class="px-2 mb-3 text-danger text-center">Please Continue </h5>

        <div class="col-12 mb-3">
          <div class="col-12 mb-3">
            <label for="exampleFormControlInput1" class="form-label">Email ID</label>
            <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Enter" required>
          </div>
        </div>

        <div class="col-12 mb-3">
          <div class="row">
            <label for="exampleFormControlInput1" class="form-label">Mobile No.</label>
            <div class="col-5">
              <select class="form-select mb-1 py-2 h-100 p-sm" aria-label="Default select example">
                <option value="1" Selected> Select</option>
                <option value="1"> Canada (+1)</option>
                <option value="2"> America (+1)</option>
                <option value="3"> Australia (+61)</option>
                <option> India (+91)</option>
                <option> UK (+44)</option>
              </select>
            </div>
            <div class="col-7">
              <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter" required>
            </div>
          </div>

        </div>

        <div class="col-12 mb-3">
          <label for="exampleFormControlInput1" class="form-label">Date Of Birth</label>
          <input type="date" class="form-control" id="exampleFormControlInput1" required>
        </div>

        <div class="col-12 mb-1">
          <label for="exampleFormControlInput1" class="form-label">Living In </label>
          <select class="form-select mb-1" aria-label="Default select example">
            <option value="1" selected>Select</option>
            <option value="1">America</option>
            <option value="1">Australia</option>
            <option value="1">Canada</option>
            <option value="1">France</option>
            <option value="1"> India</option>
            <option value="1">Oman</option>
            <option value="1">UAE</option>
            <option value="1">UK</option>
            <option value="1">Other</option>
          </select>
        </div>
        <div class="col-12 mb-3">
          <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter" required>
        </div>

      </div>
      <div class="modal-footer py-2 border-0">
        <div class="row w-100">
          <div class="col-6 text-start px-0">
            <button class="btn btn-danger" data-bs-target="#exampleModalToggle" data-bs-toggle="modal"
              data-bs-dismiss="modal">Back</button>
          </div>
          <div class="col-6 text-end px-0">

            <a href="javascript:void(0);" data-bs-dismiss="modal" routerLink="/step" title="Next"><button
                class="btn btn-danger">Next</button></a>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- modal-3 -->