<div class="container border border-1 shadow-sm my-4 p-0 position-relative main-pt">
  <nav class="position-sticky z-index-60 top-70">
    <div class="nav nav-tabs d-flex align-items-center justify-content-between p-2 bg-warning" id="nav-tab"
      role="tablist">

      <a class="my-2 py-2 px-3 btn-outline-danger border-0 flex-1 rounded-pill mx-1 dashboard-card123 text-center"
        routerLink="/myProfile" [class.notClickable]="notClickable">My
        Dashboard</a>

      <a class="my-2 py-2 px-3 btn-outline-danger border-0 flex-1 rounded-pill mx-1 dashboard-card123 text-center"
        routerLink="/myInfo" [class.notClickable]="notClickable">My Profile</a>

      <a class="my-2 py-2 px-3 btn-outline-danger active border-0 flex-1 rounded-pill mx-1 text-center"
        routerLink="/myPhotoGallery">My Photo
        Gallery</a>
    </div>

  </nav>
  <div class="p-3 bg-white pt-4 mt-68">
    <div class="tab-content" id="nav-tabContent">

      <!-- start My Photos -->
      <div class="tab-pane fade show active" id="nav-gallery" role="tabpanel" aria-labelledby="nav-gallery-tab">
        <div class="row mb-3">
          <div class="col-12 px-0 profile-banner position-relative" style="max-height: 485px">

            <app-progressive-image class="progressive-image">
              <img appProgressiveImage [src]="coverCroppedImage" alt="" class="img-fluid">

              <div appProgressiveImagePlaceholder class="placeholderOneOrThree">
                <div id="loaderProgressive" class="centerProgressive"></div>
              </div>
              <div appProgressiveImageFallback class="fallback">
                <img appProgressiveImageFallback class="img-fluid" src="assets/images/default_cover_photo.png" alt="">
              </div>
            </app-progressive-image>
            <div class="position-absolute profile-banner-edit-btn top-0">
              <div class="w-30-h-30 rounded-circle p-1 btn btn-danger center">
                <div data-bs-toggle="modal" data-bs-target="#coverPhoto"
                  class="w-30-h-30 rounded-circle p-1 top-0 right-0 btn btn-danger center position-absolute">
                  <i class="fas fa-camera h6 mb-0"></i>
                </div>
              </div>
            </div>

            <p class="text-center mb-3">
              <span *ngIf="details?.cover_photo_approved == 1 && details?.cover_photo"
                class="text-success border-success"> Approved</span>
              <span
                *ngIf="details?.cover_photo_approved == 0 && (details?.cover_photo != null && details?.cover_photo !='')"
                class="text-warning border-warning">
                Awaiting Approval</span>
              <span
                *ngIf="details?.cover_photo_approved == 2  && (details?.cover_photo != null && details?.cover_photo !='')"
                class="text-danger border-danger"> Not Approved</span>
              <span *ngIf="details?.cover_photo == null || details?.cover_photo == ''"
                class="text-warning border-warning"> Default</span>
            </p>
          </div>
        </div>
        <div class="row my-2 position-relative">
          <div class="col-12 mt-2">
            <h4 class="text-danger fw-bold mb-0 text-center lh-sm f-size text-pad"> Get more responses by uploading up
              <br /> to 20
              photos on your Photo
              Gallery.
            </h4>
          </div>
          <div class="w-180 h-180 position-absolute top-0 left-0" *ngIf="!photoLoader">
            <div class="w-115-h-115 rounded-circle position-relative mx-auto">

              <app-progressive-image class="progressive-image">
                <img appProgressiveImage [src]="croppedImage" alt=""
                  class="img-fluid rounded-circle border image-cover overflow-hidden bg-white cursor-pointer"
                  data-bs-toggle="modal" data-bs-target="#exampleModalProfile">

                <div appProgressiveImagePlaceholder class="placeholderOneOrThree-suggest">
                  <div id="loaderProgressive" class="centerProgressive  "></div>
                </div>
                <div appProgressiveImageFallback class="fallback">
                  <img appProgressiveImageFallback
                    class="img-fluid rounded-circle border image-cover overflow-hidden bg-white"
                    src="assets/images/default-profile-picture.svg" alt="">
                </div>
              </app-progressive-image>
              <div>
                <div data-bs-toggle="modal" data-bs-target="#loginModal"
                  class="w-30-h-30 rounded-circle p-1 top-0 right-0 btn btn-danger center position-absolute">
                  <i class="fas fa-camera h6 mb-0"></i>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <p class="text-center py-2"> <span *ngIf="details?.profile_photo_approved == 1 && details?.profile_photo"
                  class="text-success border-success"> Approved</span>
                <span
                  *ngIf="details?.profile_photo_approved == 0 && (details?.profile_photo !=null && details?.profile_photo !='')"
                  class="text-warning border-warning">
                  Awaiting Approval</span>
                <span
                  *ngIf="details?.profile_photo_approved == 2 && (details?.profile_photo !=null && details?.profile_photo !='')"
                  class="text-danger border-danger"> Not Approved </span>
                <span *ngIf="details?.profile_photo == null || details?.profile_photo == ''"
                  class="text-warning border-warning"> Default</span>
              </p>
            </div>

          </div>
          <button style="display:none;" type="button" id="closeModal2" data-bs-dismiss="modal"
            aria-label="Close"></button>

          <!-- profile loader start -->
          <div class="w-180 h-180 position-absolute top-0 left-0" *ngIf="photoLoader">
            <div class="w-115-h-115 rounded-circle position-relative mx-auto">

              <img class="rounded-circle border image-cover overflow-hidden bg-white animated-background-name " alt />
              <div>

                <div class="w-30-h-30 rounded-circle p-1 top-0 right-0 btn btn-danger center position-absolute">
                  <i class="fas fa-camera h6 mb-0"></i>
                </div>
              </div>
            </div>
            <p class="text-center py-2 col-12 text-center p-2 blank-box animated-background-name center mx-5 mt-1"
              style=" width
              :40%"></p>
          </div>

          <!-- profile loader end -->
        </div>
        <form #memberNgForm="ngForm" [formGroup]="userPlanForm">
          <div class="row my-4">
            <div class="col-12 text-end">
              <div class="dropdown mx-width-250 ms-auto">
                <button class="btn btn-outline-danger dropdown-toggle" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  Pictures
                  visible to </button>

                <ul class="dropdown-menu border-danger mt-2 h-170 right-0 mx-width-280 p-sm"
                  aria-labelledby="dropdownMenuButton1" style="z-index:1;">

                  <li>
                    <div class="dropdown-item px-2">
                      <input class="checkbox me-2" type="checkbox" [formControlName]="'visible_to_all'"
                        value="Visible to all members" id="visible_to_all_members"
                        (change)="memberShip('Visible to all members');getDetail($event)" />
                      <label class for="visible_to_all_members"> Visible to all members </label>
                    </div>
                  </li>
                  <li>
                    <div class="dropdown-item px-2">
                      <input class="checkbox me-2" type="checkbox" [formControlName]="'visible_to_connected'"
                        value="Visible to connected members" id="visible_to_connected_members"
                        (change)="memberShip('Visible to connected members');getDetail($event)" />
                      <label class for="visible_to_connected_members"> Visible to connected members </label>
                    </div>
                  </li>
                  <li>
                    <div class="dropdown-item px-2">
                      <input class="checkbox me-2" type="checkbox" [formControlName]="'no_one'" value="No one"
                        id="no_one" (change)="memberShip('No one');getDetail($event)" />
                      <label class for=" no_one"> No one </label>
                    </div>
                  </li>
                </ul>

              </div>

            </div>
            <div class="row row-cols-1 mx-0 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 my-3 g-2">
              <div class="col" *ngIf="!photoLoader && uploadMoreImage">
                <div class="uploader h-100 p-1">
                  <div class="border border-danger border-1 bg-light rounded-3 text-center px-3 py-5 w-100 h-100 d-flex
                    align-items-center">
                    <img id="file-image" src="#" alt class="hidden" />
                    <div id="start">
                      <i class="fa fa-upload" aria-hidden="true"></i>
                      <div>Please Upload Photos Here</div>


                      <button *ngIf="this.myProfileGallery?.length<this.totalImgCount" class="btn btn-danger px-2 my-2"
                        data-bs-toggle="modal" data-bs-target="#loginModal2" (click)="clearImage()"> Select a
                        file
                      </button>

                      <button *ngIf="this.myProfileGallery?.length==this.totalImgCount "
                        (click)="uploadGallary2($event)" class="btn btn-danger px-2 my-2"> Select a file
                      </button>

                    </div>
                    <div id="response" class="hidden">
                      <div id="messages"></div>
                      <progress class="progress" id="file-progress" value="0">
                        <span>0</span>% </progress>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="myProfileGallery.length> 0 && !photoLoader">
                <ng-container *ngFor="let myphoto of myProfileGallery , let i= index">
                  <div class="col" (mouseenter)="hoverIdx = i" (mouseleave)="hoverIdx = -1">
                    <!-- overlay  -->
                    <div class="row border-danger bg-light rounded-3_rm m-1 border border-1 shadow-sm">
                      <div class="col-12 w-100 position-relative px-0  cursor-pointer ">
                        <div class="position-absolute m-2 p-0 bg-white rounded-circle w-25-h-25 d-flex align-items-center
                          justify-content-center">
                          <div class="dropdown ">

                            <div class="ms-auto center btn w-30-h-30 rounded-circle btn-danger border-0" type="button"
                              id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="fas fa-ellipsis-h"></i>
                            </div>
                            <ul class="shadow dropdown-menu p-2 border border-2 border-danger rounded-3"
                              aria-labelledby="dropdownMenuButton1" style="z-index:1;">
                              <button data-bs-toggle="modal" style="display: none;" data-bs-target="#loginModal3"
                                id="openmodal3">editttt
                              </button>
                              <!-- add cption to photo  -->
                              <li data-bs-toggle="modal" data-bs-target="#captionBox">
                                <a class="p-sm dropdown-item" (click)="addCaption(myphoto)">
                                  <i class="fas fa-pen me-2"></i>Caption</a>

                              </li>
                              <!-- add cption to photo -->
                              <li data-bs-toggle="modal" data-bs-target="#confirmBox">
                                <a class="p-sm dropdown-item" href="javascript:void(0)" (click)="deletePhoto(i)">
                                  <i class="fas fa-trash-alt me-2"></i> Delete </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div>
                          <img (click)="openlight(myphoto,i)" class="image-cover image-cover-2 rounded-3"
                            *ngIf="myphoto.photo_thumb_url != ''" alt data-bs-toggle="modal"
                            data-bs-target="#exampleModal" src="{{myphoto.photo_thumb_url}}" alt />
                          <img (click)="openlight(myphoto,i)" class="image-cover image-cover-2 rounded-3"
                            *ngIf="myphoto.photo_thumb_url == ''" alt data-bs-toggle="modal"
                            data-bs-target="#exampleModal" src="{{myphoto.photo_url}}" alt />
                          <span [ngClass]="{ 'overlay-icon': true, 'hide': hoverIdx !== i }" *ngIf="myphoto?.caption">
                            <div class="marquee-container" *ngIf="myphoto?.caption?.length > 36">
                              <h4>{{ showCaption }}</h4>
                            </div>
                            <h7 *ngIf="myphoto?.caption?.length <= 36" class="m-0 p-0"> {{myphoto?.caption }}</h7>
                          </span>
                        </div>

                        <div class="position-absolute rounded-3 p-2 hover-image p-sm d-flex text-center"
                          *ngIf="myphoto.photo_status == 2">
                          <small class="text-light d-block text-center my-auto w-100"> <a href="javascript:void(0)"
                              class="text-light">
                              Please refer to our
                              <span class="text-underline cursor-pointer" data-bs-toggle="modal"
                                data-bs-target="#guidelinesModal"> Photo Guidelines. </span> </a>
                          </small>
                        </div>
                      </div>
                      <div *ngIf="myphoto.photo_status == 2" class="col-12 text-danger text-center p-2">
                        {{photoStatusObj[myphoto.photo_status]}}</div>
                      <div *ngIf="myphoto.photo_status == 1" class="col-12 text-success text-center p-2">
                        {{photoStatusObj[myphoto.photo_status]}}</div>
                      <div *ngIf="myphoto.photo_status == 0" class="col-12 text-warning text-center p-2">
                        {{photoStatusObj[myphoto.photo_status]}}</div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <!-- photo loader start -->
              <ng-container *ngIf="photoLoader">
                <ng-container>
                  <div class="col ">
                    <div class="uploader h-100 p-1 animated-background-name">
                      <label class="border border-danger border-1 bg-light rounded-3 text-center px-3 py-5 w-100 h-100 d-flex
                        align-items-center">
                        <img id="file-image" src="#" alt class="hidden" />
                        <div id="start">
                          <i class="fa fa-download animated-background-name" aria-hidden="true"></i>
                          <div class="col-12 text-center p-2 blank-box animated-background-name center mx-5" style=" width
                            :60%"></div>

                          <span id="file-upload-btn"
                            class="btn px-2 my-2 blank-box animated-background-name center mx-5" style=" width
                            :60%"> </span>
                        </div>
                        <div id="response" class="hidden">
                          <div id="messages"></div>
                          <progress class="progress" id="file-progress" value="0">
                            <span>0</span>% </progress>
                        </div>
                      </label>
                    </div>
                  </div>
                </ng-container>
                <ng-container>
                  <div class="col">
                    <div class="row border-danger bg-light rounded-3 m-1 border border-1 shadow-sm">
                      <div class="col-12 w-100 h-180 position-relative px-0">
                        <div class="position-absolute m-2 p-0 bg-white rounded-circle w-25-h-25 d-flex align-items-center
                          justify-content-center">
                          <div class="dropdown">
                            <i class="fas fa-ellipsis-h text-danger btn p-0" type="button" id="dropdownMenuButton1"
                              data-bs-toggle="dropdown" aria-expanded="false"></i>
                            <ul class="shadow dropdown-menu p-2 border border-2 border-danger rounded-3"
                              aria-labelledby="dropdownMenuButton1">

                            </ul>
                          </div>
                        </div>
                        <img class="image-cover rounded-3 animated-background-name" alt />
                      </div>
                      <div class="col-12 text-center p-2 blank-box animated-background-name center mx-5 mt-1 mb-1"
                        style="
                        width :60%"></div>
                    </div>
                  </div>
                </ng-container>

                <ng-container>
                  <div class="col">
                    <div class="row border-danger bg-light rounded-3 m-1 border border-1 shadow-sm">
                      <div class="col-12 w-100 h-180 position-relative px-0">
                        <div class="position-absolute m-2 p-0 bg-white rounded-circle w-25-h-25 d-flex align-items-center
                          justify-content-center">
                          <div class="dropdown">
                            <i class="fas fa-ellipsis-h text-danger btn p-0" type="button" id="dropdownMenuButton1"
                              data-bs-toggle="dropdown" aria-expanded="false"></i>
                            <ul class="shadow dropdown-menu p-2 border border-2 border-danger rounded-3"
                              aria-labelledby="dropdownMenuButton1">

                            </ul>
                          </div>
                        </div>
                        <img class="image-cover rounded-3 animated-background-name" alt />
                      </div>
                      <div class="col-12 text-center p-2 blank-box animated-background-name center mx-5 mt-1 mb-1 "
                        style=" width
                        :60%"></div>
                    </div>
                  </div>
                </ng-container>

                <ng-container>
                  <div class="col">
                    <div class="row border-danger bg-light rounded-3 m-1 border border-1 shadow-sm">
                      <div class="col-12 w-100 h-180 position-relative px-0">
                        <div class="position-absolute m-2 p-0 bg-white rounded-circle w-25-h-25 d-flex align-items-center
                          justify-content-center">
                          <div class="dropdown">
                            <i class="fas fa-ellipsis-h text-danger btn p-0" type="button" id="dropdownMenuButton1"
                              data-bs-toggle="dropdown" aria-expanded="false"></i>
                            <ul class="shadow dropdown-menu p-2 border border-2 border-danger rounded-3"
                              aria-labelledby="dropdownMenuButton1">

                            </ul>
                          </div>
                        </div>
                        <img class="image-cover rounded-3 animated-background-name" alt />
                      </div>
                      <div class="col-12 text-center p-2 blank-box animated-background-name center mx-5 mt-1 mb-1 "
                        style=" width
                        :60%"></div>
                    </div>
                  </div>
                </ng-container>

              </ng-container>

              <!-- photo loader end -->
            </div>
            <div class="col-12 py-2 text-center">
              <p class="mb-0 p-sm">
                <strong> Note: </strong>
                <span class="text-secondary"> You can upload 20 photos to your profile. Each photo must be less than 5
                  MB and in jpg,
                  gif,
                  png, bmp format. All photos uploaded are screened as per <span class="text-danger cursor-pointer"
                    data-bs-toggle="modal" data-bs-target="#guidelinesModal"> Photo Guidelines </span> and 98% of those
                  get activated within 5 business
                  day.
                </span>
              </p>
            </div>
          </div>
        </form>
      </div>
      <!-- end My Photos -->

    </div>
  </div>
</div>
<app-login-footer></app-login-footer>
<ngx-spinner bdColor="rgba(0, 0, 0, 0)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
<!-- modal  -->
<!-- upload photo start  -->

<div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
      <div class="modal-header border-0 pt-3 mb-3">
        <img src="assets/images/logo.png" class="mx-auto" alt="ETERNALSTRINGS LOGO" width="130px">

        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closeLoginModal"
          data-bs-dismiss="modal" aria-label="Close" (click)="clearImage()"></button>
      </div>
      <div class="modal-body p-0">

        <div class="col-12 px-0">

        </div>

        <form [formGroup]="imageUploadForm" (ngSubmit)="submitForm()">
          <div class="mb-3 px-3">

            <input type="file" (change)="uploadFile($event)" #fileInput class="upload"
              accept="image/png,image/jpeg,image/webp,image/bmp" id="profilePicture" />

            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
              [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 4" [resizeToWidth]="256"
              [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="true" [canvasRotation]="canvasRotation"
              [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null :
              'none'" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
              (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>
          </div>

          <button type="reset" style="display: none;" id="resetimgfield22" (click)="resetFileUploader()"> reset
            image</button>
          <div class="col-12 text-center" *ngIf="imgSelected">
            <a href="javascript:void(0)" class="btn btn-light border border-1 border-danger me-2"
              (click)="rotateLeft()"><i class="fas fa-sync-alt"></i></a>
            <button type="submit" class="btn btn-danger" id="closeLoginModal" data-bs-dismiss="modal">Save</button>
          </div>


          <div class="row mx-0">

            <div class="col-6 ps-0 text-end">

            </div>

            <div class="col-12 mt-3 py-2 bg-maroon w-100">

              <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                  class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                    class="fas fa-long-arrow-alt-left"></i> Go
                  to eternalstrings.com</a> </p>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>

<!-- upload photo end  -->

<!-- profile photo open  -->
<div class="modal fade" id="exampleModalProfile" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header pb-0 border-0">
        <h5 class="modal-title mb-0 fw-bold" id="exampleModalLabel">{{details.display_name}} </h5>
        <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close"> <i
            class="fas fa-times fs-4 text-danger"></i> </button>
      </div>
      <div class="modal-body">
        <div class="ratio-2">
          <img src="{{details.profile_photo}}" class="image-cover rounded-5 border border-1 border-success" alt>
        </div>


      </div>

      <div class="modal-footer">

      </div>
    </div>
  </div>
</div>
<!-- profile photo open  -->



<!-- edit photo stert -->
<div class="modal fade" id="loginModal2" tabindex="-1" aria-labelledby="loginModalLabel2" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
      <div class="modal-header border-0 pt-3 mb-3">
        <img src="assets/images/logo.png" class="mx-auto" alt="ETERNALSTRINGS LOGO" width="130px">

        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closeLoginModal"
          data-bs-dismiss="modal" aria-label="Close" (click)="clearImage()"></button>
      </div>
      <div class="modal-body p-0 h-400">
        <div class="col-12 px-0">
        </div>
        <form [formGroup]="editimageUploadForm" (ngSubmit)="editsubmitForm()">
          <div class="mb-3 px-3">
            <input type="file" (change)=" uploadFile($event)" #fileInput class="upload"
              accept="image/png,image/jpeg,image/webp,image/bmp" id="profilePicture" multiple />
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
              [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 3" [resizeToWidth]="1200"
              [cropperMinWidth]="0" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
              [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null :
              'none'" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
              (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>

            <div *ngIf="loading" class="loader">Loading...</div>
          </div>
          <button type="reset" style="display: none;" id="resetimgfield3" (click)="resetFileUploader()"> reset
            image</button>
          <div class="col-12 text-center" *ngIf="imgSelected">
            <a href="javascript:void(0)" class="btn btn-light border border-1 border-danger me-2"
              (click)="rotateLeft()"><i class="fas fa-sync-alt"></i></a>
            <button type="submit" class="btn btn-danger" id="closeLoginModal" data-bs-dismiss="modal">Save</button>
          </div>

          <div class="row mx-0">
            <div class="col-6 ps-0 text-end">
            </div>
            <div class="col-12 mt-3 py-2 bg-maroon w-100">
              <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                  class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                    class="fas fa-long-arrow-alt-left"></i> Go
                  to eternalstrings.com</a> </p>
            </div>
          </div>
        </form>
        <div class="col-12 text-center">
          <button style="display:none;" class="btn btn-danger" id="closeLoginModalMultiple"
            data-bs-dismiss="modal"></button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- edit photo end -->
<!-- edit photo stert -->
<div class="modal fade" id="loginModal3" tabindex="-1" aria-labelledby="loginModalLabel3" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
      <div class="modal-header border-0 pt-3 mb-3">
        <img src="assets/images/logo.png" class="mx-auto" alt="ETERNALSTRINGS LOGO" width="130px">

        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closeLoginModal"
          data-bs-dismiss="modal" aria-label="Close" (click)="clearImage();resetFileUploader();"></button>
      </div>
      <div class="modal-body p-0">
        <div class="col-12 px-0">
        </div>
        <form [formGroup]="editimageUploadForm" (ngSubmit)="editsubmitForm()">
          <div class="mb-3 px-3">

            <input type="file" (change)="uploadFile($event)" #fileInput
              accept="image/png,image/jpeg,image/webp,image/bmp" />

            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
              [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 3" [resizeToWidth]="256"
              [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
              [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null :
              'none'" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
              (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>

          </div>

          <div class="col-12 text-center" *ngIf="imgSelected">
            <a href="javascript:void(0)" class="btn btn-light border border-1 border-danger me-2"
              (click)="rotateLeft()"><i class="fas fa-sync-alt"></i></a>
            <button type="submit" class="btn btn-danger me-2" id="closeLoginModal" data-bs-dismiss="modal"
              (click)="yesDelete()">Update</button>
            <button type="reset" id="resetimgfield" style="display:none" (click)="resetFileUploader()"> reset
              image</button>
          </div>

          <div class="col-4">
            <button type='reset' id="resetimgfield11" style="display:none" class="btn btn-danger"
              (click)="resetFileUploader()" class="btn btn-danger">Cancel</button>
          </div>


          <div class="row mx-0">
            <div class="col-6 ps-0 text-end">
            </div>
            <div class="col-12 mt-3 py-2 bg-maroon w-100">
              <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                  class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                    class="fas fa-long-arrow-alt-left"></i> Go
                  to eternalstrings.com</a> </p>
            </div>
          </div>
        </form>


        <div class="col-12 text-center">
          <button style="display:none;" class="btn btn-danger" id="closeLoginModalMultiple"
            data-bs-dismiss="modal"></button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- edit photo end -->
<div class="modal fade" id="coverPhoto" tabindex="-1" aria-labelledby="coverModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
      <div class="modal-header border-0 pt-3 mb-3">
        <img src="assets/images/logo.png" class="mx-auto" alt="ETERNALSTRINGS LOGO" width="130px">

        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closeLoginModal"
          data-bs-dismiss="modal" aria-label="Close" (click)="clearImage()"></button>
      </div>
      <div class="modal-body p-0">

        <div class="col-12 px-0">

        </div>

        <form [formGroup]="coverUploadForm" (ngSubmit)="submitCoverPicture()">
          <div class="mb-3 px-3">

            <input type="file" (change)=" uploadCover($event)" (click)="fileInput.value= null" #fileInput name
              class="upload" accept="image/png,image/jpeg,image/webp,image/bmp" id="profilePicture" />
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
              [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="2.7 / 1" [resizeToWidth]="1310"
              [cropperMinWidth]="1310" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
              [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null :
              'none'" (imageCropped)="imageCropped1($event)" (imageLoaded)="imageLoaded()"
              (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>
          </div>

          <button type="reset" style="display: none;" id="resetimgfield" (click)="resetFileUploader()"> reset
            image</button>
          <div class="col-12 text-center" *ngIf="imageChangedEvent">
            <a href="javascript:void(0)" class="btn btn-light border border-1 border-danger me-2"
              (click)="rotateLeft()"><i class="fas fa-sync-alt"></i></a>
            <button type="submit" class="btn btn-danger" id="closeLoginModal" data-bs-dismiss="modal">Save</button>
          </div>
          <div class="row mx-0">

            <div class="col-6 ps-0 text-end">

            </div>

            <div class="col-12 mt-3 py-2 bg-maroon w-100">

              <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                  class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                    class="fas fa-long-arrow-alt-left"></i> Go
                  to eternalstrings.com</a> </p>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>

<!-- upload cover photo end  -->

<!-- upload coverphoto start  -->

<div class="modal fade" id="confirmBox" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
      <div class="modal-header border-0 pt-3 mb-3">
        <img src="assets/images/logo.png" class="mx-auto" alt="ETERNALSTRINGS LOGO" width="130px">

        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="confirmModalLabel"
          data-bs-dismiss="modal" aria-label="Close" (click)="cancelImg()"></button>
      </div>
      <div class="modal-body p-0">

        <div class="col-12 px-0" style="text-align: center">
          <h6>
            Do you want to delete ?
          </h6>
        </div><br>
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-danger me-2" id="confirmModalLabel" data-bs-dismiss="modal">No</button>
          <button type="submit" class="btn btn-light border border-1 border-danger me-2" id="closeLoginModal"
            (click)="yesDelete()">Yes</button>
        </div>
        <div class="row mx-0">
          <div class="col-12 mt-3 py-2 bg-maroon w-100">

            <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                  class="fas fa-long-arrow-alt-left"></i> Go
                to eternalstrings.com</a> </p>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>
<!-- upload cover photo end  -->

<!-- upload coverphoto start  -->

<div class="modal fade" id="captionBox" tabindex="-1" aria-labelledby="captionModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">

    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">

        <form #wordCountNgForm="ngForm" [formGroup]="wordCountForm">
          <label for="NewPassword" id="reason" class="fw-bold mb-2"> Do you want to add photo caption ?</label>
          <textarea id="reason" #texts rows="3" class="form-control" [(ngModel)]="caption"
            [formControlName]="'description'" (paste)="pasteNumeric6($event)" (change)="compare()"
            (keypress)="wordCounter($event)" maxlength="500"></textarea> <!-- -->

          <div class="form-label mb-2 py-1 fw-bold text-danger" *ngIf="exceedLimit"> You cannot enter more than the
            maximum of 10
            words</div>

          <p class="d-flex justify-content-start text-muted p-xsm my-2">Max 10 Words. </p>
          <p class=" d-flex justify-content-start text-muted p-xsm my-2">Entered- {{words}} Words</p>

        </form>
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-danger me-2" id="confirmModalLabel"
            data-bs-dismiss="modal">Cancel</button>
          <button type="submit" class="btn btn-light border border-1 border-danger me-2" (click)="saveCaption()"
            data-bs-dismiss="modal" [disabled]="!caption">Add</button>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- caption photo end  -->
<!-- upgrade modal -->
<button style="display:none;" id="openModal1" data-bs-target="#upgradeModal" data-bs-toggle="modal"></button>
<div class="modal fade" id="upgradeModal" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>
        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">
        <p class="p-sm mb-4 fw-bold" *ngIf="this.details?.package_id == 2  && this.myProfileGallery.length ==10   ">
          Delete a photo to upload another one or Upgrade Now to Elite to have upto 20 photos..
        </p>

        <p class="p-sm mb-4 fw-bold"
          *ngIf="this.details?.package_id == 2  && this.file_array1?.length+this.myProfileGallery.length > this.totalImgCount  ">
          <!-- Delete a picture to upload another one or Upgrade Now to Elite to have upto 20 pictures.. -->
          You have uploaded {{this.myProfileGallery.length}} photos and selected {{this.file_array1?.length}} which
          exceed limit of 10 please
          delete some photos to upload new photos or Upgrade Now to Elite to have upto 20 photos..
        </p>

        <p class="p-sm mb-4 fw-bold" *ngIf="this.details?.package_id == 1 && this.myProfileGallery.length ==5">
          Delete a photo to upload another one or Upgrade Now to Premium to have upto 10 photos or Upgrade Now to
          Elite to have upto 20 photos..
        </p>

        <p class="p-sm mb-4 fw-bold" *ngIf="this.details?.package_id == 4 && this.myProfileGallery.length ==5">
          Delete a photo to upload another one or Upgrade Now to Premium to have upto 10 photos or Upgrade Now to
          Elite to have upto 20 photos..
        </p>

        <p class="p-sm mb-4 fw-bold"
          *ngIf="(this.details?.package_id == 1 || this.details?.package_id == 4 )   && this.file_array1?.length+this.myProfileGallery.length > this.totalImgCount   ">
          <!-- Delete a picture to upload another one or Upgrade Now to Premium to have upto 10 pictures or Upgrade Now to
          Elite to have upto 20 pictures.. -->
          You have uploaded {{this.myProfileGallery.length}} photos and selected {{this.file_array1?.length}} which
          exceed limit of 5 please
          delete some photos to upload new photos or Upgrade Now to Premium to have upto 10 photos or Upgrade Now to
          Elite to have upto 20 photos..
        </p>

        <button class="btn btn-danger rounded-pill" data-bs-dismiss="modal" aria-label="Close"
          (click)="goOnPackeg()">Upgrade Now </button>
      </div>

    </div>
  </div>
</div>
<!-- upgrade modal -->
<button style="display:none;" id="openModal2" data-bs-target="#upgradeModal1" data-bs-toggle="modal"></button>

<div class="modal fade" id="upgradeModal1" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">

        <p class="p-sm mb-4 fw-bold"
          *ngIf="this.file_array1?.length+this.myProfileGallery.length > this.totalImgCount    ">
          <!-- Upgrade Now to start communicating with {{displayName}}. -->
          You have uploaded {{this.myProfileGallery.length}} photos and selected {{this.file_array1?.length}} which
          exceed limit of 20 please
          delete some photos to upload new photos.
        </p>

        <p class="p-sm mb-4 fw-bold" *ngIf="this.myProfileGallery.length ==20">
          <!-- Upgrade Now to start communicating with {{displayName}}. -->
          You have uploaded 20 photos please delete some photos to upload new photos.
        </p>
      </div>

    </div>
  </div>
</div>

<div _ngcontent-bgt-c180 id="guidelinesModal" tabindex="-1" aria-labelledby="exampleModalLabel" class="modal fade"
  style="display: none;" aria-hidden="true">
  <div _ngcontent-bgt-c180 class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div _ngcontent-bgt-c180 class="modal-content position-relative"><button _ngcontent-bgt-c180 type="button"
        data-bs-dismiss="modal" aria-label="Close"
        class="btn-close position-absolute top-0 right-0 m-3 z-index-99"></button>
      <div _ngcontent-bgt-c180 class="modal-body">
        <div _ngcontent-bgt-c180 class="row">
          <div _ngcontent-bgt-c180 class="col-12 col-sm-12">
            <h5 _ngcontent-bgt-c180 class="p-0 fw-bold text-muted"> Photos Guidelines </h5>
            <div _ngcontent-bgt-c180 class="row my-3">
              <div _ngcontent-bgt-c180 class="col-12 d-flex align-items-center">
                <div _ngcontent-bgt-c180><small _ngcontent-bgt-c180><svg _ngcontent-bgt-c180
                      class="svg-inline--fa fa-check fa-w-16 text-success" aria-hidden="true" focusable="false"
                      data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512" data-fa-i2svg>
                      <path fill="currentColor"
                        d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                      </path>
                    </svg>
                    Close Up </small>
                  <div _ngcontent-bgt-c180 class="w-85-h-85"><img _ngcontent-bgt-c180
                      src="assets/images/close-up-view.png" alt
                      class="image-cover border border-1 border-danger rounded-3 bg-danger"></div>
                </div>
                <div _ngcontent-bgt-c180 class="mx-3"><small _ngcontent-bgt-c180><svg _ngcontent-bgt-c180
                      class="svg-inline--fa fa-check fa-w-16 text-success" aria-hidden="true" focusable="false"
                      data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512" data-fa-i2svg>
                      <path fill="currentColor"
                        d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                      </path>
                    </svg>
                    Half View </small>
                  <div _ngcontent-bgt-c180 class="w-85-h-85"><img _ngcontent-bgt-c180 src="assets/images/half-view.png"
                      alt class="image-cover border border-1 border-danger rounded-3 bg-danger"></div>
                </div>
                <div _ngcontent-bgt-c180><small _ngcontent-bgt-c180><svg _ngcontent-bgt-c180
                      class="svg-inline--fa fa-check fa-w-16 text-success" aria-hidden="true" focusable="false"
                      data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512" data-fa-i2svg>
                      <path fill="currentColor"
                        d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                      </path>
                    </svg>
                    Full View </small>
                  <div _ngcontent-bgt-c180 class="w-85-h-85"><img _ngcontent-bgt-c180 src="assets/images/full-view.png"
                      alt class="image-cover border border-1 border-danger rounded-3 bg-danger"></div>
                </div>
              </div>
            </div>
          </div>
          <div _ngcontent-bgt-c180 class="col-12 pb-4">
            <p _ngcontent-bgt-c180 class="lh-lg p-sm fw-bold px-3 mb-0"><svg _ngcontent-bgt-c180
                class="svg-inline--fa fa-check fa-w-16 text-success" aria-hidden="true" focusable="false"
                data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                data-fa-i2svg>
                <path fill="currentColor"
                  d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                </path>
              </svg>
              Do's </p>
            <ul _ngcontent-bgt-c180>
              <li _ngcontent-bgt-c180 class="lh-lg p-sm">Your profile photo should be front-facing and your entire
                photo should be visible. </li>
              <li _ngcontent-bgt-c180 class="lh-lg p-sm">Ensure that your photo is recent and not with a group.</li>
              <li _ngcontent-bgt-c180 class="lh-lg p-sm">You can upload upto 20 photos to your gallery.</li>
              <li _ngcontent-bgt-c180 class="lh-lg p-sm">Each photo must be less than 5 MB in size and must be in one
                of the following formats: jpg, gif, png, bmp .</li>
            </ul>
            <p _ngcontent-bgt-c180 class="h-lg p-sm fw-bold px-3 mb-0"><svg _ngcontent-bgt-c180
                class="svg-inline--fa fa-times fa-w-11 text-danger" aria-hidden="true" focusable="false"
                data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"
                data-fa-i2svg>
                <path fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                </path>
              </svg>
              Don'ts</p>
            <ul _ngcontent-bgt-c180>
              <li _ngcontent-bgt-c180 class="lh-lg p-sm"> Watermarked, digitally enhanced, morphed photos or photos
                with your personal information will be rejected. </li>
              <li _ngcontent-bgt-c180 class="lh-lg p-sm"> Irrelevant photographs will lead to deactivation of your
                profile and membership. </li>
              <li _ngcontent-bgt-c180 class="lh-lg p-sm"> The Picture should not be Blurry. </li>
              <li _ngcontent-bgt-c180 class="lh-lg p-sm"> Do not upload vulgar or nude images. </li>
            </ul>
          </div>
          <div _ngcontent-bgt-c180 class="col-12 rounded mx-auto py-2 px-3 border border-1 shadow-sm bg-light">
            <p _ngcontent-bgt-c180 class="p-sm mb-0 text-center"><span _ngcontent-bgt-c180
                class="fs-5 fw-bold text-danger">*</span> Photos will be screened, and added to your profile within 5
              business days.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog mymodel  modal-dialog-centered">
    <div class="modal-content modal-content-trans">
      <div class="modal-body text-center">
        <div class="ratio-2_rm mb-3">
          <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-25-h-25 "
            data-bs-dismiss="modal" aria-label="Close"> <i class="fas fa-times fs-5 "></i> </button>
          <img src="{{galleryPath}}"
            class="image-cover-light gallery-image-cover rounded-5 border border-1 border-success" alt>
          <a *ngIf="myProfileGallery.length>1" class="pre-button btn btn-danger py-1 px-2 mt-2"
            (click)="previousSlides()">&#10094;</a>
          <a *ngIf="myProfileGallery.length>1" class="next-button btn btn-danger py-1 px-2 ms-2  mt-2"
            (click)=" nextSlides() ">&#10095;</a>
        </div>
        <div class="mt-3 lightBox-overlay" *ngIf="showCaption">

          <div class="marquee-container text-center" *ngIf="showCaption?.length <= 100">
            <h4>{{ showCaption }}</h4>
          </div>

          <div class="marquee-container text-center" *ngIf="showCaption?.length > 100">
            <h6 style="padding-top:.8%;">{{ showCaption }}</h6>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
