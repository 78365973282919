import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'ageConvertPipe'
})
export class AgeConvertPipePipe implements PipeTransform {

  transform(value: Date): string {
    let today = moment();
            let birthdate = moment(value);
            let years = today.diff(birthdate, 'years');
            let html:string = years + " yrs";
            //console.log(html);
           // html += today.subtract(years, 'years').diff(birthdate, 'months') + " mo";
           //html += today.subtract(years, 'years');
           //console.log(html);
    return html;
}

}
