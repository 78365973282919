import { Component, OnInit, ViewChild, /*ViewEncapsulation, HostListener,*/ Renderer2 } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title, Meta } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { RegisterService } from '../services/register.service';
import { NotifService } from '../services/notif.service';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '../shared/token.service';
// unused import
// import * as $ from 'jquery';

@Component({
  selector: 'app-register-done',
  templateUrl: './register-done.component.html',
  styleUrls: ['./register-done.component.css']
})
export class RegisterDoneComponent implements OnInit {
  @ViewChild('signUpNgForm') signUpNgForm: NgForm;
  submitted = false;

  signUpForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
    firstName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100), Validators.pattern('^[a-zA-Z ]+$')]),
    lastName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(100), Validators.pattern('^[a-zA-Z ]+$')]),
    mobileNo: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]),
    countryCode: new FormControl('', [Validators.required, Validators.maxLength(10)]),
    TandC: new FormControl('', Validators.required),
    sendmail: new FormControl(''),
    countryNumberCode: new FormControl(''),
    user_id: new FormControl(''),
  })
  errors: any = null;
  countries: any;
  TandC: boolean = false;
  countryFlags: any[];
  selectedCityName: any;
  phoneCode: any;
  phoneNo: number;
  buttonDisable: boolean = false;
  changeButtonDisable: boolean = true;
  userData: any;
  options: { clickToClose: boolean; showProgressBar: boolean; theClass: string; timeOut: number; preventDuplicates: boolean; };
  isLoggedIn: any;
  minNumberLength: boolean = false;
  maxNumberLength: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private meta: Meta,
    private titleService: Title,
    private cookieService: CookieService,
    private registerService: RegisterService,
    private notifService: NotifService,
    private toastrService: ToastrService,
    public token: TokenService,
    private renderer: Renderer2

  ) {
    // this.titleService.setTitle('Start Your Search for a Life Partner | Join Eternal Strings');
    this.meta.addTag({ name: 'description', content: "Ready to unlock exclusive benefits? Sign up today on our page & step into a world of perks.Regesiter now with Eternal Strings!" });
    this.meta.addTag({ name: 'robots', content: 'all' });
    this.meta.addTag({ name: 'keywords', content: 'register, Eternal strings, Life partner'});

    this.isLoggedIn = this.token.isLoggedIn();
    if (this.isLoggedIn) {
      let register_id = parseInt(localStorage.getItem('register_id'));

      if (register_id == 0) {
        this.router.navigate(['register']);
      } else if (register_id == 1) {
        this.router.navigate(['registerNxt']);
      } else if (register_id == 2) {
        this.router.navigate(['step']);
      } else if (register_id == 3) {
        this.router.navigate(['stepTwo']);
      } else if (register_id == 4) {
        this.router.navigate(['stepDone']);
      } else if (register_id == 5) {
        this.router.navigate(['ProfilePage1']);
      } else if (register_id == 6) {
        this.router.navigate(['PartnerPreferences']);
      } else {
        this.router.navigate(['myInfo']);
      }
    }

    // this.options = this.notifService.getOptions();
    this.options = {
      clickToClose: true,
      showProgressBar: true,
      theClass: 'er-notification',
      timeOut: 0,
      preventDuplicates: true
    };
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    const subscription = this.router.events.subscribe((val) => {
      localStorage.removeItem('user')
      subscription.unsubscribe();
    });

    let userAgent = navigator.userAgent;
    if (userAgent.includes("Firefox")) {
      setTimeout(() => {
        window.scrollTo(15, 15)
      }, 500);
    }

    this.titleService.setTitle('Sign-Up | eternalstrings.com');
    this.authService.getCountryList().subscribe(
      (result) => {
        this.countries = result.data;
        if (localStorage.getItem('user')) {
          this.registerService.getUser({ user_id: localStorage.getItem('user') }).subscribe(
            (result) => {
              if (result?.status) {
                this.userData = result.userDetails;
                this.f.email.setValue(this.userData.email);
                this.f.firstName.setValue(this.userData.firstName);
                this.f.lastName.setValue(this.userData.lastName);
                this.f.mobileNo.setValue(this.userData.mobileNo);
                this.f.countryCode.setValue(this.userData.countryCode);
                this.phoneCode = +this.userData.countryCode;
                this.f.TandC.setValue(true);
                this.changeButtonDisable = false;
              }
            }
          )
        } else /*{ before sonar uncomment*/
          // before sonar
          // if (this.countries && this.countries[0] && this.countries[0].id) {
          if (this.countries?.[0].id) {
            this.phoneCode = this.countries[0].id;
            this.signUpForm.patchValue({ 'countryCode': this.countries[0].id });
            this.signUpForm.patchValue({ 'countryNumberCode': this.countries[0].country_code });
          }
        //}
      }
    );
  }

  OnchangeCountryCode(event) {
    this.signUpForm.patchValue({ 'countryCode': event.id });
    this.signUpForm.patchValue({ 'countryNumberCode': event.country_code });
  }

  get f() {
    return this.signUpForm.controls;
  }

  check() {
    if (this.signUpForm.value.TandC == true) {
      this.TandC = false;
    } else {
      this.TandC = true;
    }
  }

  // ngAfterViewInit() {
  //   scrollTo(0, 0);
  //   setTimeout(() => {
  //     scrollTo(0, 0);
  //   }, 400);
  // }

  registerDone() {
    this.errors = '';
    this.submitted = true;
    this.buttonDisable = true;
    if (this.signUpForm.invalid) {
      this.buttonDisable = false;
      // before sonar uncomment
      // return;
    } else /*{ before sonar uncomment*/
      if (this.signUpForm.value.TandC == true) {
        this.authService.register(this.signUpForm.value).subscribe(
          (result) => {
            console.log('eerror hello ', result)

            if (result?.status) {
              let currUser = result.data;
              localStorage.setItem('user', currUser.id);
              this.cookieService.set('oldUser', 'true', 365);
              console.log("this.options", this.options)
              this.toastrService.success('Sign Up Successful', "Please check your email for temporary password!");
              this.submitted = false;
              this.buttonDisable = false;
              this.changeButtonDisable = false;
            } else {
              this.toastrService.error(result.message);
              this.submitted = false;
              this.buttonDisable = false;
            }
          },
          (error) => {
            console.log('eerror hello ', error)
            if (error?.error) {
              this.errors = error.error;
              console.log(this.errors);
              this.toastrService.error('Error!', 'Not Signup!');
              this.buttonDisable = false;
            } else if (error?.email) {
              this.toastrService.error('Error!', 'The email has already been taken');
            } else if (error?.mobileNo) {
              this.toastrService.error('Error!', 'The mobile no has already been taken');
            }
            this.submitted = false;
            this.buttonDisable = false;
          });
      } else {
        this.buttonDisable = false;
        this.TandC = true
      }
    //}
  }

  updateRegisterDone() {
    if (localStorage.getItem('user')) {
      this.f.user_id.setValue(localStorage.getItem('user'));
    }
    this.errors = '';
    this.submitted = true;
    this.buttonDisable = true;
    if (this.signUpForm.invalid) {
      this.buttonDisable = false;
      // before sonar uncomment
      // return;
    } else /*{ before sonar uncomment*/
      if (this.signUpForm.value.TandC == true) {
        this.authService.register(this.signUpForm.value).subscribe(
          (result) => {
            if (result?.status) {
              let currUser = result.data;
              localStorage.setItem('user', currUser.id);
              this.cookieService.set('oldUser', 'true', 365);
              this.toastrService.success('Resend Email Successfully', "Please check your email for password!");
              this.buttonDisable = false;
              this.changeButtonDisable = false;
            } else {
              this.toastrService.error(result.message);
            }
          },
          (error) => {
            if (error?.error) {
              this.errors = error.error;
              console.log(this.errors);
              this.toastrService.error('Error!', 'Not Signup!');
              this.buttonDisable = false;
            } else if (error?.email) {
              this.toastrService.error(error.email);
            } else if (error?.mobileNo) {
              this.toastrService.error(error.mobileNo);
            }
            this.buttonDisable = false;
          });
      } else {
        this.buttonDisable = false;
        this.TandC = true
      }
    //}
  }

  keyPressNumeric(event) {
    const pattern = /\D/g;
    if (this.signUpForm.value.mobileNo.length < 6) {
      this.minNumberLength = true;
      this.maxNumberLength = false;
    } else if (this.signUpForm.value.mobileNo.length > 15) {
      this.minNumberLength = false;
      this.maxNumberLength = true;
    } else {
      this.minNumberLength = false;
      this.maxNumberLength = false;
    }
    if (!pattern.test(event.target.value) && (this.signUpForm.value.mobileNo.length > 6 && this.signUpForm.value.mobileNo.length < 16)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumeric(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    // unused code
    // this.signUpForm.patchValue({ 'mobileNo': newText });
    console.log(newText)
  }

  // ......content managment code start.....
  pasteText(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedNumber = clipboardData.getData('text');
    let newNumber = pastedNumber.replace(/[^a-zA-Z]/g, "");
    this.signUpForm.patchValue({ 'firstName': newNumber });
    setTimeout(() => {
      this.f.firstName.setValue(newNumber);
    }, 100);
  }

  pasteText1(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedNumber = clipboardData.getData('text');
    let newNumber = pastedNumber.replace(/[^a-zA-Z]/g, "");
    this.signUpForm.patchValue({ 'lastName': newNumber });
    setTimeout(() => {
      this.f.lastName.setValue(newNumber);
    }, 100);
  }

  //before sonarqube
  // NameSpaceNotAllowed(event: any) {
  //   let RegEx = new RegExp(/^([a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"])*$/g);
  //   let str = event.target.value
  //   if (RegEx.test(str)) {
  //     return event.target.value
  //   } else {
  //     let nameString = str.replace(/\s+/g, ' ');
  //     let nameString = str.replace(/[^a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"]+/g, ' ');
  //     event.target.value = nameString.replace(/^\s+/g, "");
  //   }
  // }

  //after sonarqube
  NameSpaceNotAllowed(event: any) {
    let RegEx = new RegExp(/^([a-zA-Z\d!@#$&()\-.+,%*=~[\]{}\\<>?'":])*$/g);
    let str = event.target.value;

    if (RegEx.test(str)) {
      return event.target.value;
    } else {
      let nameString = str.replace(/\s+/g, ' ');
      nameString = nameString.replace(/[^a-zA-Z\d!@#$&()\-.+,%*=\|~[\]{}\\<>\?'";]+/g, ' ');
      event.target.value = nameString.replace(/^\s+/g, "");
    }
  }

  omit_special_char(event) {
    let k;
    k = event.charCode;  //k = event.keyCode; (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  // onPaste(e) {
  //   e.preventDefault();
  //   return false;
  // }

  // ......content managment code end....
}
