import { Injectable } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { NotificationsService, Notification } from 'angular2-notifications'
// import { Subscription } from '@rxjs/subscriptions'
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotifService {

  private idsToSubscription: Array<{id: string, sub: Subscription}> = []
  private defaultOptions = {
      clickToClose: true,
      showProgressBar: true,
      theClass: 'er-notification',
      timeOut: 0,
      preventDuplicates: true
  }
  infoInfo: any = "info";
  constructor(private sanitizer: DomSanitizer,
              private notificationsService: NotificationsService,
              private router: Router) {}
  getOptions() {
      return this.defaultOptions;
  }

  success(title: string, content: string, options?: any) {
      this.notificationsService.success(title, content, options);
  }

  error(title: string, content: string, options?: any) {
      this.notificationsService.error(title, content, options);
  }

  info(title: string, content: string, options?: any) {
      this.notificationsService.info(title, content, options);
  }

  action(title: string, content: string, actionClass: string, actionFct: Function) {
      const html = '<div class="text-light">'+title+'</div><small>'+content+'</small>';

      const options = {
          timeOut: 0, 
          clickToClose: false,
          id: `custom${ new Date().getTime() }`,
          preventDuplicates: true
      }

      const notification: any = this.notificationsService.html(html, this.infoInfo, options);

      const sub = notification.click.subscribe((e: MouseEvent) => {
          if((<HTMLElement> e.target).className.indexOf(actionClass) > -1) {
              this.notificationsService.remove(notification.id);
              this.notifDestroyed(notification.id);
              actionFct();
          } else {
            this.router.navigate(['registerDone']);
          }
      })

      this.idsToSubscription.push({id: notification.id, sub});
  }
  
  notifDestroyed(id: string) {
      const subItem = this.idsToSubscription.find(i => i.id === id);

      if(subItem && subItem.sub) {
          subItem.sub.unsubscribe();
          this.idsToSubscription = this.idsToSubscription.filter(i => i.id !== id);
      }
  }
}
