<div class="bg-maroon w-100">
    <app-header></app-header>
</div>


<section class="row mx-width-800 mx-auto my-4 border border-1 shadow-sm px-3">
    <div class="col-12 p-4 bg-white rounded-5">
        <div class="my-3">
            <h3 class="text-center fw-bold text-danger mb-5">COOKIE POLICY</h3>
        </div>

        <h4 class="fw-bold mb-2">Cookie Policy</h4>
        <p class="fw-bold">Last updated May 06, 2022</p>

        <p class="lh-lg font-9-rem mt-4">A cookie is a small piece of data (text file) that a website – when visited by
            a user – asks your browser to store on your device in order to remember information about you, such as your
            language preference or login information. Those cookies are set by us and called first party cookies. We
            also use third party cookies – which are cookies from a domain different than the domain of the website you
            are visiting – for our advertising and marketing efforts.
        </p>

        <p class="lh-lg font-9-rem mb-0"> More specifically, we use cookies and other tracking technologies for the
            following purposes:</p>

        <ul class="lh-lg font-9-rem list-danger">
            <li>Assisting you in navigation;</li>
            <li>Assisting in registration to our events, login, and your ability to provide feedback;</li>
            <li>Analyzing your use of our products, services or applications;</li>
            <li>Assisting with our promotional and marketing efforts. (including behavioral advertising)</li>
        </ul>

        <p class="lh-lg font-9-rem mb-0"> Below is a detailed list of the cookies we use on our Website. Our Website is
            scanned with our cookie scanning tool regularly to maintain a list as accurate as possible. We classify
            cookies in the following categories:
        </p>

        <ul class="lh-lg font-9-rem list-danger">
            <li> Strictly Necessary Cookies </li>
            <li> Performance Cookies </li>
            <li>Functional Cookies </li>
            <li>Targeting Cookies </li>
        </ul>

        <p class="lh-lg font-9-rem mt-2"> You can opt-out of each cookie category (except strictly necessary cookies) by
            clicking on the “cookie settings” button below:
        </p>

        <button class="btn btn-danger"> Customize Settings</button>

        <h4 class="mb-2 mt-5 fw-bold">Cookie List</h4>
        <p class="lh-lg font-9-rem mt-2">A cookie is a small piece of data (text file) that a website – when
            visited by a user – asks your browser to store on your device in order to remember information about you,
            such as your language preference or login information. Those cookies are set by us and called first-party
            cookies. We also use third-party cookies – which are cookies from a domain different than the domain of the
            website you are visiting – for our advertising and marketing efforts. More specifically, we use cookies and
            other tracking technologies for the following purposes:
        </p>

        <h4 class="mb-2 mt-5 fw-bold">Strictly Necessary Cookies</h4>
        <p class="lh-lg font-9-rem mt-2">These cookies are necessary for the website to function and cannot be
            switched off in our systems. They are usually only set in response to actions made by you which amount to a
            request for services, such as setting your privacy preferences, logging in or filling in forms. You can set
            your browser to block or alert you about these cookies, but some parts of the site will not then work. These
            cookies do not store any personally identifiable information.
        </p>


        <div class="">
            <table class="table table-striped table-sm">
                <caption style="display: none;">cookie</caption>
                <thead class="bg-danger text-light">
                    <tr>
                        <th class="min-width-150 text-nowrap" scope="col">Cookie Subgroups</th>
                        <th class="min-width-150 text-nowrap" scope="col">Cookies</th>
                        <th class="min-width-150 text-nowrap" scope="col">Cookies Used</th>
                        <th class="min-width-150 xtet-nowrap" scope="col">Lifespan</th>
                    </tr>
                </thead>
                <tbody class="border-0">
                    <tr class="mb-3"> 
                        <td class="text-end text-md-start position-relative" data-label="Cookie Subgroups">my.onetrust.com	</td>
                        <td class="text-end text-md-start position-relative" data-label="Cookies"><a href="javascript:void(0)">eupubconsent, OptanonAlertBoxClosed, OptanonConsent</a></td>
                        <td class="text-end text-md-start position-relative" data-label="Cookies Used">First Party	</td>
                        <td class="text-end text-md-start position-relative" data-label="Cookies Used">5000 Days, 365 Days, 365 Days </td>
                    </tr>

                    <tr class="mb-3"> 
                        <td class="text-end text-md-start position-relative" data-label="Cookie Subgroups">my.onetrust.com	</td>
                        <td class="text-end text-md-start position-relative" data-label="Cookies"><a href="javascript:void(0)">eupubconsent, OptanonAlertBoxClosed, OptanonConsent</a></td>
                        <td class="text-end text-md-start position-relative" data-label="Cookies Used">First Party	</td>
                        <td class="text-end text-md-start position-relative" data-label="Cookies Used">5000 Days, 365 Days, 365 Days </td>
                    </tr>

                    <tr class="mb-3"> 
                        <td class="text-end text-md-start position-relative" data-label="Cookie Subgroups">my.onetrust.com	</td>
                        <td class="text-end text-md-start position-relative" data-label="Cookies"><a href="javascript:void(0)">eupubconsent, OptanonAlertBoxClosed, OptanonConsent</a></td>
                        <td class="text-end text-md-start position-relative" data-label="Cookies Used">First Party	</td>
                        <td class="text-end text-md-start position-relative" data-label="Cookies Used">5000 Days, 365 Days, 365 Days </td>
                    </tr>

                    <tr class="mb-3"> 
                        <td class="text-end text-md-start position-relative" data-label="Cookie Subgroups">my.onetrust.com	</td>
                        <td class="text-end text-md-start position-relative" data-label="Cookies"><a href="javascript:void(0)">eupubconsent, OptanonAlertBoxClosed, OptanonConsent</a></td>
                        <td class="text-end text-md-start position-relative" data-label="Cookies Used">First Party	</td>
                        <td class="text-end text-md-start position-relative" data-label="Cookies Used">5000 Days, 365 Days, 365 Days </td>
                    </tr>

                </tbody>
            </table>
        </div>


        <h4 class="mb-2 mt-5 fw-bold">Privacy Preference Center</h4>
        <p class="lh-lg font-9-rem mt-2">When you visit any web site, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience.
        </p>

        <p class="lh-lg font-9-rem mt-2">Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.
        </p>

        <p class="lh-lg font-9-rem mt-2">In some cases, data obtained from cookies is shared with third parties and may be considered a “sale of personal information” under the California Consumer Privacy Act (CCPA). You can exercise your right to opt-out of that sharing at any time by disabling cookies.
        </p>




        <button class="btn btn-danger my-3 d-block mx-auto" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">Cookies Demo Button</button>

    </div>


</section>








<div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">

    <div class="offcanvas-body small">
        <div class="row">
            <div class="col-12 col-md-9 col-lg-10">
                <p class="fs-6">
                    We use strictly necessary cookies to make our Sites work. In addition, if you consent, we will use optional functional, performance and targeting cookies to help us understand how people use our website, to improve your user experience and to provide you with targeted advertisements. You can accept all cookies, or click to review your cookie preferences.  
                </p>
            </div>
            <div class="col-12 col-md-3 col-lg-2 text-center text-md-end">
                <button class="btn btn-danger m-2 btn-sm w-150"> Accept All Cookies </button>
                <button class="btn btn-light border border-1 border-danger m-2 btn-sm w-150"> Review Preferences </button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>