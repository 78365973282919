<div class="overflow-x-hidden">
  <!-- container -->
  <div class="row">
    <div class="col-12">
      <!-- border rounded-10 shadow-sm p-3 my-5 min-width-p-80 mx-width-800 -->
      <div class="row min-height-vh-100">
        <div class="col-12 col-md-6  px-0 d-none d-md-block">
          <img src="assets/images/login.jpg" class="image-cover" alt>
        </div>
        <div class="col-12 col-md-6 grid-center px-5">
          <!-- border rounded-10 shadow-sm -->
          <div class="col-12 p-4 mx-width-600">
            <form #signInNgForm="ngForm" [formGroup]="signInForm" (submit)="login()">
              <div class="text-center">
                <img src="assets/images/logo.png" alt width="180px">
              </div>
              <h1 class="fs-2 my-4">Login Here!</h1>
              <span class="error" *ngIf="errors">{{errorMessage}}</span>
              <div class="mb-3 mt-1">
                <label for="exampleFormControlInput1" class="form-label">Mobile No. / Email ID</label>
                <input type="text" class="py-2 form-control" id="exampleFormControlInput1" placeholder="Enter username" [formControlName]="'email'">
                <span class="error" *ngIf="email!.invalid && email!.touched">Email is not valid!</span>
              </div>
              <div class="mb-3">
                <label for="exampleFormControlInput2" class="form-label">Password</label>
                <input type="Password" class="py-2 form-control" id="exampleFormControlInput1" placeholder="Password" [formControlName]="'password'">
                <span class="error" *ngIf="password!.invalid && password!.touched">Password is required!</span>
              </div>
              <div class="row mb-3">
                <div class="col-6">
                  <div class="form-check">
                    <input class="p-2 form-check-input" type="checkbox" value id="flexCheckDefault" [formControlName]="'rememberMe'">
                    <label class="form-check-label" for="flexCheckDefault">
                      Stay Logged in
                    </label>
                  </div>
                </div>
                <div class="col-6 text-end"> <a href="javascript:void(0);">Forgot Password?</a> </div>
              </div>

              <div class="col-12">
                <button type="submit" class="py-2 btn btn-danger w-100" [disabled]="signInForm!.invalid">Login</button>
              </div>
            </form>
            <div class="col-12">
              <p class=" text-center pt-3">New to Eternalstrings? <span class="fw-bold btn rounded-3">

                  <a href="javascript:void(0);" routerLink="/register"> Sign-Up Free</a> </span></p>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>