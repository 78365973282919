<div class="w-100 bg-maroon">

  <app-login-header></app-login-header>
</div>

<div class="container mx-width-800 mx-auto">
 
  <section class="my-4 bg-white rounded shadow-sm p-3 mx-width-800 mx-auto">

    <div class="row">
      <div class="col-12">
        <h4 class="mb-4 py-2 text-center">
          Congrats! Your Profile Has Been Created. <br> Upload photo and get compatible matches.
        </h4>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 py-4 col-md-6 text-center">
        <div id="profile-upload"
          class="border border-2 border-danger rounded-circle overflow-hidden position-relative mx-auto">
          <div class="hvr-profile-img">
            <input type="file" name="logo" id='getval' class="upload" id="imag">
            <div class="icon">
              <div class="camera4"><span></span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 text-center my-auto">
        <div class="">

          <div class="file-upload mx-auto">
            <label for="upload" class="btn btn-danger width-267 rounded-3 py-2 p-sm">Upload From
              Your Computer</label>
            <input id="upload" class="file-upload__input" type="file" name="file-upload">
          </div>

          <div class="col-12 my-2 mx-auto">
            <button class="btn btn-outline-danger rounded-3 border border-2 border-danger py-2 width-267 p-sm">Email
              Your
              Photos</button>
          </div>
          <div class="col-12 my-2 mx-auto">
            <button class="btn btn-primary rounded-3 py-2 width-267 p-sm">Import from facebook </button>
          </div>
          <div class="col-12 my-2 mx-auto">
            <button class="btn btn-danger rounded-3 border border-2 border-danger py-2 width-267 p-sm">Other way to
              upload </button>
            <p class="p-sm py-2 text-center mb-0"> We never post anything on your behalf, unless requested by the person
              creating profile.</p>

          </div>
        </div>
      </div>
    </div>



    <div class="row pt-2">

      <div class="col-12 col-sm-12 px-4">
        <h5 class="p-0 fw-bold text-muted"> Photos Guidelines </h5>
        <div class="row my-3">
          <div class="col-12 d-flex align-items-center">
            <div class="">
              <small><i class="fas fa-check text-success"></i> Close Up </small>
              <div class="w-85-h-85">
                <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                  src="assets/images/close-up-view.png" alt="">
              </div>
            </div>

            <div class="mx-3">
              <small><i class="fas fa-check text-success"></i> Half View </small>
              <div class="w-85-h-85">
                <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                  src="assets/images/half-view.png" alt="">
              </div>
            </div>
            <div class="">
              <small><i class="fas fa-check text-success"></i> Full View </small>
              <div class="w-85-h-85">
                <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                  src="assets/images/full-view.png" alt="">
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>






    <div class="row py-3">
      <div class="col-12 pb-4">

        <p class="lh-lg p-sm fw-bold px-3 mb-0"> <i class="fas fa-check text-success"></i> Do's </p>
        <ul class="">
          <li class="lh-lg p-sm">Your profile photo should be front-facing and your entire photo should be visible.
          </li>
          <li class="lh-lg p-sm">Ensure that your photo is recent and not with a group.</li>
          <li class="lh-lg p-sm">You can upload upto 20 photos to your gallery.</li>
          <li class="lh-lg p-sm">Each photo must be less than 15 MB in size and must be in one of the following formats:
            jpg, gif, png, bmp .</li>
        </ul>



        <p class="h-lg p-sm fw-bold px-3 mb-0"> <i class="fas fa-times text-danger"></i> Don'ts</p>

        <ul class="">
          <li class="lh-lg  p-sm">
            Watermarked, digitally enhanced, morphed photos or photos with your personal information will be rejected.
          </li>
          <li class="lh-lg  p-sm"> Irrelevant photographs will lead to deactivation of your profile and membership.
          </li>

        </ul>


      </div>


      <div class="col-8 p-sm rounded mx-auto bg-danger py-3 px-3">
        <div class="my-auto text-center text-light">
          Photos will be screened, and added to your profile within 1 business day.
        </div>
      </div>
      <div
        class="col-2 p-sm rounded mx-auto bg-danger text-light py-2 text-center px-3 d-flex align-items-center justify-content-center">
        <div class="my-auto">
          <a href="javascript:void(0)" routerLink="/myProfile">
            <div class="">
              <i class="fas fa-arrow-right text-light fs-4"></i>
            </div>
          </a>
        </div>
      </div>
    </div>


  </section>

</div>
