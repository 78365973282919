<div class="container border border-1 shadow-sm my-4 p-0 position-relative min-full-screen main-pt">

  <nav class="position-sticky z-index-60 top-70">
    <div class="nav nav-tabs d-flex align-items-center justify-content-between p-2 bg-warning position-sticky"
      id="nav-tab" role="tablist">

      <a class="my-2 py-2 px-3 btn-outline-danger rounded-0 border-0 flex-auto rounded-pill mx-1 text-center dashboard-card123"
        routerLink="/matches" type="button" aria-selected="true">
        My Matches ({{matchedProfilesCount}}) </a>

      <a class="my-2 py-2 px-3 btn-outline-danger rounded-0 border-0 flex-auto rounded-pill mx-1 text-center dashboard-card123"
        routerLink="/matches-view" type="button" aria-selected="true">
        Viewed By Others ({{viewedByProfilesCount}})</a>

      <a class="my-2 py-2 px-3 btn-outline-danger rounded-0 border-0 flex-auto rounded-pill mx-1 text-center dashboard-card123"
        routerLink="/matches-shortlist" type="button" aria-selected="true">
        My Shortlist ({{shortlistedProfilesCount}})</a>

      <a class="my-2 py-2 px-3 btn-outline-danger active rounded-0 border-0 flex-auto rounded-pill mx-1 text-center"
        routerLink="/matches-favourite" type="button" aria-selected="true">
        My Favourite ({{favouriteProfilesCount}})</a>
    </div>
  </nav>

  <div class="tab-content bg-white min_content_height_246 mt-68" id="nav-tabContent">

    <!-- -----------------------------My Favorite----------------------------- -->

    <div class="tab-pane fade show active p-3 " id="nav-Favorite" role="tabpanel" aria-labelledby="nav-Favorite-tab">
      <div class="row">
        <div class="col-7">
          <h5 class="mb-1 fw-bold"> My Favourite </h5>
        </div>

        <!-- Content loader -->
        <div class="row shadow-sm border border-0 h-100 mx-0 overflow-hidden position-relative" *ngIf="inboxLoader">
          <div *ngFor="let number of [0,1,2,3]" class="col-12 col-sm-6 col-lg-6 my-3">
            <div class="row card row shadow-sm border border-1 bg-light h-100 mx-0 overflow-hidden position-relative"
              style="width:auto;">
              <div class=" mainCardBox col-12 card-body d-flex p-1 ">
                <img src="assets/images/default-profile-picture.svg" alt="" class="img_Box animated-background" />
                <div class="card-contentBox p-1 ">
                  <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0 card-nav">
                    <span class="nameBlank-box mt-1 animated-background-name "></span>
                    <div class="ms-auto d-flex align-items-center justify-content-center btn w-25-h-25 text-center border border-1 rounded-circle
                  border-black p-1 bg-grey animated-background-name">
                      <i class="fas fa-caret-down h-4"></i>
                    </div>
                  </div>
                  <div class="row mt-3 ">
                    <div class="col-6 ">
                      <span class="blank-box mt-3 animated-background-name"></span>
                      <span class="blank-box mt-3 animated-background-name"></span>
                      <span class="blank-box mt-3 animated-background-name"></span>
                    </div>
                    <div class="col-6">
                      <span class="blank-box mt-3 animated-background-name"></span>
                      <span class="blank-box mt-3 animated-background-name"></span>
                      <span class="blank-box mt-3 animated-background-name"></span>
                      <span class="blank-box mt-3 animated-background-name"></span>
                    </div>
                  </div>
                  <span class="footerBlankBox mt-3 animated-background-name"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End content loader -->

        <div class="col-5 text-end" *ngIf="profileList.length>1 && !inboxLoader">
          <div class="dropdown">
            <button class="btn btn-outline-danger px-2 rounded-3 py-0" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false">
              Sort By
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="z-index:1;">
              <li class="dropdown-item" [ngClass]="{'current_sort': current_sort == 'default'}" id
                (click)="sortedBy($event,'default')"> Default
              </li>
              <li class="dropdown-item" [ngClass]="{'current_sort': current_sort == 'earliest'}" id="ASC"
                (click)="sortedBy($event,'earliest')">
                Earliest </li>
              <li class="dropdown-item" [ngClass]="{'current_sort': current_sort == 'latest'}" id="DESC"
                (click)="sortedBy($event,'latest')"> Latest
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row " *ngIf="profileList.length>0 && !inboxLoader">

        <div class="col-12 col-sm-6 my-3" *ngFor="let profile of profileList;let i= index">
          <div class="row shadow-sm border border-1 bg-light h-100 mx-0 overflow-hidden position-relative">
            <div class="col-12 col-lg-4 px-0">
              <div class="ratio-1 card-image">
                <img class="image-cover" *ngIf="!profile?.profile_photo || profile.profile_photo_approved!=1 "
                  src="assets/images/default-profile-picture.svg" alt="assets/images/men-avtar-2.svg">
                <div
                  *ngIf="profile?.profile_photo != null && !!profile?.profile_photo && profile.profile_photo_approved==1 ">
                  <ng-container
                    *ngIf="profile?.photo_visiblity == 'No one' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage != 5)">
                    <img class="image-cover" src="assets/images/default-profile-picture.svg"
                      alt="assets/images/men-avtar-2.svg">
                  </ng-container>
                  <ng-container
                    *ngIf="profile?.photo_visiblity == 'Visible to all members' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage == 5)">
                    <app-progressive-image class="progressive-image">
                      <img appProgressiveImage [src]="profile?.profile_photo"
                        class="img-fluid all_members margin-top-10" width="100%">
                      <div appProgressiveImagePlaceholder class="placeholderOneOrThree-matches">
                        <div id="loaderProgressive" class="centerProgressive1"></div>
                      </div>
                      <div appProgressiveImageFallback class="fallback">
                        <img appProgressiveImageFallback class="img-fluid"
                          src="assets/images/default-profile-picture.svg" alt="">
                      </div>
                    </app-progressive-image>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-8 py-2">
              <div class="row h-1001">
                <div class="col-12 h-100 d-flex flex-column">
                  <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0 card-nav">
                    <div class="col-6 px-0">
                      <h6 class="text-danger fw-bold mb-1" *ngIf="!!profile.display_name">
                        {{ profile.display_name }}
                      </h6>
                      <h6 class="text-danger fw-bold mb-1" *ngIf="!profile.display_name">
                        {{ profile.unique_key }}
                      </h6>
                      <div class="d-flex align-items-center">
                        <span class="user_email_verified"
                          [ngbTooltip]="profile?.is_user_verified ==1 ? 'Verified user': 'Verified email'"
                          placement="bottom">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                            class="bi bi-shield-fill-check mb-1 " [ngClass]="{'text-muted':profile?.is_user_verified != 1,
                                               'text-success':profile?.is_user_verified == 1}" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0
                                    0-1.044
                                    1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517
                                    2.453c.386.273.744.482
                                    1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159
                                    7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195
                                    3.061-5.513
                                    2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0
                                    0-2.887-.87C9.843.266
                                    8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708
                                    0l-1.5-1.5a.5.5 0
                                    1 1 .708-.708L7.5 7.793l2.646-2.647z" />
                          </svg>
                        </span>

                        <span class="user_email_verified1 mx-1" *ngIf="profile?.is_user_verified== 0 "
                          placement="bottom" ngbTooltip="Verified email"><i class="fa fa-check fs-5"
                            style="color:green"></i>
                        </span>
                        <span class="user_email_verified1 mx-1" *ngIf="profile?.is_user_verified== 1 "
                          placement="bottom" ngbTooltip="Verified user"><i class="fa fa-check-double fs-5 "
                            style="color:green"></i>
                        </span>
                        <div class="p-xsm mb-0 text-success" *ngIf="profile.is_logged_in">
                          <div class="flash d-inline-block me-1">
                            <div class>
                              <i class="fas fa-globe"></i>
                            </div>
                          </div>
                          <div class="d-inline-block">
                            Online
                          </div>
                        </div>
                        <div class="p-xsm mb-0 text-default" *ngIf="!profile.is_logged_in">
                          <div class="d-inline-block me-1">
                            <div class>
                              <i class="fas fa-globe"></i>
                            </div>
                          </div>
                          <div class="d-inline-block">
                            Offline
                          </div>
                        </div>

                        <div class="ms-2">
                          <img src="assets/images/icon/{{
                            profile.package_icon
                            }}" class="width-30" alt placement="top" ngbTooltip="Elite Member"
                            *ngIf="profile.package_id == 3" />
                          <img src="assets/images/icon/{{
                            profile.package_icon
                            }}" class="width-30" alt placement="top" ngbTooltip="Premium Member"
                            *ngIf="profile.package_id == 2" />
                        </div>
                      </div>
                    </div>
                    <div class="col-6 px-0 d-flex justify-content-end">
                      <div class="me-2 stage_wrp ">
                      </div>
                      <div class="dropdown center btn w-30-h-30 rounded-circle btn-danger border-0 pt-1">
                        <div class="ms-auto center btn w-20-h-20 btn-danger text-center border border-0
                          rounded-circle border-danger p-1" type="button" id="{{profile.user_id}}"
                          (click)="getId($event)" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="fas fa-caret-down h4 mt-2" id="{{profile.user_id}}" (click)="getId($event)"></i>
                        </div>
                        <ul class="dropdown-menu py-1 border border-1 border-danger"
                          aria-labelledby="dropdownMenuButton1" style="z-index:1;">
                          <li class="dropdown-item p-sm px-2"
                            (click)="connectNow(profile.user_id,profile.display_name,profile.profile_photo, profile?.profile_photo_approved)">
                            <i class="fas fa-handshake"></i> Connect
                            Now
                          </li>
                          <button style="display:none;" id="openModal2" data-bs-toggle="modal"
                            data-bs-target="#ConnectModal"></button>

                          <li class="dropdown-item p-sm px-2" id="back_to_shortlist"
                            (click)="actionSelected($event,profile.user_id)"><i class="fas fa-arrow-left"></i> Back to
                            Shortlist
                          </li>
                          <li class="dropdown-item p-sm px-2" id="block_profile"
                            (click)="actionSelected($event,profile.user_id)"><i class="fas fa-user-lock"></i> Block
                          </li>
                          <li class="dropdown-item p-sm px-2" id="block_and_report_profile"
                            data-bs-toggle="modal"
                            data-bs-target="#blockReportModal" (click)="userBlockReport(profile)"><i class="fas fa-user-clock"></i> Block &
                            Report
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-md-6 py-1 lh-lg">
                      <!-- <p class="mb-0 fw-bold"> {{ profile?.display_name}} <small class="p-sm"></small> </p> -->
                      <p class="text-muted mb-0 py-1 p-sm"> {{ profile.age}} yrs, {{
                        profile?.height|heightConverter:"Ft"}} </p>
                      <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.marital_status}} </p>
                      <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.religion | limit : 18}}<span
                          *ngIf="profile.caste">, {{
                          profile?.caste | limit
                          : 18}}</span>
                      </p>
                    </div>
                    <div class="col-12 col-md-6 py-1 lh-lg">
                      <p class="text-muted mb-0 py-1 p-sm" *ngIf="!!profile?.working_as"> {{ profile?.working_as | limit
                        : 20}}</p>
                      <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.current_city | limit : 20}}
                      </p>
                      <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.state_name | limit : 20}}
                      </p>
                      <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.living_in_country | limit : 20}}
                      </p>

                    </div>
                    <div class="col-12 py-1 lh-lg">
                      <p class="text-muted p-sm mb-0">{{profile.description | limit : 75}} <a href="javascript:void(0)"
                          (click)="ViewProfile(profile.user_id,profile.display_name)" class="default_link text-underline">View
                          Profile</a>
                      </p>
                      <button style="display:none;" id="openModal1" data-bs-target="#upgradeModal"
                        data-bs-toggle="modal"></button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-12 mt-auto">
            </div>
          </div>
        </div>
      </div>

      <div class="row center" *ngIf="profileList.length==0 && (!inboxLoader)"
        style="min-height: calc(123vh - 550px);text-align: center">
        <strong>You haven't added any profile to my favorites list.</strong>
      </div>
      <div class="row mt-3 justify-content-end" *ngIf="TotalRecord> 0 ">
        <div class="col-md-5 col-8 justify-content-center">
          <mat-paginator #paginator [pageSizeOptions]="[8, 16, 50, 100]" [length]="TotalRecord" showFirstLastButtons
            aria-label="Select page of periodic elements" (page)="handlePage($event)" [pageSize]="pageSize"
            [hidePageSize]="hidePageSize">
          </mat-paginator>
        </div>
        <div class="col-md-2 col-4 text-center" style="text-align:start; padding-top:20px;">
          <p class="mat-paginator-range">Page {{currentPage}} of {{lastPage}}</p>
        </div>
      </div>

      <div class="row mt-3">

      </div>
    </div>
    <!-- -------------------------------My Favorite-------------------- -->

  </div>
</div>
<app-login-footer></app-login-footer>

<div class="modal fade" id="upgradeModal" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">

        <p class="p-sm mb-4">
          <!-- Upgrade Now to start communicating with {{displayName}} -->
          Upgrade now to connect with {{displayName}}
        </p>
        <button class="btn btn-danger rounded-pill" data-bs-dismiss="modal" aria-label="Close"
          routerLink="/upgrade">Upgrade Now </button>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="ConnectModal" tabindex="-1" aria-labelledby="ConnectModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">
      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <div class="w-150 h-150 mx-auto border border-1 border-danger rounded-3 mb-2">
          <img *ngIf="!photoSrc || approve!=1" class="image-cover" src="assets/images/default-profile-picture.svg" alt>

          <div *ngIf="!!photoSrc &&  approve==1">

            <app-progressive-image class="progressive-image">
              <img appProgressiveImage [src]="photoSrc" alt="" class="img-fluid image-cover p-1 rounded-3">

              <div appProgressiveImagePlaceholder class="placeholderOneOrThree">
                <div id="loaderProgressive" class="centerProgressive"></div>
              </div>
              <div appProgressiveImageFallback class="fallback">
                <img appProgressiveImageFallback class="img-fluid image-cover p-1 rounded-3"
                  src="assets/images/default-profile-picture.svg" alt="">
              </div>
            </app-progressive-image>
          </div>
        </div>
        <h5 class="text-danger mb-4">{{displayName}}</h5>

        <p class="p-sm mb-4">
          Do you want to connect with {{ displayName }} ?”
        </p>
        <button class="btn btn-outline-danger" data-bs-dismiss="modal" aria-label="Close">
          No
        </button>
        <button class="btn btn-danger ms-2" data-bs-dismiss="modal" aria-label="Close" (click)="Connect()">
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Report & Block Modal -->
<div class="modal fade" id="blockReportModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">
      <div class="row modal-body">
        <div class="col-12 mb-2">
          <h5 class="fw-600 text-danger"> Please select reason:</h5>
        </div>
        <div class="col-12">
          <div class="form-check ps-0" *ngFor='let user of reportReasonsList ;let i = index'>
            <input class="checkbox me-2" type="radio" name="Serious" id="{{user.id}}" (click)="blockReportId(user)">
            <label class role="" for="{{user.id}}">
              {{user.label}}</label>
          </div>
          <div class="col-12 mb-3" *ngIf="otherReason">
            <form #wordCountNgForm="ngForm" [formGroup]="wordCountForm">
              <label for="NewPassword" id="reason" class="fw-bold"> Reason</label>
              <textarea id="reason" #texts rows="3" class="form-control" [(ngModel)]="reportReason"
                [formControlName]="'description'" (paste)="pasteNumeric6($event)" (change)="compare()"
                (keypress)="wordCounter($event)" maxlength="500"></textarea> <!-- -->

              <div class="form-label mb-2 py-1 fw-bold text-danger" *ngIf="exceedLimit"> You cannot enter more than the
                maximum of 20 words</div>
              <p class="text-muted p-xsm">Max 20 Words. </p>
              <p class="text-muted p-xsm">Entered- {{words}} Words</p>

            </form>
          </div>
        </div>
      </div>

      <div class="mt-4 text-end">
        <button class="btn btn-light border border-1 border-danger me-2" data-bs-dismiss="modal"
          aria-label="Close">Cancel </button>
        <button class="btn btn-danger m-2" data-bs-dismiss="modal" aria-label="Close" (click)="blockAndReportNow()"
          [disabled]="!reportReason">Submit</button>

      </div>
    </div>
  </div>
</div>
