<section class="container mx-auto my-5 mx-width-800">
  <div class="row">
    <div class="col-12" *ngIf="Uploaded">
      <h4 class="mb-4 py-2 text-center"><span class="fw-bold text-danger lh-base">
          Congrats! </span> Your profile has been created.
      </h4>
    </div>
  </div>


  <div class="row mt-4">

    <div class="col-12 text-center mx-auto">

      <div id="profile-upload" class="border border-2 border-danger rounded-circle position-relative mx-auto mt-93">
        <div class="hvr-profile-img" *ngIf="!is_uploaded">

          <div class="icon" data-bs-toggle="modal" data-bs-target="#loginModal">
            <div class="camera4"><span>
              </span></div>
          </div>
          <small class="text-dark m-4 d-block bottom-0 lh-sm fw-bold position-absolute" data-bs-toggle="modal"
            data-bs-target="#loginModal">Click
            here to
            upload your profile picture!</small>
        </div>
        <div *ngIf="is_uploaded">
          <div class="image-icon">
            <figure class="image-cover overflow-hidden rounded-circle">
              <img class="image-cover" id="blah" [src]="croppedImage"
                [style.border]="croppedImage ? '1px solid black' : 'none'" alt="your img" />
            </figure>

          </div>
        </div>
        <div>

          <div data-bs-toggle="modal" data-bs-target="#loginModal" class="w-30-h-30 rounded-circle p-1 top-0 right-0 btn btn-danger center
                        position-absolute me-2 mt-3">
            <i class="fas fa-camera h6 mb-0"> </i>
          </div>

        </div>
      </div>
    </div>


    <div class="col-12 text-center my-2 mx-auto" *ngIf="!imgSelected">
      <button class="btn btn-danger upload border border-2 w-150 fw-bold py-2 me-2"
        routerLink="/PartnerPreferences">Upload Later</button>
    </div>
    <div class="col-12 text-center my-2 mx-auto" *ngIf="imgSelected">
      <button class="btn fw-bold btn-light my-2 my-sm-0 mx-1" (click)="next()">Next</button>
    </div>





    <div class="col-12 col-sm-12 mt-4 px-4">
      <h5 class="p-0 fw-bold text-muted"> Photos Guidelines </h5>
      <div class="row my-3">
        <div class="col-12 d-flex align-items-center">
          <div class="">
            <small><i class="fas fa-check text-success"></i> Close Up </small>
            <div class="w-85-h-85">
              <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                src="assets/images/close-up-view.png" alt="">
            </div>
          </div>

          <div class="mx-3">
            <small><i class="fas fa-check text-success"></i> Half View </small>
            <div class="w-85-h-85">
              <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                src="assets/images/half-view.png" alt="">
            </div>
          </div>
          <div class="">
            <small><i class="fas fa-check text-success"></i> Full View </small>
            <div class="w-85-h-85">
              <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                src="assets/images/full-view.png" alt="">
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="col-12 pb-4">
      <p class="lh-lg p-sm fw-bold px-3 mb-0"> <i class="fas fa-check text-success"></i> Do's </p>
      <ul class="">
        <li class="lh-lg p-sm">Your profile photo should be front-facing and your entire photo should be visible. </li>
        <li class="lh-lg p-sm">Ensure that your photo is recent and not with a group.</li>
        <li class="lh-lg p-sm">You can upload upto 20 photos to your gallery.</li>
        <li class="lh-lg p-sm">Each photo must be less than 5 MB in size and must be in one of the following formats:
          jpg, gif, png, bmp.</li>
      </ul>

      <p class="h-lg p-sm fw-bold px-3 mb-0"> <i class="fas fa-times text-danger"></i> Don'ts</p>
      <ul class="">
        <li class="lh-lg p-sm"> Watermarked, digitally enhanced, morphed photos or photos with your personal information
          will be rejected.
        </li>
        <li class="lh-lg p-sm"> Irrelevant photographs will lead to deactivation of your profile and membership. </li>
        <li class="lh-lg p-sm"> The Picture should not be Blurry. </li>
        <li class="lh-lg p-sm"> Do not upload vulgar or nude images. </li>

      </ul>
    </div>
    <div class="col-12 rounded mx-auto py-2 px-3 border border-1 shadow-sm bg-light">
      <p class="p-sm mb-0 text-center"> <span class="fs-5 fw-bold text-danger">*</span> Photos will be screened, and
        added to your profile
        within 5 business days.</p>
    </div>

  </div>


  <div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
        <div class="modal-header border-0 pt-3 mb-3">
          <img src="assets/images/logo.png" class="mx-auto" alt="ETERNALSTRINGS LOGO" width="130px">

          <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closeLoginModal"
            data-bs-dismiss="modal" aria-label="Close" (click)="getUserDetails()"></button>
        </div>
        <div class="modal-body p-0">

          <div class="col-12 px-0">

          </div>

          <form [formGroup]="imageUploadForm" (ngSubmit)="submitForm()">
            <div class="mb-3 px-3">
              <input type="file" (change)=" uploadFile($event)" (click)="fileInput.value= null" #fileInput name=""
                class="upload" accept="image/png,image/jpeg,image/webp,image/bmp" id="profilePicture" />
              <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="1 / 1" [resizeToWidth]="256"
                [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="true" [canvasRotation]="canvasRotation"
                [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null :
                                'none'" format="png" (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                (loadImageFailed)="loadImageFailed()"></image-cropper>


            </div>

            <div class="col-12 text-center" *ngIf="imgSelected">
              <a href="javascript:void(0)" class="btn btn-light border border-1 border-danger me-2"
                (click)="rotateLeft()"><i class="fas
                                    fa-sync-alt"></i></a>
              <button type="submit" class="btn btn-danger" id="closeLoginModal" data-bs-dismiss="modal">Save</button>
            </div>

            <div class="row mx-0">

              <div class="col-6 ps-0 text-end">

              </div>


              <div class="col-12 mt-3 py-2 bg-maroon w-100">

                <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                    class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                      class="fas fa-long-arrow-alt-left"></i> Go
                    to eternalstrings.com</a> </p>
              </div>
            </div>
          </form>
        </div>
      </div>


    </div>
  </div>

</section>

<app-login-footer></app-login-footer>
