<div class="bg-maroon w-100">
  <app-login-header></app-login-header>
</div>
<div class="px-3">
  <section class="mx-width-800 mx-auto rounded-3 my-4 bg-white p-3 border border-1 shadow-sm">

    <div class="row">
      <div class="col-12">
        <h5 class="text-danger text-center fw-bold">Search For Profile </h5>
      </div>
    </div>

    <div class="col-12 mt-4">
      <h6 class="fw-bold"> Looking For A  </h6>
      <div class="select-box">
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="radio" name="Gender" id="Male" value="Male" selected>
          <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Male">Male</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="radio" name="Gender" id="Female" value="Female">
          <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Female">Female</label>
        </div>

      </div>
    </div>
    <div class="row mt-3">
      <h6 class="fw-bold"> Age </h6>
      <div class="row">
        <div class="col-5 col-md-3">
          <select class="form-select" aria-label="Default select example" required>
            <option value="1" selected>Select</option>
            <option value="1"> 18</option>
            <option value="1"> 19</option>
            <option value="1"> 20</option>
            <option value="1"> 21</option>
            <option value="1"> 22</option>
            <option value="1"> 23</option>
            <option value="1"> 24</option>
            <option value="1"> 25</option>
            <option value="1"> 26</option>
            <option value="1"> 27</option>
            <option value="1"> 28</option>
            <option value="1"> 29</option>
            <option value="1"> 30</option>
            <option value="1"> 31</option>
            <option value="1"> 32</option>
            <option value="1"> 33</option>
            <option value="1"> 34</option>
            <option value="1"> 35</option>
            <option value="1"> 36</option>
            <option value="1"> 37</option>
            <option value="1"> 38</option>
            <option value="1"> 39</option>
            <option value="1"> 40</option>
            <option value="1"> 41</option>
            <option value="1"> 42</option>
            <option value="1"> 43</option>
            <option value="1"> 44</option>
            <option value="1"> 45</option>
            <option value="1"> 46</option>
            <option value="1"> 47</option>
            <option value="1"> 48</option>
            <option value="1"> 49</option>
            <option value="1"> 50</option>
            <option value="1"> 51</option>
            <option value="1"> 52</option>
            <option value="1"> 53</option>
            <option value="1"> 54</option>
            <option value="1"> 55</option>
            <option value="1"> 56</option>
            <option value="1"> 57</option>
            <option value="1"> 58</option>
            <option value="1"> 59</option>
            <option value="1"> 60</option>
            <option value="1"> 61</option>
            <option value="1"> 62</option>
            <option value="1"> 63</option>
            <option value="1"> 64</option>
            <option value="1"> 65</option>
            <option value="1"> 66</option>
            <option value="1"> 67</option>
            <option value="1"> 68</option>
            <option value="1"> 69</option>
            <option value="1"> 70</option>
          </select>
        </div>
        <div class="col-1 my-auto text-center">
          To
        </div>
        <div class="col-5 col-md-3">
          <select class="form-select" aria-label="Default select example" required>
            <option value="1" selected>Select</option>
            <option value="1"> 18</option>
            <option value="1"> 19</option>
            <option value="1"> 20</option>
            <option value="1"> 21</option>
            <option value="1"> 22</option>
            <option value="1"> 23</option>
            <option value="1"> 24</option>
            <option value="1"> 25</option>
            <option value="1"> 26</option>
            <option value="1"> 27</option>
            <option value="1"> 28</option>
            <option value="1"> 29</option>
            <option value="1"> 30</option>
            <option value="1"> 31</option>
            <option value="1"> 32</option>
            <option value="1"> 33</option>
            <option value="1"> 34</option>
            <option value="1"> 35</option>
            <option value="1"> 36</option>
            <option value="1"> 37</option>
            <option value="1"> 38</option>
            <option value="1"> 39</option>
            <option value="1"> 40</option>
            <option value="1"> 41</option>
            <option value="1"> 42</option>
            <option value="1"> 43</option>
            <option value="1"> 44</option>
            <option value="1"> 45</option>
            <option value="1"> 46</option>
            <option value="1"> 47</option>
            <option value="1"> 48</option>
            <option value="1"> 49</option>
            <option value="1"> 50</option>
            <option value="1"> 51</option>
            <option value="1"> 52</option>
            <option value="1"> 53</option>
            <option value="1"> 54</option>
            <option value="1"> 55</option>
            <option value="1"> 56</option>
            <option value="1"> 57</option>
            <option value="1"> 58</option>
            <option value="1"> 59</option>
            <option value="1"> 60</option>
            <option value="1"> 61</option>
            <option value="1"> 62</option>
            <option value="1"> 63</option>
            <option value="1"> 64</option>
            <option value="1"> 65</option>
            <option value="1"> 66</option>
            <option value="1"> 67</option>
            <option value="1"> 68</option>
            <option value="1"> 69</option>
            <option value="1"> 70</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <h6 class="fw-bold"> Height </h6>
      <div class="row">
        <div class="col-5 col-md-3">
          <select class="form-select" aria-label="Default select example">
            <option selected>Select</option>
            <option value="1">
              < 5' (< 152 cm)</option> <option value="1"> 5'0" (152 cm)
            </option>
            <option value="1"> 5'1" (155 cm) </option>
            <option value="1"> 5'2" (157 cm) </option>
            <option value="1"> 5'3" (160 cm) </option>
            <option value="1"> 5'4" (163 cm) </option>
            <option value="1"> 5'5" (165 cm) </option>
            <option value="1"> 5'6" (168 cm) </option>
            <option value="1"> 5'7" (170 cm) </option>
            <option value="1"> 5'8" (173 cm) </option>
            <option value="1">5'9" (175 cm) </option>
            <option value="1"> 5'10" (178 cm) </option>
            <option value="1"> 5'11" (180 cm) </option>
            <option value="1"> 6'0" (183 cm) </option>
            <option value="1"> 6'1" (185 cm) </option>
            <option value="1"> 6'2" (188 cm) </option>
            <option value="1"> 6'3" (191 cm) </option>
            <option value="1"> 6'4" (193 cm) </option>
            <option value="1"> 6'5" (196 cm) </option>
            <option value="1"> 6'6" (198 cm) </option>
            <option value="1"> 6'7" (201 cm) </option>
            <option value="1"> 6'8" (203 cm) </option>
            <option value="1"> 6'9" (206 cm) </option>
            <option value="1"> 6'10" (208 cm) </option>
            <option value="1"> 6'11" (211 cm) </option>
            <option value="1"> > 7' 0" (> 213 cm) </option>
          </select>
        </div>
        <div class="col-1 my-auto text-center">
          To
        </div>
        <div class="col-5 col-md-3">
          <select class="form-select" aria-label="Default select example">
            <option selected>Select</option>
            <option value="1">
              < 5' (< 152 cm)</option> <option value="1"> 5'0" (152 cm)
            </option>
            <option value="1"> 5'1" (155 cm) </option>
            <option value="1"> 5'2" (157 cm) </option>
            <option value="1"> 5'3" (160 cm) </option>
            <option value="1"> 5'4" (163 cm) </option>
            <option value="1"> 5'5" (165 cm) </option>
            <option value="1"> 5'6" (168 cm) </option>
            <option value="1"> 5'7" (170 cm) </option>
            <option value="1"> 5'8" (173 cm) </option>
            <option value="1">5'9" (175 cm) </option>
            <option value="1"> 5'10" (178 cm) </option>
            <option value="1"> 5'11" (180 cm) </option>
            <option value="1"> 6'0" (183 cm) </option>
            <option value="1"> 6'1" (185 cm) </option>
            <option value="1"> 6'2" (188 cm) </option>
            <option value="1"> 6'3" (191 cm) </option>
            <option value="1"> 6'4" (193 cm) </option>
            <option value="1"> 6'5" (196 cm) </option>
            <option value="1"> 6'6" (198 cm) </option>
            <option value="1"> 6'7" (201 cm) </option>
            <option value="1"> 6'8" (203 cm) </option>
            <option value="1"> 6'9" (206 cm) </option>
            <option value="1"> 6'10" (208 cm) </option>
            <option value="1"> 6'11" (211 cm) </option>
            <option value="1"> > 7' 0" (> 213 cm) </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <h6 class="fw-bold"> Weight </h6>
      <div class="row">
        <div class="col-5 col-md-3">
          <select class="form-select" aria-label="Default select example">
            <option selected>Select</option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>             
          </select>
        </div>
        <div class="col-1 my-auto text-center">
          To
        </div>
        <div class="col-5 col-md-3">
          <select class="form-select" aria-label="Default select example">
            <option selected>Select</option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>
            <option value="1"> 88.5 lb (40 kg) </option>             
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <h6 class="fw-bold"> Marital Status </h6>
        <div class="select-box">
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="MaritalStatus" id="Annulled" value="Annulled">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="Annulled">Annulled</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="MaritalStatus" id="AwaitingDivorce"
              value="AwaitingDivorce">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="AwaitingDivorce"> Awaiting
              Divorce </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="MaritalStatus" id="Divorced" value="Divorced">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="Divorced">Divorced</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="MaritalStatus" id="Single" value="Single">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="Single">Single</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="MaritalStatus" id="Widowed" value="Widowed">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="Widowed">Widowed</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <h6 class="fw-bold"> Have Children </h6>
        <div class="select-box">
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="haveChildren" id="ChildrenYes" value="ChildrenYes">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="ChildrenYes">Yes</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="haveChildren" id="ChildrenNo" value="ChildrenNo">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="ChildrenNo"> No </label>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3">
        <div class="row row-cols-2 gx-5 text-center">
          <div class="col-12 col-md-6 d-flex">
            <div class="">
              <div class="form-check form-check-inline p-0 mb-0 me-1">
                <input class="form-check-input" type="checkbox" name="ChildNo" id="BoyChild" value="BoyChild">
                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 text-primary" for="BoyChild">
                  <i class="fas fa-male fs-2"><span style="display: none;">male</span></i>
                </label>
              </div>

            </div>
            <div class="mx-2">
              <input type="number" class="width-80 form-control text-center" id="exampleFormControlInput1"
                placeholder="0">
              <p class="p-sm mb-0">Not married</p>
            </div>

            <div class="">
              <input type="number" class="width-80  form-control text-center" id="exampleFormControlInput1"
                placeholder="0">
              <p class="p-sm mb-0">Married</p>
            </div>
          </div>

          <div class="col-12 col-md-6 d-flex">
            <div class="">
              <div class="form-check form-check-inline p-0 mb-0 me-1">
                <input class="form-check-input" type="checkbox" name="ChildNo" id="GirlChild" value="GirlChild">
                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="GirlChild">
                  <i class="fas fa-female fs-2"></i><span style="display: none;">male</span>
                </label>
              </div>
            </div>
            <div class="mx-2">
              <input type="number" class="width-80  form-control text-center" id="exampleFormControlInput1"
                placeholder="0">
              <p class="p-sm mb-0">Not married</p>
            </div>

            <div class="">
              <input type="number" class="width-80 form-control text-center" id="exampleFormControlInput1"
                placeholder="0">
              <p class="p-sm mb-0">Married</p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="row ">
     
      <div class="col-12 mt-3">
        <h6 class="fw-bold"> Religious Views</h6>
        <div class="select-box">
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Religious" id="Buddhist" value="Buddhist">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Buddhist">Buddhist</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Religious" id="Christian" value="Christian">
            <label class="form-check-label  p-2 rounded-3 shadow-sm border border-1" for="Christian">Christian</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Religious" id="Hindu" value="Hindu">
            <label class="form-check-label  p-2 rounded-3 shadow-sm border border-1" for="Hindu">Hindu</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Religious" id="Jain" value="Jain">
            <label class="form-check-label  p-2 rounded-3 shadow-sm border border-1" for="Jain">Jain</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Religious" id="Muslim" value="Muslim">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Muslim">Muslim</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Religious" id="Parsi" value="Parsi">
            <label class="form-check-label  p-2 rounded-3 shadow-sm border border-1" for="Parsi">Parsi</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Religious" id="Sikh" value="Sikh">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Sikh">Sikh</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Religious" id="NoReligion" value="NoReligion">
            <label class="form-check-label  p-2 rounded-3 shadow-sm border border-1" for="NoReligion">No
              Religion</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Religious" id="Other" value="Other">
            <label class="form-check-label  p-2 rounded-3 shadow-sm border border-1" for="Other">Other</label>
          </div>
        </div>

      </div>
    </div>

    <div class="row">
  
      <div class="col-12 mt-3">
        <h6 class="fw-bold "> Language </h6>
        <div class="select-box">
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Language" id="Bengali" value="Bengali">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Bengali">Bengali</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Language" id="English" value="English">
            <label class="form-check-label  p-2 rounded-3 shadow-sm border border-1" for="English">English</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Language" id="Gujarati" value="Gujarati">
            <label class="form-check-label  p-2 rounded-3 shadow-sm border border-1" for="Gujarati">Gujarati</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Language" id="Hindi" value="Hindi">
            <label class="form-check-label  p-2 rounded-3 shadow-sm border border-1" for="Hindi">Hindi</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Language" id="Marathi" value="Marathi">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Marathi">Marathi</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Language" id="Punjabi" value="Punjabi">
            <label class="form-check-label  p-2 rounded-3 shadow-sm border border-1" for="Punjabi">Punjabi</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Language" id="Tamil" value="Tamil">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Tamil">Tamil</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Language" id="Telugu" value="Telugu">
            <label class="form-check-label  p-2 rounded-3 shadow-sm border border-1" for="Telugu">Telugu</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" name="Language" id="OtherLanguage" value="OtherLanguage">
            <label class="form-check-label  p-2 rounded-3 shadow-sm border border-1" for="OtherLanguage">Other</label>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion" id="accordionPanelsStayOpenExample">

      <div class="accordion-item border-0 mt-4">
        <div class="accordion-header py-0 border border-1 bg-light d-flex justify-content-between align-items-center"
          id="panelsStayOpen-headingOne">
          <div class="ps-1 fw-bold mb-0 fs-5 text-muted">
            Location & Grew up in Details
          </div>
          <div class="border-0 px-2 py-2 accordion-btn" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne">
            <i class="fas fa-sort-down fs-3 me-2"></i>
          </div>
        </div>
        <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingOne">
          <div class="accordion-body px-0">
            <div class="row">
              <div class="col-12 col-md-6 mt-3">
                <label for="LivingIn" class="form-label mb-2 py-1 fw-bold h6"> Country </label>
                <angular2-multiselect [data]="dropdownList" id="LivingIn" [(ngModel)]="selectedItems"
                  [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label for="StateProvince" class="form-label mb-2 py-1 fw-bold h6"> State / Province </label>
                <angular2-multiselect [data]="dropdownList2" id="StateProvince" [(ngModel)]="selectedItems2"
                  [settings]="dropdownSettings2">
                </angular2-multiselect>
              </div>

              <div class="col-12 col-md-6 mt-3">
                <label for="livingCity" class="form-label mb-2 py-1 fw-bold h6"> City </label>
                <input type="text" class="form-control" id="livingCity" placeholder="Enter">
              </div>
            </div>

            <div class="row mt-3">

              <h6 class="fw-bold"> Residency Status </h6>
              <div class="select-box">
                <div class="form-check form-check-inline p-0 me-3 mb-3">
                  <input class="form-check-input" type="radio" name="ResidencyStatus" id="Citizen" value="Citizen">
                  <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Citizen">Citizen</label>
                </div>
                <div class="form-check form-check-inline p-0 me-3 mb-3">
                  <input class="form-check-input" type="radio" name="ResidencyStatus" id="PermanentResident"
                    value="PermanentResident">
                  <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                    for="PermanentResident">Permanent Resident</label>
                </div>
                <div class="form-check form-check-inline p-0 me-3 mb-3">
                  <input class="form-check-input" type="radio" name="ResidencyStatus" id="StudentVisa"
                    value="StudentVisa">
                  <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="StudentVisa">Student
                    Visa</label>
                </div>
                <div class="form-check form-check-inline p-0 me-3 mb-3">
                  <input class="form-check-input" type="radio" name="ResidencyStatus" id="Visitor" value="Visitor">
                  <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Visitor">Visitor</label>
                </div>
                <div class="form-check form-check-inline p-0 me-3 mb-3">
                  <input class="form-check-input" type="radio" name="ResidencyStatus" id="WorkPermit"
                    value="WorkPermit">
                  <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="WorkPermit">Work
                    Permit</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 mt-3">
                <label for="LivingIn" class="form-label mb-2 py-1 fw-bold h6"> Country Grew up in </label>
                <angular2-multiselect [data]="dropdownList" id="LivingIn" [(ngModel)]="selectedItems"
                  [settings]="dropdownSettings">
                </angular2-multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item border-0 mt-4">
        <div class="accordion-header py-0 bg-light border border-1 d-flex justify-content-between align-items-center"
          id="panelsStayOpen-headingTwo">
          <div class="ps-1 fw-bold mb-0 fs-5 text-muted">
            Education & Profession Details
          </div>
          <div class="border-0 px-2 py-2 accordion-btn" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true"
            aria-controls="panelsStayOpen-collapseTwo">
            <i class="fas fa-sort-down fs-3 me-2"></i>
          </div>
        </div>
        <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingTwo">
          <div class="accordion-body px-0">
            <div class="row">
              <div class="col-12 mt-3">
                <h6 class="fw-bold"> Education </h6>
                <div class="select-box">
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="Qualification" id="HighSchool"
                      value="HighSchool">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="HighSchool"> High
                      School </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="Qualification" id="SomeCollege"
                      value="SomeCollege">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="SomeCollege"> Some
                      College
                    </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="Qualification" id="SomeUniversity"
                      value="SomeUniversity">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="SomeUniversity"> Some
                      University </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="Qualification" id="AssociateDegree"
                      value="AssociateDegree">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="AssociateDegree">
                      Associate's Degree </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="Qualification" id="BachelorDegree"
                      value="BachelorDegree">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="BachelorDegree">
                      Bachelor's
                      Degree </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="Qualification" id="MasterDegree"
                      value="MasterDegree">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="MasterDegree"> Master's
                      Degree </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="Qualification" id="GraduateDegree"
                      value="GraduateDegree">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="GraduateDegree">
                      Graduate
                      Degree </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="Qualification" id="PostDoctoral"
                      value="PostDoctoral">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="PostDoctoral"> PhD /
                      Post
                      Doctorate </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">

              <div class="col-12 mt-3">
                <h6 class="fw-bold"> Education Area </h6>
                <div class="select-box">
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="EducationArea" id="ArtsDesign"
                      value="ArtsDesign">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="ArtsDesign">Arts /
                      Design </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="EducationArea" id="ComputersIT"
                      value="ComputersIT">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="ComputersIT">Computers
                      / IT </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="EducationArea" id="Medicine" value="Medicine">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Medicine">Medicine
                    </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="EducationArea" id="Management"
                      value="Management">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Management">Management
                    </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="EducationArea" id="Engineering"
                      value="Engineering">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                      for="Engineering">Engineering </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="EducationArea" id="FinanceCommerce"
                      value="FinanceCommerce">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                      for="FinanceCommerce">Finance / Commerce </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="EducationArea" id="Science" value="Science">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Science">Science
                    </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="EducationArea" id="Law" value="Law">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Law">Law </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="EducationArea" id="EducationAreaOther"
                      value="EducationAreaOther">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                      for="EducationAreaOther">Other </label>
                  </div>

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mt-3">
                <h6 class="fw-bold"> Working With </h6>
                <div class="select-box">
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="WorkingWith" id="BusinessSelf"
                      value="BusinessSelf">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="BusinessSelf"> Business
                      /
                      Self Employed </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="WorkingWith" id="GovernmentPublic"
                      value="GovernmentPublic">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                      for="GovernmentPublic">Government / Public Sector </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="WorkingWith" id="PrivateCompany"
                      value="PrivateCompany">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="PrivateCompany">
                      Private
                      Company </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="WorkingWith" id="NotWorking" value="NotWorking">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="NotWorking">
                      Not Working </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mt-3">
                <h6 class="fw-bold"> Profession Area </h6>
                <div class="select-box">
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="ProfessionArea" id="Accounting"
                      value="Accounting">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Accounting">
                      Accounting,
                      Banking & Finance </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="ProfessionArea" id="Administration"
                      value="Administration">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                      for="Administration">Administration & HR </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="ProfessionArea" id="Airline" value="Airline">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Airline">Airline &
                      Aviation</label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="ProfessionArea" id="Agriculture"
                      value="Agriculture">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                      for="Agriculture">Agriculture</label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="ProfessionArea" id="Jewellery" value="Jewellery">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Jewellery"> Beauty & Fashion  </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="ProfessionArea" id="SalesMarketing"
                      value="SalesMarketing">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="SalesMarketing">Sales &
                      Marketing</label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="ProfessionArea" id="MerchantNavy"
                      value="MerchantNavy">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="MerchantNavy">Merchant
                      Navy</label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="ProfessionArea" id="OtherProfession"
                      value="OtherProfession">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                      for="OtherProfession">Other</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 mt-3">
                <h6 class="fw-bold"> Working As </h6>
                <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter">
              </div>
            </div>

            <div class="row">
              <div class="col-12 mt-3">
                <h6 class="fw-bold"> Annual Income </h6>
                <div class="select-box">
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="AnnualIncome" id="AnnualIncomeAll"
                      value="AnnualIncomeAll">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="AnnualIncomeAll"> Open
                      to
                      all
                    </label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="AnnualIncome" id="SpecifyRange"
                      value="SpecifyRange">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="SpecifyRange"> Specify
                      an
                      income range </label>
                  </div>

                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 col-sm-3 py-2 my-auto">
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-3">
                    <select class="form-select" aria-label="Default select example">
                      <option value="volvo" selected>Select</option>
                      <option value="1">AED</option>
                      <option value="1">AUD</option>
                      <option value="1">CAD</option>
                      <option value="1">CHF</option>
                      <option value="1">EUR</option>
                      <option value="1">GBP</option>
                      <option value="1">INR</option>
                      <option value="1">USD</option>
                    </select>
                  </div>

                  <div class="col-4">
                    <input type="text" class="form-control " name="phone" placeholder="Enter" id="phone" value=" "
                      required="">
                  </div>
                  <div class="col-1 my-auto fw-bold text-center text-muted"> TO </div>
                  <div class="col-4">
                    <input type="text" class="form-control " name="phone" placeholder="Enter" id="phone" value=" "
                      required="">
                  </div>
                </div>
                <div class="col-12">
                  <div class="py-2">
                    <input class="checkbox me-2" type="checkbox" value="" id="flexCheckIndeterminate">
                    <label class="" for="flexCheckIndeterminate">
                      Include Profiles who have not specified their income
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="accordion-item border-0 mt-4">
        <div class="accordion-header py-0 bg-light border border-1 d-flex justify-content-between align-items-center"
          id="panelsStayOpen-headingThree">
          <div class="ps-1 fw-bold mb-0 fs-5 text-muted">
            Lifestyle
          </div>
          <div class="border-0 px-2 py-2 accordion-btn" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="true"
            aria-controls="panelsStayOpen-collapseThree">
            <i class="fas fa-sort-down fs-3 me-2"></i>
          </div>
        </div>
        <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingThree">
          <div class="accordion-body px-0">

            <div class="row mt-3">
              <div class="col-12 mt-3">
                <h6 class="fw-bold"> Food Preference </h6>
                <div class="select-box">
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="FoodPreference" id="Veg" value="Veg">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Veg">Veg</label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="FoodPreference" id="NonVeg" value="Non Veg">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="NonVeg">Non veg</label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="FoodPreference" id="Occasionally"
                      value="Occasionally">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                      for="Occasionally">Occasionally
                      Non-Veg</label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="FoodPreference" id="Eggetarian"
                      value="Eggetarian">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                      for="Eggetarian">Eggetarian</label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="FoodPreference" id="Pescatarian"
                      value="Pescatarian">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                      for="Pescatarian">Pescatarian</label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="FoodPreference" id="JainFood" value="JainFood">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="JainFood">Jain</label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="FoodPreference" id="Vegan" value="Vegan">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Vegan">Vegan</label>
                  </div>
                  <div class="form-check form-check-inline p-0 me-3 mb-3">
                    <input class="form-check-input" type="radio" name="FoodPreference" id="OtherFood" value="OtherFood">
                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                      for="OtherFood">Other</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="accordion-item border-0 mt-4">
        <div class="accordion-header py-0  border border-1 bg-light d-flex justify-content-between align-items-center"
          id="panelsStayOpen-headingFourth">
          <div class="ps-1 fw-bold mb-0 fs-5 text-muted">
            Search using Keywords
          </div>
          <div class="border-0 px-2 py-2 accordion-btn" type="button" data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseFourth" aria-expanded="true"
            aria-controls="panelsStayOpen-collapseFourth">
            <i class="fas fa-sort-down fs-3 me-2"></i>
          </div>
        </div>
        <div id="panelsStayOpen-collapseFourth" class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingFourth">
          <div class="accordion-body px-0">


            <div class="row"> 
                <div class="col-12 mt-3">
                  <h6 class="fw-bold"> Chat Status </h6>
                  <div class="select-box">
                    <div class="form-check form-check-inline p-0 me-3 mb-3">
                      <input class="form-check-input" type="radio" name="ChatStatus" id="ChatStatus" value="ChatStatus">
                      <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="ChatStatus">Only
                        Profiles available for Chat</label>
                    </div>
                  </div>
              </div>
            </div>
            <div class="row"> 
                <div class="col-12 mt-3">
                  <h6 class="fw-bold"> Photo Settings </h6>
                  <div class="select-box">
                    <div class="form-check form-check-inline p-0 me-3 mb-3">
                      <input class="form-check-input" type="radio" name="PhotoSettings" id="PhotoSettings1" value="PhotoSettings1">
                      <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="PhotoSettings1">Only
                        Profiles available for Chat</label>
                    </div>
                    <div class="form-check form-check-inline p-0 me-3 mb-3">
                      <input class="form-check-input" type="radio" name="PhotoSettings" id="PhotoSettings2" value="PhotoSettings2">
                      <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="PhotoSettings2">Only
                        Profiles available for Chat</label>
                    </div>
                </div>
              </div>
            </div>

            <div class="row"> 
                <div class="col-12 mt-3">
                  <h6 class="fw-bold"> Do Not Show </h6>
                  <div class="select-box">
                    <div class="form-check form-check-inline p-0 me-3 mb-3">
                      <input class="form-check-input" type="radio" name="DoNotShow" id="DoNotShow1" value="DoNotShow1">
                      <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="DoNotShow1">Profiles that have Filtered me out</label>
                    </div>
                    <div class="form-check form-check-inline p-0 me-3 mb-3">
                      <input class="form-check-input" type="radio" name="DoNotShow" id="DoNotShow2" value="DoNotShow2">
                      <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="DoNotShow2">Profiles that I have already Viewed</label>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




    </div>





    <div class="row mt-5 mb-4 text-center">
      <div class="col-12">
        <a href="javascript:void(0)" routerLink="/matches"> <button type="button"
            class="btn shadow-sm btn-danger fs-5">Search
          </button></a>
        <button type="button" class="ms-2 btn btn-outline-light text-danger border border-1 shadow-sm fs-5"> Reset </button>
      </div>
    </div>

  </section>

</div>



<app-login-footer></app-login-footer>
