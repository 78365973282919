import { OwlOptions } from 'ngx-owl-carousel-o';
import { Component, OnInit, ViewChild/*, ViewEncapsulation, HostListener*/, Renderer2, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm, } from '@angular/forms';
import { RegisterService } from '../services/register.service';
import { CookieService } from 'ngx-cookie-service';
import { PartnerPreferenceService } from '../services/partner-preference.service';
import { MyProfileService } from '../services/my-profile.service';
import * as moment from 'moment';
// unused import
import { of } from 'rxjs';
import { AuthService } from '../shared/auth.service';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotifService } from '../services/notif.service';
import { Title/*, Meta */ } from '@angular/platform-browser';
import { ImageCroppedEvent/*, LoadedImage*/, Dimensions, ImageTransform } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { RegisterNxtService } from '../services/register-nxt.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../services/common.service';
import { AuthStateService } from '../shared/auth-state.service';
import { TokenService } from '../shared/token.service';

@Component({
  selector: 'app-myinfo',
  templateUrl: './myinfo.component.html',
  styleUrls: ['./myinfo.component.css'],
})
export class MyinfoComponent implements OnInit {
  @ViewChild('tellUsMore') tellUsMore: ElementRef;
  @ViewChild('aboutMe') aboutMe: ElementRef;
  @ViewChild('aboutMe2') aboutMe2: ElementRef;

  @ViewChild('text') text: ElementRef;
  //focus on other field
  @ViewChild('otherLanguage') otherLanguageInput: ElementRef;
  @ViewChild('nameEdit') nameEditInput: ElementRef;
  @ViewChild('otherReligion') otherReligionInput: ElementRef;
  @ViewChild('otherQualification') otherQualificationInput: ElementRef;
  @ViewChild('otherResidency') otherResidencyInput: ElementRef;
  @ViewChild('otherFood') otherFoodInput: ElementRef;
  @ViewChild('otherEducation') otherEducationInput: ElementRef;
  @ViewChild('otherNatureOfWork') otherNatureOfWorkInput: ElementRef;
  @ViewChild('otherProfession') otherProfessionInput: ElementRef;
  @ViewChild('otherResidencyStatus') otherResidencyStatusInput: ElementRef;
  //areaSelect :boolean=false;
  divbox: any;
  aboutYou = true;
  details: any = [];
  creatorDetails: any = [];
  age: any;
  year: any;
  Affluence: boolean = false;
  disReq: boolean = false;
  isEditAboutYou: boolean = false;
  isEditBasic: boolean = false;
  isEditReligion: boolean = false;
  isEditAstro: boolean = false;
  isEditFamily: boolean = false;
  isEditEducation: boolean = false;
  isEditHobbies: boolean = false;
  isEditLocation: boolean = false;
  isEditContactDetail: boolean = false;
  isEditHandOverForm: boolean = false;
  /// partner
  isEditPartnerBasic: boolean = false;
  isEditPartnerReligion: boolean = false;
  isEditPartnerFamily: boolean = false;
  isEditPartnerLocation: boolean = false;
  isEditPartnerEducation: boolean = false;
  partnerDetail: any = '';
  partnerReligionSubmitted: boolean = false;
  partnerLocationSubmitted: boolean = false;
  partnerEducationSubmitted: boolean = false;
  partnerBasicSubmitted: boolean = false;

  partnerLanguages = [
    { name: 'Bengali' },
    { name: 'English' },
    { name: 'Hindi' },
    { name: 'Punjabi' },
    { name: 'Gujarati' },
    { name: 'Marathi' },
    { name: 'Tamil' },
    { name: 'Telugu' },
    { name: 'Any Language' },
    { name: 'Other' },
  ];
  selectedlanguages: any = [];

  user_id: any;
  weight: any = [];
  height: any = [];
  age_select: any = [];
  years: any = [];
  astroyears: any = [];
  hour: any = [];
  minute: any = [];
  second: any = [];
  dateSel: { id: number; name: number }[];
  astrodateSel: { id: number; name: number }[];
  timetocall: any = [];
  timetocall1: any = [];

  yearSel: { id: number; name: number }[];
  monthSel = [
    { id: '1', name: 'January' },
    { id: '2', name: 'February' },
    { id: '3', name: 'March' },
    { id: '4', name: 'April' },
    { id: '5', name: 'May' },
    { id: '6', name: 'June' },
    { id: '7', name: 'July' },
    { id: '8', name: 'August' },
    { id: '9', name: 'September' },
    { id: '10', name: 'October' },
    { id: '11', name: 'November' },
    { id: '12', name: 'December' },
  ];
  astromonthSel = [
    { id: '1', name: 'January' },
    { id: '2', name: 'February' },
    { id: '3', name: 'March' },
    { id: '4', name: 'April' },
    { id: '5', name: 'May' },
    { id: '6', name: 'June' },
    { id: '7', name: 'July' },
    { id: '8', name: 'August' },
    { id: '9', name: 'September' },
    { id: '10', name: 'October' },
    { id: '11', name: 'November' },
    { id: '12', name: 'December' },
  ];

  maxYear = moment().year() - 18;
  minYear = moment().year() - 90;
  displayName: any;
  aboutYouSubmitted: boolean = false;
  educationSubmitted: boolean = false;
  locationSubmitted: boolean = false;
  locationButton: boolean = false;
  handoverSubmitted: boolean = false;

  contactSubmitted: boolean = false;
  isQualificationOther: boolean = false;
  age_from: any = [];
  age_to: any = [];
  height_from: any = [];
  height_to: any = [];
  days: any = [];
  weight_from: any = [];
  weight_to: any = [];

  minNumberLength: boolean = false;
  maxNumberLength: boolean = false;

  // About You Form
  aboutYouForm = new FormGroup({
    first_name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25), Validators.pattern('^[a-zA-Z ]+$')]),
    last_name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25), Validators.pattern('^[a-zA-Z ]+$')]),
    display_name: new FormControl('', Validators.required),
    height: new FormControl('', Validators.required),
    weight: new FormControl('', Validators.required),
    marital_status: new FormControl('', Validators.required),
    have_children: new FormControl(''),
    boy_child: new FormControl(''),
    boy_child_not_married: new FormControl(''),
    boy_child_married: new FormControl(''),
    girl_child: new FormControl(''),
    girl_child_not_married: new FormControl(''),
    girl_child_married: new FormControl(''),
    age: new FormControl(''),
    description: new FormControl('', Validators.required),
    languages: new FormControl('', Validators.required),
    otherLanguage: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]),
    living_in_country: new FormControl(''),
    state_name: new FormControl(''),
    current_city: new FormControl(''),
  });
  // religion form
  religionForm = new FormGroup({
    religion: new FormControl('', Validators.required),
    community: new FormControl('', [Validators.minLength(2), Validators.maxLength(25)]),
    otherReligion: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]),
    caste: new FormControl('', Validators.maxLength(25)),
    gothra: new FormControl('', Validators.maxLength(25)),
  });
  // astro form
  astroForm = new FormGroup({
    dob: new FormControl(''),
    month: new FormControl('', Validators.required),
    year: new FormControl('', Validators.required),
    day: new FormControl('', Validators.required),
    time_of_birth: new FormControl(''),
    hour: new FormControl(''),
    minute: new FormControl(''),
    am_pm: new FormControl(''),
    manglik: new FormControl(''),
    country_of_birth: new FormControl('', Validators.required),
    city_of_birth: new FormControl('', [Validators.minLength(3), Validators.maxLength(25)]),
  });
  // education form
  educationForm = new FormGroup({
    qualification: new FormControl('', Validators.required),
    otherQualification: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    college_name: new FormControl('', Validators.maxLength(100)),
    annual_income: new FormControl(''),
    nature_of_work: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    otherNatureOfWork: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    working_as: new FormControl(''),
    company_name: new FormControl('', Validators.maxLength(50)),
    income_currency: new FormControl(''),
    otherProfession: new FormControl('', Validators.maxLength(50)),
  });
  //location form
  locationForm = new FormGroup({
    current_residence: new FormControl(''),
    living_in_country: new FormControl('', Validators.required),
    state_name: new FormControl('', Validators.required),
    current_city: new FormControl('', Validators.required),
    residency_status: new FormControl('', Validators.required),
    otherResidency: new FormControl('', Validators.required),
    postal_code: new FormControl(''),
  });
  // Hobby form
  hobbyForm = new FormGroup({
    hobbies: new FormControl(''),
    interests: new FormControl(''),
    preferred_movie: new FormControl(''),
    favorite_music: new FormControl(''),
    sports_fitness: new FormControl(''),
    favorite_cuisine: new FormControl(''),
    preferred_dress_style: new FormControl(''),
  });

  // contact form
  contactForm = new FormGroup({
    profile_created_by: new FormControl(''),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    countryNumberCode: new FormControl(''),
    countryCode: new FormControl(''),
    mobile_no: new FormControl(''),
    contact_person: new FormControl(''),
    relationship_with_member: new FormControl(''),
    convenient_time_to_call: new FormControl(''),
    days: new FormControl(''),
    display_contact_option: new FormControl(''),
    email: new FormControl(''),
    monday_time_from: new FormControl(''),
    monday_hour_from: new FormControl(''),
    monday_minute_from: new FormControl(''),
    monday_am_pm_from: new FormControl(''),
    monday_time_to: new FormControl(''),
    monday_hour_to: new FormControl(''),
    monday_minute_to: new FormControl(''),
    monday_am_pm_to: new FormControl(''),
    tuesday_time_from: new FormControl(''),
    tuesday_hour_from: new FormControl(''),
    tuesday_minute_from: new FormControl(''),
    tuesday_am_pm_from: new FormControl(''),
    tuesday_time_to: new FormControl(''),
    tuesday_hour_to: new FormControl(''),
    tuesday_minute_to: new FormControl(''),
    tuesday_am_pm_to: new FormControl(''),
    wednesday_time_from: new FormControl(''),
    wednesday_hour_from: new FormControl(''),
    wednesday_minute_from: new FormControl(''),
    wednesday_am_pm_from: new FormControl(''),
    wednesday_time_to: new FormControl(''),
    wednesday_hour_to: new FormControl(''),
    wednesday_minute_to: new FormControl(''),
    wednesday_am_pm_to: new FormControl(''),
    thursday_time_from: new FormControl(''),
    thursday_hour_from: new FormControl(''),
    thursday_minute_from: new FormControl(''),
    thursday_am_pm_from: new FormControl(''),
    thursday_time_to: new FormControl(''),
    thursday_hour_to: new FormControl(''),
    thursday_minute_to: new FormControl(''),
    thursday_am_pm_to: new FormControl(''),
    friday_time_from: new FormControl(''),
    friday_hour_from: new FormControl(''),
    friday_minute_from: new FormControl(''),
    friday_am_pm_from: new FormControl(''),
    friday_time_to: new FormControl(''),
    friday_hour_to: new FormControl(''),
    friday_minute_to: new FormControl(''),
    friday_am_pm_to: new FormControl(''),
    saturday_time_from: new FormControl(''),
    saturday_hour_from: new FormControl(''),
    saturday_minute_from: new FormControl(''),
    saturday_am_pm_from: new FormControl(''),
    saturday_time_to: new FormControl(''),
    saturday_hour_to: new FormControl(''),
    saturday_minute_to: new FormControl(''),
    saturday_am_pm_to: new FormControl(''),
    sunday_time_from: new FormControl(''),
    sunday_hour_from: new FormControl(''),
    sunday_minute_from: new FormControl(''),
    sunday_am_pm_from: new FormControl(''),
    sunday_time_to: new FormControl(''),
    sunday_hour_to: new FormControl(''),
    sunday_minute_to: new FormControl(''),
    sunday_am_pm_to: new FormControl(''),
    monday_time_zone: new FormControl(''),
    tuesday_time_zone: new FormControl(''),
    wednesday_time_zone: new FormControl(''),
    thursday_time_zone: new FormControl(''),
    friday_time_zone: new FormControl(''),
    saturday_time_zone: new FormControl(''),
    sunday_time_zone: new FormControl(''),
    day_time_zone: new FormControl(''),
  });

  // Hobby form
  handoverForm = new FormGroup({
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    email: new FormControl(''),
    country_code: new FormControl(''),
    mobile_no: new FormControl(''),
    relation_ship: new FormControl(''),
    first_name_to: new FormControl(''),
    last_name_to: new FormControl(''),
    email_to: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
    country_code_to: new FormControl(''),
    mobile_no_to: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]),
  });

  community: any;
  // partner preferrence
  // partner Basic
  partnerBasicForm = new FormGroup({
    age_from: new FormControl('', Validators.required),
    age_to: new FormControl('', Validators.required),
    height_from: new FormControl('', Validators.required),
    height_to: new FormControl('', Validators.required),
    weight_from: new FormControl(''),
    weight_to: new FormControl(''),
    description: new FormControl('', Validators.required),
    do_smoke: new FormControl(''),
    do_drink: new FormControl(''),
    have_pet: new FormControl(''),
    manglik: new FormControl(''),
    marital_status: new FormControl('', Validators.required),
    food_preference: new FormControl('', Validators.required),
    otherFood: new FormControl(''),
    have_children: new FormControl(''),
    boy_child: new FormControl(''),
    boy_child_not_married: new FormControl(''),
    boy_child_married: new FormControl(''),
    girl_child: new FormControl(''),
    girl_child_not_married: new FormControl(''),
    girl_child_married: new FormControl(''),
    languages: new FormControl('', Validators.required),
    otherLanguage: new FormControl(''),
  });

  // partnerReligion
  partnerReligionForm = new FormGroup({
    religion: new FormControl('', Validators.required),
    otherReligion: new FormControl(''),
    community: new FormControl(''),
    caste: new FormControl(''),
    gothra: new FormControl(''),
  });

  //location form
  partnerLocationForm = new FormGroup({
    country_name: new FormControl(''),
    state_name: new FormControl(''),
    city_name: new FormControl(''),
    residency_status: new FormControl('', Validators.required),
    otherResidencyStatus: new FormControl(''),
  });

  // family details
  partnerFamilyForm = new FormGroup({
    family_living_in_country: new FormControl(''),
    family_state_name: new FormControl(''),
    family_current_city: new FormControl(''),
    father_occupation: new FormControl(''),
    mother_occupation: new FormControl(''),
    family_type: new FormControl(''),
    living_with_family: new FormControl(''),
  });

  // Education form
  partnerEducationForm = new FormGroup({
    education: new FormControl('', Validators.required),
    otherEducation: new FormControl(''),
    annual_income: new FormControl('', Validators.required),
    nature_of_work: new FormControl('', Validators.required),
    otherNatureOfWork: new FormControl(''),
    profession_area: new FormControl(''),
    otherProfession: new FormControl(''),
    income_currency: new FormControl('', Validators.required),
    income_range_from: new FormControl('', Validators.required),
    income_range_to: new FormControl('', Validators.required),
  });

  partnerReligion = [
    { id: '1', name: 'Buddhist' },
    { id: '2', name: 'Christian' },
    { id: '3', name: 'Hindu' },
    { id: '4', name: 'Jain' },
    { id: '5', name: 'Muslim' },
    { id: '6', name: 'Parsi' },
    { id: '7', name: 'Sikh' },
    { id: '8', name: 'No Religion' },
    { id: '9', name: 'Any Religion' },
    { id: '10', name: 'Other' },
  ];

  selectedReligion: any = [];

  partnerFamilyType = [
    { id: '1', name: 'Extended Family' },
    { id: '2', name: 'Immediate Family' },
    { id: '3', name: 'Joint Family' },
    { id: '4', name: 'Nuclear Family' },
    { id: '5', name: "Does Not Matter" },
  ];
  selectedPartnerFamilyType: any = [];

  partnerMaritalStatus = [
    { id: '1', name: 'Annulled' },
    { id: '2', name: 'Awaiting Divorce' },
    { id: '3', name: 'Divorced' },
    { id: '4', name: 'Single' },
    { id: '5', name: 'Widowed' },
    { id: '6', name: 'Any Of These' },
  ];
  selectedMaritalStatus: any = [];

  partnerFoodPreference = [
    { id: '1', name: 'Veg' },
    { id: '2', name: 'Non Veg' },
    { id: '3', name: 'Occasionally Non-Veg' },
    { id: '4', name: 'Eggetarian' },
    { id: '5', name: 'Pescatarian' },
    { id: '6', name: 'Jain' },
    { id: '7', name: 'Vegan' },
    { id: '8', name: 'Other' },
    { id: '9', name: 'Does Not Matter' },
  ];
  selectedFoodPreference: any = [];
  //EducationStatus
  partnerEducation = [
    { id: '1', name: 'High School' },
    { id: '2', name: 'Some College' },
    { id: '3', name: 'Some University' },
    { id: '4', name: 'Associate`s Degree' },
    { id: '5', name: 'Bachelor`s Degree' },
    { id: '6', name: 'Master`s Degree' },
    { id: '7', name: 'Graduate Degree' },
    { id: '8', name: 'PhD/Post Doctorate' },
    { id: '9', name: 'Does Not Matter' },
    { id: '10', name: 'Other' },
  ];

  selectedPartnerEducation: any = [];

  //ResidencyStatus
  partnerResidencyStatus = [
    { id: '1', name: 'Citizen' },
    { id: '2', name: 'Work Permit' },
    { id: '3', name: 'Permanent Resident' },
    { id: '4', name: 'Student Visa' },
    { id: '5', name: 'Visitor Visa' },
    { id: '6', name: 'Other' },
  ];

  selectedPartnerResidencyStatus: any = [];

  // nature of Work

  partnerNatureOfWork = [
    { id: '1', name: 'Business/Self Employed' },
    { id: '2', name: 'Government Company/Public Sector' },
    { id: '3', name: 'Private Company' },
    { id: '4', name: 'Not Working' },
    { id: '5', name: 'Other' },
    { id: '6', name: 'Does Not Matter' },
  ];

  selectedPartnerNatureOfWork: any = [];

  // Profession

  partnerProfession = [
    { id: '1', name: 'Accounting - Banking & Finance' },
    { id: '2', name: 'Administration & HR' },
    { id: '3', name: 'Airline & Aviation' },
    { id: '4', name: 'Agriculture' },
    { id: '5', name: 'Beauty - Fashion & Jewellery Designers' },
    { id: '6', name: 'Sales & Marketing' },
    { id: '7', name: 'Merchant Navy' },
    { id: '8', name: 'Does Not Matter' },
    { id: '9', name: 'Other' },
  ];

  selectedPartnerProfession: any = [];

  selectedTime: any = [];


  timeZoneList =
    [{ id: "Etc/GMT+12 ", value: " (GMT-12:00) International Date Line West" },
    { id: "Pacific/Midway ", value: " (GMT-11:00) Midway Island_ Samoa" },
    { id: "Pacific/Honolulu ", value: " (GMT-10:00) Hawaii" },
    { id: "US/Alaska ", value: " (GMT-09:00) Alaska" },
    { id: "America/Los_Angeles ", value: " (GMT-08:00) Pacific Time (US & Canada)" },
    { id: "US/Arizona ", value: " (GMT-07:00) Arizona" },
    { id: "America/Managua ", value: " (GMT-06:00) Central America" },
    { id: "US/Central ", value: " (GMT-06:00) Central Time (US & Canada)" },
    { id: "America/Bogota ", value: " (GMT-05:00) Bogota_ Lima_ Quito_ Rio Branco" },
    { id: "US/Eastern ", value: " (GMT-05:00) Eastern Time (US & Canada)" },
    { id: "Canada/Atlantic ", value: " (GMT-04:00) Atlantic Time (Canada)" },
    { id: "America/Argentina/Buenos_Aires ", value: " (GMT-03:00) Buenos Aires_ Georgetown" },
    { id: "America/Noronha ", value: " (GMT-02:00) Mid-Atlantic" },
    { id: "Atlantic/Azores ", value: " (GMT-01:00) Azores" },
    { id: "Etc/Greenwich ", value: " (GMT+00:00) Greenwich Mean Time_ Dublin_ Edinburgh_ Lisbon_ London" },
    { id: "Europe/Amsterdam ", value: " (GMT+01:00) Amsterdam_ Berlin_ Bern_ Rome_ Stockholm_ Vienna" },
    { id: "Europe/Helsinki ", value: " (GMT+02:00) Helsinki_ Kyiv_ Riga_ Sofia_ Tallinn_ Vilnius" },
    { id: "Europe/Moscow ", value: " (GMT+03:00) Moscow_ St. Petersburg_ Volgograd" },
    { id: "Asia/Tehran ", value: " (GMT+03:30) Tehran" },
    { id: "Asia/Yerevan ", value: " (GMT+04:00) Yerevan" },
    { id: "Asia/Kabul ", value: " (GMT+04:30) Kabul" },
    { id: "Asia/Yekaterinburg ", value: " (GMT+05:00) Yekaterinburg" },
    { id: "Asia/Karachi ", value: " (GMT+05:00) Islamabad_ Karachi, Tashkent" },
    { id: "Asia/Calcutta ", value: " (GMT+05:30) Chennai_ Kolkata_ Mumbai_ New Delhi" },
    { id: "Asia/Katmandu ", value: " (GMT+05:45) Kathmandu" },
    { id: "Asia/Dhaka ", value: " (GMT+06:00) Astana_ Dhaka" },
    { id: "Asia/Rangoon ", value: " (GMT+06:30) Yangon (Rangoon)" },
    { id: "Asia/Bangkok ", value: " (GMT+07:00) Bangkok_ Hanoi_ Jakarta" },
    { id: "Asia/Hong_Kong ", value: " (GMT+08:00) Beijing_ Chongqing_ Hong Kong_ Urumqi" },
    { id: "Asia/Seoul ", value: " (GMT+09:00) Seoul" },
    { id: "Australia/Adelaide ", value: " (GMT+09:30) Adelaide" },
    { id: "Australia/Canberra ", value: " (GMT+10:00) Canberra_ Melbourne_ Sydney" },
    { id: "Asia/Magadan ", value: " (GMT+11:00) Magadan_ Solomon Is._ New Caledonia" },
    { id: "Pacific/Auckland ", value: " (GMT+12:00) Auckland, Wellington" },
    { id: "Pacific/Tongatapu ", value: " (GMT+13:00) Nuku'alofa" }
    ]
  mondayTimeFrom: any;
  mondayTimeTo: any;
  tuesdayTimeFrom: any;
  tuesdayTimeTo: any;
  wednesdayTimeFrom: any;
  wednesdayTimeTo: any;
  thursdayTimeFrom: any;
  thursdayTimeTo: any;
  fridayTimeFrom: any;
  fridayTimeTo: any;
  saturdayTimeFrom: any;
  saturdayTimeTo: any;
  sundayTimeFrom: any;
  sundayTimeTo: any;

  timeIntervals30 = [
    { id: '1', value: '00:00 AM' },
    { id: '2', value: '00:30 AM' },
    { id: '3', value: '01:00 AM' },
    { id: '4', value: '01:30 AM' },
    { id: '5', value: '02:00 AM' },
    { id: '6', value: '02:30 AM' },
    { id: '7', value: '03:00 AM' },
    { id: '8', value: '03:30 AM' },
    { id: '9', value: '04:00 AM' },
    { id: '10', value: '04:30 AM' },
    { id: '11', value: '05:00 AM' },
    { id: '12', value: '05:30 AM' },
    { id: '13', value: '06:00 AM' },
    { id: '14', value: '06:30 AM' },
    { id: '15', value: '07:00 AM' },
    { id: '16', value: '07:30 AM' },
    { id: '17', value: '08:00 AM' },
    { id: '18', value: '08:30 AM' },
    { id: '19', value: '09:00 AM' },
    { id: '20', value: '09:30 AM' },
    { id: '21', value: '10:00 AM' },
    { id: '22', value: '10:30 AM' },
    { id: '23', value: '11:00 AM' },
    { id: '24', value: '11:30 AM' },
    { id: '25', value: '12:00 PM' },
    { id: '26', value: '12:30 PM' },
    { id: '27', value: '01:00 PM' },
    { id: '28', value: '01:30 PM' },
    { id: '29', value: '02:00 PM' },
    { id: '30', value: '02:30 PM' },
    { id: '31', value: '03:00 PM' },
    { id: '32', value: '03:30 PM' },
    { id: '33', value: '04:00 PM' },
    { id: '34', value: '04:30 PM' },
    { id: '35', value: '05:00 PM' },
    { id: '36', value: '05:30 PM' },
    { id: '37', value: '06:00 PM' },
    { id: '38', value: '06:30 PM' },
    { id: '39', value: '07:00 PM' },
    { id: '40', value: '07:30 PM' },
    { id: '41', value: '08:00 PM' },
    { id: '42', value: '08:30 PM' },
    { id: '43', value: '09:00 PM' },
    { id: '44', value: '09:30 PM' },
    { id: '45', value: '10:00 PM' },
    { id: '46', value: '10:30 PM' },
    { id: '47', value: '11:00 PM' },
    { id: '48', value: '11:30 PM' },
  ];
  selectedTimeZoneDay: any;
  selectedTimeMonday: any;
  selectedTimeTuesday: any;
  selectedTimeWednesday: any;
  selectedTimeThursday: any;
  selectedTimeFriday: any;
  selectedTimeSaturday: any;
  selectedTimeSunday: any;

  // time from 

  selectedTimeMondayFrom: any;
  selectedTimeTuesdayFrom: any;
  selectedTimeWednesdayFrom: any;
  selectedTimeThursdayFrom: any;
  selectedTimeFridayFrom: any;
  selectedTimeSundayFrom: any;
  selectedTimeSaturdayFrom: any;
  // time to 
  selectedTimeMondayTo: any;
  selectedTimeTuesdayTo: any;
  selectedTimeWednesdayTo: any;
  selectedTimeThursdayTo: any;
  selectedTimeFridayTo: any;
  selectedTimeSundayTo: any;
  selectedTimeSaturdayTo: any;

  countries: any = [];
  countries5: any = [];
  countries1: any = [];

  states: any;
  aboutstates: any;
  locationstates: any;
  partnerLocationStates: any = [];
  phoneCode: any;
  partnerCurrencyCode: any = 38;
  countryOfBirth: any;
  countryOfBirthChange: any;
  currencyCode: any;
  familyPhoneCode: any;
  aboutCountryName: any;
  birthCountryName: any;

  grewUpInCountryName: any;
  aboutStateName: any;
  locationCountryName: any;
  locationStateName: any;
  partnerLocationCountryName: any;
  partnerLocationStateName: any;
  headingCountryName: any;
  headingStateName: any;
  calculatedAge: number;
  aboutYouLanguageOther: any;
  isAboutYouLanguageOther: boolean = false;
  partnerBasicLanguageOther: any;
  isPartnerBasicLanguageOther: boolean = false;
  religionSubmitted: boolean = false;
  astroSubmitted: boolean = false;
  astroButton: boolean = false;

  hobbySubmitted: boolean = false;
  isReligionOther: boolean = false;
  isPartnerReligionOther: boolean = false;
  partnerOtherReligion: boolean = false;
  partnerOtherResidencyStatus: boolean = false;
  partnerOtherEducation: boolean = false;
  partnerOtherNatureOfWork: boolean = false;
  partnerNatureOfWorkRequired: boolean = false;
  partnerOtherProfession: boolean = false;
  partnerProfessionRequired: boolean = false;
  partnerEducationRequired: boolean = false;
  partnerOtherFood: boolean = false;
  partnerFoodRequired: boolean = false;

  displayFirstName: any;
  displayLastName: any;
  displayUniqueKey: any;

  // Basics and Lifestyle Form
  basicAndLifestyleForm = new FormGroup({
    dob: new FormControl('', Validators.required),
    month: new FormControl('', Validators.required),
    year: new FormControl('', Validators.required),
    day: new FormControl('', Validators.required),
    food_preference: new FormControl('', Validators.required),
    basicOtherFood: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]),
    grew_up_in: new FormControl(''),
    personal_values: new FormControl('', Validators.maxLength(25)),
    do_smoke: new FormControl(''),
    do_drink: new FormControl(''),
    have_pet: new FormControl(''),
    sun_sign: new FormControl(''),
    blood_group: new FormControl(''),
    health_info: new FormControl('', Validators.maxLength(25)),
    disability: new FormControl('', Validators.maxLength(25)),
  });

  basicAndLifestyleSubmitted: boolean = false;
  grewUpInCountry: any;
  maritalStatus: boolean;
  showMaritalStatus: string;
  showHeight: any;
  familyDetailForm = new FormGroup({
    father_status: new FormControl('', [Validators.minLength(1), Validators.maxLength(25)]),
    mother_status: new FormControl('', [Validators.minLength(1), Validators.maxLength(25)]),
    no_of_sister: new FormControl(''),
    no_of_brother: new FormControl(''),
    family_living_in_country: new FormControl(''),
    family_zip_code: new FormControl('', [Validators.minLength(1), Validators.maxLength(15)]),
    family_state_name: new FormControl(''),
    family_current_city: new FormControl('', [Validators.minLength(3), Validators.maxLength(25)]),
    family_type: new FormControl(''),
    family_values: new FormControl('', [Validators.minLength(1), Validators.maxLength(25)]),
    native_place: new FormControl('', [Validators.minLength(1), Validators.maxLength(25)]),
    family_affluence: new FormControl(''),
    living_with_family: new FormControl('', Validators.required),
  });
  familyStateName: any;
  countryNumber: any;
  countryNumberTo: any;
  country_codeHandover: any;

  familyCountryName: any;
  CurrencyCountryName: any = '';
  PartnerCurrencyCountryName: any;
  isResidencyOther: boolean = false;

  familyStates: any;
  familySubmitted: boolean = false;
  familyButton: boolean = false;
  partnerFamilyStateName: any;
  partnerFamilyCountryName: any;
  partnerFamilyStates: any;
  partnerFamilySubmitted: boolean = false;
  partnerBasicShowChildren: boolean = false;
  aboutYouShowChildren: boolean = false;
  partnerIncomeRange: boolean = false;
  incomeRange: boolean = false;
  basicAndLifestyleButton: boolean = false;
  religionButton: boolean = false;
  educationButton: boolean = false;
  hobbyButton: boolean = false;
  contactButton: boolean = false;
  contactButtonS: boolean = false;
  handOverButton: boolean = false;
  partnerBasicButton: boolean;
  partnerReligionButton: boolean;
  partnerEducationButton: boolean;
  partnerFamilyButton: boolean;
  partnerLocationButton: boolean;
  havePicture: boolean = false;
  haveCover: boolean = false;
  profileSRC: any;
  gender: string;
  coverSRC: any = 'assets/images/banner-4.jpg';
  aboutYouButton: boolean = false;
  fileUpload: any;
  fileUploadCount: any;
  pasteEvnetStatus: boolean;
  is_uploaded = false;
  totalMediaLength: any;
  totalMediaSize: any;
  options = {
    timeOut: 0,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    // clickIconToClose: true,
    preventDuplicates: true,
  };
  partnerLocationPhoneCode: number = 0;
  GrewUpPhoneCode: any;
  contactPhoneCode: any;
  partnerFamilyPhoneCode: any;
  createdBy: string;
  firstName: string;
  lastName: string;
  isProfessionOther: boolean = false;
  isNatureOfWorkOther: boolean;
  aboutYouHaveChildren: boolean = false;
  aboutYouBoyChild: boolean = false;
  aboutYouGirlChild: boolean = false;
  partnerOtherLanguage: boolean;

  showTime: any = [];
  //
  timeZone: any = [];
  // image code
  imageUploadForm = new FormGroup({
    image: new FormControl(''),
  });

  //
  cimg: Event | any;
  imageOriginalName: string;
  imgSelected: boolean = false;
  Uploaded: boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = 'assets/images/men-avtar-2.svg';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  // image code
  coverUploadForm = new FormGroup({
    image: new FormControl(''),
  });

  //
  covercimg: Event | any;
  coverImageOriginalName: string;
  coverImgSelected: boolean = false;
  coverUploaded: boolean = false;
  coverImageChangedEvent: any = '';
  coverCroppedImage: any = 'assets/images/default_cover_photo.png';

  isBasicFoodOther: boolean = false;
  isTrue: boolean = true;

  // family type
  FamilyTypePartner: boolean = false;
  showFamilyType: any;
  showResidency: any;
  showNatureOfWork: any;
  showProfession: any;
  showEducation: any;
  showReligion: any;
  showLanguage: any;
  showFood: any;
  showMarital: any;
  editId: string | null;
  editId2: string | null;

  createdByMobilCode: string;
  createdByEmail: string;
  createdByMobile: string;

  wordCount: any = 0;
  words: any;
  aboutPartner: any;
  moreAboutMe: any;
  wordCount1: any;
  words1: any;
  wordCount2: any;
  words2: any;
  aboutPartner2: any;
  moreAboutMe2: any;
  ispartnerDetailFetched: boolean = false;
  exceedLimit: boolean = false;
  exceedLimit1: boolean = false;
  FamilyTypeSelf: boolean = false;
  EnableEditSunday: boolean = false;
  EnableEditMonday: boolean = false;
  EnableEditTuesday: boolean = false;
  EnableEditWednesday: boolean = false;
  EnableEditThursday: boolean = false;
  EnableEditFriday: boolean = false;
  EnableEditSaturday: boolean = false;
  countryRequired: boolean = false;
  partnerReligionOtherValue: boolean = false;
  partnerLanguageOtherValue: boolean = false;
  partnerFoodOtherValue: boolean = false;
  partnerEducationOtherValue: boolean = false;
  partnerNatureOfWorkOtherValue: boolean = false;
  partnerProfessionOtherValue: boolean = false;
  partnerResidencyStatusOtherValue: boolean = false;


  inCompleteUserField: any = [];
  inCompletePartnerField: any = [];
  showAnyTime: boolean = false;
  memberPackageId: any;
  notClickable: boolean = true;

  userPlanForm = new FormGroup({
    user_id: new FormControl(0),
    visible_to_all: new FormControl(false),
    visible_to_premium: new FormControl(false),
    visible_to_premium_elite: new FormControl(false),
    visible_to_elite: new FormControl(false),
    visible_to_no_one: new FormControl(false),
  })
  ProfileVisible: any;

  constructor(
    private authService: AuthService,
    private auth: AuthStateService,
    public token: TokenService,
    private _formBuilder: FormBuilder,
    private router: Router,
    private registerService: RegisterService,
    private cookieService: CookieService,
    private partnerPreferenceService: PartnerPreferenceService,
    private myProfileService: MyProfileService,
    private http: HttpClient,
    private notifService: NotifService,
    private titleService: Title,
    private myprofileService: MyProfileService,
    private registerNxtservice: RegisterNxtService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.year = new Date().getFullYear();
    this.years = [];
    this.astroyears = [];
    for (let i = this.maxYear; this.minYear <= i; i--) {
      this.years.push({ id: i, name: i });
      this.astroyears.push({ id: i, name: i });
    }
    this.sundayTimeFrom = [];
    this.sundayTimeTo = [];

    this.mondayTimeFrom = [];
    this.mondayTimeTo = [];

    this.tuesdayTimeFrom = [];
    this.tuesdayTimeTo = [];

    this.wednesdayTimeFrom = [];
    this.wednesdayTimeTo = [];

    this.thursdayTimeFrom = [];
    this.thursdayTimeTo = [];

    this.fridayTimeFrom = [];
    this.fridayTimeTo = [];

    this.saturdayTimeFrom = [];
    this.saturdayTimeTo = [];
  }

  ngOnInit(): void {
    this.titleService.setTitle('My Profile | eternalstrings.com');
    this.user_id = parseInt(this.cookieService.get('user_id'));
    this.setInitialRange();
    if (this.user_id) {
      this.getUser();
      this.getCountryList();
      this.getProfileVisibility();
    }
    this.checkpackage();
    this.inCompleteFields();
    this.scrollToTop();
  }


  ngOnDestroy() {
    console.log("hello route change ")
  }
  unhide() {
    this.router.navigate(['setting']);
    localStorage.setItem("hideProfile", 'hideProfile');
  }
  private scrollToTop() {
    setTimeout(() => {
      // Using window scroll for added compatibility
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // You can use 'auto' or 'smooth' for smooth scrolling
      });

      // For older browsers that do not support window.scrollTo with options
      document.documentElement.scrollTop = 0;
    }, 0);
  }
  // ngAfterViewInit() {
  //     scrollTo(0, 0);
  //     setTimeout(() => {
  //       scrollTo(0, 0);
  //     }, 400);
  //   }

  getProfileVisibility() {
    let data = {
      user_id: this.user_id
    }
    this.myprofileService.getProfileVisibility(data).subscribe((result) => {
      if (result?.status) {
        this.ProfileVisible = result.userProfileVisibility;
        this.userPlanForm.patchValue({
          user_id: this.user_id,
           // before sonar 
          // visible_to_all: this.ProfileVisible.visible_to_all == 1 ? true : false,
          // visible_to_premium_elite: this.ProfileVisible.visible_to_premium_elite == 1 ? true : false,
          // visible_to_elite: this.ProfileVisible.visible_to_elite == 1 ? true : false,
          // visible_to_no_one: this.ProfileVisible.visible_to_no_one == 1 ? true : false,

           //after sonar
          visible_to_all: this.ProfileVisible.visible_to_all == 1,
          visible_to_premium_elite: this.ProfileVisible.visible_to_premium_elite == 1,
          visible_to_elite: this.ProfileVisible.visible_to_elite == 1,
          visible_to_no_one: this.ProfileVisible.visible_to_no_one == 1,
        });
      } else {
        // unused import
        //this.visibletonoone=false;
        // $('#visible_to_no_one').prop('checked', false);
      }
      console.log('on load form access', this.userPlanForm.value)
    },
      (error) => {
        this.toastrService.error('Error!', 'Visible To Members Not working? ');
      },
      () => {

      });
  }

  memberShip(option: any) {
    console.log('memberShip userPlanForm: ', this.userPlanForm)
    this.userPlanForm.patchValue({
      visible_to_all: false,
      visible_to_premium_elite: false,
      visible_to_elite: false,
      visible_to_no_one: false,
    });
    this.userPlanForm.controls[option].setValue(true);
    this.userPlanForm.controls.user_id.setValue(this.user_id)
    let data = this.userPlanForm.value;
    this.myProfileService.updateProfileVisibility(data).subscribe((result) => {
      if (result.status) {
        this.toastrService.success(result.message);
      } else {
        this.toastrService.error(result.message);
      }
    },
      (error) => {
        // unused code
        // this.toastrService.error('error!', 'visibleToMembers Not work !');
      },
      () => {

      });


  }

  getCountryList() {
    let countries = this.commonService.getCountries();
    if (this.countries.length > 0) {
      let country = countries;
      this.countries5 = countries;
      this.countries1 = countries;
      this.countries = countries;
      this.countries1.push({
        "fullnumber": "Any",
        "id": 0,
        "country_code": "",
        "country_name": "Any",
        "short_name": "ANY",
        "currency": "",
        "currency_name": ""
      })
      this.getUserDetails();
      this.getPartnerDetail();
      this.countries = country.filter(item => item.id !== 0);
    } else {
      this.commonService.getCountryList({}).subscribe((result) => {
        let country = result.data;
        this.countries5 = result.data;
        this.countries1 = result.data;
        this.countries1.push({
          "fullnumber": "Any",
          "id": 0,
          "country_code": "",
          "country_name": "Any",
          "short_name": "ANY",
          "currency": "",
          "currency_name": ""
        })
        this.getUserDetails();
        this.getPartnerDetail();
        this.countries = country.filter(item => item.id !== 0);
      }, (error: { error: any; }) => {
        console.log("error in get country")
      });
    }
  }

  nsBindingErrorRecall() {
    console.log("error in firefox")
    setTimeout(() => {
      this.getUser();
      this.getCountryList();
    }, 5000);
  }

  getUser() {
    let data = {
      user_id: this.user_id,
    };
    this.registerService.getUser(data).subscribe((res: any) => {
      // before sonar
      // if (res && res.userDetails && res.userDetails.firstName && res.userDetails.lastName) {
      if (res?.userDetails?.firstName && res.userDetails.lastName) {
        this.firstName = res.userDetails.firstName;
        this.lastName = res.userDetails.lastName;
        this.createdBy = res.userDetails.firstName + ' ' + res.userDetails.lastName;
        this.createdByMobilCode = res.userDetails.countryNumberCode;
        this.createdByMobile = res.userDetails.mobileNo;
        this.createdByEmail = res.userDetails.email;
      }

      if (res) {
        this.creatorDetails = res.userDetails;
      }
      // console.log(this.createdBy, this.createdByEmail,this.createdByMobile,"contact form")

      // handover form

      if (this.creatorDetails.firstName != '') {
        this.handOverF.first_name.setValue(this.creatorDetails.firstName);
        // console.log(this.details.last_name," s1")
      }

      if (this.creatorDetails.lastName != '') {
        this.handOverF.last_name.setValue(this.creatorDetails.lastName);
      }
      if (this.creatorDetails.email != '') {
        this.handOverF.email.setValue(this.creatorDetails.email);
      }
      if (this.creatorDetails.mobileNo != '') {
        this.handOverF.mobile_no.setValue(this.creatorDetails.countryplusnumber);
      }


      if (this.creatorDetails.firstName != '') {
        this.contactF.firstName.setValue(this.creatorDetails.firstName);
        this.contactF.contact_person.setValue(this.createdBy);
      }

      if (this.creatorDetails.lastName != '') {
        this.contactF.lastName.setValue(this.creatorDetails.lastName);

      }

    }, (error: { error: any; }) => {
      console.log("error in get user")
    });
  }
  setInitialRange() {
    for (let i = 32; i <= 114; i++) {
      this.weight.push({ i });
    }

    for (let i = 100; i <= 230; i++) {
      this.height.push({ i });
    }

    for (let i = 18; i <= 90; i++) {
      this.age_select.push({ i });
    }

    for (let i = 18; i <= 90; i++) {
      this.age_select.push({ i });
    }

    for (let i = 1; i <= 12; i++) {
      this.hour.push({ i });
    }
    for (let i = 10; i <= 59; i++) {
      this.minute.push({ i });
    }
    for (let i = 0o0; i <= 59; i++) {
      this.second.push({ i });
    }
    for (let i = 18; i <= 90; i++) {
      this.age_from.push({ id: i });
    }
    for (let i = 100; i <= 230; i++) {
      this.height_from.push({ i });
    }
    for (let i = 32; i <= 114; i++) {
      this.weight_from.push({ i });
    }
  }

  loader() {
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
  }

  get aboutYouF() {
    return this.aboutYouForm.controls;
  }
  get religionF() {
    return this.religionForm.controls;
  }
  get astroF() {
    return this.astroForm.controls;
  }

  get basicAndLifestyleF() {
    return this.basicAndLifestyleForm.controls;
  }
  get educationF() {
    return this.educationForm.controls;
  }
  get locationF() {
    return this.locationForm.controls;
  }


  get familyDetailF() {
    return this.familyDetailForm.controls;
  }

  get hobbyF() {
    return this.hobbyForm.controls;
  }
  get contactF() {
    return this.contactForm.controls;
  }

  get handOverF() {
    return this.handoverForm.controls;
  }
  //partner forms
  get partnerReligionF() {
    return this.partnerReligionForm.controls;
  }
  get partnerLocationF() {
    return this.partnerLocationForm.controls;
  }
  get partnerFamilyF() {
    return this.partnerFamilyForm.controls;
  }
  get partnerEducationF() {
    return this.partnerEducationForm.controls;
  }
  get partnerBasicF() {
    return this.partnerBasicForm.controls;
  }

  getUserDetails() {
    let data = {
      user_id: this.user_id,
    };
    this.registerService.getUserDetails(data).subscribe((user_details: any) => {
      this.details = user_details.userDetails;
      let register_id = this.details.register_done;
      localStorage.setItem('register_id', register_id);
      this.commonService.setRegisterDoneStep(register_id);
      this.checkpackage();

      if (register_id == 0) {
        this.router.navigate(['register']);
      }
      if (register_id == '') {
        this.router.navigate(['register']);
      } else if (register_id == 1) {
        this.router.navigate(['registerNxt']);
      } else if (register_id == 2) {
        this.router.navigate(['step']);
      } else if (register_id == 3) {
        this.router.navigate(['stepTwo']);
      } else if (register_id == 4) {
        this.router.navigate(['stepDone']);
      } else if (register_id == 5) {
        this.router.navigate(['ProfilePage1']);
      } else if (register_id == 6) {
        this.router.navigate(['PartnerPreferences']);
      } else {
        this.router.navigate(['myInfo']);
      }
      // && this.details.profile_photo_approved == 1
      if (this.details.profile_photo) {
        this.havePicture = true;
        this.croppedImage = this.details.profile_photo;
      } else {
        this.havePicture = false;
      }
      // && this.details.cover_photo_approved == 1
      if (this.details.cover_photo) {
        this.haveCover = true;
        this.coverSRC = this.details.cover_photo;
      } else {
        this.haveCover = false;
      }

      if (this.details.gender == 'Male') {
        this.gender = 'Male';
      } else {
        this.gender = 'Female';
      }

      if (
        this.details.last_name != '' &&
        this.details.first_name != '' &&
        this.details.unique_key != ''
      ) {
        this.displayFirstName =
          this.details.first_name + ' ' + this.details.last_name[0];
        this.displayLastName =
          this.details.last_name + ' ' + this.details.first_name[0];
        this.displayUniqueKey = this.details.unique_key;
      }
      if (this.details.dob != '') {
        const date = moment(this.details.dob, 'YYYY-MM-DD');
        const years = moment().diff(date, 'years');
        this.calculatedAge = years;
        this.age = years;
      }

      if (
        !(this.details.display_name == '' || this.details.display_name == null)
      ) {
        this.displayName = this.details.display_name;
        this.aboutYouF.display_name.setValue(this.details.display_name);
      } else {
        this.displayName = this.details.unique_key;
        this.aboutYouF.display_name.setValue(this.details.unique_key);
      }

      if (this.details.first_name != '') {
        this.aboutYouF.first_name.setValue(this.details.first_name);
      }

      if (this.details.last_name != '') {
        this.aboutYouF.last_name.setValue(this.details.last_name);
      }

      if (!(this.details.age == '' || this.details.age == null)) {
        this.aboutYouF.age.setValue(this.details.age);
      } else {
        this.aboutYouF.age.setValue(this.getAge(this.details.dob));
      }

      if (this.details.height != '') {
        this.aboutYouF.height.setValue(this.details.height);
      }
      if (this.details.weight != '') {
        this.aboutYouF.weight.setValue(this.details.weight);
      }

      if (this.details.marital_status != '') {
        if (
          this.details.marital_status == 'Single' ||
          this.details.marital_status == 'Annulled'
        ) {
          this.aboutYouShowChildren = false;
        } else {
          this.aboutYouShowChildren = true;
          if (this.details.have_children != '') {
            if (this.details.have_children == 'Yes') {
              this.aboutYouHaveChildren = true;
              if (
                this.details.boy_child != '' &&
                this.details.boy_child != null
              ) {
                if (
                  this.details.boy_child == 'Yes' ||
                  this.details.boy_child == true
                ) {
                  this.aboutYouBoyChild = true;
                  this.details.boy_child = 'Yes';

                  if (
                    this.details.boy_child_married != '' &&
                    this.details.boy_child_married != null
                  ) {
                    this.aboutYouF.boy_child_married.setValue(
                      this.details.boy_child_married
                    );
                  }
                  if (
                    this.details.boy_child_not_married != '' &&
                    this.details.boy_child_not_married != null
                  ) {
                    this.aboutYouF.boy_child_not_married.setValue(
                      this.details.boy_child_not_married
                    );
                  }
                } else {
                  this.details.boy_child = 'No';
                  this.aboutYouBoyChild = false;
                }
                this.aboutYouF.boy_child.setValue(this.details.boy_child);
              }
              if (
                this.details.girl_child != '' &&
                this.details.girl_child != null
              ) {
                if (
                  this.details.girl_child == 'Yes' ||
                  this.details.girl_child == true
                ) {
                  this.aboutYouGirlChild = true;
                  this.details.girl_child = 'Yes';
                  if (
                    this.details.girl_child_married != '' &&
                    this.details.girl_child_married != null
                  ) {
                    this.aboutYouF.girl_child_married.setValue(
                      this.details.girl_child_married
                    );
                  }
                  if (
                    this.details.girl_child_not_married != '' &&
                    this.details.girl_child_not_married != null
                  ) {
                    this.aboutYouF.girl_child_not_married.setValue(
                      this.details.girl_child_not_married
                    );
                  }
                } else {
                  this.details.girl_child = 'No';
                  this.aboutYouGirlChild = false;
                }
                // console.log(this.details.girl_child," s1")
                this.aboutYouF.girl_child.setValue(this.details.girl_child);
              } else {
                this.aboutYouGirlChild = false;
              }
            } else {
              this.aboutYouHaveChildren = false;
            }
            this.aboutYouF.have_children.setValue(this.details.have_children);
          }
        }
        this.aboutYouF.marital_status.setValue(this.details.marital_status);
      }

      if (this.details.description != '') {
        this.aboutYouF.description.setValue(this.details.description);
        this.words1 = this.details.description.split(/\s+/).length;
      }

      if (this.details.languages != '') {
        if (
          this.details.languages == 'Hindi' ||
          this.details.languages == 'English' ||
          this.details.languages == 'Bengali' ||
          this.details.languages == 'Telugu' ||
          this.details.languages == 'Tamil' ||
          this.details.languages == 'Marathi' ||
          this.details.languages == 'Gujarati' ||
          this.details.languages == 'Punjabi'
        ) {
          this.aboutYouF.languages.setValue(this.details.languages);
        } else {
          this.aboutYouF.languages.setValue('other');
          this.aboutYouF.otherLanguage.setValue(this.details.languages);
        }
      }

      // Basics And Lifestyle Form Fields
      if (this.details.dob != '') {
        let basicSplitted = this.details.dob.split('-', 3);
        this.basicAndLifestyleF.year.setValue(basicSplitted[0]);
        this.basicAndLifestyleF.month.setValue(
          Number(basicSplitted[1]).toString()
        );
        this.onChangeBasicYear();
        this.basicAndLifestyleF.day.setValue(basicSplitted[2]);
        // // console.log(this.basicAndLifestyleF.day.value)
      }

      if (this.details.food_preference != '') {
        if (this.details.food_preference == 'Veg') {
          this.basicAndLifestyleF.food_preference.setValue(
            this.details.food_preference
          );
        } else if (this.details.food_preference == 'Non Veg') {
          this.basicAndLifestyleF.food_preference.setValue(this.details.food_preference);
        } else if (this.details.food_preference == 'Occasionally Non-Veg') {
          this.basicAndLifestyleF.food_preference.setValue(this.details.food_preference);
        } else if (this.details.food_preference == 'Eggetarian') {
          this.basicAndLifestyleF.food_preference.setValue(this.details.food_preference);
        } else if (this.details.food_preference == 'Pescatarian') {
          this.basicAndLifestyleF.food_preference.setValue(this.details.food_preference);
        }

        // before sonar uncoment
        // else if (this.details.food_preference == 'Pescatarian') {
        //   this.basicAndLifestyleF.food_preference.setValue(this.details.food_preference);
        // } 

        else if (this.details.food_preference == 'Jain') {
          this.basicAndLifestyleF.food_preference.setValue(this.details.food_preference);
        } else if (this.details.food_preference == 'Vegan') {
          this.basicAndLifestyleF.food_preference.setValue(this.details.food_preference);
        } else {
          this.basicAndLifestyleF.food_preference.setValue('Other');
          this.isBasicFoodOther = true;
          this.basicAndLifestyleF.basicOtherFood.setValue(this.details.food_preference);
        }
      }

      if (this.details.grew_up_in != '') {
        this.countries.forEach((country) => {
          if (this.details.grew_up_in == country.id) {
            this.grewUpInCountry = country.country_name;
            this.grewUpInCountryName = country.country_name;
            this.GrewUpPhoneCode = country.id
          }
        });
        this.basicAndLifestyleF.grew_up_in.setValue(this.details.grew_up_in);
      }

      if (this.details.personal_values != '') {
        this.basicAndLifestyleF.personal_values.setValue(
          this.details.personal_values
        );
      }

      if (this.details.do_smoke != '') {
        this.basicAndLifestyleF.do_smoke.setValue(this.details.do_smoke);
      }

      if (this.details.do_drink != '') {
        this.basicAndLifestyleF.do_drink.setValue(this.details.do_drink);
      }

      if (this.details.have_pet != '') {
        this.basicAndLifestyleF.have_pet.setValue(this.details.have_pet);
      }

      if (this.details.sun_sign != '') {
        this.basicAndLifestyleF.sun_sign.setValue(this.details.sun_sign);
      }

      if (this.details.blood_group != '') {
        this.basicAndLifestyleF.blood_group.setValue(this.details.blood_group);
      }

      if (this.details.health_info != '') {
        this.basicAndLifestyleF.health_info.setValue(this.details.health_info);
      }

      if (this.details.disability != '') {
        this.basicAndLifestyleF.disability.setValue(this.details.disability);
      }
      // Astro fields
      if (this.details.dob != '') {
        let astroSplitted = this.details.dob.split('-', 3);
        this.astroF.year.setValue(astroSplitted[0]);
        this.astroF.month.setValue(Number(astroSplitted[1]).toString());
        this.onChangeAstroYear();
        this.astroF.day.setValue(astroSplitted[2]);
      }
      if (
        this.details.time_of_birth != '' &&
        this.details.time_of_birth != null
      ) {
        let astroSplittedTime = this.details.time_of_birth.split(':', 3);
        this.astroF.hour.setValue(astroSplittedTime[0]);
        let setAmPm = astroSplittedTime[1].split(' ', 3);
        this.astroF.minute.setValue(setAmPm[0]);
        this.astroF.am_pm.setValue(setAmPm[1]);
      }

      if (this.details.city_of_birth != '') {
        this.astroF.city_of_birth.setValue(this.details.city_of_birth);
      }
      if (this.details.manglik != '') {
        this.astroF.manglik.setValue(this.details.manglik);

        if (this.details.country_of_birth != '' && this.details.country_of_birth != null) {
          this.countries.forEach((country) => {
            if (this.details.country_of_birth == country.id) {
              this.birthCountryName = country.country_name;
            }
          });
          this.countryOfBirth = parseInt(this.details.country_of_birth);

          this.astroF.country_of_birth.setValue(this.details.country_of_birth);
        } else {
          this.countryOfBirth = null;
          this.astroF.country_of_birth.setValue("");
        }
      }
      // family details
      if (this.details.father_status != '') {
        this.familyDetailF.father_status.setValue(this.details.father_status);
      }

      if (this.details.mother_status != '') {
        this.familyDetailF.mother_status.setValue(this.details.mother_status);
      }

      if (this.details.no_of_sister != '') {
        this.familyDetailF.no_of_sister.setValue(this.details.no_of_sister);
      }

      if (this.details.no_of_brother != '') {
        this.familyDetailF.no_of_brother.setValue(this.details.no_of_brother);
      }

      if (this.details.family_living_in_country != '' && this.details.family_living_in_country != null) {
        this.familyDetailF.family_living_in_country.setValue(
          this.details.family_living_in_country
        );
        this.countries.forEach((country) => {
          if (this.details.family_living_in_country == country.id) {
            this.familyCountryName = country.country_name;
          }
        });
        this.familyPhoneCode = parseInt(this.details.family_living_in_country);
        this.authService
          .getStateList({ id: this.details.family_living_in_country })
          .subscribe((result) => {
            this.familyStates = result.data;
            this.familyStates.forEach((state) => {
              if (this.details.family_state_name == state.id) {
                this.familyStateName = state.state_name;
                // // console.log(this.familyStateName," s1")
              }
            });
            if (this.details.family_state_name != '') {
              this.familyDetailF.family_state_name.setValue(
                this.details.family_state_name
              );
            }
          });
      } else {
        //  console.log("details" ,this.familyPhoneCode ,this.details.family_living_in_country )
        this.familyPhoneCode = null;
        this.familyDetailF.family_living_in_country.setValue(null);
      }

      if (this.details.family_zip_code != '') {
        this.familyDetailF.family_zip_code.setValue(
          this.details.family_zip_code
        );
      }

      if (this.details.family_current_city != '') {
        this.familyDetailF.family_current_city.setValue(
          this.details.family_current_city
        );
      }
      if (this.details?.living_with_family) {
        if (this.details.living_with_family == "Yes") {
          this.FamilyTypeSelf = true;
          this.familyDetailF.living_with_family.setValue(this.details.living_with_family);
          if (this.details.family_type != '') {
            if (this.details.family_type == 'Extended Family') {
              this.familyDetailF.family_type.setValue(this.details.family_type);
            } else if (this.details.family_type == 'Immediate Family') {
              this.familyDetailF.family_type.setValue(this.details.family_type);
            } else if (this.details.family_type == 'Joint Family') {
              this.familyDetailF.family_type.setValue(this.details.family_type);
            } else if (this.details.family_type == 'Nuclear Family') {
              this.familyDetailF.family_type.setValue(this.details.family_type);
            } else {
              this.familyDetailF.family_type.setValue('');
            }
          }
        } else {
          this.FamilyTypeSelf = false;
          this.familyDetailF.living_with_family.setValue(this.details.living_with_family);

        }
      }



      if (this.details.family_values != '') {
        this.familyDetailF.family_values.setValue(this.details.family_values);
      }

      if (this.details.native_place != '') {
        this.familyDetailF.native_place.setValue(this.details.native_place);
      }

      if (this.details.family_affluence != '') {
        this.familyDetailF.family_affluence.setValue(
          this.details.family_affluence
        );
      }

      //Religion Form Fields
      if (this.details.religion != '') {
        if (this.details.religion == 'Buddhist') {
          this.religionF.religion.setValue(this.details.religion);
        } else if (this.details.religion == 'Christian') {
          this.religionF.religion.setValue(this.details.religion);
        } else if (this.details.religion == 'Hindu') {
          this.religionF.religion.setValue(this.details.religion);
        } else if (this.details.religion == 'Jain') {
          this.religionF.religion.setValue(this.details.religion);
        } else if (this.details.religion == 'Muslim') {
          this.religionF.religion.setValue(this.details.religion);
        } else if (this.details.religion == 'Parsi') {
          this.religionF.religion.setValue(this.details.religion);
        } else if (this.details.religion == 'Sikh') {
          this.religionF.religion.setValue(this.details.religion);
        } else if (
          this.details.religion == 'No Religion' ||
          this.details.religion == 'NoReligion'
        ) {
          this.religionF.religion.setValue(this.details.religion);
        } else if (this.details.religion == 'Any Religion') {
          this.religionF.religion.setValue(this.details.religion);
        } else {
          this.isReligionOther = true;
          this.religionF.religion.setValue('Other');
          this.religionF.otherReligion.setValue(this.details.religion);
        }
      }

      if (this.details.community != '' && this.details.community != null) {
        this.religionF.community.setValue(this.details.community);
      }
      if (this.details.caste != '' && this.details.caste != null) {
        this.religionF.caste.setValue(this.details.caste);
        this.community = ', ' + this.details.caste;
      } else {
        this.community = '';
      }
      if (this.details.gothra != '') {
        this.religionF.gothra.setValue(this.details.gothra);
      }

      //Education & Carrer
      if (this.details.qualification != '') {
        if (this.details.qualification == 'High School') {
          this.details.qualification = 'High School';
          this.educationF.qualification.setValue('High School');
        } else if (this.details.qualification == 'Some College') {
          this.details.qualification = 'Some College';
          this.educationF.qualification.setValue('Some College');
        } else if (this.details.qualification == 'Some University') {
          this.details.qualification = 'Some University';
          this.educationF.qualification.setValue('Some University');
        } else if (
          this.details.qualification == "Associate's Degree" ||
          this.details.qualification == 'Associate Degree' ||
          this.details.qualification == 'Associate`s Degree'
        ) {
          this.details.qualification = 'Associate`s Degree';
          this.educationF.qualification.setValue('Associate`s Degree');
        } else if (
          this.details.qualification == "Master's Degree" ||
          this.details.qualification == 'Master Degree' ||
          this.details.qualification == 'Master`s Degree'
        ) {
          this.details.qualification = 'Master`s Degree';
          this.educationF.qualification.setValue('Master`s Degree');
        } else if (this.details.qualification == 'Graduate Degree') {
          this.details.qualification = 'Graduate Degree';
          this.educationF.qualification.setValue('Graduate Degree');
        } else if (
          this.details.qualification == "Bachelor's Degree" ||
          this.details.qualification == 'Bachelor Degree' ||
          this.details.qualification == 'Bachelor`s Degree'
        ) {
          this.details.qualification = 'Bachelor`s Degree';
          this.educationF.qualification.setValue('Bachelor`s Degree');
        } else if (
          this.details.qualification == 'PhD/Post Doctorate' ||
          this.details.qualification == 'Post Doctoral' ||
          this.details.qualification == 'PhD/Post Doctorate'
        ) {
          this.details.qualification = 'PhD/Post Doctorate';
          this.educationF.qualification.setValue('PhD/Post Doctorate');
        } else {
          this.isQualificationOther = true;
          this.educationF.qualification.setValue('Other');
          this.educationF.otherQualification.setValue(
            this.details.qualification
          );
        }
      }
      if (this.details.college_name != '') {
        this.educationF.college_name.setValue(this.details.college_name);
      }
      if (this.details.annual_income != '') {
        this.educationF.annual_income.setValue(this.details.annual_income);
      }

      // currency
      if (this.details.income_currency != '' && this.details.income_currency != null && this.details.annual_income != null && this.details.annual_income != '') {
        this.educationF.income_currency.setValue(this.details.income_currency);
        this.currencyCode = parseInt(this.details.income_currency);
        this.countries.forEach((country) => {
          if (this.details.income_currency == country.id) {
            this.CurrencyCountryName = country.currency;
          }
        });
      } else if (this.details.living_in_country) {
        //   console.log('canada')
        this.currencyCode = parseInt(this.details.living_in_country);

      } else {
        this.currencyCode = 38;
      }

      if (this.details.nature_of_work != '') {
        if (
          this.details.nature_of_work == 'GovernmentCompany' ||
          this.details.nature_of_work == 'Government Company'
        ) {
          this.details.nature_of_work = 'Government Company';
          this.educationF.nature_of_work.setValue('Government Company');
        } else if (
          this.details.nature_of_work == 'PrivateCompany' ||
          this.details.nature_of_work == 'Private Company'
        ) {
          this.details.nature_of_work = 'Private Company';
          this.educationF.nature_of_work.setValue('Private Company');
        } else if (
          this.details.nature_of_work == 'Business'
        ) {
          this.details.nature_of_work = 'Business';
          this.educationF.nature_of_work.setValue('Business');
        } else if (
          this.details.nature_of_work == 'SelfEmployed' ||
          this.details.nature_of_work == 'Self Employed'
        ) {
          this.details.nature_of_work = 'Self Employed';
          this.educationF.nature_of_work.setValue('Self Employed');
        } else if (
          this.details.nature_of_work == 'NotWorking' ||
          this.details.nature_of_work == 'Not Working'
        ) {
          this.details.nature_of_work = 'Not Working';
          this.educationF.nature_of_work.setValue('Not Working');
        } else {
          this.isNatureOfWorkOther = true;
          this.educationF.nature_of_work.setValue('Other');
          this.educationF.otherNatureOfWork.setValue(
            this.details.nature_of_work
          );
        }
      }
      if (this.details.working_as != '') {
        if (
          this.details.working_as == 'Accounting - Banking & Finance' ||
          this.details.working_as == 'Administration & HR' ||
          this.details.working_as == 'Airline & Aviation' ||
          this.details.working_as == 'Agriculture' ||
          this.details.working_as == 'Beauty - Fashion & Jewellery Designers' ||
          this.details.working_as == 'Sales & Marketing' ||
          this.details.working_as == 'Merchant Navy'
        ) {
          this.educationF.working_as.setValue(this.details.working_as);
        } else {
          this.isProfessionOther = true;
          this.educationF.working_as.setValue('Other');
          this.educationF.otherProfession.setValue(this.details.working_as);
        }
      }
      if (this.details.company_name != '') {
        this.educationF.company_name.setValue(this.details.company_name);
      }
      // Location form

      if (this.details.living_in_country != '' && this.details.living_in_country != null) {
        this.countries5.forEach((country: { id: any; country_name: any }) => {
          if (this.details.living_in_country == country.id) {
            this.locationCountryName = country.country_name;
            this.headingCountryName = country.country_name;
          }
        });
        this.phoneCode = parseInt(this.details.living_in_country);
        this.authService
          .getStateList({ id: this.phoneCode })
          .subscribe((result) => {
            this.states = result.data;
            this.locationstates = this.states;
            this.states.forEach((state) => {
              if (this.details.state_name == state.id) {
                this.locationStateName = state.state_name;
                this.headingStateName = state.state_name;
                this.locationF.state_name.setValue(state.id);
              } else {
                this.locationF.state_name.setValue("");
              }
            });
          });
      }
      if (this.details.current_residence != '') {
        this.locationF.current_residence.setValue(
          this.details.current_residence
        );
      }
      if (this.details.current_city != '') {
        this.locationF.current_city.setValue(this.details.current_city);
      }
      if (this.details.residency_status != '') {
        if (this.details.residency_status == 'Citizen') {
          this.locationF.residency_status.setValue(this.details.residency_status);
        } else if (this.details.residency_status == 'Work Permit') {
          this.locationF.residency_status.setValue(this.details.residency_status);
        } else if (this.details.residency_status == 'Permanent Resident') {
          this.locationF.residency_status.setValue(this.details.residency_status);
        } else if (this.details.residency_status == 'Student Visa') {
          this.locationF.residency_status.setValue(this.details.residency_status);
        } else if (this.details.residency_status == 'Visitor Visa') {
          this.locationF.residency_status.setValue(this.details.residency_status);
        } else {
          this.isResidencyOther = true;
          this.locationF.otherResidency.setValue(this.details.residency_status)
          this.locationF.residency_status.setValue('Other');
        }
      }
      if (this.details.postal_code != '') {
        this.locationF.postal_code.setValue(this.details.postal_code);
      }
      // hobby fields
      if (this.details.hobbies != '') {
        let re = /_/gi;
        let str = this.details.hobbies;
        let newstr = str.replace(re, ',');
        this.hobbyF.hobbies.setValue(newstr);
      }
      if (this.details.interests != '') {
        this.hobbyF.interests.setValue(this.details.interests);
      }
      if (this.details.preferred_movie != '') {
        this.hobbyF.preferred_movie.setValue(this.details.preferred_movie);
      }
      if (this.details.favorite_music != '') {
        this.hobbyF.favorite_music.setValue(this.details.favorite_music);
      }
      if (this.details.sports_fitness != '') {
        this.hobbyF.sports_fitness.setValue(this.details.sports_fitness);
      }
      if (this.details.favorite_cuisine != '') {
        this.hobbyF.favorite_cuisine.setValue(this.details.favorite_cuisine);
      }
      if (this.details.preferred_dress_style != '') {
        this.hobbyF.preferred_dress_style.setValue(
          this.details.preferred_dress_style
        );
      }

      // Contact form
      if (this.details.profile_created_by != '') {
        this.contactF.profile_created_by.setValue(this.details.profile_created_by);
      }
      // if(this.details.dspd !=''){
      //   this.contactF.dspd.setValue(this.details.dspd); for phone code
      // }
      if (this.details.country_code != '') {
        this.countries.forEach((country) => {
          if (this.details.country_code == country.country_code) {
            this.countryNumber = country.id;
          }
        });
      }

      if (this.details.country_number_code != '') {
        this.countries.forEach((country) => {
          if ((this.details.country_code) == country.id) {
            this.countryNumberTo = country.id;
            this.country_codeHandover = country.id
            console.log(this.countryNumber, this.details.country_number_code, country.country_code, "set valuse ");
            console.log(this.countryNumberTo, this.country_codeHandover, "set valuse ");

          }
        });
      }

      if (this.details.mobile_no != '') {
        this.contactF.mobile_no.setValue(this.details.mobile_no);
      }
      if (this.details?.relationship_with_member != '') {
        this.contactF.relationship_with_member.setValue(this.details.relationship_with_member);
      }
      if (this.details.email != '') {
        this.contactF.email.setValue(this.details.email);
      }
      if (
        this.details.convenient_time_to_call != '' &&
        this.details.convenient_time_to_call != null
      ) {
        // time to call
        this.showTime = [];
        this.days = [];
        let std = this.details.convenient_time_to_call.split(',');
        std.forEach((element: any) => {
          if (element != '') {
            let daytime = element.split(' - ');
            daytime.forEach((element: any) => {
              if (element != '') {
                if (element == 'Monday') {
                  $('#Monday').prop('checked', true);
                  this.days.push('Monday');
                  setTimeout(() => {
                    this.contactF.monday_hour_from.setValue(daytime[1]);
                    this.selectedTimeMondayFrom = daytime[1]
                    this.selectedTimeMondayTo = daytime[2]
                    this.contactF.monday_hour_to.setValue(daytime[2]);
                    this.contactF.day_time_zone.setValue(daytime[3]);
                    this.selectedTimeZoneDay = daytime[3];
                  }, 50);
                  // value from
                  let index = this.getTimeIntervalIndex(this.selectedTimeMondayFrom);
                  this.mondayTimeTo = this.makeToArray(index);
                  const keys = this.timeZoneList.map((data) => {
                    if (data.value == daytime[3]) {
                      let utc = data.value;
                      let s1 =
                        daytime[0] +
                        ' - ' +
                        daytime[1].replace(/-/gi, ':') +
                        ' - ' +
                        daytime[2].replace(/-/gi, ':') +
                        utc;

                      this.showTime.push(s1);
                    }
                  });
                  console.log(keys)
                } else if (element == 'Tuesday') {
                  $('#Tuesday').prop('checked', true);
                  this.days.push('Tuesday');
                  this.contactF.tuesday_hour_from.setValue(daytime[1]);
                  this.contactF.tuesday_hour_to.setValue(daytime[2]);
                  this.contactF.tuesday_time_zone.setValue(daytime[3]);
                  this.selectedTimeZoneDay = daytime[3];
                  this.selectedTimeTuesdayFrom = daytime[1]
                  this.selectedTimeTuesdayTo = daytime[2]

                  const keys = this.timeZoneList.map((data) => {
                    if (data.value == daytime[3]) {
                      let utc = data.value;
                      let s1 =
                        daytime[0] +
                        ' - ' +
                        daytime[1].replace(/-/gi, ':') +
                        ' - ' +
                        daytime[2].replace(/-/gi, ':') +
                        utc;
                      // console.log(utc,"hell")
                      this.showTime.push(s1);
                    }
                  });
                  console.log(keys)
                } else if (element == 'Wednesday') {
                  $('#Wednesday').prop('checked', true);
                  this.days.push('Wednesday');
                  this.contactF.wednesday_hour_from.setValue(daytime[1]);
                  this.contactF.wednesday_hour_to.setValue(daytime[2]);
                  this.contactF.wednesday_time_zone.setValue(daytime[3]);
                  this.selectedTimeWednesdayFrom = daytime[1]
                  this.selectedTimeWednesdayTo = daytime[2]

                  this.selectedTimeZoneDay = daytime[3];
                  const keys = this.timeZoneList.map((data) => {
                    if (data.value == daytime[3]) {
                      let utc = data.value;
                      let s1 =
                        daytime[0] +
                        ' - ' +
                        daytime[1].replace(/-/gi, ':') +
                        ' - ' +
                        daytime[2].replace(/-/gi, ':') +
                        utc;
                      this.showTime.push(s1);
                    }
                  });
                  console.log(keys)
                } else if (element == 'Thursday') {
                  $('#Thursday').prop('checked', true);
                  this.days.push('Thursday');
                  this.contactF.thursday_hour_from.setValue(daytime[1]);
                  this.contactF.thursday_hour_to.setValue(daytime[2]);
                  this.contactF.thursday_time_zone.setValue(daytime[3]);

                  this.selectedTimeThursdayFrom = daytime[1]
                  this.selectedTimeThursdayTo = daytime[2]

                  this.selectedTimeZoneDay = daytime[3];
                  const keys = this.timeZoneList.map((data) => {
                    if (data.value == daytime[3]) {
                      let utc = data.value;
                      let s1 =
                        daytime[0] +
                        ' - ' +
                        daytime[1].replace(/-/gi, ':') +
                        ' - ' +
                        daytime[2].replace(/-/gi, ':') +
                        utc;
                      this.showTime.push(s1);
                    }
                  });
                  console.log(keys)
                } else if (element == 'Friday') {
                  $('#Friday').prop('checked', true);
                  this.days.push('Friday');
                  this.contactF.friday_hour_from.setValue(daytime[1]);
                  this.contactF.friday_hour_to.setValue(daytime[2]);
                  this.contactF.friday_time_zone.setValue(daytime[3]);
                  this.selectedTimeFridayFrom = daytime[1]
                  this.selectedTimeFridayTo = daytime[2]

                  this.selectedTimeZoneDay = daytime[3];
                  const keys = this.timeZoneList.map((data) => {
                    if (data.value == daytime[3]) {
                      let utc = data.value;
                      let s1 =
                        daytime[0] +
                        ' - ' +
                        daytime[1].replace(/-/gi, ':') +
                        ' - ' +
                        daytime[2].replace(/-/gi, ':') +
                        utc;
                      this.showTime.push(s1);
                    }
                  });
                  console.log(keys)
                } else if (element == 'Saturday') {
                  $('#Saturday').prop('checked', true);
                  this.days.push('Saturday');
                  this.contactF.saturday_hour_from.setValue(daytime[1]);
                  this.contactF.saturday_hour_to.setValue(daytime[2]);
                  this.contactF.saturday_time_zone.setValue(daytime[3]);
                  this.selectedTimeSaturdayFrom = daytime[1]
                  this.selectedTimeSaturdayTo = daytime[2]

                  this.selectedTimeZoneDay = daytime[3];
                  const keys = this.timeZoneList.map((data) => {
                    if (data.value == daytime[3]) {
                      let utc = data.value;
                      let s1 =
                        daytime[0] +
                        ' - ' +
                        daytime[1].replace(/-/gi, ':') +
                        ' - ' +
                        daytime[2].replace(/-/gi, ':') +

                        utc
                        ;
                      this.showTime.push(s1);
                    }
                  });
                  console.log(keys)
                } else if (element == 'Sunday') {
                  $('#Sunday').prop('checked', true);
                  this.days.push('Sunday');
                  this.contactF.sunday_hour_from.setValue(daytime[1]);
                  this.contactF.sunday_hour_to.setValue(daytime[2]);
                  this.contactF.sunday_time_zone.setValue(daytime[3]);
                  this.selectedTimeZoneDay = daytime[3];
                  this.selectedTimeSundayFrom = daytime[1]
                  this.selectedTimeSundayTo = daytime[2]

                  const keys = this.timeZoneList.map((data) => {
                    if (data.value == daytime[3]) {
                      let utc = data.value;
                      let s1 =
                        daytime[0] +
                        ' - ' +
                        daytime[1].replace(/-/gi, ':') +
                        ' - ' +
                        daytime[2].replace(/-/gi, ':') +
                        utc;

                      this.showTime.push(s1);
                    }
                  });
                  console.log(keys)
                } else if (element == 'Anytime' || element == 'Any-Time') {
                  this.days = [];
                  this.showTime = [];
                  setTimeout(() => {
                    $('#Any-Time').prop('checked', true);
                  }, 50);
                  this.days.push('Any-Time');
                  this.showTime.push(element);
                } else {
                  // // console.log(element,"jay bhai time")
                }
              }
            });
          }
        });
        //time to call
        setTimeout(() => {
          this.days = this.days.filter((item, index) => this.days.indexOf(item) === index);
          // console.log(this.showTime, "this.showTime 11", this.days, "||")
        }, 60);
      }
      if (this.details.display_contact_option != '') {
        this.contactF.display_contact_option.setValue(
          this.details.display_contact_option
        );
      }
      // handover form

      if (this.details.profile_for != '') {
        this.handOverF.relation_ship.setValue(this.details.profile_for);
      }

      if (this.details.first_name != '') {
        this.handOverF.first_name_to.setValue(this.details.first_name);
      }

      if (this.details.last_name != '') {
        this.handOverF.last_name_to.setValue(this.details.last_name);
      }
      if (this.details.email != '') {
        this.handOverF.email_to.setValue(this.details.email);
      }
      if (this.details.mobile_no != '') {
        this.handOverF.mobile_no_to.setValue(this.details.mobile_no);
      }
      // console.log(this.details.last_name," s1")
    });

    if (!!this.editId && !!this.editId2) {
      console.log(this.editId, 'aboutYou', 'this.editId2');
      window.scrollTo({
        top: 630,
        behavior: 'smooth' // You can use 'auto' or 'smooth' for smooth scrolling
      });
    }
    setTimeout(() => {
      this.isTrue = true;
    }, 50);
  }

  resetContactDetail() {
    this.showTime = [];
    let data = {
      user_id: this.user_id,
    };
    this.registerService.getUserDetails(data).subscribe((user_details: any) => {
      this.details = user_details.userDetails;

      if (
        this.details.convenient_time_to_call != '' &&
        this.details.convenient_time_to_call != null
      ) {
        // time to call
        this.showTime = [];
        this.days = [];
        let std = this.details.convenient_time_to_call.split(',');
        std.forEach((element: any) => {
          if (element != '') {
            let daytime = element.split(' - ');
            daytime.forEach((element: any) => {
              if (element != '') {
                if (element == 'Monday') {
                  $('#Monday').prop('checked', true);
                  this.days.push('Monday');
                  setTimeout(() => {
                    this.contactF.monday_hour_from.setValue(daytime[1]);
                    this.selectedTimeMondayFrom = daytime[1]
                    this.selectedTimeMondayTo = daytime[2]
                    this.contactF.monday_hour_to.setValue(daytime[2]);
                    this.contactF.day_time_zone.setValue(daytime[3]);
                    this.selectedTimeZoneDay = daytime[3];
                  }, 50);
                  // value from
                  let index = this.getTimeIntervalIndex(this.selectedTimeMondayFrom);
                  this.mondayTimeTo = this.makeToArray(index);
                  const keys = this.timeZoneList.map((data) => {
                    if (data.value == daytime[3]) {
                      let utc = data.value;
                      let s1 =
                        daytime[0] +
                        ' - ' +
                        daytime[1].replace(/-/gi, ':') +
                        ' - ' +
                        daytime[2].replace(/-/gi, ':') +
                        utc;
                      this.showTime.push(s1);
                    }
                  });
                  console.log(keys)
                } else if (element == 'Tuesday') {
                  $('#Tuesday').prop('checked', true);
                  this.days.push('Tuesday');
                  this.contactF.tuesday_hour_from.setValue(daytime[1]);
                  this.contactF.tuesday_hour_to.setValue(daytime[2]);
                  this.contactF.tuesday_time_zone.setValue(daytime[3]);
                  this.selectedTimeZoneDay = daytime[3];
                  this.selectedTimeTuesdayFrom = daytime[1]
                  this.selectedTimeTuesdayTo = daytime[2]

                  const keys = this.timeZoneList.map((data) => {
                    if (data.value == daytime[3]) {
                      let utc = data.value;
                      let s1 =
                        daytime[0] +
                        ' - ' +
                        daytime[1].replace(/-/gi, ':') +
                        ' - ' +
                        daytime[2].replace(/-/gi, ':') +
                        utc;
                      // console.log(utc,"hell")
                      this.showTime.push(s1);
                    }
                  });
                  console.log(keys)
                } else if (element == 'Wednesday') {
                  $('#Wednesday').prop('checked', true);
                  this.days.push('Wednesday');
                  this.contactF.wednesday_hour_from.setValue(daytime[1]);
                  this.contactF.wednesday_hour_to.setValue(daytime[2]);
                  this.contactF.wednesday_time_zone.setValue(daytime[3]);
                  this.selectedTimeWednesdayFrom = daytime[1]
                  this.selectedTimeWednesdayTo = daytime[2]

                  this.selectedTimeZoneDay = daytime[3];
                  const keys = this.timeZoneList.map((data) => {
                    if (data.value == daytime[3]) {
                      let utc = data.value;
                      let s1 =
                        daytime[0] +
                        ' - ' +
                        daytime[1].replace(/-/gi, ':') +
                        ' - ' +
                        daytime[2].replace(/-/gi, ':') +
                        utc;
                      this.showTime.push(s1);
                    }
                  });
                  console.log(keys)
                } else if (element == 'Thursday') {
                  $('#Thursday').prop('checked', true);
                  this.days.push('Thursday');
                  this.contactF.thursday_hour_from.setValue(daytime[1]);
                  this.contactF.thursday_hour_to.setValue(daytime[2]);
                  this.contactF.thursday_time_zone.setValue(daytime[3]);

                  this.selectedTimeThursdayFrom = daytime[1]
                  this.selectedTimeThursdayTo = daytime[2]

                  this.selectedTimeZoneDay = daytime[3];
                  const keys = this.timeZoneList.map((data) => {
                    if (data.value == daytime[3]) {
                      let utc = data.value;
                      let s1 =
                        daytime[0] +
                        ' - ' +
                        daytime[1].replace(/-/gi, ':') +
                        ' - ' +
                        daytime[2].replace(/-/gi, ':') +
                        utc;
                      this.showTime.push(s1);
                    }
                  });
                  console.log(keys)
                } else if (element == 'Friday') {
                  $('#Friday').prop('checked', true);
                  this.days.push('Friday');
                  this.contactF.friday_hour_from.setValue(daytime[1]);
                  this.contactF.friday_hour_to.setValue(daytime[2]);
                  this.contactF.friday_time_zone.setValue(daytime[3]);
                  this.selectedTimeFridayFrom = daytime[1]
                  this.selectedTimeFridayTo = daytime[2]

                  this.selectedTimeZoneDay = daytime[3];
                  const keys = this.timeZoneList.map((data) => {
                    if (data.value == daytime[3]) {
                      let utc = data.value;
                      let s1 =
                        daytime[0] +
                        ' - ' +
                        daytime[1].replace(/-/gi, ':') +
                        ' - ' +
                        daytime[2].replace(/-/gi, ':') +
                        utc;
                      this.showTime.push(s1);
                    }
                  });
                  console.log(keys)
                } else if (element == 'Saturday') {
                  $('#Saturday').prop('checked', true);
                  this.days.push('Saturday');
                  this.contactF.saturday_hour_from.setValue(daytime[1]);
                  this.contactF.saturday_hour_to.setValue(daytime[2]);
                  this.contactF.saturday_time_zone.setValue(daytime[3]);
                  this.selectedTimeSaturdayFrom = daytime[1]
                  this.selectedTimeSaturdayTo = daytime[2]

                  this.selectedTimeZoneDay = daytime[3];
                  const keys = this.timeZoneList.map((data) => {
                    if (data.value == daytime[3]) {
                      let utc = data.value;
                      let s1 =
                        daytime[0] +
                        ' - ' +
                        daytime[1].replace(/-/gi, ':') +
                        ' - ' +
                        daytime[2].replace(/-/gi, ':') +

                        utc
                        ;
                      this.showTime.push(s1);
                    }
                  });
                  console.log(keys)
                } else if (element == 'Sunday') {
                  $('#Sunday').prop('checked', true);
                  this.days.push('Sunday');
                  this.contactF.sunday_hour_from.setValue(daytime[1]);
                  this.contactF.sunday_hour_to.setValue(daytime[2]);
                  this.contactF.sunday_time_zone.setValue(daytime[3]);
                  this.selectedTimeZoneDay = daytime[3];
                  this.selectedTimeSundayFrom = daytime[1]
                  this.selectedTimeSundayTo = daytime[2]

                  const keys = this.timeZoneList.map((data) => {
                    if (data.value == daytime[3]) {
                      let utc = data.value;
                      let s1 =
                        daytime[0] +
                        ' - ' +
                        daytime[1].replace(/-/gi, ':') +
                        ' - ' +
                        daytime[2].replace(/-/gi, ':') +
                        utc;

                      this.showTime.push(s1);
                    }
                  });
                  console.log(keys)
                } else if (element == 'Anytime' || element == 'Any-Time') {
                  // // console.log(element,"jay bhai time")
                  this.days = [];
                  this.showTime = [];
                  setTimeout(() => {
                    $('#Any-Time').prop('checked', true);
                  }, 50);
                  this.days.push('Any-Time');
                  this.showTime.push(element);
                } else {
                  // // console.log(element,"jay bhai time")
                }
              }
            });
          }
        });
        //time to call

        setTimeout(() => {
          this.days = this.days.filter((item, index) => this.days.indexOf(item) === index);
        }, 60);
      }
    });
  }

  getAge(dateString: string) {
    const date = moment(dateString, 'YYYY-MM-community');
    const years = moment().diff(date, 'years');
    this.calculatedAge = years;
    return years;
  }

  sethandoverdetail() {
    if (this.details.profile_for != '') {
      this.handOverF.relation_ship.setValue(this.details.profile_for);
    }
    if (this.details.first_name != '') {
      this.handOverF.first_name_to.setValue(this.details.first_name);
    }
    if (this.details.last_name != '') {
      this.handOverF.last_name_to.setValue(this.details.last_name);
    }
    if (this.details.email != '') {
      this.handOverF.email_to.setValue(this.details.email);
    }
    if (this.details.mobile_no != '') {
      this.handOverF.mobile_no_to.setValue(this.details.mobile_no);
    }
    // console.log(this.details.last_name," s1")
  }

  getPartnerDetail() {
    let data = {
      user_id: this.user_id,
    };
    this.ispartnerDetailFetched = false;
    this.partnerPreferenceService.getPartnerDetails(data).subscribe(
      (result) => {
        this.partnerDetail = result.userDetails;
        // basic from

        if (this.partnerDetail.do_smoke != '') {
          this.partnerBasicF.do_smoke.setValue(this.partnerDetail.do_smoke);
        }

        if (this.partnerDetail.do_drink != '') {
          this.partnerBasicF.do_drink.setValue(this.partnerDetail.do_drink);
        }

        if (this.partnerDetail.have_pet != '') {
          this.partnerBasicF.have_pet.setValue(this.partnerDetail.have_pet);
        }

        if (this.partnerDetail.age_from != '') {
          this.partnerBasicF.age_from.setValue(this.partnerDetail.age_from);
          for (let i = this.partnerDetail.age_from; i <= 90; i++) {
            this.age_to.push({ id: i });
          }
        }

        if (this.partnerDetail.age_to != '') {
          this.partnerBasicF.age_to.setValue(this.partnerDetail.age_to);
        }

        if (this.partnerDetail.height_from != '') {
          for (let i = this.partnerDetail.height_from; i <= 230; i++) {
            this.height_to.push({ i });
          }
          this.partnerBasicF.height_from.setValue(
            this.partnerDetail.height_from
          );
        }
        if (this.partnerDetail.height_to != '') {
          this.partnerBasicF.height_to.setValue(this.partnerDetail.height_to);
        }

        // weight
        if (this.partnerDetail.weight_from != '') {
          for (let i = this.partnerDetail.weight_from; i <= 114; i++) {
            this.weight_to.push({ i });
          }
          this.partnerBasicF.weight_from.setValue(
            this.partnerDetail.weight_from
          );
        }
        if (this.partnerDetail.weight_to != '') {
          this.partnerBasicF.weight_to.setValue(this.partnerDetail.weight_to);
        }
        if (this.partnerDetail.marital_status != '') {
          if (
            this.partnerDetail.marital_status == 'Single' ||
            this.partnerDetail.marital_status == 'Annulled'
          ) {
            this.partnerBasicShowChildren = false;
            if (this.partnerDetail.have_children != '') {
              this.partnerBasicF.have_children.setValue(
                this.partnerDetail.have_children
              );
            }
            this.partnerBasicF.marital_status.setValue(
              this.partnerDetail.marital_status
            );
          } else {
            this.partnerBasicShowChildren = true;
            this.partnerBasicF.marital_status.setValue(
              this.partnerDetail.marital_status
            );
            if (this.partnerDetail.marital_status == 'AwaitingDivorce') {
              this.partnerDetail.marital_status = 'Awaiting Divorce';
              this.partnerBasicF.marital_status.setValue('AwaitingDivorce');
            } else {
              this.partnerBasicF.marital_status.setValue(
                this.partnerDetail.marital_status
              );
            }
          }
        }

        if (this.partnerDetail.description != '') {
          this.partnerBasicF.description.setValue(
            this.partnerDetail.description
          );
          if (this.partnerDetail.description != '') {
            this.words2 = this.partnerDetail.description.split(/\s+/).length;
          }
        }

        //languages
        if (this.partnerDetail?.languages) {
          let languagesArr = this.partnerDetail.languages.split(',');
          this.selectedlanguages = [];
          languagesArr.forEach((element: any) => {
            //// console.log(element);
            if (element != '') {
              if (element == 'Bengali') {
                this.selectedlanguages.push('Bengali');
                $('#Bengali').prop('checked', true);
              } else if (element == 'English') {
                this.selectedlanguages.push('English');
                $('#English').prop('checked', true);
              } else if (element == 'Gujarati') {
                this.selectedlanguages.push('Gujarati');
                $('#Gujarati').prop('checked', true);
              } else if (element == 'Hindi') {
                this.selectedlanguages.push('Hindi');
                $('#Hindi').prop('checked', true);
              } else if (element == 'Marathi') {
                this.selectedlanguages.push('Marathi');
                $('#Marathi').prop('checked', true);
              } else if (element == 'Punjabi') {
                this.selectedlanguages.push('Punjabi');
                $('#Punjabi').prop('checked', true);
              } else if (element == 'Telugu') {
                this.selectedlanguages.push('Telugu');
                $('#Telugu').prop('checked', true);
              } else if (element == 'Tamil') {
                this.selectedlanguages.push('Tamil');
                $('#Tamil').prop('checked', true);
              } else if (element == 'Any Language') {
                this.selectedlanguages.push('Any Language');
                $('#AnyLanguage').prop('checked', true);
              } else {
                this.selectedlanguages.push('Other');
                this.partnerBasicF.otherLanguage.setValue(element);
                this.partnerOtherLanguage = true;
              }
            } //djk
          });
          let re = /,/gi;
          this.showLanguage = this.partnerDetail.languages.replace(re, ', ');
        }

        // food preference
        if (this.partnerDetail?.food_preference) {
          let foodArr = this.partnerDetail.food_preference.split(',');
          this.selectedFoodPreference = [];
          foodArr.forEach((element: any) => {
            //// console.log(element);
            if (element != '') {
              if (element == 'Veg') {
                this.selectedFoodPreference.push('Veg');
              } else if (element == 'Non Veg') {
                this.selectedFoodPreference.push('Non Veg');
              } else if (element == 'Occasionally Non-Veg') {
                this.selectedFoodPreference.push('Occasionally Non-Veg');
              } else if (element == 'Eggetarian') {
                this.selectedFoodPreference.push('Eggetarian');
              } else if (element == 'Pescatarian') {
                this.selectedFoodPreference.push('Pescatarian');
              } else if (element == 'Jain') {
                this.selectedFoodPreference.push('Jain');
              } else if (element == 'Vegan') {
                this.selectedFoodPreference.push('Vegan');
              } else if (element == 'Any Of These') {
                this.selectedFoodPreference.push('Any Of These');
              } else {
                this.selectedFoodPreference.push('Other');
                this.partnerBasicF.otherFood.setValue(element);
                this.partnerOtherFood = true;
              }
            }
          });

          let re = /,/gi;
          this.showFood = this.partnerDetail.food_preference.replace(re, ', ');
        }
        if (this.partnerDetail?.marital_status) {
          let marital_statusArr = this.partnerDetail.marital_status.split(',');
          this.selectedMaritalStatus = [];
          marital_statusArr.forEach((element: any) => {
            if (element != '') {
              if (element == 'Annulled') {
                this.selectedMaritalStatus.push('Annulled');
              } else if (element == 'Awaiting Divorce') {
                this.selectedMaritalStatus.push('Awaiting Divorce');
              } else if (element == 'Divorced') {
                this.selectedMaritalStatus.push('Divorced');
              } else if (element == 'Single') {
                this.selectedMaritalStatus.push('Single');
              } else if (element == 'Widowed') {
                this.selectedMaritalStatus.push('Widowed');
              } else if (element == 'Any Of These') {
                this.selectedMaritalStatus.push('Any Of These');
              } else {
                // unused code
                //  this.selectedMaritalStatus.push(element);
                //  this.partnerBasicF.otherLanguage.setValue(element);
                //  this.partnerOtherLanguage = true;
              }
            }
          });
          let re = /,/gi;
          this.showMarital = this.partnerDetail.marital_status.replace(re, ', ');
        }

        // religion data
        if (this.partnerDetail?.religion) {
          let relArr = this.partnerDetail.religion.split(',');
          this.selectedReligion = [];
          relArr.forEach((element: any) => {
            //// console.log(element);
            if (element != '') {
              if (element == 'Buddhist') {
                this.selectedReligion.push('Buddhist');
              } else if (element == 'Christian') {
                this.selectedReligion.push('Christian');
              } else if (element == 'Hindu') {
                this.selectedReligion.push('Hindu');
              } else if (element == 'Jain') {
                this.selectedReligion.push('Jain');
              } else if (element == 'Muslim') {
                this.selectedReligion.push('Muslim');
              } else if (element == 'Parsi') {
                this.selectedReligion.push('Parsi');
              } else if (element == 'Sikh') {
                this.selectedReligion.push('Sikh');
              } else if (element == 'No Religion' || element == 'NoReligion') {
                this.selectedReligion.push('No Religion');
              } else if (element == 'Any Religion') {
                this.selectedReligion.push('Any Religion');
              } else {
                this.selectedReligion.push('Other');
                this.partnerReligionF.otherReligion.setValue(element);
                this.partnerOtherReligion = true;
              }
            } //djk
          });
          let re = /,/gi;
          this.showReligion = this.partnerDetail.religion.replace(re, ', ');
        }

        if (this.partnerDetail.community != '') {
          this.partnerReligionF.community.setValue(
            this.partnerDetail.community
          );
        }
        if (this.partnerDetail.caste != '') {
          this.partnerReligionF.caste.setValue(this.partnerDetail.caste);
        }
        if (this.partnerDetail.gothra != '') {
          this.partnerReligionF.gothra.setValue(this.partnerDetail.gothra);
        }

        // Location form
        //  this.authService.getCountryList().subscribe(
        //   (result) => {
        //       this.countries = result.data;})
        if (this.partnerDetail.country_name != '' || this.partnerDetail.country_name != undefined) {

          if (this.partnerDetail.country_name == 0 || this.partnerDetail.country_name == "Any") {
            this.partnerLocationCountryName = "Any";
            this.partnerCurrencyCode = 0;
            this.partnerLocationStateName = 'Any';
            if (this.partnerDetail.state_name != '') {
              this.partnerLocationF.state_name.setValue(
                this.partnerDetail.state_name
              );
            }
          } else if (this.countries1 != '' && this.countries1 != undefined) {
            this.countries1.forEach(
              (country: { id: any; country_name: any }) => {

                if (this.partnerDetail.country_name == country.id) {
                  this.partnerLocationCountryName = country.country_name;
                  this.partnerCurrencyCode = country.id;
                } else if (this.partnerDetail.country_name == "Any") {
                  this.partnerLocationCountryName = country.country_name;
                  this.partnerCurrencyCode = country.id;
                  console.log(this.partnerDetail.country_name)
                }
              }
            );
            this.partnerLocationF.country_name.setValue(
              this.partnerDetail.country_name
            );
            this.partnerLocationPhoneCode = parseInt(
              this.partnerDetail.country_name
            );
            console.log(this.partnerLocationCountryName, "after Fun")
            if (this.partnerDetail.state_name == 'Any') {
              this.partnerLocationStateName = 'Any';
              this.authService
                .getStateList({ id: this.partnerDetail.country_name })
                .subscribe((result) => {
                  this.partnerLocationStates = result.data;
                  if (this.partnerDetail.state_name != '') {
                    this.partnerLocationF.state_name.setValue(
                      this.partnerDetail.state_name
                    );
                  }
                });
            } else {
              this.authService
                .getStateList({ id: this.partnerDetail.country_name })
                .subscribe((result) => {
                  this.partnerLocationStates = result.data;
                  this.partnerLocationStates.forEach((state) => {
                    if (this.partnerDetail.state_name == state.id) {
                      this.partnerLocationStateName = state.state_name;
                    }
                  });
                  if (this.partnerDetail.state_name != '') {
                    this.partnerLocationF.state_name.setValue(
                      this.partnerDetail.state_name
                    );
                  }
                });
            }
          }
        } else /*{ before sonar uncomment*/  if (this.countries != '' && this.countries != undefined) {
          this.countries.forEach(
            (country: { id: any; country_name: any }) => {

              if (this.partnerDetail.country_name == country.id) {
                this.partnerLocationCountryName = country.country_name;
                this.partnerCurrencyCode = country.id;
                if (this.partnerDetail.state_name != '') {
                  this.partnerLocationF.state_name.setValue(this.partnerDetail.state_name);
                  this.partnerLocationStateName = 'Any';
                }
              } else if (this.partnerDetail.country_name == "Any") {
                this.partnerLocationCountryName = country.country_name;
                this.partnerCurrencyCode = country.id;
              }
            }
          );
        }

        if (this.partnerDetail.city_name != '') {
          this.partnerLocationF.city_name.setValue(
            this.partnerDetail.city_name
          );
        }
        if (this.partnerDetail?.residency_status) {
          let relArr = this.partnerDetail.residency_status.split(',');
          this.selectedPartnerResidencyStatus = [];
          relArr.forEach((element: any) => {
            if (element != '') {
              if (element == 'Citizen') {
                this.selectedPartnerResidencyStatus.push(element);
              } else if (element == 'Work Permit') {
                this.selectedPartnerResidencyStatus.push(element);
              } else if (element == 'Permanent Resident') {
                this.selectedPartnerResidencyStatus.push(element);
              } else if (element == 'Student Visa') {
                this.selectedPartnerResidencyStatus.push(element);
              } else if (element == 'Visitor Visa') {
                this.selectedPartnerResidencyStatus.push(element);
              } else if (element == 'Other') {
                this.selectedPartnerResidencyStatus.push(element);
              } else {
                this.selectedPartnerResidencyStatus.push('Other');
                this.partnerOtherResidencyStatus = true;
                this.partnerLocationF.otherResidencyStatus.setValue(element);
              }
            }
          });

          let re = /,/gi;
          this.showResidency = this.partnerDetail.residency_status.replace(re, ', ');
        }

        //family details
        if (this.partnerDetail.father_occupation != '') {
          this.partnerFamilyF.father_occupation.setValue(
            this.partnerDetail.father_occupation
          );
        }
        if (this.partnerDetail.mother_occupation != '') {
          this.partnerFamilyF.mother_occupation.setValue(
            this.partnerDetail.mother_occupation
          );
        }

        if (this.partnerDetail.family_living_in_country != '' && this.partnerDetail.family_living_in_country != null) {
          this.partnerFamilyF.family_living_in_country.setValue(this.partnerDetail.family_living_in_country);
          this.partnerFamilyPhoneCode = parseInt(this.partnerDetail.family_living_in_country);
          if (this.countries) {
            this.countries.forEach(
              (country: { id: any; country_name: any }) => {
                if (this.partnerDetail.family_living_in_country == country.id) {
                  this.partnerFamilyCountryName = country.country_name;
                }
              }
            );
            // this.phoneCode = this.partnerDetail.family_living_in_country;
            if (this.partnerDetail.family_state_name) {
              this.authService
                .getStateList({
                  id: this.partnerDetail.family_living_in_country,
                })
                .subscribe((result) => {
                  this.partnerFamilyStates = result.data;
                  this.partnerFamilyStates.forEach((state) => {
                    if (this.partnerDetail.family_state_name == 'Any') {
                      this.partnerFamilyStateName = 'Any';
                    } else if (this.partnerDetail.family_state_name == state.id) {
                      this.partnerFamilyStateName = state.state_name;
                    }
                  });
                  if (this.partnerDetail.family_state_name != '') {
                    this.partnerFamilyF.family_state_name.setValue(
                      this.partnerDetail.family_state_name
                    );
                  }
                });
            }
          }

        } else {
          this.partnerFamilyPhoneCode = null
          this.partnerFamilyF.family_living_in_country.setValue("");
        }
        if (this.partnerDetail.family_current_city != '') {
          this.partnerFamilyF.family_current_city.setValue(
            this.partnerDetail.family_current_city
          );
        }
        if (this.partnerDetail.living_with_family != '') {
          this.partnerFamilyF.living_with_family.setValue(
            this.partnerDetail.living_with_family
          );
          if (this.partnerDetail.living_with_family == 'Yes')
            this.FamilyTypePartner = true;
          if (this.partnerDetail?.family_type) {
            let relArr = this.partnerDetail.family_type.split(',');
            this.selectedPartnerFamilyType = [];
            relArr.forEach((element: any) => {
              if (element != '') {
                if (element == 'Extended Family') {
                  this.selectedPartnerFamilyType.push(element);
                } else if (element == 'Immediate Family') {
                  this.selectedPartnerFamilyType.push(element);
                  console.log(element, 'Immediate Family')
                } else if (element == 'Joint Family') {
                  this.selectedPartnerFamilyType.push(element);
                } else if (element == 'Nuclear Family') {
                  this.selectedPartnerFamilyType.push(element);
                } else if (element == "Does Not Matter") {
                  this.selectedPartnerFamilyType.push(element);
                }
              }
            });
            // space in list
            let re = /,/gi;
            let str = this.selectedPartnerFamilyType.toString();
            this.showFamilyType = str.replace(re, ', ');

            // space in list
          } else {
            this.FamilyTypePartner = false;
            this.selectedPartnerFamilyType = [];
          }
          this.partnerFamilyF.living_with_family.setValue(
            this.partnerDetail.living_with_family
          );
        }

        if (this.partnerDetail?.nature_of_work) {
          let relArr = this.partnerDetail.nature_of_work.split(',');
          this.selectedPartnerNatureOfWork = [];
          relArr.forEach((element: any) => {
            if (element != '') {
              if (
                element == 'Business / Self Employed' ||
                element == 'Business/Self Employed'
              ) {
                this.selectedPartnerNatureOfWork.push(element);
              } else if (
                element == 'Government Company / Public Sector' ||
                element == 'Government Company/Public Sector'
              ) {
                this.selectedPartnerNatureOfWork.push(element);
              } else if (element == 'Private Company') {
                this.selectedPartnerNatureOfWork.push(element);
              } else if (element == 'Not Working') {
                this.selectedPartnerNatureOfWork.push(element);
              } else if (element == 'Does Not Matter') {
                this.selectedPartnerNatureOfWork.push(element);
              } else {
                this.selectedPartnerNatureOfWork.push('Other');
                this.partnerOtherNatureOfWork = true;
                this.partnerEducationF.otherNatureOfWork.setValue(element);
              }
            }
          });
          let re = /,/gi;
          let str = this.partnerDetail.nature_of_work.toString();
          this.showNatureOfWork = str.replace(re, ', ');
        }
        if (this.partnerDetail.annual_income != '') {
          if (
            this.partnerDetail.annual_income == 'OpenToAll' ||
            this.partnerDetail.annual_income == 'Open To All'
          ) {
            this.partnerIncomeRange = false;
            this.partnerDetail.annual_income = 'Open To All ';
            this.partnerEducationF.annual_income.setValue('Open To All');
            this.partnerEducationF.income_currency.setValue("other");
            this.partnerEducationF.income_range_from.setValue(0);
            this.partnerEducationF.income_range_to.setValue(0);

          } else {
            this.partnerDetail.annual_income = 'Specify Range';
            this.partnerEducationF.annual_income.setValue('SpecifyRange');
            this.partnerIncomeRange = true;
            if (this.partnerDetail.income_currency != '') {
              this.partnerEducationF.income_currency.setValue(
                this.partnerDetail.income_currency
              );
            }

            if (this.partnerDetail.income_currency != '') {
              this.partnerEducationF.income_currency.setValue(
                this.partnerDetail.income_currency
              );
              this.partnerCurrencyCode = parseInt(
                this.partnerDetail.income_currency
              );
              this.countries.forEach((country) => {
                if (this.partnerDetail.income_currency == country.id) {
                  this.PartnerCurrencyCountryName = country.currency;
                }
              });
            }

            if (this.partnerDetail.income_range_to != '') {
              this.partnerEducationF.income_range_to.setValue(
                this.partnerDetail.income_range_to
              );
            }
            if (this.partnerDetail.income_range_from != '') {
              this.partnerEducationF.income_range_from.setValue(
                this.partnerDetail.income_range_from
              );
            }
          }
        }
        if (this.partnerDetail?.profession_area) {
          let relArr = this.partnerDetail.profession_area.split(',');
          this.selectedPartnerProfession = [];
          relArr.forEach((element: any) => {
            //// console.log(element);
            if (element != '') {
              if (element == 'Accounting - Banking & Finance') {
                this.selectedPartnerProfession.push(element);
              } else if (element == 'Administration & HR') {
                this.selectedPartnerProfession.push(element);
              } else if (element == 'Airline & Aviation') {
                this.selectedPartnerProfession.push(element);
              } else if (element == 'Agriculture') {
                this.selectedPartnerProfession.push(element);
              } else if (element == 'Beauty - Fashion & Jewellery Designers') {
                this.selectedPartnerProfession.push(element);
              } else if (element == 'Sales & Marketing') {
                this.selectedPartnerProfession.push(element);
                $('#workPermit').prop('checked', true);
              } else if (element == 'Merchant Navy') {
                this.selectedPartnerProfession.push(element);
              } else if (element == 'Post Doctoral') {
                this.selectedPartnerProfession.push(element);
              } else if (element == 'Does Not Matter') {
                this.selectedPartnerProfession.push(element);
              } else if (element == 'Other') {
                this.selectedPartnerProfession.push(element);
              } else {
                this.selectedPartnerProfession.push('Other');
                this.partnerOtherProfession = true;
                this.partnerEducationF.otherProfession.setValue(element);
              }
            }
          });
          let re = /,/gi;
          this.showProfession = this.partnerDetail.profession_area.replace(re, ', ');
        }

        if (this.partnerDetail?.education) {
          let relArr = this.partnerDetail.education.split(',');
          this.selectedPartnerEducation = [];
          relArr.forEach((element: any) => {
            if (element != '') {
              if (element == 'High School') {
                this.selectedPartnerEducation.push(element);
                $('#Citizen').prop('checked', true);
              } else if (element == 'Some College') {
                this.selectedPartnerEducation.push(element);
                $('#workPermit').prop('checked', true);
              } else if (element == 'Some University') {
                this.selectedPartnerEducation.push(element);
              } else if (element == 'Associate`s Degree') {
                this.selectedPartnerEducation.push(element);
              } else if (element == 'Bachelor`s Degree') {
                this.selectedPartnerEducation.push(element);
              } else if (element == 'Master`s Degree') {
                this.selectedPartnerEducation.push(element);
                $('#workPermit').prop('checked', true);
                console.log(this.selectedPartnerEducation)
              } else if (element == 'Graduate Degree') {
                this.selectedPartnerEducation.push(element);
              } else if (element == 'PhD/Post Doctorate') {
                this.selectedPartnerEducation.push(element);
              } else if (element == 'Does Not Matter') {
                this.selectedPartnerEducation.push(element);
              } else if (element == 'Other') {
                this.selectedPartnerEducation.push(element);
              } else {
                this.selectedPartnerEducation.push('Other');
                this.partnerOtherEducation = true;
                this.partnerEducationF.otherEducation.setValue(element);
              }
            }
          });
          let re = /,/gi;
          this.showEducation = this.partnerDetail.education.replace(re, ', ');
        }
        if (this.details?.profile_photo && this.details.profile_photo_approved == 1) {
          this.is_uploaded = true;
          this.imgSelected = true;
          this.croppedImage = this.details.profile_photo;
        }
        this.ispartnerDetailFetched = true;
      },
      (error) => { }

    );
  }

  editAboutYou() {
    if (this.aboutYouForm.value.languages == 'other') {
      this.isAboutYouLanguageOther = true;
    }
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .addClass('removeBorder');


    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');

    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');

    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');

    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');

    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.isEditAboutYou = true;

    this.isEditPartnerBasic = false;
    this.isEditPartnerReligion = false;
    this.isEditPartnerFamily = false;
    this.isEditPartnerLocation = false;
    this.isEditPartnerEducation = false;
    window.scroll(0, 630);
  }

  editBasicLifeStyle() {
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .addClass('removeBorder');
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.isEditBasic = true;
    this.isEditPartnerBasic = false;
    this.isEditPartnerReligion = false;
    this.isEditPartnerFamily = false;
    this.isEditPartnerLocation = false;
    this.isEditPartnerEducation = false;
    window.scroll(0, 630);
  }

  editHandOver() {
    this.isEditHandOverForm = true;
    this.isEditPartnerBasic = false;
    this.isEditPartnerReligion = false;
    this.isEditPartnerFamily = false;
    this.isEditPartnerLocation = false;
    this.isEditPartnerEducation = false;
    window.scroll(0, 630);
  }

  editReligion() {
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .addClass('removeBorder');
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.isEditReligion = true;
    this.isEditPartnerBasic = false;
    this.isEditPartnerReligion = false;
    this.isEditPartnerFamily = false;
    this.isEditPartnerLocation = false;
    this.isEditPartnerEducation = false;
    window.scroll(0, 630);
  }

  editAstro() {
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .addClass('removeBorder');
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.countryOfBirthChange = this.countryOfBirth;
    this.isEditAstro = true;
    this.isEditPartnerBasic = false;
    this.isEditPartnerReligion = false;
    this.isEditPartnerFamily = false;
    this.isEditPartnerLocation = false;
    this.isEditPartnerEducation = false;
    window.scroll(0, 630);
  }

  editFamily() {
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .addClass('removeBorder');
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.isEditFamily = true;
    this.isEditPartnerBasic = false;
    this.isEditPartnerReligion = false;
    this.isEditPartnerFamily = false;
    this.isEditPartnerLocation = false;
    this.isEditPartnerEducation = false;
    window.scroll(0, 630);
  }

  editEducation() {
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .addClass('removeBorder');
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.isEditEducation = true;
    this.isEditPartnerBasic = false;
    this.isEditPartnerReligion = false;
    this.isEditPartnerFamily = false;
    this.isEditPartnerLocation = false;
    this.isEditPartnerEducation = false;
    window.scroll(0, 630);
    if (this.educationForm.value.working_as == 'Other') {
      this.isProfessionOther = true;
      this.focusOnInput8();
    } else {
      this.isProfessionOther = false;
    }
  }

  editHobbies() {
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .addClass('removeBorder');
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.isEditHobbies = true;
    this.isEditPartnerBasic = false;
    this.isEditPartnerReligion = false;
    this.isEditPartnerFamily = false;
    this.isEditPartnerLocation = false;
    this.isEditPartnerEducation = false;
    window.scroll(0, 630);
  }

  editLocation() {
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .addClass('removeBorder');
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.isEditLocation = true;

    this.isEditPartnerBasic = false;
    this.isEditPartnerReligion = false;
    this.isEditPartnerFamily = false;
    this.isEditPartnerLocation = false;
    this.isEditPartnerEducation = false;
    window.scroll(0, 630);
  }

  editContactDetail() {
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .addClass('removeBorder');
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.isEditContactDetail = true;
    this.isEditPartnerBasic = false;
    this.isEditPartnerReligion = false;
    this.isEditPartnerFamily = false;
    this.isEditPartnerLocation = false;
    this.isEditPartnerEducation = false;
    this.timeSet();
    window.scroll(0, 630);
  }

  // close window
  closeAboutYou() {
    this.aboutYouSubmitted = false;
    this.isAboutYouLanguageOther = false;
    this.isEditAboutYou = false;
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.getUserDetails();

    // Scroll to the top of the window
    window.scrollTo({
      top: 630,
      behavior: 'smooth' // You can use 'auto' or 'smooth' for smooth scrolling
    });
  }

  closeBasicLifeStyle() {
    this.isEditBasic = false;
    this.isBasicFoodOther = false;
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.getUserDetails();
    window.scroll(0, 630);
  }

  closeHandOver() {
    this.isEditHandOverForm = false;
    this.getUserDetails();
    this.sethandoverdetail();
    window.scroll(0, 630);
  }


  closeReligion() {
    this.religionSubmitted = false;
    this.isReligionOther = false;
    this.isEditReligion = false;
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.getUserDetails();
    window.scroll(0, 630);
  }

  closeFamily() {
    this.getUserDetails();
    this.familySubmitted = false;
    this.isEditFamily = false;
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    window.scroll(0, 630);
  }

  closeAstro() {
    this.astroSubmitted = false;
    this.isEditAstro = false;
    this.countryOfBirth = this.countryOfBirthChange;
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.getUserDetails();
    window.scroll(0, 630);
  }

  closeEducation() {
    this.educationSubmitted = false;
    this.isEditEducation = false;
    this.isQualificationOther = false;
    this.isNatureOfWorkOther = false;
    this.isProfessionOther = false;
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.getUserDetails();
    window.scroll(0, 630);
  }

  closeHobbies() {
    this.hobbySubmitted = false;
    this.isEditHobbies = false;
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.getUserDetails();
    window.scroll(0, 630);
  }

  closeLocation() {
    this.locationSubmitted = false;
    this.isEditLocation = false;
    this.isResidencyOther = false;
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.getUserDetails();
    window.scroll(0, 630);
  }

  closeContactDetail() {
    this.contactSubmitted = false;
    this.isEditContactDetail = false;
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.getUserDetails();
    this.resetDays();
    window.scroll(0, 630);
  }

  // Partner
  editPartnerBasic() {
    if (this.isEditContactDetail == true) {
      this.resetContactDetail();
    }
    if (this.partnerBasicForm.value.languages == 'other') {
      this.isPartnerBasicLanguageOther = true;
    }
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .addClass('removeBorder');

    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.isEditPartnerBasic = true;
    this.isEditAboutYou = false;
    this.isEditBasic = false;
    this.isEditReligion = false;
    this.isEditAstro = false;
    this.isEditFamily = false;
    this.isEditEducation = false;
    this.isEditHobbies = false;
    this.isEditLocation = false;
    this.isEditContactDetail = false;
    this.isEditHandOverForm = false;
    window.scroll(0, 1185);
  }

  editPartnerReligion() {
    if (this.isEditContactDetail == true) {
      this.resetContactDetail();
    }
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .addClass('removeBorder');
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.isEditPartnerReligion = true;
    this.isEditAboutYou = false;
    this.isEditBasic = false;
    this.isEditReligion = false;
    this.isEditAstro = false;
    this.isEditFamily = false;
    this.isEditEducation = false;
    this.isEditHobbies = false;
    this.isEditLocation = false;
    this.isEditContactDetail = false;
    this.isEditHandOverForm = false;
    window.scroll(0, 1185);
  }

  editPartnerFamily() {
    if (this.isEditContactDetail == true) {
      this.resetContactDetail();
    }
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .addClass('removeBorder');
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');

    window.scroll(0, 1185);
    this.isEditPartnerFamily = true;

    this.isEditAboutYou = false;
    this.isEditBasic = false;
    this.isEditReligion = false;
    this.isEditAstro = false;
    this.isEditFamily = false;
    this.isEditEducation = false;
    this.isEditHobbies = false;
    this.isEditLocation = false;
    this.isEditContactDetail = false;
    this.isEditHandOverForm = false;
  }

  editPartnerEducation() {
    if (this.isEditContactDetail == true) {
      this.resetContactDetail();
    }

    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .addClass('removeBorder');
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');


    this.isEditPartnerEducation = true;

    this.isEditAboutYou = false;
    this.isEditBasic = false;
    this.isEditReligion = false;
    this.isEditAstro = false;
    this.isEditFamily = false;
    this.isEditEducation = false;
    this.isEditHobbies = false;
    this.isEditLocation = false;
    this.isEditContactDetail = false;
    this.isEditHandOverForm = false;
    this.partnerOtherEducation = false;
    window.scroll(0, 1185);
    this.onSelectPartnerProfession();
  }

  editPartnerLocation() {
    if (this.isEditContactDetail == true) {
      this.resetContactDetail();
    }

    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .addClass('removeBorder');
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');

    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.isEditPartnerLocation = true;

    this.isEditAboutYou = false;
    this.isEditBasic = false;
    this.isEditReligion = false;
    this.isEditAstro = false;
    this.isEditFamily = false;
    this.isEditEducation = false;
    this.isEditHobbies = false;
    this.isEditLocation = false;
    this.isEditContactDetail = false;
    this.isEditHandOverForm = false;
    window.scroll(0, 1185);
  }

  // close function
  closePartnerBasic() {
    window.scroll(0, 1185);
    this.isEditPartnerBasic = false;
    this.partnerBasicSubmitted = false;

    this.partnerOtherLanguage = false;
    this.partnerOtherFood = false;
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.getPartnerDetail();
  }

  closePartnerReligion() {
    window.scroll(0, 1185);
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.partnerReligionSubmitted = false;
    this.isEditPartnerReligion = false;
    this.isPartnerReligionOther = false;
    this.partnerOtherReligion = false;
    this.getPartnerDetail();
  }

  closePartnerFamily() {
    window.scroll(0, 1185);
    this.isEditPartnerFamily = false;
    this.getPartnerDetail();
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
  }

  closePartnerEducation() {
    window.scroll(0, 1185);
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.isEditPartnerEducation = false;
    this.partnerOtherEducation = false;
    this.partnerOtherNatureOfWork = false;
    this.partnerOtherProfession = false;
    this.getPartnerDetail();
  }

  closePartnerLocation() {
    window.scroll(0, 1185);
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    this.isEditPartnerLocation = false;
    this.partnerLocationSubmitted = false;
    this.partnerOtherResidencyStatus = false;
    this.getPartnerDetail();
  }

  saveAboutYou() {
    this.aboutYouSubmitted = true;
    this.aboutYouButton = true;
    let language: string = '';
    if (this.aboutYouForm.value.languages != 'other') {
      language = this.aboutYouForm.value.languages;
      this.aboutYouF.otherLanguage.setValue('other');
    } else {
      language = this.aboutYouForm.value.otherLanguage;
    }

    if (this.aboutYouForm.invalid) {
      this.aboutYouButton = false;
      return;
    }

    const aboutYouFormData = {
      user_id: this.user_id,
      age: this.aboutYouForm.value.age,
      first_name: this.aboutYouForm.value.first_name.trim(),
      last_name: this.aboutYouForm.value.last_name.trim(),
      display_name: this.aboutYouForm.value.display_name,
      height: this.aboutYouForm.value.height,
      marital_status: this.aboutYouForm.value.marital_status,
      weight: this.aboutYouForm.value.weight,
      have_children: this.aboutYouForm.value.have_children,
      boy_child: this.aboutYouForm.value.boy_child,
      boy_child_not_married: this.aboutYouForm.value.boy_child_not_married,
      boy_child_married: this.aboutYouForm.value.boy_child_married,
      girl_child: this.aboutYouForm.value.girl_child,
      girl_child_not_married: this.aboutYouForm.value.girl_child_not_married,
      girl_child_married: this.aboutYouForm.value.girl_child_married,
      languages: language ? language.trim() : '',
      description: this.aboutYouForm.value.description
        ? this.aboutYouForm.value.description.trim()
        : '',
      current_city: this.aboutYouForm.value.current_city.trim(),
      state_name: this.aboutYouForm.value.state_name,
      living_in_country: this.aboutYouForm.value.living_in_country,
      myinfo_section: 'about_you',
    };

    this.myProfileService.setAboutYou(aboutYouFormData).subscribe(
      (result) => {
        this.closeAboutYou();
        this.getUser();
        this.aboutYouSubmitted = false;
        this.aboutYouButton = false;
        this.isAboutYouLanguageOther = false;
        this.toastrService.success('Successfully!', 'About You Update!');
      },
      (error) => {
        this.aboutYouSubmitted = false;
        this.aboutYouButton = false;
        this.toastrService.error('Message!', 'About You Not Update!');
      }
    );
    window.scroll(0, 630);

  }

  saveBasic() {
    this.basicAndLifestyleSubmitted = true;
    this.basicAndLifestyleButton = true;
    if (
      this.basicAndLifestyleForm.value.year &&
      this.basicAndLifestyleForm.value.month &&
      this.basicAndLifestyleForm.value.day
    ) {
      this.basicAndLifestyleF.dob.setValue(
        this.basicAndLifestyleForm.value.year +
        '-' +
        this.basicAndLifestyleForm.value.month +
        '-' +
        this.basicAndLifestyleForm.value.day
      );
    }
    let bSF: string = '';
    if (this.basicAndLifestyleForm.value.food_preference != 'Other') {
      this.basicAndLifestyleF.basicOtherFood.setValue('Other');
      bSF = this.basicAndLifestyleForm.value.food_preference;
    } else {
      bSF = this.basicAndLifestyleForm.value.basicOtherFood;
    }

    if (this.basicAndLifestyleForm.invalid) {
      this.basicAndLifestyleButton = false;
      return;
    }
    const basicAndLifestyleFormData = {
      user_id: this.user_id,
      dob: this.basicAndLifestyleForm.value.dob,
      food_preference: bSF,
      grew_up_in: this.basicAndLifestyleForm.value.grew_up_in,
      personal_values: this.basicAndLifestyleForm.value.personal_values
        ? this.basicAndLifestyleForm.value.personal_values.trim()
        : '',
      do_smoke: this.basicAndLifestyleForm.value.do_smoke,
      do_drink: this.basicAndLifestyleForm.value.do_drink,
      have_pet: this.basicAndLifestyleForm.value.have_pet,
      sun_sign: this.basicAndLifestyleForm.value.sun_sign,
      blood_group: this.basicAndLifestyleForm.value.blood_group,
      health_info: this.basicAndLifestyleForm.value.health_info
        ? this.basicAndLifestyleForm.value.health_info.trim()
        : '',
      disability: this.basicAndLifestyleForm.value.disability
        ? this.basicAndLifestyleForm.value.disability.trim()
        : '',
      myinfo_section: 'basics_lifestyle',
    };
    //console.log("saveBasic-> payload",frmdata)
    this.myProfileService
      .setBasicAndLifestyle(basicAndLifestyleFormData)
      .subscribe(
        (result) => {
          this.closeBasicLifeStyle();
          this.basicAndLifestyleSubmitted = false;
          this.basicAndLifestyleButton = false;
          this.toastrService.success(
            'Successfully!',
            'Basics & Lifestyle Update!'
          );
        },
        (error) => {
          this.basicAndLifestyleSubmitted = false;
          this.basicAndLifestyleButton = false;
          this.toastrService.error(
            'Message!',
            'Basics & Lifestyle Not Update!'
          );
        }
      );
    window.scroll(0, 630);
  }

  saveReligion() {
    this.religionSubmitted = true;
    this.religionButton = true;
    let other_religion: string = '';
    if (this.religionForm.value.religion != 'Other') {
      other_religion = this.religionForm.value.religion;
      this.religionF.otherReligion.setValue('Other');
    } else {
      other_religion = this.religionForm.value.otherReligion;
    }
    if (this.religionForm.invalid) {
      this.religionButton = false;
      return;
    }
    const religiondata = {
      user_id: this.user_id,
      religion: other_religion,
      community: this.religionForm.value.community
        ? this.religionForm.value.community.trim()
        : '',
      caste: this.religionForm.value.caste
        ? this.religionForm.value.caste.trim()
        : '',
      gothra: this.religionForm.value.gothra
        ? this.religionForm.value.gothra.trim()
        : '',
      myinfo_section: 'religious_views',
    };
    //// console.log("saveReligion11-> payload",religiondata)

    this.myProfileService.setReligionView(religiondata).subscribe(
      (result: any) => {
        this.religionSubmitted = false;
        this.religionButton = false;
        this.closeReligion();
        this.toastrService.success('Successfully!', 'Religious Views Update!');
      },
      (error) => {
        this.religionSubmitted = false;
        this.religionButton = false;
        this.toastrService.error('Message!', 'Religious Views Not Update!');
      }
    );
    window.scroll(0, 630);
  }

  saveAstro() {
    this.astroSubmitted = true;
    this.astroButton = true;

    if (
      this.astroForm.value.year &&
      this.astroForm.value.month &&
      this.astroForm.value.day
    ) {
      this.astroF.dob.setValue(
        this.astroForm.value.year +
        '-' +
        this.astroForm.value.month +
        '-' +
        this.astroForm.value.day
      );
    }
    if (
      this.astroForm.value.hour &&
      this.astroForm.value.minute &&
      this.astroForm.value.am_pm
    ) {
      this.astroF.time_of_birth.setValue(
        this.astroForm.value.hour +
        ':' +
        this.astroForm.value.minute +
        ' ' +
        this.astroForm.value.am_pm
      );
    }
    if (this.astroForm.invalid) {
      this.astroButton = false;
      return;
    }
    const astrodata = {
      user_id: this.user_id,
      manglik: this.astroForm.value.manglik
        ? this.astroForm.value.manglik.trim()
        : '',
      city_of_birth: this.astroForm.value.city_of_birth
        ? this.astroForm.value.city_of_birth.trim()
        : '',
      dob: this.astroForm.value.dob,
      time_of_birth: this.astroForm.value.time_of_birth,
      country_of_birth: this.astroForm.value.country_of_birth,
      myinfo_section: 'astro_details',
    };
    this.myProfileService.setAstroDetails(astrodata).subscribe(
      (result: any) => {
        this.closeAstro();
        this.astroSubmitted = false;
        this.astroButton = false;
        this.toastrService.success('Successfully!', 'Astro Details Update!');
      },
      (error) => {
        this.astroSubmitted = false;
        this.astroButton = false;
        this.toastrService.error('Message!', 'Astro Details Not Update!');
      }
    );
    window.scroll(0, 630);
  }

  saveFamily() {
    this.familySubmitted = true;
    this.familyButton = true;

    if (this.familyDetailForm.invalid) {
      this.familyButton = false;
      return;
    }
    const familydata = {
      user_id: this.user_id,
      father_status: this.familyDetailForm.value.father_status
        ? this.familyDetailForm.value.father_status.trim()
        : '',
      mother_status: this.familyDetailForm.value.mother_status
        ? this.familyDetailForm.value.mother_status.trim()
        : '',
      no_of_brother: this.familyDetailForm.value.no_of_brother,
      no_of_sister: this.familyDetailForm.value.no_of_sister,
      family_living_in_country:
        this.familyDetailForm.value.family_living_in_country,
      family_state_name: this.familyDetailForm.value.family_state_name,
      family_current_city: this.familyDetailForm.value.family_current_city
        ? this.familyDetailForm.value.family_current_city.trim()
        : '',
      family_zip_code: this.familyDetailForm.value.family_zip_code,
      family_type: this.familyDetailForm.value.family_type,
      family_values: this.familyDetailForm.value.family_values
        ? this.familyDetailForm.value.family_values.trim()
        : '',
      native_place: this.familyDetailForm.value.native_place
        ? this.familyDetailForm.value.native_place.trim()
        : '',
      family_affluence: this.familyDetailForm.value.family_affluence
        ? this.familyDetailForm.value.family_affluence.trim()
        : '',
      living_with_family: this.familyDetailForm.value.living_with_family,
      myinfo_section: 'family_details',
    };
    // // console.log("familydetail-> payload",frmdata)
    this.myProfileService.setFamilyDetails(familydata).subscribe(
      (result: any) => {
        //this.partnerDetail=x.userDetails[0]
        // // console.log(response)
        this.familySubmitted = false;
        this.familyButton = false;
        this.closeFamily();
        this.toastrService.success('Successfully!', 'Family Details Update!');
      },
      (error) => {
        this.familySubmitted = false;
        this.familyButton = false;
        this.toastrService.error('Message!', 'Family Details Not Update!');
      }
    );
    window.scroll(0, 630);
  }

  saveEducation() {
    this.educationSubmitted = true;

    let other_Profession: string = '';
    if (this.educationForm.value.working_as != 'Other') {
      other_Profession = this.educationForm.value.working_as;
      this.educationF.otherProfession.setValue('Other');
    } else {
      other_Profession = this.educationForm.value.otherProfession;
    }
    //nature of work
    let other_work: string = '';
    if (this.educationForm.value.nature_of_work != 'Other') {
      other_work = this.educationForm.value.nature_of_work;
      this.educationF.otherNatureOfWork.setValue('Other');
    } else {
      other_work = this.educationForm.value.otherNatureOfWork;
    }
    // education
    let other_qualification: string = '';
    if (this.educationForm.value.qualification != 'Other') {
      other_qualification = this.educationForm.value.qualification;
      this.educationF.otherQualification.setValue('Other');
    } else {
      other_qualification = this.educationForm.value.otherQualification;
    }

    if (this.educationForm.invalid) {
      this.educationButton = false;
      return;
    }

    if (!this.educationForm.value.income_currency) {
      this.educationForm.value.annual_income = '';
      this.CurrencyCountryName = '';
    }

    const educationdata = {
      user_id: this.user_id,
      qualification: other_qualification,
      college_name: this.educationForm.value.college_name
        ? this.educationForm.value.college_name.trim()
        : '',
      annual_income: this.educationForm.value.annual_income,
      income_currency: this.educationForm.value.income_currency,
      working_with: this.educationForm.value.working_with,
      nature_of_work: other_work,
      company_name: this.educationForm.value.company_name
        ? this.educationForm.value.company_name.trim()
        : '',
      working_as: other_Profession?.trim(),
      myinfo_section: 'education_professional_details',
    };
    this.myProfileService.setEducationDetailsUrl(educationdata).subscribe(
      (result) => {
        this.educationButton = true;
        this.educationSubmitted = false;
        this.educationButton = false;
        this.closeEducation();
        this.toastrService.success(
          'Successfully!',
          'Education & Professional Details Update!'
        );
      },

      (error) => {
        this.educationSubmitted = false;
        this.educationButton = false;
        this.toastrService.error(
          'Message!',
          'Education & Professional Details Not Update!'
        );
      }
    );
    window.scroll(0, 630);
  }

  saveHobbies() {
    this.hobbySubmitted = true;
    this.hobbyButton = true;

    if (this.hobbyForm.invalid) {
      this.hobbyButton = false;
      return;
    }
    const hobbydata = {
      user_id: this.user_id,
      hobbies: this.hobbyForm.value.hobbies
        ? this.hobbyForm.value.hobbies.trim()
        : '',
      interests: this.hobbyForm.value.interests
        ? this.hobbyForm.value.interests.trim()
        : '',
      preferred_movie: this.hobbyForm.value.preferred_movie
        ? this.hobbyForm.value.preferred_movie.trim()
        : '',
      favorite_music: this.hobbyForm.value.favorite_music
        ? this.hobbyForm.value.favorite_music.trim()
        : '',
      sports_fitness: this.hobbyForm.value.sports_fitness
        ? this.hobbyForm.value.sports_fitness.trim()
        : '',
      favorite_cuisine: this.hobbyForm.value.favorite_cuisine
        ? this.hobbyForm.value.favorite_cuisine.trim()
        : '',
      preferred_dress_style: this.hobbyForm.value.preferred_dress_style
        ? this.hobbyForm.value.preferred_dress_style.trim()
        : '',
      myinfo_section: 'hobbies_interests_more',
    };
    this.myProfileService.setHobbiesDetails(hobbydata).subscribe(
      (result: any) => {
        this.hobbySubmitted = false;
        this.hobbyButton = false;
        this.closeHobbies();
        this.toastrService.success(
          'Successfully!',
          'Hobbies, Interests & more  Update!'
        );
      },
      (error) => {
        this.hobbySubmitted = false;
        this.hobbyButton = false;
        this.toastrService.error(
          'Message!',
          'Hobbies, Interests & more Not Update!'
        );
      }
    );
    window.scroll(0, 630);
  }

  checkCountry() {
    console.log(this.locationForm.value.living_in_country, "country")
    if (this.locationForm.value.living_in_country == "" || this.locationForm.value.living_in_country == null) {
      this.countryRequired = true;
    } else {
      this.countryRequired = false;
    }
  }

  saveLocation() {
    this.locationSubmitted = true;
    this.locationButton = true;
    if (this.locationForm.value.residency_status != 'Other') {
      var other_residency = this.locationForm.value.residency_status;
      this.locationF.otherResidency.setValue('Other');
    } else {
      other_residency = this.locationForm.value.otherResidency;
    }
    this.checkCountry()
    if (this.locationForm.invalid || this.countryRequired == true) {
      this.locationButton = false;
      return;
    }
    const locationdata = {
      user_id: this.user_id,
      current_residence: this.locationForm.value.current_residence
        ? this.locationForm.value.current_residence.trim() : '',
      living_in_country: this.locationForm.value.living_in_country,
      state_name: this.locationForm.value.state_name,
      current_city: this.locationForm.value.current_city
        ? this.locationForm.value.current_city.trim() : '',
      residency_status: other_residency,
      postal_code: this.locationForm.value.postal_code
        ? this.locationForm.value.postal_code.trim() : '',
      myinfo_section: 'your_location',
    };
    this.myProfileService.setLocationDetails(locationdata).subscribe(
      (result: any) => {
        this.locationSubmitted = false;
        this.locationButton = false;
        this.closeLocation();
        this.toastrService.success('Successfully!', 'Your Location Update!');
      },
      (error) => {
        this.locationSubmitted = false;
        this.locationButton = false;
        this.toastrService.error('Message!', 'Your Location Not Update!');
      }
    );
    window.scroll(0, 630);
  }

  timeZones() {
    if (this.contactForm.value.monday_time_zone != '') {
      this.timeZone.push(this.contactForm.value.monday_time_zone);
    } else if (this.contactForm.value.tuesday_time_zone != '') {
      this.timeZone.push(this.contactForm.value.tuesday_time_zone);
    } else if (this.contactForm.value.wednesday_time_zone != '') {
      this.timeZone.push(this.contactForm.value.wednesday_time_zone);
    } else if (this.contactForm.value.thursday_time_zone != '') {
      this.timeZone.push(this.contactForm.value.thursday_time_zone);
    } else if (this.contactForm.value.friday_time_zone != '') {
      this.timeZone.push(this.contactForm.value.friday_time_zone);
    } else if (this.contactForm.value.saturday_time_zone != '') {
      this.timeZone.push(this.contactForm.value.saturday_time_zone);
    } else if (this.contactForm.value.sunday_time_zone != '') {
      this.timeZone.push(this.contactForm.value.sunday_time_zone);
    }
  }

  checkTimeRangeMonday() {
    if (this.selectedTimeMondayFrom && this.selectedTimeMondayTo) {
      let timefrom = this.selectedTimeMondayFrom.split(':');
      let Ap = timefrom[1].split(' ')
      let timeto = this.selectedTimeMondayTo.split(':');
      let Ap1 = timeto[1].split(' ')
      if (Ap[1] == "PM" && Ap1[1] == "PM") {
        if (parseInt(timefrom[0]) != 12 && parseInt(timeto[0]) >= 12) {
          this.selectedTimeMondayTo = null;
        } else {
          if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
            this.selectedTimeMondayTo = null;
          }
        }
      } else if ((Ap[1] == "AM" && Ap1[1] == "PM") || (Ap[1] == "PM" && Ap1[1] == "AM")) {
        // document why this block is empty
      } else if (Ap[1] == Ap1[1]) {
        if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
          this.selectedTimeMondayTo = "";
        } else {
          //  console.log("valid time ",typeof(timefrom[0]) ,typeof(timeto[0]) )
        }
      }
    }
    let index = this.getTimeIntervalIndex(this.selectedTimeMondayFrom);
    this.mondayTimeTo = this.makeToArray(index);
  }

  checkTimeRangeTuesday() {
    if (this.selectedTimeTuesdayFrom && this.selectedTimeTuesdayTo) {
      let timefrom = this.selectedTimeTuesdayFrom.split(':');
      let Ap = timefrom[1].split(' ')
      let timeto = this.selectedTimeTuesdayTo.split(':');
      let Ap1 = timeto[1].split(' ')
      if (Ap[1] == "PM" && Ap1[1] == "PM") {
        if (parseInt(timefrom[0]) != 12 && parseInt(timeto[0]) >= 12) {
          this.selectedTimeTuesdayTo = null;
        } else {
          if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
            this.selectedTimeTuesdayTo = null;
          }
        }
      } else if ((Ap[1] == "AM" && Ap1[1] == "PM") || (Ap[1] == "PM" && Ap1[1] == "AM")) {
        // document why this block is empty
      } else if (Ap[1] == Ap1[1]) {
        if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
          this.selectedTimeTuesdayTo = "";
        } else {
          //  console.log("valid time ",typeof(timefrom[0]) ,typeof(timeto[0]) )
        }
      }
    }
    let index = this.getTimeIntervalIndex(this.selectedTimeTuesdayFrom);
    this.tuesdayTimeTo = this.makeToArray(index);
  }

  checkTimeRangeWednesday() {
    if (this.selectedTimeWednesdayFrom && this.selectedTimeWednesdayTo) {
      let timefrom = this.selectedTimeWednesdayFrom.split(':');
      let Ap = timefrom[1].split(' ')
      let timeto = this.selectedTimeWednesdayTo.split(':');
      let Ap1 = timeto[1].split(' ')

      if (Ap[1] == "PM" && Ap1[1] == "PM") {
        if (parseInt(timefrom[0]) != 12 && parseInt(timeto[0]) >= 12) {
          this.selectedTimeWednesdayTo = null;
        } else {
          if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
            this.selectedTimeWednesdayTo = null;
          }
        }
      } else if ((Ap[1] == "AM" && Ap1[1] == "PM") || (Ap[1] == "PM" && Ap1[1] == "AM")) {
        // document why this block is empty
      } else if (Ap[1] == Ap1[1]) {
        if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
          this.selectedTimeWednesdayTo = "";
        } else {
          //  console.log("valid time ",typeof(timefrom[0]) ,typeof(timeto[0]) )
        }
      }
    }
    let index = this.getTimeIntervalIndex(this.selectedTimeWednesdayFrom);
    this.wednesdayTimeTo = this.makeToArray(index);
  }

  checkTimeRangeThursday() {
    if (this.selectedTimeThursdayFrom && this.selectedTimeThursdayTo) {
      let timefrom = this.selectedTimeThursdayFrom.split(':');
      let Ap = timefrom[1].split(' ')
      let timeto = this.selectedTimeThursdayTo.split(':');
      let Ap1 = timeto[1].split(' ')
      if (Ap[1] == "PM" && Ap1[1] == "PM") {
        if (parseInt(timefrom[0]) != 12 && parseInt(timeto[0]) >= 12) {
          this.selectedTimeThursdayTo = null;
        } else {
          if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
            this.selectedTimeThursdayTo = null;
          }
        }
      } else if ((Ap[1] == "AM" && Ap1[1] == "PM") || (Ap[1] == "PM" && Ap1[1] == "AM")) {
        // document why this block is empty
      } else if (Ap[1] == Ap1[1]) {
        if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
          this.selectedTimeThursdayTo = "";
        } else {
          //  console.log("valid time ",typeof(timefrom[0]) ,typeof(timeto[0]) )
        }
      }
    }
    let index = this.getTimeIntervalIndex(this.selectedTimeThursdayFrom);
    this.thursdayTimeTo = this.makeToArray(index);
  }

  checkTimeRangeFriday() {
    if (this.selectedTimeFridayFrom && this.selectedTimeFridayTo) {
      let timefrom = this.selectedTimeFridayFrom.split(':');
      let Ap = timefrom[1].split(' ')
      let timeto = this.selectedTimeFridayTo.split(':');
      let Ap1 = timeto[1].split(' ')
      if (Ap[1] == "PM" && Ap1[1] == "PM") {
        if (parseInt(timefrom[0]) != 12 && parseInt(timeto[0]) >= 12) {
          this.selectedTimeFridayTo = null;
        } else {
          if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
            this.selectedTimeFridayTo = null;
          }
        }
      } else if ((Ap[1] == "AM" && Ap1[1] == "PM") || (Ap[1] == "PM" && Ap1[1] == "AM")) {
        // document why this block is empty
      } else if (Ap[1] == Ap1[1]) {
        if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
          this.selectedTimeFridayTo = "";
        } else {
          //  console.log("valid time ",typeof(timefrom[0]) ,typeof(timeto[0]) )
        }
      }
    }
    let index = this.getTimeIntervalIndex(this.selectedTimeFridayFrom);
    this.fridayTimeTo = this.makeToArray(index);
  }

  checkTimeRangeSaturday() {
    if (this.selectedTimeSaturdayFrom && this.selectedTimeSaturdayTo) {
      let timefrom = this.selectedTimeSaturdayFrom.split(':');
      let Ap = timefrom[1].split(' ')
      let timeto = this.selectedTimeSaturdayTo.split(':');
      let Ap1 = timeto[1].split(' ')
      if (Ap[1] == "PM" && Ap1[1] == "PM") {
        if (parseInt(timefrom[0]) != 12 && parseInt(timeto[0]) >= 12) {
          this.selectedTimeSaturdayTo = null;
        } else {
          if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
            this.selectedTimeSaturdayTo = null;
          }
        }
      } else if ((Ap[1] == "AM" && Ap1[1] == "PM") || (Ap[1] == "PM" && Ap1[1] == "AM")) {
        // document why this block is empty
      } else if (Ap[1] == Ap1[1]) {
        if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
          this.selectedTimeSaturdayTo = "";
        } else {
          //  console.log("valid time ",typeof(timefrom[0]) ,typeof(timeto[0]) )
        }
      }
    }
    let index = this.getTimeIntervalIndex(this.selectedTimeSaturdayFrom);
    this.saturdayTimeTo = this.makeToArray(index);
  }

  checkTimeRangeSunday() {
    if (this.selectedTimeSundayFrom && this.selectedTimeSundayTo) {
      let timefrom = this.selectedTimeSundayFrom.split(':');
      let Ap = timefrom[1].split(' ')
      let timeto = this.selectedTimeSundayTo.split(':');
      let Ap1 = timeto[1].split(' ')
      if (Ap[1] == "PM" && Ap1[1] == "PM") {
        if (parseInt(timefrom[0]) != 12 && parseInt(timeto[0]) >= 12) {
          this.selectedTimeSundayTo = null;
        } else {
          if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
            this.selectedTimeSundayTo = null;
          }
        }
      } else if ((Ap[1] == "AM" && Ap1[1] == "PM") || (Ap[1] == "PM" && Ap1[1] == "AM")) {
        if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
          this.selectedTimeSundayTo = null;
        } else {
          //  console.log("valid time ",typeof(timefrom[0]) ,typeof(timeto[0]) )
        }
      } else if (Ap[1] == Ap1[1]) {
        if (parseInt(timefrom[0]) > parseInt(timeto[0]) || (parseInt(timefrom[0]) == parseInt(timeto[0]) && parseInt(Ap[0]) >= parseInt(Ap1[0]))) {
          this.selectedTimeSundayTo = null;
          console.log(this.selectedTimeSundayTo);
        } else {
          //  console.log("valid time ",typeof(timefrom[0]) ,typeof(timeto[0]) )
        }
      }
    }
    let index = this.getTimeIntervalIndex(this.selectedTimeSundayFrom);
    this.sundayTimeTo = this.makeToArray(index);
  }

  getTimeIntervalIndex(value) {
    let index = 1;
    this.timeIntervals30.forEach(element => {
      if (element.value == value) {
        index = parseInt(element.id);
        return index;
      }
    });
    return index;
  }

  makeToArray(index) {
    let toarray: any = [];
    for (let i = index + 1; i <= 48; i++) {
      let j = 0;
      if (i > 2 && i % 2 == 0) {
        j = (i / 2 - 1);
      } else if (i > 2 && i % 2 != 0) {
        j = (i / 2 - 0.5);
      }
      if (j >= 13) {
        j = j - 12;
      }
      let k = '' + j;
      if (i <= 20 || (i <= 44 && i >= 27)) {
        k = '0' + j;
      }
      let ampm = 'AM';
      if (i > 24) {
        ampm = 'PM';
      }

      if (i % 2 == 0) {
        toarray.push({
          id: '' + i,
          value: k + ':30 ' + ampm
        })
      } else {
        toarray.push({
          id: '' + i,
          value: k + ':00 ' + ampm
        })
      }
    }
    return toarray;
  }
  saveContactDetail() {
    // console.log("hello 11")
    this.contactSubmitted = true;
    this.contactButton = true;

    if (this.contactForm.invalid) {
      this.contactButton = false;
      return;
    }

    //time to call

    let mon =
      'Monday' +
      ' - ' +
      this.contactForm.value.monday_hour_from +
      ' - ' +
      this.contactForm.value.monday_hour_to +
      ' - ' +
      this.contactForm.value.day_time_zone;
    let tue =
      'Tuesday' +
      ' - ' +
      this.contactForm.value.tuesday_hour_from +
      ' - ' +
      this.contactForm.value.tuesday_hour_to +
      ' - ' +
      this.contactForm.value.day_time_zone;
    let wed =
      'Wednesday' +
      ' - ' +
      this.contactForm.value.wednesday_hour_from +
      ' - ' +
      this.contactForm.value.wednesday_hour_to +
      ' - ' +
      this.contactForm.value.day_time_zone;
    let thurs =
      'Thursday' +
      ' - ' +
      this.contactForm.value.thursday_hour_from +
      ' - ' +
      this.contactForm.value.thursday_hour_to +
      ' - ' +
      this.contactForm.value.day_time_zone;
    let fri =
      'Friday' +
      ' - ' +
      this.contactForm.value.friday_hour_from +
      ' - ' +
      this.contactForm.value.friday_hour_to +
      ' - ' +
      this.contactForm.value.day_time_zone;
    let satur =
      'Saturday' +
      ' - ' +
      this.contactForm.value.saturday_hour_from +
      ' - ' +
      this.contactForm.value.saturday_hour_to +
      ' - ' +
      this.contactForm.value.day_time_zone;
    let sun =
      'Sunday' +
      ' - ' +
      this.contactForm.value.sunday_hour_from +
      ' - ' +
      this.contactForm.value.sunday_hour_to +
      ' - ' +
      this.contactForm.value.day_time_zone;

    // sorting 
    let dt = this.days
    let newDaysArray = dt.filter((item, index) => dt.indexOf(item) === index);

    // order by days 
    if (this.days.length != 0) {
      newDaysArray.sort((a, b) => {
        const daysOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return daysOrder.indexOf(a) - daysOrder.indexOf(b);
      });
    }

    let datadays = newDaysArray.toString();
    let daysArr = datadays.split(',');
    this.timetocall = [];

    daysArr.forEach((element: any) => {
      if (element != '') {
        if ((element == 'Sunday') && (this.contactForm.value.sunday_hour_from && this.contactForm.value.sunday_hour_to && this.contactForm.value.day_time_zone)) {
          this.timetocall.push(sun);
        } else if ((element == 'Monday') && (this.contactForm.value.monday_hour_from && this.contactForm.value.monday_hour_to && this.contactForm.value.day_time_zone)) {
          this.timetocall.push(mon);
        } else if ((element == 'Tuesday') && (this.contactForm.value.tuesday_hour_from && this.contactForm.value.tuesday_hour_to && this.contactForm.value.day_time_zone)) {
          this.timetocall.push(tue);
        } else if ((element == 'Wednesday') && (this.contactForm.value.wednesday_hour_from && this.contactForm.value.wednesday_hour_to && this.contactForm.value.day_time_zone)) {
          this.timetocall.push(wed);
        } else if ((element == 'Thursday') && (this.contactForm.value.thursday_hour_from && this.contactForm.value.thursday_hour_to && this.contactForm.value.day_time_zone)) {
          this.timetocall.push(thurs);
        } else if ((element == 'Friday') && (this.contactForm.value.friday_hour_from && this.contactForm.value.friday_hour_to && this.contactForm.value.day_time_zone)) {
          this.timetocall.push(fri);
        } else if ((element == 'Saturday') && (this.contactForm.value.saturday_hour_from && this.contactForm.value.saturday_hour_to && this.contactForm.value.day_time_zone)) {
          this.timetocall.push(satur);
        } else if (element == 'Any-Time' || element == 'Anytime') {
          this.timetocall = [];
          this.timetocall.push('Any-Time');
        }
      }
    });

    // remove repeatation
    let a = this.timetocall;
    let b = a.reduce(function (p, c, i, a) {
      if (p.indexOf(c) == -1) p.push(c);
      // else p.push('');
      return p;
    }, []);

    const contactdata = {
      user_id: this.user_id,
      convenient_time_to_call: b.toString(),
      firstName: this.contactForm.value.firstName,
      lastName: this.contactForm.value.lastName,
      myinfo_section: 'contact_details',
    };
    // time to call
    if (b.toString() != '') {
      this.myProfileService.setContactDetails(contactdata).subscribe(
        (response: any) => {
          this.contactSubmitted = false;
          this.contactButton = false;
          this.contactForm.reset();
          this.closeContactDetail();
          this.toastrService.success('Successfully!', 'Contact Details Update!');
          this.getUser();
          this.getUserDetails();
        },
        (error) => {
          this.contactSubmitted = false;
          this.contactButton = false;
          this.toastrService.error('Message!', 'Contact Details Not Update!');
        }
      );
      window.scroll(0, 630);
    } else {
      this.contactButton = false;
      this.contactSubmitted = true;
      this.toastrService.error('Message!', 'Please Update Convenient Time To Call!');
    }
    console.log("saveContactDetail -> payload ", contactdata, this.contactForm.value);
  }
  confirmHandover() {
    $('#openModal12').click();
  }

  saveHandOver() {
    const handOverData = {
      user_id: this.user_id,
    };
    this.spinner.show();
    this.myProfileService.setHandoverDetails(handOverData).subscribe(
      (response: any) => {

        if (response.status) {
          this.toastrService.success(response.message);
          this.spinner.hide();
          let data = {
            id: this.user_id
          }
          this.auth.logout(data)
            .subscribe((res: any) => {
              console.log('logout by api')
              this.spinner.hide();
            });
          this.auth.setAuthState(false);
          this.token.removeToken();
          console.log('this.cookieService.get', this.cookieService.get('auth_token'))
          this.cookieService.delete('auth_token');
          this.delete_cookie('auth_token');
          this.router.navigate(['home']);
          this.spinner.hide();
        } else if (response.message) {
          this.toastrService.error(response.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.closeHandOver();
        this.spinner.hide();
        this.toastrService.error('Message!', 'Hanover Details Not Update!');
      }
    );
  }

  delete_cookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  spliceBasic() {
    this.selectedlanguages.forEach((element, index) => {
      if (element == 'Other') {
        this.selectedlanguages.splice(index, 1);
      }
    });
    this.selectedFoodPreference.forEach((element, index) => {
      if (element == 'Other') this.selectedFoodPreference.splice(index, 1);
    });
    window.scroll(0, 500);
  }
  // partner save data
  savePartnerBasic() {
    this.partnerBasicSubmitted = true;
    this.partnerBasicButton = true;

    // other language
    let partnerLanguageSelected = this.selectedlanguages.toString();
    if (this.selectedlanguages.length > 1) {
      this.partnerLanguageOtherValue = false;

      let langArr = partnerLanguageSelected.split(',');
      langArr.forEach((element: any) => {
        if (element != '') {
          if (element == 'Other') {
            this.partnerOtherLanguage = true;
            if (this.partnerBasicForm.value.otherLanguage != "") {
              this.selectedlanguages.push(
                this.partnerBasicForm.value.otherLanguage
              );
            } else {
              this.partnerBasicButton = false;
              return;
            }
          }
        }
      });
    } else {
      if (partnerLanguageSelected == 'Other') {
        this.partnerOtherLanguage = true;
        if (this.partnerBasicForm.value.otherLanguage != "") {
          this.partnerLanguageOtherValue = false;
          this.selectedlanguages.push(
            this.partnerBasicForm.value.otherLanguage
          );
        } else {
          this.partnerLanguageOtherValue = true;
          this.partnerBasicButton = false;
          return;
        }
      }
    }

    // other food

    let partnerFoodSelected = this.selectedFoodPreference.toString();
    if (this.selectedFoodPreference.length > 1) {
      this.partnerFoodOtherValue = false;

      let foodArr = partnerFoodSelected.split(',');
      foodArr.forEach((element: any) => {
        if (element != '') {
          if (element == 'Other') {
            this.partnerOtherFood = true;
            if (this.partnerBasicForm.value.otherFood != "") {
              this.selectedFoodPreference.push(
                this.partnerBasicForm.value.otherFood
              );
            } else {
              this.partnerBasicButton = false;
              return;
            }
          }
        }
      });
    } else {
      if (partnerFoodSelected == 'Other') {
        this.partnerOtherFood = true;
        if (this.partnerBasicForm.value.otherFood != "") {
          this.partnerFoodOtherValue = false;
          this.selectedFoodPreference.push(
            this.partnerBasicForm.value.otherFood
          );
        } else {
          this.partnerFoodOtherValue = true;
          this.partnerBasicButton = false;
          return;
        }
      }
    }

    if (this.partnerBasicForm.invalid) {
      this.partnerBasicButton = false;
      return;
    }
    this.spliceBasic();

    const languagesArr = this.selectedlanguages.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item];
    }, []);
    const marArr = this.selectedMaritalStatus.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item];
    }, []);
    const foodArr1 = this.selectedFoodPreference.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item];
    }, []);
    const frmdata = {
      user_id: this.user_id,
      age_from: this.partnerBasicForm.value.age_from,
      height_from: this.partnerBasicForm.value.height_from,
      height_to: this.partnerBasicForm.value.height_to,
      weight_from: this.partnerBasicForm.value.weight_from,
      weight_to: this.partnerBasicForm.value.weight_to,
      age_to: this.partnerBasicForm.value.age_to,
      do_smoke: this.partnerBasicForm.value.do_smoke,
      do_drink: this.partnerBasicForm.value.do_drink,
      have_pet: this.partnerBasicForm.value.have_pet,
      description: this.partnerBasicForm.value.description,
      languages: languagesArr.toString(),
      food_preference: foodArr1.toString(),
      marital_status: marArr.toString(),
      have_children: this.partnerBasicForm.value.have_children,
      manglik: this.partnerBasicForm.value.manglik,
    };

    this.myProfileService.setPartnerBasicAndLifestyle(frmdata).subscribe(
      (response: any) => {
        if (response.status) {
          this.partnerBasicSubmitted = false;
          this.partnerBasicButton = false;
          this.partnerOtherLanguage = false;
          this.partnerOtherFood = false;
          this.closePartnerBasic();
          this.toastrService.success(
            'Successfully!',
            'Partner Basics and Lifestyle Update!'
          );
          window.scroll(0, 1185);
        } else {
          this.toastrService.error(
            'Message!', 'Partner Lifestyle Not Update!'
          );
          this.partnerBasicSubmitted = false;
          this.partnerBasicButton = false;
        }
      },
      (error) => {
        this.partnerBasicSubmitted = false;
        this.partnerBasicButton = false;
        this.toastrService.error('Message!', 'Partner Lifestyle Not Update!');
      }
    );
    window.scroll(0, 1185);
  }

  spliceReligion() {
    this.selectedReligion.forEach((element, index) => {
      if (element == 'Other') this.selectedReligion.splice(index, 1);
    });
  }

  savePartnerReligion() {
    this.partnerReligionSubmitted = true;
    let partnerReligionSelected = this.selectedReligion.toString();
    if (this.selectedReligion.length > 1) {
      this.partnerReligionOtherValue = false
      let religionArr = partnerReligionSelected.split(',');
      religionArr.forEach((element: any) => {
        if (element != '') {
          if (element == 'Other') {
            this.partnerOtherReligion = true;
            if (this.partnerReligionForm.value.otherReligion != "") {

              this.selectedReligion.push(
                this.partnerReligionForm.value.otherReligion
              );
            } else {
              return;
            }
          }
        }
      });
    } else {
      if (partnerReligionSelected == 'Other') {
        this.partnerOtherReligion = true;
        if (this.partnerReligionForm.value.otherReligion != "") {
          this.partnerReligionOtherValue = false
          this.selectedReligion.push(
            this.partnerReligionForm.value.otherReligion
          );
        } else {
          this.partnerReligionOtherValue = true
          return;
        }
      }
    }
    this.spliceReligion();
    const relisnArr = this.selectedReligion.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item];
    }, []);
    if (this.partnerReligionForm.invalid) {
      return;
    } else {
      this.partnerReligionButton = true;
    }
    console.log(relisnArr)
    const relArr1 = this.selectedReligion.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item];
    }, []);
    const partnerReligiondata = {
      user_id: this.user_id,

      religion: relArr1.toString(),
      community: this.partnerReligionForm.value.community
        ? this.partnerReligionForm.value.community.trim()
        : '',
      caste: this.partnerReligionForm.value.caste
        ? this.partnerReligionForm.value.caste.trim()
        : '',
      gothra: this.partnerReligionForm.value.gothra
        ? this.partnerReligionForm.value.gothra.trim()
        : '',
    };
    this.myProfileService.setPartnerReligionView(partnerReligiondata).subscribe(
      (response: any) => {
        if (response.status) {
          this.partnerReligionSubmitted = false;
          this.partnerReligionButton = false;
          this.partnerReligionOtherValue = false;
          this.partnerOtherReligion = false;
          this.closePartnerReligion();
          this.toastrService.success(
            'Successfully!',
            'Partner Religious Views Update!'
          );
          window.scroll(0, 1185);
        } else {
          this.partnerReligionSubmitted = false;
          this.partnerReligionOtherValue = false;
          this.partnerReligionButton = false;
          this.toastrService.error('Message!', 'Partner Religious Not Update!');
        }

      },
      (error) => {
        this.partnerReligionSubmitted = false;
        this.partnerReligionOtherValue = false;
        this.partnerReligionButton = false;
        this.toastrService.error('Message!', 'Partner Religious Not Update!');
      }
    );

    window.scroll(0, 1185);
  }
  spliceEducation() {
    this.selectedPartnerEducation.forEach((element, index) => {
      if (element == 'Other') this.selectedPartnerEducation.splice(index, 1);
    });

    this.selectedPartnerNatureOfWork.forEach((element, index) => {
      if (element == 'Other') this.selectedPartnerNatureOfWork.splice(index, 1);
    });

    this.selectedPartnerProfession.forEach((element, index) => {
      if (element == 'Other') this.selectedPartnerProfession.splice(index, 1);
    });
  }

  savePartnerEducation() {
    this.partnerEducationSubmitted = true;
    this.partnerEducationButton = true;
    // education area
    let partnerEducationSelected = this.selectedPartnerEducation.toString();
    if (this.selectedReligion.length > 1) {
      this.partnerEducationOtherValue = false
      let EducationArr = partnerEducationSelected.split(',');
      EducationArr.forEach((element: any) => {
        if (element != '') {
          if (element == 'Other') {
            this.partnerOtherEducation = true;
            if (this.partnerEducationForm.value.otherEducation != "") {
              this.selectedPartnerEducation.push(
                this.partnerEducationForm.value.otherEducation
              );
            } else {
              this.partnerEducationButton = false;
              return;
            }
          }
        }
      });
    } else {
      if (partnerEducationSelected == 'Other') {
        this.partnerOtherEducation = true;
        if (this.partnerEducationForm.value.otherEducation != "") {
          this.partnerEducationOtherValue = false
          this.selectedPartnerEducation.push(
            this.partnerEducationForm.value.otherEducation
          );
        } else {
          this.partnerEducationOtherValue = true;
          this.partnerEducationButton = false;
          return;
        }
      }
    }

    // nature of work
    let partnerNatureOfWorkSelected =
      this.selectedPartnerNatureOfWork.toString();
    if (this.selectedPartnerNatureOfWork.length > 1) {
      this.partnerNatureOfWorkOtherValue = false;
      let NatureOfWorkArr = partnerNatureOfWorkSelected.split(',');
      NatureOfWorkArr.forEach((element: any) => {
        //// console.log(element);
        if (element != '') {
          if (element == 'Other') {
            this.partnerOtherNatureOfWork = true;
            if (this.partnerEducationForm.value.otherNatureOfWork != "") {
              this.selectedPartnerNatureOfWork.push(
                this.partnerEducationForm.value.otherNatureOfWork
              );
            } else {
              this.partnerEducationButton = false;
              return;
            }
          }
        }
      });
    } else {
      if (partnerNatureOfWorkSelected == 'Other') {
        this.partnerOtherNatureOfWork = true;
        if (this.partnerEducationForm.value.otherNatureOfWork != "") {
          this.partnerNatureOfWorkOtherValue = false
          this.selectedPartnerNatureOfWork.push(
            this.partnerEducationForm.value.otherNatureOfWork
          );
        } else {
          this.partnerNatureOfWorkOtherValue = true;
          this.partnerEducationButton = false;
          return;
        }
      }
    }

    // profession
    let partnerProfessionSelected = this.selectedPartnerProfession.toString();
    if (this.selectedReligion.length > 1) {
      this.partnerProfessionOtherValue = false
      let ProfessionArr = partnerProfessionSelected.split(',');
      ProfessionArr.forEach((element: any) => {
        if (element != '') {
          if (element == 'Other') {
            this.partnerOtherProfession = true;
            if (this.partnerEducationForm.value.otherProfession != "") {
              this.selectedPartnerProfession.push(
                this.partnerEducationForm.value.otherProfession
              );
            } else {
              this.partnerEducationButton = false;
            }
          }
        }
      });
    } else {
      if (partnerProfessionSelected == 'Other') {
        this.partnerOtherReligion = true;
        if (this.partnerEducationForm.value.otherProfession != "") {
          this.partnerProfessionOtherValue = false
          this.selectedPartnerProfession.push(
            this.partnerEducationForm.value.otherProfession
          );
        } else {
          this.partnerProfessionOtherValue = true;
          this.partnerEducationButton = false;
        }
      }
    }

    if (this.partnerEducationForm.invalid) {
      this.partnerEducationButton = false;
      return;
    }
    this.spliceEducation();
    const profwArr1 = this.selectedPartnerProfession.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item];
    }, []);
    const edunArr = this.selectedPartnerEducation.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item];
    }, []);

    const partnerEducationdata = {
      user_id: this.user_id,
      education: edunArr.toString(),
      nature_of_work: this.selectedPartnerNatureOfWork.toString(),
      profession_area: profwArr1.toString(),
      annual_income: this.partnerEducationForm.value.annual_income,
      income_currency: this.partnerEducationForm.value.income_currency,
      income_range_from: this.partnerEducationForm.value.income_range_from
        ? this.partnerEducationForm.value.income_range_from.trim()
        : '',
      income_range_to: this.partnerEducationForm.value.income_range_to
        ? this.partnerEducationForm.value.income_range_to.trim()
        : '',
    };
    this.myProfileService
      .setPartnerEducationDetailsUrl(partnerEducationdata)
      .subscribe(
        (result: any) => {
          if (result.status) {
            this.closePartnerEducation();
            this.partnerOtherEducation = false;
            this.partnerOtherNatureOfWork = false;
            this.partnerOtherProfession = false;
            this.partnerEducationSubmitted = false;
            this.partnerEducationButton = false;
            this.toastrService.success(
              'Successfully!',
              'Partner Education & Professional Details Update!'
            );
            window.scroll(0, 1185);
          } else {
            this.partnerEducationSubmitted = false;
            this.partnerEducationButton = false;
            this.toastrService.error('Message!', 'Partner Education & Professional Details Not Update!'
            );
          }
        },
        (error) => {
          this.partnerEducationSubmitted = false;
          this.partnerEducationButton = false;
          this.toastrService.error(
            'Message!',
            'Partner Education & Professional Details Not Update!'
          );
        }
      );

    window.scroll(0, 1185);
  }

  savePa() {
    // unused code
    // console.log(this.selectedPartnerFamilyType);
  }

  savePartnerFamily() {
    this.partnerFamilySubmitted = true;
    this.partnerFamilyButton = true;

    if (this.partnerFamilyForm.invalid) {
      this.partnerFamilyButton = false;
      return;
    }
    this.selectedPartnerFamilyType.forEach((element, index) => {
      if (element == 'Other') this.selectedPartnerFamilyType.splice(index, 1);
    });

    const ftArr1 = this.selectedPartnerFamilyType.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item];
    }, []);
    const partnerFamilydata = {
      user_id: this.user_id,
      father_occupation: this.partnerFamilyForm.value.father_occupation
        ? this.partnerFamilyForm.value.father_occupation.trim()
        : '',
      mother_occupation: this.partnerFamilyForm.value.mother_occupation
        ? this.partnerFamilyForm.value.mother_occupation.trim()
        : '',
      family_type: ftArr1.toString(),
      family_living_in_country:
        this.partnerFamilyForm.value.family_living_in_country,
      family_state_name: this.partnerFamilyForm.value.family_state_name,
      living_with_family: this.partnerFamilyForm.value.living_with_family,
      family_current_city: this.partnerFamilyForm.value.family_current_city
        ? this.partnerFamilyForm.value.family_current_city.trim()
        : '',
    };
    this.myProfileService.setPartnerFamilyDetails(partnerFamilydata).subscribe(
      (response: any) => {
        if (response.status) {
          this.closePartnerFamily();
          this.partnerFamilySubmitted = false;
          this.partnerFamilyButton = false;
          this.partnerOtherResidencyStatus = false;
          this.toastrService.success(
            'Successfully!',
            'Partner Family Details Update!'
          );
          window.scroll(0, 1185);
        } else {
          this.partnerFamilySubmitted = false;
          this.partnerFamilyButton = false;
          this.toastrService.error(
            'Message!',
            'Partner Family Details Not Update!'
          );
        }
      },
      (error) => {
        this.partnerFamilySubmitted = false;
        this.partnerFamilyButton = false;
        this.toastrService.error(
          'Message!',
          'Partner Family Details Not Update!'
        );
      }
    );
    window.scroll(0, 1185);
  }

  checkIncomeRange() {
    let from = Number(this.partnerEducationForm.value.income_range_from);
    let to = Number(this.partnerEducationForm.value.income_range_to);
    if (from <= to) {
      this.incomeRange = false;
      this.partnerEducationButton = false;
    } else {
      this.incomeRange = true;
      this.partnerEducationButton = true;
    }
  }

  savePartnerLocation() {
    this.partnerLocationSubmitted = true;
    this.partnerLocationButton = true;
    let partnerResidencyStatusSelected =
      this.selectedPartnerResidencyStatus.toString();
    if (this.selectedPartnerResidencyStatus.length > 1) {
      let ResidencyStatusArr = partnerResidencyStatusSelected.split(',');
      ResidencyStatusArr.forEach((element: any) => {
        if (element != '') {
          if (element == 'Other') {
            this.partnerOtherResidencyStatus = true;
            if (this.partnerLocationForm.value.otherResidencyStatus != "") {
              this.selectedPartnerResidencyStatus.push(
                this.partnerLocationForm.value.otherResidencyStatus
              );
            } else {
              this.partnerLocationButton = false;
              return;
            }
          }
        }
      });
    } else {
      if (partnerResidencyStatusSelected == 'Other') {
        this.partnerOtherResidencyStatus = true;
        if (this.partnerLocationForm.value.otherResidencyStatus != "") {
          this.partnerResidencyStatusOtherValue = false
          this.selectedPartnerResidencyStatus.push(
            this.partnerLocationForm.value.otherResidencyStatus
          );
        } else {
          this.partnerResidencyStatusOtherValue = true;
          this.partnerLocationButton = false;
          return;
        }
      }
    }

    if (this.partnerLocationForm.invalid) {
      this.partnerLocationButton = false;
      return;
    }
    this.selectedPartnerResidencyStatus.forEach((element, index) => {
      if (element == 'Other')
        this.selectedPartnerResidencyStatus.splice(index, 1);
    });

    const resArr1 = this.selectedPartnerResidencyStatus.reduce(
      (uniqArr, item) => {
        return uniqArr.includes(item) ? uniqArr : [...uniqArr, item];
      },
      []
    );

    const partnerLocationdata = {
      user_id: this.user_id,
      country_name: this.partnerLocationForm.value.country_name,
      state_name: this.partnerLocationForm.value.state_name,
      city_name: this.partnerLocationForm.value.city_name
        ? this.partnerLocationForm.value.city_name.trim()
        : '',
      residency_status: resArr1.toString(),
    };
    this.myProfileService
      .setPartnerLocationDetails(partnerLocationdata)
      .subscribe(
        (response: any) => {
          if (response.status) {
            this.closePartnerLocation();
            this.partnerOtherResidencyStatus = false;
            this.partnerLocationSubmitted = false;
            this.partnerLocationButton = false;
            this.toastrService.success(
              'Successfully!',
              'Location of Partner Update!'
            );
            window.scroll(0, 1185);
          } else {
            this.partnerLocationSubmitted = false;
            this.partnerLocationButton = false;
            this.toastrService.error(
              'Message!',
              'Location of Partner Not Update!'
            );
          }
        },
        (error) => {
          this.partnerLocationSubmitted = false;
          this.partnerLocationButton = false;
          this.toastrService.error(
            'Message!',
            'Location of Partner Not Update!'
          );
        }
      );
    window.scroll(0, 1185);
  }

  getData(e: any) {
    this.divbox = !this.divbox;
    return this.setData(e);
  }

  setData(e: any) {
    //empty
  }

  formatSubtitle = (percent: number): string => {
    if (percent >= 100) {
      return 'Congratulations!';
    } else if (percent >= 50) {
      return 'Half';
    } else if (percent > 0) {
      return 'Just began';
    } else {
      return 'Not started';
    }
  };

  onChangeCountry(event: any) {
    const cid = this.aboutYouForm.value.living_in_country;
    let data = {
      id: cid,
    };
    this.getStateList(data);
    this.aboutYouF.state_name.setValue('');
  }

  onChangeAboutCountry(event: any) {
    const cid = this.aboutYouForm.value.living_in_country;
    let data = {
      id: cid,
    };
    this.getAboutStateList(data);
    this.aboutYouF.state_name.setValue('');
  }

  getAboutStateList(data: any) {
    this.aboutstates = [];
    this.authService.getStateList(data).subscribe((result) => {
      this.aboutstates = result.data;
    });
  }
  onChangeCountryOfBirth(event: any) {
    const cid = this.astroForm.value.country_of_birth;
    let data = {
      id: cid,
    };
    console.log(data)
  }

  onChangeCountryLocation(event: any) {
    const cid = this.locationForm.value.living_in_country;
    // // console.log(cid,"location")
    let data = {
      id: cid,
    };
    this.checkCountry();
    this.getLocationStateList(data);
    this.locationF.state_name.setValue('');
  }

  getLocationStateList(data: any) {
    this.locationstates = [];
    this.authService.getStateList(data).subscribe((result) => {
      this.locationstates = result.data;
    });
  }

  onChangeCountryPartnerLocation(event: any) {
    const cid = this.partnerLocationForm.value.country_name;
    console.log(cid, "location")
    let data = {
      id: cid,
    };
    if (cid == 0 || cid == "Any") {
      this.partnerLocationF.state_name.setValue('Any');
      this.partnerLocationStates = []
    } else {
      this.partnerLocationF.state_name.setValue('');
      this.getPartnerLocationStateList(data);
    }
  }

  getPartnerLocationStateList(data: any) {
    this.partnerLocationStates = [];
    this.authService.getStateList(data).subscribe((result) => {
      this.partnerLocationStates = result.data;
    });
  }

  getStateList(data: any) {
    this.states = [];
    this.authService.getStateList(data).subscribe((result) => {
      this.states = result.data;
    });
  }
  onChangeFamilyCountry(event: any) {
    const cid = this.familyDetailForm.value.family_living_in_country;
    let data = {
      id: cid,
    };
    this.getFamilyStateList(data);
    this.familyDetailF.family_state_name.setValue('');
  }

  getFamilyStateList(data: any) {
    this.familyStates = [];
    this.authService.getStateList(data).subscribe((result) => {
      this.familyStates = result.data;
    });
  }

  onChangePartnerFamilyCountry(event: any) {
    const cid = this.partnerFamilyForm.value.family_living_in_country;
    let data = {
      id: cid,
    };
    this.getPartnerFamilyStateList(data);
    this.partnerFamilyF.family_state_name.setValue('');
    this.partnerFamilyStateName = ""
  }

  getPartnerFamilyStateList(data: any) {
    this.partnerFamilyStates = [];
    this.authService.getStateList(data).subscribe((result) => {
      this.partnerFamilyStates = result.data;
    });
  }

  onChangeAYLanguage() {
    this.aboutYouSubmitted = false;
    if (this.aboutYouForm.value.languages == 'other') {
      this.isAboutYouLanguageOther = true;
      this.aboutYouF.otherLanguage.setValue('');
      this.focusOnInput4();
    } else {
      this.isAboutYouLanguageOther = false;
    }
  }

  private focusOnInput4() {
    // Use a timeout to ensure the input is rendered before trying to focus on it
    setTimeout(() => {
      const inputElement = this.el.nativeElement.querySelector('#otherLanguage');
      if (inputElement) {
        this.renderer.selectRootElement(inputElement).focus();
      }
    });
  }

  onChangeProfession() {
    this.educationSubmitted = false;
    this.educationF.otherProfession.setValue('');
    if (this.educationForm.value.working_as == 'Other') {
      this.isProfessionOther = true;
      this.focusOnInput8();
    } else {
      this.isProfessionOther = false;
    }
  }

  onChangePBLanguage() {
    if (this.partnerBasicForm.value.languages == 'other') {
      this.isPartnerBasicLanguageOther = true;
    } else {
      this.isPartnerBasicLanguageOther = false;
    }
  }

  onChangeReligion() {
    this.religionSubmitted = false;
    if (this.religionForm.value.religion == 'Other') {
      this.religionF.otherReligion.setValue('');
      this.isReligionOther = true;
      this.focusOnInput1()
    } else {
      this.isReligionOther = false;
    }
  }

  private focusOnInput1() {
    // Use a timeout to ensure the input is rendered before trying to focus on it
    setTimeout(() => {
      const inputElement = this.el.nativeElement.querySelector('#otherReligion');
      if (inputElement) {
        this.renderer.selectRootElement(inputElement).focus();
      }
    });
  }

  onChangeAstroMonth() {
    this.astrodateSel = [];
    this.astroF.day.setValue('');
    let month_val = this.astroForm.value.month;
    let year_val = this.astroForm.value.year;
    if (month_val != '' && year_val != '') {
      let monthAndYear = year_val + '-' + month_val;
      let noOfDays = moment(monthAndYear, 'YYYY-MM').daysInMonth();
      for (let i = 1; i <= noOfDays; i++) {
        this.astrodateSel.push({ id: i, name: i });
      }
    }
    this.astroF.day.setValue('');
  }

  onChangeBasicMonth() {
    this.dateSel = [];
    this.astroF.day.setValue('');
    let month_val = this.basicAndLifestyleForm.value.month;
    let year_val = this.basicAndLifestyleForm.value.year;
    if (month_val != '' && year_val != '') {
      let monthAndYear = year_val + '-' + month_val;
      let noOfDays = moment(monthAndYear, 'YYYY-MM').daysInMonth();
      for (let i = 1; i <= noOfDays; i++) {
        this.dateSel.push({ id: i, name: i });
      }
    }
    this.basicAndLifestyleF.day.setValue('');
  }

  onChangeAstroYear() {
    console.log(123);
    this.astrodateSel = [];
    this.astroF.day.setValue('');
    let month_val = this.astroForm.value.month;
    let year_val = this.astroForm.value.year;
    if (month_val != '' && year_val != '') {
      let monthAndYear = year_val + '-' + month_val;
      let noOfDays = moment(monthAndYear, 'YYYY-MM').daysInMonth();
      for (let i = 1; i <= noOfDays; i++) {
        this.astrodateSel.push({ id: i, name: i });
      }
    }
    this.astroF.day.setValue('');
  }

  onChangeBasicYear() {
    console.log(234)
    this.dateSel = [];
    this.astroF.day.setValue('');
    let month_val = this.basicAndLifestyleForm.value.month;
    let year_val = this.basicAndLifestyleForm.value.year;
    if (month_val != '' && year_val != '') {
      let monthAndYear = year_val + '-' + month_val;
      let noOfDays = moment(monthAndYear, 'YYYY-MM').daysInMonth();
      for (let i = 1; i <= noOfDays; i++) {
        this.dateSel.push({ id: i, name: i });
      }
    }
    this.basicAndLifestyleF.day.setValue('');
  }

  keyPressNumeric1(event) {
    const pattern = /\D/g;
    if (!pattern.test(event.target.value)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, '');
      event.preventDefault();
      return false;
    }
  }

  pasteNumericNo(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, '');
    this.handoverForm.patchValue({ mobile_no_to: newText });
  }

  pasteNumbrother(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, '');
    this.familyDetailForm.patchValue({ no_of_brother: newText });
  }

  pasteNumsister(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, '');
    this.familyDetailForm.patchValue({ no_of_sister: newText });
  }

  keyPressFamilypostal(event) {
    let inp = String.fromCharCode(event.keyCode);
    if (/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressLocationpostal(event) {
    console.log(event);
    let inp = String.fromCharCode(event.keyCode);
    if (/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  pasteNumber(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, '');
    this.educationForm.patchValue({ annual_income: newText });
  }

  pasteNumerZipCode(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, '');
    this.locationForm.patchValue({ postal_code: newText });
  }

  keyPresspostal(event) {
    let inp = String.fromCharCode(event.keyCode);
    console.log(inp);
    if (/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  OnchangeCountryPhoneCode(event) {
    this.contactForm.patchValue({ countryCode: event.id });
    this.contactForm.patchValue({ countryNumberCode: event.country_code });
  }

  pasteNumericMobile(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, '');
    this.contactForm.patchValue({ mobile_no: newText });
  }

  pasteNumericFrom(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, '');
    this.partnerEducationForm.patchValue({ income_range_from: newText });
  }

  pasteNumericTo(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, '');
    this.partnerEducationForm.patchValue({ income_range_to: newText });
  }

  NatureOfWork() {
    this.educationSubmitted = false;

    let workVal = this.educationF.nature_of_work.value;
    if (workVal != 'Other') {
      if (workVal == 'NotWorking') {
        this.educationForm.value.income_currency.setValue('');
        this.educationForm.value.annual_income.setValue(0);
      }
      this.isNatureOfWorkOther = false;
      this.educationF.otherNatureOfWork.setValue('');
    } else {
      this.isNatureOfWorkOther = true;
      this.educationF.otherNatureOfWork.setValue('');
      this.focusOnInput7();
    }
  }

  onChangeAgeFrom(age: any) {
    this.age_to = [];
    for (let i = age.target.value; i <= 90; i++) {
      this.partnerBasicF.age_to.setValue('');
      this.age_to.push({ id: i });
    }
  }

  onChangeHeightFrom(height: any) {
    this.height_to = [];
    for (let i = height.target.value; i <= 230; i++) {
      this.partnerBasicF.height_to.setValue('');
      this.height_to.push({ i });
    }
  }

  onChangeWeightFrom(weight: any) {
    this.weight_to = [];
    for (let i = weight.target.value; i <= 114; i++) {
      this.partnerBasicF.weight_to.setValue('');
      this.weight_to.push({ i });
    }
  }

  annualIncome() {
    if (this.partnerEducationForm.value.annual_income == "OpenToAll" || this.partnerEducationForm.value.annual_income == "Open To All") {
      this.partnerIncomeRange = false;
      this.partnerEducationF.income_currency.setValue("other");
      this.partnerEducationF.income_range_from.setValue(0);
      this.partnerEducationF.income_range_to.setValue(0);

    } else {
      this.partnerIncomeRange = true;
      this.partnerEducationF.income_currency.setValue('');
      this.partnerEducationF.income_range_from.setValue('');
      this.partnerEducationF.income_range_to.setValue('');
    }
  }

  onChangePartnerReligion() {
    if (this.partnerReligionForm.value.religion == 'other') {
      this.isPartnerReligionOther = true;
    } else {
      this.isPartnerReligionOther = false;
    }
  }

  onChangeMaritalStatus() {
    if (
      this.partnerBasicForm.value.marital_status == 'Single' ||
      this.partnerBasicForm.value.marital_status == 'Annulled'
    ) {
      this.partnerBasicShowChildren = false;
    } else {
      this.partnerBasicShowChildren = true;
    }
  }

  RemoveElementFromStringArray(element: string) {
    this.days.forEach((value: string, index: any) => {
      if (value == element) this.days.splice(index, 1);
    });
  }

  conReset() {
    let fname = this.contactForm.value.firstName
    let lname = this.contactForm.value.lastName
    this.contactForm.reset('country_code');
    this.contactF.contact_person.setValue(this.createdBy);
    this.contactF.mobile_no.setValue(this.details.mobile_no);
    this.contactF.countryCode.setValue('');
    this.contactF.firstName.setValue(fname)
    this.contactF.lastName.setValue(lname)
  }

  resetDays() {
    this.selectedTimeMondayFrom = "";
    this.selectedTimeTuesdayFrom = "";
    this.selectedTimeWednesdayFrom = "";
    this.selectedTimeThursdayFrom = "";
    this.selectedTimeFridayFrom = "";
    this.selectedTimeSundayFrom = "";
    this.selectedTimeSaturdayFrom = "";
    // time to 
    this.selectedTimeMondayTo = "";
    this.selectedTimeTuesdayTo = "";
    this.selectedTimeWednesdayTo = "";
    this.selectedTimeThursdayTo = "";
    this.selectedTimeFridayTo = "";
    this.selectedTimeSundayTo = "";
    this.selectedTimeSaturdayTo = "";
  }

  dayEnable() {
    if (this.days.includes("Sunday")) {
      this.EnableEditSunday = true;
      this.sundayTimeFrom = this.makeToArray(0);
    } else {
      this.EnableEditSunday = false;
      this.sundayTimeFrom = [];
      this.selectedTimeSundayFrom = "";
      this.selectedTimeSundayTo = "";
      this.sundayTimeTo = [];
    }
    if (this.days.includes("Monday")) {
      this.EnableEditMonday = true;
      this.mondayTimeFrom = this.makeToArray(0);
    } else {
      this.EnableEditMonday = false;
      this.mondayTimeFrom = [];
      this.mondayTimeTo = [];
      this.selectedTimeMondayFrom = "";
      this.selectedTimeMondayTo = "";
    }
    if (this.days.includes("Tuesday")) {
      this.EnableEditTuesday = true;
      this.tuesdayTimeFrom = this.makeToArray(0);

    } else {
      this.EnableEditTuesday = false;
      this.tuesdayTimeFrom = [];
      this.tuesdayTimeTo = [];
      this.selectedTimeTuesdayFrom = "";
      this.selectedTimeTuesdayTo = "";
    }
    if (this.days.includes("Wednesday")) {
      this.EnableEditWednesday = true;
      this.wednesdayTimeFrom = this.makeToArray(0);

    } else {
      this.EnableEditWednesday = false;
      this.wednesdayTimeFrom = [];
      this.wednesdayTimeTo = [];
      this.selectedTimeWednesdayFrom = "";
      this.selectedTimeWednesdayTo = "";
    }
    if (this.days.includes("Thursday")) {
      this.EnableEditThursday = true;
      this.thursdayTimeFrom = this.makeToArray(0);

    } else {
      this.EnableEditThursday = false;
      this.thursdayTimeFrom = [];
      this.thursdayTimeTo = [];
      this.selectedTimeThursdayFrom = "";
      this.selectedTimeThursdayTo = "";
    }
    if (this.days.includes("Friday")) {
      this.EnableEditFriday = true;
      this.fridayTimeFrom = this.makeToArray(0);
    } else {
      this.EnableEditFriday = false;
      this.fridayTimeFrom = [];
      this.fridayTimeTo = [];
      this.selectedTimeFridayFrom = "";
      this.selectedTimeFridayTo = "";
    }
    if (this.days.includes("Saturday")) {
      this.EnableEditSaturday = true;
      this.saturdayTimeFrom = this.makeToArray(0);

    } else {
      this.EnableEditSaturday = false;
      this.saturdayTimeFrom = [];
      this.saturdayTimeTo = [];
      this.selectedTimeSaturdayFrom = "";
      this.selectedTimeSaturdayTo = "";
    }
  }
  dayCheck(event) {
    if (event.target.value == 'Any-Time') {
      if (this.days.includes("Any-Time")) {
        this.RemoveElementFromStringArray('Any-Time');
        $('#Any-Time').prop('checked', false);
      } else {
        this.conReset();
        this.days = [];
        // this.selectedTimeZoneDay = ""
        $('#Any-Time').prop('checked', true);
        this.days.push(event.target.value);
        window.scroll(0, 625);
      }
    } else {
      this.showAnyTime = false;
      this.RemoveElementFromStringArray('Any-Time');
      $('#Any-Time').prop('checked', false);
      if (this.contactForm.value.days == true) {
        this.days.push(event.target.value);
      } else {
        this.days.forEach((element: any, index: any) => {
          if (element == event.target.value) {
            this.RemoveElementFromStringArray(event.target.value);
          }
        });
      }
      this.dayEnable();
    }
    // console.log(this.selectedTimeZoneDay, "selectedTimeZoneDay", this.days, "days")
  }
  checkTimeZone() {
    if (!this.selectedTimeZoneDay) {
      this.conReset();
      this.days = [];
      this.selectedTimeMonday = null;
      this.selectedTimeTuesday = null;
      this.selectedTimeWednesday = null;
      this.selectedTimeThursday = null;
      this.selectedTimeFriday = null;
      this.selectedTimeSaturday = null;
      this.selectedTimeSunday = null;
    } else {
      this.RemoveElementFromStringArray('Any-Time');
      $('#Any-Time').prop('checked', false);
    }
  }

  submitProfilePicture() {
    const formData: any = new FormData();
    formData.append('image', this.fileUpload);
    formData.append('user_id', this.user_id);
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    };
    console.log(httpOptions);
    this.myProfileService.uploadImage(formData).subscribe((result) => {
      if (result.status) {
        this.notifService.success(
          'Image Uploaded',
          result.message,
          this.options
        );
        this.toastrService.success('Successfully!', 'Image Uploaded!');
        this.getUserDetails();
      } else {
        this.toastrService.error('Message!', 'Image not Uploaded!');

        this.notifService.error(
          'Image not Uploaded',
          result.message,
          this.options
        );
      }
      this.getUserDetails();
    });
  }

  onIncomeChange(event) {
    const searchValue = event.target.value;
    console.log(searchValue)
  }
  pasteBoyChildMarried(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, '');
    this.familyDetailForm.patchValue({ boy_child_married: newText });
  }

  pasteBoyChildNotMarried(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, '');
    this.familyDetailForm.patchValue({ boy_child_not_married: newText });
  }

  pasteGirlChildMarried(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, '');
    this.familyDetailForm.patchValue({ girl_child_married: newText });
  }

  pasteGirlChildNotMarried(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, '');
    this.familyDetailForm.patchValue({ girl_child_not_married: newText });
  }

  checkMaritalStatus() {
    if (
      this.aboutYouForm.value.marital_status == 'Single' ||
      this.aboutYouForm.value.marital_status == 'Annulled'
    ) {
      this.aboutYouShowChildren = false;
      this.aboutYouHaveChildren = false;
      this.aboutYouBoyChild = false;
      this.aboutYouGirlChild = false;
      this.aboutYouF.have_children.setValue('');
      this.aboutYouF.boy_child.setValue('');
      this.aboutYouF.girl_child.setValue('');
      this.aboutYouF.boy_child_not_married.setValue(0);
      this.aboutYouF.boy_child_married.setValue(0);
      this.aboutYouF.girl_child_not_married.setValue(0);
      this.aboutYouF.girl_child_married.setValue(0);
    } else {
      this.aboutYouShowChildren = true;
    }
  }

  checkHaveChildren() {
    if (this.aboutYouForm.value.have_children == 'Yes') {
      this.aboutYouHaveChildren = true;
    } else {
      this.aboutYouHaveChildren = false;
      this.aboutYouBoyChild = false;
      this.aboutYouGirlChild = false;
      this.aboutYouF.boy_child.setValue('');
      this.aboutYouF.girl_child.setValue('');
      this.aboutYouF.boy_child_not_married.setValue(0);
      this.aboutYouF.boy_child_married.setValue(0);
      this.aboutYouF.girl_child_not_married.setValue(0);
      this.aboutYouF.girl_child_married.setValue(0);
    }
  }

   // before sonar 
  // checkBoyChild() {
  //   if (
  //     this.aboutYouForm.value.boy_child == 'Yes' ||
  //     this.aboutYouForm.value.boy_child == true
  //   ) {
  //     this.aboutYouBoyChild = true;
  //   } else {
  //     this.aboutYouBoyChild = false;
  //     this.aboutYouF.boy_child_not_married.setValue(0);
  //     this.aboutYouF.boy_child_married.setValue(0);
  //   }
  // }

   //after sonar
  checkBoyChild() {
    const boyChildValue = this.aboutYouForm.value.boy_child;
    if (boyChildValue === 'Yes' || boyChildValue === true) {
      this.aboutYouBoyChild = true;
    } else {
      this.aboutYouBoyChild = false;
      this.aboutYouF.boy_child_not_married.setValue(0);
      this.aboutYouF.boy_child_married.setValue(0);
    }
  }

  // before sonar 
  // checkGirlChild() {
  //   if (
  //     this.aboutYouForm.value.girl_child == 'Yes' ||
  //     this.aboutYouForm.value.girl_child == true
  //   ) {
  //     this.aboutYouGirlChild = true;
  //   } else {
  //     this.aboutYouGirlChild = false;
  //     this.aboutYouF.girl_child_not_married.setValue(0);
  //     this.aboutYouF.girl_child_married.setValue(0);
  //   }
  // }

  //after sonar
  checkGirlChild() {
    const girlChildValue = this.aboutYouForm.value.girl_child;
  
    if (girlChildValue === 'Yes' || girlChildValue === true) {
      this.aboutYouGirlChild = true;
    } else {
      this.aboutYouGirlChild = false;
      this.aboutYouF.girl_child_not_married.setValue(0);
      this.aboutYouF.girl_child_married.setValue(0);
    }
  }

  educationCheck() {
    this.educationSubmitted = false;
    if (this.educationForm.value.qualification == 'Other') {
      this.isQualificationOther = true;
      this.focusOnInput2();
      this.educationF.otherQualification.setValue('');
    } else {
      this.educationF.otherQualification.setValue('');
      this.isQualificationOther = false;
    }
  }

  private focusOnInput2() {
    // Use a timeout to ensure the input is rendered before trying to focus on it
    setTimeout(() => {
      const inputElement = this.el.nativeElement.querySelector('#otherQualification');
      if (inputElement) {
        this.renderer.selectRootElement(inputElement).focus();
      }
    });
  }

  removeElement3(data: any) {
    const index: number = this.selectedFoodPreference.indexOf(data);
    if (index !== -1) {
      this.selectedFoodPreference.splice(0, 1);
    }
  }

  onSelectFoodPreference() {
    this.partnerFoodOtherValue = false;
    let partnerFoodPreference = this.selectedFoodPreference.toString();
    let foodArr = partnerFoodPreference.split(',');
    let otherSelected = false;
    foodArr.forEach((element: any) => {
      if (element != '') {
        if (element != 'Does Not Matter') {
          this.removeElement3('Does Not Matter');
          if (element == 'Other') {
            otherSelected = true;
            this.partnerOtherFood = true;
            this.focusOnInput5();
            this.partnerBasicF.otherFood.setValue('');
          }
        } else {
          this.partnerOtherFood = false;
          this.partnerBasicF.otherFood.setValue('other');
          this.selectedFoodPreference = [];
          this.selectedFoodPreference.push(element);
        }
      }
    });

    if (!otherSelected) {
      this.partnerOtherFood = false;
    }
  }

  private focusOnInput5() {
    // Use a timeout to ensure the input is rendered before trying to focus on it
    setTimeout(() => {
      const inputElement = this.el.nativeElement.querySelector('#otherFood');
      if (inputElement) {
        this.renderer.selectRootElement(inputElement).focus();
      }
    });
  }

  removeElement2(data: any) {
    const index: number = this.selectedlanguages.indexOf(data);
    if (index !== -1) {
      this.selectedlanguages.splice(0, 1);
    }
  }

  onSelectLanguages(event) {
    this.partnerLanguageOtherValue = false;
    let partnerLanguageSelected = this.selectedlanguages.toString();
    let langArr = partnerLanguageSelected.split(',');
    let otherSelected = false;
    langArr.forEach((element: any) => {
      if (element != '') {
        if (element != 'Any Language') {
          this.removeElement2('Any Language');
          this.partnerOtherLanguage = false;
          if (element == 'Other') {
            otherSelected = true;
            this.partnerOtherLanguage = true;
            this.focusOnInput4();
            this.partnerBasicF.otherLanguage.setValue('');
          }
        } else {
          this.partnerOtherLanguage = false;
          this.partnerBasicF.otherLanguage.setValue('other');
          this.selectedlanguages = [];
          this.selectedlanguages.push(element);
        }
      }
    });

    if (!otherSelected) {
      this.partnerOtherLanguage = false;
    }
  }

  removeElement1(data: any) {
    const index: number = this.selectedReligion.indexOf(data);
    if (index !== -1) {
      this.selectedReligion.splice(0, 1);
    }
  }

  onSelectReligion() {
    this.partnerReligionOtherValue = false;
    let partnerReligionSelected = this.selectedReligion.toString();
    let religionArr = partnerReligionSelected.split(',');
    let otherSelected = false;
    religionArr.forEach((element: any) => {
      if (element != '') {
        if (element != 'Any Religion') {
          this.removeElement1('Any Religion');
          this.partnerOtherReligion = false;
          if (element == 'Other') {
            otherSelected = true;
            this.partnerOtherReligion = true;
            this.focusOnInput1();
            this.partnerReligionF.otherReligion.setValue('');
          }
        } else {
          this.partnerOtherReligion = false;
          this.partnerReligionF.otherReligion.setValue('other');
          this.selectedReligion = [];
          this.selectedReligion.push(element)
        }
      }
    });
    if (!otherSelected) {
      this.partnerOtherReligion = false;
    }
  }

  // family tuype
  removeElement4(data: any) {
    const index: number = this.selectedPartnerFamilyType.indexOf(data);
    if (index !== -1) {
      this.selectedPartnerFamilyType.splice(0, 1);
    }
  }

  onSelectFamilyType() {
    let partnerFamilySelected = this.selectedPartnerFamilyType.toString();
    let famArr = partnerFamilySelected.split(',');
    famArr.forEach((element: any) => {
      if (element != '') {
        if (element != "Does Not Matter") {
          this.removeElement4("Does Not Matter");
          this.selectedPartnerFamilyType.push(element);
        } else {
          this.selectedPartnerFamilyType = [];
          this.selectedPartnerFamilyType.push(element);
        }
      }
    });
  }

  //family type
  removeElement(data: any) {
    const index: number = this.selectedMaritalStatus.indexOf(data);
    if (index !== -1) {
      this.selectedMaritalStatus.splice(0, 1);
    }
  }

  onSelectmaritalStatus() {
    let partnerMaritalStatusSelected = this.selectedMaritalStatus.toString();
    let maritalStatusArr = partnerMaritalStatusSelected.split(',');
    maritalStatusArr.forEach((element: any) => {
      if (element != '') {
        if (element != 'Any Of These') {
          this.removeElement('Any Of These');
          this.selectedMaritalStatus.push(element);
        } else {
          this.selectedMaritalStatus = [];
          this.selectedMaritalStatus.push(element);
        }
      }
    });
  }

  removeElement8(data: any) {
    const index: number = this.selectedPartnerEducation.indexOf(data);
    if (index !== -1) {
      this.selectedPartnerEducation.splice(0, 1);
    }
    this.partnerOtherEducation = false;
  }

  onSelectPartnerEducation() {
    this.partnerEducationOtherValue = false;
    let partnerEducationSelected = this.selectedPartnerEducation.toString();
    let EducationArr = partnerEducationSelected.split(',');
    let otherSelected = false;
    EducationArr.forEach((element: any) => {
      if (element != '') {
        if (element != 'Does Not Matter') {
          this.removeElement8('Does Not Matter');
          if (element == 'Other') {
            otherSelected = true;
            this.partnerOtherEducation = true;
            this.focusOnInput6();
            this.partnerEducationF.otherEducation.setValue('');
          }
        } else {
          this.partnerOtherEducation = false;
          this.partnerEducationF.otherEducation.setValue('other');
          this.selectedPartnerEducation = [];
          this.selectedPartnerEducation.push(element);
        }
      }
    });
    if (!otherSelected) {
      this.partnerOtherEducation = false;
    }
  }

  private focusOnInput6() {
    // Use a timeout to ensure the input is rendered before trying to focus on it
    setTimeout(() => {
      const inputElement = this.el.nativeElement.querySelector('#otherEducation');
      if (inputElement) {
        this.renderer.selectRootElement(inputElement).focus();
      }
    });
  }

  removeElement9(data: any) {
    const index: number = this.selectedPartnerNatureOfWork.indexOf(data);
    if (index !== -1) {
      this.selectedPartnerNatureOfWork.splice(0, 1);
    }
    this.partnerOtherNatureOfWork = false;
    this.partnerEducationForm.controls.otherNatureOfWork.reset();
  }

  onSelectPartnerNatureOfWork() {
    this.partnerNatureOfWorkOtherValue = false;
    let partnerNatureOfWorkSelected =
      this.selectedPartnerNatureOfWork.toString();
    let NatureOfWorkArr = partnerNatureOfWorkSelected.split(',');
    let otherSelected = false;
    NatureOfWorkArr.forEach((element: any) => {
      if (element != '') {
        if (element != "Does Not Matter") {
          this.removeElement9('Does Not Matter');
          if (element == 'Other') {
            otherSelected = true;
            this.partnerOtherNatureOfWork = true;
            this.partnerEducationF.otherNatureOfWork.setValue('');
            this.focusOnInput7();
          }
        } else {
          this.partnerOtherNatureOfWork = false;
          this.partnerEducationF.otherNatureOfWork.setValue('other');
          this.selectedPartnerNatureOfWork = [];
          this.selectedPartnerNatureOfWork.push(element);
        }
      }
    });

    if (!otherSelected) {
      this.partnerOtherNatureOfWork = false;
    }
  }

  private focusOnInput7() {
    // Use a timeout to ensure the input is rendered before trying to focus on it
    setTimeout(() => {
      const inputElement = this.el.nativeElement.querySelector('#otherNatureOfWork');
      if (inputElement) {
        this.renderer.selectRootElement(inputElement).focus();
      }
    });
  }

  removeElement01(data: any) {
    const index: number = this.selectedPartnerProfession.indexOf(data);
    if (index !== -1) {
      this.selectedPartnerProfession.splice(0, 1);
    }
    this.partnerOtherProfession = false;
    this.partnerEducationForm.controls.otherProfession.reset();
  }

  onSelectPartnerProfession() {
    this.partnerProfessionOtherValue = false;
    let partnerProfessionSelected = this.selectedPartnerProfession.toString();
    let ProfessionArr = partnerProfessionSelected.split(',');
    let otherSelected = false;
    ProfessionArr.forEach((element: any) => {
      if (element != '') {
        if (element != "Does Not Matter") {
          this.removeElement01('Does Not Matter');
          if (element == 'Other') {
            otherSelected = true;
            this.partnerOtherProfession = true;
            this.partnerEducationF.otherProfession.setValue('');
            this.focusOnInput8();
          }
        } else {
          this.partnerOtherProfession = false;
          this.partnerEducationF.otherProfession.setValue('other');
          this.selectedPartnerProfession = [];
          this.selectedPartnerProfession.push(element);
        }
      }
    });

    if (!otherSelected) {
      this.partnerOtherProfession = false;
    }
  }

  private focusOnInput8() {
    // Use a timeout to ensure the input is rendered before trying to focus on it
    setTimeout(() => {
      const inputElement = this.el.nativeElement.querySelector('#otherProfession');
      if (inputElement) {
        this.renderer.selectRootElement(inputElement).focus();
      }
    });
  }

  onSelectPartnerResidencyStatus() {
    this.partnerResidencyStatusOtherValue = false;
    let partnerResidencyStatusSelected =
      this.selectedPartnerResidencyStatus.toString();
    let ResidencyStatusArr = partnerResidencyStatusSelected.split(',');
    let otherSelected = false;
    ResidencyStatusArr.forEach((element: any) => {
      if (element != '') {
        this.partnerOtherResidencyStatus = false;
        if (element == 'Other') {
          otherSelected = true;
          this.partnerOtherResidencyStatus = true;
          this.focusOnInput9();
          this.partnerLocationF.otherResidencyStatus.setValue('');
        } else {
          this.partnerOtherResidencyStatus = false;
        }
      }
    });

    if (!otherSelected) {
      this.partnerOtherResidencyStatus = false;
    }
  }

  private focusOnInput9() {
    // Use a timeout to ensure the input is rendered before trying to focus on it
    setTimeout(() => {
      const inputElement = this.el.nativeElement.querySelector('#otherResidencyStatus');
      if (inputElement) {
        this.renderer.selectRootElement(inputElement).focus();
      }
    });
  }

  checkResidency() {
    console.log(this.locationForm.value.residency_status, this.locationF.residency_status.value, "sagar")
    if (this.locationForm.value.residency_status == "Other") {
      this.locationF.otherResidency.setValue('');
      this.isResidencyOther = true;
      this.focusOnInput3()
    } else {
      this.isResidencyOther = false;

    }
  }

  private focusOnInput3() {
    // Use a timeout to ensure the input is rendered before trying to focus on it
    setTimeout(() => {
      const inputElement = this.el.nativeElement.querySelector('#otherResidency');
      if (inputElement) {
        this.renderer.selectRootElement(inputElement).focus();
      }
    });
  }

  timeSet() {
    if (
      this.details.convenient_time_to_call != '' &&
      this.details.convenient_time_to_call != null
    ) {
      this.showTime = [];
      this.days = [];

      let std = this.details.convenient_time_to_call.split(',');
      std.forEach((element: any) => {
        this.showTime.push(element);
        if (element != '') {
          let daytime = element.split(' - ');
          daytime.forEach((element: any) => {
            if (element != '') {
              if (element == 'Monday') {
                this.days.push('Monday');
                setTimeout(() => {
                  $('#Monday').prop('checked', true);
                  this.contactF.monday_hour_from.setValue(daytime[1]);
                  this.selectedTimeMondayFrom = daytime[1]
                  this.selectedTimeMondayTo = daytime[2]
                  this.contactF.monday_hour_to.setValue(daytime[2]);
                  this.contactF.day_time_zone.setValue(daytime[3]);
                  this.selectedTimeZoneDay = daytime[3];
                  let index = this.getTimeIntervalIndex(this.selectedTimeMondayFrom);
                  this.mondayTimeFrom = this.makeToArray(0);
                  this.mondayTimeTo = this.makeToArray(index);
                }, 50);
                // value from
                const keys = this.timeZoneList.map((data) => {
                  if (data.value == daytime[3]) {
                    let utc = data.value;
                    let s1 =
                      daytime[0] +
                      ' - ' +
                      daytime[1].replace(/-/gi, ':') +
                      ' - ' +
                      daytime[2].replace(/-/gi, ':') +
                      ' (' +
                      utc +
                      ')';
                    //console.log( s1)
                    this.showTime.push(s1);
                  }
                });
                console.log(keys);
              } else if (element == 'Tuesday') {
                setTimeout(() => {
                  $('#Tuesday').prop('checked', true);
                  this.days.push('Tuesday');
                  this.contactF.tuesday_hour_from.setValue(daytime[1]);
                  this.contactF.tuesday_hour_to.setValue(daytime[2]);
                  this.contactF.tuesday_time_zone.setValue(daytime[3]);
                  this.selectedTimeZoneDay = daytime[3];
                  this.selectedTimeTuesdayFrom = daytime[1]
                  this.selectedTimeTuesdayTo = daytime[2]
                  let index = this.getTimeIntervalIndex(this.selectedTimeTuesdayFrom);
                  this.tuesdayTimeFrom = this.makeToArray(0);
                  this.tuesdayTimeTo = this.makeToArray(index);
                }, 50);
                const keys = this.timeZoneList.map((data) => {
                  if (data.value == daytime[3]) {
                    let utc = data.value;
                    let s1 =
                      daytime[0] +
                      ' - ' +
                      daytime[1].replace(/-/gi, ':') +
                      ' - ' +
                      daytime[2].replace(/-/gi, ':') +
                      ' (' +
                      utc +
                      ')';
                    this.showTime.push(s1);
                  }
                });
                console.log(keys);
              } else if (element == 'Wednesday') {
                setTimeout(() => {
                  $('#Wednesday').prop('checked', true);
                  this.days.push('Wednesday');
                  this.contactF.wednesday_hour_from.setValue(daytime[1]);
                  this.contactF.wednesday_hour_to.setValue(daytime[2]);
                  this.contactF.wednesday_time_zone.setValue(daytime[3]);
                  this.selectedTimeWednesdayFrom = daytime[1]
                  this.selectedTimeWednesdayTo = daytime[2]
                  this.selectedTimeZoneDay = daytime[3];
                  let index = this.getTimeIntervalIndex(this.selectedTimeWednesdayFrom);
                  this.wednesdayTimeFrom = this.makeToArray(0);
                  this.wednesdayTimeTo = this.makeToArray(index);
                }, 50);
                const keys = this.timeZoneList.map((data) => {
                  if (data.value == daytime[3]) {
                    let utc = data.value;
                    let s1 =
                      daytime[0] +
                      ' - ' +
                      daytime[1].replace(/-/gi, ':') +
                      ' - ' +
                      daytime[2].replace(/-/gi, ':') +
                      ' (' +
                      utc +
                      ')';
                    this.showTime.push(s1);
                  }
                });
                console.log(keys);
              } else if (element == 'Thursday') {
                setTimeout(() => {
                  $('#Thursday').prop('checked', true);
                  this.days.push('Thursday');
                  this.contactF.thursday_hour_from.setValue(daytime[1]);
                  this.contactF.thursday_hour_to.setValue(daytime[2]);
                  this.contactF.thursday_time_zone.setValue(daytime[3]);

                  this.selectedTimeThursdayFrom = daytime[1]
                  this.selectedTimeThursdayTo = daytime[2]

                  this.selectedTimeZoneDay = daytime[3];
                  let index = this.getTimeIntervalIndex(this.selectedTimeThursdayFrom);
                  this.thursdayTimeFrom = this.makeToArray(0);
                  this.thursdayTimeTo = this.makeToArray(index);
                }, 50);
                const keys = this.timeZoneList.map((data) => {
                  if (data.value == daytime[3]) {
                    let utc = data.value;
                    let s1 =
                      daytime[0] +
                      ' - ' +
                      daytime[1].replace(/-/gi, ':') +
                      ' - ' +
                      daytime[2].replace(/-/gi, ':') +
                      ' (' +
                      utc +
                      ')';
                    this.showTime.push(s1);
                  }
                });
                console.log(keys);
              } else if (element == 'Friday') {
                setTimeout(() => {
                  $('#Friday').prop('checked', true);
                  this.days.push('Friday');
                  this.contactF.friday_hour_from.setValue(daytime[1]);
                  this.contactF.friday_hour_to.setValue(daytime[2]);
                  this.contactF.friday_time_zone.setValue(daytime[3]);
                  this.selectedTimeFridayFrom = daytime[1]
                  this.selectedTimeFridayTo = daytime[2]
                  this.selectedTimeZoneDay = daytime[3];
                  let index = this.getTimeIntervalIndex(this.selectedTimeFridayFrom);
                  this.fridayTimeFrom = this.makeToArray(0);
                  this.fridayTimeTo = this.makeToArray(index);
                }, 50);
                const keys = this.timeZoneList.map((data) => {
                  if (data.value == daytime[3]) {
                    let utc = data.value;
                    let s1 =
                      daytime[0] +
                      ' - ' +
                      daytime[1].replace(/-/gi, ':') +
                      ' - ' +
                      daytime[2].replace(/-/gi, ':') +
                      ' (' +
                      utc +
                      ')';
                    this.showTime.push(s1);
                  }
                });
                console.log(keys);
              } else if (element == 'Saturday') {
                setTimeout(() => {
                  $('#Saturday').prop('checked', true);
                  this.days.push('Saturday');
                  this.contactF.saturday_hour_from.setValue(daytime[1]);
                  this.contactF.saturday_hour_to.setValue(daytime[2]);
                  this.contactF.saturday_time_zone.setValue(daytime[3]);
                  this.selectedTimeSaturdayFrom = daytime[1]
                  this.selectedTimeSaturdayTo = daytime[2]
                  this.selectedTimeZoneDay = daytime[3];
                  let index = this.getTimeIntervalIndex(this.selectedTimeSaturdayFrom);
                  this.saturdayTimeFrom = this.makeToArray(0);
                  this.saturdayTimeTo = this.makeToArray(index);
                }, 50);
                const keys = this.timeZoneList.map((data) => {
                  if (data.value == daytime[3]) {
                    let utc = data.value;
                    let s1 =
                      daytime[0] +
                      ' - ' +
                      daytime[1].replace(/-/gi, ':') +
                      ' - ' +
                      daytime[2].replace(/-/gi, ':') +
                      ' (' +
                      utc +
                      ')';
                    this.showTime.push(s1);
                  }
                });
                console.log(keys);
              } else if (element == 'Sunday') {
                setTimeout(() => {
                  $('#Sunday').prop('checked', true);
                  this.days.push('Sunday');
                  this.contactF.sunday_hour_from.setValue(daytime[1]);
                  this.contactF.sunday_hour_to.setValue(daytime[2]);
                  this.contactF.sunday_time_zone.setValue(daytime[3]);
                  this.selectedTimeZoneDay = daytime[3];
                  this.selectedTimeSundayFrom = daytime[1]
                  this.selectedTimeSundayTo = daytime[2]
                  let index = this.getTimeIntervalIndex(this.selectedTimeSundayFrom);
                  this.sundayTimeFrom = this.makeToArray(0);
                  this.sundayTimeTo = this.makeToArray(index);
                }, 50);

                const keys = this.timeZoneList.map((data) => {
                  if (data.value == daytime[3]) {
                    let utc = data.value;
                    let s1 =
                      daytime[0] +
                      ' - ' +
                      daytime[1].replace(/-/gi, ':') +
                      ' - ' +
                      daytime[2].replace(/-/gi, ':') +
                      ' (' +
                      utc +
                      ')';
                    this.showTime.push(s1);
                  }
                });
                console.log(keys);
              } else if (element == 'Anytime' || element == 'Any-Time' || element == 'Any Time') {
                setTimeout(() => {
                  $('#Any-Time').prop('checked', true);
                  this.days.push('Any-Time');
                }, 50);
              } else {
                // // console.log(element,"jay bhai time")
              }
            }
          });
        }
      });

      //time to call
      setTimeout(() => {
        this.days = this.days.filter((item, index) => this.days.indexOf(item) === index);
      }, 60);
    }
  }
  // profile image
  daysTrue() {
    if (!this.days.includes("Any-Time")) {
      if (this.days.includes("Monday")) {
        this.selectedTimeMonday = true
      } else if (!this.days.includes("Monday")) {
        this.selectedTimeMonday = false
      } else if (this.days.includes("Tuesday")) {
        this.selectedTimeTuesday = true
      } else if (!this.days.includes("Tuesday")) {
        this.selectedTimeTuesday = false
      } else if (this.days.includes("Wednesday")) {
        this.selectedTimeWednesday = true
      } else if (!this.days.includes("Wednesday")) {
        this.selectedTimeWednesday = false
      } else if (this.days.includes("Thursday")) {
        this.selectedTimeThursday = true
      } else if (!this.days.includes("Thursday")) {
        this.selectedTimeThursday = false
      } else if (this.days.includes("Friday")) {
        this.selectedTimeFriday = true
      } else if (!this.days.includes("Friday")) {
        this.selectedTimeFriday = false
      } else if (this.days.includes("Saturday")) {
        this.selectedTimeSaturday = true
      } else if (!this.days.includes("Saturday")) {
        this.selectedTimeSaturday = false
      } else if (this.days.includes("Sunday")) {
        this.selectedTimeSunday = true
      } else if (!this.days.includes("Sunday")) {
        this.selectedTimeSunday = false
      }
    } else {
      console.log("hello Days check 14")
    }
  }

  submitForm() {
    const formData: any = new FormData();
    formData.append('image', this.croppedImage);
    formData.append('user_id', this.user_id);
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    };
    console.log(httpOptions);
    this.myprofileService.uploadImage(formData).subscribe((result) => {
      if (result.status) {
        this.notifService.success('Image Uploaded', result.message, this.options);
        this.registerNxtservice
          .getUserDetails({ user_id: this.user_id })
          .subscribe((data: any) => {
            if (data?.userDetails?.profile_photo) {
              this.is_uploaded = true;
              this.Uploaded = true;
              this.croppedImage = data.userDetails.profile_photo;
              this.commonService.updateProfilePhoto(this.croppedImage);

              this.toastrService.success('Successfully!', 'Image Uploaded!');
            }
          });
      } else {
        this.toastrService.error('Message!', 'Image not Uploaded!');

        this.notifService.error(
          'Image not Uploaded',
          result.message,
          this.options
        );
      }
      this.getUserDetails();
    });

  }

  // upload timh code
  uploadFile(event) {
    this.imgSelected = true;
    this.imageOriginalName = event.target.files[0].name;
    this.fileType = event.target.files[0].type;
    this.imageChangedEvent = event;
    // console.log(this.cimg ,"cimg")
    event = this.cimg;
    this.is_uploaded = true;
    if (event?.target && (event.target as HTMLInputElement).files) {
      const file_array = (event.target as HTMLInputElement).files;
      if ((event?.target as HTMLInputElement).files!.length == 1) {
        let fileDetails = file_array![0];
        this.totalMediaSize = fileDetails.size;
        if (this.totalMediaSize <= '5000000') {
          if (
            fileDetails.type == 'image/png' ||
            fileDetails.type == 'image/jpeg' ||
            fileDetails.type == 'image/webp' ||
            fileDetails.type == 'image/x-icon' ||
            fileDetails.type == 'image/bmp' ||
            fileDetails.type == 'image/jpg'
          ) {
            let reader = new FileReader();
            reader.readAsDataURL(fileDetails);
            reader.onload = (evn: any) => {
              this.croppedImage = evn.target.result;
            };
            this.fileUpload = fileDetails;
          } else {
            this.is_uploaded = false;
            this.toastrService.error('Error!', 'Only jpg, jpeg, png, bmp, gif files are allowed to upload');
          }
        } else {
          this.is_uploaded = false;
          this.toastrService.error(
            'Error!',
            'Total File size should be smaller than 5 MB.!'
          );
          this.notifService.error(
            'Error!',
            "File can't be uploaded. Total File size should be smaller than 5 MB.",
            this.options
          );
        }
      } else {
        this.is_uploaded = false;
        this.toastrService.error(
          'Error!',
          'Unable to upload more than one file at a time!'
        );

        this.notifService.error(
          'Error!',
          'Unable to upload more than one file at a time.',
          this.options
        );
      }
    } else {
      this.is_uploaded = false;
      this.notifService.error(
        'Error!',
        "File can't be uploaded. Only Image is allowed!",
        this.options
      );
    }
  }

  fileType: any;
  imageCropped(event: ImageCroppedEvent) {
    let tempImage: any = event.base64;

    fetch(tempImage)
      .then((res) => res.blob())
      .then((blob) => {
        this.croppedImage = new File([blob], this.imageOriginalName, {
          type: this.fileType,
        });
        this.coverCroppedImage = new File([blob], this.imageOriginalName, {
          type: this.fileType,
        });
      });
  }
  fileType1: any;
  imageCropped1(event: ImageCroppedEvent) {
    let tempImage: any = event.base64;

    fetch(tempImage)
      .then((res) => res.blob())
      .then((blob) => {
        //  this.croppedImage = new File([blob], this.imageOriginalName, { type: this.fileType })
        this.coverCroppedImage = new File([blob], this.imageOriginalName, {
          type: this.fileType1,
        });
      });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageLoaded() {
    this.showCropper = true;
    // // console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    //  // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    //  // console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }

  cancelImg() {
    this.imageUploadForm.reset();
    this.getUserDetails();
  }

  // cover photo img
  uploadCover(event: Event) {
    this.coverImgSelected = true;
    this.imageChangedEvent = event;
    event = this.covercimg;
    this.is_uploaded = true;
    if (event?.target && (event.target as HTMLInputElement).files) {
      const file_array = (event.target as HTMLInputElement).files;
      if ((event?.target as HTMLInputElement).files!.length == 1) {
        let fileDetails = file_array![0];
        this.totalMediaSize = fileDetails.size;
        if (this.totalMediaSize <= '5000000') {
          if (
            fileDetails.type == 'image/png' ||
            fileDetails.type == 'image/jpeg' ||
            fileDetails.type == 'image/webp' ||
            fileDetails.type == 'image/x-icon' ||
            fileDetails.type == 'image/bmp' ||
            fileDetails.type == 'image/jpg'
          ) {
            let reader = new FileReader();
            reader.readAsDataURL(fileDetails);
            reader.onload = (evn: any) => {
              this.coverCroppedImage = evn.target.result;
            };
            this.fileUpload = fileDetails;
          } else {
            this.is_uploaded = false;
            this.toastrService.error('Error!', 'Only jpg, jpeg, png, bmp, gif files are allowed to upload');
          }
        } else {
          this.is_uploaded = false;
          this.toastrService.error(
            'Error!',
            'Total File size should be smaller than 5 MB.!'
          );
          this.notifService.error(
            'Error!',
            "File can't be uploaded. Total File size should be smaller than 5 MB.",
            this.options
          );
        }
      } else {
        this.is_uploaded = false;
        this.toastrService.error(
          'Error!',
          'Unable to upload more than one file at a time!'
        );
        this.notifService.error(
          'Error!',
          'Unable to upload more than one file at a time.',
          this.options
        );
      }
    } else {
      this.is_uploaded = false;
      this.notifService.error(
        'Error!',
        "File can't be uploaded. Only Image is allowed!",
        this.options
      );
    }
  }

  submitCoverPicture() {
    this.spinner.show();
    const formData: any = new FormData();
    formData.append('image', this.coverCroppedImage);
    formData.append('user_id', this.user_id);
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    };
    console.log(this.coverCroppedImage.size, "||", httpOptions)
    if (this.coverCroppedImage.size <= '5000000') {
      this.myProfileService.uploadCoverImage(formData).subscribe((result) => {
        if (result.status) {
          this.notifService.success(
            'Image Uploaded',
            result.message,
            this.options
          );
          this.toastrService.success(
            'cover photo uploaded successfully !   '
          );
          this.spinner.hide();
        } else {
          this.toastrService.error('Error!', 'cover photo not Uploaded!');
          this.spinner.hide();
          this.notifService.error(
            'cover photo not Uploaded',
            result.message,
            this.options
          );
        }
        this.getUserDetails();
      });
    } else {
      this.spinner.hide();
      this.toastrService.error('Error!', 'Total File size should be smaller than 5 MB!');
    }

  }

  basicFoodCheck() {
    this.basicAndLifestyleSubmitted = false;
    if (this.basicAndLifestyleForm.value.food_preference === 'Other') {
      this.basicAndLifestyleF.basicOtherFood.setValue('');
      this.isBasicFoodOther = true;
      // Focus on the input field
      this.focusOnInput();
    } else {
      this.isBasicFoodOther = false;
      this.basicAndLifestyleF.basicOtherFood.setValue('');
    }
  }

  private focusOnInput() {
    // Use a timeout to ensure the input is rendered before trying to focus on it
    setTimeout(() => {
      const inputElement = this.el.nativeElement.querySelector('#nameEdit');
      if (inputElement) {
        this.renderer.selectRootElement(inputElement).focus();
      }
    });
  }

  familyTypeCheck() {
    console.log(this.familyDetailForm.value.living_with_family)
    if (this.familyDetailForm.value.living_with_family == 'Yes') {
      this.FamilyTypeSelf = true;
      this.familyDetailF.family_type.setValue("")
    } else {
      this.familyDetailF.family_type.setValue("")
      this.FamilyTypeSelf = false;
    }
  }

  partnerFamilyTypeCheck() {
    if (this.partnerFamilyForm.value.living_with_family == 'Yes') {
      this.FamilyTypePartner = true;
    } else {
      this.selectedPartnerFamilyType = [];
      this.FamilyTypePartner = false;
    }
  }

  showviewvalue: string = 'aboutYou';

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    margin: 24,
    center: true,
    dots: true,
    navSpeed: 700,
    navText: [' < ', ' > '],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      1366: {
        items: 2,
      },
    },
    nav: true,
  };

  // end code
  openEditPreview() {
    this.editId = localStorage.getItem('selectedTab');
    this.editId2 = localStorage.getItem('selectedTab');

    if (this.editId == 'aboutYou') {
      //  document why this block is empty
    } else if (this.editId == 'basicAndLifestyle') {
      console.log(this.editId == 'basicAndLifestyle')
      //  document why this block is empty
    } else if (this.editId == 'astro') {
      console.log(this.editId == 'astro')
      //  document why this block is empty
    } else if (this.editId == 'education') {
      console.log(this.editId == 'education')
      //  document why this block is empty
    } else if (this.editId == 'MyPhotos') {
      console.log(this.editId == 'MyPhotos')
      //  document why this block is empty
    } else if (this.editId == 'contact') {
      console.log(this.editId == 'contact')
      //  document why this block is empty
    } else if (this.editId == 'religion') {
      //  document why this block is empty
    } else if (this.editId == 'familyDetails') {
      //  document why this block is empty
    } else if (this.editId == 'hobby') {
      //  document why this block is empty
    } else if (this.editId == 'location') {
      //  document why this block is empty
    }
  }

  inCompleteFields() {
    let aboutYou = ["first_name", "last_name", "languages", "marital_status", "have_children", "food_preference", "height", "weight"]
    let educationAndProfession = ["qualification", "nature_of_work"];
    let astro = ["dob", "country_of_birth"]
    let religion = ["religion"]
    let location = ["living_in_country", "state_name", "current_city", "postal_code", "residency_status"]
    let familydetail = ["living_with_family"]
    let contactDetail = ["convenient_time_to_call"]
    let photos = ["profile_photo", "cover_photo"]
    let notChangeAble = ["mobile_no", "email", "gender"]
    let partnerDetail = ["have_children", "country_name", "state_name"]
    this.inCompleteUserField = []
    this.inCompletePartnerField = []

    let userField = localStorage.getItem('inCompleteUserDetail')?.split(',');
    let partnerField = localStorage.getItem('inCompletePatnerDetailDetail')?.split(',');
    console.log(userField, "|sagar|", partnerField)
    if (userField && userField.length > 0) {
      userField.forEach(element => {

        if (aboutYou.includes(element)) {
          if (element == 'have_children' && !this.details.have_children) {
            console.log("About You > Have Children")
            this.inCompleteUserField.push("About You > Have Children ")
          }
        } else if (educationAndProfession.includes(element)) {
          // unused code
          // console.log("Education And Profession  toster")
          // this.inCompleteUserField.push(element)
        } else if (astro.includes(element)) {
          if (element == 'country_of_birth' && !this.details.country_of_birth) {
            console.log("Astro Details > Country of Birth ")
            this.inCompleteUserField.push("Astro Details > Country of Birth ")
          }
        } else if (religion.includes(element)) {
          // unused code
          // console.log("Religion  toster")
          // this.inCompleteUserField.push(element)
        } else if (location.includes(element)) {
          if (element == 'postal_code') {
            console.log("Your Location > Zip/Pin/Postal Code ")
            this.inCompleteUserField.push("Your Location > Zip/Pin/Postal Code ")
          }
        } else if (familydetail.includes(element)) {
          this.inCompleteUserField.push(element)
        } else if (contactDetail.includes(element)) {
          if (element == 'convenient_time_to_call' && !this.details.convenient_time_to_call) {
            console.log("Contact Details > Convenient Time To Call ")
            this.inCompleteUserField.push("Contact Details > Convenient Time To Call ")
          }
        } else if (photos.includes(element)) {
          if (element == 'profile_photo' && !this.details.profile_photo) {
            console.log(" Please upload profile photo ")
            this.inCompleteUserField.push("Please Upload Profile photo")
          } else if (element == 'cover_photo' && !this.details.cover_photo) {
            console.log("Please upload cover photo")
            this.inCompleteUserField.push("Please Upload Cover photo")
          }
        } else if (notChangeAble.includes(element)) {
          // console.log("Not Change  toster")
        } else {
          console.log("happy  toster")
        }
      });
    }
    if (partnerField && partnerField.length > 0) {
      partnerField.forEach(element => {
        if (partnerDetail.includes(element)) {
          if (element == 'have_children' && !this.partnerDetail.have_children) {
            console.log("About Partner Preferences > Basics and Lifestyle > Have Children ")
            this.inCompletePartnerField.push("Partner Preferences > Basics and Lifestyle > Have Children ")
          } else if (element == 'country_name' && !this.partnerDetail.country_name) {
            console.log("About Partner Preferences > Location of Partner > Country  ")
            this.inCompletePartnerField.push("Partner Preferences > Location of Partner > Country ")
          } else if (element == 'state_name' && !this.partnerDetail.state_name) {
            console.log("About Partner Preferences >Location of Partner > State ")
            this.inCompletePartnerField.push("Partner Preferences > Location of Partner > State")
          }
        }
      });
    }
    if ((userField && userField.length > 0) || (partnerField && partnerField.length > 0)) {
      $('#fields').click();
    }
    if (!userField || userField.length == 0) {
      localStorage.removeItem('inCompleteUserDetail');
    }
    if (!partnerField || partnerField.length == 0) {
      localStorage.removeItem('inCompletePatnerDetailDetail');
    }
  }

  removeNotification() {
    localStorage.removeItem('inCompleteUserDetail');
    localStorage.removeItem('inCompletePatnerDetailDetail');
  }

  openEditPartnerPrefrence() {
    this.editId = 'aboutYou';
    setTimeout(() => {
      this.scrollToDiv();
    }, 3000);
  }

  scrollToDiv() {
    console.log("hello scroll")
    const element = this.el.nativeElement.querySelector('#scrollDiv'); // Replace 'yourDivId' with the actual ID of your div
    if (element) {
      const targetPosition = window.scrollY + 1160;
      window.scrollTo({ top: targetPosition, behavior: 'smooth' });
    }
  }

  wordCounteraboutMe(event) {
    this.exceedLimit = false;
    let para = this.details.description;
    if (para.trim().length > 0) {
      this.wordCount1 = this.aboutMe
        ? this.aboutMe.nativeElement.value.split(/\s+/)
        : 0;
      this.words1 = this.wordCount1 ? this.wordCount1.length : 0;
      if (this.words1 > 100) {
        this.exceedLimit = true;
      } else {
        this.exceedLimit = false;
      }
      if (
        this.words1 >= 101 &&
        event.code != 'Backspace' &&
        event.code != 'Delete'
      ) {
        // before sonarqube
        // const pattern = /[a-zA-Z0-9&_\.!,-\s()"']/g;
        const pattern = /[a-zA-Z0-9&_.!,\-\s()"']/g;
        if (!pattern.test(event.target.value)) {
          event.preventDefault();
          return false;
        } else {
          event.target.value = event.target.value.replace(
            // before sonarqube
            // /[a-zA-Z0-9&_\.!,-\s()']/g,
            // ''
            /[a-zA-Z0-9&_.,!\-\s()']/g,
            ''
          );
          // before sonarqube
          // this.tellUsMore = this.aboutPartner.replace(/  +/g, ' ');
          this.tellUsMore = this.aboutPartner.replace(/ {2,}/g, ' ');
          this.aboutYouForm.patchValue({ 'description': this.aboutPartner.trim() + ' ' });
          this.words1 = this.aboutPartner.split(/\s+/).length
          event.preventDefault();
          return false;
        }
      } else {
        this.aboutPartner = this.aboutYouForm.value.description;
      }
    } else {
      this.words1 = 0;
    }
  }

  pasteNumeric8(event) {
    setTimeout(() => {
      this.wordCount1 = this.aboutMe
        ? this.aboutMe.nativeElement.value.split(/\s+/)
        : 0;
      this.words1 = this.wordCount1 ? this.wordCount1.length : 0;

      if (this.words1 >= 101) {
        // 
        this.wordCount1 = this.wordCount1.slice(0, 100);
        this.words1 = this.wordCount1 ? this.wordCount1.length : 0;
        this.aboutYouForm.patchValue({
          'description': this.wordCount1.join(' '),
        });
        if (this.words1 > 100) {
          this.exceedLimit = true;
        } else {
          this.exceedLimit = false;
        }
      }
    }, 500);
    //
  }

  wordCounteraboutMe2(event) {
    this.exceedLimit1 = false;
    let para = this.partnerDetail.description;
    if (para.trim().length > 0) {
      this.wordCount2 = this.aboutMe2
        ? this.aboutMe2.nativeElement.value.split(/\s+/)
        : 0;
      this.words2 = this.wordCount2 ? this.wordCount2.length : 0;
      if (this.words2 > 100) {
        this.exceedLimit1 = true;
      } else {
        this.exceedLimit1 = false;
      }
      if (
        this.words2 >= 101 &&
        event.code != 'Backspace' &&
        event.code != 'Delete'
      ) {
        // before sonarqube
        // const pattern = /[a-zA-Z0-9&_\.!,-\s()"']/g;
        const pattern = /[a-zA-Z0-9&_.!,\-\s()"']/g;
        if (!pattern.test(event.target.value)) {
          event.preventDefault();
          return false;
        } else {
          event.target.value = event.target.value.replace(
            // before sonarqube
            // /[a-zA-Z0-9&_\.!,-\s()']/g,
            // ''
            /[a-zA-Z0-9&_.,!\-\s()']/g,
            ''
          );
          // before sonar
          // this.tellUsMore = this.aboutPartner2.replace(/  +/g, ' ');
          this.tellUsMore = this.aboutPartner.replace(/ {2,}/g, ' ');
          this.partnerBasicForm.patchValue({ 'description': this.aboutPartner2.trim() + ' ' });
          this.words2 = this.aboutPartner2.split(/\s+/).length
          event.preventDefault();
          return false;
        }
      } else {
        this.aboutPartner2 = this.partnerBasicForm.value.description;
      }
    }
    else {
      this.words2 = 0;
    }
  }

  pasteNumeric82(event) {
    setTimeout(() => {
      this.wordCount2 = this.aboutMe2
        ? this.aboutMe2.nativeElement.value.split(/\s+/)
        : 0;
      this.words2 = this.wordCount2 ? this.wordCount2.length : 0;
      if (this.words2 > 100) {
        this.exceedLimit1 = true;
      } else {
        this.exceedLimit1 = false;
      }
      if (this.words2 > 101) {
        // 
        this.wordCount2 = this.wordCount2.slice(0, 100);
        this.words2 = this.wordCount2 ? this.wordCount2.length : 0;
        this.partnerBasicForm.patchValue({
          'description': this.wordCount2.join(' '),
        });

        if (this.words2 > 100) {
          this.exceedLimit1 = true;
        } else {
          this.exceedLimit1 = false;
        }
      }
    }, 500);
  }


  ngAfterViewInit() {
    this.notClickable = false;
    let selectedTab = localStorage.getItem('selectedTab');
    let selectedTabpp = localStorage.getItem('selectedTabpp');
    if (selectedTab) {
      this.openEditPreview();
      console.log(this.editId, 'aboutYou', 'this.editId');
      localStorage.removeItem('selectedTab');

    } else if (selectedTabpp) {
      this.openEditPartnerPrefrence();
      localStorage.removeItem('selectedTabpp');
    }
    else {
      this.editId = 'aboutYou';
      this.editId2 = '';
    }
  }

  ngAfterContentChecked() {
    // console.log('ngAfterContentChecked')
  }

  ngAfterViewChecked() {
    // console.log('ngAfterViewChecked')
  }

  ngDoCheck() {
    // console.log('ngDoCheck')
  }

  ngAfterContentInit() {
    // console.log('ngAfterContentInit')
  }

  // validation functions

  //after sonarqube
  // NameSpaceNotAllowed(event: any) {
  //   let RegEx = new RegExp(/^([a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"])*$/g);
  //   let str = event.target.value
  //   if (RegEx.test(str)) {
  //     return event.target.value
  //   } else {
  //     var nameString = str.replace(/\s+/g, ' ');
  //     var nameString = str.replace(/[^a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"]+/g, ' ');
  //     event.target.value = nameString.replace(/^\s+/g, "");
  //   }
  // }


  //after sonarqube
  NameSpaceNotAllowed(event: any) {
    let RegEx = new RegExp(/^([a-zA-Z\d!@#$&()\-.+,%*=~[\]{}\\<>?'":])*$/g);
    let str = event.target.value;

    if (RegEx.test(str)) {
      return event.target.value;
    } else {
      let nameString = str.replace(/\s+/g, ' ');
      nameString = nameString.replace(/[^a-zA-Z\d!@#$&()\-.+,%*=\|~[\]{}\\<>\?'";]+/g, ' ');
      event.target.value = nameString.replace(/^\s+/g, "");
    }
  }

  pasteText(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedNumber = clipboardData.getData('text');
    let newNumber = pastedNumber.replace(/[^a-zA-Z]/g, "");
    this.aboutYouForm.patchValue({ 'first_name': newNumber });
    setTimeout(() => {
      this.aboutYouF.first_name.setValue(newNumber);
    }, 100);
  }

  pasteTextLastname(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedNumber = clipboardData.getData('text');
    let newNumber = pastedNumber.replace(/[^a-zA-Z]/g, "");
    this.aboutYouForm.patchValue({ 'last_name': newNumber });
    setTimeout(() => {
      this.aboutYouF.last_name.setValue(newNumber);
    }, 100);
  }

  omit_special_char(event) {
    let k;
    k = event.charCode;  //k = event.keyCode; (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  openTabSection() {
    this.addBorderClass();
    window.scrollTo(0, 630);
    // before sonar
    // if (this.isEditAboutYou == true) {
    //   this.closeAboutYou();
    // } else if (this.isEditBasic == true) {
    //   this.closeBasicLifeStyle();
    // } else if (this.isEditReligion == true) {
    //   this.closeReligion();
    // } else if (this.isEditAstro == true) {
    //   this.closeAstro();
    // } else if (this.isEditFamily == true) {
    //   this.closeFamily();
    // } else if (this.isEditEducation == true) {
    //   this.closeEducation();
    // } else if (this.isEditHobbies == true) {
    //   this.closeHobbies();
    // } else if (this.isEditLocation == true) {
    //   this.closeLocation();
    // } else if (this.isEditContactDetail == true) {
    //   this.closeContactDetail();
    // } else if (this.isEditHandOverForm == true) {
    //   this.closeHandOver();
    // }

    // after sonar
    if (this.isEditAboutYou) {
      this.closeAboutYou();
    } else if (this.isEditBasic) {
      this.closeBasicLifeStyle();
    } else if (this.isEditReligion) {
      this.closeReligion();
    } else if (this.isEditAstro) {
      this.closeAstro();
    } else if (this.isEditFamily) {
      this.closeFamily();
    } else if (this.isEditEducation) {
      this.closeEducation();
    } else if (this.isEditHobbies) {
      this.closeHobbies();
    } else if (this.isEditLocation) {
      this.closeLocation();
    } else if (this.isEditContactDetail) {
      this.closeContactDetail();
    } else if (this.isEditHandOverForm) {
      this.closeHandOver();
    }

    this.educationF.otherProfession.setValue('');
    this.partnerBasicF.otherLanguage.setValue('');
    this.partnerBasicF.otherFood.setValue('');
    this.partnerReligionF.otherReligion.setValue('');
    this.partnerEducationF.otherEducation.setValue('');
    this.partnerEducationF.otherNatureOfWork.setValue('');
    this.partnerEducationF.otherProfession.setValue('');
    this.partnerLocationF.otherResidencyStatus.setValue('');

    this.isEditPartnerBasic = false;
    this.isEditPartnerReligion = false;
    this.isEditPartnerFamily = false;
    this.isEditPartnerLocation = false;
    this.isEditPartnerEducation = false;

    this.isAboutYouLanguageOther = false;
    this.isBasicFoodOther = false;
    this.isReligionOther = false;
    this.isQualificationOther = false;
    this.isNatureOfWorkOther = false;
    this.isProfessionOther = false;
    this.isResidencyOther = false;
    this.partnerOtherLanguage = false;
    this.partnerOtherFood = false;
    this.partnerOtherReligion = false;
    this.partnerOtherEducation = false;
    this.partnerOtherNatureOfWork = false;
    this.partnerOtherProfession = false;
    this.partnerOtherResidencyStatus = false;
  }

  openPartnerTabSection() {
    this.addBorderClass();
    // before sonar
    // if (this.isEditContactDetail == true) {
    //   this.resetContactDetail();
    // }
    // window.scroll(0, 1185);
    // if (this.isEditPartnerBasic == true) {
    //   this.closePartnerBasic();
    // } else if (this.isEditPartnerReligion == true) {
    //   this.closePartnerReligion();
    // } else if (this.isEditPartnerFamily == true) {
    //   this.closePartnerFamily();
    // } else if (this.isEditPartnerLocation == true) {
    //   this.closePartnerLocation();
    // } else if (this.isEditPartnerEducation == true) {
    //   this.closePartnerEducation();
    // }

    // before sonar
    if (this.isEditContactDetail) {
      this.resetContactDetail();
    }
    window.scroll(0, 1185);
    if (this.isEditPartnerBasic) {
      this.closePartnerBasic();
    } else if (this.isEditPartnerReligion) {
      this.closePartnerReligion();
    } else if (this.isEditPartnerFamily) {
      this.closePartnerFamily();
    } else if (this.isEditPartnerLocation) {
      this.closePartnerLocation();
    } else if (this.isEditPartnerEducation) {
      this.closePartnerEducation();
    }

    this.isEditAboutYou = false;
    this.isEditBasic = false;
    this.isEditReligion = false;
    this.isEditAstro = false;
    this.isEditFamily = false;
    this.isEditEducation = false;
    this.isEditHobbies = false;
    this.isEditLocation = false;
    this.isEditContactDetail = false;
    this.isEditHandOverForm = false;

    this.educationF.otherProfession.setValue('');
    this.partnerBasicF.otherLanguage.setValue('');
    this.partnerBasicF.otherFood.setValue('');
    this.partnerReligionF.otherReligion.setValue('');
    this.partnerEducationF.otherEducation.setValue('');
    this.partnerEducationF.otherNatureOfWork.setValue('');
    this.partnerEducationF.otherProfession.setValue('');
    this.partnerLocationF.otherResidencyStatus.setValue('');

    this.isAboutYouLanguageOther = false;
    this.isBasicFoodOther = false;
    this.isReligionOther = false;
    this.isQualificationOther = false;
    this.isNatureOfWorkOther = false;
    this.isProfessionOther = false;
    this.isResidencyOther = false;
    this.partnerOtherLanguage = false;
    this.partnerOtherFood = false;
    this.partnerOtherReligion = false;
    this.partnerOtherEducation = false;
    this.partnerOtherNatureOfWork = false;
    this.partnerOtherProfession = false;
    this.partnerOtherResidencyStatus = false;
  }

  updateHandOver() {
    this.handoverSubmitted = true;

    this.handOverButton = true;

    if (this.handoverForm.invalid) {
      this.handOverButton = false;
      return;

    }
    let country_number_code1 = this.handoverForm.value.country_code_to;
    this.countries.forEach((country) => {
      if (this.countryNumberTo == country.id) {
        country_number_code1 = country.country_code;
      }
    });

    console.log(this.countryNumberTo, 'this.countryNumberTo');
    let data = {
      user_id: this.user_id,
      email: this.handoverForm.value.email_to,
      country_number_code: country_number_code1,
      mobile_no: this.handoverForm.value.mobile_no_to,
      country_code: this.countryNumberTo
    };
    this.myProfileService.updateProfileDetails(data).subscribe(
      (result) => {
        if (result.status) {
          this.getUserDetails();
          this.getUser();
          this.isEditHandOverForm = false;
          this.toastrService.success(result.message);
          this.closeHandOver();
          this.handoverSubmitted = true;
          this.handOverButton = false;
        } else {
          this.toastrService.error(result.message);
          this.isEditHandOverForm = false;
          this.handoverSubmitted = true;
          this.handOverButton = false;
        }
      },
      (error) => {
        this.isEditHandOverForm = false;;
        this.toastrService.error('Message!', 'Handover Not Update!');
        this.closeHandOver();
        this.handoverSubmitted = true;
        this.handOverButton = false;
      }
    );
    this.closeHandOver();
  }

  keyPressNumericMobile(event) {
    const pattern = /\D/g;
    if (this.handoverForm.value.mobile_no_to.length < 6) {
      this.minNumberLength = true;
      this.maxNumberLength = false;
    } else if (this.handoverForm.value.mobile_no_to.length > 15) {
      this.minNumberLength = false;
      this.maxNumberLength = true;
    } else {
      this.minNumberLength = false;
      this.maxNumberLength = false;
    }
    if (!pattern.test(event.target.value) && (this.handoverForm.value.mobile_no_to.length > 6 && this.handoverForm.value.mobile_no_to.length < 16)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumericMobileTo(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.contactForm.patchValue({ 'mobileNo': newText });
  }

  checkpackage() {
    console.log(this.details.register_done, ' this.details.register_done')
    this.memberPackageId = localStorage.getItem('package_id1');
    if (this.memberPackageId == 1 && this.details.register_done == 7) {
      $('#openModal1').click();
      localStorage.removeItem('package_id1');
    } else {
      // unused code
      // localStorage.removeItem('package_id1');
    }
  }

  onChangeCountry1(event: any) {
    console.log(event);
  }

  // before sonar
  // closeTabOnEdit() {
  //   if (this.isEditPartnerBasic == true) {
  //     this.isEditPartnerBasic = false;
  //   } else if (this.isEditPartnerReligion == true) {
  //     this.isEditPartnerReligion = false;
  //   } else if (this.isEditPartnerFamily == true) {
  //     this.isEditPartnerFamily = false;
  //   } else if (this.isEditPartnerLocation == true) {
  //     this.isEditPartnerLocation = false;
  //   } else if (this.isEditPartnerEducation == true) {
  //     this.isEditPartnerEducation = false;
  //   }
  // }

  //after sonarqube
  closeTabOnEdit() {
    if (this.isEditPartnerBasic) {
      this.isEditPartnerBasic = false;
    } else if (this.isEditPartnerReligion) {
      this.isEditPartnerReligion = false;
    } else if (this.isEditPartnerFamily) {
      this.isEditPartnerFamily = false;
    } else if (this.isEditPartnerLocation) {
      this.isEditPartnerLocation = false;
    } else if (this.isEditPartnerEducation) {
      this.isEditPartnerEducation = false;
    }
  }

  addBorderClass() {
    $('#aboutYou')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#basicAndLifestyle')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#religion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#familyDetails')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#astro')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#education')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#hobby')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#location')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#contact')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerBasic')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerReligion')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerEducation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerLocation')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
    $('#partnerFamily')
      .find('.row')
      .find('.col-12')
      .find('.pb-1')
      .removeClass('removeBorder');
  }
}

function sortedDays() {
  throw new Error('Function not implemented.');
}
