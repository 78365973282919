import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Title/*, Meta */ } from '@angular/platform-browser';
import { AuthService } from '../shared/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ViewportScroller } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { PartnerPreferenceService } from '../services/partner-preference.service';
import { MyProfileService } from '../services/my-profile.service';
import { NotifService } from '../services/notif.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { CommonService } from '../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import {/* FormBuilder,*/ FormGroup, FormControl, Validators, NgForm, } from '@angular/forms';



@Component({
  selector: 'app-matches-shortlist',
  templateUrl: './matches-shortlist.component.html',
  styleUrls: ['./matches-shortlist.component.css']
})
export class MatchesShortlistComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  value: number = 18;
  highValue: number = 100;
  options: Options = {
    floor: 18,
    ceil: 90,
  };

  minValue: number = 0;
  maxValue: number = 230;
  options2: Options = {
    floor: 100,
    ceil: 230,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return +value + ' cm';
        case LabelType.High:
          return +value + ' cm';
        default:
          return '';
      }
    },
  };


  minValue3: number = 0;
  maxValue3: number = 150;
  options3: Options = {
    floor: 70,
    ceil: 150,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return +value + ' lb';
        case LabelType.High:
          return +value + ' lb';
        default:
          return '';
      }
    },
  };


  minValue4: number = 0;
  maxValue4: number = 100000000000;
  options4: Options = {
    floor: 0,
    ceil: 100000000000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "$" + value;
        case LabelType.High:
          return "$" + value;
        default:
          return '';
      }
    }
  };

  hidePageSize = true;

  inboxLoader: boolean = false;
  user_id: any;
  matches: any = [];
  sort_by: "";
  selectId: any;
  selectedIds: any = [];
  sortBy: any = '';
  current_sort: string = 'default';
  profileList: any = [];
  profile: any;

  matchedProfilesCount: any = 0;
  shortlistedProfilesCount: number = 0;
  favouriteProfilesCount: number = 0;
  viewedByProfilesCount: number = 0;
  currentPage: number = 1;
  pageSize: number = 8;
  TotalRecord: number = 0;
  showPagination: boolean = false;
  profile_ids: any;
  compareCheck: any;
  lastPage: any;
  encrypted_id: string | undefined;
  EliteProfile: boolean;
  displayName: any;
  package_id: any;
  //package_id:number = 0;
  massage: boolean = false;
  modalShown: boolean = false;

  otherReason: boolean = false;
  reportReason: string = ""
  reportReasonsList: any = [
    { id: 1, label: "Harassment or Abuse" },
    { id: 2, label: "Unwanted Attention" },
    { id: 3, label: "Spam" },
    { id: 4, label: "Scam or Fraud" },
    { id: 5, label: "Disrespectful Behavior" },
    { id: 6, label: "Privacy Concerns" },
    { id: 7, label: "Fake or Suspicious Profiles" },
    { id: 8, label: "Abusive or Threatening Behavior" },
    { id: 9, label: "Explicit Content" },
    { id: 10, label: "Violation of Platform Rules" },
    { id: 11, label: "Other" }
  ];
  profileId: any;
  @ViewChild('wordCountForm') wordCountNgForm: NgForm;
  @ViewChild("texts") texts: ElementRef;
  wordCountForm = new FormGroup({
    user_id: new FormControl(''),
    description: new FormControl('', [Validators.required]),
  })
  wordCount: any;
  words: any;
  tellUsMore: any;
  exceedLimit: boolean;
  constructor(
    private titleService: Title,
    private authService: AuthService,
    private cookieService: CookieService,
    private scroller: ViewportScroller,
    private partnerPreferenceService: PartnerPreferenceService,
    private myProfileService: MyProfileService,
    private NotifService: NotifService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastrService: ToastrService,
    private CommonService: CommonService
  ) {
    localStorage.setItem('currentUrl', this.router.url);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.titleService.setTitle('My Shortlist | eternalstrings.com');
    this.user_id = parseInt(this.cookieService.get('user_id'));
    this.matchedProfilesCount = localStorage.getItem('matchedProfilesCount');
    this.package_id = localStorage.getItem("package_id");

    let modalShownBefore = localStorage.getItem('shortListModal');
    if (modalShownBefore != null && modalShownBefore != '') {
      localStorage.removeItem('shortListModal');
      $('#openModal2').click();
    }
    this.getShortedProfiles();
  }

  checkMarkProfiles() {
    let ids: any = localStorage.getItem("profile_ids");
    if (ids && !!ids) {
      let idsArr = ids.split(',');
      this.selectedIds = []
      for (let j in this.profileList) {
        //  console.log('profileList:: ',this.profileList[j]['user_id'])
        if (idsArr.length > 0) {
          let isFound = false;
          for (let i in idsArr) {
            //  console.log('profiles:: ',idsArr[i])
            if (idsArr[i] == this.profileList[j]['user_id']) {
              this.profileList[j]['checkedbox'] = true;
              isFound = true;
              break;
            } else {
              this.profileList[j]['checkedbox'] = false;
            }
          }
          if (isFound) {
            this.selectedIds.push(this.profileList[j]['user_id'])
          }
        }
      }
    }
  }

  ViewProfile(id, Name) {
    localStorage.setItem("pageFrom", "Matches");
    let pid: any = localStorage.getItem('package_id');
    if (pid > 0) {
      let encrypted_id: any = this.CommonService.encryptData(id);
      encrypted_id = encrypted_id.toString().replaceAll('=', 'Ar1t').replaceAll('/', 'FGH').replaceAll('+', 'THD');
      let url = `${environment.baseURL}` + 'detail/' + encrypted_id
      window.open(url, '_blank');
    } else {
      this.displayName = Name;
      this.massage = true;
      $('#openModal1').click();
    }
  }

  getShortedProfiles() {
    let data = {
      user_id: this.user_id,
      sort_by: this.sortBy,
      pageNo: this.currentPage,
      itemPerPage: this.pageSize,
    }
    this.inboxLoader = true;
    this.myProfileService.getShortedProfiles(data).subscribe(
      (result) => {
        if (result.status) {
          let data1 = result.pages.data;
          const data2 = { checkedbox: false };
          for (let i = 0; i < data1.length; i++) {
            data[i] = Object.assign(data1[i], data2);
          }
          this.profileList = data1;
          this.currentPage = result.pages.current_page
          this.shortlistedProfilesCount = result.shortlistedProfilesCount;
          this.favouriteProfilesCount = result.favouriteProfilesCount;
          this.viewedByProfilesCount = result.viewedByProfilesCount;
          this.TotalRecord = result.pages.total;
          this.currentPage = result.pages.current_page;
          this.lastPage = result.pages.last_page;
          this.checkMarkProfiles()
          if (this.TotalRecord >= this.pageSize) {
            //   console.log("got data")
            this.showPagination = true
          } else {
            this.showPagination = false
          }
          if (this.profileList.length > 0) {
            // unused code
            //this.toastrService.success('Successfully!', 'shortlistedProfiles!');
            console.log()
          } else {
            // unused code
            //this.toastrService.success('You have no profiles in my shortlist! ');
          }
        } else {
          this.toastrService.error(result.message);
        }
        this.inboxLoader = false;
      }, (error) => {                              //Error callback
        this.inboxLoader = false;
      })
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.getShortedProfiles();
  }

  sortedBy(event, current_sort: any = 'default') {
    if (this.sortBy != event.target.id) {
      this.sortBy = event.target.id;
      this.current_sort = current_sort;
      this.toastrService.success('List sorted!');
      this.getShortedProfiles();
    }
  }

  getId(event) {
    // console.log(event.target.id)
    this.selectId = event.target.id
  }

  CompareId(event, i) {
    let Sid = parseInt(event.target.id)
    this.profileList[i]['checkedbox'] = event.target.checked;

    if (this.selectedIds.some(a => a === Sid)) {
      this.selectedIds = this.selectedIds.filter(a => a !== Sid)
    } else {
      if (this.selectedIds.length >= 4 && event.target.checked) {
        setTimeout(() => {
          this.profileList[i]['checkedbox'] = false;
          this.toastrService.error('You can compare only four profiles at a time');
        }, 200);
      } else if (event.target.checked) {
        this.selectedIds.push(Sid)
      }
    }
  }

  compareSelectedProfiles() {
    this.massage = false;
    if (this.package_id > 1 && this.selectedIds.length > 1 && this.selectedIds.length <= 4) {
      localStorage.setItem('profile_ids', this.selectedIds);
    }
  }

  addToFavoriteList(profile_id: any) {
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
    }
    this.myProfileService.addToFavoriteList(pload).subscribe(
      (result) => {
        if (result.status) {
          this.toastrService.success(result.message);
          this.getShortedProfiles();
        } else {
          this.toastrService.error(result.message);
        }
      })
  }

  actionSelected(event: any, profile_id: any) {
    this.selectId = profile_id;
    let std = event.target.id;
    if (std == "add_to_my_favourite") {
      let pload = {
        user_id: this.user_id,
        profile_id: this.selectId,
      }
      this.myProfileService.addToFavoriteList(pload).subscribe(
        (result) => {
          if (result.status) {
            this.toastrService.success(result.message);
            this.getShortedProfiles();
            this.IdCheck(profile_id);
          } else {
            this.toastrService.error(result.message);
          }
        })

    } else if (std == "report_profile") {
      let data = {
        user_id: this.user_id,
        profile_id: this.selectId,
        action: std,
      }
      this.myProfileService.reportProfile(data).subscribe(
        (result) => {
          if (result.status) {
            this.getShortedProfiles();
            this.toastrService.success(result.message);

          } else {
            this.toastrService.error(result.message);
          }
        })
    } else if (std == "remove_from_shortlist") {
      let data = {
        user_id: this.user_id,
        profile_id: this.selectId,
        action: std,
      }
      this.myProfileService.addToShortlist(data).subscribe(
        (result) => {
          if (result.status) {
            this.getShortedProfiles();
            this.IdCheck(profile_id);
            this.toastrService.success(result.message);

          } else {
            this.toastrService.error(result.message);
          }
        })
    } else {
      let data = {
        user_id: this.user_id,
        profile_id: this.selectId,
        action: std,
      }
      this.myProfileService.actionOnProfile(data).subscribe(
        (result) => {
          if (result.status) {
            this.getShortedProfiles();
            if (std == "block_profile") {
              this.toastrService.success(result.message);
              console.log()
            } else if (std == "do_not_show_again") {
              this.toastrService.success(result.message);
            } else {
              this.toastrService.success(result.message);
            }
          } else {
            this.toastrService.error(result.message);
          }
        })
    }
  }

  IdCheck(id) {
    let Sid = String(id)
    if (this.selectedIds.some(a => a === Sid)) {
      this.selectedIds = this.selectedIds.filter(a => a !== Sid);
    }
    localStorage.setItem('profile_ids', this.selectedIds);
  }
  //  getUserPackage(data:any){
  //   this.package_id=data.userDetails.package_id;
  //   console.log(data,"balram")
  //   console.log(this.package_id)
  // }

  blockReportId(user) {
    if (user.label == "Other") {
      this.otherReason = true;
      this.reportReason = ""
    } else {
      this.otherReason = false
      this.reportReason = user.label
    }
    console.log(user, "id and label", this.reportReason)
  }

  blockAndReportNow() {
    let data = {
      user_id: this.user_id,
      profile_id: this.profileId,
      action: "block_and_report",
      reason: this.reportReason
    };
    // console.log("api called", data)
    this.myProfileService.doActionOnRequests(data).subscribe(
      (result: any) => {
        if (result.status) {
          this.toastrService.success(result.message);
          this.getShortedProfiles();

        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        // unused code
        // this.ChatId = 0;
        // this.toastrService.error();
      }
    );
  }

  userBlockReport(user) {
    this.profileId = user.user_id
    this.clear();
  }

  clear() {
    // console.log("before ", this.reportReason)
    this.reportReason = ""
    this.exceedLimit = false;
    this.otherReason = false
    let ele = document.getElementsByName("Serious");
     // before sonar
    // for (var i = 0; i < ele.length; i++) {
    //   var element = ele[i] as HTMLInputElement;
    //   element.checked = false;
    // }
    //after sonar
    for (const element of Array.from(ele) as HTMLInputElement[]) {
      element.checked = false;
    }
  }

  wordCounter(event) {
    let para = this.wordCountForm?.value?.description;
    if (para.trim().length > 0) {
      this.wordCount = this.texts ? this.texts.nativeElement.value.split(/\s+/) : 0;
      this.words = this.wordCount ? this.wordCount.length : 0;
      if (this.words >= 21 && event.code != 'Backspace' && event.code != 'Delete') {
        this.wordError();
          // before sonarqube
        // const pattern = /[a-zA-Z0-9&_\.!,-\s()"']/g;
        const pattern = /[a-zA-Z0-9&_.!,\-\s()"']/g;
        if (!pattern.test(event.target.value)) {
          event.preventDefault();
          return false;
        } else {
          // before sonarqube
          // event.target.value = event.target.value.replace(/[a-zA-Z0-9&_\.!,-\s()']/g, "");
          // this.tellUsMore = this.tellUsMore.replace(/  +/g, ' ');
          event.target.value = event.target.value.replace(/[a-zA-Z0-9&_.,!\-\s()']/g, "");
          this.tellUsMore = this.tellUsMore.replace(/ {2,}/g, ' ');
          this.wordCountForm.patchValue({ 'description': this.tellUsMore.trim() + ' ' });
          this.words = this.tellUsMore.split(/\s+/).length
          event.preventDefault();
          return false;
        }
      } else {
        this.wordError();
        this.tellUsMore = this.wordCountForm?.value?.description;
      }
    } else {
      this.wordError();
      this.words = 0;
    }
  }

  wordError() {
    if (this.words > 20) {
      this.exceedLimit = true;
    } else {
      this.exceedLimit = false;
    }
  }

  pasteNumeric6(event) {
    setTimeout(() => {
      let para = this.wordCountForm?.value?.description;
      if (para.trim().length > 0) {
        this.wordCount = this.texts ? this.texts.nativeElement.value.split(/\s+/) : 0;
        this.words = this.wordCount ? this.wordCount.length : 0;
        if (this.words >= 21) {
          this.wordCount = this.wordCount.slice(0, 20);
          this.words = this.wordCount ? this.wordCount.length : 0;
          this.wordCountForm.patchValue({ 'description': this.wordCount.join(" ") });
          this.wordError();
        }
      } else {
        this.wordError();
        this.words = 0;
      }
    }, 500);
  }

  pos = "";
  compare() {
    let pre = this.wordCount?.value?.description;
    console.log(pre, this.wordCount?.value?.description)
    setTimeout(() => {
      this.pos = this.wordCount?.value?.description;
      if (pre == this.pos) {
        // console.log("change", "itime")
      }
    }, 500);
    if (pre == this.pos) {
      // console.log("change", "out-time")
    }
  }

}