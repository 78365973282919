import { Component, OnInit } from '@angular/core';
import { Title/*, Meta */ } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { MyProfileService } from '../services/my-profile.service';
import { ActivatedRoute, /*NavigationEnd,*/ Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'src/environments/environment';
import { CommonService } from '../services/common.service';
// unused import
// import { isRestTypeNode } from 'typescript';
// import { MatPaginator } from '@angular/material/paginator';
// import { ViewportScroller } from '@angular/common';
// import { LabelType, Options } from '@angular-slider/ngx-slider';
// import * as $ from 'jquery';

@Component({
  selector: 'app-upgread',
  templateUrl: './upgread.component.html',
  styleUrls: ['./upgread.component.css']
})
export class UpgreadComponent implements OnInit {

  packageid: any;
  user_id: any;
  package_id: number;
  paymentHandler: any = null;
  packages: any = [];
  currencySymbole = {
    'USD': '&#x24;',
    'CAD': '&#x24;',
    'INR': '&#x24;',
  }
  currentPackage: any = "";
  remaningDays: any;
  CurrentPlanDetail: any;
  showPackages: any = []
  endDate: string | number | Date;
  remainingDays: number;
  frompackages: any = "";
  // previousUrl: string = null;

  constructor(
    private titleService: Title,
    private cookieService: CookieService,
    private myProfileService: MyProfileService,
    private toastrService: ToastrService,
    private router: Router,
    private location: Location,
    private activeRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private commonService: CommonService
  ) {
    this.activeRoute.queryParams
      .subscribe(params => {
        if (params && params.status == 0 && params.msg) {
          this.toastrService.error(params.msg);
        } else if (params && params.status == 1 && params.msg) {
          this.toastrService.success(params.msg);
        }
      }
      );
  }


  ngOnInit(): void {
    this.titleService.setTitle('Upgrade | eternalstrings.com');

    this.user_id = parseInt(this.cookieService.get('user_id'));
    this.packageid = localStorage.getItem('package_id');
    this.frompackages = localStorage.getItem('frompackages');
    this.invokeStripe();
    this.getUserPackages();
    this.getCorePackages();
  }

  getCorePackages() {
    let pload = {
      user_id: this.user_id,
    };
    this.spinner.show();
    this.myProfileService.getCorePackages(pload).subscribe(
      (result) => {
        if (result.status) {
          this.showPackages = result.packages;
          let datas = result.packages
          console.log(datas)
          this.filterPackage();
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastrService.error('Some thing want wrong info!');
      }
    );
  }

  filterPackage() {
    this.packages = []
    if (this.showPackages.length > 0) {
      if (this.CurrentPlanDetail?.package_id) {
        this.showPackages.forEach(element => {
          if (element.id > this.CurrentPlanDetail.package_id) {
            this.packages.push(element)
          }
        });
      } else {
        this.showPackages.forEach(element => {
          if (element.id > 0) {
            this.packages.push(element)
          }
        });
      }
    } else {
      this.packages = this.showPackages
    }
  }

  getUserPackages() {
    let pload = {
      user_id: this.user_id,
    };
    this.myProfileService.getUserPlans(pload).subscribe(
      (result) => {
        if (result.status) {
          // before sonar
          // if (result && result.active_plan && result.active_plan.length > 0) {
          if (result?.active_plan.length > 0) {
            result.active_plan.forEach(element => {
              if (element.active_plan_id) {
                this.CurrentPlanDetail = element
                this.daysCalculation();
                if (element.package_id == 3) {
                  let data = element.package_id + "-renewal"
                  let encrypted_id: any = this.commonService.encryptData(data);
                  encrypted_id = encrypted_id.toString().replaceAll('=', 'Ar1t').replaceAll('/', 'FGH').replaceAll('+', 'THD');
                  this.router.navigate(['packages/' + encrypted_id]);
                }
                this.filterPackage();
                return;
              }
            });
          }
        }
      },
      (error) => {
        this.toastrService.error('Some thing want wrong info!');
      }
    );
  }

  daysCalculation() {
    const dateTime1 = new Date(this.CurrentPlanDetail.start_date); // 3 / 6 / 9 extend
    const newDate = new Date(this.CurrentPlanDetail.start_date);
    let month = 0
    if (this.CurrentPlanDetail.subscription_plan == "3 month") {
      month = 3;
    } else if (this.CurrentPlanDetail.subscription_plan == "6 month") {
      month = 6;
    } else if (this.CurrentPlanDetail.subscription_plan == "9 month") {
      month = 9;
    }
    newDate.setMonth(newDate.getMonth() + month);
    const formattedDate = newDate.toISOString().slice(0, 19).replace("T", " ");
    this.endDate = new Date(formattedDate)
    const dateTime2 = new Date(this.endDate);
    const currentDate = new Date();
    // Calculate the difference days month
    const differenceInMilliseconds = dateTime2.getTime() - dateTime1.getTime();
    const dayDifference = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    // Calculate the remaining days, 
    const timeDifference = dateTime2.getTime() - currentDate.getTime();
    const remainingDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    this.remainingDays = remainingDays;
    console.log(dateTime1, "dateTime1", dateTime2, "dateTime2 ", currentDate, "currentDate")
    console.log("dayDifference", dayDifference, "remainingDays", remainingDays, "timeDifference", timeDifference)
  }
  makePayment(amount: any, package_id: any) {
    const paymentHandler = (<any>window).StripeCheckout.configure({
      key: 'pk_test_51MvFxISDm5aHfu8hyKteRTrelsr1w3uenC2cB45RBYq0AW2X8jz4KFUh6BvkFxla7NXFfuo41GXs6dvfRzYNoYvk00ePiBhr7E',
      locale: 'auto',
      token: function (stripeToken: any) {
        this.package_id = package_id;
        this.upgradePackage(package_id);
      },
    });
    paymentHandler.open({
      name: 'Eternalstrings',
      description: '',
      amount: amount * 100,
    });
  }

  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_51MvFxISDm5aHfu8hyKteRTrelsr1w3uenC2cB45RBYq0AW2X8jz4KFUh6BvkFxla7NXFfuo41GXs6dvfRzYNoYvk00ePiBhr7E',
          locale: 'auto',
          token: function (stripeToken: any) {
            alert('Payment has been successfull!');
          },
        });
      };
      window.document.body.appendChild(script);
    } else {
      //    // console.log('dfdfsdfs')
    }
  }

  upgradePackage(package_id: number) {
    let pload = {
      user_id: this.user_id,
      package_id: package_id,
    };

    this.myProfileService.upgradePackage(pload).subscribe(
      (result) => {
        this.toastrService.success('User package upgraded successfully!');
        this.location.back();
      },
      (error) => {
        this.toastrService.error('Some thing want wrong info!');
      }
    );
  }

  selectPackages(package_id: any) {
    if (package_id == 1) {
      this.router.navigate(['matches']);
    } else {
      let data = package_id + "-upgrade"
      let encrypted_id: any = this.commonService.encryptData(data);
      encrypted_id = encrypted_id.toString().replaceAll('=', 'Ar1t').replaceAll('/', 'FGH').replaceAll('+', 'THD');
      const url = `${environment.baseURL}` + 'packages/' + encrypted_id;
      window.open(url, '_blank');
      // window.open(url, '_blank');
    }
  }

  // startPayment(package_id:any,stripe_item_id:any) {
  //   let data = {
  //     user_id: this.user_id,
  //     package_id:package_id,
  //     stripe_item_id:stripe_item_id,
  //   }
  //   this.spinner.show();
  //   this.myProfileService.startPayment(data).subscribe(
  //     (result) => {
  //       this.spinner.hide();
  //       if(result.status) {
  //         window.location.href = result.url.url;
  //       } else {
  //         this.toastrService.error(result.message);
  //       }
  //     },
  //     (error) => {        
  //       this.toastrService.error('Some thing went wrong info!');
  //     }
  //   );
  // }  

  backLocation() {
    this.location.back();
    console.log("Back");
  }

  backLocation2() {
    if (!!localStorage.getItem('frompackages')) {
      localStorage.removeItem('frompackages');
      this.location.back();
      this.location.back();
      this.location.back();
      console.log("Back2Times");
    }
  }

}
