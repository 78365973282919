<style>
  body {
    font-family: Verdana, sans-serif;
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }

  .row>.column {
    padding: 0 8px;
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .column {
    float: left;
    width: 25%;
  }

  /* The Modal (background) */
  .modal1 {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
  }

  /* Modal Content */
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    width: 90%;
    max-width: 1200px;
  }

  /* The Close Button */
  .close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
  }

  .mySlides {
    display: none;
  }

  .cursor {
    cursor: pointer;
  }

  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }

  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }

  img {
    margin-bottom: -4px;
  }

  .caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
  }

  .demo {
    opacity: 0.6;
  }

  .active,
  .demo:hover {
    opacity: 1;
  }

  img.hover-shadow {
    transition: 0.3s;
  }

  .hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
</style>
<!-- light box css -->

<h2 class="text-danger mt-4 text-center  mt-120" style="display: none;">Coming soon...</h2>
<section class="container px-3 mt-120">
  <div class="row chat-container border border-1 shadow-sm my-4 rounded-3 overflow-hidden"
    [ngClass]="{'open-chat': activeUserId > 0,'mobile-575': windowInnerWidth <= 575}">
    <div class="col-12 col-sm-4 h-100 bg-white border border-1 border-start-0 border-bottom-0 border-top-0"
      [ngClass]="{'user_list_wpr': activeUserId > 0}">
      <div class="row">
        <div class="col-12 p-2 d-flex align-items-center">
          <div class="w-40-h-40 rounded-circle overflow-hidden">
            <img class="image-cover w-100 h-100" *ngIf="userDetails?.profile_photo "
              src="{{userDetails?.profile_photo}}" alt>
            <img class="image-cover rounded-3" *ngIf="!userDetails?.profile_photo "
              src="assets/images/default-profile-picture.svg" alt>
          </div>
          <div class="ps-2">
            <h6 class="mb-0 fw-500"> {{userDetails?.display_name}} </h6>
          </div>
        </div>
        <div class="col-12 p-2 border border-1 border-start-0 border-end-0 d-flex align-items-center">
          <div class="position-relative me-2 flex-auto">
            <input [(ngModel)]="searchUser" type="search" (keyup)="searchChatUsersOnKeyPress($event)"
              class="py-2 ps-2 pe-4 w-100 border-1 border border-danger rounded-pill" placeholder="Search here.....">
            <div class="search-btn position-absolute btn-danger w-30-h-30 rounded-circle center" *ngIf="!userListRes"
              (click)="searchChatUserList()">
              <span><i class="fa fa-spinner"></i></span>
            </div>
            <div class="search-btn position-absolute btn-danger w-30-h-30 rounded-circle center"
              *ngIf="userListRes && searchUser != ''" (click)="removeSearch()">
              <span><i class="fas fa-times"></i></span>
            </div>
          </div>
          <div class>
            <button class="btn w-35-h-35 btn-danger center rounded-circle" *ngIf="unRead" (click)="filterChat()"
              type="button" placement="bottom" ngbTooltip="Unread Chats">
              <i class="fas fa-filter"></i>
            </button>
            <button class="btn w-35-h-35 btn-warning center rounded-circle" *ngIf="!unRead" (click)="filterChat()"
              type="button" placement="bottom" ngbTooltip="Recent Chats">
              <i class="fas fa-filter"></i>
            </button>
            <!--  filter for unread  -->
          </div>
        </div>
        <div class="col-12 bg-light p-0">
        </div>
        <div class="col-12 px-0">
          <div class="my-2 px-3 border border-end-0 border-start-0 scrollbar user-chat-container">
            <div class="text-center mt-3" *ngIf="!unRead">
              <p class="mb-3 text-muted fw-bold">Unread chat filter</p>

            </div>
            <ng-container *ngIf="chatUserList?.length>0">
              <div class="row hover-2 border border-start-0 border-end-0 border-top-0 cursor-pointer"
                *ngFor='let user of chatUserList ;let i = index'>
                <div (click)="openChatFromList(user.user_id,i,user)"
                  [ngClass]="{'active': activeUserId == user.user_id}" class="col-12 py-2 d-flex align-items-center">

                  <div class="w-50-h-50 flex-shrink-0 position-relative">
                    <img *ngIf="!user?.profile_photo || user.profile_photo_approved != 1" class="image-cover rounded-3"
                      src="assets/images/default-profile-picture.svg" alt="...">
                    <div
                      *ngIf="user?.profile_photo != null && !!user?.profile_photo && user.profile_photo_approved ==1 ">
                      <ng-container *ngIf="user?.photo_visiblity == 'No one' ">
                        <img class="image-cover" src="assets/images/default-profile-picture.svg"
                          alt="Default Profile Img">
                      </ng-container>
                      <ng-container
                        *ngIf="user?.photo_visiblity == 'Visible to all members' || user?.photo_visiblity == 'Visible to connected members'">
                        <img class="image-cover rounded-3" src="{{user.profile_photo}}" alt="...">
                      </ng-container>
                    </div>
                    <span class="position-absolute top-0 left-0 m-1 translate-middle badge rounded-pill bg-danger"
                      *ngIf="user?.unread_message > 0">
                      {{user?.unread_message}} <span class="visually-hidden">unread messages</span>
                    </span>
                  </div>

                  <div class="overflow-hidden ms-2 width_80">
                    <p class="mb-0 text-truncate fw-600 mb-0">{{user?.display_name }}

                    </p>
                    <span *ngIf="user?.last_chat != null && user?.last_chat.message != null ">

                      <p *ngIf="user?.last_message != null" class="text-truncate p-xsm fw-500 mb-0 text-muted">
                        <span *ngIf="user?.last_chat?.mime_type == ''"
                          [innerHtml]="user?.last_chat.message | safeHtmlPipe"> </span>
                        <i *ngIf="user?.last_chat?.mime_type == 'image/jpeg'|| user?.last_chat?.mime_type == 'image/tiff' || user?.last_chat?.mime_type == 'image/png' || user?.last_chat?.mime_type.includes('image/')"
                          class="fas fas fa-file"></i>
                        <i *ngIf="user?.last_chat?.mime_type == 'video/mp4' || user?.last_chat?.mime_type == 'video/webm' || user?.last_chat?.mime_type.includes('video/')"
                          class="fas fas fa-file"></i>
                        <i *ngIf="user?.last_chat?.mime_type == 'application/pdf'|| user?.last_chat?.mime_type.includes('application/')"
                          class="fas fa-file"></i>
                      </p>
                    </span>

                    <p *ngIf="user?.last_message == null" class="text-truncate p-xsm fw-500 mb-0 text-muted"> To see
                      User List to chat with
                      Connect with the Users now. </p>
                  </div>

                  <div class="flex-shrink-0 width-60">
                    <div class="p-xsm mb-0 text-success text-right" *ngIf="user.is_logged_in">
                      <div class="flash d-inline-block me-1" ngbTooltip="Online">
                        <div class>
                          <i class="fas fa-globe"></i>
                        </div>
                      </div>
                    </div>
                    <div class="p-xsm mb-0 text-right" *ngIf="!user.is_logged_in">
                      <div class="flash1 d-inline-block me-1" ngbTooltip="Offline">
                        <div class>
                          <i class="fas fa-globe"></i>
                        </div>
                      </div>
                    </div>

                    <p *ngIf="user?.last_chat != null" class="mt-2 fw-500 text-end text-danger">
                      <span *ngIf="user?.msgDate == 'Today'">{{user.msgTime }}</span>
                      <span *ngIf="user?.msgDate != 'Today'">{{user.msgDate}}</span>

                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="chatUserList?.length == 0 && !userListRes">
              <div
                class="row hover-2 border border-start-0 border-end-0 border-top-0 cursor-pointer animated-background">
                <div class="col-12 py-2 d-flex align-items-center" *ngFor="let number of [0,1,2]">
                  <div class="w-50-h-50 flex-shrink-0 position-relative">
                    <img class="image-cover rounded-3 animated-background-name"
                      src="assets/images/default-profile-picture.svg" alt="">
                    <span
                      class="position-absolute top-0 left-0 m-1 translate-middle badge rounded-pill  animated-background-name">
                      0+</span>
                  </div>
                  <div class="overflow-hidden ms-2">
                    <p class="mb-2 text-truncate fw-600 mb-0  text-muted animated-background-name">user name</p>
                    <p class="text-truncate p-xsm fw-600 mb-0 text-muted animated-background-name">Last conversation
                      message</p>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="chatUserList?.length == 0 && userListRes">
              <div class="text-center mt-3" *ngIf="unRead">
                <p class="mb-0 text-muted fw-bold"> No connections present!</p>
                <p class="mb-2 text-muted p-sm">To see User List to chat with Connect with the Users now.</p>
              </div>
              <div class="text-center mt-3" *ngIf="!unRead">
                <p class="mb-0 text-muted fw-bold">No unread chat!</p>

              </div>
            </ng-container>

          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 position-relative" [ngClass]="{'chat_view_wpr': activeUserId > 0}">
      <ng-container *ngIf="activeUserId>0">
        <div class="row bg-white py-2">
          <div class="col-6">
            <div class="d-flex align-items-center">
              <div (click)="goBack()" class="w-40-h-40 center btn-light rounded-circle btn-light" placement="bottom"
                ngbTooltip="Hide Chat">
                <i class="fas fa-arrow-left fs-5"></i>
              </div>

              <div class="mx-2 w-40-h-40 rounded-circle overflow-hidden">
                <img *ngIf="!activeUser?.profile_photo || activeUser.profile_photo_approved != 1"
                  class="image-cover rounded-3" src="assets/images/default-profile-picture.svg" alt="...">
                <div *ngIf="activeUser?.profile_photo != null && !!activeUser?.profile_photo &&
                  activeUser.profile_photo_approved
                  ==1 ">
                  <ng-container *ngIf="activeUser?.photo_visiblity == 'No one' ">
                    <img class="image-cover" src="assets/images/default-profile-picture.svg"
                      alt="Default Profile Img">
                  </ng-container>
                  <ng-container *ngIf="activeUser?.photo_visiblity == 'Visible to all members' || activeUser?.photo_visiblity ==
                    'Visible to connected members'">
                    <img class="image-cover rounded-3" src="{{activeUser.profile_photo}}" alt="...">
                  </ng-container>
                </div>

              </div>

              <div class>
                <h6 class="fw-500 mb-1">{{activeUser?.display_name}} </h6>
                <div class=" px-0 d-flex align-items-center">

                  <span class="user_email_verified"
                    [ngbTooltip]="activeUser?.is_user_verified ==1 ? 'Verified user': 'Verified email'"
                    placement="bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                      class="bi bi-shield-fill-check mb-1 " [ngClass]="{'text-muted':activeUser?.is_user_verified != 1,
                                               'text-success':activeUser?.is_user_verified == 1}" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0
                                    0-1.044
                                    1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517
                                    2.453c.386.273.744.482
                                    1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159
                                    7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195
                                    3.061-5.513
                                    2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0
                                    0-2.887-.87C9.843.266
                                    8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708
                                    0l-1.5-1.5a.5.5 0
                                    1 1 .708-.708L7.5 7.793l2.646-2.647z" />
                    </svg>
                  </span>

                  <span class="user_email_verified1 mx-1" *ngIf="activeUser?.is_user_verified== 0 " placement="bottom"
                    ngbTooltip="Verified email"><i class="fa fa-check fs-6" style="color:green"></i>
                  </span>
                  <span class="user_email_verified1 mx-1" *ngIf="activeUser?.is_user_verified== 1 " placement="bottom"
                    ngbTooltip="Verified user"><i class="fa fa-check-double fs-6 " style="color:green"></i>
                  </span>
                  <div class="d-flex align-items-center">
                    <div class="p-xsm mb-0 text-success" *ngIf="activeUser.is_logged_in">
                      <div class="flash d-inline-block me-1">
                        <div class>
                          <i class="fas fa-globe"></i>
                        </div>
                      </div>
                      <div class="d-inline-block">
                        Online
                      </div>
                    </div>
                    <div class="p-xsm mb-0 text-default" *ngIf="!activeUser.is_logged_in">
                      <div class="d-inline-block me-1">
                        <div class>
                          <i class="fas fa-globe"></i>
                        </div>
                      </div>
                      <div class="d-inline-block">
                        Offline
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-6 d-flex align-items-center justify-content-end">

            <a href="javascript:void(0);" class="border-0 btn-blue me-2 rounded-circle w-30-h-30 center"
              placement="bottom" ngbTooltip="Coming Soon">
              <i class="fas fa-phone-alt"></i>
            </a>
            <a href="javascript:void(0);" class="border-0 btn-blue me-2 rounded-circle w-30-h-30 center"
              placement="bottom" ngbTooltip="Coming Soon">
              <i class="fas fa-video"></i>
            </a>

            <div class="border-0 btn-blue me-2 rounded-circle w-30-h-30 center" placement="bottom"
              ngbTooltip="Search Messages" (click)="openSearchChatModal(searchChatModal)">
              <i class="fas fa-search"></i>
            </div>

            <div class="dropdown">
              <button class="w-30-h-30 btn-danger rounded-circle center border-0" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li class="dropdown-item btn btn-light rounded-0 border-0 w-100" data-bs-toggle="modal"
                  data-bs-target="#clearMessagesModal" (click)="clearMessages('onlyMine')"> Clear Messages </li>
                <li class="dropdown-item btn btn-light rounded-0 border-0 w-100" data-bs-toggle="modal"
                  data-bs-target="#blockModal" (click)="confirmBlockReport('block')"> Block </li>
                <li class="dropdown-item btn btn-light rounded-0 border-0 w-100"  data-bs-toggle="modal"
                  data-bs-target="#blockReportModal" (click)="confirmBlockReport('block and report')"> Block & Report
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row my-0 chat-area scrollbar" #scrollMe (scroll)="onScrollMe($event)">
          <a href="javascript:void(0);" *ngIf="showMoreUserChatting" (click)="showMoreUserChattingFun(nextPage)"
            class="read-more" placement="bottom">
            <span class="mdi mdi-chevron-double-up bg-danger text-white show-more-button"> <i
                class="fas fa-angle-double-up"></i> Show More</span>
          </a>
          <div class="col-12" *ngIf="user_chatting[activeUserId]?.length>0">
            <ng-container *ngFor="let chat of user_chatting[activeUserId];let i = index">
              <div id="chat_{{chat.id}}" class="my-2 d-flex justify-content-start" *ngIf="chat.user_to == user_id">
                <div class="w-25-h-25 overflow-hidden rounded-circle position-relative flex-shrink-0">
                  <img *ngIf="chat?.profile_photo && photo_visibility" class="image-cover position-absolute"
                    src="{{chat?.profile_photo}}" alt>
                  <img *ngIf="!chat?.profile_photo && photo_visibility == false" class="image-cover"
                    src="assets/images/default-profile-picture.svg" alt="...">

                </div>
                <div class="dropdown flex-shrink-0 ">
                  <div
                    class="ms-1 p-0 btn w-20-h-20 overflow-hidden hover-2 rounded-circle position-relative d-flex align-items-center justify-content-center "
                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fas fa-ellipsis-v text-danger p-sm"></i>
                  </div>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li class="dropdown-item btn-light rounded-0 w-100" (click)="deleteMessage(chat?.id,'other')"
                      data-bs-toggle="modal" data-bs-target="#deleteMessageModal"><i class="fas fa-trash"></i> Remove
                    </li>
                  </ul>
                </div>
                <div class="d">
                  <div *ngIf="chat?.mime_type == ''"
                    class="rounded-3 ms-2  border shadow-sm  bg-white text-muted  px-2 pt-2 chat-message position-relative">
                    <div class="mb-0 lh-base right-chat-text" [innerHtml]="chat?.message | safeHtmlPipe">
                    </div>
                    <ng-container *ngIf="chat?.hasOwnProperty('splitmsg')">
                      <a href="javascript:void(0)" class="read_more text-danger"
                        *ngIf="chat?.splitmsg.length > messageId[chat?.id].limit" (click)="readMore(chat.id,i)">...read
                        more</a>
                      <a href="javascript:void(0)" class="read_more text-danger"
                        *ngIf="chat?.splitmsg.length <= messageId[chat?.id].limit" (click)="readLess(chat.id,i)">...read
                        Less</a>
                    </ng-container>
                    <!-- <p class="text-warning" (click)="readMore(chat.id)">..read more </p> -->
                    <!-- <p></p> -->

                    <!-- <p class="p-xsm text-start m-1">{{chat?.msgRecived | utcToLocalTime: 'short'}}</p> -->

                    <p class="p-xsm text-end m-1 p-1" *ngIf="chat?.msgRecived == 'Invalid Date,Invalid Date'">
                      {{chat?.created_at | date:'short'}} </p>
                    <p class="p-xsm text-end m-1 p-1" *ngIf="chat?.msgRecived != 'Invalid Date,Invalid Date'">
                      {{chat?.msgRecived | date:'short'}} </p>
                  </div>

                  <div *ngIf="chat?.mime_type != ''"
                    class="shadow-sm me-2 cursor-pointer px-2 pt-2 chat-message-1 position-relative">
                    <ng-container *ngIf="chat?.mime_type == 'image/jpeg' || chat?.mime_type == 'image/tiff'||
                      chat?.mime_type == 'image/png' || chat?.mime_type.includes('image/')">
                      <img *ngIf="chat?.thumb_image != '' && chat?.thumb_image != null" src="{{chat?.thumb_image}}" alt=""
                        width="200" (click)="openMedia(chat)">
                      <img *ngIf="chat?.thumb_image == ''" src="{{chat?.message}}" alt="" width="200"
                        (click)="openMedia(chat)">
                    </ng-container>

                    <video
                      *ngIf="chat?.mime_type == 'video/mp4' || chat?.mime_type == 'video/webm' || chat?.mime_type.includes('video/')"
                      src="{{chat?.message}}" height="200" controls></video>
                    <a *ngIf="chat?.mime_type == 'application/pdf'" href="{{chat?.message}}" target="_blank">
                      <img src="assets/images/icon/pdf.png" alt="" width="100" />
                    </a>
                    <a *ngIf="(chat?.mime_type != 'application/pdf' && chat?.mime_type.includes('application/')) || (chat?.mime_type.includes('text/')) "
                      href="{{chat?.message}}" target="_blank">
                      <img src="assets/images/icon/file.png" alt="" width="100" />
                    </a>
                    <div class="mt-2 text-muted right-chat-text" *ngIf="chat?.caption.length > 0"
                      [innerHtml]="chat?.caption | safeHtmlPipe">
                    </div>
                    <p class="p-xsm text-end m-1 p-1" *ngIf="chat?.msgRecived != 'Invalid Date,Invalid Date'">
                      {{chat?.msgRecived | date:'short'}} </p>
                    <p class="p-xsm text-end m-1 p-1" *ngIf="chat?.msgRecived == 'Invalid Date,Invalid Date'">
                      {{chat?.created_at | date:'short'}} </p>

                  </div>
                </div>

              </div>
              <div id="chat_{{chat.id}}" class="my-2 d-flex justify-content-end" *ngIf="chat.user_from == user_id">
                <div class>

                  <!-- message sent in chat  -->
                  <div *ngIf="chat?.mime_type == ''"
                    class="rounded-3 border shadow-sm me-2 bg-danger text-white  px-2 pt-2 chat-message-1 position-relative">
                    <div class="mb-0 lh-base left-chat-text" [innerHtml]="chat?.message | safeHtmlPipe">
                    </div>

                    <ng-container *ngIf="chat?.hasOwnProperty('splitmsg')">
                      <a href="javascript:void(0)" class="read_more text-white"
                        *ngIf="chat?.splitmsg.length > messageId[chat?.id].limit" (click)="readMore(chat.id,i)">...read
                        more</a>
                      <a href="javascript:void(0)" class="read_more text-white"
                        *ngIf="chat?.splitmsg.length <= messageId[chat?.id].limit" (click)="readLess(chat.id,i)">...read
                        Less</a>
                    </ng-container>

                    <p class="p-xsm text-end m-1">{{chat?.msgSent | date: 'short'}}
                      <i class="fas fa-check" *ngIf="chat?.message_status == 1"></i>
                      <i class="fas fa-check-double" *ngIf="chat?.message_status == 2"></i>
                      <i class="fas fa-eye" *ngIf="chat?.message_status == 3"></i>
                    </p>
                  </div>
                  <div *ngIf="chat?.mime_type != ''"
                    class="shadow-sm me-2 cursor-pointer px-2 pt-2 chat-message-1 position-relative">
                    <ng-container *ngIf="chat?.mime_type == 'image/jpeg' || chat?.mime_type == 'image/tiff' ||
                      chat?.mime_type == 'image/png' || chat?.mime_type.includes('image/')">
                      <img *ngIf="chat?.thumb_image != ''  && chat?.thumb_image != null" src="{{chat?.thumb_image}}" alt=""
                        width="200" (click)="openMedia(chat)">
                      <img *ngIf="chat?.thumb_image == ''" src="{{chat?.message}}" alt="" width="200"
                        (click)="openMedia(chat)">
                    </ng-container>
                    <video
                      *ngIf="chat?.mime_type == 'video/mp4' || chat?.mime_type == 'video/webm' || chat?.mime_type.includes('video/')"
                      src="{{chat?.message}}" height="200" controls></video>

                    <a *ngIf="chat?.mime_type == 'application/pdf'" href="{{chat?.message}}" target="_blank">
                      <img src="assets/images/icon/pdf.png" alt="" width="100" />
                    </a>
                    <a *ngIf="(chat?.mime_type != 'application/pdf' && chat?.mime_type.includes('application/'))"
                      href="{{chat?.message}}" target="_blank">
                      <img src="assets/images/icon/file.png" alt="" width="100" />
                    </a>
                    <span *ngIf="chat?.mime_type != 'application/pdf' && chat.mime_type.includes('text/') && chat?.mime_type
                      != 'image/jpeg' && chat?.mime_type == 'image/tiff' && chat?.mime_type != 'image/png'
                      && chat?.mime_type != 'video/mp4' && chat?.mime_type != 'video/webm' && chat?.mime_type != '' &&
                      chat?.mime_type.length > 1">
                      <img src="assets/images/icon/file.png" alt="" width="100" />
                    </span>


                    <div class="mt-2 left-chat-text" *ngIf="chat?.caption.length > 0"
                      [innerHtml]="chat?.caption | safeHtmlPipe">
                    </div>
                    <p class="p-xsm  p-1 text-end m-1 p-1" *ngIf="chat?.msgSent != 'Invalid Date,Invalid Date'">
                      {{chat?.msgSent |
                      date:'short'}} </p>
                    <p class="p-xsm text-end m-1 p-1 p-1" *ngIf="chat?.msgSent == 'Invalid Date,Invalid Date'">
                      {{chat?.created_at | date:'short'}} </p>
                  </div>

                </div>
                <div class="mx-1 dropdown flex-shrink-0">
                  <div
                    class="p-0 btn w-20-h-20 overflow-hidden hover-2 rounded-circle position-relative d-flex align-items-center justify-content-center "
                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fas fa-ellipsis-v text-danger p-sm"></i>
                  </div>
                  <ul class="dropdown-menu cursor-pointer" aria-labelledby="dropdownMenuButton1">
                    <li class="dropdown-item text-muted" (click)="deleteMessage(chat?.id,'self')" data-bs-toggle="modal"
                      data-bs-target="#deleteMessageModal"><i class="fas fa-trash text-danger me-1"></i> Remove</li>
                    <li class="dropdown-item text-muted" (click)="forwardMessage(chat?.id,mymodal)"><i
                        class="fas fa-arrow-right text-danger me-1"></i>
                      Forward</li>
                  </ul>
                </div>
                <div class="w-25-h-25 overflow-hidden rounded-circle position-relative flex-shrink-0">
                  <img *ngIf="chat?.profile_photo && photo_visibility" class="image-cover position-absolute"
                    src="{{chat?.profile_photo}}" alt>
                  <img *ngIf="!chat?.profile_photo && photo_visibility == false" class="image-cover"
                    src="assets/images/default-profile-picture.svg" alt="...">

                </div>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="user_chatting[activeUserId]?.length == 0 && chatListRes">
            <div class="text-center mt-3">
              <p class="mb-0 text-muted fw-bold"> No chat present!</p>
              <p class="mb-2 text-muted p-sm">You can send message to start conversation</p>
            </div>
          </ng-container>
        </div>
        <form [formGroup]="sendMessageForChatForm">

          <div class="row bg-white w-100 position-absolute bottom-0 p-0">
            <div class="d-flex position-relative overflow-auto">
              <div *ngFor="let images of imgURL; let i = index" [attr.data-index]="i"
                class="each_media position-relative">
                <a href="javascript:void(0);" (click)="RemoveImage(images, i)"
                  class='position-absolute close_img my-element z-index-99'>
                  <p class="fw-bold text-dark">X</p>
                </a>
                <div *ngIf="imgURL" class=' z-index-10'
                  [ngClass]="this.selectedCaptionIndex == i ? 'border border-danger' : 'border'">

                  <img *ngIf="images.type == 'image'" class='border chat-obj-fit' (click)="showImage(images, i)"
                    src='{{images.url}}' alt="">
                  <video *ngIf="images.type == 'video'" (click)="showImage(images, i)" [muted]="true"
                    class='chat-obj-fit' [src]='images.url'></video>
                  <div *ngIf="images.name" class="text-center" (click)="showImage(images, i)" style="display: none;">
                    <p class="text-dark font-weight-normal previewTestLimit" title="{{images.name}}">
                      {{images.name | limit : 15}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card gif-box" *ngIf="showStickerBox">
              <div class="card-header p-1">
                <img *ngFor="let image of leagueDetails; let i = index;" class="gif-head-button"
                  [ngClass]="image.league_id == activeLeagueId ? 'active-league' : ''" src="{{image.profile_pic}}" alt
                  width="35" (click)="changeLeague(image.league_id, 'sticker');">
              </div>
              <div class="card-body py-1 px-0">
                <div>
                  <div class="search-field shadow-sm sticky" style="top:0px; z-index:0 !important;">
                    <input type="text" class="form-control border-0 pr-5" placeholder="Search"
                      [(ngModel)]="searchSticker" (keyup)="toggleStickerBox('searchField')" />
                    <i class="mdi mdi-magnify mdi-2x search-pos"></i>
                  </div>
                  <div id="stickerDiv" class="gif-data text-center" infiniteScroll [infiniteScrollDistance]="4"
                    [infiniteScrollThrottle]="50"
                    (scrolled)="leaguesGifStickerImagesShowMoreFun(nextPageGifSticker, $event);" [scrollWindow]="false">
                    <div class="gif-image-block">
                      <span class="gif-images" *ngFor="let imgsrc of chatStickerArray; let i = index;">
                        <a href="javascript:void(0);" title="{{imgsrc.name}}"
                          (click)="sendMessageGifStickerFun(imgsrc.image_url, 'sticker')">
                          <span>
                            <app-progressive-image class="progressive-image">
                              <img appProgressiveImage src="{{imgsrc.image_url}}" alt="" class="img-fluid mx-1">

                              <div appProgressiveImagePlaceholder class="newsImgLoader">
                                <div id="loaderProgressive" class="centerProgressive"></div>
                              </div>
                              <img appProgressiveImageFallback class="fallback p-2 mx-auto" src="assets/images/news.png" alt=""
                                width="100px">
                            </app-progressive-image>
                          </span>
                        </a>
                      </span>
                    </div>

                    <div class='spinner-border text-info' *ngIf="gifLoader"><span class='sr-only'></span></div>

                    <p class="not-found-text" *ngIf="chatStickerArray.length == 0 && !gifLoader">No Sticker Found</p>

                    <div *ngIf="showMoreGifSticker && !gifLoader">
                      <a href="javascript:void(0);" (click)="leaguesGifStickerImagesShowMoreFun(nextPageGifSticker, $event);
                                  " placement="top">
                        <span class="mdi mdi-chevron-double-down">Show More</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="emoji-div-class emoji-pos-class" *ngIf="showEmojiPicker">
              <span class="d-inline-flex float-right">
                <emoji-mart class="emoji-mart" [set]="sheet === '' ? null : sheet" [isNative]="sheet === ''"
                  *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)" title="Pick your emoji…"
                  [showPreview]="false" [noCountryFlags]="false"
                  [i18n]="{search: 'Search',categories: {search: 'Search Results',recent: 'Frequently Used'}}"
                  [style]="{width :'300px'}" [showSingleCategory]="true" emojiTooltip="true">
                </emoji-mart>
              </span>
            </div>

            <div class="col-12 p-0 d-flex align-items-center">
              <button type="button" class="mx-2 w-40-h-40 center rounded-circle btn btn-danger" placement="top"
                ngbTooltip="Emojis" (click)="toggleEmojiPicker();" *ngIf="this.totalMediaLength == 0">
                <i class="fas fa-smile-beam fs-5"></i>
              </button>

              <div class="file-upload">
                <label for="upload" class="mx-2 w-40-h-40 center rounded-circle btn btn-danger" placement="top"
                  ngbTooltip="Upload File">
                  <i class="fas fa-paperclip fs-5"></i>
                </label>
                <input [formControlName]="'attachment'" id="upload" (click)="fileInput.value = null" #fileInput
                  class="file-upload__input" type="file" name="file-upload" (change)="fileChangeEvent($event)" multiple
                  accept="image/*,video/*,audio/ogg,audio/mpeg,audio/aac,.doc,.docx,.pdf,.zip,.rar,.txt,.ppt,.pptx,.csv,.xls,xlsx">
              </div>

              <div class="message-box" *ngIf="this.totalMediaLength == 0">
                <div #one contenteditable="true" id="chatTextarea" (beforeinput)="backspaceClick($event);"
                  (keyup.enter)="onEnterPress(sendData, $event);"
                  (keyup)="sendMessageChange(sendData.message,$event);getF(sendData.message,$event)"
                  (paste)="textPaste(sendData.message, $event); urlServiceCall(sendData.message, $event);"
                  (cut)="urlServiceCall(sendData.message, $event);" (focus)="focusMessageFunction();onFocus();"
                  (input)="urlServiceCall(sendData.message, $event);" tabindex="0" data-lexical-editor="true"
                  class="form-control mr-1 p-0" data-text="Write your message..." [innerHtml]="msgContent"
                  (keypress)="onKeypressEvent($event)">
                </div>
              </div>

              <div class="message-box" *ngIf="this.totalMediaLength != 0">
                <form #wordCountNgForm="ngForm" [formGroup]="wordCountForm">
                  <textarea id="reason" #texts rows="3" class="form-control" [formControlName]="'description'"
                    (keypress)="wordCounter($event)" (change)="compare()" (paste)="pasteNumeric6($event)"
                    placeholder="Add Caption (Optional).." maxlength="500"></textarea>
                  <div class="form-label mb-2 py-1 fw-bold text-danger" *ngIf="exceedLimit"> You cannot enter more than
                    the
                    maximum of 10
                    words</div>
                  <div class=" d-flex ">
                    <p class=" d-flex justify-content-start text-muted p-xsm my-2 col-6">Entered- {{words}} Words</p>
                    <p class="d-flex justify-content-end text-muted p-xsm my-2 col-6">Max 10 Words. </p>
                  </div>
                </form>
              </div>
              <div class>
                <button type="submit" *ngIf="this.imgURL.length == 0"
                  class="mx-2 w-40-h-40 center rounded-circle btn btn-danger" placement="top"
                  (click)="sendMessageForChat()">
                  <i class="fas fa-paper-plane fs-5"></i>
                </button>
                <button class="w-40-h-40 center rounded-circle btn btn-danger" placement="top"
                  *ngIf="this.imgURL.length > 0" (click)="sendMessageFun(sendData)">
                  <i class="fas fa-paper-plane fs-5"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </ng-container>

      <div *ngIf="!activeUserId"
        class="d-flex align-items-center justify-content-center flex-column w-100 h-100 d-none1">
        <div class="alert-danger rounded-3">
          <i class="fas fa-comments display-1 text-danger m-4"></i>
        </div>
        <div class="mt-2">
          <h5 class="mb-0 fw-bold text-muted">Start a conversation</h5>
        </div>
        <div class="mt-1">
          <p class="p-sm mb-0 text-muted"> Your chat with any user will appear here.</p>
        </div>
      </div>

    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body p-4">
        <h5 class="mb-4 fw-600" id="exampleModalLabel">Clear Messages?</h5>
        <p class="fw-500">
          All messages will only be removed from your chat.
        </p>
        <div class="mt-4 text-end">
          <button class="btn btn-light border border-1 border-danger me-2" data-bs-dismiss="modal"
            aria-label="Close">Cancel</button>
          <button class="btn btn-danger">Clear Chat</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Block Modal -->
<div class="modal fade" id="blockModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body p-4">
        <h5 class="mb-4 fw-600" id="exampleModalLabel">{{action | titlecase}}</h5>
        <p class="fw-500">
          Do you want to {{action}} this profile?
        </p>
        <div class="mt-4 text-end">
          <button class="btn btn-light border border-1 border-danger me-2" data-bs-dismiss="modal"
            aria-label="Close">Cancel</button>
          <button class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"
            (click)="blockReportNow()">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Report & Block Modal -->
<div class="modal fade" id="blockReportModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">
      <div class="row modal-body">
        <div class="col-12 mb-2">
          <h5 class="fw-600 text-danger"> Please select reason:</h5>
        </div>
        <div class="col-12">
          <div class="form-check ps-0" *ngFor='let user of reportReasonsList ;let i = index'>
            <input class="checkbox me-2" type="radio" name="Serious" id="{{user.id}}" (click)="blockReportId(user)">
            <label class role="" for="{{user.id}}">
              {{user.label}}</label>
          </div>
          <div class="col-12 mb-3" *ngIf="otherReason">
            <label for="NewPassword" id="reason" class="fw-bold"> Reason</label>
            <textarea name id="reason" rows="3" class="form-control" [(ngModel)]="reportReason"
              maxlength="500"></textarea> <!-- -->
          </div>
        </div>
      </div>

      <div class="mt-4 text-end">
        <button class="btn btn-light border border-1 border-danger me-2" data-bs-dismiss="modal"
          aria-label="Close">Cancel </button>
        <button class="btn btn-danger m-2" data-bs-dismiss="modal" aria-label="Close" (click)="blockAndReportNow()"
          [disabled]="!reportReason">Submit</button>

      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="clearMessagesModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body p-4">
        <h5 class="mb-4 fw-600" id="exampleModalLabel">Clear Messages?</h5>
        <p class="fw-500">
          All messages will only be removed from your chat.
        </p>
        <div class="mt-4 text-end">
          <button class="btn btn-light border border-1 border-danger me-2" data-bs-dismiss="modal"
            aria-label="Close">Cancel</button>
          <button class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" (click)="clearMessagesNow()">Clear
            Chat</button>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="deleteMessageModal" tabindex="-1" aria-labelledby="deleteMessageModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body p-4">
        <h5 class="mb-4 fw-600" id="exampleModalLabel">Remove message?</h5>
        <div class="mt-4 text-end ">
          <button class="btn btn-danger btn-sm me-2" data-bs-dismiss="modal" aria-label="Close"
            (click)="deleteMessageNow('self')">remove for me</button>
          <button class="btn btn-danger me-2 btn-sm" data-bs-dismiss="modal" aria-label="Close"
            *ngIf="messageOf == 'self'" (click)="deleteMessageNow('everyone')">remove for everyone</button>
          <button class="btn btn-light btn-sm border border-1 border-danger " data-bs-dismiss="modal"
            aria-label="Close">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Forward message Modal -->
<div class="modal fade" id="forward_message_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-header pb-0 border-0">
      <h5 class="modal-title mb-0 fw-bold" id="exampleModalLabel1">Forward message to <small class="p-sm"></small></h5>
    </div>
    <div class="modal-content">
      <div class="modal-body p-4">
        <div class="col-12 p-2 border border-1 border-start-0 border-end-0 d-flex align-items-center">
          <div class="position-relative me-2 flex-auto">
            <input [(ngModel)]="searchUser" type="search" (keyup)="searchChatUsersOnKeyPress($event)"
              class="py-2 ps-2 pe-4 w-100 border-1 border border-danger rounded-pill" placeholder="Search here.....">
            <div class="search-btn position-absolute btn-danger w-30-h-30 rounded-circle center"
              (click)="searchChatUsers()">
              <i class="fas fa-search"></i>
            </div>
          </div>
          <div class>
            <button class="btn w-35-h-35 btn-danger center rounded-circle" type="button" placement="bottom"
              ngbTooltip="Unread Chats">
              <i class="fas fa-filter"></i>
            </button>
          </div>
        </div>
        <div class="col-12 bg-light p-0">
        </div>
        <div class="col-12 px-0">
          <div class="my-2 px-3 border border-end-0 border-start-0 scrollbar user-chat-container">
            <ng-container *ngIf="chatUserList?.length>0">
              <div class="row hover-2 border border-start-0 border-end-0 border-top-0 cursor-pointer"
                *ngFor='let user of chatUserList ;let i = index'>
                <div (click)="openChatFromList(user.user_id,i,user)"
                  [ngClass]="{'active': activeUserId == user.user_id}" class="col-12 py-2 d-flex align-items-center">

                  <div class="w-50-h-50 flex-shrink-0 position-relative">
                    <img *ngIf="user?.profile_photo && photo_visibility" class="image-cover rounded-3"
                      src="{{user.profile_photo}}" alt="...">
                    <img *ngIf="!user?.profile_photo && photo_visibility == false" class="image-cover rounded-3"
                      src="assets/images/default-profile-picture.svg" alt="...">
                    <span class="position-absolute top-0 left-0 m-1 translate-middle badge rounded-pill bg-danger"
                      *ngIf="user?.unread_message > 0">
                      {{user?.unread_message}} <span class="visually-hidden">unread messages</span>
                    </span>
                  </div>

                  <div class="overflow-hidden ms-2">
                    <p class="mb-0 text-truncate fw-600 mb-0">{{user?.display_name }} </p>
                    <p class="text-truncate p-xsm fw-500 mb-0 text-muted"> To see User List to chat with
                      Connect with the Users now. </p>
                  </div>

                  <div class="flex-shrink-0 width-60">
                    <p class="mb-1 fw-500 text-end p-sm text-danger">
                      5/01/2019
                    </p>
                    <div class="p-xsm mb-0 text-success text-right" *ngIf="user.is_logged_in">
                      <div class="flash d-inline-block me-1" ngbTooltip="Online">
                        <div class>
                          <i class="fas fa-globe"></i>
                        </div>
                      </div>
                    </div>
                    <div class="p-xsm mb-0 text-right" *ngIf="!user.is_logged_in">
                      <div class="flash1 d-inline-block me-1" ngbTooltip="Offline">
                        <div class>
                          <i class="fas fa-globe"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="mt-4 text-end">
          <button class="btn btn-light border border-1 border-danger me-2" data-bs-dismiss="modal"
            aria-label="Close">Cancel</button>
          <button class="btn btn-danger">Clear Chat</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #mymodal let-modal>
  <div class="modal-header border-0">
    <h5 class="modal-title mb-0 fw-bold" id="exampleModalLabel1">Forward message to <small class="p-sm"></small></h5>
  </div>
  <div class="modal-content">
    <div class="modal-body p-2">
      <div class="col-12 p-2 border-start-0 border-end-0 d-flex align-items-center">
        <div class="position-relative me-2 flex-auto">
          <input [(ngModel)]="searchUserForwardTo" type="search" (keyup)="searchChatUsersForwardTo()"
            class="py-2 ps-2 pe-4 w-100 border-1 border border-danger rounded-pill" placeholder="Search here.....">
          <div class="search-btn position-absolute btn-danger w-30-h-30 rounded-circle center"
            *ngIf="searchUserForwardTo !=''" (click)="removeChatUsersForwardTo()">
            <span><i class="fas fa-times"></i></span>
          </div>
        </div>
      </div>
      <div class="col-12 px-0">
        <div class="my-2 px-3 border border-end-0 border-start-0 scrollbar user-chat-container">
          <ng-container *ngIf="chatUserListForwardTo?.length>0">
            <div class="row hover-2 border border-start-0 border-end-0 border-top-0 cursor-point er"
              *ngFor='let user of chatUserListForwardTo ;let i = index'>
              <div class="col-12 py-2 d-flex align-items-center">


                <div class="w-50-h-50 flex-shrink-0 position-relative">
                  <img *ngIf="!user?.profile_photo || user.profile_photo_approved != 1" class="image-cover rounded-3"
                    src="assets/images/default-profile-picture.svg" alt="...">
                  <div *ngIf="user?.profile_photo != null && !!user?.profile_photo && user.profile_photo_approved ==1 ">
                    <ng-container *ngIf="user?.photo_visiblity == 'No one' ">
                      <img class="image-cover" src="assets/images/default-profile-picture.svg"
                        alt="assets/images/men-avtar-2.svg">
                    </ng-container>
                    <ng-container
                      *ngIf="user?.photo_visiblity == 'Visible to all members' || user?.photo_visiblity == 'Visible to connected members'">
                      <img class="image-cover rounded-3" src="{{user.profile_photo}}" alt="...">
                    </ng-container>
                  </div>

                </div>


                <div class="overflow-hidden ms-2 width_80">
                  <p class="mb-0 text-truncate fw-600 mb-0">{{user?.display_name}}</p>
                </div>

                <div class="flex-shrink-0 width-60 text-right">
                  <input type="checkbox" [(ngModel)]="myChats[i]" id="{{ user.id }}"
                    (change)="CompareId($event,i,user.user_id)" class="checkbox w-25-h-25"
                    ngbTooltip="Please check mark to forward meessage to" />
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="chatUserListForwardTo?.length == 0">
            <div class="text-center mt-3">
              <p class="mb-0 text-muted fw-bold">No chat present!</p>
              <p class="mb-2 text-muted p-sm">You can send message to start conversation</p>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="mt-4 text-end">
        <button class="btn btn-light border border-1 border-danger me-2" data-bs-dismiss="modal" aria-label="Close"
          (click)="modal.close('Save click')">Cancel</button>
        <button class="btn btn-danger" (click)="modal.close('Save click');forwardMessageNow();">Send</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #searchChatModal let-modal>
  <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
    data-bs-dismiss="modal" aria-label="Close" id="searchChatModal" (click)="modal.close('Save click')">
    <i class="fas fa-times fs-5"></i>
  </button>
  <div class="modal-header border-0">
    <h5 class="modal-title mb-0 fw-bold" id="exampleModalLabel1">Search chat<small class="p-sm"></small></h5>
  </div>
  <div class="modal-content">
    <div class="modal-body p-2">
      <div class="col-12 p-2 border-start-0 border-end-0 d-flex align-items-center">
        <div class="position-relative me-2 flex-auto">
          <input [(ngModel)]="searchChatWord" type="search" (keyup)="searchChat()"
            class="py-2 ps-2 pe-4 w-100 border-1 border border-danger rounded-pill" placeholder="Search here.....">
          <div class="search-btn position-absolute btn-danger w-30-h-30 rounded-circle center"
            *ngIf="searchChatWord !=''" (click)="removeSearchChatMessages()">
            <span><i class="fas fa-times"></i></span>
          </div>
        </div>
      </div>
      <div class="col-12 px-0">
        <div class="my-2 px-3 border border-end-0 border-start-0 scrollbar user-chat-container">
          <div class="col-12" *ngIf="searched_chatting?.length>0">
            <ng-container *ngFor="let chat of searched_chatting">
              <div class="my-2 d-flex justify-content-start" *ngIf="chat.user_to == user_id">
                <div class="w-25-h-25 overflow-hidden rounded-circle position-relative flex-shrink-0">
                  <img *ngIf="chat?.profile_photo && photo_visibility" class="image-cover position-absolute"
                    src="{{chat?.profile_photo}}" alt>
                  <img *ngIf="!chat?.profile_photo && photo_visibility == false" class="image-cover"
                    src="assets/images/default-profile-picture.svg" alt="...">

                </div>
                <div class="d">
                  <div
                    class="rounded-3 ms-2  border shadow-sm  bg-white text-muted  px-2 pt-2 chat-message position-relative">
                    <span *ngIf="chat?.mime_type == ''" [innerHtml]="chat?.message | safeHtmlPipe"> </span>

                    <p class="p-xsm text-end m-1">{{chat?.created_at | utcToLocalTime: 'short'}}</p>
                  </div>
                </div>
              </div>
              <div class="my-2 d-flex justify-content-end" *ngIf="chat.user_from == user_id">
                <div class>
                  <div
                    class="rounded-3 border shadow-sm me-2 bg-danger text-white  px-2 pt-2 chat-message-1 position-relative">
                    <p class="mb-0 lh-base">{{chat?.message}}</p>
                    <p class="p-xsm text-end m-1">{{chat?.msgSent | utcToLocalTime: 'short'}}
                      <i class="fas fa-check" *ngIf="chat?.message_status == 1"></i>
                      <i class="fas fa-check-double" *ngIf="chat?.message_status == 2"></i>
                      <i class="fas fa-eye" *ngIf="chat?.message_status == 3"></i>
                    </p>

                  </div>
                </div>
                <div class="w-25-h-25 overflow-hidden rounded-circle position-relative flex-shrink-0">
                  <img *ngIf="chat?.profile_photo && photo_visibility" class="image-cover position-absolute"
                    src="{{chat?.profile_photo}}" alt>
                  <img *ngIf="!chat?.profile_photo && photo_visibility == false" class="image-cover image-rounder"
                    src="assets/images/default-profile-picture.svg" alt="...">

                </div>
              </div>
            </ng-container>
          </div>
          <ng-container *ngIf="searched_chatting?.length == 0">
            <div class="text-center mt-3">
              <p class="mb-0 text-muted fw-bold"> No message found!</p>
              <p class="mb-2 text-muted p-sm">You can search messages if you chated with {{activeUser?.display_name}}
                ever.</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- light box div -->
<div class="row">
</div>

<div id="myModal" class="modal chat_media_modal" style="background-color:#000000; display:none;">
  <span class="close cursor" (click)="closeModal()">&times;</span>
  <div class="modal-content">
    <img src="{{openChatMedia}}" alt="">
  </div>
</div>
<!-- light box div end  -->
<!-- lightbox script -->
<script>
  function openModal() {
    console.log("modal open lightbox")
    document.getElementById("myModal").style.display = "block";
  }

  function closeModal() {
    document.getElementById("myModal").style.display = "none";
  }

  var slideIndex = 1;
  showSlides(slideIndex);

  function plusSlides(n) {
    showSlides(slideIndex += n);
  }

  function currentSlide(n) {
    showSlides(slideIndex = n);
  }

  function showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("demo");
    var captionText = document.getElementById("caption");
    if (n > slides.length) {
      slideIndex = 1
    }
    if (n < 1) {
      slideIndex = slides.length
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " active";
    captionText.innerHTML = dots[slideIndex - 1].alt;
  }

</script>