import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timezone'
})
export class TimezonePipe implements PipeTransform {
  transform(value: Date, timezone: string): string {
    const datePipe = new DatePipe('en-US');
    const formattedDate = datePipe.transform(value, 'medium', timezone);
    return formattedDate || '';
  }
}
