import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NotifService } from '../services/notif.service';
import { TokenService } from '../shared/token.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-loginhome',
  templateUrl: './loginhome.component.html',
  styleUrls: ['./loginhome.component.css']
})
export class LoginhomeComponent implements OnInit {
  isLoggedIn:any;
  user_id: number;

  

  constructor(
    private notifService: NotifService,
    private cookieService: CookieService,
    private router: Router,
    public token: TokenService,

  ) {
    this.isLoggedIn = this.token.isLoggedIn();
    this.user_id = parseInt(this.cookieService.get('user_id'));
  }

  ngOnInit(): void {
    //document why this method 'ngOnInit' is empty
  }

}
