import { Component, OnInit } from '@angular/core';
import { Title/*, Meta */ } from '@angular/platform-browser';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MyProfileService } from '../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.css']
})

export class ProfilePreviewComponent implements OnInit {
  myProfileDetail: any;
  myPhotos: any = 'assets/images/banner-4.jpg';
  myProfileGallery: any = [];
  profilePhoto: any = 'assets/images/men-avtar-2.svg';
  age: any;
  user_id: any;
  displayName: any;
  showTime: any = [];
  detailLoader: boolean = false;

  galleryPath: any = '';
  index: number;
  indexNext: number;
  indexPrevious: number;
  hoverIdx = -1;
  showCaption: string = ''

  constructor(private titleService: Title,
    private myProfileService: MyProfileService,
    private cookieService: CookieService,
    private router: Router,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.user_id = this.cookieService.get('user_id');
    this.titleService.setTitle('My Profile Preview | eternalstrings.com');
    this.userProfilePreview();
    this.getProfileGallery();
  }

  userProfilePreview() {
    this.detailLoader = true;
    let data = {
      user_id: this.user_id,
      detail_of: 'Self'
    }

    this.myProfileService.myProfilePreview(data).subscribe((res) => {
      if (res.status == true) {
        this.myProfileDetail = res['userDetails'][0];
        if (this.myProfileDetail.dob != '') {
          const date = moment(this.myProfileDetail.dob, 'YYYY-MM-DD');
          const years = moment().diff(date, 'years');
          this.age = years;
        }
        if (!(this.myProfileDetail.display_name == '' || this.myProfileDetail.display_name == null)) {

          this.displayName = this.myProfileDetail.display_name;

        } else {
          this.displayName = this.myProfileDetail.first_name;
        }
        if (this.myProfileDetail.profile_photo && this.myProfileDetail.profile_photo_approved == 1) {
          this.profilePhoto = this.myProfileDetail.profile_photo;
        }
        if (this.myProfileDetail.cover_photo && this.myProfileDetail.cover_photo_approved == 1) {
          this.myPhotos = this.myProfileDetail.cover_photo;
        }
        this.detailLoader = false;
        if (
          this.myProfileDetail.convenient_time_to_call != '' &&
          this.myProfileDetail.convenient_time_to_call != null
        ) {
          // time to call
          if (this.myProfileDetail.convenient_time_to_call == "Any-Time") {
            this.showTime = [];
            this.showTime.push("Any Time");
          } else {
            this.showTime = [];
            let std = this.myProfileDetail.convenient_time_to_call.split(',');
            std.forEach((element: any) => {
              if (element != '') {
                let daytime = element.split(' - ');
                daytime.forEach((element: any) => {
                  if (element != '') {
                    let s1 = daytime[0] + " " + daytime[1] + " " + daytime[2] + " " + daytime[3]
                    if (element == 'Sunday') {
                      this.showTime.push(s1);
                    } else if (element == 'Monday') {
                      this.showTime.push(s1);
                    } else if (element == 'Tuesday') {
                      this.showTime.push(s1);
                    } else if (element == 'Wednesday') {
                      this.showTime.push(s1);
                    } else if (element == 'Thursday') {
                      this.showTime.push(s1);
                    } else if (element == 'Friday') {
                      this.showTime.push(s1);
                    } else if (element == 'Saturday') {
                      this.showTime.push(s1);
                    } else if (element == 'Anytime') {
                      this.showTime = [];
                      this.showTime.push("Any Time");
                    } else {
                      // // console.log(element,"jay bhai time")
                    }
                  }
                  this.detailLoader = false;
                });
              }
            });
          }

        }
        this.detailLoader = false;
      } else {
        this.toastrService.warning('Some thing went wrong!!');
        this.detailLoader = false;
      }
    }, (error) => {
      this.toastrService.error('Api error!!');
      this.detailLoader = false;
    });
  }

  getProfileGallery() {
    let payload = {
      profile_id: this.user_id,
      user_id: this.user_id,
    };
    this.myProfileService.getProfileGallery(payload).subscribe((res) => {
      if (res.status) {
        this.myProfileGallery = res.UserProfileGallery;
      }
    }, (error) => {
      // unused code
      // this.toastrService.error('Some thing went wrong!!');
    });
  }

  EditAboutYou() {
    localStorage.setItem('selectedTab', 'aboutYou');
    this.router.navigate(['myInfo']);
  }

  EditBasicsLifestyle() {
    localStorage.setItem('selectedTab', 'basicAndLifestyle');
    this.router.navigate(['myInfo']);
  }

  EditAstroDetails() {
    localStorage.setItem('selectedTab', 'astro');
    this.router.navigate(['myInfo']);
  }

  EditEducationProfessionalDetails() {
    localStorage.setItem('selectedTab', 'education');
    this.router.navigate(['myInfo']);
  }

  EditMyPhotos() {
    localStorage.setItem('selectedTab', 'MyPhotos');
    this.router.navigate(['myPhotoGallery']);
  }

  EditContactDetails() {
    localStorage.setItem('selectedTab', 'contact');
    this.router.navigate(['myInfo']);
  }

  EditReligiousViews() {
    localStorage.setItem('selectedTab', 'religion');
    this.router.navigate(['myInfo']);
  }

  EditFamilyDetails() {
    localStorage.setItem('selectedTab', 'familyDetails');
    this.router.navigate(['myInfo']);
  }

  EditHobbiesInterests() {
    localStorage.setItem('selectedTab', 'hobby');
    this.router.navigate(['myInfo']);
  }

  EditYourLocation() {
    localStorage.setItem('selectedTab', 'location');
    this.router.navigate(['myInfo']);
  }

  openlight(data: any, index: number) {
    this.galleryPath = data.photo_url;
    this.showCaption = data.caption
    this.index = index;
  }

  nextSlides() {
    this.index = this.index + 1;
    this.indexNext = this.index;
    if (this.indexNext != this.myProfileGallery.length) {
      this.galleryPath = this.myProfileGallery[this.indexNext].photo_url;
      this.showCaption = this.myProfileGallery[this.indexNext].caption;
      console.log(this.indexNext, 'photoindex');
    } else if (this.indexNext == this.myProfileGallery.length) {
      this.galleryPath = this.myProfileGallery[0].photo_url;
      this.showCaption = this.myProfileGallery[0].caption;
      this.index = 0;
      console.log(this.indexNext, 'photoindex');
    }
  }

  previousSlides() {
    this.index = this.index - 1;
    this.indexPrevious = this.index;
    if (this.indexPrevious != -1) {
      this.galleryPath = this.myProfileGallery[this.indexPrevious].photo_url;
      this.showCaption = this.myProfileGallery[this.indexPrevious].caption;

      console.log(this.indexPrevious, 'photoindex');
    } else if (this.indexPrevious == -1) {
      this.galleryPath = this.myProfileGallery[(this.myProfileGallery.length - 1)].photo_url;
      this.showCaption = this.myProfileGallery[(this.myProfileGallery.length - 1)].caption;
      this.index = (this.myProfileGallery.length - 1)
      console.log(this.indexPrevious, 'photoindex');
    }
  }
}
