import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

// export class User {
  
//   profile_for !: String;
//       first_name !: String;
//       last_name !: String;
//       gender !: String;
//       languages!: String;
//       caste !: String;
//       religion!: String;
// }
@Injectable({
  providedIn: 'root'
})

export class RegisterService {
  private registerOneUrl =`${environment.apiURL}/auth/registerOne`;
  private getUserdetailsUrl =`${environment.apiURL}/auth/getUserDetails`;
  private getverifiedUrl =`${environment.apiURL}/auth/isVerifyProfileFor`;
  private getUserUrl =`${environment.apiURL}/auth/getUser`;



  constructor(
    private http: HttpClient
  ) { }

  registerOne(user: any): Observable<any> {
    return this.http.post(this.registerOneUrl, user);
  }
  getUserDetails(user:any): Observable<any> {
    return this.http.post(this.getUserdetailsUrl, user);
  }
  getUser(user:any): Observable<any> {
    return this.http.post(this.getUserUrl, user);
  }
  getverified(user:any): Observable<any> {
    return this.http.post(this.getverifiedUrl, user);
  }
}
