import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../services/common.service';

import * as $ from 'jquery';
import { Type } from 'typescript';


@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.css']
})
export class ProfileCardComponent implements OnInit {
  @Input() profile: any;
  @Output() eventName = new EventEmitter<Type>();

  displayName: any;
  profileId: any;
  user_id: any;
  selectId: any;
  profile_id: any;
  photoSrc: any;
  approve: any;
  constructor( 
    private CommonService: CommonService

  ) { }

  ngOnInit(): void {
    // document why this method 'ngOnInit' is empty
  }

  ViewProfile(id, Name) {
    localStorage.setItem("pageFrom", "Matches");
    let pid: any = localStorage.getItem('package_id');
    if (pid > 0) {
      let encrypted_id: any = this.CommonService.encryptData(id);
      encrypted_id = encrypted_id.toString().replaceAll('=', 'Ar1t').replaceAll('/', 'FGH').replaceAll('+', 'THD');
      let url = `${environment.baseURL}` + 'detail/' + encrypted_id
      window.open(url, '_blank');
    } else {
      this.displayName = Name;
      $('#openModal1').click();
    }
  }

  userBlockReport(user) {
    this.profileId = user.user_id
    this.clear();
  }
  clear() {
    // console.log("before ", this.reportReason)
    // this.reportReason = ""
    // this.exceedLimit = false;
    // this.otherReason = false
    // var ele = document.getElementsByName("Serious");
    // for (var i = 0; i < ele.length; i++) {
    //   var element = ele[i] as HTMLInputElement;
    //   element.checked = false;
    // }
  }

  actionSelected(event: any, profile_id: any) {
    this.selectId = profile_id;
    this.eventName.emit(this.selectId)

  }
  getId(event) {
    //  console.log(event.target.id)
    this.selectId = event.target.id
  }

  connectNow(id, Name, photo, approve) {

    let pid: any = localStorage.getItem('package_id');
    this.profile_id = id
    this.photoSrc = photo
    this.displayName = Name;
    this.approve = approve;
    if (pid > 1) {
      $('#openModal2').click();
    } else {
      $('#openModal1').click();
    }
  }

}
