<div class="w-100 bg-maroon" *ngIf="!isLoggedIn">
  <app-header></app-header>
</div>
<button style="display:none;" id="topBar"></button>

<section class="row m-0 section">
  <div class="row mx-0 row-cols-1 row-cols-md-2 row-cols-lg-2 g-4 py-3">
    <div class="col ">
      <div class="mx-auto mx-width-800 py-0">

        <div class="info white-text geeks">
          <div class="inner_div">
            <h6><strong>Please read before Sign-Up</strong></h6>
            When you are signing up here for<b> someone other than yourself:</b><br>Brother, Daughter, Friends,
            Relative, Sister or Son<br>
            <p>Then please Sign-Up using your own:<br>
              <b> First Name:</b><br>
              <b> Last Name:</b><br>
              <b> Mobile Number:</b><br>
              <b> Email:</b>
            </p>
            <p>After Sign-Up you will be able to fill in all their details their:<br>
              First Name:<br>
              Last Name:<br>
              Mobile Number:<br>
              Email:<br>
              Various other details includings:<br>
              Profile picture<br>
              cover picture<br>
          </div>
        </div>
      </div>
    </div>
    <div class="col gape">
      <form #signUpNgForm="ngForm" [formGroup]="signUpForm">

        <div class="row mx-auto mx-width-800 py-md-5">

          <div class="col-12 my-3">
            <h1 class="fw-bold text-center display-1">Sign-Up Here!</h1>
          </div>

          <div class="col-12 col-md-6 my-3">
            <label for="firstname" class="form-label py-1 mb-2 fw-bold h6">First Name <span class="text-danger">
                *</span></label>
            <input type="text" maxlength="100" class="form-control" id="firstname" placeholder="Enter First Name"
              [formControlName]="'firstName'" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
              (input)="NameSpaceNotAllowed($event)" autocomplete="" (paste)="pasteText($event)"
              (keypress)="omit_special_char($event)">
            <div *ngIf="submitted && f.firstName.errors">
              <div *ngIf="f.firstName.errors.required" class="form-label mb-2 py-1 fw-bold text-danger">First name is
                required</div>
              <div *ngIf="f.firstName.errors.pattern" class="form-label mb-2 py-1 fw-bold text-danger">First name is not
                valid</div>
            </div>
            <div *ngIf="errors?.name">
              <div class="form-label mb-2 py-1 fw-bold text-danger">{{ errors?.name }}</div>
            </div>
          </div>

          <div class="col-12 col-md-6 my-3">
            <label for="lastname" class="form-label fw-bold mb-2 h6 py-1">Last Name <span class="text-danger">
                *</span></label>
            <input type="text" maxlength="100" class="form-control" id="lastname" placeholder="Enter Last Name"
              [formControlName]="'lastName'" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
              (input)="NameSpaceNotAllowed($event)" autocomplete="" (paste)="pasteText1($event)"
              (keypress)="omit_special_char($event)">
            <div *ngIf="submitted && f.lastName.errors">
              <div *ngIf="f.lastName.errors.required" class="form-label mb-2 py-1 fw-bold text-danger">Last name is
                required
              </div>
              <div *ngIf="f.lastName.errors.pattern" class="form-label mb-2 py-1 fw-bold text-danger">Last name is not
                valid
              </div>
            </div>
          </div>

          <div class="col-12 mt-3">
            <div class="row">
              <label for="Mobile" class="form-label mb-2 py-1 fw-bold h6">Mobile Number <span class="text-danger">
                  *</span></label>
              <div class="col-12 col-md-4 mb-md-3 pe-md-0">

                <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="id"
                  [formControlName]="'countryCode'" [(ngModel)]="phoneCode" placeholder="Select Country Code"
                  dropdownPosition="auto" [virtualScroll]="true" (change)="OnchangeCountryCode($event)">
                  <ng-template ng-label-tmp let-item="item">
                    <img height="20" width="20"
                      src="assets/images/flag/Flag_of_{{item.country_name | replaceSpaceWithUnderscore}}.svg" alt=""
                      style="margin-right:5px;" alt="image" title="Country" />
                    <b> {{item.country_name}}</b>{{item.country_code}}
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <img height="20" width="20"
                      src="assets/images/flag/Flag_of_{{item.country_name | replaceSpaceWithUnderscore}}.svg" alt=""
                      style="margin-right:5px;" alt="image" title="Country"/>
                    <b> {{item.country_name}}</b>{{item.country_code}}
                  </ng-template>
                </ng-select>

                <div *ngIf="submitted && f.countryCode.errors">
                  <div class="form-label mb-2 py-1 fw-bold text-danger">Country code is required</div>
                </div>
              </div>
              <div class="col-12 col-md-8">
                <input type="text" class="form-control" id="Mobile" placeholder="Enter" [formControlName]="'mobileNo'"
                  [ngClass]="{ 'is-invalid':
              submitted && f.mobileNo.errors }" (input)="keyPressNumeric($event)" (paste)="pasteNumeric($event)"
                  autocomplete="" [ngModel]="phoneNo" maxlength="15">
                <div *ngIf="submitted && f.mobileNo.errors">
                  <div *ngIf="f.mobileNo.errors.required">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile number is required</div>
                  </div>
                  <div *ngIf="f.mobileNo.errors.minlength">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be between 6 to 15 digits
                    </div>
                  </div>
                  <div *ngIf="f.mobileNo.errors.maxlength">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be between 6 to 15 digits
                    </div>
                  </div>
                </div>
                <div *ngIf="!submitted">
                  <div *ngIf="minNumberLength ">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be between 6 to 15 digits
                    </div>
                  </div>
                  <div *ngIf="maxNumberLength">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be between 6 to 15 digits
                    </div>
                  </div>
                </div>
                <div *ngIf="submitted && errors">
                  <div *ngIf="errors.mobileNo">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">{{ errors?.mobileNo }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mt-3">
            <label for="Email" class="form-label mb-2 py-1 fw-bold h6">Email <span class="text-danger"> *</span></label>
            <input type="text" class="form-control" id="Email" placeholder="Enter Email" [formControlName]="'email'"
              [ngClass]="{ 'is-invalid': submitted
          && f.email.errors }" autocomplete="">
            <div *ngIf="submitted && f.email.errors">
              <div *ngIf="f.email.errors.required">
                <div class="form-label mb-2 py-1 fw-bold text-danger">Email is required</div>
              </div>
              <div *ngIf="f.email.errors.email || f.email.errors.pattern">
                <div class="form-label mb-2 py-1 fw-bold text-danger">Email must be a valid email address</div>
              </div>
            </div>
            <div *ngIf="errors?.email">
              <div class="form-label mb-2 py-1 fw-bold text-danger">{{ errors?.email }}</div>
            </div>
          </div>

          <div class="col-12 mt-3">
            <div class="form-check p-0 m-0 d-flex align-items-center">
              <input class="checkbox me-2" type="checkbox" value id="flexCheckDefault" [formControlName]="'TandC'"
                (change)="check()" [ngClass]="{
            'is-invalid': submitted && f.TandC.errors }">
              <label class="form-check-label text-muted p-sm" for="flexCheckDefault">
                By signing up, you agree to the eternalstrings.com
                <a href="javascript:void(0);" class="text-danger" target="_blank" routerLinkActive="active"
                  routerLink="/Terms" rel="noopener">Terms and
                  Conditions</a> and
                <a href="javascript:void(0);" routerLinkActive="active" target="_blank" routerLink="/Privacy"
                  class="text-danger"rel="noopener"> Privacy
                  Policy</a>.
              </label>
            </div>
            <div *ngIf="submitted && f.TandC.errors">
              <div class="form-label mb-2 py-1 fw-bold text-danger">To proceed further you need to accept and agree to
                the
                Terms and Conditions and
                Privacy Policy</div>
            </div>
            <div *ngIf="TandC">
              <div class="form-label mb-2 py-1 fw-bold text-danger">To proceed further you need to accept and agree to
                the
                Terms and Conditions and
                Privacy Policy</div>
            </div>
            <div>

            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="form-check p-0 m-0 d-flex align-items-center">
              <input class="checkbox me-2" type="checkbox" id="flexCheckDefault" [formControlName]="'sendmail'">
              <label class="form-check-label text-muted p-sm" for="flexCheckDefault">
                Allow to receive promotional emails from <a href="javascript:void(0)" target="_blank" routerLink="/home"
                  class="text-danger" rel="noopener">eternalstrings.com</a>
              </label>
            </div>
          </div>

          <div class="col-12 text-center mt-5">
            <a href="javascript:void(0);" *ngIf="changeButtonDisable">
              <button type="button" class="btn px-5 py-2 rounded-3 fs-5 fw-bold btn-danger" [disabled]="buttonDisable"
                (click)="registerDone()" style="
            margin-right: 30px">Submit</button>
            </a>
            <button *ngIf="!changeButtonDisable" class="btn py-2 rounded-3 fs-5 fw-bold btn-danger"
              (click)="updateRegisterDone()">Update & Resend</button>
            <a *ngIf="!changeButtonDisable" href="javascript:void(0);" class="text-danger" style="margin-left: 20px;"
              data-bs-toggle="modal" data-bs-target="#loginModal"> Go to login </a>
          </div>

        </div>
      </form>
    </div>
  </div>
</section>

<section class="bg-white py-4">
  <div class="container">
    <h2 class="pt-4 text-danger text-center">Why register on eternalstrings.com?</h2>
    <div class="seperator-line centered"></div>

    <div class="row row-cols-1 row-cols-md-2 mx-width-800 mx-auto row-cols-lg-2 g-4 text-center py-5">

      <div class="col">
        <div class="card border-0 ">
          <div class="card-body px-3">
            <div class>
              <div class="align-items-center d-flex justify-content-center bg-danger mx-auto rounded-circle w-65-h-65">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                  class="bi bi-lock-fill text-light" viewBox="0 0 16
                  16">
                  <path
                    d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                </svg>
              </div>
            </div>
            <h3 class="card-title text-danger py-0 mb-0 mt-2">100% Privacy</h3>
            <p class="card-text">
              100% Control on your Photos and info.</p>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card border-0 ">
          <div class="card-body px-3">
            <div class>
              <div class="align-items-center d-flex justify-content-center bg-danger mx-auto rounded-circle w-65-h-65">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                  class="bi bi-safe text-light" viewBox="0 0 16
                    16">
                  <path d="M1 1.5A1.5 1.5 0 0 1 2.5 0h12A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-12A1.5 1.5 0 0 1 1 14.5V13H.5a.5.5 0 0 1
                      0-1H1V8.5H.5a.5.5 0 0 1 0-1H1V4H.5a.5.5 0 0 1 0-1H1V1.5zM2.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5v-13a.5.5 0
                      0 0-.5-.5h-12z" />
                  <path d="M13.5 6a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zM4.828 4.464a.5.5 0 0 1 .708 0l1.09 1.09a3.003 3.003 0 0 1
                        3.476 0l1.09-1.09a.5.5 0 1 1 .707.708l-1.09 1.09c.74 1.037.74 2.44 0 3.476l1.09 1.09a.5.5 0 1 1-.707.708l-1.09-1.09a3.002
                        3.002 0 0 1-3.476 0l-1.09 1.09a.5.5 0 1 1-.708-.708l1.09-1.09a3.003 3.003 0 0 1 0-3.476l-1.09-1.09a.5.5 0 0 1 0-.708zM6.95
                        6.586a2 2 0 1 0 2.828 2.828A2 2 0 0 0 6.95 6.586z" />
                </svg>
              </div>
            </div>
            <h3 class="card-title text-danger py-0 mb-0 mt-2">Fully secure</h3>
            <p class="card-text">
              Data information are safe & secure</p>
          </div>
        </div>
      </div>

    </div>

  </div>
</section>

<app-footer></app-footer>