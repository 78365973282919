import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { TokenService } from "../shared/token.service";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private tokenService: TokenService) { }
    accessData: string = '';
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const accessToken = this.tokenService.getToken();
        const regex: RegExp = /\/admin\//;
        if (regex.test(req.url)) {
            this.accessData = 'Eternal_Strings_Admin';
        } else {
            this.accessData = 'Eternal_Strings_User';
        }
        req = req.clone({
            setHeaders: {
                Authorization: "Bearer " + accessToken,
                CheckUser: this.accessData
            }
        });
        return next.handle(req);
    }
}