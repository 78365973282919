import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Pipe({
  name: 'limitHtml'
})
export class LimitHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value: SafeHtml, maxLength: number): SafeHtml {
    const sanitizedValue = this.sanitizer.sanitize(SecurityContext.HTML, value);

    // charater start
    // if (!sanitizedValue) {
    //   return '';
    // }

    // if (sanitizedValue.length <= maxLength) {
    //   return value;
    // }
    // charater end 

    // const truncatedValue = sanitizedValue.slice(0, maxLength) + '...';
    // return this.sanitizer.bypassSecurityTrustHtml(truncatedValue);


    // word start 
    // Convert the SafeHtml content to a string 
    const stringValue = value.toString();

    // Split the content into words and keep only the specified wordCount
    const words = stringValue.split(' ', maxLength);
    const truncatedContent = words.join(' ');

    // Sanitize and return the truncated content as SafeHtml
    return this.sanitizer.bypassSecurityTrustHtml(truncatedContent);
    // word end 

  }
}