<div class="container">
  <div class="row">

  </div>
</div>

<section class="section mt-93">

  <form #registerNgForm="ngForm" id="register" [formGroup]="registerInForm">
    <div *ngIf="errors?.profile_for" class="alert alert-danger mt-3">
      {{ errors?.profile_for }}
    </div>
    <div *ngIf="errors?.firstName" class="alert alert-danger mt-3">
      {{ errors?.firstName }}
    </div>
    <div *ngIf="errors?.lastName" class="alert alert-danger mt-3">
      {{ errors?.lastName }}
    </div>
    <div *ngIf="errors?.gender" class="alert alert-danger mt-3">
      {{ errors?.gender }}
    </div>
    <div *ngIf="errors?.languages" class="alert alert-danger mt-3">
      {{ errors?.languages }}
    </div>
    <div *ngIf="errors?.religion" class="alert alert-danger mt-3">
      {{ errors?.religion }}
    </div>

    <div class="row mx-auto mx-width-800 py-5">
      <h1 class="fw-bold text-center display-2">Register Here</h1>
      <div class="w-100">
        <h3 class="fw-bold text-center display-2">{{name}}</h3>
        <span class="form-label mb-1 error text-success w-100 mb-3 ">
          <h3 class="hover-danger text-center">{{verifyMsg}}</h3>
        </span>
      </div>
      <input class="form-check-input" type="hidden" [formControlName]="'user_id'">

      <div class="col-12">
        <h6 class="fw-bold"> Profile For <span class="text-danger"> *</span></h6>
        <div class="select-box" [ngClass]="{ 'is-invalid': submitted && f.profile_for.errors }">

          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Self1" value="Self" [formControlName]="'profile_for'"
              (click)="profileFor($event)">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Self1">Self</label>

          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Brother2" value="Brother" [formControlName]="'profile_for'"
              (click)="profileFor($event)">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Brother2">Brother</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Daughter3" value="Daughter"
              [formControlName]="'profile_for'" (click)="profileFor($event)">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Daughter3">Daughter </label>

          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Friend4" value="Friend" [formControlName]="'profile_for'"
              (click)="profileFor($event)">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Friend4">Friend
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Relative5" value="Relative"
              [formControlName]="'profile_for'" (click)="profileFor($event)">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Relative5">Relative
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Sister6" value="Sister" [formControlName]="'profile_for'"
              (click)="profileFor($event)">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Sister6">Sister
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Son7" value="Son" [formControlName]="'profile_for'"
              (click)="profileFor($event)">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Son7">Son </label>
          </div>

          <div *ngIf="submitted && f.profile_for.errors">
            <div class="form-label mb-2 py-1 fw-bold text-danger"> Profile for is required</div>
          </div>
        </div>

      </div>

      <div class="col-12" *ngIf="!profile_for">
        <label class="fw-bold" for="flexCheckIndeterminate">
          Please check the box below to acknowledge that you have permission and the individual is over 18 years of
          age.</label>
        <div class="py-2">
          <input class="checkbox me-2" type="checkbox" [formControlName]="'have_permission'" id="flexCheckIndeterminate"
            (change)="permission()">
          <label class="fw-bold" for="flexCheckIndeterminate">
            I have permission to create profile for my {{relation_name}}.<span class="text-danger"> *</span>
          </label>
        </div>
        <div *ngIf="!havePermission">
          <div class="form-label mb-2 py-1 fw-bold text-danger">{{relation}} Permission is required</div>
        </div>
      </div>

      <div class="col-12 col-md-6 my-3" *ngIf="!profile_for">
        <h6 class="fw-bold ">{{relation}} First Name <span class="text-danger"> *</span></h6>
        <input [readonly]="!profileIsVerified" type="text" maxlength="25" class="form-control"
          [formControlName]="'first_name'" [ngClass]="{
          'is-invalid': submitted && f.first_name.errors}" (input)="NameSpaceNotAllowed($event)"
          (paste)="pasteText($event)" (keypress)="omit_special_char($event)">
        <div *ngIf="submitted && f.first_name.errors">
          <div *ngIf="f.first_name.errors.required" class="form-label mb-2 py-1 fw-bold text-danger">First Name is
            required
          </div>
          <div *ngIf="f.first_name.errors.pattern" class="form-label mb-2 py-1 fw-bold text-danger">First name is not
            valid</div>
        </div>
      </div>

      <div class="col-12 col-md-6 my-3" *ngIf="!profile_for">
        <h6 class="fw-bold ">{{relation}} Last Name <span class="text-danger"> *</span></h6>
        <input [readonly]="!profileIsVerified" type="text" maxlength="25" class="form-control"
          [formControlName]="'last_name'" [ngClass]="{
          'is-invalid': submitted && f.last_name.errors }" (input)="NameSpaceNotAllowed($event)"
          (paste)="pasteText1($event)" (keypress)="omit_special_char($event)">
        <div *ngIf="submitted && f.last_name.errors">
          <div *ngIf="f.last_name.errors.required" class="form-label mb-2 py-1 fw-bold text-danger">Last Name is
            required
          </div>
          <div *ngIf="f.last_name.errors.pattern" class="form-label mb-2 py-1 fw-bold text-danger">Last name is not
            valid</div>
        </div>
      </div>
      <div class="col-12 mt-3" *ngIf="!profile_for">
        <h6 class="fw-bold ">{{relation}} Email <span class="text-danger"> *</span></h6>
        <input class="form-check-input" type="hidden" id="user" [formControlName]="'user_id'" />

        <input [readonly]="!profileIsVerified" type="email" class="form-control" id="firstname" placeholder="Enter"
          [formControlName]="'email'" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" autocomplete="" />
        <div *ngIf="submitted && f.email.errors">
          <div *ngIf="f.email.errors.required">
            <div class="form-label mb-2 py-1 fw-bold text-danger">Email is required</div>
          </div>
          <div *ngIf="f.email.errors.email || f.email.errors.pattern">
            <div class="form-label mb-2 py-1 fw-bold text-danger">Email must be a valid email address</div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3" *ngIf="!profile_for">
        <div class="row">
          <h6 class="fw-bold ">{{relation}} Mobile Number<span class="text-danger"> *</span></h6>
          <div class="col-6">

            <ng-select [readonly]="!profileIsVerified" [items]="this.countries" bindLabel="fullnumber" bindValue="id"
              [formControlName]="'country_code'" [(ngModel)]="phoneCode" placeholder="Select Country Code"
              dropdownPosition="auto" [virtualScroll]="true" (change)="OnchangeCountryCode($event)">
              <ng-template ng-label-tmp let-item="item">
                <img height="20" width="20"
                  src="assets/images/flag/Flag_of_{{item.country_name | replaceSpaceWithUnderscore}}.svg" alt=""
                  style="margin-right:5px;" />
                <b> {{item.country_name}}</b>{{item.country_code}}
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <img height="20" width="20"
                  src="assets/images/flag/Flag_of_{{item.country_name | replaceSpaceWithUnderscore}}.svg" alt=""
                  style="margin-right:5px;" />
                <b> {{item.country_name}}</b>{{item.country_code}}
              </ng-template>
            </ng-select>

            <div *ngIf="submitted && f.country_code.errors">
              <div class="form-label mb-2 py-1 fw-bold text-danger">Country code is required</div>
            </div>

          </div>
          <div class="col-6">
            <input [readonly]="!profileIsVerified" type="text" class="form-control" id="Mobile" placeholder="Enter"
              [formControlName]="'mobile_no'" [ngClass]="{ 'is-invalid': submitted && f.mobile_no.errors }"
              (input)="keyPressNumeric($event)" (paste)="pasteNumeric($event)" maxlength="15" />
            <div *ngIf="submitted && f.mobile_no.errors">
              <div *ngIf="f.mobile_no.errors.required">
                <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile number is required</div>
              </div>
              <div *ngIf="f.mobile_no.errors.minlength">
                <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be between 6 to 15 digits</div>
              </div>
              <div *ngIf="f.mobile_no.errors.maxlength">
                <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be between 6 to 15 digits</div>
              </div>
            </div>
            <div *ngIf="!submitted">
              <div *ngIf="minNumberLength ">
                <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be between 6 to 15 digits</div>
              </div>
              <div *ngIf="maxNumberLength">
                <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be between 6 to 15 digits</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3" id="scrollOnLanguages">
        <h6 class="fw-bold ">{{relation}} Gender <span class="text-danger"> *</span></h6>
        <div class="select-box" [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="disable_g">
            <input class="form-check-input" type="radio" id="Male" value="Male" [attr.disabled]="!disable_m"
              [formControlName]="'gender'">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Male">Male</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="disable_g">
            <input class="form-check-input" type="radio" id="Female" value="Female" [attr.disabled]="disable_f"
              [formControlName]="'gender'">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="Female">Female</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!disable_g">
            <input class="form-check-input" type="radio" id="Male" value="Male" [formControlName]="'gender'">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Male">Male</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!disable_g">
            <input class="form-check-input" type="radio" id="Female" value="Female" [formControlName]="'gender'">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="Female">Female</label>
          </div>
          <div *ngIf="submitted && f.gender.errors">
            <div class="form-label mb-2 py-1 fw-bold text-danger">Gender is required</div>
          </div>
        </div>

      </div>

      <div class="col-12 mt-3">
        <h6 class="fw-bold ">{{relation}} Preferred Language <span class="text-danger"> *</span></h6>
        <div class="select-box" [ngClass]="{ 'is-invalid': submitted && f.languages.errors }">
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Bengali" value="Bengali" [formControlName]="'languages'"
              (change)="checkLanguage()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Bengali">Bengali</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="English" value="English" [formControlName]="'languages'"
              (change)="checkLanguage()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="English">English</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Gujarati" value="Gujarati" [formControlName]="'languages'"
              (change)="checkLanguage()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Gujarati">Gujarati</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Hindi" value="Hindi" [formControlName]="'languages'"
              (change)="checkLanguage()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Hindi">Hindi</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Marathi" value="Marathi" [formControlName]="'languages'"
              (change)="checkLanguage()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Marathi">Marathi</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Punjabi" value="Punjabi" [formControlName]="'languages'"
              (change)="checkLanguage()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Punjabi">Punjabi</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Tamil" value="Tamil" [formControlName]="'languages'"
              (change)="checkLanguage()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Tamil">Tamil</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Telugu" value="Telugu" [formControlName]="'languages'"
              (change)="checkLanguage()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Telugu">Telugu</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!otherLanguage">
            <input class="form-check-input" type="radio" id="OtherLanguages" value="OtherLanguages"
              [formControlName]="'languages'" (change)="checkLanguage()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="OtherLanguages">Other</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="otherLanguage">
            <input type="text" class="form-control" id="OtherLanguages" placeholder="Enter language" autocomplete=""
              [formControlName]="'languages'" (input)="NameSpaceNotAllowed($event)" minlength="2" maxlength="25">
          </div>
          <div *ngIf="submitted && f.languages.errors">
            <div class="form-label mb-2 py-1 fw-bold text-danger">Language is required</div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3">
        <h6 class="fw-bold">{{relation}} Religious Views <span class="text-danger"> *</span></h6>
        <div class="select-box" [ngClass]="{ 'is-invalid': submitted && f.religion.errors }">
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Buddhist" value="Buddhist" [formControlName]="'religion'"
              (change)="checkReligion()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Buddhist">Buddhist</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Christian" value="Christian" [formControlName]="'religion'"
              (change)="checkReligion()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Christian">Christian</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Hindu" value="Hindu" [formControlName]="'religion'"
              (change)="checkReligion()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Hindu">Hindu</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Jain" value="Jain" [formControlName]="'religion'"
              (change)="checkReligion()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Jain">Jain</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Muslim" value="Muslim" [formControlName]="'religion'"
              (change)="checkReligion()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Muslim">Muslim</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Parsi" value="Parsi" [formControlName]="'religion'"
              (change)="checkReligion()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Parsi">Parsi</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="Sikh" value="Sikh" [formControlName]="'religion'"
              (change)="checkReligion()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Sikh">Sikh</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" id="NoReligion" value="No Religion"
              [formControlName]="'religion'" (change)="checkReligion()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="NoReligion">No
              Religion</label>
          </div>

          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!otherReligion">
            <input class="form-check-input" type="radio" id="Other" value="Other" min="2" max="100"
              [formControlName]="'religion'" (change)="checkReligion()">
            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Other">Other</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="otherReligion">
            <input type="text" class="form-control" id="caste" placeholder="Enter religion"
              [formControlName]="'religion'" (input)="NameSpaceNotAllowed($event)" minlength="2" maxlength="25">
          </div>
        </div>
        <div *ngIf="submitted && f.religion.errors">
          <div class="form-label mb-2 py-1 fw-bold text-danger">Religion is required</div>
        </div>
      </div>

      <div class="col-12 mt-3">
        <label for="caste" class="form-label mb-2 py-1 fw-bold h6"> Caste <span class="fw-normal text-muted">
            (Optional) </span> <span class="btn p-0 my-0 mx-1 border-0" placement="top"
            ngbTooltip="Any class or group of society sharing common cultural feature.">
            <i class="fas fa-question-circle fs-5 p-0 m-0 text-danger"></i> </span></label>

        <input type="text" class="form-control" id="caste" placeholder="Enter Caste" [formControlName]="'caste'"
          (input)="NameSpaceNotAllowed($event)" minlength="3" maxlength="25">
      </div>

      <div class="col-12 text-end mt-5">
        <a href="javascript:void(0);">
          <button type="button" class="btn py-2 px-5 rounded-3 fs-5 fw-bold btn-danger" [disabled]="disableButton"
            (click)="register()">Next</button>
        </a>
      </div>
    </div>
  </form>
</section>
<app-login-footer></app-login-footer>