import { Component, OnInit, ViewChild/*, ViewEncapsulation */ } from '@angular/core';
import { Router } from '@angular/router';
import { StepService } from '../services/step.service';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm, } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
import { Title/*, Meta */ } from '@angular/platform-browser';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css'],
})
export class StepComponent implements OnInit {

  @ViewChild('stepForm') stepNgForm: NgForm;
  errorMessage: string;
  errors: any;
  submitted = false;
  buttonDisable: boolean = false;
  stepForm = new FormGroup({
    user_id: new FormControl(''),
    living_with_family: new FormControl('', Validators.required),
    family_type: new FormControl(''),
    residency_status: new FormControl('', Validators.required),
    country_of_birth: new FormControl('', Validators.required),
    marital_status: new FormControl('', Validators.required),
    have_children: new FormControl(''),
    boy_child: new FormControl(''),
    boy_child_not_married: new FormControl('', [Validators.minLength(1), Validators.maxLength(2)]),
    boy_child_married: new FormControl(''),
    girl_child: new FormControl(''),
    girl_child_not_married: new FormControl(''),
    girl_child_married: new FormControl(''),
    food_preference: new FormControl('', Validators.required),
    height: new FormControl('', Validators.required),
    weight: new FormControl('', Validators.required),
    hobbies: new FormControl(''),
    hobbiesOther: new FormControl(''),
  })

  name = 'Angular Multi Select Dropdown';
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  dropdownSettings = {};
  status_single = false;
  haveChildren = false;
  boyChild = true;
  girlChild = true;
  hobby: any = [];
  jsonObj: any;
  familyType = false;
  birthCountry: boolean = false;
  checkHobby: boolean = false;
  foodPreference: boolean = false;
  requiredHobby: boolean = false;
  weight: any = [];
  height: any = [];
  username: any;
  countries: any = [];
  countryOfBirth: any = null;
  residencyOtherCheck: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private stepservices: StepService,
    private authService: AuthService,
    private cookieService: CookieService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.getCountryList()
    window.scroll(0, 0);
    this.titleService.setTitle('Step 1');

    let dd = this.cookieService.get('user_id');
    this.f.user_id.setValue(dd);
    let data = {
      user_id: dd
    }
    this.stepservices.getUserDetails(data)
      .subscribe((res: any) => {
        if (res?.userDetails) {
          let detail = res.userDetails
          this.username = detail.first_name + " " + detail.last_name
          if (detail != 'user data not found') {
            if (detail.register_done <= 2) {
              this.f.user_id.setValue(dd);
            } else {
              this.f.living_with_family.setValue(detail.living_with_family);
              if (detail?.residency_status) {
                if (detail.residency_status == "Citizen") {
                  this.f.residency_status.setValue(detail.residency_status);
                } else if (detail.residency_status == "Work Permit") {
                  this.f.residency_status.setValue(detail.residency_status);
                } else if (detail.residency_status == "Permanent Resident") {
                  this.f.residency_status.setValue(detail.residency_status);
                } else if (detail.residency_status == "Student Visa") {
                  this.f.residency_status.setValue(detail.residency_status);
                } else if (detail.residency_status == "Visitor Visa") {
                  $('#VisitorVisa').prop('checked', true);
                } else {
                  this.residencyOtherCheck = true;
                  this.f.residency_status.setValue(detail.residency_status);
                }
              }

              // residency status
              if (detail.living_with_family == "Yes") {
                this.familyType = true;
                this.f.family_type.setValue(detail.family_type);
              }
              if (detail.country_of_birth != '') {
                let mailCountries = ["38", "231", "13", "75", "101", "165", "229", "230"]
                if (mailCountries.indexOf(detail.country_of_birth) != -1) {
                  this.f.country_of_birth.setValue(detail.country_of_birth)
                } else {
                  if (detail.country_of_birth != 'Other') {
                    this.f.country_of_birth.setValue(detail.country_of_birth)
                    this.countryOfBirth = parseInt(detail.country_of_birth);
                  } else {
                    this.countryOfBirth = null;
                  }
                  this.birthCountry = true;
                }
              }

              this.f.marital_status.setValue(detail.marital_status);
              if (detail.marital_status == "Annulled" || detail.marital_status == "Single") {
                this.status_single = false;
              } else {
                this.status_single = true;
                this.f.have_children.setValue(detail.have_children);
                if (detail.have_children == "Yes") {
                  this.haveChildren = true;
                  if (detail.boy_child == "Yes" || detail.boy_child == true) {
                    this.f.boy_child.setValue(detail.boy_child);
                    this.boyChild = false;
                    this.f.boy_child_not_married.setValue(detail.boy_child_not_married);
                    this.f.boy_child_married.setValue(detail.boy_child_married);
                  }
                  if (detail.girl_child == "Yes" || detail.girl_child == true) {
                    this.f.girl_child.setValue(detail.girl_child);
                    this.girlChild = false;
                    this.f.girl_child_not_married.setValue(detail.girl_child_not_married);
                    this.f.girl_child_married.setValue(detail.girl_child_married);
                  }
                } else {
                  this.haveChildren = false;
                }
              }

              this.f.height.setValue(detail.height);
              this.f.weight.setValue(detail.weight);
              if (detail.food_preference != '') {
                if (detail.food_preference == "Veg") {
                  this.f.food_preference.setValue("Veg")
                } else if (detail.food_preference == "Non Veg") {
                  this.f.food_preference.setValue("Non Veg")
                } else if (detail.food_preference == "Occasionally Non-Veg") {
                  this.f.food_preference.setValue("Occasionally Non-Veg")
                } else if (detail.food_preference == "Eggetarian") {
                  this.f.food_preference.setValue("Eggetarian")
                } else if (detail.food_preference == "Pescatarian") {
                  this.f.food_preference.setValue("Pescatarian")
                } else if (detail.food_preference == "Jain-Food") {
                  this.f.food_preference.setValue("Jain-Food")
                } else if (detail.food_preference == "Vegan") {
                  this.f.food_preference.setValue("Vegan")
                } else {
                  this.foodPreference = true;
                  this.f.food_preference.setValue(detail.food_preference)
                }
              }

              if (detail?.hobbies) {
                let hobbiesArr = detail.hobbies.split('_');
                this.hobby = [];
                hobbiesArr.forEach((element: any) => {
                  if (element != '') {
                    if (element == "Arts And Crafts") {
                      this.hobby.push("Arts And Crafts");
                      $('#ArtsAndCrafts').prop('checked', true);
                    } else if (element == "Baking") {
                      this.hobby.push("Baking");
                      $('#Baking').prop('checked', true);
                    } else if (element == "Cooking") {
                      this.hobby.push("Cooking");
                      $('#Cooking').prop('checked', true);
                    } else if (element == "Gardening") {
                      this.hobby.push("Gardening");
                      $('#Gardening').prop('checked', true);
                    } else if (element == "Reading") {
                      this.hobby.push("Reading");
                      $('#Reading').prop('checked', true);
                    } else if (element == "Team Sports") {
                      this.hobby.push("Team Sports");
                      $('#TeamSports').prop('checked', true);
                    } else if (element == "Working Out") {
                      this.hobby.push("WorkingOut");
                      $('#WorkingOut').prop('checked', true);
                    } else if (element == "Yoga") {
                      this.hobby.push("Yoga");
                      $('#Yoga').prop('checked', true);
                    } else {
                      this.f.hobbiesOther.setValue(element);
                      this.checkHobby = true;
                    }
                  }
                });
              }
            }
          }
        }
      });

    for (let i = 32; i <= 114; i++) {
      this.weight.push({ i });
    }

    for (let i = 100; i <= 230; i++) {
      this.height.push({ i });
    }
  }

  getCountryList() {
    this.authService.getCountryList().subscribe(
      (result) => {
        this.countries = result.data;
      },
      (error) => {

      },
      () => {
      }
    );
  }

  get f() {
    return this.stepForm.controls;
  }

  checkFamilyType() {
    if (this.stepForm.value.living_with_family == "Yes") {
      this.familyType = true;
    } else {
      this.familyType = false;
      this.f.family_type.setValue("");
    }
  }

  checkMaritalStatus() {
    if (this.stepForm.value.marital_status == "Single" || this.stepForm.value.marital_status == "Annulled") {
      this.status_single = false;
      this.haveChildren = false;
      this.f.have_children.setValue("");
      this.f.boy_child.setValue("");
      this.f.girl_child.setValue("");
      this.f.boy_child_not_married.setValue(0);
      this.f.boy_child_married.setValue(0);
      this.f.girl_child_not_married.setValue(0);
      this.f.girl_child_married.setValue(0);
    } else {
      this.status_single = true;
    }
  }

  checkHaveChildren() {
    if (this.stepForm.value.have_children == "Yes") {
      this.haveChildren = true;
    } else {
      this.haveChildren = false;
      this.boyChild = false;
      this.girlChild = false;
      this.f.boy_child.setValue("");
      this.f.girl_child.setValue("");
      this.f.boy_child_not_married.setValue("");
      this.f.boy_child_married.setValue("");
      this.f.girl_child_not_married.setValue("");
      this.f.girl_child_married.setValue("");
    }
  }

  checkBoyChild() {
    if (this.stepForm.value.boy_child == true) {
      this.boyChild = false;
    } else {
      this.boyChild = true;
      this.f.boy_child_not_married.setValue("");
      this.f.boy_child_married.setValue("");
    }
  }

  checkGirlChild() {
    if (this.stepForm.value.girl_child == true) {
      this.girlChild = false;

    } else {
      this.girlChild = true;
      this.f.girl_child_not_married.setValue("");
      this.f.girl_child_married.setValue("");
    }
  }

  RemoveElementFromStringArray(element: string) {
    this.hobby.forEach((value: string, index: any) => {
      if (value == element) this.hobby.splice(index, 1);
    });
  }

  hobbies(event: any) {
    if (this.stepForm.value.hobbies == true) {
      if (event.target.id != "HobbiesOther") {
        this.requiredHobby = false;
        this.hobby.push(event.target.value)
      } else {
        this.checkHobby = true;
      }
    } else {
      this.hobby.forEach((element: any, index: any) => {
        if (element == event.target.value) {
          this.RemoveElementFromStringArray(event.target.value);
        }
      });
    }
  }

  checkBirthCountry() {
    if (this.stepForm.value.country_of_birth == "Other") {
      this.f.country_of_birth.setValue("");
      this.birthCountry = true;
    } else {
      this.birthCountry = false;
    }
  }

  checkHobbies() {
    if (this.stepForm.value.hobies == "Other") {
      this.checkHobby = true;
    } else {
      this.checkHobby = false;
    }
  }

  CheckFood() {
    if (this.stepForm.value.food_preference == "Other") {
      this.f.food_preference.setValue("");
      this.foodPreference = true;
    } else {
      this.foodPreference = false;
    }
  }

  residencyStatusCheck() {
    if (this.stepForm.value.residency_status == "Other") {
      this.f.residency_status.setValue("");
      this.residencyOtherCheck = true;
    } else {
      this.residencyOtherCheck = false;
    }
  }

  step() {
    this.submitted = true;
    if (this.checkHobby) {
      if (this.stepForm.value.hobbiesOther != '') {
        this.hobby.push(this.stepForm.value.hobbiesOther);
      }
    }
    if (this.stepForm.invalid) {
      this.requiredHobby = true;
      this.buttonDisable = false;
      this.scrollToFirstInvalidControl();
      return;
    }

    this.errors = false;
    let fdata = this.stepForm.value;
    let user = {
      user_id: fdata.user_id,
      living_with_family: fdata.living_with_family,
      family_type: fdata.family_type,
      residency_status: fdata.residency_status,
      country_of_birth: fdata.country_of_birth,
      marital_status: fdata.marital_status,
      have_children: fdata.have_children,
      boy_child: fdata.boy_child,
      boy_child_not_married: fdata.boy_child_not_married,
      boy_child_married: fdata.boy_child_married,
      girl_child: fdata.girl_child,
      girl_child_not_married: fdata.girl_child_not_married,
      girl_child_married: fdata.girl_child_married,
      hobbies: this.hobby,
      food_preference: fdata.food_preference.trim(),
      height: fdata.height,
      weight: fdata.weight,
    }

    this.stepservices.registerThree(user).subscribe(
      (result) => {
        this.buttonDisable = false;
        if (result?.status) {
          this.buttonDisable = true;
          this.stepForm.reset();
          this.router.navigate(['stepTwo']);
        }
      },
      (error) => {
        this.errors = error.error;
        this.buttonDisable = false;
      },

    );
  }

  keyPressAlphaNumeric(event) {
    let inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9&,-\s]/.test(inp)) {
      this.requiredHobby = false;
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumeric1(event) {
    const pattern = /\D/g;
    if (!pattern.test(event.target.value)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumeric1(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.stepForm.patchValue({ 'boy_child_not_married': newText });
  }

  keyPressNumeric2(event) {
    console.log(event)
    const pattern = /\D/g;
    if (!pattern.test(event.target.value)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }
  pasteNumeric2(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.stepForm.patchValue({ 'boy_child_married': newText });
  }

  keyPressNumeric3(event) {
    const pattern = /\D/g;
    console.log(pattern)
    if (!pattern.test(event.target.value)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumeric3(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.stepForm.patchValue({ 'girl_child_not_married': newText });
  }

  keyPressNumeric4(event) {
    const pattern = /\D/g;
    console.log(pattern, event)
    if (!pattern.test(event.target.value)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumeric4(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.stepForm.patchValue({ 'girl_child_married': newText });
  }

  //before sonarqube
  // private scrollToFirstInvalidControl() {
  //   let form = document.getElementById('step'); // <-- your formID
  //   let firstInvalidControl = form!.getElementsByClassName('ng-invalid')[0];
  //   console.log(firstInvalidControl);
  //   if (firstInvalidControl) {
  //     firstInvalidControl.scrollIntoView();
  //     (firstInvalidControl as HTMLElement).focus();
  //   }
  // }

  //after sonarqube
  private scrollToFirstInvalidControl() {
    let form = document.querySelector<HTMLElement>('#step');
    if (form) {
      let firstInvalidControl = form.querySelector('.ng-invalid');

      if (firstInvalidControl) {
        (firstInvalidControl as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'center' });
        (firstInvalidControl as HTMLElement).focus();
      }
    }
  }

  // .......content managment code start....
  //before sonarqube
  // NameSpaceNotAllowed(event: any) {
  //   let RegEx = new RegExp(/^([a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"])*$/g);
  //   let str = event.target.value
  //   if (RegEx.test(str)) {
  //     return event.target.value
  //   } else {
  //     var nameString = str.replace(/\s+/g, ' ');
  //     var nameString = str.replace(/[^a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"]+/g, ' ');
  //     event.target.value = nameString.replace(/^\s+/g, "");
  //   }
  // }

  //after sonarqube
  NameSpaceNotAllowed(event: any) {
    let RegEx = new RegExp(/^([a-zA-Z\d!@#$&()\-.+,%*=~[\]{}\\<>?'":])*$/g);
    let str = event.target.value;

    if (RegEx.test(str)) {
      return event.target.value;
    } else {
      let nameString = str.replace(/\s+/g, ' ');
      nameString = nameString.replace(/[^a-zA-Z\d!@#$&()\-.+,%*=\|~[\]{}\\<>\?'";]+/g, ' ');
      event.target.value = nameString.replace(/^\s+/g, "");
    }
  }

  // .......content managment code end....
}
