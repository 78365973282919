import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  /**
   * Constructor
   * @param router The router object
   */
  constructor(
    private router: Router,
    private cookieService: CookieService
  ) { }
  /**
   * Can activate function
   * @param next The activated route snapshot object
   * @param state The router state snapshot object
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // console.log('canActivate:',this.cookieService.getAll())
    if (
      this.cookieService.get('auth_token')
      // localStorage.getItem('auth_token')
    ) { return true; }
    // localStorage.removeItem('auth_token');
    this.cookieService.delete('auth_token');
    this.cookieService.delete('user_id');
    console.log('canActivate:', this.cookieService.getAll())
    this.router.navigateByUrl('/home');
    return false;
  }
}
