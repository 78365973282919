<section class="section mt-93">
    <form #registerNxtNgForm="ngForm" id="registerNxt" [formGroup]="registerNxtForm" (submit)="registerNxt()">
        <div class="row mx-auto mx-width-800 py-5">
            <h1 class="fw-bold text-center display-2">Please Continue</h1>

            <div class="col-12 mt-3">

                <div class="row">
                    <label for="DateOfBirth" class="form-label mb-2 py-1 fw-bold h6">Date
                        of Birth <span class="text-danger"> *</span></label>
                    <div class="col-4">
                        <ng-select [items]="years" bindLabel="name" bindValue="name" placeholder="Select Year"
                            [formControlName]="'year'" [ngClass]="{
                            'is-invalid': submitted && f.year.errors }" (change)="onChangeYear()">
                        </ng-select>
                        <div *ngIf="submitted && f.year.errors">
                            <div class="form-label mb-2 py-1 fw-bold text-danger">Year is required</div>
                        </div>
                    </div>

                    <div class="col-4">
                        <ng-select [items]="monthSel" bindLabel="name" bindValue="id" placeholder="Select Month"
                            [formControlName]="'month'" labelForId="mont" (change)="onChangeMonth()"
                            [ngClass]="{ 'is-invalid': submitted && f.month.errors }">
                        </ng-select>
                        <div *ngIf="submitted && f.month.errors">
                            <div class="form-label mb-2 py-1 fw-bold text-danger">Month is required</div>
                        </div>
                    </div>

                    <div class="col-4">
                        <ng-select [items]="dateSel" bindLabel="name" bindValue="name" placeholder="Select Date"
                            [formControlName]="'day'" [ngClass]="{ 'is-invalid': submitted && f.day.errors }">
                        </ng-select>
                        <div *ngIf="submitted && f.day.errors">
                            <div class="form-label mb-2 py-1 fw-bold text-danger">Date is required</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-3">
                <label for="LivingIn" class="form-label mb-2 py-1 fw-bold h6">
                    Country <span class="text-danger"> *</span></label>
                <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="id"
                    [formControlName]="'living_in_country'" (change)="onChangeCountry($event)" [(ngModel)]="phoneCode"
                    placeholder="Select Country" dropdownPosition="auto" [virtualScroll]="true">
                    <ng-template ng-label-tmp let-item="item">
                        <img height="20" width="20"
                            src="assets/images/flag/Flag_of_{{item.country_name | replaceSpaceWithUnderscore}}.svg"
                            alt="" style="margin-right:5px;" />
                        <b> {{item.country_name}}</b>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <img height="20" width="20"
                            src="assets/images/flag/Flag_of_{{item.country_name | replaceSpaceWithUnderscore}}.svg"
                            alt="" style="margin-right:5px;" />
                        <b> {{item.country_name}}</b>
                    </ng-template>
                </ng-select>
                <div *ngIf="submitted && f.living_in_country.errors">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">Country is required</div>
                </div>

            </div>

            <div class="col-12 col-md-6 mt-3">
                <label for="StateProvince" class="form-label mb-2 py-1 fw-bold h6">
                    State/Province <span class="text-danger"> *</span></label>
                <select class="form-select mb-1 py-2 bg-light" id="Mobile" aria-label="Default select example"
                    [formControlName]="'state_name'" [ngClass]="{ 'is-invalid': submitted && f.state_name.errors }">
                    <option value="" selected disabled hidden>Select State</option>
                    <option value="{{ state.id }}" *ngFor="let state of this.states">
                        {{ state.state_name }}
                    </option>
                </select>
                <div *ngIf="submitted && f.state_name.errors">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">State is required</div>
                </div>
            </div>

            <div class="col-12 col-md-6 mt-3">
                <label for="Currentcity" class="form-label mb-2 py-1 fw-bold h6">
                    Current City <span class="text-danger"> *</span></label>
                <input type="text" class="form-control bg-light" id="Currentcity" placeholder="Enter" maxlength="25"
                    autocomplete="" [formControlName]="'current_city'"
                    [ngClass]="{ 'is-invalid': submitted && f.current_city.errors }"
                    (input)="NameSpaceNotAllowed($event)" (keypress)="omit_special_char($event)" />
                <div *ngIf="submitted && f.current_city.errors">
                    <div *ngIf="f.current_city.errors.required" class="form-label mb-2 py-1 fw-bold text-danger">City is
                        required</div>
                    <div *ngIf="f.current_city.errors.pattern" class="form-label mb-2 py-1 fw-bold text-danger">City
                        name is not valid</div>
                </div>
            </div>

            <div class="col-12 col-md-6 mt-3">
                <label for="PostalCode" class="form-label mb-2 py-1 fw-bold h6">
                    Zip/Pin/Postal Code
                </label>
                <input type="text" class="form-control bg-light" id="PostalCode" placeholder="Enter" maxlength="25"
                    autocomplete="" [formControlName]="'postal_code'" (input)="keyPresspostal($event)"
                    (input)="NameSpaceNotAllowed($event)" />
            </div>

            <div class="col-6 mt-5">
                <a href="javascript:void(0);" routerLink="/register">
                    <button type="button" class="btn w-120 py-2 rounded-3 fs-5 fw-bold
                        btn-danger">
                        Previous
                    </button></a>
            </div>
            <div class="col-6 text-end mt-5">
                <a href="javascript:void(0);">
                    <button type="submit" class="btn w-120 py-2 rounded-3 fs-5 fw-bold
                        btn-danger" [disabled]="buttonDisable">
                        Next
                    </button></a>
            </div>
        </div>
    </form>
</section>

<app-login-footer></app-login-footer>