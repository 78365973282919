import { Component, OnInit, ViewChild/*, ViewEncapsulation */} from '@angular/core';
import { RegisterNxtService } from '../services/register-nxt.service';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup,FormControl,Validators, NgForm } from '@angular/forms';
import { AuthService } from '../shared/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Title/*, Meta*/} from '@angular/platform-browser';
import * as moment from 'moment';
// unused import
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
// import { isThisTypeNode } from 'typescript';


@Component({
  selector: 'app-register-nxt',
  templateUrl: './register-nxt.component.html',
  styleUrls: ['./register-nxt.component.css'],
})
export class RegisterNxtComponent implements OnInit {
  @ViewChild('registerNxtForm') registerNxtNgForm: NgForm;

  errorMessage: string;
  errors: any;
  submitted =false;
   registerNxtForm= new FormGroup({
    user_id :new FormControl(''),
   
    day: new FormControl(null,Validators.required),
    month: new FormControl(null, Validators.required),
    year: new FormControl(null,Validators.required),
    current_city: new FormControl('',[Validators.required,Validators.minLength(3), Validators.maxLength(25), Validators.pattern('^[a-zA-Z ]+$')]),
    postal_code: new FormControl(''),
    state_name: new FormControl('',Validators.required),
    living_in_country: new FormControl('',Validators.required),
   })

  dropdownList: any = [];
  countryName: any = [];
  dropdownSettings = {};
  filterResult1 :any = [];
  dropdownList2: any = [];
  stateName: any = [];
  dropdownSettings2 = {};
// ciuntry state 
  stateInfo: any[] = [];
  countryInfo: any[] = [];
  cityInfo: any;
  selectedCountryIdString: string;
  selectedCountryList: any;
  countries: any;
  states: any;
  jsonObj: any;
  user_logged_in: boolean=false;
  selectedYear: number;
  years: any = [];
  country: any;
  cid: any;
  cntryId: any;
  selectedDate: number; 
  selectedMonth: any;
  phoneCode: any = 38;
  countryFlags: any[];
  ngStateName: any;
  buttonDisable:boolean = false;
  dateSel: { id: number; name: number; }[];
  yearSel: { id: number; name: number; }[];
 
  monthSel = [
    { id: '1', name: "January" },
    { id: '2', name: "February" },
    { id: '3', name: "March" },
    { id: '4', name: "April" },
    { id: '5', name: "May" }, 
    { id: '6', name: "June" },
    { id: '7', name: "July" },
    { id: '8', name: "August" },
    { id: '9', name: "September" },
    { id: '10', name: "October" },
    { id: '11', name: "November" },
    { id: '12', name: "December" },

  ];

  maxYear = moment().year()-18;
  minYear =moment().year()-90;
  name: string;
 
  constructor(
    private registerNxtservice:RegisterNxtService,
    private _formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private cookieService: CookieService,
    private titleService: Title
  ) {
    this.years=[];
    for (let i=this.maxYear;this.minYear<=i;i--){
      this.years.push({ id: i, name: i });
    }
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.titleService.setTitle('Sign-Up | eternalstrings.com');

    this.authService.getCountryList().subscribe(
      (result) => {
          this.countries = result.data;
          this.f.living_in_country.setValue(38);
          let dd = this.cookieService.get('user_id');
          this.f.user_id.setValue(dd);
          let data ={
            user_id:dd
          }
          this.registerNxtservice.getUserDetails(data)
          .subscribe((x:any) => {   
            let detail=x.userDetails;
            // before sonar 
            // if(!!detail.living_in_country) {
              if(detail.living_in_country) {
              this.phoneCode = parseInt(detail.living_in_country);
            }
            this.name=detail.first_name + " "+detail.last_name
            if(detail != 'user data not found') {
              if (detail.register_done<=1){
              this.authService.getStateList({id:this.countries[0].id}).subscribe(
                  (result) => {
                    this.states = result.data;
                  });
              }else{
                this.cntryId= detail.living_in_country;
                this.authService.getStateList({id:this.cntryId}).subscribe(
                  (result) => {
                    this.states = result.data;
                    // before sonar
                    // if(result && result.data && result.data[0] && result.data[0]['country_id']) {
                      if(result?.data[0]['country_id']) {
                      this.country =result.data[0]['country_id']
                      this.f.state_name.setValue(detail.state_name);
                    }
                    let str = detail.dob;
                    let splitted = str.split("-",3);
                    this.f.day.setValue(splitted[2]);
                    this.f.month.setValue(Number(splitted[1]).toString());
                    this.f.year.setValue(splitted[0]);
                    this.f.current_city.setValue(detail.current_city);
                    this.f.postal_code.setValue(detail.postal_code);
                    this.ngStateName = detail.state_name;
                    this.cid=detail.living_in_country;
                  }
                );
              }
            } else {
              this.authService.getStateList({id:this.countries[0].id}).subscribe(
                (result) => {
                  this.states = result.data;
                });
            }
            
          });
      });
    

  }
  
  onChangeMonth() {
    this.dateSel = [];
    this.f.day.setValue('');
    let month_val =this.registerNxtForm.value.month;
    let year_val =this.registerNxtForm.value.year;
    if(month_val != '' && year_val != '') {
      let monthAndYear = year_val+'-'+month_val;
      let noOfDays = moment(monthAndYear, "YYYY-MM").daysInMonth();
      for(let i=1; i<=noOfDays; i++) {
        this.dateSel.push({id: i, name: i});
      }
    }
  }

  onChangeYear() {
    console.log(this.f.day)
    this.dateSel = [];
    this.f.day.setValue('');
    let month_val =this.registerNxtForm.value.month;
    let year_val =this.registerNxtForm.value.year;
    if(month_val != '' && year_val != '') {
      let monthAndYear = year_val+'-'+month_val;
      let noOfDays = moment(monthAndYear, "YYYY-MM").daysInMonth();
      for(let i=1; i<=noOfDays; i++) {
        this.dateSel.push({id: i, name: i});
      }
    }
  }

  getCountryList() {
    this.authService.getCountryList().subscribe(
      (result) => {
        this.countries = result.data;
      },
      (error) => {

      },
      () => {
      }
    );
  }
  
  get f() {
    return this.registerNxtForm.controls;
  }

  registerNxt(){
    this.submitted = true;
    this.buttonDisable = true;
    if (this.registerNxtForm.invalid) {
    this.buttonDisable = false;
    this.scrollToFirstInvalidControl();
    return ;
    }
    const frmdata=this.registerNxtForm.value;
    console.log(frmdata)

    this.errors = false;
      let user ={
        user_id :frmdata.user_id,
        dob : frmdata.year+"-"+frmdata.month+"-"+frmdata.day,
        current_city: frmdata.current_city.trim(),
        postal_code:  frmdata.postal_code?frmdata.postal_code.trim():'',
        // postal_code:  frmdata.postal_code.trim() ,
        // college_name: this.f.college_name.value ? this.f.college_name.value.trim() : '',
        state_name:frmdata.state_name,
        living_in_country: frmdata.living_in_country,
        // living_in_country: this.country,
        // living_in_country:this.registerNxtForm.value.living_in_country,
      }
     this.registerNxtservice.registerTwo(user).subscribe(
      (result) => {
        this.buttonDisable = false;
      if (result?.status){
        this.registerNxtForm.reset();
        this.router.navigate(['step']);
      }
      },
      (error) => {
        this.errors = error.error;
        this.buttonDisable = false;
      },
      
    );
  }
  
  getStateList(data){
    this.states = [];
    this.authService.getStateList(data).subscribe(
      (result) => {
        this.states = result.data;
        // before sonar
        // if(result && result.data && result.data[0] && result.data[0]['country_id']) {
          if(result?.data[0]['country_id']) {
          this.country =result.data[0]['country_id']
        }
       
      }
    );
  }

  onChangeCountry(event:any) {
   const cid=this.registerNxtForm.value.living_in_country;
    let data= {
      id : cid,
    }
  //  console.log(event,cid)
     this.getStateList(data);
     this.f.state_name.setValue('');
  }

  keyPresspostal(event) {
    let inp = String.fromCharCode(event.keyCode);
    if (/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
//before sonarqube
// private scrollToFirstInvalidControl() {
//     let form = document.getElementById('registerNxt'); // <-- your formID
//     let firstInvalidControl = form!.getElementsByClassName('ng-invalid')[0];
//     firstInvalidControl.scrollIntoView();
//     (firstInvalidControl as HTMLElement).focus();
// }

//after sonarqube
private scrollToFirstInvalidControl() {
  let form = document.querySelector<HTMLElement>('#registerNxt');
  if (form) {
    let firstInvalidControl = form.querySelector('.ng-invalid');
    if (firstInvalidControl) {
      (firstInvalidControl as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'center' });
      (firstInvalidControl as HTMLElement).focus();
    }
  }
}

// .......content managment code start....
//before sonarqube
// NameSpaceNotAllowed(event: any) {
//   let RegEx = new RegExp(/^([a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"])*$/g);
//   let str = event.target.value
//   if (RegEx.test(str)) {
//     return event.target.value
//   } else {
//     var nameString = str.replace(/\s+/g, ' ');
//     var nameString = str.replace(/[^a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"]+/g, ' ');
//     event.target.value = nameString.replace(/^\s+/g, "");
//   }
// }

//after sonarqube
NameSpaceNotAllowed(event: any) {
  let RegEx = new RegExp(/^([a-zA-Z\d!@#$&()\-.+,%*=~[\]{}\\<>?'":])*$/g);
  let str = event.target.value;

  if (RegEx.test(str)) {
    return event.target.value;
  } else {
    let nameString = str.replace(/\s+/g, ' ');
    nameString = nameString.replace(/[^a-zA-Z\d!@#$&()\-.+,%*=\|~[\]{}\\<>\?'";]+/g, ' ');
    event.target.value = nameString.replace(/^\s+/g, "");
  }
}

omit_special_char(event) {
  let k;
  k = event.charCode;  //k = event.keyCode; (Both can be used)
  return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
}

// .......content managment code end....
}
