<div class="container py-5 px-3 mt-93">
  <form #partnerPreferenceNgForm="ngForm" id="partnereference" [formGroup]="partnerPreferenceForm" (submit)="submit()">
    <div class="row">
      <div class="col-12 py-2">
        <h1 class="fw-bold text-center display-2">
          Tell us What You are Looking For in a Life Partner
        </h1>
      </div>
    </div>

    <input class type="hidden" id="Self1" [formControlName]="'user_id'" />
    <div class="col-12 mt-4">
      <h6 class="fw-bold">Looking For A <span class="text-danger"> *</span></h6>
      <div class="select-box" [ngClass]="{ 'is-invalid': submitted && f.looking_for.errors }">
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="radio" [formControlName]="'looking_for'" id="Male" value="Male"
            [attr.disabled]="disable_looking_for" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="Male">Male</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="radio" [attr.disabled]="disable_looking_for" id="Female"
            [formControlName]="'looking_for'" value="Female" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="Female">Female</label>
        </div>
        <div *ngIf="submitted && f.looking_for.errors">
          <div class="form-label mb-2 py-1 fw-bold text-danger">
            Looking for is required
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <h6 class="fw-bold">Age <span class="text-danger"> *</span></h6>
      <div class="row">
        <div class="col-5 col-md-3">
          <select class="form-select" aria-label="Default select example" id="age_from" required
            [formControlName]="'age_from'" (change)="onChangeAgeFrom($event)" [ngClass]="{ 'is-invalid': submitted &&
                        f.age_from.errors }">
            <option value selected disabled hidden>Select</option>
            <option value="{{ age.id }}" *ngFor="let age of
                            this.age_from">
              {{ age.id }}
            </option>
          </select>
          <div *ngIf="
                        (submitted && f.age_from.errors) || (submitted &&
                        f.age_to.errors)
                        ">
            <div class="form-label mb-2 py-1 fw-bold text-danger">
              Age is required
            </div>
          </div>
        </div>
        <div class="col-1 my-auto text-center">To</div>
        <div class="col-5 col-md-3">
          <select class="form-select" aria-label="Default select example" id="age_to" required
            [formControlName]="'age_to'" (change)="checkAge()" [ngClass]="{ 'is-invalid': submitted && f.age_to.errors
                        }">
            <option value selected disabled hidden>Select</option>
            <option value="{{ age.id }}" *ngFor="let age of
                            this.age_to">
              {{ age.id }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <h6 class="fw-bold">Height <span class="text-danger"> *</span></h6>
      <div class="row">
        <div class="col-5 col-md-3">
          <select class="form-select" aria-label="Default select example" id="height_from"
            [formControlName]="'height_from'" (change)="onChangeHeightFrom($event)" [ngClass]="{ 'is-invalid': submitted &&
            f.height_from.errors }">
            <option value selected disabled hidden>Select</option>
            <option value="{{ height_from.i }}" *ngFor="let height_from of this.height_from">
              {{ height_from.i }} cm ({{ height_from.i | heightConverter : "Ft" }})
            </option>
          </select>
        </div>
        <div class="col-1 my-auto text-center">To</div>
        <div class="col-5 col-md-3">
          <select class="form-select" aria-label="Default select example" [formControlName]="'height_to'"
            id="height_to1" (change)="checkHeight()" [ngClass]="{ 'is-invalid': submitted &&
                        f.height_to.errors }">
            <option value selected disabled hidden>Select</option>
            <option value="{{ height_to.i }}" *ngFor="let height_to of this.height_to">
              {{ height_to.i }} cm ({{ height_to.i |
              heightConverter : "Ft" }})
            </option>
          </select>
        </div>
        <div *ngIf="submitted && f.height_to.errors">
          <div class="form-label mb-2 py-1 fw-bold text-danger">
            Height is required
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <h6 class="fw-bold">Weight </h6>
      <div class="row">
        <div class="col-5 col-md-3">
          <select class="form-select" aria-label="Default select example" [formControlName]="'weight_from'"
            (change)="onChangeWeightFrom($event)">
            <option value selected disabled hidden>Select</option>
            <option value="{{ weight_from.i }}" *ngFor="let weight_from of this.weight_from">
              {{ weight_from.i}} kg ({{ weight_from.i | weightConverter : "Pounds" }} lb)
            </option>
          </select>
        </div>
        <div class="col-1 my-auto text-center">To</div>
        <div class="col-5 col-md-3">
          <select class="form-select" aria-label="Default select example" [formControlName]="'weight_to'" [ngClass]="{ 'is-invalid': submitted &&
                        f.weight_to.errors }">
            <option value selected disabled hidden>Select</option>
            <option value="{{ weight_to.i }}" *ngFor="let weight_to of this.weight_to">
              {{weight_to.i }} kg ({{ weight_to.i | weightConverter : "Pounds" }} lb)
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mt-3" id="marital_status">
      <div class="col-12 mt-3">
        <h6 class="fw-bold">Living With Family</h6>
        <div class="select-box">
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="hidden" id="Self1" [formControlName]="'user_id'" />
            <input class="form-check-input" type="radio" [formControlName]="'living_with_family'" id="Yes" value="Yes"
              (change)="checkFamilyType()" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Yes">Yes</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" [formControlName]="'living_with_family'" id="No" value="No"
              (change)="checkFamilyType()" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="No">No</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" [formControlName]="'living_with_family'" id="DoesNotMatter"
              value="Does Not Matter" (change)="checkFamilyType()" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="DoesNotMatter">Does Not Matter</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12">
        <div class="select-box" *ngIf="isFamilyType">
          <div class="form-check form-check-inline p-0 me-3 mb-3" ngbTooltip="A kinship group consisting of a family
                        nucleus and various relatives, as grandparents,
                        usually living in one household and functioning as a
                        larger unit">
            <input class="form-check-input " type="checkbox" [formControlName]="'family_type'" id="ExtendedFamily"
              value="Extended Family" (change)="familyTypeCheck($event)" [checked]="true" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="ExtendedFamily" placement="top">Extended Family</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" ngbTooltip="A social unit consisting of people who
                        are very closely related, usually including parents,
                        siblings, spouses, and children, or the members of
                        this social unit.">
            <input class="form-check-input" type="checkbox" [formControlName]="'family_type'" id="ImmediateFamily"
              value="Immediate Family" (change)="familyTypeCheck($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="ImmediateFamily" placement="top">Immediate Family</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" ngbTooltip="A type of extended family composed of
                        parents, their children, and the children's spouses
                        and offspring in one household.">
            <input class="form-check-input" type="checkbox" [formControlName]="'family_type'" id="JointFamily"
              value="Joint Family" (change)="familyTypeCheck($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="JointFamily" placement="top">Joint Family</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" ngbTooltip="A social unit composed of two parents
                        and one or more children.">
            <input class="form-check-input" type="checkbox" [formControlName]="'family_type'" id="NuclearFamily"
              value="Nuclear Family" (change)="familyTypeCheck($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="NuclearFamily" placement="top">Nuclear Family</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3" id="marital_status1">
      <div class="col-12 mt-3">
        <h6 class="fw-bold">
          Marital Status <span class="text-danger"> *</span>
        </h6>
        <div class="select-box" [ngClass]="{ 'is-invalid': submitted &&
                    f.marital_status.errors }">
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'marital_status'"
              (change)="marital_status($event)" id="Annulled" value="Annulled" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Annulled">Annulled</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'marital_status'" id="AwaitingDivorce"
              (change)="marital_status($event)" value="Awaiting Divorce" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="AwaitingDivorce">
              Awaiting Divorce
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'marital_status'" id="Divorced"
              (change)="marital_status($event)" value="Divorced" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Divorced">Divorced</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'marital_status'" id="Single"
              (change)="marital_status($event)" value="Single" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Single">Single</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'marital_status'" id="Widowed"
              (change)="marital_status($event)" value="Widowed" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Widowed">Widowed</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'marital_status'" id="AnyOfThese"
              (change)="marital_status($event)" value="Any Of These" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="AnyOfThese">Any Of These</label>
          </div>
          <div *ngIf="submitted && maritalStatusRequired ">
            <div class="form-label mb-2 py-1 fw-bold text-danger">
              Marital Status is required
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
            <div class="col-12 mt-3" id="religionview">
        <h6 class="fw-bold">Have Children</h6>
        <div class="select-box">
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" [formControlName]="'have_children'" id="ChildrenYes"
              (change)="checkHaveChildren()" value="Yes" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="ChildrenYes">Yes</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" [formControlName]="'have_children'" id="ChildrenNo"
              (change)="checkHaveChildren()" value="No" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="ChildrenNo">
              No
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" [formControlName]="'have_children'" id="ChildrenNotMatter"
              (change)="checkHaveChildren()" value="Does Not Matter" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="ChildrenNotMatter">
              Does Not Matter
            </label>
          </div>
        </div>
      </div>

    </div>
 <div class="row mt-3">
            <div class="col-12 mt-3" id="Preferredlanguage">
        <h6 class="fw-bold">
          Religious Views <span class="text-danger"> *</span>
        </h6>
        <div class="select-box" id="religion" [ngClass]="{ 'is-invalid': submitted && f.religion.errors
                    }">
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" id="Buddhist" value="Buddhist"
              [formControlName]="'religion'" (change)="Religion($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Buddhist">Buddhist</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" id="Christian" value="Christian"
              [formControlName]="'religion'" (change)="Religion($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Christian">Christian</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" id="Hindu" value="Hindu" [formControlName]="'religion'"
              (change)="Religion($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Hindu">Hindu</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" id="Jain" value="Jain" [formControlName]="'religion'"
              (change)="Religion($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Jain">Jain</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" id="Muslim" value="Muslim" [formControlName]="'religion'"
              (change)="Religion($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Muslim">Muslim</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" id="Parsi" value="Parsi" [formControlName]="'religion'"
              (change)="Religion($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Parsi">Parsi</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" id="Sikh" value="Sikh" [formControlName]="'religion'"
              (change)="Religion($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Sikh">Sikh</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" id="NoReligion" value="No Religion"
              [formControlName]="'religion'" (change)="Religion($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="NoReligion">No Religion</label>
          </div>

          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" id="AnyReligion" value="Any Religion"
              [formControlName]="'religion'" (change)="Religion($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="AnyReligion">Any Religion</label>
          </div>
                    <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!checkreligion">
            <input class="form-check-input" type="checkbox" id="Other" value="Other" [formControlName]="'religion'"
              (change)="Religion($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Other">Other Religion</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="checkreligion">
            <input type="text" class="form-control" id="caste" placeholder="Enter religion"
              [formControlName]="'otherReligion'" (input)="NameSpaceNotAllowed($event);checkOtherOption()" minlength="2"
              maxlength="25" />
          </div>

        </div>
        <div *ngIf="submitted && requiredreligion ">
          <div class="form-label mb-2 py-1 fw-bold text-danger">
            Religious Views is required.
          </div>
        </div>
      </div>
    </div>
      <!-- multiselect  -->
    <div class="col-12 mt-3" id="Preferredlanguage1">
      <h6 class="fw-bold">
        Preferred Language<span class="text-danger"> *</span>
      </h6>
      <div class="select-box">
               <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" [formControlName]="'languages'" id="Bengali" value="Bengali"
            (change)="language($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="Bengali">Bengali</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" [formControlName]="'languages'" id="English" value="English"
            (change)="language($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="English">English</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" id="Gujarati" value="Gujarati" [formControlName]="'languages'"
            (change)="language($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="Gujarati">
            Gujarati
          </label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" id="Hindi" value="Hindi" [formControlName]="'languages'"
            (change)="language($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="Hindi">Hindi</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" id="Marathi" value="Marathi" [formControlName]="'languages'"
            (change)="language($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="Marathi">Marathi</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" id="Punjabi" value="Punjabi" [formControlName]="'languages'"
            (change)="language($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="Punjabi">Punjabi</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" id="Tamil" value="Tamil" [formControlName]="'languages'"
            (change)="language($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="Tamil">
            Tamil</label>
        </div>

        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" id="Telugu" value="Telugu" [formControlName]="'languages'"
            (change)="language($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="Telugu">
            Telugu</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" id="AnyLanguage" value="Any Language"
            [formControlName]="'languages'" (change)="language($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="AnyLanguage">
            Any Language</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!checkLanguages">
          <input class="form-check-input" type="checkbox" id="LanguagesOther" value="LanguagesOther"
            [formControlName]="'languages'" (change)="language($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="LanguagesOther">
            Other Language
          </label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="checkLanguages">
          <input type="text" class="form-control" placeholder="Enter language" id="caste"
            [formControlName]="'languagesOther'" autocomplete=""
            (input)="NameSpaceNotAllowed($event);checkOtherOption()" minlength="2" maxlength="25" />
        </div>
        <div *ngIf="submitted && requiredLanguage">
          <div class="form-label mb-2 py-1 fw-bold text-danger">
            Preferred Language is required.
          </div>
        </div>
      </div>
    </div>
    <!-- multiselect  -->
    <div class="col-12 mt-5 py-2">
      <h5 class="mb-0 fw-bold">Location Details</h5>
    </div>

    <div class="row mt-3">
           <div class="col-12 col-md-6 mt-3">
              <h6 class="fw-bold"> Country </h6>
        <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="id" [formControlName]="'country_name'"
          (change)="onChangeCountry($event)" [(ngModel)]="phoneCode" placeholder="Select Country Code"
          dropdownPosition="auto" [virtualScroll]="true">
          <ng-template ng-label-tmp let-item="item">
            <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                            item.country_name | replaceSpaceWithUnderscore
                            }}.svg" alt="" style="margin-right: 5px" />
            <b> {{ item.country_name }}</b>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                            item.country_name | replaceSpaceWithUnderscore
                            }}.svg" alt="" style="margin-right: 5px" />
            <b> {{ item.country_name }}</b>
          </ng-template>
        </ng-select>

      </div>

      <div class="col-12 col-md-6 mt-3">
               <h6 class="fw-bold"> State/Province </h6>
        <select class="form-select mb-1 py-2 bg-light" id="Mobile" aria-label="Default select example"
          [formControlName]="'state_name'">
          <option value selected disabled hidden>Select</option>
          <option value="Any">Any</option>
          <option value="{{ state.id }}" *ngFor="let state of
                        this.states">
            {{ state.state_name }}
          </option>
        </select>

      </div>

      <div class="col-12 col-md-6 mt-3" id="residencystatus">
                <h6 class="fw-bold"> City <span class="fw-normal text-muted"> (Optional) </span></h6>
        <input type="text" class="form-control" id="livingCity" placeholder="Enter" [formControlName]="'city_name'"
          maxlength="25" (paste)="pasteText($event)" (input)="OnlyLetterbetweenSpace($event)" />
      </div>

      <!-- residency status -->
           <div class="col-12 mt-5" id="education">
        <h6 class="fw-bold">
          Residency Status <span class="text-danger"> *</span>
        </h6>
        <div class="select-box" [ngClass]="{ 'is-invalid': submitted &&
                    f.residency_status.errors }">
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'residency_status'" id="Citizen"
              value="Citizen" (change)="r_status($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Citizen">Citizen</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'residency_status'" id="WorkPermit"
              value="Work Permit" (change)="r_status($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="WorkPermit">Work Permit</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'residency_status'"
              id="PermanentResident" value="Permanent Resident" (change)="r_status($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="PermanentResident">Permanent Resident</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'residency_status'" id="StudentVisa"
              value="Student Visa" (change)="r_status($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="StudentVisa">Student Visa</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'residency_status'" id="VisitorVisa"
              value="Visitor Visa" (change)="r_status($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="VisitorVisa">Visitor Visa</label>
          </div>

          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!residencyCheck">
            <input class="form-check-input" type="checkbox" [formControlName]="'residency_status'" id="OtherResidency"
              value="Other" (change)="r_status($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="OtherResidency">Other</label>
          </div>

          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="residencyCheck">
            <input type="text" class="form-control" id placeholder="Enter residency status "
              [formControlName]="'residencyOther'" (input)="NameSpaceNotAllowed($event);checkOtherOption()"
              maxlength="25" />
          </div>

          <div *ngIf="submitted && requiredRStatus">
            <div class="form-label mb-2 py-1 fw-bold text-danger">
              Residency status is required
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-5 py-2">
      <h5 class="mb-0 fw-bold">Education & Profession Details</h5>
    </div>
       <div class="row mt-3" id="worknature">
      <div class="col-12 mt-3">
        <h6 class="fw-bold">Education <span class="text-danger"> *</span></h6>
        <div class="select-box">
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'education'" id="HighSchool"
              value="High School" (change)="CheckEducation($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="HighSchool">
              High School
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'education'" id="SomeCollege"
              value="Some College" (change)="CheckEducation($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="SomeCollege">
              Some College
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'education'" id="SomeUniversity"
              value="Some University" (change)="CheckEducation($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="SomeUniversity">
              Some University
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'education'" id="AssociateDegree"
              value="Associate`s Degree" (change)="CheckEducation($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="AssociateDegree">
              Associate's Degree
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'education'" id="BachelorDegree"
              value="Bachelor`s Degree" (change)="CheckEducation($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="BachelorDegree">
              Bachelor's Degree
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'education'" id="MasterDegree"
              value="Master`s Degree" (change)="CheckEducation($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="MasterDegree">
              Master's Degree
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'education'" id="GraduateDegree"
              value="Graduate Degree" (change)="CheckEducation($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="GraduateDegree">
              Graduate Degree
            </label>
          </div>

          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'education'" id="PostDoctoral"
              value="PhD/Post Doctorate" (change)="CheckEducation($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="PostDoctoral">
              PhD/Post Doctorate
            </label>
          </div>

          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'education'" id="NotMatter"
              value="Does Not Matter" (change)="CheckEducation($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="NotMatter">
              Does Not Matter
            </label>
          </div>

          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!educationCheck">
            <input class="form-check-input" type="checkbox" [formControlName]="'education'" id="OtherEducation"
              value="Other" (change)="CheckEducation($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="OtherEducation">Other</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="educationCheck">
            <input type="text" class="form-control" id="caste" [formControlName]="'educationOther'"
              placeholder="Enter education " maxlength="50" autocomplete=""
              (input)="NameSpaceNotAllowed($event);checkOtherOption()" />
          </div>
                   <div *ngIf=" submitted && educationRequired">
            <div class="form-label mb-2 py-1 fw-bold text-danger">
              Education is required
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3" id="worknature1">
      <div class="col-12 mt-3">
        <h6 class="fw-bold">
          Nature of Work <span class="text-danger"> *</span>
        </h6>
        <div class="select-box">
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'nature_of_work'" id="BusinessSelf"
              value="Business/Self Employed" (change)="work($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="BusinessSelf">
              Business/Self Employed
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'nature_of_work'" id="GovernmentPublic"
              value="Government Company/Public Sector" (change)="work($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="GovernmentPublic">Government/Public Sector
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'nature_of_work'" id="PrivateCompany"
              value="Private Company" (change)="work($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="PrivateCompany">
              Private Company
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'nature_of_work'" id="NotWorking"
              value="Not Working" (change)="work($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="NotWorking">
              Not Working
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'nature_of_work'" id="AnyWork"
              value="Does Not Matter" (change)="work($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm border
                            border-1" for="AnyWork">Does Not Matter</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!workCheck">
            <input class="form-check-input" type="checkbox" [formControlName]="'nature_of_work'" id="OtherWork"
              value="Other" (change)="work($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="OtherWork">Other</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="workCheck">
            <input type="text" class="form-control" id="caste" placeholder="Enter nature of work " maxlength="50"
              [formControlName]="'workOther'" (input)="NameSpaceNotAllowed($event);checkOtherOption()" />
          </div>
          <div *ngIf="submitted && natureOfWorkRequired">
            <div class="form-label mb-2 py-1 fw-bold text-danger">
              Nature of work is required
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 mt-3">
        <h6 class="fw-bold">Profession Area</h6>
        <div class="select-box">
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'profession_area'" id="Accounting"
              value="Accounting - Banking & Finance" (change)="professionArea($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Accounting">
              Accounting - Banking & Finance
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'profession_area'" id="Administration"
              value="Administration & HR" (change)="professionArea($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Administration">Administration & HR
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'profession_area'" id="Airline"
              value="Airline & Aviation" (change)="professionArea($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Airline">
              Airline & Aviation</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'profession_area'" id="Agriculture"
              value="Agriculture" (change)="professionArea($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Agriculture">Agriculture</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'profession_area'" id="Jewellery"
              value="Beauty - Fashion & Jewellery Designers" (change)="professionArea($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="Jewellery">
              Beauty - Fashion & Jewellery Designers
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'profession_area'" id="SalesMarketing"
              value="Sales & Marketing" (change)="professionArea($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="SalesMarketing">
              Sales & Marketing</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'profession_area'" id="MerchantNavy"
              value="Merchant Navy" (change)="professionArea($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="MerchantNavy">
              Merchant Navy</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="checkbox" [formControlName]="'profession_area'" id="AnyProfession"
              value="Does Not Matter" (change)="professionArea($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm border
                            border-1" for="AnyProfession">Does Not Matter</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!professionCheck">
            <input class="form-check-input" type="checkbox" [formControlName]="'profession_area'" id="OtherProfession"
              value="Other" (change)="professionArea($event)" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="OtherProfession">Other</label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="professionCheck">
            <input type="text" class="form-control" id="caste" placeholder="Enter profession area" maxlength="50"
              [formControlName]="'professionOther'" (input)="NameSpaceNotAllowed($event);checkOtherOption()" />
          </div>
          <div *ngIf="professionRequired">
            <div class="form-label mb-2 py-1 fw-bold text-danger">Profession Area is required</div>
          </div>
        </div>
      </div>
         </div>

    <div class="row mt-3" id="food">
      <div class="col-12 mt-3">
        <h6 class="fw-bold">Annual Income <span class="text-danger"> *</span></h6>
        <div class="select-box" [ngClass]="{ 'is-invalid': submitted && f.annual_income.errors }">

          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" [formControlName]="'annual_income'" id="AnnualIncomeAll"
              value="Open To All" (change)="checkIncomeType()" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="AnnualIncomeAll">
              Open to all
            </label>
          </div>
          <div class="form-check form-check-inline p-0 me-3 mb-3">
            <input class="form-check-input" type="radio" [formControlName]="'annual_income'" id="SpecifyRange"
              value="SpecifyRange" (change)="checkIncomeType()" />
            <label class="form-check-label p-2 rounded-3 shadow-sm
                            border border-1" for="SpecifyRange">
              Specify an income range
            </label>
          </div>
          <div *ngIf="submitted && f.annual_income.errors">
            <div class="form-label mb-2 py-1 fw-bold text-danger">Annual income is required</div>
          </div>
        </div>
      </div>

    </div>

    <div class="row mt-2">
      <div class="col-12 col-sm-3 py-2 my-auto">
             </div>
      <div class="col-12">
        <div class="row" *ngIf="incomeType">
          <div class="col-4">
            <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="id"
              [formControlName]="'income_currency'" (change)="onChangeCountryCurrency($event)"
              [(ngModel)]="currencyCode" placeholder="Select Country Code" dropdownPosition="auto"
              [virtualScroll]="true">
              <ng-template ng-label-tmp let-item="item">
                <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                    item.country_name |
                                    replaceSpaceWithUnderscore
                                    }}.svg" alt="" style="margin-right: 5px" />
                {{item.country_name}} <b> {{ item.currency}}</b>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <img height="20" width="20" src="assets/images/flag/Flag_of_{{
                                    item.country_name |
                                    replaceSpaceWithUnderscore
                                    }}.svg" alt="" style="margin-right: 5px" />
                {{item.country_name}} <b> {{ item.currency}}</b>
              </ng-template>
            </ng-select>
            <div *ngIf="submitted && f.income_currency.errors">
              <div class="form-label mb-2 py-1 fw-bold
                                text-danger">
                Annual income currency is required
              </div>
            </div>
          </div>

          <div class="col-3">
            <input type="text" class="form-control" [formControlName]="'income_range_from'" placeholder="Enter"
              id="phone" min="0" required (keyup)="checkIncomeRange()" maxlength="12" (input)="keyPressNumeric6($event)"
              (paste)="pasteNumeric6($event)" [ngClass]="{
                            'is-invalid': submitted &&
                            f.income_range_from.errors
                            }" />
            <div *ngIf="submitted && f.income_range_from.errors">
              <div class="form-label mb-2 py-1 fw-bold
                                text-danger">
                Annual income range from is required
              </div>
            </div>
          </div>
          <div class="col-1 my-auto fw-bold text-center text-muted">TO</div>
          <div class="col-3">
            <input type="text" class="form-control" [formControlName]="'income_range_to'" placeholder="Enter" id="phone"
              min="0" required (keyup)="checkIncomeRange()" maxlength="12" (input)="keyPressNumeric5($event)"
              (paste)="pasteNumeric5($event)" [ngClass]="{
                            'is-invalid': submitted && f.income_range_to.errors
                            }" />
            <div *ngIf="incomeRange">
              <div class="form-label mb-2 py-1 fw-bold
                                text-danger">
                Range is not valid
              </div>
            </div>
            <div *ngIf="submitted && f.income_range_to.errors">
              <div class="form-label mb-2 py-1 fw-bold
                                text-danger">
                Annual income range to is required
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="py-2">
            <input class="checkbox me-2" type="checkbox" [formControlName]="'hide_income'"
              id="flexCheckIndeterminate" />
            <label class for="flexCheckIndeterminate">
              Include Profiles who have not specified their income
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mt-3" id="food1">
      <h6 class="fw-bold">
        Food Preference <span class="text-danger"> *</span>
      </h6>
      <div class="select-box" [ngClass]="{ 'is-invalid': submitted && f.food_preference.errors
                }">
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" [formControlName]="'food_preference'" id="Veg" value="Veg"
            (change)="food_prefernce ($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="Veg">Veg</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" [formControlName]="'food_preference'" id="NonVeg"
            value="Non Veg" (change)="food_prefernce ($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="NonVeg">Non veg</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" [formControlName]="'food_preference'" id="Occasionally"
            value="Occasionally Non-Veg" (change)="food_prefernce ($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="Occasionally">Occasionally Non-Veg</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" [formControlName]="'food_preference'" id="Eggetarian"
            value="Eggetarian" (change)="food_prefernce ($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="Eggetarian">Eggetarian</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" [formControlName]="'food_preference'" id="Pescatarian"
            value="Pescatarian" (change)="food_prefernce ($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="Pescatarian">Pescatarian</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" [formControlName]="'food_preference'" id="JainFood"
            value="Jain" (change)="food_prefernce ($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="JainFood">Jain</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" [formControlName]="'food_preference'" id="Vegan" value="Vegan"
            (change)="food_prefernce ($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="Vegan">Vegan</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3">
          <input class="form-check-input" type="checkbox" [formControlName]="'food_preference'" id="AnyFood"
            value="Does Not Matter" (change)="food_prefernce ($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="AnyFood">Does Not Matter</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!foodCheck">
          <input class="form-check-input" type="checkbox" [formControlName]="'food_preference'" id="OtherFood"
            value="Other" (change)="food_prefernce ($event)" />
          <label class="form-check-label p-2 rounded-3 shadow-sm border
                        border-1" for="OtherFood">Other</label>
        </div>
        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="foodCheck">
          <input type="text" class="form-control" id="caste" placeholder="Enter food preference"
            [formControlName]="'otherFood'" maxlength="25" (input)="NameSpaceNotAllowed($event);checkOtherOption()" />
        </div>
        <div *ngIf="submitted && foodRequired">
          <div class="form-label mb-2 py-1 fw-bold text-danger">
            Food Preference is required
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mt-3">
      <h6 class="fw-bold">
        Partner Expectations <span class="text-danger"> *</span>
      </h6>
           <textarea class="w-100 rounded border p-2 border-2" id="wordCount" #text
        class="w-100 rounded border p-2 border-2 w-100" rows="10" placeholder="
                &#x2022; Be specific and clear about what you are looking for in a partner.
                &#x2022; Consider what values and lifestyle are important to you in a relationship.
                &#x2022; Mention interests and activities you would like to share with a partner.
                &#x2022; Express the importance of trust and honesty in a relationship.
                &#x2022; Remember to be open-minded and flexible, as everyone has different qualities and characteristics that make them unique and
                special." maxlength="1000" [formControlName]="'description'"
        [ngClass]="{ 'is-invalid': submitted && f.description.errors }" (paste)="pasteNumeric8($event)"
        (keydown)="wordCounter($event)">
            </textarea>
      <div class="form-label mb-2 py-1 fw-bold text-danger" *ngIf="exceedLimit"> You cannot enter more than the
        maximum of 100 words</div>
           <p class="text-muted p-xsm">Max 100 Words.</p>
      <p class="text-muted p-xsm">Entered- {{words}} Words</p>

      <div *ngIf="submitted && f.description.errors">
        <div class="form-label mb-2 py-1 fw-bold text-danger">
          Partner Expectations is required
        </div>
      </div>
    </div>

    <div class="row mt-5 mb-4 text-center">
      <div class="col-12">
        <a href="javascript:void(0)" class>
          <button type="submit" class="btn btn-danger fs-5" [disabled]="buttonDisable"> Save & Continue
          </button>
        </a>
      </div>
    </div>
  </form>
</div>

<div class="w-100 bg-maroon">
  <app-login-footer></app-login-footer>
</div>