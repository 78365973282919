import { Component, OnInit, ViewChild/*, ViewEncapsulation*/, ElementRef } from '@angular/core';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Title/*, Meta */ } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm, } from '@angular/forms';
import { AuthService } from '../shared/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ViewportScroller } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { PartnerPreferenceService } from '../services/partner-preference.service';
import { Observable/*, of, from*/ } from 'rxjs';
import { NotifService } from '../services/notif.service';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { EncryptionService } from '../services/encryption.service';
import { environment } from 'src/environments/environment';
import { CommonService } from '../services/common.service';
import * as $ from 'jquery';
// unused import
// import { Subject } from 'rxjs';
// import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MyProfileService } from '../services/my-profile.service';

@Component({
  selector: 'app-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.css'],
})
export class MatchesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  value: number = 18;
  highValue: number = 90;
  options: Options = {
    floor: 18,
    ceil: 90,
  };

  filterName: ""
  minValue: number = 0;
  maxValue: number = 230;
  options2: Options = {
    floor: 100,
    ceil: 230,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return +value + ' cm';
        case LabelType.High:
          return +value + ' cm';
        default:
          return '';
      }
    },
  };


  minValue3: number = 32;
  maxValue3: number = 114;
  options3: Options = {
    floor: 32,
    ceil: 114,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return +value + ' kg';
        case LabelType.High:
          return +value + ' kg';
        default:
          return '';
      }
    },
  };


  minValue4: number = 0;
  maxValue4: number = 100000000000;
  options4: Options = {
    floor: 0,
    ceil: 100000000000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "$" + value;
        case LabelType.High:
          return "$" + value;
        default:
          return '';
      }
    }
  };

  matchesForm = new FormGroup({
    //    user_id :new FormControl(''),
    looking_for: new FormControl(''),
    age_from: new FormControl(''),
    age_to: new FormControl(''),
    height_from: new FormControl(''),
    height_to: new FormControl(''),
    weight_from: new FormControl(''),
    weight_to: new FormControl(''),
    living_with_family: new FormControl(''),
    family_type: new FormControl(''),
    marital_status: new FormControl(''),
    have_children: new FormControl(''),
    religion: new FormControl(''),
    languages: new FormControl(''),
    country: new FormControl(''),
    allCountry: new FormControl(''),
    other_country: new FormControl(''),
    residency_status: new FormControl(''),
    education: new FormControl(''),
    profession_area: new FormControl(''),
    nature_of_work: new FormControl(''),
    food_preference: new FormControl(''),
    income_range_from: new FormControl(''),
    income_range_to: new FormControl(''),
    income_currency: new FormControl(''),

  })
  inboxLoader: boolean = true;
  inboxLoader1: boolean = true;
  age_from: any = [];
  age_to: any = [];
  income_from: any = [];
  income_to: any = [];
  weight_from: any = [];
  weight_to: any = [];
  height_from: any = [];
  height_to: any = [];
  rangeAge: number[] = [18, 90];
  rangeHeight: number[] = [0, 2000];
  rangeWeight: number[] = [0, 2000];
  livingWithFamily: string | any;
  familyType: any = [];
  maritalStatus: any = [];
  religion: any = [];
  user_id: string;
  matches: any = [];
  toggleMatch: any = [];
  language: any = [];
  residency: any[];
  education: any = [];
  profession: any = [];
  work: any[];
  food: any[];
  showFamilyType: boolean = false;
  detail: any;
  selectId: any;
  selectedcountry: any = []
  otherCountrySelected: any = []
  isOtherCountrySelected: boolean = false;
  isAllCountrySelected: boolean = false
  allCountrySelected: any = [];
  showStates: boolean = false;
  states: any = [];
  selectedStates: any = [];
  sort_by = "";
  sortBy: any = '';
  current_sort: string = 'default';
  currencyCode: any;
  countries: any;

  paginateData: any[] = [];
  source$: Observable<any>;
  itemPerPage: number = 8;
  pageNo: number = 1;
  totalPages: number;
  isAllFamilyType: boolean = false;
  isAllMaritalStatus: boolean = false;
  isAllReligion: boolean = false;
  isAllLanguage: boolean = false;
  isAllResidencyStatus: boolean = false;
  isAllEducation: boolean = false;
  isAllNatureOfWork: boolean = false;
  isAllProfession: boolean = false;
  isAllFoodPreference: boolean = false;
  // is other true ;
  isOtherMaritalStatus: boolean = false;
  isOtherReligion: boolean = false;
  isOtherLanguage: boolean = false;
  isOtherResidencyStatus: boolean = false;
  isOtherEducation: boolean = false;
  isOtherNatureOfWork: boolean = false;
  isOtherProfession: boolean = false;
  isOtherFoodPreference: boolean = false;
  AnualIncomeHide: boolean = false;
  toggle: boolean = false;
  optionsMsg: { clickToClose: boolean; showProgressBar: boolean; theClass: string; timeOut: number; preventDuplicates: boolean; };

  matchedProfilesCount: number = 0;
  shortlistedProfilesCount: number = 0;
  favouriteProfilesCount: number = 0;
  viewedByProfilesCount: number = 0;
  showNextPage: boolean = true;
  showPagination: boolean;
  TotalRecord: any;
  currentPage: number = 1;
  currentPage1: number = 1;

  incomeRange: boolean;
  annualIncome: string = "";
  advanceFilter: boolean = false;
  ageFromModel: any = 18;
  ageToModel: any = 90;
  prePage: boolean;
  hidePageSize = true;
  renderPagination: boolean = false;
  lastPage: number = 1;
  encrypted_id: string | undefined;
  showIcon: number;
  EliteProfile: boolean = false;
  displayName: any;
  photoSrc: any = "assets/images/men-avtar-2.svg";
  profile_id: any;
  matchesLoader: boolean = true;

  otherReason: boolean = false;
  reportReason: string = ""
  reportReasonsList: any = [
    { id: 1, label: "Harassment or Abuse" },
    { id: 2, label: "Unwanted Attention" },
    { id: 3, label: "Spam" },
    { id: 4, label: "Scam or Fraud" },
    { id: 5, label: "Disrespectful Behavior" },
    { id: 6, label: "Privacy Concerns" },
    { id: 7, label: "Fake or Suspicious Profiles" },
    { id: 8, label: "Abusive or Threatening Behavior" },
    { id: 9, label: "Explicit Content" },
    { id: 10, label: "Violation of Platform Rules" },
    { id: 11, label: "Other" }

  ];
  profileId: any;
  @ViewChild('wordCountForm') wordCountNgForm: NgForm;
  @ViewChild("texts") texts: ElementRef;
  wordCountForm = new FormGroup({
    user_id: new FormControl(''),
    description: new FormControl('', [Validators.required]),
  })
  wordCount: any;
  words: any;
  tellUsMore: any;
  exceedLimit: boolean;
  filtersSaved: any = {}
  constructor(

    private titleService: Title,
    private _formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private cookieService: CookieService,
    private scroller: ViewportScroller,
    private partnerPreferenceService: PartnerPreferenceService,
    private myProfileService: MyProfileService,
    private spinner: NgxSpinnerService,
    private notifService: NotifService,
    private toastrService: ToastrService,
    private encrypt: EncryptionService,
    private commonService: CommonService,
    private activeRoute: ActivatedRoute,

  ) {

    this.activeRoute.queryParams
      .subscribe(params => {
        if (params && params.status == 0 && params.msg) {
          this.toastrService.error(params.msg);
          // unused code
          // if (localStorage.getItem('currentUrl')) {
          //   this.router.navigate([localStorage.getItem('currentUrl')]);
          // }

        } else if (params && params.status == 1 && params.msg) {
          this.toastrService.success(params.msg);
          // unused code
          // if (localStorage.getItem('currentUrl')) {
          //   this.router.navigate([localStorage.getItem('currentUrl')]);
          // }
        }
      });
    this.optionsMsg = {
      clickToClose: true,
      showProgressBar: true,
      theClass: 'er-notification',
      timeOut: 0,
      preventDuplicates: true
    };
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
      // console.log("loader")
    }, 3000);

    let userAgent = navigator.userAgent;
    if (userAgent.includes("Firefox")) {
      // console.log("firefox")
      setTimeout(() => {
        window.scrollTo(15, 15)
        // console.log("scroll")
      }, 500);
    }

    this.titleService.setTitle('My Matches | eternalstrings.com');
    this.user_id = this.cookieService.get('user_id');
    // unused code
    //this.f.user_id.setValue(this.user_id);
    // if (dataFilter) {
    // } else {
    // this.advanceFilter = true;
    let fm = this.commonService.getLookingFor();
    // console.log('fm::::::', fm)
    if (fm != '') {
      this.advanceFilter = false;
      this.f.looking_for.setValue(fm)
      this.f.age_from.setValue(18);
      this.f.age_to.setValue(90);
      this.f.height_from.setValue(100);
      this.f.height_to.setValue(230);
      this.f.weight_from.setValue(32);
      this.f.weight_to.setValue(114);
      $('#flexSwitchCheckChecked').click();
      this.toggle = true;
      this.ApplyFilters();
      // }
    }

    this.countryList();
    this.rangeSet()
    this.getPartnerDetail();
    this.getStatus();
    let packId = 2
    if (packId == 2) {
      this.EliteProfile = true;
    } else {
      this.EliteProfile = false;
    }
    this.otherCountrySelected = [38, 231, 13, 44, 101, 75, 82]
    this.states = [{ id: 1, state_name: 'Any', country_id: 0 },
    { id: 2, state_name: 'Alberta', country_id: 1 }]

    setTimeout(() => {
      let val = this.commonService.getFilterData()
      if (val != false) {
        // unused code
        // $('#saveFilter1').click(); 
      }
    }, 3000);
  }

  lastFilterApply() {
    let dataFilter = localStorage.getItem("savedfilter")
    let val = this.commonService.getFilterData();
    let dd = JSON.parse(dataFilter)
    // console.log(dd, val)
    this.searchPartner(val);
    this.setValue(val)
  }

  setValue(val) {
    if (val) {
      this.detail = val
      this.f.looking_for.setValue(this.detail.looking_for)
      this.f.age_from.setValue(this.detail.age_from);
      this.f.age_to.setValue(this.detail.age_to);
      this.f.height_from.setValue(this.detail.height_from);
      this.f.height_to.setValue(this.detail.height_to);
      if (this.detail.weight_from) {
        this.f.weight_from.setValue(this.detail.weight_from);
      } else {
        this.f.weight_from.setValue(32);
      }
      if (this.detail.weight_to) {
        this.f.weight_to.setValue(this.detail.weight_to);
      } else {
        this.f.weight_to.setValue(114);
      }
      this.value = this.detail.age_from;
      this.highValue = this.detail.age_to;
      this.minValue = this.detail.height_from;
      this.maxValue = this.detail.height_to;
      this.minValue3 = this.detail.weight_from;
      this.maxValue3 = this.detail.weight_to;
      this.f.income_range_from.setValue(this.detail.income_range_from);
      this.f.income_range_to.setValue(this.detail.income_range_to);

      this.minValue4 = this.detail.income_range_from;
      this.maxValue4 = this.detail.income_range_to;

      this.annualIncome = this.detail.annual_income
      this.f.income_currency.setValue(this.detail.income_currency);
      if (this.detail.income_currency && this.detail.income_currency != "other") {
        //  document why this block is empty
      }
      if (this.detail.annual_income == 'SpecifyRange') {
        this.currencyCode = parseInt(this.detail.income_currency)
      } else if (this.detail.annual_income == 'Open To All' || this.detail.annual_income == 'OpenToAll') {
        // unused code
        // this.currencyCode = 0;
      }
      if (this.detail.living_with_family != "") {
        if (this.detail.living_with_family == "Yes") {
          this.f.living_with_family.setValue("Yes")
          this.showFamilyType = true;
          // set family type 
          if (this.detail?.family_type) {
            this.familyType = [];
            this.detail.family_type.forEach((element: any) => {
              if (element != '') {
                setTimeout(() => {

                  if (element == "Extended Family") {
                    this.familyType.push("Extended Family");
                    $('#familyTypeSidebar2').prop('checked', true);
                  } else if (element == "Immediate Family") {
                    this.familyType.push("Immediate Family");
                    $('#familyTypeSidebar3').prop('checked', true);
                  } else if (element == "Joint Family") {
                    this.familyType.push("Joint Family");
                    $('#familyTypeSidebar4').prop('checked', true);
                  } else if (element == "Nuclear Family") {
                    this.familyType.push(element);
                    $('#familyTypeSidebar5').prop('checked', true);
                  } else if (element == "Doesn't Matter") {
                    this.familyType = [];
                    this.isAllFamilyType = true;
                    this.f.family_type.setValue(true);
                    this.familyType = ['Extended Family', 'Immediate Family', 'Joint Family', 'Nuclear Family'];
                    $('#familyTypeSidebar2').prop('checked', true);
                    $('#familyTypeSidebar3').prop('checked', true);
                    $('#familyTypeSidebar4').prop('checked', true);
                    $('#familyTypeSidebar5').prop('checked', true);

                  } else {
                    //  // console.log(this.familyType,"esle condition");
                  }
                  if (this.familyType.length >= 4) {
                    this.isAllFamilyType = true;
                    this.f.family_type.setValue(true);
                    $('#familyTypeSidebar1').prop('checked', true)
                  }
                }, 50);
              }
            });
          }
          // set family type
        } else if (this.detail.living_with_family == "No") {
          this.f.living_with_family.setValue("No")
          this.showFamilyType = false;
          $('#familyTypeSidebar2').prop('checked', true)

        } else {
          this.f.living_with_family.setValue("")
          this.showFamilyType = false;
          $('#familyTypeSidebar3').prop('checked', true)
        }
      }

      // residency status set 
      if (this.detail?.residency_status && this.detail.residency_status?.length > 0) {
        this.residency = [];
        this.detail.residency_status.forEach((element: any) => {
          // console.log(element);
          if (element != '') {
            if (element == "Citizen") {
              this.residency.push("Citizen");
              $('#SidebarResidencyFilter2').prop('checked', true);
            } else if (element == "Work Permit") {
              this.residency.push("Work Permit");
              $('#SidebarResidencyFilter3').prop('checked', true);
            } else if (element == "Permanent Resident") {
              this.residency.push("Permanent Resident");
              $('#SidebarResidencyFilter4').prop('checked', true);
            } else if (element == "Student Visa") {
              this.residency.push("Student Visa");
              $('#SidebarResidencyFilter5').prop('checked', true);
            } else if (element == "Visitor Visa") {
              this.residency.push("Visitor Visa");
              $('#SidebarResidencyFilter6').prop('checked', true);
            } else {
              $('#SidebarResidencyFilter7').prop('checked', true);
              this.residency.push("Other");
              this.isOtherResidencyStatus = true;
            }
          }
          // residency status 
          const resArr = this.residency.reduce((uniqArr, item) => {
            return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
          }, []);
          if (resArr.length >= 6) {

            this.residency = resArr;
            this.isAllResidencyStatus = true;
            this.f.residency_status.setValue(true)
            $('#SidebarResidencyFilter1').prop('checked', true);
          }
        });
        // console.log(this.residency,this.detail.residency_status)
      }

      // religion set 
      if (this.detail?.religion && this.detail.religion.length > 0) {
        this.religion = [];
        this.detail.religion.forEach((element: any) => {

          if (element != '') {
            if (element == "Buddhist") {
              this.religion.push("Buddhist");
              $('#Religion1').prop('checked', true);
            } else if (element == "Christian") {
              this.religion.push("Christian");
              $('#Religion2').prop('checked', true);
            } else if (element == "Hindu") {
              this.religion.push("Hindu");
              $('#Religion3').prop('checked', true);
            } else if (element == "Parsi") {
              this.religion.push("Parsi");
              $('#Religion9').prop('checked', true);
            } else if (element == "Jain") {
              this.religion.push("Jain");
              $('#Religion4').prop('checked', true);
            } else if (element == "Muslim") {
              this.religion.push("Muslim");
              $('#Religion5').prop('checked', true);
            } else if (element == "Sikh") {
              this.religion.push("Sikh");
              $('#Religion6').prop('checked', true);
            } else if (element == "No Religion") {
              this.religion.push("No Religion");
              $('#Religion7').prop('checked', true);
            } else if (element == "Any Religion") {
              this.religion = ['Buddhist', 'Christian', 'Hindu', 'Parsi', 'Jain', 'Muslim', 'No Religion', 'Other', 'Sikh'];
              $('#Religion1').prop('checked', true);
              $('#Religion2').prop('checked', true);
              $('#Religion3').prop('checked', true);
              $('#Religion4').prop('checked', true);
              $('#Religion5').prop('checked', true);
              $('#Religion6').prop('checked', true);
              $('#Religion7').prop('checked', true);
              $('#Religion8').prop('checked', true);
              $('#Religion9').prop('checked', true);
              this.isOtherReligion = true;
            } else {
              this.religion.push("Other");
              $('#Religion8').prop('checked', true);
              this.isOtherReligion = true;
            }
          }
          // languages 
          const relArr = this.religion.reduce((uniqArr, item) => {
            return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
          }, []);
          if (relArr.length >= 9) {
            this.religion = relArr;
            this.isAllReligion = true;
            this.f.religion.setValue(true);
            $('#Religion').prop('checked', true);
          }
        });
      }

      if (this.detail?.languages && this.detail.languages.length > 0) {
        this.language = [];
        this.detail.languages.forEach((element: any) => {
          if (element != '') {
            if (element == "Bengali") {
              this.language.push("Bengali");
              $('#LanguagePreference1').prop('checked', true);
            } else if (element == "English") {
              this.language.push("English");
              $('#LanguagePreference2').prop('checked', true);
            } else if (element == "Gujarati") {
              this.language.push("Gujarati");
              $('#LanguagePreference3').prop('checked', true);
            } else if (element == "Hindi") {
              this.language.push("Hindi");
              $('#LanguagePreference4').prop('checked', true);
            } else if (element == "Marathi") {
              this.language.push("Marathi");
              $('#LanguagePreference5').prop('checked', true);
            } else if (element == "Punjabi") {
              this.language.push("Punjabi");
              $('#LanguagePreference6').prop('checked', true);
            } else if (element == "Telugu") {
              this.language.push("Telugu");
              $('#LanguagePreference9').prop('checked', true);
            } else if (element == "Tamil") {
              this.language.push("Tamil");
              $('#LanguagePreference7').prop('checked', true);
            } else if (element == "Any Language") {

              this.language = ['Bengali', 'English', 'Gujarati', 'Hindi', 'Marathi', 'Punjabi', 'Other', 'Tamil', 'Telugu'];
              $('#LanguagePreference1').prop('checked', true);
              $('#LanguagePreference2').prop('checked', true);
              $('#LanguagePreference3').prop('checked', true);
              $('#LanguagePreference4').prop('checked', true);
              $('#LanguagePreference5').prop('checked', true);
              $('#LanguagePreference6').prop('checked', true);
              $('#LanguagePreference7').prop('checked', true);
              $('#LanguagePreference8').prop('checked', true);
              $('#LanguagePreference9').prop('checked', true);
              $('#LanguagePreference8').prop('checked', true);
              this.isOtherLanguage = true;

            } else {
              $('#LanguagePreference8').prop('checked', true);
              this.language.push("Other");
              this.isOtherLanguage = true;
            }
          }
          const langArr = this.language.reduce((uniqArr, item) => {
            return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
          }, []);
          this.language = langArr;
          if (langArr.length >= 9) {

            this.isAllLanguage = true
            this.f.languages.setValue(true)
            $('#LanguagePreference').prop('checked', true);
          }
        });
      }

      if (this.detail?.food_preference && this.detail.food_preference.length > 0) {
        this.food = [];
        this.detail.food_preference.forEach((element: any) => {
          // console.log(element);
          if (element != '') {
            if (element == "Veg") {
              this.food.push(element);
              $('#FoodPreference1').prop('checked', true);
            } else if (element == "Non Veg") {
              this.food.push(element);
              $('#FoodPreference2').prop('checked', true);
            } else if (element == "Occasionally Non-Veg") {
              this.food.push(element);
              $('#FoodPreference4').prop('checked', true);
            } else if (element == "Eggetarian") {
              this.food.push(element);
              $('#FoodPreference3').prop('checked', true);
            } else if (element == "Pescatarian") {
              this.food.push(element);
              $('#FoodPreference8').prop('checked', true);
            } else if (element == "Jain") {
              this.food.push(element);
              $('#FoodPreference5').prop('checked', true);
            } else if (element == "Vegan") {
              this.food.push(element);
              $('#FoodPreference6').prop('checked', true);
            } else if (element == "Does Not Matter") {
              this.food = [];
              this.isAllFoodPreference = true;
              this.f.food_preference.setValue(true)
              this.food = ['Veg', 'Occasionally Non-Veg', 'Non-Veg', 'Eggetarian', 'Pescatarian', 'Jain', 'Other', 'Vegan'];
              $('#FoodPreference1').prop('checked', true);
              $('#FoodPreference2').prop('checked', true);
              $('#FoodPreference3').prop('checked', true);
              $('#FoodPreference4').prop('checked', true);
              $('#FoodPreference5').prop('checked', true);
              $('#FoodPreference6').prop('checked', true);
              $('#FoodPreference7').prop('checked', true);
              $('#FoodPreference8').prop('checked', true);
              this.isOtherFoodPreference = true
            } else {
              this.food.push("Other");
              $('#FoodPreference7').prop('checked', true);
              this.isOtherFoodPreference = true
            }
          }
          const foodArr = this.food.reduce((uniqArr, item) => {
            return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
          }, []);
          this.food = foodArr;
          if (foodArr.length >= 8) {
            this.isAllFoodPreference = true;
            this.f.food_preference.setValue(true)
            $('#FoodPreference').prop('checked', true);
          }
        });
      }

      if (this.detail?.marital_status && this.detail.marital_status.length > 0) {
        this.maritalStatus = [];
        this.detail.marital_status.forEach((element: any) => {
          if (element != '') {
            if (element == "Annulled") {
              this.maritalStatus.push("Annulled");
              $('#MaritalStatus2').prop('checked', true);
            } else if (element == "Awaiting Divorce") {
              this.maritalStatus.push("Awaiting Divorce");
              $('#MaritalStatus3').prop('checked', true);
            } else if (element == "Divorced") {
              this.maritalStatus.push("Divorced");
              $('#MaritalStatus4').prop('checked', true);
            } else if (element == "Single") {
              this.maritalStatus.push("Single");
              $('#MaritalStatus5').prop('checked', true);
            } else if (element == "Widowed") {
              this.maritalStatus.push("Widowed");
              $('#MaritalStatus6').prop('checked', true);
            } else if (element == "Any Of These") {
              this.maritalStatus = ['Annulled', 'Awaiting Divorce', 'Divorced', 'Single', 'Widowed'];
              $('#MaritalStatus2').prop('checked', true);
              $('#MaritalStatus3').prop('checked', true);
              $('#MaritalStatus4').prop('checked', true);
              $('#MaritalStatus5').prop('checked', true);
              $('#MaritalStatus6').prop('checked', true);
            }
          }
          if (this.maritalStatus.length >= 5) {
            this.isAllMaritalStatus = true;
            this.f.marital_status.setValue(true);
            $('#MaritalStatus1').prop('checked', true)
          }
        });

      }

      if (this.detail?.education && this.detail.education.length > 0) {
        this.education = [];
        this.detail.education.forEach((element: any) => {
          // console.log(element);
          if (element != '') {
            if (element == "High School") {
              this.education.push("High School");
              $('#Qualification1').prop('checked', true);
            } else if (element == "Some College") {
              this.education.push("Some College");
              $('#Qualification2').prop('checked', true);
            } else if (element == "Some University") {
              this.education.push("Some University");
              $('#Qualification3').prop('checked', true);
            } else if (element == "Associate Degree" || element == "Associate`s Degree") {
              this.education.push("Associate`s Degree");
              $('#Qualification4').prop('checked', true);
            } else if (element == "Bachelor Degree" || element == "Bachelor`s Degree") {
              this.education.push("Bachelor`s Degree");
              $('#Qualification5').prop('checked', true);
            } else if (element == "Master Degree" || element == "Master`s Degree") {
              this.education.push("Master`s Degree");
              $('#Qualification6').prop('checked', true);
            } else if (element == "Graduate Degree") {
              this.education.push("Graduate Degree");
              $('#Qualification7').prop('checked', true);
            } else if (element == "Post Doctoral" || element == "PhD/Post Doctorate" || element == "PhD/Post Doctorate") {
              this.education.push("PhD/Post Doctorate");
              $('#Qualification8').prop('checked', true);
            } else if (element == "Does Not Matter") {
              this.education = ['Master`s Degree', 'PhD/Post Doctorate', 'Graduate Degree', 'Bachelor`s Degree', 'Associate`s Degree', 'Some University', 'Other', 'Some College', 'High School'];
              $('#Qualification1').prop('checked', true);
              $('#Qualification2').prop('checked', true);
              $('#Qualification3').prop('checked', true);
              $('#Qualification4').prop('checked', true);
              $('#Qualification5').prop('checked', true);
              $('#Qualification6').prop('checked', true);
              $('#Qualification7').prop('checked', true);
              $('#Qualification8').prop('checked', true);
              $('#Qualification9').prop('checked', true);
              this.isOtherEducation = true;
            } else {
              $('#Qualification9').prop('checked', true);
              this.education.push("Other");
              this.isOtherEducation = true;
            }
          }
          const eduArr = this.education.reduce((uniqArr, item) => {
            return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
          }, []);
          this.education = eduArr
          if (eduArr.length >= 9) {
            this.isAllEducation = true;
            this.f.education.setValue(true)
            $('#Qualification').prop('checked', true);
          }
        });
      }

      if (this.detail?.nature_of_work) {
        this.work = [];
        this.detail.nature_of_work.forEach((element: any) => {
          // console.log(element);
          if (element != '') {
            if (element == "Does Not Matter") {
              this.work = [];
              this.isOtherNatureOfWork = false;
              this.isAllNatureOfWork = false;
              return;
            } else if (element == "Business / Self Employed" || element == "Business/Self Employed") {
              this.work.push("Business");
              this.work.push("Self Employed");
              $('#natureSidebarBtn7').prop('checked', true);
              $('#natureSidebarBtn2').prop('checked', true);
            } else if (element == "Government Company / Public Sector" || element == "Government Company" || element == "Government Company/Public Sector") {
              this.work.push("Government Company");
              $('#natureSidebarBtn3').prop('checked', true);
            } else if (element == "Private Company" || element == "Government Company/Public Sector") {
              this.work.push("Private Company");
              $('#natureSidebarBtn4').prop('checked', true);
            } else if (element == "Not Working") {
              this.work.push("Not Working");
              $('#natureSidebarBtn5').prop('checked', true);
            } else if (element == "Self Employed") {
              this.work.push("Self Employed");
              $('#natureSidebarBtn7').prop('checked', true);
            } else if (element == "Does Not Matter") {
              this.isAllNatureOfWork = true;
              this.isOtherNatureOfWork = true;
              this.isOtherNatureOfWork = true;
              this.f.nature_of_work.setValue(true)
              this.work = ['Business', 'Government Company', 'Private Company', 'Self Employed', 'Not Working', 'Other'];
              $('#natureSidebarBtn1').prop('checked', true);
              $('#natureSidebarBtn2').prop('checked', true);
              $('#natureSidebarBtn3').prop('checked', true);
              $('#natureSidebarBtn4').prop('checked', true);
              $('#natureSidebarBtn5').prop('checked', true);
              $('#natureSidebarBtn6').prop('checked', true);
              $('#natureSidebarBtn7').prop('checked', true);
            } else {
              $('#natureSidebarBtn6').prop('checked', true);
              this.isOtherNatureOfWork = true;
              this.work.push("Other");
            }
          }
        });
        const worArr = this.work.reduce((uniqArr, item) => {
          return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
        }, []);
        this.work = worArr
        if (worArr.length >= 6) {
          this.isAllNatureOfWork = true;
          this.f.nature_of_work.setValue(true)
          $('#natureSidebarBtn6').prop('checked', true);
        }
        // console.log(this.work,"sagar//")

      }


      if (this.detail?.profession_area && this.detail.profession_area.length > 0) {
        this.profession = [];
        this.detail.profession_area.forEach((element: any) => {
          // console.log(element);
          if (element != '') {
            if (element == "Accounting - Banking & Finance" || element == "Accounting, Banking & Finance") {
              this.profession.push("Accounting - Banking & Financeli");
              $('#ProfessionArea1').prop('checked', true);
            } else if (element == "Administration & HR") {
              this.profession.push("Administration & HR");
              $('#ProfessionArea2').prop('checked', true);
            } else if (element == "Airline & Aviation") {
              this.profession.push("Airline & Aviation");
              $('#ProfessionArea3').prop('checked', true);
            } else if (element == "Agriculture") {
              this.profession.push("Agriculture");
              $('#ProfessionArea4').prop('checked', true);
            } else if (element == "Beauty - Fashion & Jewellery Designers") {
              this.profession.push("Beauty - Fashion & Jewellery Designers");
              $('#ProfessionArea5').prop('checked', true);
            } else if (element == "Sales & Marketing" || element == "Sales Marketing") {
              this.profession.push("Sales & Marketing");
              $('#ProfessionArea6').prop('checked', true);
            } else if (element == "Merchant Navy") {
              this.profession.push("Merchant Navy");
              $('#ProfessionArea8').prop('checked', true);
            } else if (element == "Does Not Matter") {
              this.isAllProfession = true;
              this.f.profession_area.setValue(true)
              this.profession = ['Accounting, Banking & Finance', 'Administration & HR', 'Airline & Aviation', 'Agriculture', 'Beauty - Fashion & Jewellery Designers', 'Sales & Marketing', 'Other', 'Merchant Navy'];
              $('#ProfessionArea1').prop('checked', true);
              $('#ProfessionArea2').prop('checked', true);
              $('#ProfessionArea3').prop('checked', true);
              $('#ProfessionArea4').prop('checked', true);
              $('#ProfessionArea5').prop('checked', true);
              $('#ProfessionArea6').prop('checked', true);
              $('#ProfessionArea7').prop('checked', true);
              $('#ProfessionArea8').prop('checked', true);
              this.isOtherProfession = true;
            } else {
              $('#ProfessionArea7').prop('checked', true);
              this.isOtherProfession = true;
              this.profession.push("Other");
            }
          }
        });
        const proArr = this.profession.reduce((uniqArr, item) => {
          return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
        }, []);
        this.profession = proArr
        if (proArr.length >= 8) {
          this.isAllProfession = true;
          this.f.profession_area.setValue(true)
          $('#ProfessionArea').prop('checked', true);
        }
      }
      // have children
      if (this.detail.have_children) {
        if (this.detail.have_children == "Yes") {
          this.f.have_children.setValue("Yes");
        } else if (this.detail.have_children == "No") {
          this.f.have_children.setValue("No");
        } else {
          this.f.have_children.setValue("Does not matter");
        }
        this.f.have_children.setValue(this.detail.have_children)
      }
    }
  }

  beforeunload = () => {
    this.commonService.setFilterData(this.filtersSaved)
    let da = ""
    if (da) {
      return 'Save filter for refrence ?'
    }
    return false;
  }

  ViewProfile(id, Name) {
    localStorage.setItem("pageFrom", "Matches");
    let pid: any = localStorage.getItem('package_id');
    if (pid > 0) {
      let encrypted_id: any = this.commonService.encryptData(id);
      encrypted_id = encrypted_id.toString().replaceAll('=', 'Ar1t').replaceAll('/', 'FGH').replaceAll('+', 'THD');
      let url = `${environment.baseURL}` + 'detail/' + encrypted_id
      window.open(url, '_blank');
    } else {
      this.displayName = Name;
      $('#openModal1').click();
    }
  }

  connectCancelled(id, Name, Photo, profile) {
    this.profile_id = profile.user_id;
    this.displayName = profile.display_name;

    if (!profile?.profile_photo || profile.profile_photo_approved != 1 || profile?.photo_visiblity == 'No one' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage != 5)) {
      this.photoSrc = "assets/images/default-profile-picture.svg";
    } else {
      this.photoSrc = profile.profile_photo;
    }
    $('#openConnectModal').click();
  }

  actionOnProfile() {
    let pload = {
      user_id: parseInt(this.user_id),
      profile_id: this.profile_id,
      action: "un_decline",
    };

    this.myProfileService.actionOnProfile(pload).subscribe(
      (result) => {
        if (result.status) {
          this.toastrService.success(result.message);
          this.ApplyFilters();
        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        this.toastrService.error('Some thing went wrong!!');
      }
    );
  }

  annualIncomeType(event: any) {
    this.annualIncome = event.target.value;
    if (this.annualIncome == 'Open To All') {
      this.ApplyFilters();
    } else if (this.annualIncome == 'SpecifyRange' && parseInt(this.matchesForm.value.income_currency) > 0 && parseInt(this.matchesForm.value.income_range_to) > parseInt(this.matchesForm.value.income_range_from)) {
      this.ApplyFilters();
      // console.log(this.annualIncome)
    }
  }

  countryList() {
    this.authService.getCountryList().subscribe(
      (result) => {
        this.countries = result.data;
        this.countries.push({
          "fullnumber": "Any",
          "id": 0,
          "country_code": "",
          "country_name": "Any",
          "short_name": "ANY",
          "currency": "",
          "currency_name": ""
        })
      })
  }

  getStatus() {
    let IconStatus = 5;
    this.showIcon = 0;
    this.showIcon = IconStatus;
  }

  getPartnerDetail() {
    let data = {
      user_id: this.user_id
    }
    this.partnerPreferenceService.getPartnerDetails(data)
      .subscribe((res: any) => {
        if (res.error == "User details not registered") {
          this.router.navigate(['register']);
        } else if (!this.advanceFilter) {
          this.commonService.setLookingFor(res.userDetails.looking_for);
          if (this.matchesForm.value.looking_for == '') {
            this.advanceFilter = false;
            this.detail = res.userDetails;
            this.f.looking_for.setValue(this.detail.looking_for)
            this.f.age_from.setValue(18);
            this.f.age_to.setValue(90);
            this.f.height_from.setValue(100);
            this.f.height_to.setValue(230);
            this.f.weight_from.setValue(32);
            this.f.weight_to.setValue(114);
            if (this.detail) {
              $('#flexSwitchCheckChecked').click();
              this.toggle = true;
            }
          }
        } else {
          // console.log('Api check called');
          // console.log("this.advanceFilter2 prefrence", this.advanceFilter)
          if (res?.userDetails) {
            this.detail = res.userDetails
            this.commonService.setLookingFor(res.userDetails.looking_for);
            this.f.looking_for.setValue(this.detail.looking_for)
            this.f.age_from.setValue(this.detail.age_from);
            this.f.age_to.setValue(this.detail.age_to);
            this.f.height_from.setValue(this.detail.height_from);
            this.f.height_to.setValue(this.detail.height_to);
            if (this.detail.weight_from) {
              this.f.weight_from.setValue(this.detail.weight_from);
            } else {
              this.f.weight_from.setValue(32);
            }
            if (this.detail.weight_to) {
              this.f.weight_to.setValue(this.detail.weight_to);
            } else {
              this.f.weight_to.setValue(114);
            }
            this.value = this.detail.age_from;
            this.highValue = this.detail.age_to;
            this.minValue = this.detail.height_from;
            this.maxValue = this.detail.height_to;
            this.minValue3 = this.detail.weight_from;
            this.maxValue3 = this.detail.weight_to;

            this.f.income_range_from.setValue(this.detail.income_range_from);
            this.f.income_range_to.setValue(this.detail.income_range_to);

            this.minValue4 = this.detail.income_range_from;
            this.maxValue4 = this.detail.income_range_to;

            this.annualIncome = this.detail.annual_income
            this.f.income_currency.setValue(this.detail.income_currency);
            if (this.detail.income_currency && this.detail.income_currency != "other") {
              // this.currencyCode = parseInt(this.detail.income_currency)
            }
            if (this.detail.annual_income == 'SpecifyRange') {
              this.currencyCode = parseInt(this.detail.income_currency)
            } else if (this.detail.annual_income == 'Open To All' || this.detail.annual_income == 'OpenToAll') {
              //unused code
              // this.currencyCode = 0;
            }
            // console.log('currencyCode',this.currencyCode)
            // living with family 
            if (this.detail.living_with_family != "") {
              if (this.detail.living_with_family == "Yes") {
                this.f.living_with_family.setValue("Yes")
                this.showFamilyType = true;
                // set family type 
                if (this.detail?.family_type) {
                  let FTArr = this.detail.family_type.split(',');
                  this.familyType = [];
                  FTArr.forEach((element: any) => {
                    if (element != '') {
                      setTimeout(() => {

                        if (element == "Extended Family") {
                          this.familyType.push("Extended Family");
                          $('#familyTypeSidebar2').prop('checked', true);
                        } else if (element == "Immediate Family") {
                          this.familyType.push("Immediate Family");
                          $('#familyTypeSidebar3').prop('checked', true);
                        } else if (element == "Joint Family") {
                          this.familyType.push("Joint Family");
                          $('#familyTypeSidebar4').prop('checked', true);
                        } else if (element == "Nuclear Family") {
                          this.familyType.push(element);
                          $('#familyTypeSidebar5').prop('checked', true);
                        } else if (element == "Doesn't Matter") {
                          this.familyType = [];
                          this.isAllFamilyType = true;
                          this.f.family_type.setValue(true);
                          this.familyType = ['Extended Family', 'Immediate Family', 'Joint Family', 'Nuclear Family'];
                          $('#familyTypeSidebar2').prop('checked', true);
                          $('#familyTypeSidebar3').prop('checked', true);
                          $('#familyTypeSidebar4').prop('checked', true);
                          $('#familyTypeSidebar5').prop('checked', true);

                        } else {
                          //  // console.log(this.familyType,"esle condition");
                        }
                        if (this.familyType.length >= 4) {
                          this.isAllFamilyType = true;
                          this.f.family_type.setValue(true);
                          $('#familyTypeSidebar1').prop('checked', true)
                        }
                      }, 50);
                    }
                  });
                }
                // set family type
              } else if (this.detail.living_with_family == "No") {
                this.f.living_with_family.setValue("No")
                this.showFamilyType = false;
                $('#familyTypeSidebar2').prop('checked', true)

              } else {
                this.f.living_with_family.setValue("")
                this.showFamilyType = false;
                $('#familyTypeSidebar3').prop('checked', true)
              }
            }

            // residency status set 
            if (this.detail?.residency_status) {
              let residencyArr = this.detail.residency_status.split(',');
              this.residency = [];
              residencyArr.forEach((element: any) => {
                // console.log(element);
                if (element != '') {
                  if (element == "Citizen") {
                    this.residency.push("Citizen");
                    $('#SidebarResidencyFilter2').prop('checked', true);
                  } else if (element == "Work Permit") {
                    this.residency.push("Work Permit");
                    $('#SidebarResidencyFilter3').prop('checked', true);
                  } else if (element == "Permanent Resident") {
                    this.residency.push("Permanent Resident");
                    $('#SidebarResidencyFilter4').prop('checked', true);
                  } else if (element == "Student Visa") {
                    this.residency.push("Student Visa");
                    $('#SidebarResidencyFilter5').prop('checked', true);
                  } else if (element == "Visitor Visa") {
                    this.residency.push("Visitor Visa");
                    $('#SidebarResidencyFilter6').prop('checked', true);
                  } else {
                    $('#SidebarResidencyFilter7').prop('checked', true);
                    this.residency.push("Other");
                    this.isOtherResidencyStatus = true;
                  }
                }
                // residency status 
                const resArr = this.residency.reduce((uniqArr, item) => {
                  return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
                }, []);
                if (resArr.length >= 6) {

                  this.residency = resArr;
                  this.isAllResidencyStatus = true;
                  this.f.residency_status.setValue(true)
                  $('#SidebarResidencyFilter1').prop('checked', true);
                }
              });
              // console.log(this.residency,this.detail.residency_status)
            }

            // religion set 
            if (this.detail?.religion) {
              let religionArr = this.detail.religion.split(',');
              this.religion = [];
              religionArr.forEach((element: any) => {

                if (element != '') {
                  if (element == "Buddhist") {
                    this.religion.push("Buddhist");
                    $('#Religion1').prop('checked', true);
                  } else if (element == "Christian") {
                    this.religion.push("Christian");
                    $('#Religion2').prop('checked', true);
                  } else if (element == "Hindu") {
                    this.religion.push("Hindu");
                    $('#Religion3').prop('checked', true);
                  } else if (element == "Parsi") {
                    this.religion.push("Parsi");
                    $('#Religion9').prop('checked', true);
                  } else if (element == "Jain") {
                    this.religion.push("Jain");
                    $('#Religion4').prop('checked', true);
                  } else if (element == "Muslim") {
                    this.religion.push("Muslim");
                    $('#Religion5').prop('checked', true);
                  } else if (element == "Sikh") {
                    this.religion.push("Sikh");
                    $('#Religion6').prop('checked', true);
                  } else if (element == "No Religion") {
                    this.religion.push("No Religion");
                    $('#Religion7').prop('checked', true);
                  } else if (element == "Any Religion") {
                    this.religion = ['Buddhist', 'Christian', 'Hindu', 'Parsi', 'Jain', 'Muslim', 'No Religion', 'Other', 'Sikh'];
                    $('#Religion1').prop('checked', true);
                    $('#Religion2').prop('checked', true);
                    $('#Religion3').prop('checked', true);
                    $('#Religion4').prop('checked', true);
                    $('#Religion5').prop('checked', true);
                    $('#Religion6').prop('checked', true);
                    $('#Religion7').prop('checked', true);
                    $('#Religion8').prop('checked', true);
                    $('#Religion9').prop('checked', true);
                    this.isOtherReligion = true;
                  } else {
                    this.religion.push("Other");
                    $('#Religion8').prop('checked', true);
                    this.isOtherReligion = true;
                  }
                }
                // languages 
                const relArr = this.religion.reduce((uniqArr, item) => {
                  return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
                }, []);
                if (relArr.length >= 9) {
                  this.religion = relArr;
                  this.isAllReligion = true;
                  this.f.religion.setValue(true);
                  $('#Religion').prop('checked', true);
                }
              });
            }

            if (this.detail?.languages) {
              let languagesArr = this.detail.languages.split(',');
              this.language = [];
              languagesArr.forEach((element: any) => {
                // console.log(element);
                if (element != '') {
                  if (element == "Bengali") {
                    this.language.push("Bengali");
                    $('#LanguagePreference1').prop('checked', true);
                  } else if (element == "English") {
                    this.language.push("English");
                    $('#LanguagePreference2').prop('checked', true);
                  } else if (element == "Gujarati") {
                    this.language.push("Gujarati");
                    $('#LanguagePreference3').prop('checked', true);
                  } else if (element == "Hindi") {
                    this.language.push("Hindi");
                    $('#LanguagePreference4').prop('checked', true);
                  } else if (element == "Marathi") {
                    this.language.push("Marathi");
                    $('#LanguagePreference5').prop('checked', true);
                  } else if (element == "Punjabi") {
                    this.language.push("Punjabi");
                    $('#LanguagePreference6').prop('checked', true);
                  } else if (element == "Telugu") {
                    this.language.push("Telugu");
                    $('#LanguagePreference9').prop('checked', true);
                  } else if (element == "Tamil") {
                    this.language.push("Tamil");
                    $('#LanguagePreference7').prop('checked', true);
                  } else if (element == "Any Language") {

                    this.language = ['Bengali', 'English', 'Gujarati', 'Hindi', 'Marathi', 'Punjabi', 'Other', 'Tamil', 'Telugu'];
                    $('#LanguagePreference1').prop('checked', true);
                    $('#LanguagePreference2').prop('checked', true);
                    $('#LanguagePreference3').prop('checked', true);
                    $('#LanguagePreference4').prop('checked', true);
                    $('#LanguagePreference5').prop('checked', true);
                    $('#LanguagePreference6').prop('checked', true);
                    $('#LanguagePreference7').prop('checked', true);
                    $('#LanguagePreference8').prop('checked', true);
                    $('#LanguagePreference9').prop('checked', true);
                    $('#LanguagePreference8').prop('checked', true);
                    this.isOtherLanguage = true;

                  } else {
                    $('#LanguagePreference8').prop('checked', true);
                    this.language.push("Other");
                    this.isOtherLanguage = true;
                  }
                }
                const langArr = this.language.reduce((uniqArr, item) => {
                  return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
                }, []);
                this.language = langArr;
                if (langArr.length >= 9) {

                  this.isAllLanguage = true
                  this.f.languages.setValue(true)
                  $('#LanguagePreference').prop('checked', true);
                }
              });
            }

            if (this.detail?.food_preference) {
              let food_preferenceArr = this.detail.food_preference.split(',');
              this.food = [];
              food_preferenceArr.forEach((element: any) => {
                // console.log(element);
                if (element != '') {
                  if (element == "Veg") {
                    this.food.push(element);
                    $('#FoodPreference1').prop('checked', true);
                  } else if (element == "Non Veg") {
                    this.food.push(element);
                    $('#FoodPreference2').prop('checked', true);
                  } else if (element == "Occasionally Non-Veg") {
                    this.food.push(element);
                    $('#FoodPreference4').prop('checked', true);
                  } else if (element == "Eggetarian") {
                    this.food.push(element);
                    $('#FoodPreference3').prop('checked', true);
                  } else if (element == "Pescatarian") {
                    this.food.push(element);
                    $('#FoodPreference8').prop('checked', true);
                  } else if (element == "Jain") {
                    this.food.push(element);
                    $('#FoodPreference5').prop('checked', true);
                  } else if (element == "Vegan") {
                    this.food.push(element);
                    $('#FoodPreference6').prop('checked', true);
                  } else if (element == "Does Not Matter") {
                    this.food = [];
                    this.isAllFoodPreference = true;
                    this.f.food_preference.setValue(true)
                    this.food = ['Veg', 'Occasionally Non-Veg', 'Non-Veg', 'Eggetarian', 'Pescatarian', 'Jain', 'Other', 'Vegan'];
                    $('#FoodPreference1').prop('checked', true);
                    $('#FoodPreference2').prop('checked', true);
                    $('#FoodPreference3').prop('checked', true);
                    $('#FoodPreference4').prop('checked', true);
                    $('#FoodPreference5').prop('checked', true);
                    $('#FoodPreference6').prop('checked', true);
                    $('#FoodPreference7').prop('checked', true);
                    $('#FoodPreference8').prop('checked', true);
                    this.isOtherFoodPreference = true
                  } else {
                    this.food.push("Other");
                    $('#FoodPreference7').prop('checked', true);
                    this.isOtherFoodPreference = true
                  }
                }
                const foodArr = this.food.reduce((uniqArr, item) => {
                  return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
                }, []);
                this.food = foodArr;
                if (foodArr.length >= 8) {
                  this.isAllFoodPreference = true;
                  this.f.food_preference.setValue(true)
                  $('#FoodPreference').prop('checked', true);
                }
              });
            }

            if (this.detail?.marital_status) {
              let maritalsArr = this.detail.marital_status.split(',');
              this.maritalStatus = [];
              maritalsArr.forEach((element: any) => {
                if (element != '') {
                  if (element == "Annulled") {
                    this.maritalStatus.push("Annulled");
                    $('#MaritalStatus2').prop('checked', true);
                  } else if (element == "Awaiting Divorce") {
                    this.maritalStatus.push("Awaiting Divorce");
                    $('#MaritalStatus3').prop('checked', true);
                  } else if (element == "Divorced") {
                    this.maritalStatus.push("Divorced");
                    $('#MaritalStatus4').prop('checked', true);
                  } else if (element == "Single") {
                    this.maritalStatus.push("Single");
                    $('#MaritalStatus5').prop('checked', true);
                  } else if (element == "Widowed") {
                    this.maritalStatus.push("Widowed");
                    $('#MaritalStatus6').prop('checked', true);
                  } else if (element == "Any Of These") {
                    this.maritalStatus = ['Annulled', 'Awaiting Divorce', 'Divorced', 'Single', 'Widowed'];
                    $('#MaritalStatus2').prop('checked', true);
                    $('#MaritalStatus3').prop('checked', true);
                    $('#MaritalStatus4').prop('checked', true);
                    $('#MaritalStatus5').prop('checked', true);
                    $('#MaritalStatus6').prop('checked', true);
                  }
                }
                if (this.maritalStatus.length >= 5) {
                  this.isAllMaritalStatus = true;
                  this.f.marital_status.setValue(true);
                  $('#MaritalStatus1').prop('checked', true)
                }
              });

            }

            if (this.detail?.education) {
              let educationArr = this.detail.education.split(',');
              this.education = [];
              educationArr.forEach((element: any) => {
                if (element != '') {
                  if (element == "High School") {
                    this.education.push("High School");
                    $('#Qualification1').prop('checked', true);
                  } else if (element == "Some College") {
                    this.education.push("Some College");
                    $('#Qualification2').prop('checked', true);
                  } else if (element == "Some University") {
                    this.education.push("Some University");
                    $('#Qualification3').prop('checked', true);
                  } else if (element == "Associate Degree" || element == "Associate`s Degree") {
                    this.education.push("Associate`s Degree");
                    $('#Qualification4').prop('checked', true);
                  } else if (element == "Bachelor Degree" || element == "Bachelor`s Degree") {
                    this.education.push("Bachelor`s Degree");
                    $('#Qualification5').prop('checked', true);
                  } else if (element == "Master Degree" || element == "Master`s Degree") {
                    this.education.push("Master`s Degree");
                    $('#Qualification6').prop('checked', true);
                  } else if (element == "Graduate Degree") {
                    this.education.push("Graduate Degree");
                    $('#Qualification7').prop('checked', true);
                  } else if (element == "Post Doctoral" || element == "PhD/Post Doctorate" || element == "PhD/Post Doctorate") {
                    this.education.push("PhD/Post Doctorate");
                    $('#Qualification8').prop('checked', true);
                  } else if (element == "Does Not Matter") {
                    this.education = ['Master`s Degree', 'PhD/Post Doctorate', 'Graduate Degree', 'Bachelor`s Degree', 'Associate`s Degree', 'Some University', 'Other', 'Some College', 'High School'];
                    $('#Qualification1').prop('checked', true);
                    $('#Qualification2').prop('checked', true);
                    $('#Qualification3').prop('checked', true);
                    $('#Qualification4').prop('checked', true);
                    $('#Qualification5').prop('checked', true);
                    $('#Qualification6').prop('checked', true);
                    $('#Qualification7').prop('checked', true);
                    $('#Qualification8').prop('checked', true);
                    $('#Qualification9').prop('checked', true);
                    this.isOtherEducation = true;
                  } else {
                    $('#Qualification9').prop('checked', true);
                    this.education.push("Other");
                    this.isOtherEducation = true;
                  }
                }
                const eduArr = this.education.reduce((uniqArr, item) => {
                  return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
                }, []);
                this.education = eduArr
                if (eduArr.length >= 9) {
                  this.isAllEducation = true;
                  this.f.education.setValue(true)
                  $('#Qualification').prop('checked', true);
                }
              });
            }

            if (this.detail?.nature_of_work) {
              let workArr = this.detail.nature_of_work.split(',');
              this.work = [];
              workArr.forEach((element: any) => {
                // console.log(element);
                if (element != '') {
                  if (element == "Does Not Matter") {
                    this.work = [];
                    this.isOtherNatureOfWork = false;
                    this.isAllNatureOfWork = false;
                    return;
                  } else if (element == "Business / Self Employed" || element == "Business/Self Employed") {
                    this.work.push("Business");
                    this.work.push("Self Employed");
                    $('#natureSidebarBtn7').prop('checked', true);
                    $('#natureSidebarBtn2').prop('checked', true);
                  } else if (element == "Government Company / Public Sector" || element == "Government Company" || element == "Government Company/Public Sector") {
                    this.work.push("Government Company");
                    $('#natureSidebarBtn3').prop('checked', true);
                  } else if (element == "Private Company" || element == "Government Company/Public Sector") {
                    this.work.push("Private Company");
                    $('#natureSidebarBtn4').prop('checked', true);
                  } else if (element == "Not Working") {
                    this.work.push("Not Working");
                    $('#natureSidebarBtn5').prop('checked', true);
                  } else if (element == "Self Employed") {
                    this.work.push("Self Employed");
                    $('#natureSidebarBtn7').prop('checked', true);
                  } else if (element == "Does Not Matter") {
                    this.isAllNatureOfWork = true;
                    this.isOtherNatureOfWork = true;
                    this.isOtherNatureOfWork = true;
                    this.f.nature_of_work.setValue(true)
                    this.work = ['Business', 'Government Company', 'Private Company', 'Self Employed', 'Not Working', 'Other'];
                    $('#natureSidebarBtn1').prop('checked', true);
                    $('#natureSidebarBtn2').prop('checked', true);
                    $('#natureSidebarBtn3').prop('checked', true);
                    $('#natureSidebarBtn4').prop('checked', true);
                    $('#natureSidebarBtn5').prop('checked', true);
                    $('#natureSidebarBtn6').prop('checked', true);
                    $('#natureSidebarBtn7').prop('checked', true);
                  } else {
                    $('#natureSidebarBtn6').prop('checked', true);
                    this.isOtherNatureOfWork = true;
                    this.work.push("Other");
                  }
                }
              });
              const worArr = this.work.reduce((uniqArr, item) => {
                return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
              }, []);
              this.work = worArr
              if (worArr.length >= 6) {
                this.isAllNatureOfWork = true;
                this.f.nature_of_work.setValue(true)
                $('#natureSidebarBtn6').prop('checked', true);
              }
              // console.log(this.work,"sagar//")

            }


            if (this.detail?.profession_area) {
              let professionArr = this.detail.profession_area.split(',');
              this.profession = [];
              professionArr.forEach((element: any) => {
                // console.log(element);
                if (element != '') {
                  if (element == "Accounting - Banking & Finance" || element == "Accounting, Banking & Finance") {
                    this.profession.push("Accounting - Banking & Financeli");
                    $('#ProfessionArea1').prop('checked', true);
                  } else if (element == "Administration & HR") {
                    this.profession.push("Administration & HR");
                    $('#ProfessionArea2').prop('checked', true);
                  } else if (element == "Airline & Aviation") {
                    this.profession.push("Airline & Aviation");
                    $('#ProfessionArea3').prop('checked', true);
                  } else if (element == "Agriculture") {
                    this.profession.push("Agriculture");
                    $('#ProfessionArea4').prop('checked', true);
                  } else if (element == "Beauty - Fashion & Jewellery Designers") {
                    this.profession.push("Beauty - Fashion & Jewellery Designers");
                    $('#ProfessionArea5').prop('checked', true);
                  } else if (element == "Sales & Marketing" || element == "Sales Marketing") {
                    this.profession.push("Sales & Marketing");
                    $('#ProfessionArea6').prop('checked', true);
                  } else if (element == "Merchant Navy") {
                    this.profession.push("Merchant Navy");
                    $('#ProfessionArea8').prop('checked', true);
                  } else if (element == "Does Not Matter") {
                    this.isAllProfession = true;
                    this.f.profession_area.setValue(true)
                    this.profession = ['Accounting, Banking & Finance', 'Administration & HR', 'Airline & Aviation', 'Agriculture', 'Beauty - Fashion & Jewellery Designers', 'Sales & Marketing', 'Other', 'Merchant Navy'];
                    $('#ProfessionArea1').prop('checked', true);
                    $('#ProfessionArea2').prop('checked', true);
                    $('#ProfessionArea3').prop('checked', true);
                    $('#ProfessionArea4').prop('checked', true);
                    $('#ProfessionArea5').prop('checked', true);
                    $('#ProfessionArea6').prop('checked', true);
                    $('#ProfessionArea7').prop('checked', true);
                    $('#ProfessionArea8').prop('checked', true);
                    this.isOtherProfession = true;
                  } else {
                    $('#ProfessionArea7').prop('checked', true);
                    this.isOtherProfession = true;
                    this.profession.push("Other");
                  }
                }
              });
              const proArr = this.profession.reduce((uniqArr, item) => {
                return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
              }, []);
              this.profession = proArr
              if (proArr.length >= 8) {
                this.isAllProfession = true;
                this.f.profession_area.setValue(true)
                $('#ProfessionArea').prop('checked', true);
              }
            }
            // have children
            if (this.detail.have_children) {
              if (this.detail.have_children == "Yes") {
                this.f.have_children.setValue("Yes");
              } else if (this.detail.have_children == "No") {
                this.f.have_children.setValue("No");
              } else {
                this.f.have_children.setValue("Does not matter");
              }
              this.f.have_children.setValue(this.detail.have_children)
            }
            this.ApplyFilters();
          }
        }
      });

  }
  setFullRangeOfAHW() {
    this.f.age_from.setValue(18);
    this.f.age_to.setValue(90);
    this.f.height_from.setValue(100);
    this.f.height_to.setValue(230);
    this.f.weight_from.setValue(32);
    this.f.weight_to.setValue(114);

    this.value = 18;
    this.highValue = 90;
    this.minValue = 100;
    this.maxValue = 230;
    this.minValue3 = 32;
    this.maxValue3 = 114;
  }

  rangeSet() {
    for (let i = 18; i <= 90; i++) {
      this.age_from.push({ id: i });
      this.age_to.push({ id: i });
    }

    for (let i = 100; i <= 230; i++) {
      this.height_from.push({ i });
      this.height_to.push({ i });

    }
    for (let i = 32; i <= 114; i++) {
      this.weight_from.push({ i });
      this.weight_to.push({ i });
    }

    for (let i = 0; i <= 200; i++) {
      this.income_from.push({ id: i });
      this.income_to.push({ id: i });
    }
  }

  get f() {
    return this.matchesForm.controls;
  }

  loader() {
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1500);
  }

  haveChildren(id) {
    this.f.have_children.setValue(id)
    this.ApplyFilters();
  }

  sliderValueChangeAgeEndPoints() {
    if ((this.value == 18 && this.f.age_from.value != this.value) || this.highValue == 90 && this.f.age_to.value != this.highValue) {
      this.handleChange()
    }
  }

  sliderValueChangeHeightEndPoints() {
    if ((this.minValue == 100 && this.f.height_from.value != this.minValue) || this.maxValue == 230 && this.f.height_to.value != this.maxValue) {
      this.handleChange()
    }
  }

  sliderValueChangeWeightEndPoints() {
    if ((this.minValue3 == 32 && this.f.weight_from.value != this.minValue3) || this.maxValue3 == 114 && this.f.weight_to.value != this.maxValue3) {
      this.handleChange()
    }
  }

  handleChange() {
    // console.log('handleChange before', this.minValue, "this.minValue", this.maxValue, "this.maxValue", this.detail.height_from, "this.detail.height_from", this.detail.height_to, "this.detail.height_to")
    this.f.age_from.setValue(this.value)
    this.f.age_to.setValue(this.highValue)
    this.f.height_from.patchValue(this.minValue)
    this.f.height_to.patchValue(this.maxValue)
    this.f.weight_from.patchValue(this.minValue3)
    this.f.weight_to.patchValue(this.maxValue3)
    this.f.income_range_from.patchValue(this.minValue4)
    this.f.income_range_to.patchValue(this.maxValue4)
    this.ApplyFilters();
    // console.log('handleChange after', this.minValue, "this.minValue", this.maxValue, "this.maxValue", this.detail.height_from, "this.detail.height_from", this.detail.height_to, "this.detail.height_to")
    // this.changeOnSlider();
  }

  handleRange() {
    // console.log('handleRange before', this.minValue, "this.minValue", this.maxValue, "this.maxValue", this.detail.height_from, "this.detail.height_from", this.detail.height_to, "this.detail.height_to")

    if (this.f.age_to.value < this.f.age_from.value) {
      let temp1 = this.f.age_from.value;
      let temp2 = this.f.age_to.value;
      this.f.age_to.setValue(temp1);
      this.f.age_from.setValue(temp2);
    }
    if (this.f.height_to.value < this.f.height_from.value) {
      let temp1 = this.f.height_from.value;
      let temp2 = this.f.height_to.value;
      this.f.height_to.setValue(temp1);
      this.f.height_from.setValue(temp2);
    }
    if (this.f.weight_to.value < this.f.weight_from.value) {
      let temp1 = this.f.weight_from.value;
      let temp2 = this.f.weight_to.value;
      this.f.weight_to.setValue(temp1);
      this.f.weight_from.setValue(temp2);
    }

    this.value = this.f.age_from.value;
    this.highValue = this.f.age_to.value;
    this.minValue = this.f.height_from.value;
    this.maxValue = this.f.height_to.value;
    this.minValue3 = this.f.weight_from.value;
    this.maxValue3 = this.f.weight_to.value;
    this.minValue4 = this.f.income_range_from.value;
    this.maxValue4 = this.f.income_range_to.value;

    this.ApplyFilters();
    // this.changeOnDropdown();

    // console.log('handleRange after', this.minValue, "this.minValue", this.maxValue, "this.maxValue", this.detail.height_from, "this.detail.height_from", this.detail.height_to, "this.detail.height_to")

  }
  changeOnDropdown() {
    if (this.f.age_from.value) {
      this.age_to = [];
      for (let i = this.f.age_from.value; i <= 90; i++) {
        this.age_to.push({ id: i });
      }
    }
    if (this.f.height_from.value) {
      this.height_to = [];
      for (let i = this.f.height_from.value; i <= 230; i++) {
        this.height_to.push({ i });
      }
    }
    if (this.f.weight_from.value) {
      this.weight_to = [];
      for (let i = this.f.weight_from.value; i <= 114; i++) {
        this.weight_to.push({ i });
      }
    }
    if (this.f.income_range_from.value) {
      this.income_to = []
      for (let i = this.f.income_range_from.value; i <= 200; i++) {
        this.income_to.push({ id: i });
      }
    }
  }

  changeOnSlider() {
    if (this.value) {
      this.age_to = [];
      for (let i = this.value; i <= 90; i++) {
        this.age_to.push({ id: i });
      }
    }
    if (this.minValue) {
      this.height_to = [];
      for (let i = this.minValue; i <= 230; i++) {
        this.height_to.push({ i });
      }
    }
    if (this.minValue3) {
      this.weight_to = [];
      for (let i = this.minValue3; i <= 114; i++) {
        this.weight_to.push({ i });
      }
    }
    if (this.minValue4) {
      this.income_to = []
      for (let i = this.minValue4; i <= 200; i++) {
        this.income_to.push({ id: i });
      }
    }
  }

  LivingWithFamily(id) {
    // console.log(id)
    this.livingWithFamily = id;
    if (id == "Yes") {
      this.f.living_with_family.setValue("Yes")
      // console.log("show",this.matchesForm.value)
      this.showFamilyType = true;
    } else {
      this.f.living_with_family.setValue(id)
      this.showFamilyType = false;
      this.familyType = [];
      // console.log("hide",this.matchesForm.value)
    }
    this.ApplyFilters();
  }

  // family type 
  filterFamilyType(id) {

    if (id != "All") {
      if (this.familyType.some(a => a === id)) {
        this.familyType = this.familyType.filter(a => a !== id)
      } else {
        this.familyType.push(id)
      }
    } else {
      // console.log( this.f.family_type.value,"valuesbbbb")
      if (this.isAllFamilyType == false && this.matchesForm.value.family_type == false) {
        this.isAllFamilyType = true;
        this.f.family_type.setValue(true);
        this.familyType = ['Extended Family', 'Immediate Family', 'Joint Family', 'Nuclear Family'];
        $('#familyTypeSidebar2').prop('checked', true);
        $('#familyTypeSidebar3').prop('checked', true);
        $('#familyTypeSidebar4').prop('checked', true);
        $('#familyTypeSidebar5').prop('checked', true);
      } else {
        this.familyType = []
        this.f.family_type.setValue(false);
        this.isAllFamilyType = false;
        $('#familyTypeSidebar2').prop('checked', false);
        $('#familyTypeSidebar3').prop('checked', false);
        $('#familyTypeSidebar4').prop('checked', false);
        $('#familyTypeSidebar5').prop('checked', false);
      }
    }
    if (this.familyType.length >= 4) {
      this.isAllFamilyType = true;
      this.f.family_type.setValue(true);
      $('#familyTypeSidebar1').prop('checked', true)
    } else {
      this.isAllFamilyType = false;
      this.f.family_type.setValue(false);
      $('#familyTypeSidebar1').prop('checked', false)
    }
    this.ApplyFilters();
    // console.log(this.familyType,this.isAllFamilyType, this.matchesForm.value.family_type)
  }
  // marital status
  filterMaritalStatus(id) {
    if (id != "All") {
      if (this.maritalStatus.some(a => a === id)) {
        this.maritalStatus = this.maritalStatus.filter(a => a !== id)
      } else {
        this.maritalStatus.push(id)
      }
    } else {
      if (this.matchesForm.value.marital_status == false && this.isAllMaritalStatus == false) {
        this.isAllMaritalStatus = true;
        this.f.marital_status.setValue(true);
        this.maritalStatus = ['Annulled', 'Awaiting Divorce', 'Divorced', 'Single', 'Widowed'];
        $('#MaritalStatus2').prop('checked', true);
        $('#MaritalStatus3').prop('checked', true);
        $('#MaritalStatus4').prop('checked', true);
        $('#MaritalStatus5').prop('checked', true);
        $('#MaritalStatus6').prop('checked', true);
      } else {
        this.maritalStatus = []
        this.isAllMaritalStatus = false;
        this.f.marital_status.setValue(false);
        $('#MaritalStatus2').prop('checked', false);
        $('#MaritalStatus3').prop('checked', false);
        $('#MaritalStatus4').prop('checked', false);
        $('#MaritalStatus5').prop('checked', false);
        $('#MaritalStatus6').prop('checked', false);
      }
    }
    if (this.maritalStatus.length >= 5) {
      this.isAllMaritalStatus = true;
      this.f.marital_status.setValue(true);
      $('#MaritalStatus1').prop('checked', true)
    } else {
      this.isAllMaritalStatus = false;
      this.f.marital_status.setValue(false);
      $('#MaritalStatus1').prop('checked', false)
    }

    this.ApplyFilters();
  }

  // religion 
  filterReligion(id) {
    if (id != "All") {
      if (this.religion.some(a => a === id)) {
        this.religion = this.religion.filter(a => a !== id)
      } else {
        this.religion.push(id)
      }
    } else {
      if (this.isAllReligion == false && this.matchesForm.value.religion == false) {
        this.isAllReligion = true;
        this.f.religion.setValue(true);

        this.religion = ['Buddhist', 'Christian', 'Hindu', 'Parsi', 'Jain', 'Muslim', 'No Religion', 'Other', 'Sikh'];
        $('#Religion1').prop('checked', true);
        $('#Religion2').prop('checked', true);
        $('#Religion3').prop('checked', true);
        $('#Religion4').prop('checked', true);
        $('#Religion5').prop('checked', true);
        $('#Religion6').prop('checked', true);
        $('#Religion7').prop('checked', true);
        $('#Religion8').prop('checked', true);
        $('#Religion9').prop('checked', true);
      } else {
        this.isAllReligion = false;
        ///this.isOtherReligion = false;
        this.religion = []
        this.f.religion.setValue(false);
        $('#Religion1').prop('checked', false);
        $('#Religion2').prop('checked', false);
        $('#Religion3').prop('checked', false);
        $('#Religion4').prop('checked', false);
        $('#Religion5').prop('checked', false);
        $('#Religion6').prop('checked', false);
        $('#Religion7').prop('checked', false);
        $('#Religion8').prop('checked', false);
        $('#Religion9').prop('checked', false);
      }
    }
    if (this.religion.length >= 9) {
      this.isAllReligion = true;
      this.f.religion.setValue(true);
      $('#Religion').prop('checked', true);
    } else {
      this.isAllReligion = false;
      $('#Religion').prop('checked', false);
    }
    if (this.religion.toString().includes("Other")) {
      this.isOtherReligion = true;
    } else {
      this.isOtherReligion = false;
    }
    this.ApplyFilters();
  }

  //language 
  filterLanguage(id) {
    if (id != "All") {
      if (this.language.some(a => a === id)) {
        this.language = this.language.filter(a => a !== id)
      } else {
        this.language.push(id)
      }
    } else {
      if (this.isAllLanguage == false && this.matchesForm.value.languages == false) {
        this.isAllLanguage = true;
        this.f.languages.setValue(true);
        this.language = ['Bengali', 'English', 'Gujarati', 'Hindi', 'Marathi', 'Punjabi', 'Other', 'Tamil', 'Telugu'];
        $('#LanguagePreference1').prop('checked', true);
        $('#LanguagePreference2').prop('checked', true);
        $('#LanguagePreference3').prop('checked', true);
        $('#LanguagePreference4').prop('checked', true);
        $('#LanguagePreference5').prop('checked', true);
        $('#LanguagePreference6').prop('checked', true);
        $('#LanguagePreference7').prop('checked', true);
        $('#LanguagePreference8').prop('checked', true);
        $('#LanguagePreference9').prop('checked', true);
      } else {
        this.language = []
        this.isAllLanguage = false
        this.f.languages.setValue(false);
        $('#LanguagePreference1').prop('checked', false);
        $('#LanguagePreference2').prop('checked', false);
        $('#LanguagePreference3').prop('checked', false);
        $('#LanguagePreference4').prop('checked', false);
        $('#LanguagePreference5').prop('checked', false);
        $('#LanguagePreference6').prop('checked', false);
        $('#LanguagePreference7').prop('checked', false);
        $('#LanguagePreference8').prop('checked', false);
        $('#LanguagePreference9').prop('checked', false);
      }
    }
    if (this.language.length >= 9) {
      this.isAllLanguage = true
      this.f.languages.setValue(true)
      $('#LanguagePreference').prop('checked', true);
    } else {
      this.isAllLanguage = false
      this.f.languages.setValue(false)
      $('#LanguagePreference').prop('checked', false);
    }

    if (this.language.toString().includes("Other")) {
      this.isOtherLanguage = true;
    } else {
      this.isOtherLanguage = false;
    }
    this.ApplyFilters();
  }

  //residency 
  filterResidency(id) {
    if (id != "All") {
      if (this.residency.some(a => a === id)) {
        this.residency = this.residency.filter(a => a !== id)
      } else {
        this.residency.push(id)
      }
    } else {
      if (this.isAllResidencyStatus == false && this.matchesForm.value.residency_status == false) {
        this.isAllResidencyStatus = true;
        this.f.residency_status.setValue(true)
        this.residency = ['Citizen', 'Work Permit', 'Permanent Resident', 'Student Visa', 'Visitor Visa', 'Other'];
        $('#SidebarResidencyFilter2').prop('checked', true);
        $('#SidebarResidencyFilter3').prop('checked', true);
        $('#SidebarResidencyFilter4').prop('checked', true);
        $('#SidebarResidencyFilter5').prop('checked', true);
        $('#SidebarResidencyFilter6').prop('checked', true);
        $('#SidebarResidencyFilter7').prop('checked', true);
      } else {
        this.residency = []
        this.f.residency_status.setValue(false)
        this.isAllResidencyStatus = false;
        $('#SidebarResidencyFilter2').prop('checked', false);
        $('#SidebarResidencyFilter3').prop('checked', false);
        $('#SidebarResidencyFilter4').prop('checked', false);
        $('#SidebarResidencyFilter5').prop('checked', false);
        $('#SidebarResidencyFilter6').prop('checked', false);
        $('#SidebarResidencyFilter7').prop('checked', false);
      }
    }
    if (this.residency.length >= 6) {
      this.isAllResidencyStatus = true;
      this.f.residency_status.setValue(true)
      $('#SidebarResidencyFilter1').prop('checked', true);
    } else {
      this.isAllResidencyStatus = false;
      $('#SidebarResidencyFilter1').prop('checked', false);
    }

    if (this.residency.toString().includes("Other")) {
      this.isOtherResidencyStatus = true;
    } else {
      this.isOtherResidencyStatus = false;
    }

    this.ApplyFilters();
  }

  //residency 
  filterEducation(id) {

    if (id != "All") {
      if (this.education.some(a => a === id)) {
        this.education = this.education.filter(a => a !== id)
      } else {
        this.education.push(id)
      }
    } else {
      if (this.isAllEducation == false && this.matchesForm.value.education == false) {
        this.isAllEducation = true;
        this.f.education.setValue(true)
        this.education = ['Master`s Degree', 'PhD/Post Doctorate', 'Graduate Degree', 'Bachelor`s Degree', 'Associate`s Degree', 'Some University', 'Other', 'Some College', 'High School'];
        $('#Qualification1').prop('checked', true);
        $('#Qualification2').prop('checked', true);
        $('#Qualification3').prop('checked', true);
        $('#Qualification4').prop('checked', true);
        $('#Qualification5').prop('checked', true);
        $('#Qualification6').prop('checked', true);
        $('#Qualification7').prop('checked', true);
        $('#Qualification8').prop('checked', true);
        $('#Qualification9').prop('checked', true);
      } else {
        this.education = []
        this.isAllEducation = false;
        this.f.education.setValue(false)
        $('#Qualification1').prop('checked', false);
        $('#Qualification2').prop('checked', false);
        $('#Qualification3').prop('checked', false);
        $('#Qualification4').prop('checked', false);
        $('#Qualification5').prop('checked', false);
        $('#Qualification6').prop('checked', false);
        $('#Qualification7').prop('checked', false);
        $('#Qualification8').prop('checked', false);
        $('#Qualification9').prop('checked', false);
      }
    }
    // console.log(this.education,"sagar")

    if (this.education.length >= 9) {
      this.isAllEducation = true;
      this.f.education.setValue(true)
      $('#Qualification').prop('checked', true);
    } else {
      this.isAllEducation = false;
      $('#Qualification').prop('checked', false);
    }
    if (this.education.toString().includes("Other")) {
      this.isOtherEducation = true;
    } else {
      this.isOtherEducation = false;
    }
    this.ApplyFilters();
  }

  keyPressNumeric(event) {
    const pattern = /\D/g;
    if (!pattern.test(event.target.value)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumeric(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.matchesForm.patchValue({ 'income_range_from': newText });
  }

  pasteNumeric1(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.matchesForm.patchValue({ 'income_range_to': newText });
  }

  // nature of work 
  filterWork(id) {
    if (id != "All") {
      if (this.work.some(a => a === id)) {
        this.work = this.work.filter(a => a !== id)
      } else {
        this.work.push(id)
      }
    } else {

      if (this.isAllNatureOfWork == false && this.matchesForm.value.nature_of_work == false) {
        this.isAllNatureOfWork = true;
        this.f.nature_of_work.setValue(true)
        this.work = ['Business', 'Government Company', 'Private Company', 'Self Employed', 'Not Working', 'Other'];
        $('#natureSidebarBtn1').prop('checked', true);
        $('#natureSidebarBtn2').prop('checked', true);
        $('#natureSidebarBtn3').prop('checked', true);
        $('#natureSidebarBtn4').prop('checked', true);
        $('#natureSidebarBtn5').prop('checked', true);
        $('#natureSidebarBtn6').prop('checked', true);
        $('#natureSidebarBtn7').prop('checked', true);
      } else {
        this.work = []
        this.isAllNatureOfWork = false;
        this.f.nature_of_work.setValue(false)
        $('#natureSidebarBtn1').prop('checked', false);
        $('#natureSidebarBtn2').prop('checked', false);
        $('#natureSidebarBtn3').prop('checked', false);
        $('#natureSidebarBtn4').prop('checked', false);
        $('#natureSidebarBtn5').prop('checked', false);
        $('#natureSidebarBtn6').prop('checked', false);
        $('#natureSidebarBtn7').prop('checked', false);
      }

    }
    if (this.work.toString().includes("Other")) {
      this.isOtherNatureOfWork = true;
    } else {
      this.isOtherNatureOfWork = false;
    }
    if (this.work.length >= 6) {
      this.isAllNatureOfWork = true;
      this.f.nature_of_work.setValue(true)
      $('#natureSidebarBtn1').prop('checked', true);
    } else {
      this.isAllNatureOfWork = false;
      $('#natureSidebarBtn1').prop('checked', false);
    }
    this.ApplyFilters();
  }

  //profession 
  filterProfession(id) {
    if (id != "All") {
      if (this.profession.some(a => a === id)) {
        this.profession = this.profession.filter(a => a !== id)
      } else {
        this.profession.push(id)
      }

    } else {
      if (this.isAllProfession == false && this.matchesForm.value.profession_area == false) {
        this.isAllProfession = true;
        this.f.profession_area.setValue(true)
        this.profession = ['Accounting, Banking & Finance', 'Administration & HR', 'Airline & Aviation', 'Agriculture', 'Beauty - Fashion & Jewellery Designers', 'Sales & Marketing', 'Other', 'Merchant Navy'];
        $('#ProfessionArea1').prop('checked', true);
        $('#ProfessionArea2').prop('checked', true);
        $('#ProfessionArea3').prop('checked', true);
        $('#ProfessionArea4').prop('checked', true);
        $('#ProfessionArea5').prop('checked', true);
        $('#ProfessionArea6').prop('checked', true);
        $('#ProfessionArea7').prop('checked', true);
        $('#ProfessionArea8').prop('checked', true);
      } else {
        this.profession = []
        this.isAllProfession = false;
        this.f.profession_area.setValue(false)
        $('#ProfessionArea1').prop('checked', false);
        $('#ProfessionArea2').prop('checked', false);
        $('#ProfessionArea3').prop('checked', false);
        $('#ProfessionArea4').prop('checked', false);
        $('#ProfessionArea5').prop('checked', false);
        $('#ProfessionArea6').prop('checked', false);
        $('#ProfessionArea7').prop('checked', false);
        $('#ProfessionArea8').prop('checked', false);
      }
    }
    if (this.profession.length >= 8) {
      this.isAllProfession = true;
      this.f.profession_area.setValue(true)
      $('#ProfessionArea').prop('checked', true);
    } else {
      this.isAllProfession = false;
      $('#ProfessionArea').prop('checked', false);
    }

    if (this.profession.toString().includes("Other")) {
      this.isOtherProfession = true;
    } else {
      this.isOtherProfession = false;
    }
    this.ApplyFilters();
  }

  // food 
  filterFood(id) {
    if (id != "All") {
      if (this.food.some(a => a === id)) {
        this.food = this.food.filter(a => a !== id)
      } else {
        this.food.push(id)
      }
    } else {
      if (this.isAllFoodPreference == false && this.matchesForm.value.food_preference == false) {
        this.isAllFoodPreference = true;
        this.f.food_preference.setValue(true)
        this.food = ['Veg', 'Occasionally Non-Veg', 'Non-Veg', 'Eggetarian', 'Pescatarian', 'Jain', 'Other', 'Vegan'];
        $('#FoodPreference1').prop('checked', true);
        $('#FoodPreference2').prop('checked', true);
        $('#FoodPreference3').prop('checked', true);
        $('#FoodPreference4').prop('checked', true);
        $('#FoodPreference5').prop('checked', true);
        $('#FoodPreference6').prop('checked', true);
        $('#FoodPreference7').prop('checked', true);
        $('#FoodPreference8').prop('checked', true);
      } else {
        this.food = []
        this.isAllFoodPreference = false;
        this.f.food_preference.setValue(false)
        $('#FoodPreference1').prop('checked', false);
        $('#FoodPreference2').prop('checked', false);
        $('#FoodPreference3').prop('checked', false);
        $('#FoodPreference4').prop('checked', false);
        $('#FoodPreference5').prop('checked', false);
        $('#FoodPreference6').prop('checked', false);
        $('#FoodPreference7').prop('checked', false);
        $('#FoodPreference8').prop('checked', false);
      }
    }
    if (this.food.length >= 8) {
      this.isAllFoodPreference = true;
      this.f.food_preference.setValue(true)
      $('#FoodPreference').prop('checked', true);
    } else {
      this.isAllFoodPreference = false;
      $('#FoodPreference').prop('checked', false);
    }

    if (this.food.toString().includes("Other")) {
      this.isOtherFoodPreference = true;
    } else {
      this.isOtherFoodPreference = false;
    }
    this.ApplyFilters();
  }

  currencyChange() {
    this.checkIncomeRange();
  }

  ApplyFilters() {
    this.loader();
    let data = {
      user_id: parseInt(this.user_id),
      looking_for: this.matchesForm.value.looking_for,
      age_from: parseInt(this.matchesForm.value.age_from),
      age_to: parseInt(this.matchesForm.value.age_to),
      height_from: parseInt(this.matchesForm.value.height_from),
      height_to: parseInt(this.matchesForm.value.height_to),
      weight_from: parseInt(this.matchesForm.value.weight_from),
      weight_to: parseInt(this.matchesForm.value.weight_to),
      living_with_family: this.matchesForm.value.living_with_family,
      family_type: this.familyType,
      marital_status: this.maritalStatus,
      have_children: this.matchesForm.value.have_children,
      religion: this.religion,
      first_language: this.language,
      residency_status: this.residency,
      qualification: this.education,
      working_as: this.profession,
      nature_of_work: this.work,
      income_currency: parseInt(this.matchesForm.value.income_currency),
      income_range_from: parseInt(this.matchesForm.value.income_range_from),
      income_range_to: parseInt(this.matchesForm.value.income_range_to),
      food_preference: this.food,
      sort_by: this.sortBy,
      state_name: this.selectedStates,
      isAllCountrySelected: this.isAllCountrySelected,
      allCountrySelected: this.allCountrySelected,
      isOtherCountrySelected: this.isOtherCountrySelected,
      otherCountrySelected: this.otherCountrySelected,
      selectedCountry: this.selectedcountry,
      isAllMaritalStatus: this.isAllMaritalStatus,
      isAllReligion: this.isAllReligion,
      isAllLanguage: this.isAllLanguage,
      isAllResidencyStatus: this.isAllResidencyStatus,
      isAllEducation: this.isAllEducation,
      isAllNatureOfWork: this.isAllNatureOfWork,
      isAllProfession: this.isAllProfession,
      isAllFoodPreference: this.isAllFoodPreference,
      itemPerPage: this.itemPerPage,
      pageNo: this.pageNo,
      isOtherMaritalStatus: this.isOtherMaritalStatus,
      isOtherReligion: this.isOtherReligion,
      isOtherLanguage: this.isOtherLanguage,
      isOtherResidencyStatus: this.isOtherResidencyStatus,
      isOtherEducation: this.isOtherEducation,
      isOtherNatureOfWork: this.isOtherNatureOfWork,
      isOtherProfession: this.isOtherProfession,
      isOtherFoodPreference: this.isOtherFoodPreference,
      annual_income: this.annualIncome,
    }

    this.renderPagination = false;
    this.inboxLoader1 = true;
    this.inboxLoader = true;

    this.filtersSaved = data
    localStorage.setItem('savedfilter', JSON.stringify(this.filtersSaved));
    this.test(data)
    this.myProfileService.searchPartner(data).subscribe(
      (result) => {
        if (result.status) {
          this.scroller.scrollToAnchor("viewToTop");
          this.matches = result.pages.data;
          let matchesLenth = this.matches.length;
          this.TotalRecord = result.pages.total;
          this.renderPagination = true;
          this.matchedProfilesCount = this.TotalRecord;
          this.shortlistedProfilesCount = result.shortlistedProfilesCount;
          this.favouriteProfilesCount = result.favouriteProfilesCount;
          this.viewedByProfilesCount = result.viewedByProfilesCount;
          this.currentPage1 = result.pages.current_page;
          this.lastPage = result.pages.last_page;
          // console.log(matchesLenth)
          localStorage.setItem('matchedProfilesCount', result.pages.total)

          if (this.TotalRecord >= this.itemPerPage) {
            this.showPagination = true
          } else {
            this.showPagination = false
          }
        } else {
          this.inboxLoader = false;
          this.inboxLoader1 = false;
        }
        this.inboxLoader = false;
        this.inboxLoader1 = false;
      }, (error) => {                              //Error callback
        this.inboxLoader = false;
        this.inboxLoader1 = false;
      })
    this.matchesLoader = false;
  }

  searchPartner(data) {
    this.filtersSaved = data
    this.test(data)
    this.myProfileService.searchPartner(data).subscribe(
      (result) => {
        if (result.status) {
          this.scroller.scrollToAnchor("viewToTop");
          this.matches = result.pages.data;
          let matchesLenth = this.matches.length;
          this.TotalRecord = result.pages.total;
          this.renderPagination = true;
          this.matchedProfilesCount = this.TotalRecord;
          this.shortlistedProfilesCount = result.shortlistedProfilesCount;
          this.favouriteProfilesCount = result.favouriteProfilesCount;
          this.viewedByProfilesCount = result.viewedByProfilesCount;
          this.currentPage1 = result.pages.current_page;
          this.lastPage = result.pages.last_page;
          // console.log(matchesLenth)
          localStorage.setItem('matchedProfilesCount', result.pages.total)

          if (this.TotalRecord >= this.itemPerPage) {
            this.showPagination = true
          } else {
            this.showPagination = false
          }
          //  
        } else {
          this.inboxLoader = false;
          this.inboxLoader1 = false;
        }
        this.inboxLoader = false;
        this.inboxLoader1 = false;

      }, (error) => {                              //Error callback
        this.inboxLoader = false;
        this.inboxLoader1 = false;
      })
    this.matchesLoader = false;
  }

  public handlePage(e: any) {
    this.prePage = true;
    this.pageNo = e.pageIndex + 1;
    this.itemPerPage = e.pageSize;
    this.ApplyFilters();
  }

  getId(event) {
    this.selectId = parseInt(event.target.id)
  }

  actionSelected(event: any, profile_id: any) {
    let std = event.target.id
    if (std == "add_to_shortlist") {
      let pload = {
        user_id: this.user_id,
        profile_id: profile_id,
        action: "add_to_shortlist",
      }
      this.myProfileService.addToShortlist(pload).subscribe(
        (result) => {
          if (result.status) {
            this.toastrService.success(result.message);
            this.ApplyFilters();
          } else {
            this.toastrService.error(result.message);
          }
        })
    } else if (std == "block_profile") {
      let pload = {
        user_id: this.user_id,
        profile_id: profile_id,
        action: "block_profile",
      }
      this.myProfileService.actionOnProfile(pload).subscribe(
        (result) => {
          if (result.status) {
            this.toastrService.success(result.message);
            this.ApplyFilters();
          } else {
            this.toastrService.error(result.message);
          }
        })
    } else if (std == "block_and_report_profile") {
      let pload = {
        user_id: this.user_id,
        profile_id: profile_id,
        action: "block_and_report_profile",
      }
      this.myProfileService.reportProfile(pload).subscribe(
        (result) => {
          if (result.status) {
            this.toastrService.success(result.message);
            this.ApplyFilters();
          } else {
            this.toastrService.error(result.message);
            this.notifService.error('Image not uploaded', result.message, this.options);
          }
        })
    }
  }

  onSelectAllCountry() {
    if (this.matchesForm.value.allCountry) {
      this.isAllCountrySelected = true;
      this.allCountrySelected = [38, 231, 13, 44, 101, 75, 82];
      this.selectedcountry = [38, 231, 13, 44, 101, 75, 82];
      this.isOtherCountrySelected = true;
      $('#AllCountry').prop('checked', true);
      $('#India').prop('checked', true);
      $('#China').prop('checked', true);
      $('#France').prop('checked', true);
      $('#Germany').prop('checked', true);
      $('#Canada').prop('checked', true);
      $('#America').prop('checked', true);
      $('#Australia').prop('checked', true);
      $('#otherCountries').prop('checked', true);
    } else {
      this.isAllCountrySelected = false;
      this.allCountrySelected = [];
      this.selectedcountry = [];
      this.isOtherCountrySelected = false;
      $('#AllCountry').prop('checked', false);
      $('#India').prop('checked', false);
      $('#China').prop('checked', false);
      $('#France').prop('checked', false);
      $('#Germany').prop('checked', false);
      $('#Canada').prop('checked', false);
      $('#America').prop('checked', false);
      $('#Australia').prop('checked', false);
      $('#otherCountries').prop('checked', false);
    }

    if (this.selectedcountry.length >= 7) {
      if (this.isOtherCountrySelected == true) {
        this.isAllCountrySelected = true;
        this.f.allCountry.setValue(true);
        $('#AllCountry').prop('checked', true);
      } else {
        this.f.allCountry.setValue(false);
        this.isAllCountrySelected = false;
        $('#AllCountry').prop('checked', false);
      }
    } else {
      this.f.allCountry.setValue(false);
      this.isAllCountrySelected = false;
      $('#AllCountry').prop('checked', false);
    }
    this.ApplyFilters();
  }

  onSelectOtherCountry() {
    if (this.matchesForm.value.other_country == true) {
      this.isOtherCountrySelected = true
      //this.otherCountrySelected =[]
    } else {
      this.isOtherCountrySelected = false
      this.isAllCountrySelected = false;
      $('#AllCountry').prop('checked', false);
      // this.otherCountrySelected =[]
    }

    if (this.selectedcountry.length >= 7) {

      if (this.isOtherCountrySelected == true) {
        this.f.allCountry.setValue(true);
        this.isAllCountrySelected = true;
        $('#AllCountry').prop('checked', true);
      } else {
        this.f.allCountry.setValue(false);
        this.isAllCountrySelected = false;
        $('#AllCountry').prop('checked', false);
      }
    } else {
      this.f.allCountry.setValue(false);
      this.isAllCountrySelected = false;
      $('#AllCountry').prop('checked', false);
    }
    this.ApplyFilters();
  }


  onSelectState(event) {
    let Sid = event.target.id
    if (this.selectedStates.some(a => a === Sid)) {
      this.selectedStates = this.selectedStates.filter(a => a !== Sid)
    } else {
      this.selectedStates.push(Sid)
    }
    this.ApplyFilters();
    // console.log(this.selectedStates)
  }

  onSelectCountry(event) {
    let id = parseInt(event.target.value)
    if (this.selectedcountry.some(a => a === id)) {
      this.selectedcountry = this.selectedcountry.filter(a => a !== id)
      this.otherCountrySelected.push(id)
    } else {
      this.otherCountrySelected = this.otherCountrySelected.filter(a => a !== id)
      this.selectedcountry.push(id)
    }

    if (this.selectedcountry.length == 1) {
      this.showStates = false; //true
      this.authService.getStateList({ id: this.selectedcountry[0] }).subscribe(
        (result) => { this.states = result.data; })
    } else if (this.selectedcountry.length >= 7) {
      // console.log(this.matchesForm.value.allCountry,"saagar11",this.selectedcountry.length)

      if (this.isOtherCountrySelected == true) {
        this.isAllCountrySelected = true;
        this.f.allCountry.setValue(true);
        $('#AllCountry').prop('checked', true);
      } else {
        this.f.allCountry.setValue(false);
        this.isAllCountrySelected = false;
        $('#AllCountry').prop('checked', false);
      }
    } else {
      this.isAllCountrySelected = false;
      this.f.allCountry.setValue(false);
      $('#AllCountry').prop('checked', false);
      this.showStates = false;
      this.selectedStates = [];
    }
    this.ApplyFilters();
    // console.log(this.selectedcountry)
  }

  checkIncomeRange() {
    let from = Number(this.matchesForm.value.income_range_from);
    let to = Number(this.matchesForm.value.income_range_to);
    if (this.matchesForm.value.income_range_from && this.matchesForm.value.income_range_to && this.matchesForm.value.income_currency) {
      if (from <= to) {
        this.incomeRange = false;
        if (this.currencyCode != "" || this.currencyCode > 0) {
          this.annualIncome = "SpecifyRange";
          this.handleRange();
        }
      } else {
        this.incomeRange = true;
      }
    }
  }

  sortedBy(event, current_sort: any = 'default') {
    // console.log("sorted")
    this.sort_by = event.target.id
    if (this.sortBy != event.target.id) {
      this.sortBy = event.target.id;
      this.current_sort = current_sort;

      this.ApplyFilters();
      this.toastrService.success('List sorted!');
    }
  }

  onToggle() {
    if (this.toggle == false) {
      this.toggle = true;
      this.advanceFilter = false;
      this.UnCheck();
      // console.log(this.toggle, 'this.toggle2')
    } else {
      this.toggle = false;
      this.advanceFilter = true;
      this.pageNo = 1;
      this.getPartnerDetail();
    }
  }

  FilterOff() {
    //  user_id: this.user_id,
    // looking_for: this.detail.looking_for,
    // age_from: 18,
    // age_to: 90,
    // height_from: 100,
    // height_to: 230,
    // weight_from: 70,
    // weight_to: 250,
    // living_with_family: "",
    //  income_currency: null,
    // income_range_from: null,
    // income_range_to: null,
    // have_children: "",
    //this.f.user_id.setValue(this.user_id);
    this.f.looking_for.setValue(this.detail.looking_for)
    this.f.age_from.setValue(this.detail.age_from);
    this.f.age_to.setValue(this.detail.age_to);
    this.f.height_from.setValue(this.detail.height_from);
    this.f.height_to.setValue(this.detail.height_to);
    this.f.weight_from.setValue(this.detail.weight_from);
    this.f.weight_to.setValue(this.detail.weight_to);
    this.f.living_with_family.setValue("")
    $('#familyTypeSidebar3').prop('checked', true)
    this.value = this.detail.age_from;
    this.f.income_range_from.setValue(this.detail.income_range_from);
    this.f.income_range_to.setValue(this.detail.income_range_to);
    this.minValue4 = this.detail.income_range_from;
    this.maxValue4 = this.detail.income_range_to;
    this.ApplyFilters();
  }

  UnCheck() {
    // family type
    this.annualIncome = "Open To All"
    this.f.income_range_from.setValue("");
    this.f.income_range_to.setValue("");
    this.f.income_currency.setValue("");
    this.minValue4 = 1;
    this.maxValue4 = 9999999999;
    this.f.age_from.setValue(18);
    this.f.age_to.setValue(90);
    this.f.height_from.setValue(100);
    this.f.height_to.setValue(230);
    this.f.weight_from.setValue(32);
    this.f.weight_to.setValue(114);
    this.value = 18;
    this.highValue = 90;
    this.minValue = 100;
    this.maxValue = 230;
    this.minValue3 = 32;
    this.maxValue3 = 114;
    this.f.living_with_family.setValue("")
    $('#familyTypeSidebar3').prop('checked', true)
    this.f.have_children.setValue("")
    $('#HaveChildrenSidebar3').prop('checked', true)
    this.familyType = []
    this.f.family_type.setValue(false);
    this.isAllFamilyType = false;
    $('#familyTypeSidebar2').prop('checked', false);
    $('#familyTypeSidebar3').prop('checked', false);
    $('#familyTypeSidebar4').prop('checked', false);
    $('#familyTypeSidebar5').prop('checked', false);

    // marital status 
    this.maritalStatus = []
    this.isAllMaritalStatus = false;
    this.f.marital_status.setValue(false);
    $('#MaritalStatus2').prop('checked', false);
    $('#MaritalStatus3').prop('checked', false);
    $('#MaritalStatus4').prop('checked', false);
    $('#MaritalStatus5').prop('checked', false);
    $('#MaritalStatus6').prop('checked', false);

    // religion
    this.isAllReligion = false;
    ///this.isOtherReligion = false;
    this.religion = []
    this.f.religion.setValue(false);
    this.isOtherReligion = false;
    $('#Religion1').prop('checked', false);
    $('#Religion2').prop('checked', false);
    $('#Religion3').prop('checked', false);
    $('#Religion4').prop('checked', false);
    $('#Religion5').prop('checked', false);
    $('#Religion6').prop('checked', false);
    $('#Religion7').prop('checked', false);
    $('#Religion8').prop('checked', false);
    $('#Religion9').prop('checked', false);

    // residency 
    this.residency = []
    this.f.residency_status.setValue(false)
    this.isAllResidencyStatus = false;
    this.isOtherResidencyStatus = false;
    $('#SidebarResidencyFilter2').prop('checked', false);
    $('#SidebarResidencyFilter3').prop('checked', false);
    $('#SidebarResidencyFilter4').prop('checked', false);
    $('#SidebarResidencyFilter5').prop('checked', false);
    $('#SidebarResidencyFilter6').prop('checked', false);
    $('#SidebarResidencyFilter7').prop('checked', false);
    // languages
    this.language = []
    this.isAllLanguage = false
    this.f.languages.setValue(false);
    this.isOtherLanguage = false;
    $('#LanguagePreference1').prop('checked', false);
    $('#LanguagePreference2').prop('checked', false);
    $('#LanguagePreference3').prop('checked', false);
    $('#LanguagePreference4').prop('checked', false);
    $('#LanguagePreference5').prop('checked', false);
    $('#LanguagePreference6').prop('checked', false);
    $('#LanguagePreference7').prop('checked', false);
    $('#LanguagePreference8').prop('checked', false);
    $('#LanguagePreference9').prop('checked', false);

    //education

    this.education = []
    this.isAllEducation = false;
    this.f.education.setValue(false);
    this.isOtherEducation = false;
    $('#Qualification1').prop('checked', false);
    $('#Qualification2').prop('checked', false);
    $('#Qualification3').prop('checked', false);
    $('#Qualification4').prop('checked', false);
    $('#Qualification5').prop('checked', false);
    $('#Qualification6').prop('checked', false);
    $('#Qualification7').prop('checked', false);
    $('#Qualification8').prop('checked', false);
    $('#Qualification9').prop('checked', false);

    // work 
    this.work = []
    this.isAllNatureOfWork = false;
    this.f.nature_of_work.setValue(false);
    this.isOtherNatureOfWork = false;
    $('#natureSidebarBtn1').prop('checked', false);
    $('#natureSidebarBtn2').prop('checked', false);
    $('#natureSidebarBtn3').prop('checked', false);
    $('#natureSidebarBtn4').prop('checked', false);
    $('#natureSidebarBtn5').prop('checked', false);
    $('#natureSidebarBtn6').prop('checked', false);
    $('#natureSidebarBtn7').prop('checked', false);

    //profession
    this.profession = []
    this.isAllProfession = false;
    this.f.profession_area.setValue(false);
    this.isOtherProfession = false;
    $('#ProfessionArea1').prop('checked', false);
    $('#ProfessionArea2').prop('checked', false);
    $('#ProfessionArea3').prop('checked', false);
    $('#ProfessionArea4').prop('checked', false);
    $('#ProfessionArea5').prop('checked', false);
    $('#ProfessionArea6').prop('checked', false);
    $('#ProfessionArea7').prop('checked', false);
    $('#ProfessionArea8').prop('checked', false);

    // food
    this.food = []
    this.isAllFoodPreference = false;
    this.f.food_preference.setValue(false)
    this.isOtherFoodPreference = false;
    $('#FoodPreference1').prop('checked', false);
    $('#FoodPreference2').prop('checked', false);
    $('#FoodPreference3').prop('checked', false);
    $('#FoodPreference4').prop('checked', false);
    $('#FoodPreference5').prop('checked', false);
    $('#FoodPreference6').prop('checked', false);
    $('#FoodPreference7').prop('checked', false);
    $('#FoodPreference8').prop('checked', false);

    // country 
    this.isAllCountrySelected = false;
    this.allCountrySelected = [];
    this.selectedcountry = [];
    this.isOtherCountrySelected = false;
    $('#AllCountry').prop('checked', false);
    $('#India').prop('checked', false);
    $('#China').prop('checked', false);
    $('#France').prop('checked', false);
    $('#Germany').prop('checked', false);
    $('#Canada').prop('checked', false);
    $('#America').prop('checked', false);
    $('#Australia').prop('checked', false);
    $('#otherCountries').prop('checked', false);

    // state
    this.f.allCountry.setValue(false);
    this.isAllCountrySelected = false;
    $('#AllCountry').prop('checked', false);

    this.ApplyFilters();

  }



  EditPartnerPrefrence() {
    localStorage.setItem('selectedTabpp', 'PartnerPrefrence');
  }
  //this.router.navigate(['detail/ +''+ profile.user_id']);
  // total pages -

  test(data) {
    this.myProfileService.searchPartners(data).subscribe(
      (result) => {
        // console.log("result", result)
      })
  }

  ApplyFilterToggleFalse() {
    let data = {
      user_id: this.user_id,
      looking_for: this.detail.looking_for,
      age_from: 18,
      age_to: 90,
      height_from: 100,
      height_to: 230,
      weight_from: 32,
      weight_to: 114,
      living_with_family: "",
      family_type: [],
      marital_status: [],
      have_children: "",
      religion: [],
      first_language: [],
      qualification: [],
      working_as: [],
      income_currency: null,
      income_range_from: null,
      income_range_to: null,
      sort_by: "",
      state_name: [],
      isAllCountrySelected: false,
      allCountrySelected: [],
      isOtherCountrySelected: false,
      otherCountrySelected: [],
      selectedCountry: [],
      isAllMaritalStatus: false,
      isAllReligion: false,
      isAllLanguage: false,
      isAllResidencyStatus: false,
      isAllEducation: false,
      isAllNatureOfWork: false,
      isAllProfession: false,
      isAllFoodPreference: false,
      itemPerPage: 8,
      pageNo: 1,
      isOtherMaritalStatus: false,
      isOtherReligion: false,
      isOtherLanguage: false,
      isOtherResidencyStatus: false,
      isOtherEducation: false,
      isOtherNatureOfWork: false,
      isOtherProfession: false,
      isOtherFoodPreference: false,
      annual_income: "OpenToAll"
    }
    this.inboxLoader1 = true;
    this.inboxLoader = true;
    this.test(data)
    this.myProfileService.searchPartner(data).subscribe(
      (result) => {
        if (result.status) {
          this.matches = result.pages.data;
          this.TotalRecord = result.pages.total;
          this.matchedProfilesCount = this.TotalRecord;
          this.shortlistedProfilesCount = result.shortlistedProfilesCount;
          this.favouriteProfilesCount = result.favouriteProfilesCount;
          this.viewedByProfilesCount = result.viewedByProfilesCount;
          localStorage.setItem('matchedProfilesCount', result.pages.total)
          if (this.TotalRecord >= this.itemPerPage) {
            this.showPagination = true
          } else {
            this.showPagination = false
            this.inboxLoader = false;
            this.inboxLoader1 = false
          }
        } else {
          this.inboxLoader = false;
          this.inboxLoader1 = false
        }
      })
    this.inboxLoader = false;
    this.inboxLoader1 = false;
  }

  blockReportId(user) {
    if (user.label == "Other") {
      this.otherReason = true;
      this.reportReason = ""
    } else {
      this.otherReason = false
      this.reportReason = user.label
    }
  }

  blockAndReportNow() {
    let data = {
      user_id: this.user_id,
      profile_id: this.profileId,
      action: "block_and_report",
      reason: this.reportReason
    };
    this.myProfileService.doActionOnRequests(data).subscribe(
      (result: any) => {
        if (result.status) {
          this.toastrService.success(result.message);
          this.ApplyFilters();

        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        // unused code
        // this.ChatId = 0;
        // this.toastrService.error();
      }
    );
  }

  userBlockReport(user) {
    this.profileId = user.user_id
    this.clear();
  }

  clear() {
    // console.log("before ", this.reportReason)
    this.reportReason = ""
    this.exceedLimit = false;
    this.otherReason = false
    let ele = document.getElementsByName("Serious");
    // before sonar
    // for (var i = 0; i < ele.length; i++) {
    //   var element = ele[i] as HTMLInputElement;
    //   element.checked = false;
    // }
    //after sonar
    for (const element of Array.from(ele) as HTMLInputElement[]) {
      element.checked = false;
    }
  }

  wordCounter(event) {
    let para = this.wordCountForm?.value?.description;
    if (para.trim().length > 0) {
      this.wordCount = this.texts ? this.texts.nativeElement.value.split(/\s+/) : 0;
      this.words = this.wordCount ? this.wordCount.length : 0;
      if (this.words >= 21 && event.code != 'Backspace' && event.code != 'Delete') {
        this.wordError();
        // before sonarqube
        // const pattern = /[a-zA-Z0-9&_\.!,-\s()"']/g;
        const pattern = /[a-zA-Z0-9&_.!,\-\s()"']/g;
        if (!pattern.test(event.target.value)) {
          event.preventDefault();
          return false;
        } else {
          // before sonarqube
          // event.target.value = event.target.value.replace(/[a-zA-Z0-9&_\.!,-\s()']/g, "");
          // this.tellUsMore = this.tellUsMore.replace(/  +/g, ' ');
          event.target.value = event.target.value.replace(/[a-zA-Z0-9&_.,!\-\s()']/g, "");
          this.tellUsMore = this.tellUsMore.replace(/ {2,}/g, ' ');
          this.wordCountForm.patchValue({ 'description': this.tellUsMore.trim() + ' ' });
          this.words = this.tellUsMore.split(/\s+/).length
          event.preventDefault();
          return false;
        }
      } else {
        this.wordError();
        this.tellUsMore = this.wordCountForm?.value?.description;
      }
    } else {
      this.wordError();
      this.words = 0;
    }
  }

  wordError() {
    if (this.words > 20) {
      this.exceedLimit = true;
    } else {
      this.exceedLimit = false;
    }
  }
  pasteNumeric6(event) {
    setTimeout(() => {
      let para = this.wordCountForm?.value?.description;
      if (para.trim().length > 0) {
        this.wordCount = this.texts ? this.texts.nativeElement.value.split(/\s+/) : 0;
        this.words = this.wordCount ? this.wordCount.length : 0;
        if (this.words >= 21) {
          this.wordCount = this.wordCount.slice(0, 20);
          this.words = this.wordCount ? this.wordCount.length : 0;
          this.wordCountForm.patchValue({ 'description': this.wordCount.join(" ") });
          this.wordError();
        }
      } else {
        this.wordError();
        this.words = 0;
      }
    }, 500);
  }

  pos = "";
  compare() {
    let pre = this.wordCount?.value?.description;
    setTimeout(() => {
      this.pos = this.wordCount?.value?.description;
      if (pre == this.pos) {
        // console.log("change", "itime")
      }
    }, 500);
    if (pre == this.pos) {
      // console.log("change", "out-time")
    }
  }

  saveFilters() {
    let data: any = {
      name: this.filterName,
      filter: this.filtersSaved
    }
    // console.log(data)
  }
}
