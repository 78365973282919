<div class="w-100 bg-maroon" *ngIf="!isLoggedIn">
  <app-header></app-header>
</div>
<div class="page-header">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-caption">
        </div>
      </div>
    </div>
  </div>
</div>


<section class="container my-4 px-3 ">

  <div class="card rounded-5 border border-1 shadow-sm p-4  min_content_height_202 mt-120">
    <div class="my-3">
      <h3 class="text-center fw-bold text-danger">SAFETY TIPS</h3>
    </div>
    <h6 class="fw-bold mb-4 ">Last updated March 06, 2023</h6>

    <p class="lh-lg font-9-rem ">
      At Eternal Strings our Users safety is of topmost priority. Eternal Strings is a safe platform to meet new people and start meaningful relationships. It is always wise to keep a few safety tips in mind. At Eternal Strings we do not conduct criminal background checks on our users, so it is up to each of our Users to determine if they are comfortable meeting up with someone. However, it is important to remember that if you do experience sexual assault or violence, it is not your fault. Make sure to always be wary of someone who immediately wants to stop communication on the Eternal Strings platform and request to communicate through other ways. Those Users do not have the best intentions.
    </p>

    <p class="lh-lg font-9-rem  mt-3"> Below are some safety steps you need to be mindful of to increase your safety when interacting with others-whether you are interacting in person or virtually. Like any safety tips, these are not a guarantee, but they may help you feel more secure.</p>

    <ol type="A" class="lh-lg font-9-rem ">
      <li class="mt-2">
        Never share your financial or personal information, such as your Social Insurance Number, Credit Card Number, or
        your Bank Account information, mother’s maiden name, home or work address or other private information that can
        be used to access your financial information. If you are a parent, don’t share your children’s names, where they
        go to school or their age or gender.
      </li>
      <li class="mt-2">
        Never send any money, gift cards to anyone.
      </li>
      <li class="mt-2">
        Eternal Strings never calls any Users about their bank account information
      </li>

      <li class="mt-2">
        Eternal Strings will not ask for your full financial details over the telephone. An agent may ask for the last 4
        digits of your payment method to verify that you are the rightful owner of the account. Under no circumstances
        will you ever be charged for customer care operations.
      </li>
      <li class="mt-2">
        When you access your account in public or on a shared computer, make sure you are aware of your surroundings, so that others cannot view your password or other personal information. Make sure to pick a strong password, Eternal Strings will never email you asking for your username and password information. If you receive an email or phone call asking for your account information, report it immediately.
      </li>
      <li class="mt-2">
        Never include your last name, home address email address, place of work, phone number or any other identifying information on your profile or email messages during early communication. Always use Eternal Strings secure messaging process to protect your identity.
      </li>
      <li class="mt-2">
        Do your research before meeting a match in person. Pay attention to the details your match shares with you. If something does not feel right, always trust your gut instincts and immediately stop communicating with them. Feel free to report your misgivings to Customer care.
      </li>
      <li class="mt-2">
        Do not share personal information until you feel completely safe in doing so.
      </li>

    </ol>


    <h5 class="mt-4 p-1 fw-bold"> ALWAYS USE YOUR BEST JUDGMENT </h5>

    <ul class="lh-lg font-9-rem ">
      <li>
        Avoid connecting with profiles that make you feel unsafe.
      </li>
      <li>
        Block and report suspicious users.
      </li>
      <li>
        Check out your potential date on social media.
      </li>
      <li>
        A phone or video call can be a useful screening tool before meeting in person.
      </li>
    </ul>


    <p class="lh-lg font-9-rem mt-3  mt-3">
      Here are a few examples of some common red flags and stories scammers may use to build trust and manipulate others user in an unhealthy way:
    </p>
    <ul  class="lh-lg font-9-rem ">
      <li>
        Asks for financial assistance because they have a sudden personal crisis
      </li>
      <li>
        Claims to be recently widowed with children
      </li>
      <li>
        Disappears suddenly from the site and then reappears under a different name.
      </li>
      <li>
        Always blames someone else for the troubles in his or her life.
      </li>
      <li>
        Tells inconsistent stories
      </li>
      <li>
        Asks for home or work address because they want to send flowers or gifts.
      </li>
      <li>
        When asked a specific question, gives vague answers.
      </li>
      <li>
        Asks inappropriate questions.
      </li>
      <li>
        Pressures you to provide your phone number to talk outside of Eternal Strings site or app.
      </li>
      <li>
        Messages are in poorly written language and grammar but claims to have a high level of education.
      </li>
      <li>
        Want you to compromise your principles
      </li>
      <li>
        Overly romantic and complimentary very early in your communication.
      </li>
      <li>
        Wants the relationship to move faster than what you are comfortable with
      </li>
    </ul>



    <p class="lh-lg font-9-rem mt-3  mt-3">
      Report the following behavior immediately:
    </p>
    <ul  class="lh-lg font-9-rem ">
      <li>
        If they are a minor.
      </li>
      <li>
        Asks for any photographs other than the ones you have posted.
      </li>
      <li>
        Asks for financial assistance.
      </li>
      <li>
        Attempts to intimidate or threaten you in any way.
      </li>
      <li>
        Sends you offensive or harassing messages.
      </li>
      <li>
        Attempts to sell you any products or services.
      </li>
      <li>
        Profile seems fake.
      </li>
    </ul>


    <h5 class="mt-4 p-1 fw-bold"> WHEN MEETING IN PERSON </h5>

    <p class="lh-lg font-9-rem mt-3  mt-3">
      Meeting your match for the first time in person is exciting, so enjoy this special moment while making your safety
      your priority. Here are some tips to give you some peace of mind when you meet your match:
    </p>
    <ul  class="lh-lg font-9-rem ">
      <li>
        Video chat before you meet them in person. This is a good way to make sure your match is who they claim to be on
        their profile. If they resist a video call, that is suspicious activity and a red flag
      </li>
      <li>
        For the first date always meet in a public place. Do not meet in any isolated location or at their home. If at
        any time you feel unsafe or threatened during this meeting call your local enforcement agency (911 for Canada
        and USA). When meeting for the first time. Both of you would be more comfortable meeting at a coffee shop or a
        restaurant with plenty of other people around
      </li>
      <li>
        Take a screenshot of the profile picture and personal information send it to a friend or family member. Let at
        least one friend or family member know when and where you are planning to meet. If the first meeting is good and
        you go for another meeting, make sure to tell someone and let them know of the new location. Another good thing
        to practice is to text or call a friend partway through the date and when you get home to check in.
      </li>
      <li>
        Always control your own transportation to and from the first meeting so that you can leave when you want to in
        case you are uncomfortable. Have easy access to your emergency numbers and make sure your phone is fully charged
        or bring a portable battery with you.
      </li>
      <li>
        Know where your drink is coming from and never leave it unattended. Know your limit when drinking and don’t feel
        pressured into having more than you want.
      </li>
      <li>
        Your safety is most important. If you feel unsafe or uncomfortable during your meeting you can cut the first
        meeting short, un-match, block and report your match after the meeting. This will keep them from being able to
        access your profile in the future.
      </li>
      <li>
        If Eternal Strings removes or closes an account of a match for inappropriate behavior, falsified information, or suspicious activity, you may receive.
      </li>
      <li>
        If you have a negative experience, help is available. <strong> If you feel you are in immediate danger or need emergency assistance, call 911 (US or Canada) or your local law enforcement agency.</strong>
      </li>
    </ul>



  </div>


</section>


<app-footer></app-footer>