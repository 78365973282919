import { Component, OnInit } from '@angular/core';
import { Title/*, Meta */} from '@angular/platform-browser';
import { TokenService } from '../../shared/token.service';



@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {
  isLoggedIn: any;

  constructor(private titleService: Title,
    public token: TokenService,
    ) { }

  ngOnInit(): void {
    window.scroll(0,0);
    this.titleService.setTitle('Disclaimer  | eternalstrings.com');
    this.isLoggedIn = this.token.isLoggedIn();

  }

}
