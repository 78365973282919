import { Component, OnInit } from '@angular/core';
import { MyProfileService } from '../services/my-profile.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { NotifService } from '../services/notif.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Title/*, Meta */} from '@angular/platform-browser';
import { Router/*, ActivatedRoute, RouterLink */} from '@angular/router';

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.css']
})
export class CompareComponent implements OnInit {
  camparedProfiles: any;
  //user_id: any;
  compareLoader:boolean = false
  options: any;
  user_id: number;
  profile_ids: any = '';
 ShowFavouriteBtn :boolean= false;
  constructor(
    private myProfileService :MyProfileService,
    private cookieService: CookieService,
    private toastrService: ToastrService,
    private notifService: NotifService,
    private spinner: NgxSpinnerService,
    private titleService: Title,
    private router: Router,
   // private route: ActivatedRoute,
  ) { }
 
  ngOnInit(): void {
    this.titleService.setTitle('Compare Matches | EternalStrings.com');
    this.user_id = parseInt(this.cookieService.get('user_id'));
    let ids:any = localStorage.getItem("profile_ids")
    if(ids && !!ids) {
      this.profile_ids = ids.split(',');
      if(this.profile_ids.length>1) {
        this.compareProfiles()
      } else {
        // unused code
        // console.log('Minimum Two Profiles Required For Match',this.profile_ids);
        //this.toastrService.error('Message!', 'Two pofile requreid for match!');
        //alert("two pofile requreid!!");
        //this.toastrService.warning('', 'Two pofile requreid for match!!!');
        confirm("At least two pofile requreid for match!");
        window.close()
      }
    }
  }
  
  compareProfiles() {
    this.compareLoader=true;
    let data = {
      user_id:this.user_id,
      profile_ids:this.profile_ids
       //this.cookieService.get("user_id"),localStorage.getItem("key");
     }
    this.myProfileService.compareProfiles(data).subscribe((result:any) => { if(result.status) {
    this.camparedProfiles = result.camparedProfiles;
          
        } else {
          //unused code
          // this.notifService.error('Image not Uploaded', result.message,this.options);
        }
        this.compareLoader=false;
        // unused code
          //console.log(this.camparedProfiles);
          //this.spinner.hide();
       },
        (error) => {
    
       })
  }

  removeItem(profile){
    if(this.camparedProfiles.length > 2){
      let ids:any = localStorage.getItem("profile_ids")
      if(ids && !!ids) {
        this.profile_ids = ids.split(',');
        this.profile_ids = this.profile_ids.filter(function(value, index) {
          return  value != profile.user_id;
        });
        localStorage.setItem("profile_ids",this.profile_ids.toString()) 
      } 
      this.camparedProfiles = this.camparedProfiles.filter(function(value, index) {
        return  value.user_id != profile.user_id;
      });
    }else{
      this.toastrService.warning('','Minimum Two Profiles required for match!!!');
    }
  }

  addToFavoriteList(profile_id:any) {
    let pload = {
      user_id : this.cookieService.get('user_id'),
      profile_id : profile_id,
    }
    this.cookieService.get('user_id');
      this.myProfileService.addToFavoriteList(pload).subscribe(
      (result) => {
      if(result.status) {
        this.toastrService.success('Successfully!', 'Profile added to My Favourite !');
        this.ShowFavouriteBtn = true;
      }else {
        this.toastrService.error('Message!', 'Profile already added to My Favourite !');
      }
    })
  }
 
  goNow(){
    this.router.navigate(['matches-favourite']);
  }

}
