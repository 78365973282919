import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  dropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings = {};

  dropdownList2: any = [];
  selectedItems2: any = [];
  dropdownSettings2 = {};
  // unused code
  // constructor() { }

  ngOnInit(): void {
    this.dropdownList = [
      { id: 1, itemName: 'America' },
      { id: 2, itemName: 'Australia' },
      { id: 3, itemName: 'Canada' },
      { id: 4, itemName: 'France' },
      { id: 5, itemName: 'India' },
      { id: 7, itemName: 'Oman' },
      { id: 8, itemName: 'UAE' },
      { id: 9, itemName: 'UK' },
      { id: 10, itemName: 'Other' },
    ];
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3,
      classes: 'myclass custom-class dropdown-edit',
      includeSelectAllOption: false,
      limitSelection: 1,
      showCheckbox: true,
    };

    this.dropdownList2 = [
      { id: 1, itemName: 'Andhra Pradesh' },
      { id: 2, itemName: 'Arunachal Pradesh' },
      { id: 3, itemName: 'Assam' },
      { id: 4, itemName: 'Bihar ' },
      { id: 5, itemName: 'Chhattishgarh' },
      { id: 7, itemName: 'Goa' },
      { id: 8, itemName: 'Gujarat' },
      { id: 9, itemName: 'Haryana' },
      { id: 11, itemName: 'Himachal Pradesh' },
      { id: 12, itemName: 'Jammu & Kashmir' },
      { id: 13, itemName: 'Jharkhand' },
      { id: 14, itemName: 'Karnataka' },
      { id: 15, itemName: 'Kerala' },
      { id: 10, itemName: 'Madhya Pradesh' },
    ];
    this.selectedItems2 = [];
    this.dropdownSettings2 = {
      singleSelection: false,
      text: 'Select',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 3,
      classes: 'myclass custom-class dropdown-edit',
      includeSelectAllOption: false,
      limitSelection: 1,
      showCheckbox: true,
    };
  }

}
