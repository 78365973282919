import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class StepdoneService {
  private registerFiveUrl =`${environment.apiURL}/auth/registerFive`;
  private getUserdetailsUrl =`${environment.apiURL}/auth/getUserDetails`;


  constructor(
    private http: HttpClient,
    private titleService: Title 

  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.titleService.setTitle('Step 1');
  
  }

  registerFive(user: any): Observable<any> {
    return this.http.post(this.registerFiveUrl, user);
  }
  getUserDetails(user:any): Observable<any> {
    return this.http.post(this.getUserdetailsUrl, user);
  }
}