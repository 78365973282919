import { Component, OnInit } from '@angular/core';
import { Title/*, Meta */} from '@angular/platform-browser';
import { TokenService } from '../../shared/token.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {
  isLoggedIn: any;

  constructor(private titleService: Title,
    public token: TokenService,
    ) { }

  ngOnInit(): void {
    window.scroll(0,0);
    this.titleService.setTitle('Privacy Policy | eternalstrings.com');
    this.isLoggedIn = this.token.isLoggedIn();
  }

}
