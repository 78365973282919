<div class="overflow-x-hidden">

  <div class="container p-3 mx-width-800 mt-93">
    <form #stepDoneNgForm="ngForm" [formGroup]="stepDoneForm" (submit)="stepDone()">

      <div class="row py-5">
        <div class="col-12">
          <h1 class="fw-bold text-center display-2"> A Short Personality Description </h1>
        </div>

        <div class="col-12 px-4">
          <label for="about" class="form-label mb-2 py-1 fw-bold h6">Tell us more</label>
        </div>
        <div class="col-12 px-4 mb-4">
          <input class="form-check-input" type="hidden" id="Self1" [formControlName]="'user_id'">

          <textarea class="w-100 rounded border p-2 border-2" id="wordCount" #text cols="30" rows="16"
            [formControlName]="'description'" [ngClass]="{
            'is-invalid': submitted && f.description.errors }"
            placeholder="
            &#x2022; Be honest and authentic in your personality description.
            &#x2022; Avoid exaggerating or embellishing your traits.
            &#x2022; Focus on describing your unique characteristics and how they shape your perspective on life.
            &#x2022; Mention your interests and hobbies.
            &#x2022; Use positive and upbeat language.
            &#x2022; Highlight your strengths and what makes you unique.
            &#x2022; Mention your career, education, or any notable achievements."
            (paste)="pasteNumeric6($event)" (change)="compare()"
            (keypress)="wordCounter($event)" (keyup.backspace)="wordCounter($event)" autocomplete="" maxlength="1000">
          </textarea>

          <div class="form-label mb-2 py-1 fw-bold text-danger" *ngIf="exceedLimit"> You cannot enter more than the maximum of 100 words</div>
          <p class="text-muted p-xsm">Max 100 Words. </p>
          <p class="text-muted p-xsm">Entered- {{words}} Words</p>

        </div>

        <div *ngIf="submitted && f.description.errors">
          <div class="form-label mb-2 py-1 fw-bold text-danger">Write something</div>
        </div>

        <div class="col-6 mt-5">
          <a href="javascript:void(0);" routerLink="/stepTwo">
            <button type="button" class="w-120 btn py-2 rounded-3 fs-5 fw-bold btn-danger">Previous</button></a>
        </div>
        <div class="col-6 text-end mt-5">
          <a href="javascript:void(0);">
            <button type="submit" class="btn w-120 py-2 rounded-3 fs-5 fw-bold btn-danger">Submit</button></a>
        </div>

      </div>
    </form>
  </div>
</div>

<app-login-footer></app-login-footer>