import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addSpaceAfterComma'
})
export class AddSpaceAfterCommaPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;

    // Check if the value contains a comma
    if (value.includes(',')) {
      // Split the string by commas
      const parts = value.split(',');

      // Trim each part and join with comma and space
      const result = parts.map(part => part.trim()).join(', ');

      return result;
    } else {
      return value; // Return the original value if it doesn't contain a comma
    }
  }

}
