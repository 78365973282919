<div class="mt-3" style>
  <p class="fw-bold text-light">&nbsp;</p>
</div>
<div class="mt-5" style>
  <p class="fw-bold text-light">&nbsp;</p>
</div>

<div class="page" id="printDiv">
  <div class="subpage" id="printDiv11">
    <div style="
        display: grid;
        grid-template-rows: 0% 87% 13%;
        height: 100%;
        font-family: math;
      ">
      <div class="grid-item">
        <div style="width: 100%; position: relative">
          <div style="
              position: absolute;
              top: 0;
              z-index: 0;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            ">
            <img style="width: 100%" src="/assets/images/Banner-2-with-Logo.png" alt="" />
          </div>
          <div style="
              display: flex;
              justify-content: start;
              padding-top: 2rem;
              position: relative;
              width: 100%;
            ">
            <div style="width: 50%; display: flex; justify-content: center">
              <div style="
                  width: 10rem;
                  height: 10rem;
                  border-radius: 50%;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: aliceblue;
                  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
                    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
                ">
                <img [src]="croppedImage" width="100%" alt="assets/img/defaul.svg"/>
              </div>
            </div>
            <div style="
              position: absolute;
              top: 65%;
              width: 100%;
              text-align: center;
              padding: 10px;
              font-size: 14px;
              font-weight: 600;
            ">
              <span style="
                padding: 7px 20px;
                border-radius: 30px;
                color: #fff;
                background-color: #811719;
                font-weight: 600;
                box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
                  rgba(0, 0, 0, 0.23) 0px 6px 6px;
                font-family: system-ui;
                text-transform: uppercase;
              ">{{this.displayName}}</span>
            </div>
          </div>

        </div>
      </div>

      <div class="grid-item" style="width: 100%; margin-top: 12rem">
        <div style="
            display: grid;
            grid-template-columns: 50% 50%;
            padding-top: 10px;
          ">
          <div class="grid-item" style="
              padding: 0 0.5rem;
              border-right: 1px solid lightgray;
              line-height: 20px;
            ">
            <p style="
                font-size: 0.9rem;
                font-weight: 600;
                color: #811719;
                text-transform: uppercase;

                margin-bottom: 0.5rem;
                margin-top: 0.8rem;
                width: fit-content;
                border-bottom: 3px solid #c1c1c1;
              ">
              About Me
            </p>

            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Name:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all">{{this.displayName}}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Age:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all">{{this.age}} Years</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Height:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all">
                {{ myProfileDetail?.height }} cm
                ({{ myProfileDetail?.height | heightConverter : "Ft" }})</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Weight:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all"> {{ myProfileDetail?.weight }} kg ({{
            myProfileDetail?.weight| weightConverter : "Pounds"
            }}
                lb)</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Preferred Language:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span> {{ myProfileDetail?.languages }}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
               border-bottom: 1px solid darkgray;
              ">
              <span style="font-weight: 600">Marital Status:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all">{{ myProfileDetail?.marital_status }}</span>
            </div>
            <p style="
                font-size: 0.9rem;
                font-weight: 600;
                color: #811719;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
                margin-top: 0.8rem;
                width: fit-content;
                border-bottom: 3px solid #c1c1c1;
              ">
              Basic & Lifestyle
            </p>

            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
               border-bottom: 1px solid darkgray;
              ">
              <span style="font-weight: 600">Food Preference:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all">{{ myProfileDetail?.food_preference }}</span>
            </div>

            <p style="
                font-size: 0.9rem;
                font-weight: 600;
                color: #811719;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
                margin-top: 0.8rem;
                width: fit-content;
                border-bottom: 3px solid #c1c1c1;
              ">
              Religious Views
            </p>

            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
               border-bottom: 1px solid darkgray;
              "> <span style="font-weight: 600">Religion:</span>
              <span style="
                border-bottom: 1px dashed #b5b5b5;
                flex: auto;
                height: 2px;
              "></span>
              <span>{{ myProfileDetail?.religion }}</span>
            </div>

          </div>
          <div class="grid-item" style="padding: 0 0.5rem; line-height: 20px">
            <p style="
              font-size: 0.9rem;
              font-weight: 600;
              color: #811719;
              text-transform: uppercase;
              margin-bottom: 0.5rem;
              margin-top: 0.8rem;
              width: fit-content;
              border-bottom: 3px solid #c1c1c1;
            ">
              Astro Details
            </p>

            <div style="
              display: flex;
              gap: 10px;
              font-size: 0.9rem;
              margin-bottom: 0.3rem;
              padding-bottom: 10px;
              justify-content: space-between;
              align-items: center;
             border-bottom: 1px solid darkgray;
            ">
              <span style="font-weight: 600">Country of Birth:</span>
              <span style="
                border-bottom: 1px dashed #b5b5b5;
                flex: auto;
                height: 2px;
              "></span>
              <span style="word-wrap: break-word; word-break: break-all"> {{myProfileDetail?.country_of_birth}}</span>
            </div>
            <p style="
                font-size: 0.9rem;
                font-weight: 600;
                color: #811719;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
                margin-top: 0.8rem;
                width: fit-content;
                border-bottom: 3px solid #c1c1c1;
              ">
              Family Details
            </p>

            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
               border-bottom: 1px solid darkgray;
              "> <span style="font-weight: 600">Living with Family:</span>
              <span style="
                border-bottom: 1px dashed #b5b5b5;
                flex: auto;
                height: 2px;
              "></span>
              <span> {{myProfileDetail?.living_with_family}}</span>
            </div>
            <p style="
                font-size: 0.9rem;
                font-weight: 600;
                color: #811719;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
                margin-top: 0.8rem;
                width: fit-content;
                border-bottom: 3px solid #c1c1c1;
              ">
              Educational & Professional Details
            </p>

            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Education:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all"> {{myProfileDetail?.qualification}}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
               border-bottom: 1px solid darkgray;
              ">
              <span style="font-weight: 600">Nature of Work:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all">{{myProfileDetail?.nature_of_work}}</span>
            </div>
            <p style="
                font-size: 0.9rem;
                font-weight: 600;
                color: #811719;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
                margin-top: 0.8rem;
                width: fit-content;
                border-bottom: 3px solid #c1c1c1;
              ">
              Your Location
            </p>

            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Country:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all">{{myProfileDetail?.living_in_country}}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">State/Province:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all">{{myProfileDetail?.state_name}}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
               border-bottom: 1px solid darkgray;
              ">
              <span style="font-weight: 600">City:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all">{{myProfileDetail?.current_city}}</span>
            </div>

          </div>
        </div>
        <div style="display: grid; grid-template-columns: 100%; padding: 0 10px;">
          <p style="
            font-size: 0.9rem;
            font-weight: 600;
            color: #811719;
            text-transform: uppercase;
            margin-bottom: 0.5rem;
            width: fit-content;
            border-bottom: 3px solid #c1c1c1;
          ">
            Personality Description
          </p>

          <div style="
            display: flex;
            gap: 10px;
            font-size: 0.9rem;
            margin-bottom: 0.3rem;
            padding-bottom: 10px;
           border-bottom: 1px solid darkgray;
          ">
            <span style="font-weight: 600; font-size: 12px;">{{myProfileDetail?.description}}</span>
          </div>
        </div>
      </div>
      <div style="
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url(/assets/images/Biodata-Footer.png);
          display: flex;
          align-items: end;
          justify-content: center;
        ">
        <h6 style="text-align: center; font-weight: 600; color: #fff">
          eternalstrings.com
        </h6>
      </div>
    </div>
  </div>
</div>
<div class="page" id="printDiv">
  <div class="subpage" id="printDiv12">
    <div style="
        display: grid;
        grid-template-rows: 0 87% 13%;
        height: 100%;
        font-family: math;
      ">

      <!-- secound part start -->
      <div class="grid-item">
        <div style="width: 100%; position: relative">
          <div style="
              position: absolute;
              top: 0;
              z-index: 0;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            ">
            <img style="width: 100%" src="/assets/images/Banner-2-with-Logo.png" alt="" />
          </div>
          <div style="
              display: flex;
              justify-content: start;
              padding-top: 2rem;
              position: relative;
              width: 100%;
            ">
            <div style="width: 50%; display: flex; justify-content: center">
              <div style="
                  width: 10rem;
                  height: 10rem;
                  border-radius: 50%;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: aliceblue;
                  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
                    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
                ">
                <img [src]="croppedImage" width="100%" alt="SagarBhai" />
              </div>
            </div>
            <div style="
              position: absolute;
              top: 80%;
              width: 100%;
              text-align: center;
              padding: 10px;
              font-size: 14px;
              font-weight: 600;
            ">
              <span style="
                padding: 7px 20px;
                border-radius: 30px;
                color: #fff;
                background-color: #811719;
                font-weight: 600;
                box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
                  rgba(0, 0, 0, 0.23) 0px 6px 6px;
                font-family: system-ui;
                text-transform: uppercase;
              ">Partner Preference</span>
            </div>
          </div>

        </div>
      </div>
      <div class="grid-item" style="width: 100%; margin-top: 12rem">

        <div style="
            display: grid;
            grid-template-columns: 50% 50%;
            padding-top: 10px;
          ">
          <div class="grid-item" style="
              padding: 0 0.5rem;
              border-right: 1px solid lightgray;
              line-height: 20px;
            ">
            <p style="
                font-size: 0.9rem;
                font-weight: 600;
                color: #811719;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
                margin-top: 0.8rem;
                width: fit-content;
                border-bottom: 3px solid #c1c1c1;
              ">
              Basic & Lifestyle
            </p>

            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Age:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all"> {{ partnerDetail?.age_from }} years -
                {{ partnerDetail?.age_to }} years</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Height:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all"> {{ partnerDetail?.height_from }} cm ({{ partnerDetail?.height_from |
                            heightConverter : "Ft" }}) -
                {{ partnerDetail?.height_to }} cm ({{ partnerDetail?.height_to |
                            heightConverter : "Ft" }})</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Weight:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all"> {{ partnerDetail?.weight_from }} kg ({{ partnerDetail?.weight_from
                            | weightConverter : "Pounds"
                            }} lb) - {{ partnerDetail?.weight_to }} kg ({{
                            partnerDetail?.weight_to | weightConverter :
                            "Pounds"
                            }} lb )</span>
            </div>

            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Preferred Language:</span><span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span><span
                style="word-wrap: break-word; word-break: break-all">{{ partnerDetail?.languages}}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Food Preference:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span><span> {{ showFood}}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
             
              ">
              <span style="font-weight: 600">Marital Status :</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span><span>{{showMarital}}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Have Children:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span> {{partnerDetail?.have_children}}</span>
            </div>
            <p style="
                font-weight: 600;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                border-bottom: 2px solid #d1d1d1;
                width: fit-content;
              ">
              Do you prefer a partner
            </p>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Who smokes?</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span><span> {{ partnerDetail?.do_smoke }}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Who drinks alcohol?</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span> {{ partnerDetail?.do_drink }}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
               border-bottom: 1px solid darkgray;
              ">
              <span style="font-weight: 600">Who has pets?</span><span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span><span> {{ partnerDetail?.have_pet }}</span>
            </div>
            <p style="
                font-size: 0.9rem;
                font-weight: 600;
                color: #811719;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
                margin-top: 0.8rem;
                width: fit-content;
                border-bottom: 3px solid #c1c1c1;
              ">
              Religious Views
            </p>

            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
               border-bottom: 1px solid darkgray;
              ">
              <span style="font-weight: 600">Religion:</span>
              <span style="
                border-bottom: 1px dashed #b5b5b5;
                flex: auto;
                height: 2px;
              "></span>
              <span>{{showReligion}}</span>
            </div>
          </div>
          <div class="grid-item" style="padding: 0 0.5rem; line-height: 20px">
            <p style="
                font-size: 0.9rem;
                font-weight: 600;
                color: #811719;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
                margin-top: 0.8rem;
                width: fit-content;
                border-bottom: 3px solid #c1c1c1;
              ">
              Family Details
            </p>

            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Father's Status:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span> {{ partnerDetail?.father_occupation }}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Mother's Status:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span> {{ partnerDetail?.mother_occupation }} </span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Family Location:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all">
                {{partnerFamilyStateName}},
                <span>{{partnerFamilyCountryName}}</span>
              </span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
               border-bottom: 1px solid darkgray;
              ">
              <span style="font-weight: 600">Living with Family:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span> {{partnerDetail?.living_with_family }}</span>
            </div>
            <p style="
                font-size: 0.9rem;
                font-weight: 600;
                color: #811719;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
                margin-top: 0.8rem;
                width: fit-content;
                border-bottom: 3px solid #c1c1c1;
              ">
              Educational & Professional Details
            </p>

            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Education:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all">{{showEducation}}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Nature of Work:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span style="word-wrap: break-word; word-break: break-all">{{showNatureOfWork}}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Profession Area:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span>{{showProfession}}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
               border-bottom: 1px solid darkgray;
              ">
              <span style="font-weight: 600">Annual Income: </span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span>{{ partnerDetail?.annual_income }}</span>
            </div>
            <p style="
                font-size: 0.9rem;
                font-weight: 600;
                color: #811719;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
                margin-top: 0.8rem;
                width: fit-content;
                border-bottom: 3px solid #c1c1c1;
              ">
              Partner Location
            </p>

            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">Country</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span> {{ partnerLocationCountryName }}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
              ">
              <span style="font-weight: 600">State/Province:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span>{{ partnerLocationStateName}}</span>
            </div>
            <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
               border-bottom: 1px solid darkgray;
              ">
              <span style="font-weight: 600">City:</span>
              <span style="
                  border-bottom: 1px dashed #b5b5b5;
                  flex: auto;
                  height: 2px;
                "></span>
              <span> {{ partnerDetail?.city_name }}</span>
            </div>

          </div>
        </div>
        <div style="display: grid; grid-template-columns: 100%; padding: 0 10px;">
          <p style="
                font-size: 0.9rem;
                font-weight: 600;
                color: #811719;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
                width: fit-content;
                border-bottom: 3px solid #c1c1c1;
              ">
            Partner Expectation
          </p>

          <div style="
                display: flex;
                gap: 10px;
                font-size: 0.9rem;
                margin-bottom: 0.3rem;
                padding-bottom: 10px;
               border-bottom: 1px solid darkgray;
              ">
            <span style="font-weight: 600; word-wrap: break-word; font-size: 12px;"> {{ partnerDetail?.description }}
            </span>
          </div>
        </div>
      </div>
      <!-- end -->
      <div style="
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url(/assets/images/Biodata-Footer.png);
          display: flex;
          align-items: end;
          justify-content: center;
        ">
        <h6 style="text-align: center; font-weight: 600; color: #fff">
          eternalstrings.com
        </h6>
      </div>
    </div>
  </div>
</div>
<button type="button" (click)="p2()">print</button>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Alkalami&family=Open+Sans:ital,wght@0,300;0,500;0,600;0,700;1,300;1,700;1,800&family=Oxygen&family=Roboto:wght@100;300;700;900&display=swap");

  .page {
    width: 210mm;
    min-height: 297mm;
    /*/ min-height: auto; */
    padding: 20mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    font-family: "Roboto", sans-serif;
  }

  .subpage {
    border: 2px #811719 solid;
    /*/ height: auto; */
    height: 257mm;
    outline: 2cm #ffeaea solid;
    background-color: #fff;
  }

  @page {
    size: A4;
    margin: 0;
  }

  @media print {

    html,
    body {
      width: 210mm;
      height: 297mm;
    }

    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }
  }

  @font-face {
    font-family: Raavi;
    panose-1: 2 11 5 2 4 2 4 2 2 3;
  }

  @font-face {
    font-family: "Cambria Math";
    panose-1: 2 4 5 3 5 4 6 3 2 4;
  }

  @font-face {
    font-family: Calibri;
    panose-1: 2 15 5 2 2 2 4 3 2 4;
  }

  @font-face {
    font-family: "Open Sans";
    panose-1: 2 11 6 6 3 5 4 2 2 4;
  }

</style>

<div class="modal fade" id="upgradeModal" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">
      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <div id="logoDiv">
          <a class="d-flex justify-content-center" style="margin-left: 9%; display: block">
            <img src="https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/logo.png" class="mx-auto"
              alt="ETERNALSTRINGS LOGO" width="130px" /></a>
        </div>

        <p class="p-sm mb-4">
          <!-- Upgrade Now to start communicating with {{displayName}}. -->
          <!-- Upgrade now to view the profile of {{upgradeDisplayName}}. -->
          <!-- Click Upgrade Now to View Profile! KamalPreet S  -->
        </p>

        <button class="btn btn-danger rounded-pill" data-bs-dismiss="modal" aria-label="Close" routerLink="/upgrade">
          Upgrade Now
        </button>
      </div>
    </div>
  </div>
</div>
