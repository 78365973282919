import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { TokenService } from '../shared/token.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  private userState = new BehaviorSubject<boolean>(this.token.isLoggedIn()!);
  userAuthState = this.userState.asObservable();
  // headers: { headers: { 'Content-Type': string; }; };
  headers: any;
  constructor(public token: TokenService,
    private http: HttpClient,
    private cookieService: CookieService,
    ) {
    this.headers = {
      headers : {
          'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
      }
  }

  }
  setAuthState(value: boolean) {
    this.userState.next(value);
  }

  logout(data:any) {
    this.headers.headers.authorization = 'Bearer '+this.cookieService.get('auth_token');
    return this.http.post( `${environment.apiURL}/auth/logout`, data, this.headers);
  }
}