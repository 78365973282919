import { Component, OnInit, ViewChild, TemplateRef/*, ViewEncapsulation*/, HostListener, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../shared/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenService } from '../../shared/token.service';
import { AuthStateService } from '../../shared/auth-state.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { NotifService } from '../../services/notif.service';
// unused code
// import { interval } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import * as $ from 'jquery';
import { browserRefresh } from 'src/app/app.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('signInNgForm') signInNgForm: NgForm;
  @ViewChild('customHtml') customHtml: TemplateRef<any>;
  @Output() onDatePicked = new EventEmitter<any>();
  toggleNavbar = true;
  readonly notifyOptions = {
    timeOut: 4000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    // clickIconToClose: true,
    preventDuplicates: true
  };
  txt: string = '';
  options: any;
  private subscription;

  showPassword: boolean = false;
  errorMessage: string;
  errmsg: string;
  errmsgotp: string;
  otpResposce: boolean = true;
  resendBtn: boolean = false;
  verify_by: any = '';
  submitted = false;
  errors: any = null;
  loginRes: any;
  tryAnotherWayLink: boolean = true;
  //submitted : boolean= false;
  signInForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', Validators.required),
    remember_me: new FormControl('')
  })

  otpForm = new FormGroup({
    // email: new FormControl('', [Validators.required]),
    otp: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    device_verified: new FormControl('')
  })



  forgotpasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
  })
  forgotpasswordForm1 = new FormGroup({
    countryCode: new FormControl('', [Validators.required, Validators.maxLength(10)]),
    countryNumberCode: new FormControl(''),
    mobileNo: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(14)]),
  })


  jsonObj: any;
  user_logged_in: boolean;
  username: string;
  pass: any;
  showmodel = false;
  showmodelOtp = false;
  erorrmsg: boolean = false;
  frmdata: any;
  otpfrmdata: any;
  mobileformdata: any;
  countries: any;
  selectedCityName: any;
  phoneCode: any;
  mailsent: boolean = false;
  mailsentmsg: string;
  have_otp_setting: any;
  otplabel: any;
  user_id: any;
  minNumberLength: boolean = false;
  maxNumberLength: boolean = false;
  otpSubmitted: boolean = false;

  encryEmail: any = '';
  encryMobile: any = '';

  // timer
  initialTimeInSeconds: number = 300; // 5 minutes in seconds
  currentTimeInSeconds: number;
  interval: any; // Store the interval reference
  displayTime: string;
  // timer end

  urlchek:any;

  timeoutId = null;
  public browserRefresh: boolean;
  isModalOpen: boolean = false;
  currentURL: string;
  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private token: TokenService,
    private authState: AuthStateService,
    private cookieService: CookieService,
    //  private notificationsService: NotificationsService,
    private notifService: NotifService,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private location: Location,
  ) {
    this.options = {
      clickToClose: true,
      showProgressBar: true,
      theClass: 'er-notification',
      timeOut: 7000,
      preventDuplicates: true
    };;
    this.currentTimeInSeconds = this.initialTimeInSeconds;
    this.displayTime = this.formatTime(this.currentTimeInSeconds);
  }

  navbarfixed: boolean = false;
  @HostListener('window:scroll', ['$event']) onscroll() {
    if (window.scrollY > 0) {
      this.navbarfixed = true;
    }
    else {
      this.navbarfixed = false;
    }
  }

  pageAccess: any = []
  ngOnInit(): void {
    this.urlchek = environment.baseURL;
    window.scroll(0, 0);
    if (this.cookieService.get('user_id') == "") {
      this.user_logged_in = true;
    }
    this.f.email.setValue(this.username);
    this.f.password.setValue(this.pass);
    this.authService.getCountryList().subscribe(
      (result) => {
        this.countries = result.data;
        // before sonar
        // if (this.countries && this.countries[0] && this.countries[0].id) {
        // after sonar
        if (this.countries?.[0].id) {
          this.phoneCode = this.countries[0].id;
          this.forgotpasswordForm1.patchValue({ 'countryCode': this.countries[0].id });
          this.forgotpasswordForm1.patchValue({ 'countryNumberCode': this.countries[0].country_code });
        }
      }
    );

    this.currentURL = this.location.path();
    // console.log('Current URL: ' + this.currentURL, "this.currentURL.includes('password')", this.currentURL.includes('password'));

    this.browserRefresh = browserRefresh;
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    if (browserRefresh) {
      this.timeoutId = setTimeout(() => {
        // console.log('refreshed?:', browserRefresh, "timeout called ");
        if (!this.isModalOpen && this.currentURL != "/registerDone" && !this.currentURL.includes("password")) {
          $('#openModal2').click();
        } else {
          console.log("else part")
          $('#closeModal2').click();
        }
      }, 5000);
    }
  }

  ngOnDestroy() {
    console.log("before page route change ")
    clearTimeout(this.timeoutId);
  }

  OnchangeCountryCode(event) {
    this.forgotpasswordForm1.patchValue({ 'countryCode': event.id });
    this.forgotpasswordForm1.patchValue({ 'countryNumberCode': event.country_code });
  }

  getCountryList() {
    this.authService.getCountryList().subscribe(
      (result) => {
        this.countries = result.data;
      }
    );
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }

  get f() {
    return this.signInForm.controls;
  }

  get otpf() {
    return this.otpForm.controls;
  }

  get s() {
    return this.forgotpasswordForm1.controls
  }

  changemodel() {
    if (this.showmodel == true) {
      this.showmodel = false
      this.errors = '';
    } else {
      this.showmodel = true
      this.errors = '';
    }
  }

  changemodel1() {
    this.isModalOpen = true;
    document.getElementById("deleteModalupgade").click();
    console.log(this.showmodel, "sagar")
    this.showmodel = false
  }


  forgotpassword() {
    const obscureEmail = email => {
      const [name, domain] = email.split("@");
      return `${name[0]}${new Array(name.length - 1).join("*")}${name[name.length - 1]}@${domain}`;
    };

    const email = obscureEmail(this.forgotpasswordForm.value.email);
    this.authService.forgotPasswordSendMail(this.forgotpasswordForm.value).subscribe(
      (result) => {
        document.getElementById("closeLoginModal")!.click();
        this.toastrService.success("An email has been sent to " + email + ".", "Email Sent", this.options,)
      },
      (error) => {
        this.errors = error.error;
        this.toastrService.error(error.email
        );
      }
    );
  }

  forgotpassword1() {
    const obscureEmail = number => {
      const name = number;
      return `${name[0]}${new Array(name.length - 3).join("*")}${name[name.length - 3]}${name[name.length - 2]}${name[name.length - 1]}`;
    };

    const moNumber = obscureEmail(this.forgotpasswordForm1.value.mobileNo);
    this.submitted = true;
    if (this.forgotpasswordForm1.invalid) {
      return;
    }
    this.mobileformdata = {
      mobileNo: this.forgotpasswordForm1.value.mobileNo,
      countryNumberCode: this.forgotpasswordForm1.value.countryNumberCode,

    }
    this.authService.forgotPasswordSendSMS(this.mobileformdata).subscribe(
      (result) => {
        document.getElementById("closeLoginModal")!.click();
        this.toastrService.success("An sms has been sent to " + moNumber + ".", "SMS Sent", this.options)
      },
      (error) => {
        this.errors = error.error;
        this.toastrService.error(error.email);
      }
    );
  }

  close() {
    console.log("modal close")
    this.isModalOpen = false;
    this.signInForm.reset();
    this.forgotpasswordForm.reset();
    this.forgotpasswordForm1.reset();
    this.errors = ""
    this.submitted = false;
    this.mailsentmsg = ""
  }


  keyPressNumeric(event) {
    let inp = String.fromCharCode(event.keyCode);
    if (/[0-9#*-]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  
  login() {
    this.submitted = true;
    this.frmdata = {
      email: this.signInForm.value.email,
      password: this.signInForm.value.password,
      remember_me: this.signInForm.value.remember_me
    }
    if (this.signInForm.value.remember_me == true) {
      localStorage.setItem('Name', this.signInForm.value.email);
      localStorage.setItem('token', "credentials.token");
    } else {
      sessionStorage.setItem('Name', this.signInForm.value.email);
      sessionStorage.setItem('token', " credentials.token");
    }

    const device_verified_for = localStorage.getItem('device_verified_for');
    if (device_verified_for != null && device_verified_for) {
      this.frmdata.device_verified_for = device_verified_for;
    } else {
      this.frmdata.device_verified_for = 0
    }

    this.authService.signin(this.frmdata).subscribe(
      (result) => {
        this.loginRes = result;
        this.verify_by = result.verify_by;
        this.user_id = result.user.id;
        if (result.have_otp_setting == true) {

          this.showmodelOtp = true;
          console.log("You are in otp box");
          this.startTimer();
          this.encryMobile = result.enc_mobile;
          this.encryEmail = result.enc_email;
        } else if (result.have_otp_setting == false) {
          this.showmodelOtp = false;
          this.responseHandler(result, this.signInForm.value.remember_me);
          this.commonService.updateLoginStatus(true);

          if (!!result.page) {
            let register_id = result.page.register_done
            localStorage.setItem('register_id', register_id);
            if (register_id == 0) {
              console.log(register_id, "msgt");

              this.router.navigate(['register']);
            } 
            if (register_id == '') {
              this.router.navigate(['register']);
            }
            else if (register_id == 1) {
              this.router.navigate(['registerNxt']);
            }
            else if (register_id == 2) {
              this.router.navigate(['step']);
            }
            else if (register_id == 3) {
              this.router.navigate(['stepTwo']);
            }
            else if (register_id == 4) {
              this.router.navigate(['stepDone']);
            }
            else if (register_id == 5) {
              this.router.navigate(['ProfilePage1']);
            }
            else if (register_id == 6) {
              this.router.navigate(['PartnerPreferences']);
            }
            else if (register_id == 7) {
              localStorage.setItem('Member', 'Free');
              this.router.navigate(['myProfile']);
              localStorage.setItem('have_otp_setting', 'have_otp_setting');
              localStorage.setItem('shortListModal', 'true');
            }
            else {
              localStorage.setItem('Member', 'Free');
              this.router.navigate(['myProfile']);
            }
            this.cookieService.set('oldUser', 'true', 365);
          } else {
            console.log('profile deleted perhaps!')
            this.router.navigate(['register']);
          }

          this.authState.setAuthState(true);
          this.otpForm.reset();

          document.getElementById("closeLoginModal")!.click();
          this.errmsgotp = " "
        }
      },
      (error) => {
        this.errors = error.error;
        this.errmsg = error.error;
      },
      () => {
      });
    this.errmsg = " "

  }
  // Handle response
  responseHandler(data: any, remember_me) {
    this.token.handleData(data.access_token, data.user.id, remember_me);
  }

  otpLogin() {

    this.otpSubmitted = true;
    this.otpfrmdata = {
      user_id: this.user_id,
      // email : this.otpForm.value.email,
      otp_number: this.otpForm.value.otp,
      device_verified: this.otpForm.value.device_verified
      // remember_me:this.signInForm.value.remember_me
    }
    this.authService.verifyByOtp(this.otpfrmdata).subscribe(
      (res) => {
        console.log("otp verify")
        if (res.status) {
          this.otpResposce = true;
          this.resendBtn = true;
          this.responseHandler(this.loginRes, this.signInForm.value.remember_me);
          this.commonService.updateLoginStatus(true);
          console.log(this.loginRes.user.id);

          if (!!this.loginRes.page) {
            if (this.otpForm.value.device_verified) {
              localStorage.setItem('device_verified_for', this.user_id);
            } else {
              // unused code
              // localStorage.setItem('device_verified_for', this.user_id);
            }
            let register_id = this.loginRes.page.register_done
            localStorage.setItem('register_id', register_id);
            if (register_id == 0) {
              console.log(register_id, "msgt");

              this.router.navigate(['register']);
            } 
            if (register_id == '') {
              this.router.navigate(['register']);
            }
            else if (register_id == 1) {
              this.router.navigate(['registerNxt']);
            }
            else if (register_id == 2) {
              this.router.navigate(['step']);
            }
            else if (register_id == 3) {
              this.router.navigate(['stepTwo']);
            }
            else if (register_id == 4) {
              this.router.navigate(['stepDone']);
            }
            else if (register_id == 5) {
              this.router.navigate(['ProfilePage1']);
            }
            else if (register_id == 6) {
              this.router.navigate(['PartnerPreferences']);
            }
            else if (register_id == 7) {
              localStorage.setItem('Member', 'Free');
              this.router.navigate(['myProfile']);
              localStorage.setItem('have_otp_setting', 'have_otp_setting');
            }
            else {
              localStorage.setItem('Member', 'Free');
              this.router.navigate(['myProfile']);
            }
            this.cookieService.set('oldUser', 'true', 365);
          } else {
            console.log('profile deleted perhaps!')
            this.router.navigate(['register']);
          }

          this.otpForm.reset();
          // Close modal
          document.getElementById("closeLoginModal")!.click();

        } else if (res.status == false) {
          this.otpResposce = false;
          this.resendBtn = false;
          this.errmsgotp = res.message;
          this.signInForm.reset();
        }
      },
      (error) => {
        this.errors = error.error;
        this.errmsgotp = error.error;
        console.log("otp not verify")
        this.otpSubmitted = true;
      }, () => {
        this.authState.setAuthState(true);
      });
  }

  ResendOneTimePassword() {
    let data = {
      user_id: this.user_id,
    }
    this.authService.ResendOneTimePassword(data).subscribe(
      (res) => {
        if (!!res.status) {
          this.resendBtn = true;
          this.otpForm.reset();
          this.currentTimeInSeconds = this.initialTimeInSeconds;
          this.otpResposce = true;
        }
      },
      (error) => {
        this.errors = error.error;
        this.errmsgotp = error.error;
        console.log(" not getting otp ")
        this.otpResposce = false;
        this.toastrService.error("error caught in component"
        );
      });
  }

  tryAnotherWay() {
    let data = {
      user_id: this.user_id,
      otp_login: true
    }
    this.authService.tryAnotherWay(data).subscribe(
      (res) => {
        if (!!res.status) {
          this.otpForm.reset();
          this.currentTimeInSeconds = this.initialTimeInSeconds;
          this.tryAnotherWayLink = false;
          this.verify_by = 'email';
          this.resendBtn = false;
        }
      },
      (error) => {
        this.errors = error.error;
        this.errmsgotp = error.error;
        console.log(" not getting otp ")
        this.otpResposce = false;
        this.toastrService.error("error caught in component"
        );
      });
  }

  keyPressNumericMobile(event) {
    this.otpResposce = true;
    const pattern = /\D/g;
    if (this.otpForm.value.otp.length < 6) {
      this.minNumberLength = true;
      this.maxNumberLength = false;
    } else if (this.otpForm.value.otp.length == 6) {
      this.minNumberLength = false;
      this.maxNumberLength = false;
      this.otpLogin();
    } else if (this.otpForm.value.otp.length > 6) {
      this.minNumberLength = false;
      this.maxNumberLength = true;
    }


    if (!pattern.test(event.target.value) && (this.otpForm.value.otp.length == 6)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumericMobileTo(event) {
    this.otpResposce = true;
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.otpForm.patchValue({ 'mobileNo': newText });
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.currentTimeInSeconds--;

      if (this.currentTimeInSeconds < 0) {
        this.pauseTimer();
        this.otpForm.reset();
        this.showmodelOtp = false;
        this.currentTimeInSeconds = this.initialTimeInSeconds;
      }

      this.displayTime = this.formatTime(this.currentTimeInSeconds);
    }, 1000); // Update the timer every 1 second (1000 milliseconds)
  }

  pauseTimer() {
    clearInterval(this.interval); // Clear the interval to pause the timer
  }

  resetTimer() {
    this.pauseTimer();
    this.currentTimeInSeconds = this.initialTimeInSeconds;
    this.displayTime = this.formatTime(this.currentTimeInSeconds);
  }

  formatTime(timeInSeconds: number): string {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds.toString();
    return `${formattedMinutes}:${formattedSeconds}`;
  }

}
