<div class="banner position-relative hero-image">
  <app-header *ngIf="!isLoggedIn" class="position-relative z-index-9999"></app-header>

  <ng-container>

    <video #myVideo (click)="toggleVideo()" style="width: 100%;" class="vid-home-mt">
      <source src="assets/video/banner3.mp4" type="video/mp4">
      <!-- <link rel="preload" as="video" href="assets/video/banner3.mp4"/> -->
    </video>
  </ng-container>

  <!-- top-0 position-absolute w-100 -->
  <button type="button"
    class="d-none form-btn pulse-button position-absolute d-block d-lg-none btn btn-danger mx-auto my-auto rounded-circle"
    data-bs-toggle="modal" data-bs-target="#searchBox">
    <i class="fas fa-plus fs-2"></i>
  </button>

  <button style="display:none;" id="openModal2" data-bs-target="#deleteModal1" data-bs-toggle="modal"></button>

  <div class="row d-lg-block h-100 position-absolute w-100 bottom-0 m-0 py-4">

    <div class="col-12 ps-sm-3 h-100 d-flex align-items-center">

      <div
        class="w-100 row border border-0 border-danger rounded-3 shadow-sm bg-transparent p-0 m-0 mx-width-250  d-none d-md-block">
        <form [formGroup]="lookingforSearchForm" class="form-class">
          <div class="col-12">
            <h5 class="text-center text-white fw-bold looking">Search For Profiles</h5>
          </div>
          <div class="col-12 p-sm mb-1">
            <label for="gender" class="form-label fw-bold text-shadow text-white mb-1">Looking For
              A</label>
            <select class="form-select p-sm mb-1 fw-bold" aria-label="Default select example" required
              formControlName="looking_for">
              <option class="fw-bold" value selected> Select</option>
              <option value="Male" class="fw-bold"><strong> Male</strong></option>
              <option value="Female" class="fw-bold"> <strong> Female</strong></option>
            </select>
          </div>

          <div class="col-12 p-sm mb-2">
            <label for="age" class="form-label fw-bold text-white text-shadow mb-1">Age</label>
            <div class="d-flex p-sm align-items-center justify-content-between">
              <div class="flex-auto">
                <select class="form-select p-sm fw-bold mb-0" aria-label="Default select example" required
                  formControlName="age_from" (change)="onChangeAgeFrom($event)">
                  <option class="fw-bold" value selected>Select</option>
                  <option class="fw-bold" value="{{age.id}}" *ngFor="let age of this.age_from">{{age.id}}</option>

                </select>
              </div>
              <div class="w-25-h-25 p-1 border-1 border-white border text-white mx-1 rounded-circle text-center"> To
              </div>
              <div class="flex-auto">
                <select class="form-select p-sm fw-bold mb-0" aria-label="Default select example" required
                  formControlName="age_to" (change)="checkAge()">
                  <option class="fw-bold" value selected>Select</option>
                  <option class="fw-bold" value="{{ age.id }}" *ngFor="let age of
                this.age_to">
                    {{ age.id }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-12 p-sm mt-1">
            <label for="religion" class="text-white fw-bold form-label text-shadow mb-1">Religion</label>
            <select class="form-select p-sm fw-bold" aria-label="Default select example" required
              formControlName="religion">
              <option class="fw-bold" value selected>Select</option>
              <option class="fw-bold" value="Buddhist">Buddhist</option>
              <option class="fw-bold" value="Christian">Christian</option>
              <option class="fw-bold" value="Hindu">Hindu</option>
              <option class="fw-bold" value="Jain">Jain</option>
              <option class="fw-bold" value="Muslim">Muslim</option>
              <option class="fw-bold" value="Parsi">Parsi</option>
              <option class="fw-bold" value="Sikh">Sikh</option>
              <option class="fw-bold" value="No Religion">No Religion</option>
              <option class="fw-bold" value="Other">Other</option>
            </select>
          </div>

          <div class="col-12 p-sm mt-1">
            <label for="country" class="text-white fw-bold form-label text-shadow mb-1">Country</label>
            <ng-select [items]="this.countries" class="custom-ng-select" bindLabel="fullnumber" bindValue="id"
              [(ngModel)]="phoneCode" formControlName="country" placeholder="Select Country" dropdownPosition="auto"
              [virtualScroll]="true" ngModel>
              <ng-template ng-label-tmp let-item="item">
                <img height="20" width="20"
                  src="assets/images/flag/Flag_of_{{item.country_name | replaceSpaceWithUnderscore}}.svg"
                  style="margin-right:5px;" alt="Description of image" title="Country"/>
                <b> {{item.country_name}}</b>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <img height="20" width="20"
                  src="assets/images/flag/Flag_of_{{item.country_name | replaceSpaceWithUnderscore}}.svg"
                  style="margin-right:5px;"  alt="Description of image" title="Country"/>
                <b> {{item.country_name}}</b>
              </ng-template>
            </ng-select>
          </div>

          <div class="col-12 mt-2">
            <button type="submit" class="btn btn-danger fw-bold active p-sm w-100" (click)="submit()">Lets get
              started</button>
            <button type="submit" style="display:none;" id="openModalButton"
              class="btn btn-danger fw-bold active p-sm w-100" data-bs-toggle="modal"
              data-bs-target="#welcomeModal">Open Modal</button>
          </div>
        </form>
      </div>
    </div>
    <div class="col-12 col-sm-10 col-md-8 col-lg-6 mt-auto img-box py-2 d-none d-md-block">
      <div class="text-light text-center blur-black p-2 rounded-3 mx-auto">
        <h1 class="mb-2 mb-md-3">Your perfect life partner awaits you. </h1>
        <h5> Real People, Real Relations </h5>
      </div>
    </div>
  </div>
</div>

<div class="how-work mt-5 pt-5">
  <div class="container">
    <h2 class="text-center mb-0">Find Your Special Someone</h2>
    <div class="d-flex align-items-center justify-content-around flex-wrap py-5">
      <div class="stepper-box m-4 position-relative cursor-pointer" routerLink="/registerDone" title="Sign-Up">
        <div
          class="rounded-10 border d-flex align-items-center border-1 shadow-sm bg-white ps-3 pe-5 py-4 clip-path clip-path-1 position-relative">
          <div class="text-center ms-3">
            <h4 class="mb-2 text-danger cursor-pointer fw-bold hover-scale" href="javascript:void(0)" title="Sign-Up">Sign-Up
            </h4>
            <p class="p-sm">Register for free</p>
          </div>
        </div>
      </div>

      <div class="stepper-box m-4 position-relative cursor-pointer" routerLink="/registerDone" title="Sign-Up">
        <div
          class="rounded-10 border d-flex align-items-center border-1 shadow-sm bg-white ps-3 pe-5 py-4 clip-path clip-path-2 position-relative">
          <div class="text-center ms-3">
            <h4 class="mb-2 cursor-pointer fw-bold text-danger hover-scale" href="javascript:void(0)"> Connect </h4>
            <p class="p-sm">Find your Perfect Match</p>
          </div>
        </div>
      </div>

      <div class="stepper-box m-4 position-relative cursor-pointer" routerLink="/registerDone" title="Sign-Up">
        <div
          class="rounded-10 border d-flex align-items-center border-1 shadow-sm bg-white ps-3 pe-5 py-4 clip-path clip-path-3 position-relative">
          <div class="text-center ms-3">
            <h4 class="mb-2 cursor-pointer text-danger fw-bold hover-scale" href="javascript:void(0)"> Upgrade </h4>
            <p class="p-sm"> Become a Premium or Elite Member </p>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>

<!-- <div class="special-someone pt-3 bg-white pb-4" style="display: none;">
  <div class="container">
    <h2 class="pt-4 text-center">Success Stories</h2>
    <p class="text-center head-p">Beautiful memories</p>
    <div class="seperator-line centered"></div>
    <div class="row mt-5 g-4">
      <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let item of carouselItems;let i = index">
          <ng-template carouselSlide>
            <div class="item">
              <div (click)="openStory(i)" class="col-md-4_rm " data-bs-toggle="modal" data-bs-target="#storiesModal0">
                <div class="card mycard">
                  <div class="ratio-1 overflow-hidden">
                    <img src="{{item.image}}" alt="" class="image-cover card-img-top">
                  </div>
                  <div class="card-body text-center">
                    <h5 class="card-title text-danger hover-scale" data-bs-toggle="modal"
                      data-bs-target="#storiesModal0">{{item.title}}</h5>
                    <a href="javascript:void(0);" class="card-link text-dark">
                      {{item.location}}</a>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
    <div class="row mt-5 g-4" style="display: none;">
      <div class="col-md-4" data-bs-toggle="modal" data-bs-target="#storiesModal">
        <div class="card mycard">
          <div class="ratio-1 overflow-hidden">
            <img src="assets/images/success-wedding/success-wedding-1.JPG" alt="" class="image-cover card-img-top">
          </div>
          <div class="card-body text-center">
            <h5 class="card-title text-danger hover-scale" data-bs-toggle="modal" data-bs-target="#storiesModal">Hethin
              & Kamal</h5>
            <a href="javascript:void(0);" class="card-link text-dark">
              Vancouver, Canada</a>
          </div>
        </div>
      </div>
      <div class="col-md-4" data-bs-toggle="modal" data-bs-target="#storiesModal1">
        <div class="card mycard">
          <div class="ratio-1 overflow-hidden">
            <img src="assets/images/success-wedding/success-wedding-2.JPG" alt="" class="image-cover card-img-top">
          </div>
          <div class="card-body text-center">
            <h5 class="card-title text-danger hover-scale" data-bs-toggle="modal" data-bs-target="#storiesModal1">Mariah
              & Karnvir</h5>
            <a href="javascript:void(0);" class="card-link text-dark">
              Surrey, Canada</a>
          </div>
        </div>
      </div>

      <div class="col-md-4" data-bs-toggle="modal" data-bs-target="#storiesModal3">
        <div class="card mycard">
          <div class="ratio-1 overflow-hidden">
            <img src="assets/images/success-wedding/success-wedding-4.jpeg" alt="" class="image-cover card-img-top">
          </div>
          <div class="card-body text-center">
            <h5 class="card-title text-danger hover-scale">Harminder & Harkirat</h5>

            <a href="javascript:void(0);" class="card-link text-dark"> Surrey, Canada</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<app-success-story></app-success-story>
<!-- <app-honeymoon-destinations></app-honeymoon-destinations> -->


<section id="call-out" class="call-out-main-block cursor-pointer" data-bs-toggle="modal" data-bs-target="#planningModal">
  <div class="parallax"
    style="background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('assets/images/banner-5-v1_webp.webp');">
    <div class="overlay-bg"></div>
    <div class="container text-center">
      <div class="call-out-dtl">
        <h2 class="call-out-heading text-white mb-4"> Start Planning Today </h2>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
<!-- Modal -->

<!---------------------------------------------------------------------------------------------->
<div class="modal fade" id="welcomeModal" tabindex="-1" aria-labelledby="welcomeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
      <div class="modal-header border-0 pt-3 mb-3">
        <!-- <img src="assets/images/logo.png" class="mx-auto" alt="Eternalstrings LOGO" width="130px"> -->
        <img src="assets/images/logo_webp.webp" class="mx-auto" alt="Description of eternalstrings logo" title="ETERNALSTRINGS LOGO" width="130px">

        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closeWelcomeModal"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0">
        <div class="col-12 px-0">
          <div class="mb-3 px-3">
            <p style="margin: 0;">Welcome to ETERNAL STRINGS!</p>
            <p>Create your profile and start finding your life partner.</p>
          </div>
          <div class="row mx-0">
            <div class="col-12 ps-0 text-center">
              <button type="submit" class="btn btn-danger px-3" (click)="goToSignUp()">Sign Up</button>
            </div>
            <div class="col-12 mt-3 py-2 bg-maroon w-100">
              <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                  class="text-light" id="closeWelcomeModal" data-bs-dismiss="modal" aria-label="Close"><i
                    class="fas fa-long-arrow-alt-left"></i> Go to eternalstrings.com</a> </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- .........Destinations madal..... -->
<!-- <div class="modal fade" id="montrealModal" tabindex="-1" aria-labelledby="montrealModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-800 rounded-10 position-relative overflow-hidden">
      <div class="modal-header text-white bg-maroon border-0 pt-3 mb-3 pb-1">
        <h6 class="modal-title">Honeymoon Destinations: Montreal</h6>
        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closeparisModal"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body col-12 mb-3 px-3">
        "Montreal: A Honeymoon Haven"
        <br><br>
        Montreal, Canada's charming European-style city, is a top honeymoon destination. Old Montreal offers picturesque
        cobblestone streets,
        horse-drawn carriages, and beautiful 17th and 18th-century architecture for romantic strolls.
        The city's renowned French-Canadian cuisine can be enjoyed at various romantic restaurants, bistros, and rooftop
        terraces.
        <br><br>
        Cultural experiences are also in abundance with museums, theaters, and art galleries to explore. Outdoor
        activities such as cycling and boat
        rides on the St.
        Lawrence River also offer scenic views for honeymooners. With a mix of romantic ambiance, delicious food, and
        cultural and outdoor activities,
        Montreal is a perfect destination for a romantic and memorable honeymoon.
      </div>
      <div class="modal-footer mt-0 py-0  bg-maroon w-100">
        <div class="col-12 ">
          <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
              class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                class="fas fa-long-arrow-alt-left"></i> Go
              to eternalstrings.com</a> </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="parisModal" tabindex="-1" aria-labelledby="parisModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-800 rounded-10 position-relative overflow-hidden">
      <div class="modal-header text-white bg-maroon border-0 pt-3 mb-3 pb-1">
        <h6 class="modal-title">Honeymoon Destinations: Paris</h6>
        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closeparisModal"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body col-12 mb-3 px-3 overflow-height">
        "Paris: A Honeymoon Destination of Romance and Culture"
        <br><br>
        Paris, the City of Love, is an ideal destination for newlyweds seeking a romantic and cultural honeymoon
        experience. The city offers iconic
        landmarks, art, delicious food, and picturesque parks and gardens that will make your honeymoon unforgettable.
        <br><br>
        One of the must-see landmarks in Paris is the Eiffel Tower. The tower offers a stunning view of the city and is
        the perfect spot for a
        romantic stroll or elevator ride to the top for a panoramic view. Another popular destination is the Louvre
        Museum, home to famous
        masterpieces like the Mona Lisa. Take a stroll through the galleries and admire the art together.
        <br><br>
        Take a romantic boat ride on the Seine River and enjoy the beautiful scenery of the city, from the stunning
        architecture to the charming
        bridges. Paris is also known for its delicious food and wine, treat your partner to a romantic dinner at one of
        the city's many fine-dining
        restaurants or bistros.
        <br><br>
        For a more intimate and romantic experience, take a stroll through the city's many parks and gardens. The
        Luxembourg Gardens is a popular
        spot, known for its beautiful gardens, ponds, and statues. Take a picnic and enjoy the peaceful ambiance of the
        gardens.

      </div>
      <div class="modal-footer mt-2 py-1 bg-maroon w-100">
        <div class="col-12 ">
          <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
              class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                class="fas fa-long-arrow-alt-left"></i> Go
              to eternalstrings.com</a> </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="switzerlandModal" tabindex="-1" aria-labelledby="switzerlandModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-800 rounded-10 position-relative overflow-hidden">
      <div class="modal-header text-white bg-maroon border-0 pt-3 mb-3 pb-1">
        <h6 class="modal-title">Honeymoon Destinations: Switzerland</h6>
        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closemaldivesModal"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body col-12 mb-3 px-3">
        "Switzerland: A Honeymoon Destination of Enchantment"<br><br>
        Switzerland, with its picturesque alpine landscapes and charming towns, is a top honeymoon destination for
        newlyweds. The Matterhorn, a famous
        peak that sits on the border of Switzerland and Italy, offers breathtaking views and romantic hiking or cable
        car rides. Lucerne, with its
        charming medieval architecture and serene lakeside setting, is perfect for romantic boat rides and strolls. St.
        Moritz, a luxurious ski
        resort, offers high-end shopping, dining, and spa experiences. The country also offers plenty of outdoor
        activities such as skiing, hiking,
        and biking for an adventurous honeymoon. Switzerland offers a perfect blend of romance and adventure for a
        honeymoon to remember.

      </div>
      <div class="modal-footer mt-2 py-1 bg-maroon w-100">
        <div class="col-12 ">
          <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
              class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                class="fas fa-long-arrow-alt-left"></i> Go
              to eternalstrings.com</a> </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="maldivesModal" tabindex="-1" aria-labelledby="maldivesModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-800 rounded-10 position-relative overflow-hidden">
      <div class="modal-header text-white bg-maroon border-0 pt-3 mb-3 pb-1">
        <h6 class="modal-title">Honeymoon Destinations: Maldives</h6>
        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closemaldivesModal"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body col-12  mb-3 px-3 overflow-height">
        "The Maldives: A Tropical Honeymoon Paradise"
        <br><br>
        The Maldives, a tropical paradise located in the Indian Ocean, is a top honeymoon destination for couples. Known
        for its crystal-clear waters,
        white sandy beaches, and stunning coral reefs, the Maldives offers a romantic and secluded getaway.
        <br><br>
        One of the most popular activities in the Maldives is to explore the stunning coral reefs. Take a romantic
        snorkeling or diving excursion to
        see the vibrant marine life and colorful coral. The Maldives is also home to many luxury resorts that offer
        overwater bungalows, providing a
        unique and romantic experience. Enjoy an intimate dinner on your own private deck, with the sound of the waves
        in the background.
        <br><br>
        Take a romantic sunset cruise and enjoy the stunning views of the Indian Ocean. The Maldives is also known for
        its beautiful beaches, take a
        romantic walk on the white sandy beaches, and enjoy the sound of the waves.
        The Maldives also offers a variety of water sports, such as paddleboarding, windsurfing, and kite-surfing. Take
        a romantic lesson together and
        enjoy the beautiful scenery while trying something new.
        <br><br>
        In conclusion, the Maldives is a tropical paradise and a perfect honeymoon destination for couples. The Maldives
        offers crystal-clear waters,
        white sandy beaches, stunning coral reefs, and luxury resorts, all of which make for a romantic and secluded
        getaway. Whether you're looking
        for a relaxing holiday, or an action-packed adventure, the Maldives has something for every type of honeymooner.

      </div>
      <div class="modal-footer mt-2 py-1 bg-maroon w-100">
        <div class="col-12 ">
          <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
              class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                class="fas fa-long-arrow-alt-left"></i> Go
              to eternalstrings.com</a> </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="goaModal" tabindex="-1" aria-labelledby="goaModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-800 rounded-10 position-relative overflow-hidden">
      <div class="modal-header text-white bg-maroon border-0 pt-3 mb-3 pb-1">
        <h6 class="modal-title">Honeymoon Destinations: Goa</h6>
        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closegoaModal"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body col-12 mb-3 px-3 overflow-height">
        1. "Goa: A Romantic Escape to Beaches and Culture"
        <br>
        2. "Goa: A Honeymoon Destination of Sun, Sand and Culture"
        <br>
        3. "Goa: A Perfect Blend of Beaches and Heritage for Honeymooners"
        <br>
        4. "Goa: The Ultimate Honeymoon Destination of Beaches and Adventure"
        <br>
        5. "Goa: A Honeymoon Paradise of Beaches, Culture and Fun"
        <br><br>
        Goa, located on the western coast of India, is a top honeymoon destination for couples. Known for its beautiful
        beaches, delicious food, and
        unique culture, Goa offers a perfect blend of relaxation and adventure for a romantic getaway.
        <br><br>
        One of the main attractions in Goa is its beaches. Take a romantic walk on the soft white sandy beaches, and
        enjoy the sound of the waves. The
        beaches offer a variety of activities such as sunbathing, swimming, and watersports like parasailing, jet
        skiing, and banana boat rides.
        <br><br>
        The culture of Goa is a unique blend of Indian and Portuguese influences. The city offers a rich history, and
        you can explore the colonial
        architecture and visit the many temples and churches in the area. Take a romantic stroll through the charming
        streets, visit the local markets
        and try the delicious seafood.
        <br><br>
        Goa is also known for its nightlife, with a variety of clubs, bars, and beach shacks to choose from. Take a
        romantic dance together under the
        stars or enjoy a romantic dinner at one of the many beach shacks.
        <br><br>
        In conclusion, Goa is a honeymoon destination that offers a perfect blend of relaxation and adventure. The
        beautiful beaches, delicious food,
        and unique culture make it a perfect place for a romantic getaway. Whether you're looking for a relaxing holiday
        or an action-packed
        adventure, Goa has something for every type of honeymooner.

      </div>
      <div class="modal-footer  mt-2 py-1 bg-maroon w-100">
        <div class="col-12">
          <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
              class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                class="fas fa-long-arrow-alt-left"></i> Go
              to eternalstrings.com</a> </p>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- .........Destinations madal end..... -->

<!-- .....cover Stories modal........ -->
<!-- <div class="modal fade" id="storiesModal" tabindex="-1" aria-labelledby="storiesModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
      <div class="modal-header text-white bg-maroon border-0 pt-3 mb-3 pb-1">
        <h6 class="modal-title">Success Stories: Beautiful memories</h6>
        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closestoriesModal1"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body col-12  mb-3 px-3 overflow-height">
        Hethin and Kamal
        <br><br>
        A huge thank you to our matchmaker Gail Bains for introducing me to my soul mate.
        She set us up on a blind date and we instantly connected. We had so much in common and just felt so comfortable
        being around each other.
        We dated for awhile and both came to the realization that we couldn't live without one another, so we ended up
        getting married.
        Without Gail's matchmaking skills and intuition we wouldn't be where we are today, in a strong and loving
        relationship.
        Our foundation for the rest of our lives has been laid and it’s all thanks to Gail. Thank you again for changing
        our lives and helping us find
        happiness, true love and joy.

      </div>
      <div class="modal-footer row mx-0 mt-2 py-1 bg-maroon w-100">
        <div class="col-12 ">
          <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
              class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                class="fas fa-long-arrow-alt-left"></i> Go
              to eternalstrings.com</a> </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="storiesModal1" tabindex="-1" aria-labelledby="storiesModal1Label" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-1000 rounded-10 position-relative overflow-hidden">
      <div class="modal-header text-white bg-maroon border-0 pt-3 mb-3 pb-1">
        <h6 class="modal-title">Success Stories: Beautiful memories</h6>
        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closestoriesModal1"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0 col-12 px-0 mb-3 px-3">
        Mariah and Karnvir
        <br><br>
        Gail Bains is very kind and caring, and also direct. She calls things out, which I appreciate, and we talk
        through real-life situations,
        applying the principals I am learning.
        I trust that I can share anything with Gail, including insecurities I might have.
        She is supportive, nonjudgmental, and encouraging. Gail Bains approach is very different than what I expected.
        She helped me understand and realize the importance of what it was that I was looking for in a life partner.
        I highly recommend working with Gail as she was able to help find my soul mate.
        I truly can’t thank you enough.
        Mariah
        <br><br>
        Gail, thank you so much for making me believe again that the love of my life is somewhere out there.
        I honestly at some points felt hopeless until I met and worked with you. She made me believe in connection,
        love, partnership and
        companionship again.
        By the time I found Gail I was hopelessly thinking that there is no one out there that could be a good match for
        me.
        She has changed that- she gave me confidence, put a smile on my face and made me believe in love again! She is
        an incredible woman.
        I would highly recommend her for anyone who values their time and energy. Karnvir
      </div>
      <div class="modal-footer row mx-0 mt-2 py-1 bg-maroon w-100">
        <div class="col-12 ">
          <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
              class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                class="fas fa-long-arrow-alt-left"></i> Go
              to eternalstrings.com</a> </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="storiesModal3" tabindex="-1" aria-labelledby="storiesModal1Label" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-1000 rounded-10 position-relative overflow-hidden">
      <div class="modal-header text-white bg-maroon border-0 pt-3 mb-3 pb-1">
        <h6 class="modal-title">Success Stories: Beautiful memories</h6>
        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closestoriesModal1"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0 col-12 px-0 mb-3 px-3">
        Harminder & Harkirat

        <br><br>
        Our journey to find true love and companionship has been a dream come true, and we owe a heartfelt thank you to
        Eternal String. This incredible platform, brought to our attention through the dedicated and insightful service
        of
        Gail Bains, has been a cornerstone in our love story. From the moment we engaged with Eternal String, we
        realized
        the uniqueness of their approach to matchmaking – it's personalized, thoughtful, and genuinely focused on
        creating
        meaningful connections. The journey with them was not just about finding a partner, but about understanding
        ourselves and what we truly sought in a life companion. Today, as we reflect on our blissful marital life, we
        recognize how instrumental Eternal String has been. It's more than a matrimonial site; it's a gateway to finding
        a
        soulmate. For anyone who is on the quest for love, we wholeheartedly recommend Eternal String. It's not just a
        platform; it's a catalyst for finding lifelong happiness and love.
      </div>
      <div class="modal-footer row mx-0 mt-2 py-1 bg-maroon w-100">
        <div class="col-12 ">
          <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
              class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                class="fas fa-long-arrow-alt-left"></i> Go
              to eternalstrings.com</a> </p>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="modal fade" id="storiesModal0" tabindex="-1" aria-labelledby="storiesModal1Label" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-1000 rounded-10 position-relative overflow-hidden">
      <div class="modal-header text-white bg-maroon border-0 pt-3 mb-3 pb-1">
        <h6 class="modal-title">Success Stories: Beautiful memories</h6>
        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closestoriesModal1"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0 col-12 px-0 mb-3 px-3 row">
        <div class="col-md-5 col-sm-12 mt-3">
          <div class="ratio-1 overflow-hidden">
            <img src="{{coupleImg}}" alt="" class="image-cover card-img-top">
          </div>
        </div>
        <div class="col-md-7 col-sm-12 mt-3" style="text-align: justify; max-height: 300px; overflow-y: auto;">
          <b>{{title}}</b>

          <br><br>
          <div [innerHTML]="story | limit: limitStory">
          </div>
          <ng-container *ngIf="story.length>250">
            <a href="javascript:void(0)" class="text-danger" *ngIf="limitStory == 600" (click)="readMore()">read
              more</a>
            <a href="javascript:void(0)" class="text-danger" *ngIf="limitStory > 600" (click)="readLess()">read
              less</a>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer row mx-0 mt-2 py-1 bg-maroon w-100">
        <div class="col-12 ">
          <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
              class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                class="fas fa-long-arrow-alt-left"></i> Go
              to eternalstrings.com</a> </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- start today plannin madal -->

<div class="modal fade" id="planningModal" tabindex="-1" aria-labelledby="planningModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
      <div class="modal-header text-white bg-maroon border-0 pt-3 mb-3 pb-1">
        <img src="assets/images/second_main_logo_webp.webp" class="mx-auto" alt="Description of eternalstrings logo" title="ETERNALSTRINGS LOGO" width="130px">
        <button type="button" class="btn position-absolute top-0 right-0 m-2 center w-30-h-30" data-bs-dismiss="modal"
          aria-label="Close"
          style="background-color: #811719 !important;border-color: #811719 !important;color: #ffffff;">
          <i class="fas fa-times fs-5"></i>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="col-12 px-0">
          <div class="mb-3 px-3">
            <h1 class="text-danger mt-2 text-center">Coming soon...</h1>
          </div>
          <div class="row mx-0">
            <div class="col-12 mt-3 py-2 bg-maroon w-100">
              <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                  class="text-light" id="closeWelcomeModal" data-bs-dismiss="modal" aria-label="Close"><i
                    class="fas fa-long-arrow-alt-left"></i> Go to eternalstrings.com</a> </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>