import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from 'src/app/services/register.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  disableButton: any = false;
  buttonDisable: boolean;
  errors: any;
  phoneNo: number;
  countries: any;
  phoneCode: any;
  dd: any;
  //full_name: any;
  mobile_no: any;
  email: any;
  userDetails: any = [];
  // is_logged_in:any;
  buttonClicked = false;
  btnLable: string = 'Send Message';
  @ViewChild("text") text: ElementRef;
  wordCount: any;
  words: any;
  tellUsMore: any;
  exceedLimit: boolean = false;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastrService: ToastrService,
    private registerservice: RegisterService,
    private cookieService: CookieService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Unite with Your Soul Mate | Contact Eternal String');
    this.meta.addTag({ name: 'description', content: 'Reach out on our Contact Us page! Fast responses, friendly service, and the help you need are just a click away. Connect now!' });
    this.meta.addTag({ name: 'robots', content: 'all' });
    this.meta.addTag({ name: 'keywords', content: 'Contact us, Soul mate, eternal strings'});

    this.dd = this.cookieService.get('user_id');
    this.contactForm = this.formBuilder.group({
      full_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(25), Validators.pattern('^[a-zA-Z ]+$')]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      mobileNo: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]),
      countryCode: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    })

    this.authService.getCountryList().subscribe(
      (result) => {
        this.countries = result.data;
        if (localStorage.getItem('register_id')) {
          let data = {
            user_id: this.dd
          }
          this.registerservice.getUser(data).subscribe(
            (res: any) => {
              if (res?.status) {

                this.userDetails = res['userDetails'];
                console.log(this.userDetails, 'userDetails');
                console.log(res, 'response', this.userDetails.country);
                this.contactForm.patchValue(res.userDetails);
                const full_name = `${this.userDetails.firstName} ${this.userDetails.lastName}`;
                this.contactForm.controls['full_name'].patchValue(full_name);
                this.f.countryCode.setValue(this.userDetails.countryNumberCode);
                this.phoneCode = this.userDetails.countryNumberCode;

              }
            }
          )
        } else /*{ before sonar uncomment*/
             //before sonar
          // if (this.countries && this.countries[0] && this.countries[0].id) {
            if (this.countries?.[0]?.id) {
            this.phoneCode = this.countries[0].id;
            this.contactForm.patchValue({ 'countryCode': this.countries[0].country_code});
            this.contactForm.patchValue({ 'countryNumberCode': this.countries[0].country_code });
          }
        //}
      }
    );
  }

  Submit() {
    this.submitted = true;
    console.log('this.contactForm', this.contactForm)
    if (this.contactForm.invalid) {
      this.disableButton = false;
      // return;
    } else {
      this.errors = false;
      const mobileNo = this.contactForm.get('mobileNo').value;
      const countryCode = this.contactForm.get('countryCode').value;

      const formattedValue = `${countryCode}-${mobileNo}`
      let formData = new FormData();
      formData.append("full_name", this.contactForm.value.full_name)
      formData.append("email", this.contactForm.value.email)
      formData.append('mobile_no', formattedValue);
      formData.append("subject", this.contactForm.value.subject)
      formData.append("message", this.contactForm.value.message)
      this.btnLable = 'Message sending...';
      this.buttonClicked = true;
      this.authService.contactUs(formData).subscribe(
        (res: any) => {
          this.btnLable = 'Send message';
          this.buttonClicked = false;
          this.buttonDisable = false;
          console.log('API Response:', res);

          if (res.status) {
            this.contactForm.reset();
            this.toastrService.success(res.message);
          } else {
            this.toastrService.error(res.message);
          }
          this.submitted = false;
        },
        (error) => {
          this.btnLable = 'Send message';

          this.buttonClicked = false;
          console.log('Error:', error);

          this.toastrService.error('An error occurred while processing the request.');
          //}

          this.buttonDisable = false;

        }
      );
    }
  }

  get f() {
    return this.contactForm.controls;
  }

   //before sonarqube
  // NameSpaceNotAllowed(event: any) {
  //   var RegEx = new RegExp(/^([a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"])*$/g);
  //   var str = event.target.value
  //   if (RegEx.test(str)) {
  //     return event.target.value
  //   } else {
  //     var nameString = str.replace(/\s+/g, ' ');
  //     var nameString = str.replace(/[^a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"]+/g, ' ');
  //     event.target.value = nameString.replace(/^\s+/g, "");
  //   }
  // }

    //after sonarqube
    NameSpaceNotAllowed(event: any) {
      let RegEx = new RegExp(/^([a-zA-Z\d!@#$&()\-.+,%*=~[\]{}\\<>?'":])*$/g);
      let str = event.target.value;
  
      if (RegEx.test(str)) {
        return event.target.value;
      } else {
        let nameString = str.replace(/\s+/g, ' ');
        nameString = nameString.replace(/[^a-zA-Z\d!@#$&()\-.+,%*=\|~[\]{}\\<>\?'";]+/g, ' ');
        event.target.value = nameString.replace(/^\s+/g, "");
      }
    }

  omit_special_char(event) {
    let k;
    k = event.charCode;  //k = event.keyCode; (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  pasteText(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedNumber = clipboardData.getData('text');
    let newNumber = pastedNumber.replace(/[^a-zA-Z]/g, "");
    this.contactForm.patchValue({ 'first_name': newNumber });
    setTimeout(() => {
      this.f.first_name.setValue(newNumber);
    }, 100);
  }

  keyPressNumeric(event) {
    const pattern = /[^+^0-9]/g;
    if (!pattern.test(event.target.value)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/[^+^0-9]/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumeric(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.contactForm.patchValue({ 'mobileNo': newText });
  }

  OnchangeCountryCode(event) {
    this.contactForm.patchValue({ 'countryCode': event.country_code });
    this.contactForm.patchValue({ 'countryNumberCode': event.country_code });
  }

  wordError() {
    // setTimeout(() => {
    if (this.words > 99) {
      this.exceedLimit = true;
      //this.toastrService.error('Limit Max 100 Words')
    } else {
      this.exceedLimit = false;
    }
    // }, 500);
  }

  pos = "";
  compare() {
    let pre = this.contactForm.value;
    console.log(pre, this.contactForm.value)
    setTimeout(() => {
      this.pos = this.contactForm.value;
      if (pre == this.pos) {
        console.log("change", "itime")
      }
    }, 500);
    if (pre == this.pos) {
      console.log("change", "out-time")
    }

  }

  // pasteNumeric6(event) {
  //   setTimeout(() => {
  //     let para = this.contactForm.value;
  //     if (para.trim().length > 0) {
  //       this.wordCount = this.text ? this.text.nativeElement.value.split(/\s+/) : 0;
  //       this.words = this.wordCount ? this.wordCount.length : 0;
  //       if (this.words >= 101) {
  //         event.target.value = "paste";
  //         this.wordCount = this.wordCount.slice(0, 100);
  //         this.words = this.wordCount ? this.wordCount.length : 0;
  //         this.contactForm.patchValue({ 'message': this.wordCount.join(" ") });
  //         this.wordError();
  //       }
  //     } else {
  //       this.wordError();
  //       this.words = 0;
  //     }
  //   }, 500);
  // }

  // wordCounter(event) {
  //   let para = this.contactForm.value;
  //   if (para.trim().length > 0) {
  //     this.wordCount = this.text ? this.text.nativeElement.value.split(/\s+/) : 0;
  //     this.words = this.wordCount ? this.wordCount.length : 0;
  //     // console.log('event',event)
  //     // console.log('words',this.words)
  //     if (this.words >= 101 && event.code != 'Backspace' && event.code != 'Delete') {
  //       this.wordError();
  //       const pattern = /[a-zA-Z0-9&_\.!,-\s()"']/g;
  //       if (!pattern.test(event.target.value)) {
  //         event.preventDefault();
  //         return false;
  //       } else {
  //         //   console.log("blur2"); 
  //         event.target.value = event.target.value.replace(/[a-zA-Z0-9&_\.!,-\s()']/g, "");
  //         // this.stepDoneForm.value.description = this.tellUsMore;
  //         this.tellUsMore = this.tellUsMore.replace(/  +/g, ' ');
  //         this.contactForm.patchValue({ 'message': this.tellUsMore.trim() + ' ' });
  //         this.words = this.tellUsMore.split(/\s+/).length
  //         event.preventDefault();
  //         return false;
  //       }
  //     } else {
  //       this.wordError();
  //       this.tellUsMore = this.contactForm.value;
  //     }
  //   } else {
  //     this.wordError();
  //     this.words = 0;
  //   }
  // }

  wordCounter(event) {
    this.wordCount = this.contactForm.value.message.split(/\s+/);
    this.words = this.wordCount.length;

    if (this.words >= 100 && event.code != 'Backspace' && event.code != 'Delete') {
      this.wordError();
      event.preventDefault();
    } else {
      this.wordError();
    }
  }

  pasteNumeric6(event) {
    setTimeout(() => {
      this.wordCount = this.contactForm.value.message.split(/\s+/);
      this.words = this.wordCount.length;

      if (this.words >= 100 && event.code != 'Backspace' && event.code != 'Delete') {
        this.wordCount = this.wordCount.slice(0, 100);
        this.contactForm.patchValue({ message: this.wordCount.join(" ") });
        this.wordError();
      }
    }, 500);
  }
}
