import { Component, OnInit } from '@angular/core';
import { Title/*, Meta*/ } from '@angular/platform-browser';
import { TokenService } from '../shared/token.service';


@Component({
  selector: 'app-safty-tips',
  templateUrl: './safty-tips.component.html',
  styleUrls: ['./safty-tips.component.css']
})
export class SaftyTipsComponent implements OnInit {
  isLoggedIn: any;

     
    constructor(private titleService: Title,
    public token: TokenService,
    ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Safety Tips | eternalstrings.com');
    window.scroll(0,0);
    this.isLoggedIn = this.token.isLoggedIn();
  }

}
