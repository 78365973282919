import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'heightTop'
})
export class HeightTopPipe implements PipeTransform {

  transform(value: number , unit : string){

      if(value && !(NaN)){

           if(unit === "Ft"){
             var height = value / 30.48;
              height.toFixed(2);
             var feet = Math.floor(height);
             var inches = Math.round((height - feet) * 11);
             var text = feet + "'"+" "+ inches +'"' ;
             return text;
                          
           }

           if(unit === "cm"){
            var height = value * 30.48;
            return height.toFixed(1);
          }

      }

    return;
  }

}