import { Component, OnInit } from '@angular/core';
import { Title/*, Meta */} from '@angular/platform-browser';
import { TokenService } from '../../shared/token.service';


@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  isLoggedIn: any;

  constructor(private titleService: Title,
    public token: TokenService,
    ) { }

  ngOnInit(): void {
    window.scroll(0,0);
    this.titleService.setTitle('Terms & Conditions |  eternalstrings.com');
    this.isLoggedIn = this.token.isLoggedIn();
  }

}
