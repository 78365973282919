import { Component, OnInit } from '@angular/core';
import { Title/*, Meta8*/ } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { MyProfileService } from '../services/my-profile.service';
import { ActivatedRoute,/* NavigationEnd,*/ Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location/*,ViewportScroller*/ } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonService } from '../services/common.service';
//import { filter } from 'rxjs/operators';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';




@Component({
  selector: 'app-renewal',
  templateUrl: './renewal.component.html',
  styleUrls: ['./renewal.component.css']
})

export class RenewalComponent implements OnInit {

  packageid: any;
  user_id: any;
  package_id: number;
  paymentHandler: any = null;
  packages: any = [];
  currencySymbole = {
    'USD': '&#x24;',
    'CAD': '&#x24;',
    'INR': '&#x24;',
  }
  currentPackage: any;
  remaningDays: any;
  CurrentPlanDetail: any;
  showPackages: any;
  endDate: string | number | Date;
  remainingDays: number;
  // previousUrl: string = null;

  constructor(
    private titleService: Title,
    private cookieService: CookieService,
    private myProfileService: MyProfileService,
    private toastrService: ToastrService,
    private router: Router,
    private location: Location,
    private activeRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private commonService: CommonService

  ) {
    this.activeRoute.queryParams
      .subscribe(params => {
        if (params && params.status == 0 && params.msg) {
          this.toastrService.error(params.msg);

        } else if (params && params.status == 1 && params.msg) {
          this.toastrService.success(params.msg);
        }
      }
      );
    // unused code
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.previousUrl = localStorage.getItem('previousUrl');
    //     localStorage.setItem('previousUrl', event.url);
    //   }
    // });
    // this.router.events
    // .pipe(filter(event => event instanceof NavigationEnd))
    // .subscribe((event: NavigationEnd) => {
    //   if (this.previousUrl) {
    //     // Save the previous URL in local storage
    //     localStorage.setItem('previousPath', this.previousUrl);
    //   }
    //   this.previousUrl = event.url;
    // });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Upgrade | eternalstrings.com');
    this.user_id = parseInt(this.cookieService.get('user_id'));
    this.invokeStripe();
    this.getUserPackages();
    this.getCorePackages();
    this.packageid = localStorage.getItem('package_id');
    console.log(this.packageid, 'this.packageid');
  }

  getCorePackages() {
    let pload = {
      user_id: this.user_id,
    };
    this.spinner.show();
    this.myProfileService.getCorePackages(pload).subscribe(
      (result) => {
        if (result.status) {
          this.showPackages = result.packages;
          this.filterPackages();
          console.log(this.packages, 'this.packages');
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastrService.error('Some thing want wrong info!');
      }
    );
  }

  filterPackages() {
    console.log("filter pacakge ", this.showPackages, this.CurrentPlanDetail)
    this.packages = [];
    this.packages = this.showPackages;
    // if (this.showPackages && this.CurrentPlanDetail && this.CurrentPlanDetail.package_id) {
    //   this.showPackages.forEach(element => {
    //     if (element.id == this.CurrentPlanDetail.package_id) {
    //       this.packages.push(element)
    //       console.log(this.packages, this.CurrentPlanDetail.package_id)
    //     }
    //   });
    // } else {
    //   this.packages = this.showPackages
    // }
  }

  daysDiff() {
    const dateTime1 = new Date(this.CurrentPlanDetail.payment_date);
    const dateTime2 = new Date(this.CurrentPlanDetail.end_date);
    const currentDate = new Date();

    // Calculate the difference days month
    const differenceInMilliseconds = dateTime2.getTime() - dateTime1.getTime();
    const dayDifference = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    // Calculate the remaining days, 
    const timeDifference = dateTime2.getTime() - currentDate.getTime();
    const remainingDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    this.remaningDays = remainingDays
    console.log(remainingDays, "remaing diff", this.remaningDays, dayDifference)
  }

  getUserPackages() {
    let pload = {
      user_id: this.user_id,
    };
    this.myProfileService.getUserPlans(pload).subscribe(
      (result) => {
        console.log(result, 'this.packages hello ', this.packages);
        if (result.status) {
          // before sonar
          // if (result && result.active_plan && result.active_plan.length > 0) {
          if (result?.active_plan && result.active_plan.length > 0) {
            result.active_plan.forEach(element => {
              if (element.active_plan_id) {
                this.CurrentPlanDetail = element
                this.daysCalculation();
                console.log(element.package_id, element, "sagar check ")
                this.daysDiff();
                this.filterPackages();
              }
              return;
            });
          }
        }
      },
      (error) => {
        this.toastrService.error('Some thing want wrong info!');
      }
    );
  }

  daysCalculation() {
    const dateTime1 = new Date(this.CurrentPlanDetail.start_date); // 3 / 6 / 9 extend
    const newDate = new Date(this.CurrentPlanDetail.start_date);
    let month = 0
    if (this.CurrentPlanDetail.subscription_plan == "3 month") {
      month = 3;
    } else if (this.CurrentPlanDetail.subscription_plan == "6 month") {
      month = 6;
    } else if (this.CurrentPlanDetail.subscription_plan == "9 month") {
      month = 9;
    }
    newDate.setMonth(newDate.getMonth() + month);
    const formattedDate = newDate.toISOString().slice(0, 19).replace("T", " ");
    this.endDate = new Date(formattedDate)
    //  console.log(dateTime1, this.CurrentPlanDetail)
    const dateTime2 = new Date(this.endDate);
    const currentDate = new Date();

    // Calculate the difference days month
    const differenceInMilliseconds = dateTime2.getTime() - dateTime1.getTime();
    const dayDifference = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    // Calculate the remaining days, 
    const timeDifference = dateTime2.getTime() - currentDate.getTime();
    const remainingDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    this.remainingDays = remainingDays
    console.log(dayDifference);
  }

  makePayment(amount: any, package_id: any) {
    const paymentHandler = (<any>window).StripeCheckout.configure({
      key: 'pk_test_51MvFxISDm5aHfu8hyKteRTrelsr1w3uenC2cB45RBYq0AW2X8jz4KFUh6BvkFxla7NXFfuo41GXs6dvfRzYNoYvk00ePiBhr7E',
      locale: 'auto',
      token: function (stripeToken: any) {
        this.package_id = package_id;
        this.upgradePackage(package_id);
      },
    });
    paymentHandler.open({
      name: 'Eternalstrings',
      description: '',
      amount: amount * 100,
    });
  }

  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_51MvFxISDm5aHfu8hyKteRTrelsr1w3uenC2cB45RBYq0AW2X8jz4KFUh6BvkFxla7NXFfuo41GXs6dvfRzYNoYvk00ePiBhr7E',
          locale: 'auto',
          token: function (stripeToken: any) {
            alert('Payment has been successfull!');
          },
        });
      };
      window.document.body.appendChild(script);
    } else {
      //    console.log('dfdfsdfs')
    }
  }

  upgradePackage(package_id: number) {
    let pload = {
      user_id: this.user_id,
      package_id: package_id,
    };

    this.myProfileService.upgradePackage(pload).subscribe(
      (result) => {
        this.toastrService.success('User package upgraded successfully!');
        this.location.back();
      },
      (error) => {

        this.toastrService.error('Some thing want wrong info!');
      }
    );
  }

  selectPackages(package_id: any) {
    if (package_id == 1) {
      this.router.navigate(['matches']);
    } else {
      let data = package_id + "-renewal"
      let encrypted_id: any = this.commonService.encryptData(data);
      encrypted_id = encrypted_id.toString().replaceAll('=', 'Ar1t').replaceAll('/', 'FGH').replaceAll('+', 'THD');
      // this.router.navigate(['packages/' + encrypted_id]);
      const url = `${environment.baseURL}` + 'packages/' + encrypted_id;
      window.open(url, '_blank');
    }

  }

  // startPayment(package_id:any,stripe_item_id:any) {
  //   let data = {
  //     user_id: this.user_id,
  //     package_id:package_id,
  //     stripe_item_id:stripe_item_id,
  //   }
  //   this.spinner.show();
  //   this.myProfileService.startPayment(data).subscribe(
  //     (result) => {
  //       this.spinner.hide();
  //       if(result.status) {
  //         window.location.href = result.url.url;
  //       } else {
  //         this.toastrService.error(result.message);
  //       }
  //     },
  //     (error) => {        
  //       this.toastrService.error('Some thing went wrong info!');
  //     }
  //   );
  // }  

  backLocation(){
    localStorage.setItem('fromrenew','fromrenew');
     console.log("Back");
  }

}
