import { Component, OnInit, ViewChild,/* ViewEncapsulation, */ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm, } from '@angular/forms';
import { StepdoneService } from '../services/stepdone.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-step-done',
  templateUrl: './step-done.component.html',
  styleUrls: ['./step-done.component.css']
})

export class StepDoneComponent implements OnInit {
  @ViewChild('stepDoneForm') stepDoneNgForm: NgForm;

  errorMessage: string;
  errors: any;
  submitted = false;
  texa: string;
  stepDoneForm = new FormGroup({
    user_id: new FormControl('34'),
    user_fake: new FormControl(''),

    description: new FormControl('', [Validators.required]),
  })
  jsonObj: any;
  name: string;
  placeHolder: string;
  @ViewChild("text") text: ElementRef;
  wordCount: any;
  words: any;
  tellUsMore: any;
  exceedLimit: boolean = false;
  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private stepdoneservice: StepdoneService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.getUserDetails();
  }

  getUserDetails() {
    let dd = this.cookieService.get('user_id');
    this.f.user_id.setValue(dd);
    let data = {
      user_id: dd
    }
    this.stepdoneservice.getUserDetails(data)
      .subscribe((x: any) => {
        let detail = x.userDetails
        this.name = detail.first_name + " " + detail.last_name
        if (detail.register_done <= 4) {
          this.f.user_id.setValue(dd);
          this.placeHolder = "hello"
        } else {
          this.f.description.setValue(detail.description);
          this.words = this.stepDoneForm.value.description.split(/\s+/).length
        }
      });
  }

  get f() {
    return this.stepDoneForm.controls;
  }

  stepDone() {
    this.submitted = true;
    if (this.stepDoneForm.invalid) {
      window.scroll(0, 0);
      return;
    }
    let frmdata = {
      user_id: this.stepDoneForm.value.user_id,
      description: this.stepDoneForm.value.description.trim(),
    }
    this.errors = false;
    this.stepdoneservice.registerFive(frmdata).subscribe(
      (result) => {
        if (result?.status) {
          this.stepDoneForm.reset();
          this.router.navigate(['/ProfilePage1']);;
        }
      },
      (error: { error: any; }) => {
        this.errors = error.error;
      },
    );
  }
  pos = "";
  compare() {
    let pre = this.stepDoneForm.value.description;
    console.log(pre, this.stepDoneForm.value.description)
    setTimeout(() => {
      this.pos = this.stepDoneForm.value.description;
      if (pre == this.pos) {
        console.log("change", "itime")
      }
    }, 500);
    if (pre == this.pos) {
      console.log("change", "out-time")
    }
  }


  wordCounter(event) {
    let para = this.stepDoneForm.value.description;
    if (para.trim().length > 0) {
      this.wordCount = this.text ? this.text.nativeElement.value.split(/\s+/) : 0;
      this.words = this.wordCount ? this.wordCount.length : 0;

      if (this.words >= 101 && event.code != 'Backspace' && event.code != 'Delete') {

        this.wordError();
        // before sonarqube
        // const pattern = /[a-zA-Z0-9&_\.!,-\s()"']/g;
        const pattern = /[a-zA-Z0-9&_.!,\-\s()"']/g;
        if (!pattern.test(event.target.value)) {
          event.preventDefault();
          return false;
        } else {
          // before sonarqube
          // event.target.value = event.target.value.replace(/[a-zA-Z0-9&_\.!,-\s()']/g, "");
          // this.tellUsMore = this.tellUsMore.replace(/  +/g, ' ');
          event.target.value = event.target.value.replace(/[a-zA-Z0-9&_.,!\-\s()']/g, "");
          this.tellUsMore = this.tellUsMore.replace(/ {2,}/g, ' ');
          this.stepDoneForm.patchValue({ 'description': this.tellUsMore.trim() + ' ' });
          this.words = this.tellUsMore.split(/\s+/).length
          event.preventDefault();
          return false;
        }
      } else {
        this.wordError();
        this.tellUsMore = this.stepDoneForm.value.description;
      }
    } else {
      this.wordError();
      this.words = 0;
    }
  }

  keyPressNumeric6(event) {
    if (this.words >= 101) {
      // before sonarqube
      // const pattern = /[a-zA-Z0-9&_\.!,-\s()"']/g;
      const pattern = /[a-zA-Z0-9&_.!,\-\s()"']/g;
      if (!pattern.test(event.target.value)) {
        event.preventDefault();
        return false;
      } else {
        // before sonarqube
        // event.target.value = event.target.value.replace(/[a-zA-Z0-9&_\.!,-\s()']/g, "");
        event.target.value = event.target.value.replace(/[a-zA-Z0-9&_.!,\-\s()"']/g, "");
        this.stepDoneForm.patchValue({ 'description': this.tellUsMore });
        event.preventDefault();
        return false;
      }
    } else {
      this.tellUsMore = this.stepDoneForm.value.description;
    }
  }

  wordError() {
    if (this.words > 100) {
      this.exceedLimit = true;
    } else {
      this.exceedLimit = false;
    }
  }
  pasteNumeric6(event) {
    setTimeout(() => {
      let para = this.stepDoneForm.value.description;
      if (para.trim().length > 0) {
        this.wordCount = this.text ? this.text.nativeElement.value.split(/\s+/) : 0;
        this.words = this.wordCount ? this.wordCount.length : 0;
        if (this.words >= 101) {
          this.wordCount = this.wordCount.slice(0, 100);
          this.words = this.wordCount ? this.wordCount.length : 0;
          this.stepDoneForm.patchValue({ 'description': this.wordCount.join(" ") });
          this.wordError();
        }
      } else {
        this.wordError();
        this.words = 0;
      }
    }, 500);
  }
}
