<section id="wedding-location" class="pt-5 pb-3">
  <div class="container">
    <div class="section text-center">
      <h3 class="section-heading"> Honeymoon Destinations</h3>
      <p class="section-sub-heading">For a Lifetime of Romantic Memories</p>
      <div class="seperator-line centered"></div>
    </div>
    <div class="row mt-5">
      <div class="col-md-4 col-sm-12">
        <div class="row">
          <div class="col-md-12 col-sm-6">
            <div (click)="openModal(0)" class="location-block  cursor-pointer" data-bs-toggle="modal"
              data-bs-target="#honeymoonDestinationsModal">
              <div class="city-img">
                <img src="assets/images/honeymoon/montreal.jpg" class="img-responsive" alt="city">
                <div class="overlay-bg"></div>
              </div>
              <div class="city-dtl text-center mx-auto py-4 position-absolute">
                <h6 class="city-dtl-heading"><a href="javascript:void(0);">Montreal</a></h6>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-6">
            <div (click)="openModal(1)" class="location-block cursor-pointer" data-bs-toggle="modal"
              data-bs-target="#honeymoonDestinationsModal">
              <div class="city-img">
                <img src="assets/images/honeymoon/switzerland.jpg" class="img-responsive" alt="city">
                <div class="overlay-bg"></div>
              </div>
              <div class="city-dtl text-center mx-auto py-4 position-absolute">
                <h6 class="city-dtl-heading"><a href="javascript:void(0);">Switzerland</a></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div (click)="openModal(2)" class="location-block cursor-pointer" data-bs-toggle="modal"
          data-bs-target="#honeymoonDestinationsModal">
          <div class="city-img">
            <img src="assets/images/honeymoon/paris.jpg" class="img-responsive" alt="city">
            <div class="overlay-bg"></div>
          </div>
          <div class="city-dtl text-center mx-auto py-4 position-absolute">
            <h6 class="city-dtl-heading"><a href="javascript:void(0);">Paris</a></h6>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div (click)="openModal(3)" class="location-block cursor-pointer" data-bs-toggle="modal"
              data-bs-target="#honeymoonDestinationsModal">
              <div class="city-img">
                <img src="assets/images/honeymoon/maldives.jpg" class="img-responsive" alt="city">
                <div class="overlay-bg"></div>
              </div>
              <div class="city-dtl text-center mx-auto py-4 position-absolute">
                <h6 class="city-dtl-heading"><a href="javascript:void(0);">Maldives</a></h6>
              </div>
            </div>
          </div>
          <div (click)="openModal(4)" class="col-md-12 col-sm-12" data-bs-toggle="modal"
            data-bs-target="#honeymoonDestinationsModal">
            <div class="location-block cursor-pointer">
              <div class="city-img">
                <img src="assets/images/honeymoon/goa.jpg" class="img-responsive" alt="city" data-bs-toggle="modal"
                  data-bs-target="#honeymoonDestinationsModal">
                <div class="overlay-bg"></div>
              </div>
              <div class="city-dtl text-center mx-auto py-4 position-absolute">
                <h6 class="city-dtl-heading"><a href="javascript:void(0);">Goa</a></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="modal fade" id="honeymoonDestinationsModal" tabindex="-1" aria-labelledby="honeymoonDestinationsModalLabel"
  aria-hidden="true">
  <div class="modal-dialog width-90 modal-fullscreen1">
    <div class="modal-content mx-auto mx-width-1000 rounded-10 position-relative overflow-hidden">
      <div class="modal-header text-white bg-maroon border-0 pt-3 pb-1">
        <h6 class="modal-title">Honeymoon Destinations: {{carouselItems[honeyDest].location}}</h6>
        <button type="button" class="btn position-absolute top-0 right-0 m-2 center w-30-h-30 white-cross"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>
      </div>
      <div class="modal-body p-0 col-12 px-0 mb-3 px-3 row">
        <div class="col-md-5 col-sm-12 mt-3">
          <div class="ratio-1 overflow-hidden">
            <img src="{{carouselItems[honeyDest].image}}" alt="" class="image-cover card-img-top">
          </div>
        </div>
        <div class="col-md-7 col-sm-12 story_wpr mt-3">
          <div class="clearfix">
            <p><b>{{carouselItems[honeyDest].title}}</b></p>
          </div>
          <div class="mt-3" [innerHTML]="carouselItems[honeyDest].story">
          </div>
        </div>
      </div>
      <div class="modal-footer row mx-0 py-1 bg-maroon w-100">
        <div class="col-12 ">
          <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
              class="text-light" id="closeLoginModal" data-bs-dismiss="modal" aria-label="Close"><i
                class="fas fa-long-arrow-alt-left"></i> Go
              to eternalstrings.com</a> </p>
        </div>
      </div>
    </div>
  </div>
</div>