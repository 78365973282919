import { Component, ChangeDetectorRef, HostListener,AfterViewInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './shared/auth.service';
import { interval, Subscription } from 'rxjs';
import { AuthStateService } from './shared/auth-state.service';
import { TokenService } from './shared/token.service';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { CommonService } from './services/common.service';
import { DisableRightClickService } from './services/disable-right-click.service';
import { CanonicalService } from './canonical.service';
import { filter } from 'rxjs/operators';
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'ETERNALSTRINGS';
  user_id: number;
  isLoggedIn: any = false;
  currentRoute: any = '';
  ipAddress: any;
  isAdminRoute: boolean = false;
  routeInitialized: boolean = false;
  subscription: Subscription;
  counter: number;
  previousUrl: string;

  constructor(
    private cdref: ChangeDetectorRef,
    private cookieService: CookieService,
    private authService: AuthService,
    private auth: AuthStateService,
    public router: Router,
    public token: TokenService,
    private CommonService: CommonService,
    private rightClickDisable: DisableRightClickService,
    private canonicalServices : CanonicalService,


  ) {
    this.user_id = parseInt(this.cookieService.get('user_id'));
    this.isLoggedIn = this.token.isLoggedIn();
    // console.log('app compo isLoggedIn',this.isLoggedIn)
    // console.log(router.url);
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        // console.log('NavigationStart123',event);
        this.currentRoute = event.url;
        this.routeInitialized = true;
        if (this.currentRoute.indexOf('/admin') > -1) {
          this.isAdminRoute = true;
        }

        var register_id = parseInt(localStorage.getItem('register_id'));
        if (register_id != null) {
          // console.log('NavigationStart',event);
          if (event.url == '/matches' && register_id < 7) {
            // this.checkStepAndRedirect(register_id);
          } else if (event.url == '/comunication-inbox' && register_id < 7) {
            // this.checkStepAndRedirect(register_id);

          }
        }
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        // console.log('NavigationEnd',event);
        this.currentRoute = event.url;
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        // console.log('NavigationError',event.error);
      }


    });


    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });

  }
  checkStepAndRedirect(register_id) {
    if (register_id == 0) {
      console.log(register_id, "msgt");

      this.router.navigate(['register']);
    }
    else if (register_id == 1) {
      this.router.navigate(['registerNxt']);
    }
    else if (register_id == 2) {
      this.router.navigate(['step']);
    }
    else if (register_id == 3) {
      this.router.navigate(['stepTwo']);
    }
    else if (register_id == 4) {
      this.router.navigate(['stepDone']);
    }
    else if (register_id == 5) {
      this.router.navigate(['ProfilePage1']);
    }
    else if (register_id == 6) {
      this.router.navigate(['PartnerPreferences']);
    }
  }

  ngAfterViewInit() {
    var currentUrl = this.router.url;
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url != '' && event.url != '/RefreshComponent' && this.counter != 0 && currentUrl != this.previousUrl) {
          this.counter = 0;
        }
        if (event.url != '' && event.url != '/RefreshComponent' && this.counter == 0 && currentUrl != this.previousUrl) {
          this.previousUrl = event.url;
          this.counter++;
          this.canonicalServices.setCanonicalURL();
        }
      });
      // console.log('this.canonicalServices',this.canonicalServices.setCanonicalURL())
  }

  ngOnInit() {
    this.rightClickDisable.disableRightClick();
    this.isLoggedIn = this.token.isLoggedIn();
    // console.log('app compo login status', this.isLoggedIn)
    // console.log('app compo register_id',localStorage.getItem('register_id'))
    var aa = this.cookieService.get('auth_token')
    // console.log('remember_me',aa)
    this.CommonService.updateLoginStatus(this.isLoggedIn);
    // console.log('this.token.isLoggedIn::',this.isLoggedIn)
    this.CommonService.currentApprovalStageMessage.subscribe(status => {
      // console.log('status subscribe',status)
      this.isLoggedIn = status;
    });

    //  getIPAdd(){
    this.CommonService.getIp().subscribe(data => {
      // console.log('th data', data);
      this.ipAddress = data
    })
    // console.log("fun call detail" ,this.ipAddress)

    // }

    // interval(60000).subscribe(x => {
    //   if( !this.cookieService.get('auth_token') &&  !this.cookieService.get('user_id')) {
    //     this.auth.setAuthState(false);
    //     this.token.removeToken();
    //     this.router.navigate(['home']);
    //   }
    // });
    // let context = this;
    // window.addEventListener("beforeunload", function (e) {
    //   if(context.cookieService.get('remember_me') == 'false'){
    //     context.logout();
    //   }
    // });
  }
  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    alert()
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  logout() {
    console.log('logout from app ts')
    debugger
    // console.log('tab wala logout');
    // this.authService.logout().subscribe(
    //   (result) => {
    //   })
    this.cookieService.delete('auth_token');
    this.cookieService.delete('user_id');
    this.cookieService.delete('remember_me');
  }
  doSomething(event: any) {
    // console.log('event',event)
    this.isLoggedIn = event;
  }
}
