import { Component, OnInit, ViewChild/*, ViewEncapsulation, HostListener*/ } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterService } from '../services/register.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('resetPasswordNgForm') resetPasswordNgForm: NgForm;
  submitted = false;

  resetPasswordForm = new FormGroup({
    currentPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}'), Validators.minLength(8)]),
    ConfirmPassword: new FormControl('', [Validators.required]),
  })
  errors: any = null;
  countries: any;
  passmatch: boolean = true;
  id: string;
  pageValid: boolean = true;
  errmsg: string = '';
  errmsg1: string = '';
  passvalid: boolean;
  showPassword: boolean = false;
  showPassword0: boolean = false;
  showPassword1: boolean = false;
  errorMessage: string;
  err: boolean;
  errormsg: string;
  currentPasswordValid: boolean = false;
  sBtn: boolean = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private registerService: RegisterService,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    let url = window.location.href
    let urlArray = url.split('change-password/')
    let data = urlArray[urlArray.length - 1]
    let dataValues = data.split(/([]+)/)
    this.id = dataValues[0];
    this.checkvalid();
  }

  passwordMatch() {
    let np = (this.resetPasswordForm.value.newPassword);
    let cp = (this.resetPasswordForm.value.ConfirmPassword);
    if (np == cp) {
      this.sBtn = true;
      this.passmatch = true;
      return true;
    } else {
      this.passmatch = false;
      this.sBtn = false;
      return false;
    }
  }
  get f() {
    return this.resetPasswordForm.controls;
  }

  checkvalid() {
    let data = {
      id: this.id,
    }
    this.authService.isChangeActive(data)
      .subscribe((result: any) => {
        if (result.status) {
          this.pageValid = true;
        } else {
          this.pageValid = false;
          this.errmsg = "you are not valid user";
        }
      });
  }

  validatePassword(event: any) {
    if (event.target.value.length >= 8) {
      // let regex = new RegExp('(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}');
      let regex = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}/;
      let ch1 = regex.test(event.target.value);
      if (ch1) {
        this.passvalid = true;
        this.matchPassword(event)
        this.errormsg = ""
      } else {
        this.passvalid = false;
        this.err = false;
        this.errormsg = "Password must have a minimum of 8 characters including at least one Capital, one number, and one special character"
      }
    }
    else {
      this.errormsg = "Password must have a minimum of 8 characters including at least one Capital, one number, and one special character"
      this.passvalid = false;
    }
  }

  matchPassword(event: any) {
    if (this.resetPasswordForm.value.newPassword) {
      let np = (this.resetPasswordForm.value.newPassword);
      let cp = (this.resetPasswordForm.value.ConfirmPassword);
      if (np == cp) {
        this.passmatch = true;
        this.sBtn = true;
        return true;
      } else {
        this.passmatch = false;
        this.sBtn = false;
        return false;
      }
    }
  }

  showHidePassword0() {
    this.showPassword0 = !this.showPassword0;
  }

  showHidePassword1() {
    this.showPassword1 = !this.showPassword1;
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }

  resetPasswordDone() {
    this.errors = "";
    if (this.passvalid) {
      if (this.passmatch) {
        this.submitted = true;
        if (this.sBtn) {
          let data = {
            id: this.id,
            temp_password: this.resetPasswordForm.value.currentPassword,
            password: this.resetPasswordForm.value.newPassword
          }
          this.authService.changePassword(data)
            .subscribe(
              (result) => {
                if (result.status) {
                  this.toastrService.success(result.message);
                  this.resetPasswordForm.reset();
                  setTimeout(() => {
                    window.location.href = `${environment.baseURL}`;
                  }, 3000);
                }
                else {
                  this.toastrService.error(result.message);
                };
              },
              (error) => {
                this.errors = error.error;
              },
            )
        }
      } else {
        this.submitted = false;
        this.resetPasswordForm.reset();
      }
    }
  }
}

