import { Component, ViewChild, OnInit/*, AfterContentInit*/, ElementRef } from '@angular/core';
import { Title/*, Meta */ } from '@angular/platform-browser';
import * as moment from 'moment';
import { Router, ActivatedRoute/*, RouterLink */ } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { PartnerPreferenceService } from '../services/partner-preference.service';
import { MyProfileService } from '../services/my-profile.service';
import { AuthService } from '../shared/auth.service';
import { CommonService } from '../services/common.service';
import { Observable } from 'rxjs';
import { /*FormBuilder, */FormGroup, FormControl, Validators, NgForm, } from '@angular/forms';
import { RegisterService } from '../services/register.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css'],
})
export class DetailComponent implements OnInit {
  @ViewChild('blockModal') blockModal: any;

  partnerProfileDetail: any;
  myPhotos: any = [];
  std: string;
  pageId: any;
  age: any;
  ageCount: any;
  user_id: any;
  displayName: any;
  profileId: any;
  partnerPreferences: any;
  partnerPreferencesdisplayName: any;
  myProfileDetail: any;
  detailLoader: boolean = true;
  isblock: boolean = false;
  ispakage: boolean = false;
  matchAge: boolean = true;
  matchHeight: boolean = true;
  matchMaritalStatus: boolean = true;
  matchReligion: boolean = true;
  matchFirstLanguage: boolean = true;
  matchCountryLivingIn: boolean = true;
  matchStateLivingIn: boolean = true;
  matchCityLivingIn: boolean = true;
  matchResidencyStatus: boolean = true;
  matchEducation: boolean = true;
  matchAnnualIncome: boolean = true;
  partnerLocationCountryName: any;
  partnerLocationStateName: any;
  partnerLocationStates: any;
  countries: any;
  CurrencyCountryName: any;
  partnerCurrencyCode: any;
  states: any;
  text: any = [];
  text1: any = [];
  text2: any = [];
  text3: any = [];
  text4: any = [];
  text5: any = [];
  count: number = 0;
  conversationStarter: any = [];
  resultStarter: any = [];
  newArray: any = [];

  watchPartnerPrefrenceRes: boolean = false;
  watchPartnerDetailRes: boolean = false;
  StarterAge: boolean = true;
  StarterHeight: boolean = false;
  StarterMaritalStatus: boolean = false;
  StarterReligion: boolean = false;
  StarterFirstLanguage: boolean = false;
  StarterEducation: boolean = false;
  StarterCountryLivingIn: boolean = false;
  StarterStateLivingIn: boolean = false;
  StarterCityLivingIn: boolean = false;
  StarterResidencyStatus: boolean = false;
  StarterAnnualIncome: boolean = false;
  cardCheck: boolean = false;
  cardpremiumCheck: boolean = false;

  showmarital_status: any;
  showlanguages: any;
  showreligion: any;
  showeducation: any;
  showResidencyStatus: any;
  selectId: any;
  tabFrom: any;

  fromMatches: boolean = false;
  fromShortList: boolean = false;
  fromFavourite: boolean = false;
  aboutUser: any;

  showConnect: boolean = false;
  showShortList: boolean = false;
  showFavourite: boolean = false;
  package_id: number;
  profileSrc: any = 'assets/images/men-avtar-2.svg';
  showIcon: number = 0;
  photoSrc: any = 'assets/images/banner-4.jpg';
  previewUrl: string = '';
  imgStatus: boolean;

  showMatchesIcon: any = false;


  galleryPath: any = '';
  showCaption: any = '';
  index: number;
  indexNext: number;
  indexPrevious: number;
  myDetails: any;
  access_for: any;
  myRequestDetails: any;
  requestFor: string = '';

  time = 6;
  s: any;
  interval: any;

  otherReason: boolean = false;
  reportReason: string = ""
  reportReasonsList: any = [
    { id: 1, label: "Harassment or Abuse" },
    { id: 2, label: "Unwanted Attention" },
    { id: 3, label: "Spam" },
    { id: 4, label: "Scam or Fraud" },
    { id: 5, label: "Disrespectful Behavior" },
    { id: 6, label: "Privacy Concerns" },
    { id: 7, label: "Fake or Suspicious Profiles" },
    { id: 8, label: "Abusive or Threatening Behavior" },
    { id: 9, label: "Explicit Content" },
    { id: 10, label: "Violation of Platform Rules" },
    { id: 11, label: "Other" }

  ];

  starterArray = ["marital_status", "languages", "religion", "college_name", "living_in_country", "state_name", "current_city", "residency_status", "hobbies1", "country_of_birth"]

  @ViewChild('wordCountForm') wordCountNgForm: NgForm;
  @ViewChild("texts") texts: ElementRef;
  wordCountForm = new FormGroup({
    user_id: new FormControl(''),
    description: new FormControl('', [Validators.required]),
  })
  wordCount: any;
  words: any;
  tellUsMore: any;
  exceedLimit: boolean;
  myInfo: any;
  dataMatched: any[];
  constructor(
    //private modalService: NgbModal,
    private titleService: Title,
    private myProfileService: MyProfileService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private partnerPreferenceService: PartnerPreferenceService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private CommonService: CommonService,
    private registerservice: RegisterService,
    private spinner: NgxSpinnerService,
  ) {
    localStorage.setItem('currentUrl', this.router.url);
    // interval set time out 
    this.s = new Observable((observer) => {
      this.interval = setInterval(() => {
        observer.next((
          this.time = this.time
        ));
      }, 500);
    });
    this.s.subscribe((res) => {
      this.time = res;
      if (this.interval && this.time == 0) {
        clearInterval(this.interval);
      }
    });
  }

  ngOnInit(): void {
    let pageId = this.route.snapshot.params['id'];
    if (pageId.toString().length > 10) {
      pageId = pageId.toString().replaceAll('Ar1t', '=').replaceAll('FGH', '/').replaceAll('THD', '+');
      this.pageId = this.CommonService.decryptData(pageId)
    } else {
      this.pageId = pageId;
    }
    this.user_id = this.cookieService.get('user_id');
    this.getprofileDetail();
    this.authService.getCountryList().subscribe((result) => {
      this.countries = result.data;
    });

    let data = {
      user_id: this.user_id
    }
    this.registerservice.getUserDetails(data).subscribe((res: any) => {
      this.myInfo = res.userDetails;
      this.conversationStarterKey('ngoninit');
    })

  }

  getProfileGallery() {
    let payload = {
      profile_id: this.pageId,
      user_id: this.user_id,
    };
    this.myProfileService.getProfileGallery(payload).subscribe((res) => {
      if (res.status) {
        this.myPhotos = res.UserProfileGallery;
      }
    }, (error) => {
      // unused code
      // this.toastrService.error('Some thing went wrong!!');
    });
  }

  getprofileDetail() {
    let data = {
      profile_id: this.pageId,
      user_id: this.user_id,
    };
    this.detailLoader = true;
    this.myProfileService.viewProfile(data).subscribe((user_details: any) => {
      this.detailLoader = false;
      if (user_details.status == 203 || user_details.message == 'You are blocked') {
        $('#openModal2222').click();
        this.detailLoader = false;
      } else {
        this.myRequestDetails = user_details['userDetails'][0];
        this.partnerProfileDetail = this.myRequestDetails;
        this.getPartnerDetails();

        this.conversationStarterKey('after view profile');
        this.profileId = this.partnerProfileDetail.user_id;

        this.age = this.myRequestDetails?.age;
        if (this.partnerProfileDetail.profile_photo && this.partnerProfileDetail.profile_photo_approved == 1) {
          this.profileSrc = this.partnerProfileDetail.profile_photo;
        }
        if (this.partnerProfileDetail.cover_photo && this.partnerProfileDetail.cover_photo_approved == 1) {
          this.photoSrc = this.partnerProfileDetail.cover_photo;
        }

        if (
          !(
            this.partnerProfileDetail.display_name == '' ||
            this.partnerProfileDetail.display_name == null
          )
        ) {
          this.displayName = this.partnerProfileDetail.display_name;
          this.titleService.setTitle(this.displayName + ' | eternalstrings.com');
        } else {
          this.displayName = this.partnerProfileDetail.first_name;
          this.titleService.setTitle(this.displayName + ' | eternalstrings.com');
        }

        // Check if a specific property is not set
        if (this.myRequestDetails.request_contact_detail !== undefined) {
          // Property is set
          console.log("Property is set");
        } else {
          // Property is not set
          console.log("Property is not set");
        }



        this.getMoreAboutUser();
        this.getProfileGallery();
      }
    }, (error) => {
      this.toastrService.error('Some thing went wrong!!');
      this.detailLoader = false;
    })
  }


  getMoreAboutUser() {
    let payload = {
      profile_id: this.pageId,
      user_id: this.user_id,
    };

    this.myProfileService.getMoreAboutUser(payload).subscribe((res) => {
      this.aboutUser = res.aboutUser;
      this.showIcon = this.aboutUser.stage + 1
      if (this.aboutUser.isBlocked == true) {
        this.isblock = true;
      } else {
        // unused code
        // this.getDetail()
      }
    });
  }
  
// before sonar
  // openModal() {
  //   if (this.isblock == true && this.cardCheck == false) {
  //     this.cardCheck = true;
  //     $('#blockModal1').click();
  //   }
  //   this.isblock = false;
  // }

  // after sonar
  openModal() {
    const shouldShowModal = this.isblock && !this.cardCheck;
    
    if (shouldShowModal) {
      this.cardCheck = true;
      $('#blockModal1').click();
    }
    
    this.isblock = false;
}


  conversationStarterKey(from: string) {
    console.log('called from ', from)
    console.log('partnerProfileDetail', this.partnerProfileDetail);
    console.log('myinfo', this.myInfo);

    this.dataMatched = []
    if (this.partnerProfileDetail && this.myInfo) {
      this.countryMatch();
      const obj1 = this.myInfo;
      const obj2 = this.partnerProfileDetail;
      for (const key in obj1) {
        if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
          const value1 = obj1[key];
          const value2 = obj2[key];
          if (value1 != null && value1 != '' && value1 != undefined && value2 != null && value2 != '' && value2 != undefined) {
            if (value1 === value2) {

              if (this.starterArray.includes(key)) {
                let keyValue = {
                  key: key,
                  value: value1
                }
                // var key = ${ key }
                this.dataMatched.push(keyValue)
              }

              if (key == 'dob') {
                const date = moment(value1, 'YYYY-MM-DD');
                const years = moment().diff(date, 'years');
                this.ageCount = years;

                if (this.starterArray.includes('age1')) {
                  let keyValue = {
                    key: 'age1',
                    value: this.ageCount
                  }
                  this.dataMatched.push(keyValue)
                }

              }

              console.log(`Key '${key}' matches with value: ${value1}`);
              // var key = ${ key }
              //this.dataMatched.push(key)
            } else if (value1 == null || value1 === '' || value1 === undefined) {
              // unused code
              // console.log(`Key '${key}' has a null, blank, or undefined value in the first object`);
            } else if (value2 == null || value2 === '' || value2 === undefined) {
              // unused code
              // console.log(`Key '${key}' has a null, blank, or undefined value in the second object`);
            } else {
              // unused code
              // console.log(`Key '${key}' does not match in both objects`);
            }

            // hobbies stert
            if (key == 'hobbies') {

              const hobbiesArray = value1.toLowerCase().split('_');
              const hobbies1Array = value2.toLowerCase().split('_');

              // Use Array.filter to find common hobbies
              const commonHobbies = hobbiesArray.filter(hobby => hobbies1Array.includes(hobby));

              // Function to capitalize the first letter of a word
              function capitalizeFirstLetter(word: string): string {
                return word.charAt(0).toUpperCase() + word.slice(1);
              }

              // Use map to capitalize the first letter of each word in commonHobbies
              const capitalizedCommonHobbies = commonHobbies.map(hobby => capitalizeFirstLetter(hobby));

              if (this.starterArray.includes('hobbies1') && commonHobbies.length != 0) {
                const keyValue = {
                  key: 'hobbies1',
                  value: capitalizedCommonHobbies
                };
                this.dataMatched.push(keyValue);
                console.log('hobbies1hobbies1');
              }
            }
            // hobbies end
          }

        }
      }
      console.log(this.dataMatched, "key matched")
    }

  }

  countryMatch() {
    if (this.countries != '' && this.countries != undefined) {
      this.countries.forEach(
        (country: { id: any; country_name: any }) => {
          if (parseInt(this.myInfo.living_in_country) == country.id) {
            let userCountry = country.country_name;
            if (this.partnerProfileDetail.living_in_country == userCountry) {
              console.log("country matched ")
              let keyValue = {
                key: "living_in_country",
                value: this.partnerProfileDetail.living_in_country
              }
              this.dataMatched.push(keyValue)

              this.authService.getStateList({ id: this.myInfo.living_in_country, }).subscribe((result) => {
                let stateData = result.data;
                stateData.forEach((state) => {
                  if (parseInt(this.myInfo.state_name) == state.id) {
                    let userState = state.state_name
                    if (userState == this.partnerProfileDetail.state_name) {
                      console.log("state matched ")
                      let keyValue = {
                        key: "state_name",
                        value: this.partnerProfileDetail.state_name
                      }
                      this.dataMatched.push(keyValue)
                    } else {
                      // unused code
                      // var keyValue = {
                      //   key: "living_in_country",
                      //   value: this.partnerProfileDetail.living_in_country
                      // }
                      // this.dataMatched.push(keyValue)
                    }
                  }
                })
              });
            }
          }

          if (parseInt(this.myInfo.country_of_birth) == country.id) {
            let userCountry1 = country.country_name;
            if (this.partnerProfileDetail.country_of_birth == userCountry1) {
              console.log("country of Birth matched ")
              if (this.starterArray.includes('country_of_birth')) {
                let keyValue = {
                  key: "country_of_birth",
                  value: this.partnerProfileDetail.country_of_birth
                }
                this.dataMatched.push(keyValue)
              }
            }
          }

        }
      );
    }
  }

  compareAndConsoleMatchedKeys(obj1, obj2) {
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key) && obj1[key] === obj2[key]) {
        console.log(`Matching key: ${key}`);
      }
    }
  }


  getDetail() {
    // this.detailLoader = true;
    // let data = {
    //   profile_id: this.pageId,
    // };
    // //// console.log('data',data);
    // this.myProfileService.userProfilePreview(data).subscribe(
    //   (res) => {
    //     this.partnerProfileDetail = res['userDetails'][0];
    //     // console.log('data', this.partnerProfileDetail);
    //     this.conversationStarterKey();
    //     // this.myPhotos = res.userPhotos;
    //     this.profileId = this.partnerProfileDetail.user_id;
    //     // this.detailLoader=false;
    //     // if (this.partnerProfileDetail.profile_photo && this.partnerProfileDetail.profile_photo_approved == 1) {
    //     //   this.profileSrc = this.partnerProfileDetail.profile_photo;
    //     // }
    //     // if (this.partnerProfileDetail.cover_photo && this.partnerProfileDetail.cover_photo_approved == 1) {
    //     //   this.photoSrc = this.partnerProfileDetail.cover_photo;
    //     // }
    //     // if (
    //     //   !(
    //     //     this.partnerProfileDetail.display_name == '' ||
    //     //     this.partnerProfileDetail.display_name == null
    //     //   )
    //     // ) {
    //     //   this.displayName = this.partnerProfileDetail.display_name;
    //     //   this.titleService.setTitle(this.displayName + ' | eternalstrings.com');
    //     // } else {
    //     //   this.displayName = this.partnerProfileDetail.first_name;
    //     //   this.titleService.setTitle(this.displayName + ' | eternalstrings.com');
    //     // }
    //     this.getPartnerDetails()

    //     this.detailLoader = false;
    //   },
    //   (error) => {
    //     this.detailLoader = false;
    //   }
    // );
  }

  getPartnerDetails() {
    let Partnerdata = {
      user_id: this.user_id,
    };
    this.partnerPreferenceService.getPartnerDetails(Partnerdata).subscribe((res) => {
      this.partnerPreferences = res.userDetails;

      let re1 = /,/gi;
      let str1 = this.partnerPreferences.marital_status.toString();
      this.showmarital_status = str1.replace(re1, ', ');

      let re2 = /,/gi;
      let str2 = this.partnerPreferences.religion.toString();
      this.showreligion = str2.replace(re2, ', ');

      let re3 = /,/gi;
      let str3 = this.partnerPreferences.languages.toString();
      this.showlanguages = str3.replace(re3, ', ');

      let re4 = /,/gi;
      let str4 = this.partnerPreferences.education.toString();
      this.showeducation = str4.replace(re4, ', ');

      let re5 = /,/gi;
      let str5 = this.partnerPreferences.residency_status.toString();
      this.showResidencyStatus = str5.replace(re5, ', ');

      if (this.partnerPreferences.country_name == 0) {
        this.countries.push({
          "fullnumber": "Any",
          "id": 0,
          "country_code": "",
          "country_name": "Any",
          "short_name": "ANY",
          "currency": "",
          "currency_name": ""
        })
      }
      if (this.countries != '' && this.countries != undefined) {
        this.countries.forEach(
          (country: { id: any; country_name: any }) => {
            if (this.partnerPreferences.country_name == country.id) {
              this.partnerLocationCountryName = country.country_name;
              this.partnerCurrencyCode =
                +this.partnerPreferences.income_currency;
              // // console.log(this.partnerLocationCountryName);
              //// console.log('countryApi', this.partnerLocationCountryName)

              if (this.partnerPreferences.state_name == 'Any') {
                this.partnerLocationStateName = 'Any';
                this.authService
                  .getStateList({
                    id: this.partnerPreferences.country_name,
                  })
                  .subscribe((result) => {
                    this.partnerLocationStates = result.data;
                    this.MatchesAtAGlance();
                  });
              } else {
                this.authService
                  .getStateList({
                    id: this.partnerPreferences.country_name,
                  })
                  .subscribe((result) => {
                    this.partnerLocationStates = result.data;
                    if (this.partnerLocationStates.length > 0) {
                      let counter = 0;
                      this.partnerLocationStates.forEach((state) => {
                        if (
                          this.partnerPreferences.state_name == state.id
                        ) {
                          this.partnerLocationStateName =
                            state.state_name;
                        }
                        counter++;
                        // // console.log('na',this.partnerLocationStates.length, counter)
                        if (
                          this.partnerLocationStates.length == counter
                        ) {
                          //  // console.log('na',this.partnerLocationStates.length, counter)
                          this.MatchesAtAGlance();
                        }
                      });
                    } else {
                      // // console.log('na',this.partnerLocationStates.length)
                      this.MatchesAtAGlance();
                    }
                  });
              }

              if (this.partnerPreferences.income_currency != '') {
                this.countries.forEach((country) => {
                  if (
                    this.partnerPreferences.income_currency ==
                    country.id
                  ) {
                    this.CurrencyCountryName = country.currency;
                  }
                });
              }
            }
          })
      }
        
        this.detailLoader = false;
        this.getUserProfileFeilds();
    },
    (error) => {
      this.toastrService.error('Some thing went wrong!!');
      this.detailLoader = false;
    });
  }
  getUserProfileFeilds() {
    let mydata = {
      user_id: this.user_id,
    };
    this.myProfileService.getUserProfileFeilds(mydata).subscribe((res) => {
      this.myProfileDetail = res.userDetails;
      this.detailLoader = false;
    });
  }


  // openModal1() {
  //   if(this.ispakage==true && this.cardpremiumCheck==false) {
  //      this.cardpremiumCheck=true;
  //       $('#upgradeModal1').click();
  //        //this.blockModal.nativeElement.click()
  //   }
  //      this.ispakage=false;
  //   //console.log("pakage false");

  //   }

  ngAfterViewInit() {
    if (!this.aboutUser?.isBlocked) {
      this.addToViewedByOther();
    }
  }
  addToViewedByOther() {
    this.user_id = this.cookieService.get('user_id');
    let data = {
      profile_id: this.pageId,
      viewed_by: this.user_id,
    };
    this.myProfileService.addToViewedByOther(data).subscribe((res) => {
    });
  }

  MatchesAtAGlance() {
    this.count = 0;
    if (
      this.partnerPreferences.age_from <= this.age &&
      this.partnerPreferences.age_to >= this.age
    ) {
      this.matchAge = true;
      this.count++;
      this.conversationStarter.push('Both of you have the same Age');
    } else {
      this.matchAge = false;
    }

    if (
      this.partnerProfileDetail.height >= this.partnerPreferences.height_from &&
      this.partnerProfileDetail.height <= this.partnerPreferences.height_to
    ) {
      this.matchHeight = true;
      this.count++;
      this.conversationStarter.push('Both of you have the same Height');
    } else {
      this.matchHeight = false;
    }

    this.text = this.partnerPreferences.marital_status;

    let result = this.text.indexOf(this.partnerProfileDetail.marital_status);

    if (result == -1 && this.text != 'Any Of These') {
      this.matchMaritalStatus = false;
    } else {
      this.matchMaritalStatus = true;
      this.count++;
      this.conversationStarter.push('You are both Single');
    }

    this.text1 = this.partnerPreferences.religion;
    let result1 = this.text1.indexOf(this.partnerProfileDetail.religion);
    if (
      result1 == -1 &&
      this.text1 != 'Any Religion' &&
      this.text1 != 'No Religion'
    ) {
      this.matchReligion = false;
    } else {
      this.matchReligion = true;
      this.count++;
      this.conversationStarter.push('Both of you have the same Religious Views');
    }

    this.text3 = this.partnerPreferences.languages;

    let result3 = this.text3.indexOf(this.partnerProfileDetail.languages);
    if (result3 == -1 && this.text3 != 'Any Language') {
      this.matchFirstLanguage = false;
    } else {
      this.matchFirstLanguage = true;
      this.count++;
      this.conversationStarter.push('Both of you have the same Preferred Language');
    }

    this.text2 = this.partnerPreferences.education;

    let result2 = this.text2.indexOf(this.partnerProfileDetail.qualification);

    if (
      result2 == -1 &&
      this.text2 != "Doesn't Matter" &&
      this.text2 != 'Does Not Matter'
    ) {
      this.matchEducation = false;
    } else {
      this.matchEducation = true;
      this.count++;
      this.conversationStarter.push('Both of you have the same Education');
    }

    if (
      this.partnerLocationCountryName ==
      this.partnerProfileDetail.living_in_country || this.partnerLocationCountryName == 'Any'
    ) {
      this.matchCountryLivingIn = true;
      // // console.log("country matched");
      this.count++;
      this.conversationStarter.push('Both of you live in the same Country');
    } else {
      this.matchCountryLivingIn = false;
      // // console.log("country Not matched");  
    }

    if (
      this.partnerLocationStateName == this.partnerProfileDetail.state_name ||
      (this.partnerLocationStateName == 'Any' &&
        this.partnerLocationCountryName ==
        this.partnerProfileDetail.living_in_country) || (this.partnerLocationStateName == 'Any' &&
          this.partnerLocationCountryName ==
          'Any')
    ) {
      this.matchStateLivingIn = true;
      //// console.log("state matched");
      this.count++;
      this.conversationStarter.push('Both of you are from the same State/Province');
    } else {
      this.matchStateLivingIn = false;
    }
    this.text5 = this.partnerPreferences.residency_status;

    let result5 = this.text5.indexOf(
      this.partnerProfileDetail.residency_status
    );

    if (
      result5 == -1 &&
      this.text5 != "Doesn't Matter" &&
      this.text5 != 'Does Not Matter'
    ) {
      this.matchResidencyStatus = false;
    } else {
      this.matchResidencyStatus = true;
      this.count++;
      this.conversationStarter.push('Both of you have the same Residency Status');
    }

    if (
      this.partnerPreferences.annual_income == 'OpenToAll' ||
      this.partnerPreferences.annual_income == 'Open To All'
    ) {
      // // console.log("matchAnnualIncome");
      this.matchAnnualIncome = true;
      this.count++;
      this.conversationStarter.push('Both of you have the same Annual Income')
    } else if (
      this.CurrencyCountryName == this.partnerProfileDetail?.income_currency &&
      +this.partnerProfileDetail.annual_income >=
      +this.partnerPreferences.income_range_from &&
      +this.partnerProfileDetail.annual_income <=
      +this.partnerPreferences.income_range_to
    ) {
      this.matchAnnualIncome = true;
      this.count++;
    } else {
      this.matchAnnualIncome = false;
    }

    const n = 10; // number of elements we want to get
    // const shuffledArray = this.conversationStarter.sort(
    //   () => 0.5 - Math.random()
    // ); // shuffles array
    // const resultStarter = shuffledArray.slice(0, n); // gets first n elements after shuffle
    const resultStarter = this.conversationStarter.slice(0, n); // gets first n elements after shuffle

    this.newArray = resultStarter.slice();

    this.detailLoader = false

    this.showMatchesIcon = true;
  }

  actionSelected(event: any, profile_id: any) {
    let std = event.target.id;
    this.selectId = profile_id;
    if (std == 'add_to_shortlist') {
      let pload = {
        user_id: this.user_id,
        profile_id: this.selectId,
        action: 'add_to_shortlist',
      };
      this.myProfileService.addToShortlist(pload).subscribe((result) => {
        if (result.status) {
          this.toastrService.success(
            result.message
          );
          this.getMoreAboutUser();
          this.getDetail();
        } else {
          this.toastrService.error(result.message);
        }
      });
    } else if (std == 'block_profile') {
      let pload = {
        user_id: this.user_id,
        profile_id: this.selectId,
        action: 'block_profile',
      };
      this.myProfileService.actionOnProfile(pload).subscribe((result) => {
        if (result.status) {
          this.toastrService.success(result.message);
          this.getMoreAboutUser();
          this.getDetail();

        } else {
          this.toastrService.error(result.message);
        }
      });
    } else if (std == 'report_profile') {
      let pload = {
        user_id: this.user_id,
        profile_id: this.selectId,
        action: 'report_profile',
      };
      this.myProfileService.reportProfile(pload).subscribe((result) => {
        if (result.status) {
          this.toastrService.success(
            result.message
          );
          this.getMoreAboutUser();
          this.getDetail();
        } else {
          this.toastrService.error(result.message);
        }
      });
    } else if (std == "back_to_shortlist") {
      let pload = {
        user_id: this.user_id,
        profile_id: this.selectId,
        action: 'back_to_shortlist'
      }
      this.myProfileService.addToShortlist(pload).subscribe(
        (result) => {
          if (result.status) {
            this.toastrService.success(
              result.message
            );
            this.getMoreAboutUser();
            this.getDetail()
          } else {
            this.toastrService.error(result.message);
          }
        })


    } else if (std == "remove_from_shortlist") {
      let pload = {
        user_id: this.user_id,
        profile_id: this.selectId,
        action: 'remove_from_shortlist'
      }
      this.myProfileService.addToShortlist(pload).subscribe(
        (result) => {
          if (result.status) {
            this.toastrService.success(
              result.message
            );
            this.getMoreAboutUser();
            this.getDetail()
          } else {
            this.toastrService.error(result.message);
          }
        })

    }
  }

  addToFavoriteList(profile_id: any) {
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      // action  : "add_to_shortlist"
    };
    this.myProfileService.addToFavoriteList(pload).subscribe((result) => {
      if (result.status) {
        this.toastrService.success(result.message);
        this.getMoreAboutUser();
        this.getDetail();
      } else {
        this.toastrService.error(result.message);
      }
    });

  }

  AddtoMyShortlist(profile_id: any) {
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      action: 'add_to_shortlist',
    };
    this.myProfileService.addToShortlist(pload).subscribe((result) => {
      if (result.status) {
        this.toastrService.success(
          result.message
        );
        this.getMoreAboutUser();
        this.getDetail();
      } else {
        this.toastrService.error(result.message);
      }
    });

  }

  connectNow() {
    let pload = {
      user_id: this.user_id,
      profile_id: this.profileId,
    };
    this.myProfileService.connectNow(pload).subscribe((result) => {
      if (result.status) {
        this.toastrService.success(result.message + '' + '!');
        this.getMoreAboutUser();
        this.getDetail();
      } else {
        this.toastrService.error(result.message);
      }
    });

  }

  connectCancelled(id, Name, Photo, approve) {
    this.profileId = id;
    this.displayName = Name;
    if (approve == 1) {
      this.profileSrc = Photo
    }
    $('#openConnectModal').click();
  }
  actionOnProfile() {

    let pload = {
      user_id: this.user_id,
      profile_id: this.profileId,
      action: "un_decline",
    };

    this.myProfileService.actionOnProfile(pload).subscribe(
      (result) => {
        if (result.status) {
          this.toastrService.success(result.message);
          this.getMoreAboutUser();
          this.getDetail();
        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        this.toastrService.error('Some thing went wrong!!');
      }
    );
  }

  goNow() {
    this.router.navigate(['matches']);
  }

  //  communication inbox actions 
  doActionOnRequestsRespond(action: any, profile_id: any) {
    if (action == 'accept') {
      let pload = {
        user_id: this.user_id,
        profile_id: profile_id,
        action: action,
      };
      console.log(pload);
      this.myProfileService.doActionOnRequests(pload).subscribe(
        (result) => {
          if (result.status) {
            if (action == 'accept') {
              this.toastrService.success(
                'Your message has been sent to ' + '' + this.partnerProfileDetail.display_name
              );
              this.router.navigate(['accepted']);
            }
          } else {
            this.toastrService.error(result.message);
          }
        },
        (error) => {
          this.toastrService.error('Some thing went wrong!!');
        }
      );
    }
  }

  doActionOnRequests(action: any, profile_id: any) {
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      action: action,
    };
    console.log(pload);
    this.myProfileService.doActionOnRequests(pload).subscribe(
      (result) => {
        if (result.status) {
          this.toastrService.success(result.message);
          this.getMoreAboutUser();
          this.getDetail();

        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        this.toastrService.error('Some thing went wrong!');
      }
    );
  }

  // communication sent actions

  doActionOnRequestsSend(action: any, profile_id: any) {
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      action: action,
    };
    console.log(pload);

    this.myProfileService.doActionOnRequests(pload).subscribe(
      (result) => {
        if (result.status) {
          this.toastrService.success(result.message);
          this.getMoreAboutUser();
          this.getDetail();

        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        this.toastrService.error('Some thing went wrong!!');
      }
    );
  }

  // communication cancelled actions
  doActionOnRequestscancelled(action: any, profile_id: any) {
    console.log(action)
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      action: action,
    };
    console.log(pload);
    this.myProfileService.doActionOnRequests(pload).subscribe(
      (result) => {
        if (result.status) {
          this.toastrService.success(result.message);
          this.getMoreAboutUser();
          this.getDetail();


        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        this.toastrService.error('Some thing went wrong!!');
      }
    );
  }

  // communication blocked actions
  doActionOnRequestsBlocked(action: any, profile_id: any) {
    console.log(profile_id)
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      action: action,
    };
    console.log(pload);
    this.myProfileService.doActionOnRequests(pload).subscribe(
      (result) => {
        if (result.status) {
          this.toastrService.success(result.message);
          this.getMoreAboutUser();
          this.getDetail();
        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        this.toastrService.error('Some thing went wrong!');
      }
    );
  }


  // reloadCurrentPage() {
  //   window.location.reload();
  //  }

  doActionOnRequestsFollow(action: any, profile_id: any) {
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      action: action,
    };

    this.myProfileService.doActionOnRequests(pload).subscribe(
      (result) => {
        if (result.status) {
          this.toastrService.success(result.message);
          this.getMoreAboutUser();
          this.getDetail();
        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        this.toastrService.error('Some thing went wrong!!');
      }
    );
  }


  connectNowModal() {

    let pid: any = localStorage.getItem('package_id');
    if (pid > 1) {
      $('#openModal2').click();
    } else {
      $('#openModal1').click();
    }
  }

  openlight(url: any, index: number) {
    this.galleryPath = url.photo_url;
    this.showCaption = url.caption
    this.index = index;
    console.log(url, "url")
  }

  nextSlides() {
    this.index = this.index + 1;
    this.indexNext = this.index;
    if (this.indexNext != this.myPhotos.length) {
      this.galleryPath = this.myPhotos[this.indexNext].photo_url;
      this.showCaption = this.myPhotos[this.indexNext].caption;
      console.log(this.indexNext, 'photoindex');
    } else if (this.indexNext == this.myPhotos.length) {
      this.galleryPath = this.myPhotos[0].photo_url;
      this.showCaption = this.myPhotos[0].caption;
      this.index = 0;
      console.log(this.indexNext, 'photoindex');
    }
  }

  previousSlides() {
    this.index = this.index - 1;
    this.indexPrevious = this.index;
    if (this.indexPrevious != -1) {
      this.galleryPath = this.myPhotos[this.indexPrevious].photo_url;
      this.showCaption = this.myPhotos[this.indexPrevious].caption;
      console.log(this.indexPrevious, 'photoindex');
    } else if (this.indexPrevious == -1) {
      this.galleryPath = this.myPhotos[(this.myPhotos.length - 1)].photo_url;
      this.showCaption = this.myPhotos[(this.myPhotos.length - 1)].caption;
      this.index = (this.myPhotos.length - 1)
      console.log(this.indexPrevious, 'photoindex');
    }
  }

  getRequestAccess(id) {
    this.access_for = id;
    if (this.access_for == 'contact_detail') {
      this.requestFor = 'Contact';
    } else if (this.access_for == 'astro_detail') {
      this.requestFor = 'Astro';
    }
    // console.log("hello ", id, this.partnerProfileDetail, "||", this.myDetails)
    if (this.myProfileDetail.package_id && (this.myProfileDetail.package_id > 1 && this.myProfileDetail.package_id != 4)) {
      // !this.myRequestDetails.request_contact_detail &&
      if (id == 'contact_detail') {
        $('#requestAccess').click();
      } else if (id == 'astro_detail') {
        $('#requestAccess').click();
        console.log(id)
      }
    } else {
      $('#upgradeModal1').click();
    }
  }

  procced() {
    let data = {
      profile_id: this.pageId,
      user_id: this.user_id,
      access_for: this.access_for
    }
    this.myProfileService.requestAccess(data).subscribe(
      (result) => {
        if (result.status) {
          this.getprofileDetail();
          this.toastrService.success(result.message);

        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        this.toastrService.error('Some thing went wrong!!');
      }
    );
  }

  blockReportId(user) {
    if (user.label == "Other") {
      this.otherReason = true;
      this.reportReason = ""
    } else {
      this.otherReason = false
      this.reportReason = user.label
    }
    console.log(user, "id and label", this.reportReason)
  }

  blockAndReportNow() {
    let data = {
      user_id: this.user_id,
      profile_id: this.profileId,
      action: "block_and_report",
      reason: this.reportReason
    };
    // console.log("api called", data)
    this.myProfileService.doActionOnRequests(data).subscribe(
      (result: any) => {
        if (result.status) {
          this.toastrService.success(result.message);
        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        // unused code
        // this.ChatId = 0;
        // this.toastrService.error();
      }
    );
  }

  userBlockReport(user) {
    this.profileId = this.profileId
    this.clear();
  }

  clear() {
    // console.log("before ", this.reportReason)
    this.reportReason = ""
    this.exceedLimit = false;
    this.otherReason = false
    let ele = document.getElementsByName("Serious");
    // before sonar
    // for (var i = 0; i < ele.length; i++) {
    //   var element = ele[i] as HTMLInputElement;
    //   element.checked = false;
    // }
    //after sonar
    for (const element of Array.from(ele) as HTMLInputElement[]) {
      element.checked = false;
    }
  }

  wordCounter(event) {
    let para = this.wordCountForm?.value?.description;
    if (para.trim().length > 0) {
      this.wordCount = this.texts ? this.texts.nativeElement.value.split(/\s+/) : 0;
      this.words = this.wordCount ? this.wordCount.length : 0;
      if (this.words >= 21 && event.code != 'Backspace' && event.code != 'Delete') {
        this.wordError();
        // before sonarqube
        // const pattern = /[a-zA-Z0-9&_\.!,-\s()"']/g;
        const pattern = /[a-zA-Z0-9&_.!,\-\s()"']/g;
        if (!pattern.test(event.target.value)) {
          event.preventDefault();
          return false;
        } else {
          // before sonarqube
          // event.target.value = event.target.value.replace(/[a-zA-Z0-9&_\.!,-\s()']/g, "");
          // this.tellUsMore = this.tellUsMore.replace(/  +/g, ' ');
          event.target.value = event.target.value.replace(/[a-zA-Z0-9&_.,!\-\s()']/g, "");
          this.tellUsMore = this.tellUsMore.replace(/ {2,}/g, ' ');
          this.wordCountForm.patchValue({ 'description': this.tellUsMore.trim() + ' ' });
          this.words = this.tellUsMore.split(/\s+/).length
          event.preventDefault();
          return false;
        }
      } else {
        this.wordError();
        this.tellUsMore = this.wordCountForm?.value?.description;
      }
    } else {
      this.wordError();
      this.words = 0;
    }
  }

  wordError() {
    if (this.words > 20) {
      this.exceedLimit = true;
    } else {
      this.exceedLimit = false;
    }
  }

  pasteNumeric6(event) {
    setTimeout(() => {
      let para = this.wordCountForm?.value?.description;
      if (para.trim().length > 0) {
        this.wordCount = this.texts ? this.texts.nativeElement.value.split(/\s+/) : 0;
        this.words = this.wordCount ? this.wordCount.length : 0;
        if (this.words >= 21) {
          this.wordCount = this.wordCount.slice(0, 20);
          this.words = this.wordCount ? this.wordCount.length : 0;
          this.wordCountForm.patchValue({ 'description': this.wordCount.join(" ") });
          this.wordError();
        }
      } else {
        this.wordError();
        this.words = 0;
      }
    }, 500);
  }

  pos = "";
  compare() {
    let pre = this.wordCount?.value?.description;
    console.log(pre, this.wordCount?.value?.description)
    setTimeout(() => {
      this.pos = this.wordCount?.value?.description;
      if (pre == this.pos) {
        // console.log("change", "itime")
      }
    }, 500);
    if (pre == this.pos) {
      // console.log("change", "out-time")
    }
  }

}
