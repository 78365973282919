<div class="w-100 bg-maroon mb-3">
  <app-header></app-header>
</div>

<div class="bg-light py-5">
  <div class="container">
    <h2 class="text-center mt-5">Feel Free To Contact Us</h2>
    <p class="plan-sub-heading lead text-center">How may we be of assistance?</p>
    <div class="seperator-line centered mb-3"></div>
    <div class="contact-form form-group">
      <form [formGroup]="contactForm">
        <div class="row">
          <div class="col-sm-6">
            <label for="name" id="name" class="form-label mb-2 py-1 fw-bold h6">Name<span class="text-danger ms-1">*</span></label>
            <input type="text" class="form-control" name="yourname" id="name" formControlName="full_name" placeholder="Enter" required="" [ngClass]="{
              'is-invalid': submitted && f.full_name.errors}" (input)="NameSpaceNotAllowed($event)"
              (paste)="pasteText($event)" (keypress)="omit_special_char($event)">
            <div *ngIf="submitted && f.full_name.errors">
              <div *ngIf="f.full_name.errors.required" class="form-label mb-2 py-1 fw-bold text-danger">Name
                is required
              </div>
              <div *ngIf="f.full_name.errors.pattern" class="form-label mb-2 py-1 fw-bold text-danger">Name
                is not valid</div>
            </div>
          <div class="col-12 mt-3">
            <div class="row">
              <label for="Mobile" class="form-label mb-2 py-1 fw-bold h6">Mobile Number <span class="text-danger ms-1">
                  *</span></label>
              <div class="col-12 my-2 my-md-0 col-md-4 mb-md-3 pe-md-0">
                <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="country_code" [formControlName]="'countryCode'" [(ngModel)]="phoneCode"
                  placeholder="Select Country Code" dropdownPosition="auto" [virtualScroll]="true" (change)="OnchangeCountryCode($event)">
                  <ng-template ng-label-tmp let-item="item">
                    <img height="20" width="20" src="assets/images/flag/Flag_of_{{item.country_name | replaceSpaceWithUnderscore}}.svg" alt=""
                      style="margin-right:5px;" alt="image" title="Country"/>
                    <b> {{item.country_name}}</b>{{item.country_code}}
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <img height="20" width="20" src="assets/images/flag/Flag_of_{{item.country_name | replaceSpaceWithUnderscore}}.svg" alt=""
                      style="margin-right:5px;" alt="image" title="Country"/>
                    <b> {{item.country_name}}</b>{{item.country_code}}
                  </ng-template>
                </ng-select>
    
                <div *ngIf="submitted && f.countryCode.errors">
                  <div class="form-label mb-2 py-1 fw-bold text-danger">Country code is required</div>
                </div>
              </div>
              <div class="col-12 col-md-8">
                <input type="text" class="form-control" id="Mobile" placeholder="Enter" [formControlName]="'mobileNo'" [ngClass]="{ 'is-invalid':
                  submitted && f.mobileNo.errors }" (input)="keyPressNumeric($event)" (paste)="pasteNumeric($event)" autocomplete=""
                  [ngModel]="phoneNo">
                <div *ngIf="submitted && f.mobileNo.errors">
                  <div *ngIf="f.mobileNo.errors.required">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile number is required</div>
                  </div>
                  <div *ngIf="f.mobileNo.errors.minlength">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be at least 6 characters</div>
                  </div>
                  <div *ngIf="f.mobileNo.errors.maxlength">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be at most 15 characters</div>
                  </div>
                </div>
                <div *ngIf="!submitted">
                  <div *ngIf="minNumberLength ">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be at least 6 characters</div>
                  </div>
                  <div *ngIf="maxNumberLength">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">Mobile Number must be at most 15 characters</div>
                  </div>
                </div>
                <div *ngIf="submitted && errors">
                  <div *ngIf="errors.mobileNo">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">{{ errors?.mobileNo }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          </div>

          <div class="col-sm-6">
            
            <label for="staticEmail" id="email" class="form-label mb-2 py-1 fw-bold h6">Email<span class="text-danger ms-1">*</span></label>
            <input type="email" class="form-control" name="email" id="email" formControlName="email" placeholder="Enter" required="" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" autocomplete="">
            <div *ngIf="submitted && f.email.errors">
              <div *ngIf="f.email.errors.required">
                <div class="form-label mb-2 py-1 fw-bold text-danger">Email is required</div>
              </div>
              <div *ngIf="f.email.errors.email || f.email.errors.pattern">
                <div class="form-label mb-2 py-1 fw-bold text-danger">Email must be a valid email address</div>
              </div>
            </div>
          <div class="col-12 mt-3">
            <label for="subject" id="subject" class="form-label mb-2 py-1 fw-bold h6">Subject<span class="text-danger ms-1">*</span></label>
            <input type="text" class="form-control" name="subject" id="subject" formControlName="subject" placeholder="Enter" required="" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" autocomplete="">
            <div *ngIf="submitted && f.subject.errors">
              <div *ngIf="f.subject.errors.required" class="form-label mb-2 py-1 fw-bold text-danger">Subject is required</div>
          </div>
        </div>
          </div>
          
        </div>
        <label for="message" id="message" class="form-label mb-2 py-1 fw-bold h6">Message<span class="text-danger ms-1">*</span></label>
        <textarea name="message" id="wordCount" class="form-control" formControlName="message" placeholder="Enter" required="" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" autocomplete=""  placeholder="message" maxlength=""
        (paste)="pasteNumeric6($event)" (change)="compare()"
            (keypress)="wordCounter($event)" (keyup.backspace)="wordCounter($event)"></textarea>
        <div *ngIf="submitted && f.message.errors">
        <div *ngIf="f.message.errors.required">
          <div class="form-label mb-2 py-1 fw-bold text-danger">Message is required</div>
        </div>
        </div>
        <p class="p-xsm p-1 fw-bold text-danger" *ngIf="exceedLimit">You cannot enter more than the maximum of 100 words</p>
        <p class="p-xsm p-1 text-muted">Limit Max 100 Words Entered- {{words}} Words</p>
        <div class="message-button text-center mt-4">
            <i *ngIf="buttonClicked" class="fa fa-spinner fa-spin" style="display: none;"></i><button class="btn btn-danger text-light btn-lg"  [disabled]="buttonClicked" (click)="Submit()">{{btnLable}}</button>
        </div>
      </form>
    </div>
  </div>

</div>

<app-footer></app-footer>