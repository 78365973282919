<header class="navbar-dark bg-white">
  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand mr-5" href="javascript:void(0);"><img src="assets/images/logo.png" alt="" width="150"></a>
      <button class="navbar-toggler bg-dark" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav m-auto">
          <li class="nav-item active">
            <a class="nav-link mx-2 text-dark" href="javascript:void(0);" routerLinkActive="active-link"
              routerLink="dashboard">My Profile</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-2 text-dark" href="javascript:void(0);" routerLinkActive="active-link"
              routerLink="/matches">Matches</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-2 text-dark" href="javascript:void(0);" routerLinkActive="active-link"
              routerLink="/search">Search</a>
          </li>
          <li class="nav-item">
            <a class="nav-link mx-2 text-dark" href="javascript:void(0);" routerLinkActive="active-link"
              routerLink="/inbox"> Inbox</a>
          </li>

        </ul>
        <div class="form-inline my-2 my-lg-0">

          <div class="dropdown d-flex align-items-center">
            <div class="cursor-pointer w-50-h-50 rounded-circle">
              <img class="rounded-circle image-cover overflow-hidden" src="assets/images/real-wedding-img-1.jpg" alt="">
            </div>
            <span class="btn dropdown-toggle fs-3 px-2 text-danger" type="button" id="dropdownMenuButton1"
              data-bs-toggle="dropdown" aria-expanded="false">
            </span>

            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item slide-10 text-danger" href="javascript:void(0)"><i
                    class="fas fa-user-alt me-2"></i> Profile</a></li>
              <li><a class="dropdown-item slide-10 text-danger" href="javascript:void(0)"><i
                    class="fas fa-cog me-2"></i> Account Settings</a></li>
              <li><a class="dropdown-item slide-10 text-danger" href="javascript:void(0)"><i
                    class="fas fa-mail-bulk me-2"></i> Email / SMS Alerts </a></li>
              <li><a class="dropdown-item slide-10 text-danger" href="javascript:void(0)"><i
                    class="fas fa-user-lock me-2"></i>Privacy Options</a></li>
              <li><a class="dropdown-item slide-10 text-danger" href="javascript:void(0)"><i
                    class="fas fa-sort-numeric-down me-2"></i> Contact Filters</a></li>
              <li><a class="dropdown-item slide-10 text-danger" href="javascript:void(0)" routerLink="/home"><i
                    class="fas fa-sign-out-alt me-2 fs-5 "></i>Logout</a></li>
            </ul>
          </div>





        </div>
      </div>
    </nav>
  </div>
</header>





<!-- Content -->


<section class="my-5">

  <div class="container mx-auto mx-1200">
    <div class="row gx-4">

      <div class="col-3">
        <div class="border border-1 shadow-sm rounded">
          <div class="h-220 rounded position-relative">
            <img class="image-cover rounded" src="assets/images/real-wedding-img-2.jpg" alt="">
            <div
              class="position-absolute bg-danger btn btn-pos w-30-h-30 rounded-circle d-flex align-items-center justify-content-center">
              <i class="fas fa-plus text-light fs-5"></i>
            </div>
          </div>
          <div class="row px-2 py-4 m-0">
            <div class="col-8">
              <h5 class="mb-0">User Name</h5>
              <p class="p-sm mb-0">SH80505446</p>
            </div>
            <div class="col-4 my-auto text-end">
              <a href="javascript:void(0)" class="text-danger"> Edit </a>
            </div>
          </div>
          <div class="row px-2 py-4 m-0 border border-1 border-star-0 border-end-0">
            <div class="col-8">
              <p class="mb-0">Account Type</p>
              <p class="p-sm mb-0 fw-bold"> Free Membership</p>
            </div>
            <div class="col-4 my-auto text-end">
              <a href="javascript:void(0)" class="text-danger">
                Upgrade </a>
            </div>
          </div>
          <div class="row px-2 py-4 m-0">
            <div class="col-8">
              <p class="mb-0 ">Mobile no. is verified</p>
              <p class="p-sm mb-0">
                <a href="javascript:void(0)" class="text-danger">Verify your ID</a>
              </p>
            </div>
            <div class="col-4 my-auto text-end">
              <a href="javascript:void(0)" class="text-danger"> Edit </a>
            </div>
          </div>

        </div>
      </div>




      <div class="col-6">

        <div class="">
          <div class="row">
            <div class="col-12 pb-3 px-0">
              <h5 class="mb-0">Your Activity Summary</h5>
            </div>
          </div>
          <div class="row rounded-3 overflow-hidden">
            <div class="col-4 bg-white py-2">
              <h5 class="fw-bold mb-0"> 0 </h5>
              <p class="mb-0">No Pending Invitations</p>
            </div>
            <div class="col-4 bg-white py-2 border border-1 border-top-0 border-bottom-0">
              <h5 class="fw-bold mb-0"> 0 </h5>
              <p class="mb-0"> Accepted Invitations</p>
            </div>
            <div class="col-4 bg-white py-2">
              <h5 class="fw-bold mb-0"> 0 </h5>
              <p class="mb-0"> No Recent Visitors</p>
            </div>
          </div>
        </div>


        <div class="mt-4">
          <div class="row rounded-3 overflow-hidden">
            <div class="col-4 bg-white py-2">
              <p class="mb-0 text-center p-sm">Only
                <a href="javascript:void(0)" class="text-danger"> Premium </a>
                Members can avail these benefits
              </p>
            </div>
            <div class="col-4 bg-white py-2 border border-1 border-top-0 border-bottom-0">
              <h5 class="fw-bold mb-0"> 0 </h5>
              <p class="mb-0"> Contacts viewed</p>
            </div>
            <div class="col-4 bg-white py-2">
              <h5 class="fw-bold mb-0"> 0 </h5>
              <p class="mb-0"> Chats initiated</p>
            </div>
          </div>
        </div>


        <div class="">
          <div class="row mt-4">
            <div class="col-12 pb-3 px-0">
              <h5 class="mb-0">Improve your Profile</h5>
            </div>
          </div>
          <div class="row rounded-3 overflow-hidden">
            <div class="col-4">

            </div>
            <div class="col-8">
            </div>
          </div>
        </div>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</section>

<footer class="bg-dark footer pt-5 pb-4">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-3">
        <img src="assets/images/white-logo.png" alt="" width="150">
        <small class="d-block mb-3 text-muted mt-2">© 2021-2022</small>
      </div>
      <div class="col-6 col-md-2">
        <h5>Need Help?</h5>
        <ul class="list-unstyled text-small">
          <li><a class="text-muted" href="javascript:void(0);">Member Login</a></li>
          <li><a class="text-muted" href="javascript:void(0);">Sign-Up</a></li>
          <li><a class="text-muted" href="javascript:void(0);">Partner Search</a></li>
          <li><a class="text-muted" href="javascript:void(0);">Customer Support</a></li>
          <li><a class="text-muted" href="javascript:void(0);">Site Map</a></li>
        </ul>
      </div>
      <div class="col-6 col-md-2">
        <h5>Company</h5>
        <ul class="list-unstyled text-small">
          <li><a class="text-muted" href="javascript:void(0);">About</a></li>
          <li><a class="text-muted" href="javascript:void(0);">Blog</a></li>
          <li><a class="text-muted" href="javascript:void(0);">Contact Us</a></li>
          <li><a class="text-muted" href="javascript:void(0);">Services</a></li>
        </ul>
      </div>
      <div class="col-6 col-md-2">
        <h5 class="fs-4">Privacy & You</h5>
        <ul class="list-unstyled text-small">
          <li><a class="text-muted" href="javascript:void(0);">Terms of Use</a></li>
          <li><a class="text-muted" href="javascript:void(0);">Privacy Policy</a></li>
          <li><a class="text-muted" href="javascript:void(0);">Safety Tips</a></li>
          <li><a class="text-muted" href="javascript:void(0);">Report Misuse</a></li>
        </ul>
      </div>
      <div class="col-6 col-md-2">
        <h5>More</h5>
        <ul class="list-unstyled text-small">
          <li><a class="text-muted" href="javascript:void(0);">VIP Wedding</a></li>
          <li><a class="text-muted" href="javascript:void(0);">Select Wedding</a></li>
          <li><a class="text-muted" href="javascript:void(0);">Wedding Centres</a></li>
          <li><a class="text-muted" href="javascript:void(0);">Success Stories</a></li>
          <li><a class="text-muted" href="javascript:void(0);">Life Partner Meet</a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>