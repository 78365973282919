<footer class="footer py-2">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-2 mb-3 mb-md-0">
        <img src="assets/images/main_logo.svg" alt="" width="96px">
      </div>

      <div class="col-12 mt-1 col-md-2">
        <p class="text-light mb-0 p-sm">© 2022-2023</p>
      </div>

      <div class="col-6 col-md-2">
        <ul class="list-unstyled text-small mb-0">
          <li>
            <a class="text-light p-sm" href="javascript:void(0);" routerLinkActive="active"
              routerLink="/contact">Contact Us</a>
          </li>
          <li>
            <a class="text-light p-sm" href="javascript:void(0);" routerLinkActive="active" routerLink="/faq">FAQ</a>
          </li>

        </ul>
      </div>

      <div class="col-6 col-md-2">
        <ul class="list-unstyled text-small mb-0">

          <li><a class="text-light p-sm" href="javascript:void(0);" routerLinkActive="active" routerLink="/safety-tips">
              Safety Tips </a></li>
          <li><a class="text-light p-sm" href="javascript:void(0);" routerLinkActive="active"
              routerLink="/Disclaimer">Disclaimer</a></li>
        </ul>
      </div>

      <div class="col-6 col-md-2">
        <ul class="list-unstyled text-small mb-0">
          <li>
            <a class="text-light p-sm" href="javascript:void(0);" routerLinkActive="active" routerLink="/Terms">
              Terms & Conditions
            </a>
          </li>
          <li><a class="text-light p-sm" href="javascript:void(0);" routerLinkActive="active" routerLink="/Privacy">
              Privacy Policy </a>
          </li>
        </ul>
      </div>

      <div class="col-6 col-md-2">
        <ul class="list-unstyled text-small mb-0">

          <li><a class="text-light p-sm" href="javascript:void(0);" routerLinkActive="active" routerLink="/payment">
              Payment Policy </a>
          </li>
          <li>
            <a type="button" class="text-light p-sm" data-bs-toggle="modal" data-bs-target="#CookiesModal">
              Cookie Preferences </a>
          </li>
        </ul>
      </div>
    </div>
  </div>


  <div class="position-fixed bottom-0 right-0 m-2 z-index-999" *ngIf="isShow" (click)="gotoTop()">
    <button class="btn w-35-h-35 btn-light rounded-circle p-0 center border-danger border-2">
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>

</footer>

<!-- Cookies Modal -->
<div class="modal fade" id="CookiesModal" tabindex="-1" aria-labelledby="CookiesModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="fw-600" id="CookiesModalLabel">Cookie Preferences </h5>
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>
      </div>
      <div class="modal-body">

        <div class="row">
          <div class="col-12 col-md-4">
            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <button class="btn btn-outline-danger p-sm mb-2 active" id="v-pills-home-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
                aria-selected="true">Cookie Details</button>
              <button class="btn btn-outline-danger p-sm mb-2" id="v-pills-profile-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile"
                aria-selected="false">Strictly Necessary Cookies </button>
              <button class="btn btn-outline-danger p-sm mb-2" id="v-pills-messages-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages"
                aria-selected="false">
                Functional Cookies </button>
              <button class="btn btn-outline-danger p-sm mb-2" id="v-pills-settings-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings"
                aria-selected="false">Analytics and Performance Cookies</button>

              <button class="btn btn-outline-danger p-sm mb-2" id="v-pills-cookiesTabFifth-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-cookiesTabFifth" type="button" role="tab"
                aria-controls="v-pills-cookiesTabFifth" aria-selected="false"> Targeting Cookies</button>

            </div>
          </div>
          <div class="col-12 col-md-8">

            <div class="tab-content" id="v-pills-tabContent">
              <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                aria-labelledby="v-pills-home-tab">
                <h6 class="fw-600">Cookie Details</h6>
                <p>
                  <strong>Click on the different category headings to find out more and change your cookie
                    preferences.</strong>
                  When you visit our Sites, we store or retrieve information on your browser using cookies and similar
                  technologies, which may be set
                  by us or other companies who provide services to Eternal Strings in connection with our Sites. Cookies
                  and the information they
                  collect might relate to you, your preferences or your device, and are used to make the Sites work as
                  you expect them to, store your
                  preferences, help us understand how people use our Sites, provide a more personalized web experience,
                  and create a profile of your
                  interests in order to show you relevant advertisements or content on other websites with the
                  participation of our advertising
                  partners as described below. For more information about how we use cookies, please see the cookie
                  section of our <a class="default_link" data-bs-dismiss="modal" href="javascript:void(0)"
                    routerLink="/Privacy">Privacy Policy</a> .
                </p>
                <p>
                  You can choose not to allow certain types of cookies via the settings, but that choice may impact your
                  experience and some features
                  on the Sites. Your choice is specific to the device, website, and browser you are using, and is
                  deleted when you clear your cookies.
                </p>
              </div>

              <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div class="row">
                  <div class="col-8">
                    <h6 class="fw-600">Strictly Necessary Cookies </h6>
                  </div>
                  <div class="col-4 text-end">
                    <p class="text-danger fw-500">Always active </p>
                  </div>
                </div>

                <p>
                  Strictly necessary cookies are needed for the website to function and cannot be switched off in our
                  systems. They enable core functions like carrying out network transmissions to help the Sites operate
                  or are set in response to actions made by you, such as setting your privacy preferences, logging in or
                  filling in forms. Strictly necessary cookies cannot be switched off in our systems, but you can
                  disable them by changing your browser settings. Disabling these cookies will affect how the Sites
                  function and may prevent you from using certain features on the Sites.
                </p>

              </div>

              <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">

                <div class="row">
                  <div class="col-8">
                    <h6 class="fw-600"> Functional Cookies </h6>
                  </div>
                  <div class="col-4 ">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                      <label class="form-check-label" for="flexSwitchCheckDefault"> Inactive </label>
                    </div>
                  </div>
                </div>

                <p>
                  You can opt in to allow functional cookies using this toggle. Functional cookies enable our Sites to
                  provide enhanced functionality and personalization on our Sites - for example, remembering your choice
                  of language or region. Blocking these cookies may impact your experience and some features on the
                  Sites. Your choice is specific to the device, website, and browser you are using, and is deleted when
                  you clear your browser’s cookies.

                </p>

              </div>

              <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <div class="row">
                  <div class="col-8">
                    <h6 class="fw-600"> Analytics and Performance Cookies </h6>
                  </div>
                  <div class="col-4  ">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault2">
                      <label class="form-check-label" for="flexSwitchCheckDefault2"> Inactive </label>
                    </div>
                  </div>
                </div>
                <p>
                  You can opt in to allow performance cookies using this toggle. Analytics and performance cookies allow
                  us to count visits and traffic sources so we can measure and improve the performance of our Sites.
                  They help us to know which pages are the most and least popular and see how visitors move around the
                  Sites. We don't use information collected by analytics and performance cookies for any advertising or
                  profiling purposes. Your choice is specific to the device, website, and browser you are using, and is
                  deleted when you clear your browser’s cookies.

                </p>
              </div>

              <div class="tab-pane fade" id="v-pills-cookiesTabFifth" role="tabpanel"
                aria-labelledby="v-pills-cookiesTabFifth-tab">
                <div class="row">
                  <div class="col-8">
                    <h6 class="fw-600"> Targeting Cookies </h6>
                  </div>
                  <div class="col-4 ">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault2">
                      <label class="form-check-label" for="flexSwitchCheckDefault2"> Inactive </label>
                    </div>
                  </div>
                </div>
                <p>
                  You can opt in to allow cookie-based tracking for personalized, targeted advertising through our
                  advertising partners as described
                  below, using this toggle. These cookies may be set through our Sites by our advertising partners. They
                  may be used by those
                  companies to build a profile of your interests and show you relevant advertisements or content on
                  other websites. Blocking these
                  cookies may impact your experience and some features on the Sites. If you do not allow these cookies,
                  you will still see
                  advertisements, but you may receive less relevant advertising or content and some features of our
                  Sites may be impacted. Your choice
                  is specific to the device, website, and browser you are using, and is deleted when you clear your
                  browser’s cookies.

                </p>
              </div>

            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-danger">Confirm My Choices </button>
        <button type="button" class="btn btn-danger">Allow All</button>
      </div>
    </div>
  </div>
</div>
