import { Component, OnInit } from '@angular/core';
import { Title/*, Meta */} from '@angular/platform-browser';
// unused import
// import { LabelType, Options } from '@angular-slider/ngx-slider';
// import { MatPaginator } from '@angular/material/paginator';
// import { isRestTypeNode } from 'typescript';
import { AuthService } from '../shared/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ViewportScroller } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { PartnerPreferenceService } from '../services/partner-preference.service';
import { MyProfileService } from '../services/my-profile.service';
import { NotifService } from '../services/notif.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css'],
})
export class InboxComponent implements OnInit {
  blockedProfileList: any=[];
  FollowUpProfileList: any=[];
  SkeltonLoader(i: number) {
    return new Array(i);
  }

  user_id: any;
  matches: any = [];
  sort_by: '';
  Filter_by: '';
  selectId: any;
  selectedIds: any = [];
  FilterBy: any = '';
  sendFilterBy: any = '';
  cancelledFilterBy: any = '';
  follow_upFilterBy: any = '';
  blockFilterBy: any = '';
  Filters: string = 'AllRequests';
  sendFilters: string = 'All';
  cancelledFilters: string = 'AllCancelled';
  follow_upFilters: string = 'follow_up';
  blockFilters: string = 'block';
  profileList: any = [];
  sendprofileList: any = [];
  cancelledprofileList: any = [];

  matchedProfilesCount: number = 0;
  shortlistedProfilesCount: number = 0;
  favouriteProfilesCount: number = 0;
  viewedByProfilesCount: number = 0;
  currentPage: number = 1;
  pageSize: number = 8;
  TotalRecord: number = 0;

  Responduserid: any;
  Responddisplayname: any;
  RespondProfilephoto: 'assets/images/banner-7.jpg';

  isRespond: boolean = true;

  blockedList: any = [];

  isInbox:boolean = false;
  isSend:boolean = false;
  isFollowUp:boolean = false;
  isCancelled:boolean = false;
  isBlock:boolean = false;
  isUnblock:boolean = false;
  isInbox_date:any= '27/01/2000';
  isSend_date: any='28/01/2000';
  isFollowUp_date: any='29/01/2000';
  isCancelled_date: any='30/01/2000';
  isBlock_date: any='31/01/2000';
  isUnblock_date: any='01/02/2000';
  historyProfileId:any;
  hidePageSize = true;
  encrypted_id: string | undefined;


  constructor(
    private titleService: Title,

    private authService: AuthService,
    private cookieService: CookieService,
    private scroller: ViewportScroller,
    private partnerPreferenceService: PartnerPreferenceService,
    private myProfileService: MyProfileService,
    private NotifService: NotifService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastrService: ToastrService,
    private CommonService: CommonService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('My Inbox | eternalstrings.com');

    this.user_id = parseInt(this.cookieService.get('user_id'));
    // this.getShortedProfiles()
    this.getAllRequest();
    this.blocked_List();
    this.getSentRequest();
    this.getCancelledRequest();  
  }

  //   user_id:10,
  // filter:'recieved/accepted_by_me/accepted_by_them/'

  getAllRequest() {
    let data = {
      user_id: this.user_id,
      Filter_by: this.FilterBy,
      // pageNo :this.currentPage,
      //itemPerPage :this.pageSize,
      filter: this.FilterBy,
    };
    this.myProfileService.getAllRequest(data).subscribe((result) => {
      if (result?.status) {
        this.profileList = result.pages.data;
        this.currentPage = result.pages.current_page;
        this.shortlistedProfilesCount = result.shortlistedProfilesCount;
        this.favouriteProfilesCount = result.favouriteProfilesCount;
        this.viewedByProfilesCount = result.viewedByProfilesCount;
        this.TotalRecord = result.pages.total;
        if (this.profileList?.length > 0) {
          // unused code
          //this.toastrService.success('Successfully!', 'shortlistedProfiles!');
          console.log(123)
        } else {
          // unused code
          //this.toastrService.success('You have no profiles in my shortlist! ');
        }
      } else {
        this.toastrService.error('Data not found!');
      }
      this.spinner.hide();
    });
  }

  getSentRequest() {
    let data = {
      user_id: this.user_id,
      Filter_by: this.sendFilterBy,
      // pageNo :this.currentPage,
      //itemPerPage :this.pageSize,
      filter: this.sendFilterBy,
    };
    this.myProfileService.getSentRequest(data).subscribe((result) => {
      if (result.status) {
        this.sendprofileList = result.pages.data;
        this.currentPage = result.pages.current_page;
        this.shortlistedProfilesCount = result.shortlistedProfilesCount;
        this.favouriteProfilesCount = result.favouriteProfilesCount;
        this.viewedByProfilesCount = result.viewedByProfilesCount;
        this.TotalRecord = result.pages.total;
        if (this.sendprofileList?.length > 0) {
          // unused code
          //this.toastrService.success('Successfully!', 'shortlistedProfiles!');
          console.log(123)
        } else {
          // unused code
          //this.toastrService.success('You have no profiles in my shortlist! ');
        }
      } else {
        this.toastrService.error('Data not found!');
      }
      this.spinner.hide();
    });
  }

  getCancelledRequest() {
    let data = {
      user_id: this.user_id,
     // Filter_by: this.cancelledFilterBy,
      // pageNo :this.currentPage,
      //itemPerPage :this.pageSize,
     filter: this.cancelledFilterBy,
    };
    this.myProfileService.getCancelledRequest(data).subscribe((result) => {
      if (result.status) {
        this.cancelledprofileList = result.pages.data;
        this.currentPage = result.pages.current_page;
        this.shortlistedProfilesCount = result.shortlistedProfilesCount;
        this.favouriteProfilesCount = result.favouriteProfilesCount;
        this.viewedByProfilesCount = result.viewedByProfilesCount;
        this.TotalRecord = result.pages.total;
        if (this.cancelledprofileList?.length > 0) {
          // unused code
          //this.toastrService.success('Successfully!', 'shortlistedProfiles!');
          console.log(123)
        } else {
          // unused code
          //this.toastrService.success('You have no profiles in my shortlist! ');
        }
      } else {
        this.toastrService.error('Data not found!');
      }
      this.spinner.hide();
    });
  }

getBlockedRequest(){
  let data = {
      user_id: this.user_id,
     filter: this.blockFilters,
    };
      this.myProfileService.getBlockedRequest(data).subscribe((result) => {
      if (result.status) {
        this.blockedList = result.pages.data;
        this.currentPage = result.pages.current_page;
        this.TotalRecord = result.pages.total;
      } else {
        this.toastrService.error('Data not found!');
      }
      this.spinner.hide();
    });
}

getFollowUpRequest() {
    let data = {
      user_id: this.user_id,
      filter: this.follow_upFilterBy,
    };
    this.myProfileService.getFollowUpRequest(data).subscribe((result) => {
      if (result.status) {
        this.FollowUpProfileList = result.pages.data;
        this.currentPage = result.pages.current_page;
        this.TotalRecord = result.pages.total;
        if (this.FollowUpProfileList.length > 0) {
          // unused code
          //this.toastrService.success('Successfully!', 'shortlistedProfiles!');
          console.log(123)
        } else {
           // unused code
          //this.toastrService.success('You have no profiles in my shortlist! ');
        }
      } else {
        this.toastrService.error('Data not found!');
      }
      this.spinner.hide();
    });
  }

  doActionOnRequestsRespond(action: any, profile_id: any) {
    if (action == 'accept') {
      let pload = {
        user_id: this.user_id,
        profile_id: profile_id,
        action: action,
      };

      this.myProfileService.doActionOnRequests(pload).subscribe(
        (result) => {
          if (result.status) {
            if (action == 'accept') {
              this.toastrService.success(
                'Your message has been sent to ' + '' + this.Responddisplayname
              );
              this.router.navigate(['chat']);
            }
          } else {
            this.toastrService.error('No request found!');
          }
        },
        (error) => {
          this.toastrService.error('Some thing want wrong info!');
        }
      );
    }
  }

  doActionOnRequests(action: any, profile_id: any) {
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      action: action,
    };

    this.myProfileService.doActionOnRequests(pload).subscribe(
      (result) => {
        if (result.status) {
          if (action == 'decline') {
            this.toastrService.success('User profile decline succesfully!');
          } else if (action == 'block') {
            this.toastrService.success('User profile block succesfully!');
          } else if (action == 'block_and_report') {
            this.toastrService.success(
              'User profile block and report succesfully!'
            );
          }
          this.getAllRequest();
        } else {
          this.toastrService.error('Some thing want wrong info!');
        }
      },
      (error) => {
        this.toastrService.error('Some thing want wrong info!');
      }
    );
  }

  doActionOnRequestsSend(action: any, profile_id: any) {
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      action: action,
    };

    this.myProfileService.doActionOnRequests(pload).subscribe(
      (result) => {
        if (result.status) {
          if (action == 'cancel') {
            this.toastrService.success('User profile Cancel succesfully!');
          } else if (action == 'nudge') {
            this.toastrService.success('User profile Nudge succesfully!');
          } else {
            this.toastrService.error('Some thing want wrong info!');
          }
          this.getFollowUpRequest();
        }
      },
      (error) => {
        this.toastrService.error('Some thing want wrong info!');
      }
    );
  }

  doActionOnRequestsFollow(action: any, profile_id: any) {
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      action: action,
    };

    this.myProfileService.doActionOnRequests(pload).subscribe(
      (result) => {
        if (result.status) {
          if (action == 'follow_cancel') {
            this.toastrService.success('User profile Cancel succesfully!');
          } else {
            this.toastrService.error('Some thing want wrong info!');
          }
          this.getAllRequest();
        }
      },
      (error) => {
        this.toastrService.error('Some thing want wrong info!');
      }
    );
  }

  doActionOnRequestscancelled(action: any, profile_id: any) {
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      action: action,
    };

    this.myProfileService.doActionOnRequests(pload).subscribe(
      (result) => {
        if (result.status) {
          if (action == 'un_cancel') {
            this.toastrService.success('User profile UnCancel succesfully!');
          } else {
            this.toastrService.error('Some thing want wrong info!');
          }
          this.getAllRequest();
        }
      },
      (error) => {
        this.toastrService.error('Some thing want wrong info!');
      }
    );
  }

  doActionOnRequestsBlocked(action: any, profile_id: any) {
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      action: action,
    };

    this.myProfileService.doActionOnRequests(pload).subscribe(
      (result) => {
        if (result.status) {
          if (action == 'block') {
            this.toastrService.success('User profile Block succesfully!');
          } else if (action == 'block_and_report') {
            this.toastrService.success(
              'User profile Block & Report succesfully!'
            );
          } else if (action == 'un_block') {
            this.toastrService.success('User profile Unblock succesfully!');
          }
          this.getBlockedRequest();
        } else {
          this.toastrService.error('Some thing want wrong info!');
        }
      },
      (error) => {
        this.toastrService.error('Some thing want wrong info!');
      }
    );
  }

  getRespondData(profile_id: any) {
    let FTArr = this.profileList;
    FTArr.forEach((element: any) => {
      if (element.user_id == profile_id) {
        (this.Responddisplayname = element.display_name);
          (this.RespondProfilephoto = element.profile_photo);
          (this.Responduserid = profile_id);
        // console.log( this.Responduserid,this.Responddisplayname, this.RespondProfilephoto)
      }
    });
  }

  getHistoryData(profile_id: any) {
    let historyList = this.profileList;
    historyList.forEach((element: any) => {
      if (element.user_id == profile_id) {
        this.historyProfileId=element.profile_id;
        console.log(element.user_id,'user_id from element');
        console.log(element.profile_id,'profile_id from element');
        console.log(profile_id,"id from card profile user id");
       this.isInbox = true;
       this.isSend = true;
       this.isFollowUp = true;
       this.isCancelled = true;
       this.isBlock = true;
       this.isUnblock = true;
      
      }
    });
  }

  FiltersBy(event, Filters: any = 'AllRequests') {
    if (this.FilterBy != event.target.id) {
      this.FilterBy = event.target.id;
      this.Filters = Filters;
      this.getAllRequest();
    }
  }

  sendFiltersBy(event, sendFilters: any = 'All') {
    if (this.sendFilterBy != event.target.id) {
      this.sendFilterBy = event.target.id;
      this.sendFilters = sendFilters;
      this.getSentRequest();
    }
  }
  cancelledFiltersBy(event, cancelledFilters: any = 'AllCancelled') {
    if (this.cancelledFilterBy != event.target.id) {
      this.cancelledFilterBy = event.target.id;
      this.cancelledFilters = cancelledFilters;
      this.getCancelledRequest();
    }
  }

  // follow up filter
  follow_upFiltersBy(event, follow_upFilters: any = 'follow_up') {
    if (this.follow_upFilterBy != event.target.id) {
      this.follow_upFilterBy = event.target.id;
      this.follow_upFilters = follow_upFilters;
     this.getFollowUpRequest();
    }
  }

  // block filter
  blockFiltersBy(event, blockFilters: any = 'block') {
    if (this.blockFilterBy != event.target.id) {
      this.blockFilterBy = event.target.id;
      this.blockFilters = blockFilters;
      this.getBlockedRequest();
    }
  }

  blocked_List() {
    this.getBlockedRequest();
  }
  public handlePage(e: any) {
   
    this.currentPage = e.pageIndex+1;
    this.pageSize = e.pageSize;
  }

  Upgrade(userid, user_id){
    console.log(userid,'from upgrade useris');
    console.log(user_id,'from upgrade user_id');
  }

  ViewProfile(id){
    localStorage.setItem("pageFrom", "Favourite");
    let encrypted_id:any = this.CommonService.encryptData(id);
    encrypted_id = encrypted_id.toString().replaceAll('=', 'Ar1t').replaceAll('/', 'FGH').replaceAll('+', 'THD');
    let url =`${environment.baseURL}`+'detail/'+encrypted_id
    window.open(url, '_blank');
  
  }
}
