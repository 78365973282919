<div class="w-100 bg-maroon" *ngIf="pageValid">
    <app-header></app-header>
</div>
<section class="section" *ngIf="pageValid">
    <div class="row mx-auto mx-width-600 p-4 my-5 bg-white rounded-5 shadow-sm">
        <div class="col-12 my-3">
            <h1 class="fw-bold text-center display-1">Change-Password Here!</h1>
        </div>
        <form #resetPasswordNgForm="ngForm" [formGroup]="resetPasswordForm">
            <div class="col-12 mt-3">
                <label for="confirmPassword" class="form-label mb-2 py-1 fw-bold h6">Temporary Password</label>
                <div class="position-relative">
                    <input [type]=" showPassword0 ? 'text' : 'password' " class="form-control" id="confirmPassword"
                        placeholder="Enter temporary password" [formControlName]="'currentPassword'"
                        [ngClass]="{ 'is-invalid': submitted && f.currentPassword.errors }">
                    <div class="position-absolute eye-btn right-0 text-danger" (click)='showHidePassword0()'>
                        <div *ngIf="showPassword0"> <i class="fa fa-eye"></i> </div>
                        <div *ngIf="!showPassword0"><i class="fa fa-eye-slash"></i></div>
                    </div>
                </div>
                <div *ngIf="submitted">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">{{this.errors.error}}</div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <label for="password" class="form-label mb-2 py-1 fw-bold h6">Password</label>
                <div class="position-relative">
                    <input [type]=" showPassword ? 'text' : 'password' " class="form-control" id="password"
                        placeholder="Enter password" [formControlName]="'newPassword'"
                        (input)="validatePassword($event)" maxlength="32">
                    <div class="position-absolute eye-btn right-0 text-danger" (click)='showHidePassword()'>
                        <div *ngIf="showPassword"> <i class="fa fa-eye"></i> </div>
                        <div *ngIf="!showPassword"><i class="fa fa-eye-slash"></i></div>
                    </div>
                </div>
                <div>
                    <div class="form-label mb-2 py-1 fw-bold text-danger">{{errormsg}}</div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <label for="confirmPassword" class="form-label mb-2 py-1 fw-bold h6">Confirm password</label>
                <div class="position-relative">
                    <input [type]=" showPassword1 ? 'text' : 'password' " class="form-control" id="confirmPassword"
                        [formControlName]="'ConfirmPassword'" placeholder="Enter confirm password"
                        (keyup)="matchPassword($event)" [ngClass]="{ 'is-invalid': submitted &&
                        f.ConfirmPassword.errors }">
                    <div class="position-absolute eye-btn right-0 text-danger" (click)='showHidePassword1()'>
                        <div *ngIf="showPassword1"> <i class="fa fa-eye"></i> </div>
                        <div *ngIf="!showPassword1"><i class="fa fa-eye-slash"></i></div>
                    </div>
                </div>
                <div *ngIf="!passmatch">
                    <div class="form-label mb-2 py-1 fw-bold text-danger">Passwords does not match</div>
                </div>
            </div>
            <div class="col-12 text-center mt-5">
                <a href="javascript:void(0);">
                    <button type="submit" class="btn px-5 py-2 rounded-3 fs-5 fw-bold btn-danger"
                        (click)="resetPasswordDone()" *ngIf="sBtn">Submit</button></a>
                <button type="submit" class="btn px-5 py-2 rounded-3 fs-5 fw-bold btn-danger" [disabled]="!sBtn"
                    *ngIf="!sBtn">Submit</button>
            </div>
        </form>
    </div>
</section>
<app-footer *ngIf="pageValid"></app-footer>
<div class="col-12 my-3" *ngIf="!pageValid">
    <h1 class=" text-center display-1">This link is invalid ...</h1>
</div>