<div class="bg-maroon w-100">
    <app-login-header></app-login-header>
</div>

<section class="my-5">
    <div class="container">
        <div class="row">

            <div class="col-md-8 col-12">
                <div class="card">
                    <div class="card-body shadow-sm border-1 rounded-3">
                        <h5
                            class="pb-2 fw-500 border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
                            Notifications <span class="ms-2 btn-outline-danger btn rounded-3 px-2 py-0">
                                949</span></h5>

                        <div class="row mx-0">
                            <div class="col-12 px-0 mb-2 mt-2">
                                <div class="badge badge-light btn text-danger border-1 border-danger"> Today </div>
                            </div>
                            <div
                                class="col-12 py-2 alert alert-success d-flex align-items-center overflow-hidden rounded-3 mb-2" routerLink="/detail">
                                <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                                    <img src="assets/images/Girl-modal.jpg" class="image-cover"
                                        alt="">
                                </div>
                                <div class="flex-grow-1 ms-2 overflow-hidden">
                                    <div class="d-flex align-items-center justify-content-between mb-1">
                                        <h6 class="mb-0 fw-bold">Request Accepted</h6>
                                        <small class="text-muted text-break p-sm">9 Min. Ago</small>
                                    </div>
                                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                                        adipisicing elit. Odio, cumque? </div>
                                </div>
                            </div>

                            <div
                                class="col-12 py-2 alert alert-danger d-flex align-items-center overflow-hidden rounded-3 mb-2"  routerLink="/detail">
                                <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                                    <img src="assets/images/Girl-modal.jpg" class="image-cover"
                                        alt="">
                                </div>
                                <div class="flex-grow-1 ms-2 overflow-hidden">
                                    <div class="d-flex align-items-center justify-content-between mb-1">
                                        <h6 class="mb-0 fw-bold">Request Canceled</h6>
                                        <small class="text-muted text-break p-sm">29 Min. Ago</small>
                                    </div>
                                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                                        adipisicing elit. Odio, cumque? </div>
                                </div>
                            </div>

                            <div
                                class="col-12 py-2 alert alert-primary d-flex align-items-center overflow-hidden rounded-3 mb-2"  routerLink="/detail">
                                <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                                    <img src="assets/images/Girl-modal.jpg" class="image-cover"
                                        alt="">
                                </div>
                                <div class="flex-grow-1 ms-2 overflow-hidden">
                                    <div class="d-flex align-items-center justify-content-between mb-1">
                                        <h6 class="mb-0 fw-bold">Follow Request</h6>
                                        <small class="text-muted text-break p-sm"> 3 hours Ago</small>
                                    </div>
                                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                                        adipisicing elit. Odio, cumque? </div>
                                </div>
                            </div>

                            <div
                                class="col-12 py-2 alert alert-primary d-flex align-items-center overflow-hidden rounded-3 mb-2"  routerLink="/detail">
                                <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                                    <img src="assets/images/Girl-modal.jpg" class="image-cover"
                                        alt="">
                                </div>
                                <div class="flex-grow-1 ms-2 overflow-hidden">
                                    <div class="d-flex align-items-center justify-content-between mb-1">
                                        <h6 class="mb-0 fw-bold">Follow Request</h6>
                                        <small class="text-muted text-break p-sm"> 3 hours Ago</small>
                                    </div>
                                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                                        adipisicing elit. Odio, cumque? </div>
                                </div>
                            </div>


                            <div class="col-12 px-0 mb-2 mt-4">
                                <div class="badge badge-light btn text-danger border-1 border-danger"> Yesterday </div>
                            </div>

                            <div
                                class="col-12 py-2 alert alert-success d-flex align-items-center overflow-hidden rounded-3 mb-2"  routerLink="/detail">
                                <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                                    <img src="assets/images/Girl-modal.jpg" class="image-cover"
                                        alt="">
                                </div>
                                <div class="flex-grow-1 ms-2 overflow-hidden">
                                    <div class="d-flex align-items-center justify-content-between mb-1">
                                        <h6 class="mb-0 fw-bold">Request Accepted</h6>
                                        <small class="text-muted text-break p-sm">9 Min. Ago</small>
                                    </div>
                                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                                        adipisicing elit. Odio, cumque? </div>
                                </div>
                            </div>

                            <div
                                class="col-12 py-2 alert alert-danger d-flex align-items-center overflow-hidden rounded-3 mb-2"  routerLink="/detail">
                                <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                                    <img src="assets/images/Girl-modal.jpg" class="image-cover"
                                        alt="">
                                </div>
                                <div class="flex-grow-1 ms-2 overflow-hidden">
                                    <div class="d-flex align-items-center justify-content-between mb-1">
                                        <h6 class="mb-0 fw-bold">Request Canceled</h6>
                                        <small class="text-muted text-break p-sm">29 Min. Ago</small>
                                    </div>
                                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                                        adipisicing elit. Odio, cumque? </div>
                                </div>
                            </div>


                            <div class="col-12 px-0 mb-2 mt-4">
                                <div class="badge badge-light btn text-danger border-1 border-danger">November 25, 2022 </div>
                            </div>

                            <div
                                class="col-12 py-2 alert alert-success d-flex align-items-center overflow-hidden rounded-3 mb-2"  routerLink="/detail">
                                <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                                    <img src="assets/images/Girl-modal.jpg" class="image-cover"
                                        alt="">
                                </div>
                                <div class="flex-grow-1 ms-2 overflow-hidden">
                                    <div class="d-flex align-items-center justify-content-between mb-1">
                                        <h6 class="mb-0 fw-bold">Request Accepted</h6>
                                        <small class="text-muted text-break p-sm">9 Min. Ago</small>
                                    </div>
                                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                                        adipisicing elit. Odio, cumque? </div>
                                </div>
                            </div>

                            <div
                                class="col-12 py-2 alert alert-danger d-flex align-items-center overflow-hidden rounded-3 mb-2"  routerLink="/detail">
                                <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                                    <img src="assets/images/Girl-modal.jpg" class="image-cover"
                                        alt="">
                                </div>
                                <div class="flex-grow-1 ms-2 overflow-hidden">
                                    <div class="d-flex align-items-center justify-content-between mb-1">
                                        <h6 class="mb-0 fw-bold">Request Canceled</h6>
                                        <small class="text-muted text-break p-sm">29 Min. Ago</small>
                                    </div>
                                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                                        adipisicing elit. Odio, cumque? </div>
                                </div>
                            </div>

                            <div
                                class="col-12 py-2 alert alert-danger d-flex align-items-center overflow-hidden rounded-3 mb-2"  routerLink="/detail">
                                <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                                    <img src="assets/images/Girl-modal.jpg" class="image-cover"
                                        alt="">
                                </div>
                                <div class="flex-grow-1 ms-2 overflow-hidden">
                                    <div class="d-flex align-items-center justify-content-between mb-1">
                                        <h6 class="mb-0 fw-bold">Request Canceled</h6>
                                        <small class="text-muted text-break p-sm">29 Min. Ago</small>
                                    </div>
                                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                                        adipisicing elit. Odio, cumque? </div>
                                </div>
                            </div>

                            <div
                                class="col-12 py-2 alert alert-danger d-flex align-items-center overflow-hidden rounded-3 mb-2"  routerLink="/detail">
                                <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                                    <img src="assets/images/Girl-modal.jpg" class="image-cover"
                                        alt="">
                                </div>
                                <div class="flex-grow-1 ms-2 overflow-hidden">
                                    <div class="d-flex align-items-center justify-content-between mb-1">
                                        <h6 class="mb-0 fw-bold">Request Canceled</h6>
                                        <small class="text-muted text-break p-sm">29 Min. Ago</small>
                                    </div>
                                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                                        adipisicing elit. Odio, cumque? </div>
                                </div>
                            </div>
                            <div
                                class="col-12 py-2 alert alert-danger d-flex align-items-center overflow-hidden rounded-3 mb-2"  routerLink="/detail">
                                <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                                    <img src="assets/images/Girl-modal.jpg" class="image-cover"
                                        alt="">
                                </div>
                                <div class="flex-grow-1 ms-2 overflow-hidden">
                                    <div class="d-flex align-items-center justify-content-between mb-1">
                                        <h6 class="mb-0 fw-bold">Request Canceled</h6>
                                        <small class="text-muted text-break p-sm">29 Min. Ago</small>
                                    </div>
                                    <div class="truncate-2 p-xsm fw-bold"> Lorem ipsum, dolor sit amet consectetur
                                        adipisicing elit. Odio, cumque? </div>
                                </div>
                            </div>






                        </div>





                    </div>


                </div>
            </div>



            <div class="col-md-4 col-12 mt-4 mt-md-0 position-relative">
                <div class="card position-sticky">
                    <div class="card-body">
                        <h5
                        class="pb-2 fw-500 border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
                        Notifications Filter</h5>

                        <div class="input-group my-3">
                            <input type="text" class="w-100 rounded-lg px-3 py-1" placeholder="Search here"
                                aria-label="Search Here" aria-describedby="button-addon2">
                        </div>

                        <ul class="list-group rounded-0">
                            <li class="list-group-item border-start-0 border-end-0">
                                <input class="checkbox me-2" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Request Accepted
                                </label>
                            </li>
                            <li class="list-group-item border-start-0 border-end-0">
                                <input class="checkbox me-2" type="checkbox" value="" id="flexCheckChecked">
                                <label class="form-check-label" for="flexCheckChecked">
                                    Request Canceled
                                </label>
                            </li>
                            <li class="list-group-item border-start-0 border-end-0">
                                <input class="checkbox me-2" type="checkbox" value="" id="flexCheckChecked3">
                                <label class="form-check-label" for="flexCheckChecked3">
                                    Follow Request
                                </label>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<app-login-footer></app-login-footer>