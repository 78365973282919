import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string): string {
    // Check if the input value matches the expected phone number pattern
    const regex = /^(\+\d{2})?(\s)?(\(\d{3}\))(\s)?(\d{3})(-)?(\d{4})$/;
    if (regex.test(value)) {
      return value; // The input is already in the desired format, so return it as is
    } else {
      // Format the input to the desired format (+91 (982) 668-8720)
      const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      if (numericValue.length === 10) {
        return ` (${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)}-${numericValue.slice(6)}`;
      } else {
        return value; // Return the input as is if it cannot be formatted
      }
    }
  }

}
