<div class="overflow-x-hidden">

    <!-- bg-white border rounded-10 shadow-sm p-3 -->
    <div class="container p-3 mx-width-800 mt-93">

        <form #stepNgForm="ngForm" id="step" [formGroup]="stepForm" (submit)="step()">
            <div class="row py-5">
                <div *ngIf="errors?.living_with_family" class="alert alert-danger mt-3">
                    {{ errors?.living_with_family }}
                </div>
                <div *ngIf="errors?.family_type" class="alert alert-danger mt-3">
                    {{ errors?.family_type }}
                </div>
                <div *ngIf="errors?.residency_status" class="alert alert-danger mt-3">
                    {{ errors?.residency_status }}
                </div>
                <div *ngIf="errors?.country_of_birth" class="alert alert-danger mt-3">
                    {{ errors?.country_of_birth }}
                </div>
                <div *ngIf="errors?.marital_status" class="alert alert-danger mt-3">
                    {{ errors?.marital_status }}
                </div>
                <div *ngIf="errors?.have_children" class="alert alert-danger mt-3">
                    {{ errors?.have_children }}
                </div>
                <div *ngIf="errors?.boy_child" class="alert alert-danger mt-3">
                    {{ errors?.boy_child }}
                </div>
                <div *ngIf="errors?.boy_child_not_married" class="alert alert-danger mt-3">
                    {{ errors?.boy_child_not_married }}
                </div>
                <div *ngIf="errors?.boy_child_married" class="alert alert-danger mt-3">
                    {{ errors?.boy_child_married }}
                </div>
                <div *ngIf="errors?.girl_child" class="alert alert-danger mt-3">
                    {{ errors?.girl_child }}
                </div>
                <div *ngIf="errors?.girl_child_not_married" class="alert alert-danger mt-3">
                    {{ errors?.girl_child_not_married }}
                </div>
                <div *ngIf="errors?.girl_child_married" class="alert alert-danger mt-3">
                    {{ errors?.girl_child_married }}
                </div>
                <div *ngIf="errors?.food_preference" class="alert alert-danger mt-3">
                    {{ errors?.food_preference }}
                </div>
                <div *ngIf="errors?.height" class="alert alert-danger mt-3">
                    {{ errors?.height }}
                </div>
                <div *ngIf="errors?.weight" class="alert alert-danger mt-3">
                    {{ errors?.weight }}
                </div>
                <div class="col-12">
                    <h1 class="fw-bold text-center display-2">More Details to Enhance The Search</h1>
                </div>



                <div class="col-12 mt-3">
                    <h6 class="fw-bold"> Living With Family <span class="text-danger"> *</span></h6>
                    <div class="select-box" [ngClass]="{ 'is-invalid': submitted && f.living_with_family.errors }">
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="hidden" id="Self1" [formControlName]="'user_id'">
                            <input class="form-check-input" type="radio" [formControlName]="'living_with_family'"
                                id="Male" value="Yes" (change)="checkFamilyType()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="Male">Yes</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'living_with_family'"
                                id="Female" value="No" (change)="checkFamilyType()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="Female">No</label>
                        </div>
                        <div *ngIf="submitted && f.living_with_family.errors">
                            <div class="form-label mb-2 py-1 fw-bold text-danger">Living with family is required</div>
                        </div>
                    </div>
                </div>


                <div class="col-12 mt-3" *ngIf="familyType">
                    <div class="select-box">
                        <div class="form-check form-check-inline p-0 me-3 mb-3" ngbTooltip="A
                            kinship group consisting of a family nucleus and various relatives, as grandparents, usually living in one household
                            and functioning as a larger unit">
                            <input class="form-check-input" type="radio" [formControlName]="'family_type'"
                                value="Extended Family" id="ExtendedFamily">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="ExtendedFamily" placement="top">Extended
                                Family</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" ngbTooltip="A
                            social unit consisting of people who are very closely related, usually including parents, siblings, spouses, and
                            children, or the members of this social unit.">
                            <input class="form-check-input" type="radio" [formControlName]="'family_type'"
                                id="ImmediateFamily" value="Immediate Family">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="ImmediateFamily" placement="top">Immediate
                                Family</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" ngbTooltip="A
                            type of extended family composed of parents, their children, and the children's spouses and offspring in one
                            household.">
                            <input class="form-check-input" type="radio" [formControlName]="'family_type'"
                                id="JointFamily" value="Joint Family">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="JointFamily"
                                placement="top">Joint
                                Family</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" ngbTooltip="A
                            social unit composed of two parents and one or more children.">
                            <input class="form-check-input" type="radio" [formControlName]="'family_type'"
                                id="NuclearFamily" value="Nuclear Family">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="NuclearFamily"
                                placement="top">Nuclear
                                Family</label>
                        </div>

                    </div>
                </div>



                <div class="col-12 mt-3">
                    <h6 class="fw-bold"> Residency Status <span class="text-danger"> *</span></h6>
                    <div class="select-box" [ngClass]="{ 'is-invalid': submitted && f.residency_status.errors }">
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'residency_status'"
                                (change)="residencyStatusCheck()" id="Citizen" value="Citizen">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="Citizen">Citizen</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'residency_status'"
                                (change)="residencyStatusCheck()" id="WorkPermit" value="Work Permit">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="WorkPermit">Work
                                Permit</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'residency_status'"
                                (change)="residencyStatusCheck()" id="PermanentResident" value="Permanent Resident">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="PermanentResident">Permanent
                                Resident</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'residency_status'"
                                (change)="residencyStatusCheck()" id="StudentVisa" value="Student Visa">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="StudentVisa">Student
                                Visa</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'residency_status'"
                                (change)="residencyStatusCheck()" id="VisitorVisa" value="Visitor Visa">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="VisitorVisa">Visitor
                                Visa</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!residencyOtherCheck">
                            <input class="form-check-input" type="radio" [formControlName]="'residency_status'"
                                id="OtherResidency" value="Other" (change)="residencyStatusCheck()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="OtherResidency">Other</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="residencyOtherCheck">
                            <input type="text" class="form-control" id="caste" placeholder="Enter "
                                [formControlName]="'residency_status'" (input)="NameSpaceNotAllowed($event)"
                                maxlength="25">
                        </div>
                        <div *ngIf="submitted && f.residency_status.errors">
                            <div class="form-label mb-2 py-1 fw-bold text-danger">Residency status is required</div>
                        </div>
                    </div>
                </div>


                <div class="col-12 mt-3">
                    <h6 class="fw-bold"> Country of Birth <span class="text-danger"> *</span> </h6>
                    <div class="select-box" [ngClass]="{ 'is-invalid': submitted && f.country_of_birth.errors }">
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'country_of_birth'"
                                id="Canada" value="38" (change)="checkBirthCountry()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="Canada">Canada</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'country_of_birth'"
                                id="America" value="231" (change)="checkBirthCountry()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="America">America</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'country_of_birth'"
                                id="Australia" value="13" (change)="checkBirthCountry()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="Australia">Australia</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'country_of_birth'"
                                id="France" value="75" (change)="checkBirthCountry()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="France">France
                            </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'country_of_birth'"
                                id="India" value="101" (change)="checkBirthCountry()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="India">India
                            </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'country_of_birth'"
                                id="Oman" value="165" (change)="checkBirthCountry()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Oman">Oman
                            </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'country_of_birth'" id="UAE"
                                value="229" (change)="checkBirthCountry()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="UAE">UAE
                            </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'country_of_birth'" id="UK"
                                value="230" (change)="checkBirthCountry()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="UK">UK </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!birthCountry">
                            <input class="form-check-input" type="radio" [formControlName]="'country_of_birth'"
                                id="Other" value="Other" (change)="checkBirthCountry()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Other">Other
                            </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="birthCountry"
                            style="min-width: 250px;">
                            <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="id"
                                [formControlName]="'country_of_birth'" (change)="onChangeCountry($event)"
                                [(ngModel)]="countryOfBirth" placeholder="Select Country" dropdownPosition="auto"
                                [virtualScroll]="true">
                                <ng-template ng-label-tmp let-item="item">
                                    <img height="20" width="20" src="assets/images/flag/Flag_of_{{item.country_name |
                                        replaceSpaceWithUnderscore}}.svg" alt="" style="margin-right:5px;" />
                                    <b> {{item.country_name}}</b>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    <img height="20" width="20" src="assets/images/flag/Flag_of_{{item.country_name |
                                        replaceSpaceWithUnderscore}}.svg" alt="" style="margin-right:5px;" />
                                    <b> {{item.country_name}}</b>
                                </ng-template>
                            </ng-select>
                            <div *ngIf="submitted && f.country_of_birth.errors">
                                <div class="form-label mb-2 py-1 fw-bold text-danger">Country is required</div>
                            </div>
                        </div>
                        <div *ngIf="submitted && f.country_of_birth.errors">
                            <div class="form-label mb-2 py-1 fw-bold text-danger">Country of Birth is required</div>
                        </div>
                    </div>
                </div>


                <div class="col-12 mt-3">
                    <h6 class="fw-bold"> Marital Status <span class="text-danger"> *</span></h6>
                    <div class="select-box" [ngClass]="{ 'is-invalid': submitted && f.marital_status.errors }">
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'marital_status'"
                                (change)="checkMaritalStatus()" id="Annulled" value="Annulled">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="Annulled">Annulled</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'marital_status'"
                                id="AwaitingDivorce" (change)="checkMaritalStatus()" value="Awaiting Divorce">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="AwaitingDivorce"> Awaiting
                                Divorce </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'marital_status'"
                                id="Divorced" (change)="checkMaritalStatus()" value="Divorced">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="Divorced">Divorced</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'marital_status'"
                                id="Single" (change)="checkMaritalStatus()" value="Single">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="Single">Single</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'marital_status'"
                                id="Widowed" (change)="checkMaritalStatus()" value="Widowed">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="Widowed">Widowed</label>
                        </div>
                        <div *ngIf="submitted && f.marital_status.errors">
                            <div class="form-label mb-2 py-1 fw-bold text-danger">Marital status is required</div>
                        </div>
                    </div>
                </div>


                <div class="col-12 mt-3" *ngIf="status_single">
                    <h6 class="fw-bold"> Have Children </h6>
                    <div class="select-box">
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'have_children'"
                                id="ChildrenYes" (change)="checkHaveChildren()" value="Yes">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="ChildrenYes">Yes</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'have_children'"
                                id="ChildrenNo" (change)="checkHaveChildren()" value="No">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="ChildrenNo">
                                No </label>
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-3">

                    <div class="row row-cols-2 gx-5 text-center" *ngIf="haveChildren">
                        <div class="col-12 col-md-6 d-flex">
                            <div class="">
                                <div class="form-check form-check-inline p-0 mb-0 me-1">
                                    <input class="form-check-input" type="checkbox" [formControlName]="'boy_child'"
                                        id="BoyChild" value="BoyChild" (change)="checkBoyChild()">
                                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 text-primary"
                                        for="BoyChild">
                                        <i class="fas fa-male fs-2"></i>
                                    </label>
                                </div>

                            </div>
                            <div class="mx-2" *ngIf="!boyChild">
                                <input type="text" class="width-80 form-control text-center"
                                    [formControlName]="'boy_child_not_married'" maxlength="2"
                                    (input)="keyPressNumeric1($event)" (paste)="pasteNumeric1($event)"
                                    id="boyChildNotMarried" placeholder="0">
                                <p class="p-sm mb-0">Not married</p>

                            </div>

                            <div class="" *ngIf="!boyChild">
                                <input type="text" class="width-80 form-control text-center"
                                    [formControlName]="'boy_child_married'" maxlength="2"
                                    (input)="keyPressNumeric2($event)" (paste)="pasteNumeric2($event)"
                                    id="boyChildMarried" placeholder="0">
                                <p class="p-sm mb-0">Married</p>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 d-flex">
                            <div class="">
                                <div class="form-check form-check-inline p-0 mb-0 me-1">
                                    <input class="form-check-input" type="checkbox" [formControlName]="'girl_child'"
                                        id="GirlChild" value="GirlChild" (change)="checkGirlChild()">
                                    <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                        for="GirlChild">
                                        <i class="fas fa-female fs-2"></i>
                                    </label>
                                </div>
                            </div>
                            <div class="mx-2" *ngIf="!girlChild">
                                <input type="text" class="width-80 form-control text-center"
                                    [formControlName]="'girl_child_not_married'" maxlength="2"
                                    (input)="keyPressNumeric3($event)" (paste)="pasteNumeric3($event)"
                                    id="girlChildNotMarried" placeholder="0">
                                <p class="p-sm mb-0">Not married</p>
                            </div>

                            <div class="" *ngIf="!girlChild">
                                <input type="text" class="width-80 form-control text-center"
                                    [formControlName]="'girl_child_married'" id="girl_child_married" maxlength="2"
                                    (input)="keyPressNumeric4($event)" (paste)="pasteNumeric4($event)" placeholder="0">
                                <p class="p-sm mb-0">Married</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-12 mt-3">
                    <h6 class="fw-bold"> Hobbies</h6>
                    <div class="select-box">
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="checkbox" [formControlName]="'hobbies'"
                                id="ArtsAndCrafts" value="Arts And Crafts" (change)="hobbies($event)">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="ArtsAndCrafts"> Arts and
                                Crafts</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="checkbox" [formControlName]="'hobbies'" id="Baking"
                                value="Baking" (change)="hobbies($event)">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Baking">
                                Baking</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="checkbox" [formControlName]="'hobbies'" id="Cooking"
                                value="Cooking" (change)="hobbies($event)">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Cooking">
                                Cooking </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="checkbox" [formControlName]="'hobbies'" id="Gardening"
                                value="Gardening" (change)="hobbies($event)">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Gardening">
                                Gardening</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="checkbox" [formControlName]="'hobbies'" id="Reading"
                                value="Reading" (change)="hobbies($event)">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="Reading">Reading</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="checkbox" [formControlName]="'hobbies'"
                                id="TeamSports" value="Team Sports" (change)="hobbies($event)">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="TeamSports">
                                Team
                                Sports</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="checkbox" [formControlName]="'hobbies'"
                                id="WorkingOut" value="Working Out" (change)="hobbies($event)">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="WorkingOut">
                                Working
                                Out</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="checkbox" [formControlName]="'hobbies'" id="Yoga"
                                value="Yoga" (change)="hobbies($event)">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 " for="Yoga">
                                Yoga</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!checkHobby">
                            <input class="form-check-input" type="checkbox" [formControlName]="'hobbies'"
                                id="HobbiesOther" (change)="hobbies($event)">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1 "
                                for="HobbiesOther">Other</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="checkHobby">
                            <input type="text" class="form-control" id="caste" (keypress)="keyPressAlphaNumeric($event)"
                                (input)="NameSpaceNotAllowed($event)" [formControlName]="'hobbiesOther'" maxlength="25"
                                autocomplete="">
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-3">
                    <h6 class="fw-bold"> Food Preference <span class="text-danger"> *</span></h6>
                    <div class="select-box" [ngClass]="{ 'is-invalid': submitted && f.food_preference.errors }">
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'food_preference'" id="Veg"
                                value="Veg" (change)="CheckFood()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="Veg">Veg</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'food_preference'"
                                id="NonVeg" value="Non Veg" (change)="CheckFood()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="NonVeg">Non
                                veg</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'food_preference'"
                                id="Occasionally" value="Occasionally Non-Veg" (change)="CheckFood()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="Occasionally">Occasionally
                                Non-Veg</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'food_preference'"
                                id="Eggetarian" value="Eggetarian" (change)="CheckFood()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="Eggetarian">Eggetarian</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'food_preference'"
                                id="Pescatarian" value="Pescatarian" (change)="CheckFood()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="Pescatarian">Pescatarian</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'food_preference'"
                                id="JainFood" value="Jain-Food" (change)="CheckFood()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="JainFood">Jain</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'food_preference'"
                                id="Vegan" value="Vegan" (change)="CheckFood()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="Vegan">Vegan</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!foodPreference">
                            <input class="form-check-input" type="radio" [formControlName]="'food_preference'"
                                id="OtherFood" value="Other" (change)="CheckFood()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="OtherFood">Other</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="foodPreference">
                            <input type="text" class="form-control" id="caste" placeholder="Enter "
                                [formControlName]="'food_preference'" maxlength="25"
                                (input)="NameSpaceNotAllowed($event)">
                        </div>
                        <div *ngIf="submitted && f.food_preference.errors">
                            <div class="form-label mb-2 py-1 fw-bold text-danger">Food Preference is required</div>
                        </div>
                    </div>
                </div>


                <div class="col-12 col-md-6">
                    <div class="row">
                        <h6 class="fw-bold"> Height <span class="text-danger"> *</span></h6>
                        <div class="col-12 mb-3">
                            <select class="form-select" aria-label="Default select example" [formControlName]="'height'"
                                [ngClass]="{ 'is-invalid':
                                submitted && f.height.errors }">
                                <option value="" selected disabled hidden>Select</option>
                                <option value="{{height.i}}" *ngFor="let height of this.height">
                                    {{height.i}} cm ({{ height.i | heightConverter : 'Ft'}})</option>

                            </select>
                        </div>
                        <div *ngIf="submitted && f.height.errors">
                            <div class="form-label mb-2 py-1 fw-bold text-danger">Height is required</div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="row">
                        <h6 class="fw-bold"> Weight <span class="text-danger"> *</span></h6>
                        <div class="col-12 mb-3">
                            <select class="form-select" aria-label="Default select example" [formControlName]="'weight'"
                                [ngClass]="{ 'is-invalid':
                                submitted && f.weight.errors }">
                                <option value="" selected disabled hidden>Select</option>
                                <option value="{{weight.i}}" *ngFor="let weight of this.weight">
                                    {{weight.i}} kg ({{ weight.i | weightConverter : 'Pounds' }} lb)</option>
                            </select>
                        </div>
                        <div *ngIf="submitted && f.weight.errors">
                            <div class="form-label mb-2 py-1 fw-bold text-danger">weight is required</div>
                        </div>
                    </div>
                </div>

                <div class="w-100"></div>
                <div class="col-6 mt-5">
                    <a href="javascript:void(0);" routerLink="/registerNxt">
                        <button type="button"
                            class="btn w-120 py-2 rounded-3 fs-5 fw-bold btn-danger">Previous</button></a>
                </div>
                <div class="col-6 text-end mt-5">
                    <a href="javascript:void(0);">
                        <button type="submit" class="btn w-120 py-2 rounded-3 fs-5 fw-bold btn-danger"
                            [disabled]="buttonDisable">Next</button></a>
                </div>
            </div>
        </form>
    </div>
</div>
<app-login-footer></app-login-footer>