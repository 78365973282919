<div class="container border border-1 shadow-sm my-4 p-0 position-relative">

    <div class="tab-content bg-white min_content_height_246 mt-68" id="nav-tabContent">

        <!-- tab-one -->
        <div class="tab-pane fade show active p-3" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

            <div class="row">
                <div class="col-8">
                    <h5 class="mb-1 fw-bold"><span *ngIf="sortByMatchesh=='suggested'">Suggested</span> <span
                            *ngIf="sortByMatchesh=='premium'">Premium</span> <span
                            *ngIf="sortByMatchesh=='elite'">Elite</span> Matches({{TotalRecord}}) </h5>
                </div>

                <div class="col-4">
                    <div class="row">
                        <div class="col-md-9 col-12 d-flex align-items-center">
                            <label for="Designation" class="form-label fw-bold mb-0">Sort By ({{TotalRecord}})</label>
                        </div>
                        <div class="col-12">
                            <select [(ngModel)]="sortByMatchesh" class="form-select ms-auto cursor-pointer"
                                aria-label="Default select example" (change)="sortedBy($event)">
                                <option value="suggested">Suggested</option>
                                <option value="premium">Premium</option>
                                <option value="elite">Elite</option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- Content loader -->
                <div *ngIf="inboxLoader"
                    class="row shadow-sm border border-0 h-100 mx-0 overflow-hidden position-relative">
                    <div *ngFor="let number of [0,1,2,3]" class="col-12 col-sm-6 col-lg-6 my-3">
                        <div class="row card row shadow-sm border border-1 bg-light h-100 mx-0 overflow-hidden position-relative"
                            style="width:auto;">
                            <div class=" mainCardBox col-12 card-body d-flex p-1 ">
                                <img src="assets/images/default-profile-picture.svg" alt=""
                                    class="img_Box animated-background" />
                                <div class="card-contentBox p-1 ">
                                    <div
                                        class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0 card-nav">
                                        <span class="nameBlank-box mt-1 animated-background-name "></span>
                                    </div>
                                    <div class="row mt-3 ">
                                        <div class="col-6 ">
                                            <span class="blank-box mt-3 animated-background-name"></span>
                                            <span class="blank-box mt-3 animated-background-name"></span>
                                            <span class="blank-box mt-3 animated-background-name"></span>
                                        </div>
                                        <div class="col-6">
                                            <span class="blank-box mt-3 animated-background-name"></span>
                                            <span class="blank-box mt-3 animated-background-name"></span>
                                            <span class="blank-box mt-3 animated-background-name"></span>
                                            <span class="blank-box mt-3 animated-background-name"></span>
                                        </div>
                                    </div>
                                    <span class="footerBlankBox mt-3 animated-background-name"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End content loader -->


            </div>

            <div *ngIf="TotalRecord==0 && (!inboxLoader) " class=" row card_wpr_ht center"
                style="min-height: calc(128vh - 550px);text-align: center;">
                <!--   -->
                <strong>Data not found.</strong>
            </div>

            <div *ngIf="!inboxLoader && TotalRecord> 0 " class="row h-100">
                <!--  -->
                <div class="col-12 col-sm-6 col-lg-6 my-3" *ngFor="let profile of profileList,let i= index">
                    <div class="row shadow-sm border border-1 bg-light h-100 mx-0 overflow-hidden position-relative">
                        <div class="col-12 col-lg-4 px-0">
                            <div class="ratio-1 card-image">
                                <img class="image-cover"
                                    *ngIf="!profile?.profile_photo || profile.profile_photo_approved!=1 "
                                    src="assets/images/default-profile-picture.svg" alt="Default profile">
                                <div
                                    *ngIf="profile?.profile_photo != null && !!profile?.profile_photo && profile.profile_photo_approved==1 ">
                                    <ng-container
                                        *ngIf="profile?.photo_visiblity == 'No one' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage != 5)">
                                        <img class="image-cover" src="assets/images/default-profile-picture.svg"
                                            alt="Default profile">
                                    </ng-container>
                                    <ng-container
                                        *ngIf="profile?.photo_visiblity == 'Visible to all members' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage == 5 )">
                                        <app-progressive-image class="progressive-image">
                                            <img appProgressiveImage [src]="profile?.profile_photo" alt=""
                                                class="img-fluid all_members">
                                            <div appProgressiveImagePlaceholder class="placeholderOneOrThree-veiw">
                                                <div id="loaderProgressive" class="centerProgressive-veiw"></div>
                                            </div>
                                            <div appProgressiveImageFallback class="fallback">
                                                <img appProgressiveImageFallback class="img-fluid"
                                                    src="assets/images/default-profile-picture.svg" alt="">
                                            </div>
                                        </app-progressive-image>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-8 py-2">
                            <div class="row h-100">
                                <div class="col-12 h-100 d-flex flex-column">
                                    <div
                                        class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0 card-nav">
                                        <div class="col-6 px-0">
                                            <h6 class="text-danger fw-bold mb-1" *ngIf="!!profile.display_name">
                                                {{ profile.display_name }}
                                            </h6>
                                            <h6 class="text-danger fw-bold mb-1" *ngIf="!profile.display_name">
                                                {{ profile.unique_key }}
                                            </h6>
                                            <div class="d-flex align-items-center">
                                                <span class="user_email_verified"
                                                    [ngbTooltip]="profile?.is_user_verified ==1 ? 'Verified user': 'Verified email'"
                                                    placement="bottom">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                        fill="currentColor" class="bi bi-shield-fill-check mb-1 "
                                                        [ngClass]="{'text-muted':profile?.is_user_verified != 1,
                                                   'text-success':profile?.is_user_verified == 1}" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0
                                        0-1.044
                                        1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517
                                        2.453c.386.273.744.482
                                        1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159
                                        7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195
                                        3.061-5.513
                                        2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0
                                        0-2.887-.87C9.843.266
                                        8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708
                                        0l-1.5-1.5a.5.5 0
                                        1 1 .708-.708L7.5 7.793l2.646-2.647z" />
                                                    </svg>
                                                </span>

                                                <span class="user_email_verified1 mx-1"
                                                    *ngIf="profile?.is_user_verified== 0 " placement="bottom"
                                                    ngbTooltip="Verified email"><i class="fa fa-check fs-5"
                                                        style="color:green"></i>
                                                </span>
                                                <span class="user_email_verified1 mx-1"
                                                    *ngIf="profile?.is_user_verified== 1 " placement="bottom"
                                                    ngbTooltip="Verified user"><i class="fa fa-check-double fs-5 "
                                                        style="color:green"></i>
                                                </span>
                                                <div class="p-xsm mb-0 text-success" *ngIf="profile.is_logged_in">
                                                    <div class="flash d-inline-block me-1">
                                                        <div class>
                                                            <i class="fas fa-globe"></i>
                                                        </div>
                                                    </div>
                                                    <div class="d-inline-block">
                                                        Online
                                                    </div>
                                                </div>
                                                <div class="p-xsm mb-0 text-default" *ngIf="!profile.is_logged_in">
                                                    <div class="d-inline-block me-1">
                                                        <div class>
                                                            <i class="fas fa-globe"></i>
                                                        </div>
                                                    </div>
                                                    <div class="d-inline-block">
                                                        Offline
                                                    </div>
                                                </div>

                                                <div class="ms-2">
                                                    <img src="assets/images/icon/{{
                                profile.package_icon
                                }}" class="width-30" alt placement="top" ngbTooltip="Elite Member"
                                                        *ngIf="profile.package_id == 3" />
                                                    <img src="assets/images/icon/{{
                                                            profile.package_icon
                                                            }}" class="width-30" alt placement="top"
                                                        ngbTooltip="Premium Member" *ngIf="profile.package_id == 2" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-6 col-md-6 py-1 lh-lg">
                                            <p class="text-muted mb-0 py-1 p-sm"> {{profile?.age}} yrs, {{
                                                profile?.height|heightConverter:"Ft"}} </p>
                                            <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.marital_status | limit
                                                :18}} </p>
                                            <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.religion | limit
                                                :18}}<span *ngIf="profile.caste">, {{
                                                    profile?.caste | limit :18}}</span>
                                            </p>
                                        </div>
                                        <div class="col-6 col-md-6 py-1 lh-lg">
                                            <p class="text-muted mb-0 py-1 p-sm" *ngIf="!!profile?.working_as"> {{
                                                profile?.working_as | limit
                                                : 20}}</p>
                                            <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.current_city | limit :
                                                20}}
                                            </p>
                                            <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.stateName | limit : 20}}
                                            </p>
                                            <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.living_in_country_name |
                                                limit : 20}}
                                            </p>
                                        </div>
                                        <div class="col-12 py-1 lh-lg">
                                            <p class="text-muted p-sm mb-0">{{profile.description | limit :75}} <a
                                                    href="javascript:void(0)"
                                                    (click)="ViewProfile(profile.user_id,profile.display_name)"
                                                    class="default_link text-underline">View
                                                    Profile</a>
                                            </p>
                                            <button style="display:none;" id="openModal1" data-bs-target="#upgradeModal"
                                                data-bs-toggle="modal"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div class="row mt-3 justify-content-end" *ngIf="TotalRecord> 0 ">
                <div class="col-md-5 col-8 justify-content-center">
                    <mat-paginator #paginator [pageSizeOptions]="[8,16, 50, 100]" [length]="TotalRecord"
                        showFirstLastButtons aria-label="Select page of periodic elements" (page)="handlePage($event)"
                        [pageSize]="pageSize" [hidePageSize]="hidePageSize">
                    </mat-paginator>
                </div>
                <div class="col-md-2 col-4 text-center" style="text-align:start; padding-top:20px;">
                    <p class="mat-paginator-range">Page {{currentPage}} of {{lastPage}}</p>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">

            </div>

        </div>

    </div>
</div>



<!-- Connect to cancelled  request modal -->

<div class="modal fade" id="upgradeModal" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content position-relative">

            <div class="modal-body text-center p-4">
                <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
                    data-bs-dismiss="modal" aria-label="Close">
                    <i class="fas fa-times fs-5"></i>
                </button>

                <img src="assets/images/logo.png" alt="" width="200" class="mb-6">

                <p class="p-sm mb-4">
                    <strong>You need to Sign-Up to view the profile!</strong>
                </p>

                <button class="btn btn-danger rounded-pill" data-bs-dismiss="modal" aria-label="Close"
                    routerLink="/registerDone">Sign-Up </button>
            </div>

        </div>
    </div>
</div>

<app-login-footer></app-login-footer>