import { Component, OnInit, ViewChild/*, ViewEncapsulation, HostListener*/, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm, } from '@angular/forms';
import { RegisterService } from '../services/register.service';
import { CookieService } from 'ngx-cookie-service';
import { PartnerPreferenceService } from '../services/partner-preference.service';
import { MyProfileService } from '../services/my-profile.service';
// unused import
// import * as moment from 'moment';
// import { of } from 'rxjs';
// import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from '../shared/auth.service';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotifService } from '../services/notif.service';
import { Title/*, Meta */} from '@angular/platform-browser';
import { ImageCroppedEvent, Dimensions, ImageTransform/*, LoadedImage, base64ToFile*/} from 'ngx-image-cropper';
import { RegisterNxtService } from '../services/register-nxt.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.css']
})
export class PhotoGalleryComponent implements OnInit {

  loading = false;
  // image code 
  imageUploadForm = new FormGroup({
    image: new FormControl(''),
  });
  file_array1: any = '';
  galleryPath: any = '';
  index: number;
  indexNext: number;
  indexPrevious: number;

  editphotoSrc: string = ''; // The source of the original image
  editimageChangedEvent: any = null; // Event for image change
  // photoId : number ;

  binaryImage: ArrayBuffer;

  editimageUploadForm = new FormGroup({
    image: new FormControl(''),
  });
  // edit image
  hoverIdx = -1;
  cimg: Event | any;
  imageOriginalName: string;
  imgSelected: boolean = false;
  multipleImage: boolean = false;
  Uploaded: boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = 'assets/images/men-avtar-2.svg';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  imgCount: number = 0;
  totalImgCount: number = 0;
  // image code 
  coverUploadForm = new FormGroup({
    image: new FormControl(''),
  });

  covercimg: Event | any;
  coverImageOriginalName: string;
  coverImgSelected: boolean = false;
  coverUploaded: boolean = false;
  coverImageChangedEvent: any = '';
  coverCroppedImage: any = 'assets/images/default_cover_photo.png';
  photoLoader: boolean = false;
  coverImgSize: boolean = true;
  photo_visibilty: any
  fileUpload: any;
  fileUploadCount: any;
  pasteEvnetStatus: boolean;
  is_uploaded = false;
  totalMediaLength: any;
  totalMediaSize: any;
  user_id: string;
  details: any = [];
  options = {
    timeOut: 0,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    preventDuplicates: true
  };
  myProfileGallery: any = [];
  photoId: any;
  // userPlanForm= new FormGroup({
  //   user_plan1 : new FormControl(false),
  //   user_plan2 : new FormControl(false),
  //   user_plan3: new FormControl(false),
  //   user_plan4 : new FormControl(false),
  // })
  userPlanForm = new FormGroup({
    // user_id: new FormControl(this.user_id, Validators.required),
    photo_visiblity: new FormControl('Visible to all members', Validators.required),
    visible_to_all: new FormControl(false),
    visible_to_connected: new FormControl(false),
    no_one: new FormControl(false),

  })
  Plan: any = [];
  PictureVisible: any;
  visibletoall: boolean = false;
  visibletopremium: boolean = false;
  visibletoelite: boolean = false;
  visibletonoone: boolean = false;
  imageBase64: string | ArrayBuffer | null;
  uploadMoreImage: boolean = true;
  frmData: any;

  photoStatusObj = {
    0: 'Awaiting approval',
    1: 'Approved',
    2: 'Not approved'
  }
  caption: string = '';
  photoDetail: any = [];
  showCaption: any;

  @ViewChild('wordCountForm') wordCountNgForm: NgForm;
  @ViewChild("texts") texts: ElementRef;
  wordCountForm = new FormGroup({
    user_id: new FormControl(''),
    description: new FormControl('', [Validators.required]),
  })
  wordCount: any;
  words: any;
  tellUsMore: any;
  exceedLimit: boolean;
  notClickable: boolean = true;
  addNewImg: boolean;
  @ViewChild('fileInput') fileInput: ElementRef;
  time: number;



  constructor(

    private _formBuilder: FormBuilder,
    private router: Router,
    private registerService: RegisterService,
    private cookieService: CookieService,
    private partnerPreferenceService: PartnerPreferenceService,
    private myProfileService: MyProfileService,
    private authService: AuthService,
    private http: HttpClient,
    private notifService: NotifService,
    private titleService: Title,
    private myprofileService: MyProfileService,
    private registerNxtservice: RegisterNxtService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.titleService.setTitle('My Photo-Gallery | eternalstrings.com');
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
    this.user_id = this.cookieService.get('user_id');
    let data = {
      user_id: this.user_id
    }
    this.userPhotoGallery();
    this.registerService.getUserDetails(data)
      .subscribe((user_details: any) => {
        this.details = user_details.userDetails;
        let register_id = this.details.register_done;
        localStorage.setItem('register_id', register_id);
        if (this.details != '') {
          if (this.details.package_id == 3) {
            this.totalImgCount = 20;
          } else if (this.details.package_id == 2) {
            this.totalImgCount = 10;
          } else if (this.details.package_id == 4) {
            this.totalImgCount = 5;
          }
          else {
            this.totalImgCount = 5;
          }

          let register_id = this.details.register_done

          if (register_id == 0) {
            this.router.navigate(['register']);
          } 
          if (register_id == '') {
            this.router.navigate(['register']);
          }
          else if (register_id == 1) {
            this.router.navigate(['registerNxt']);
          }
          else if (register_id == 2) {
            this.router.navigate(['step']);
          }
          else if (register_id == 3) {
            this.router.navigate(['stepTwo']);
          }
          else if (register_id == 4) {
            this.router.navigate(['stepDone']);
          }
          else if (register_id == 5) {
            this.router.navigate(['ProfilePage1']);
          } else if (register_id == 6) {
            this.router.navigate(['PartnerPreferences']);
          }
          else {
            this.router.navigate(['myPhotoGallery']);
          }

          if (this.details.profile_photo) {
            this.croppedImage = this.details.profile_photo;
          } else {
            // unused code
            // this.havePicture = false;
          }

          if (this.details.cover_photo) {
            this.coverCroppedImage = this.details.cover_photo;
          }
          if (this.details?.profile_photo) {
            this.is_uploaded = true;
            this.imgSelected = true;
            this.croppedImage = this.details.profile_photo;
          }
        }
      }
      );
    this.myPhotoGalleryAccess();
  }

  resetFileUploader() {
    this.fileInput.nativeElement.value = null;
    // unused code
    // setTimeout(() => {
    //   console.log("clear img")
    //   $('#closeLoginModal').click();
    // }, 2000);
  }

  getGalleryAccess() {
    let data = {
      user_id: this.user_id
    }
    this.myProfileService.getUserSettings(data).subscribe((res: any) => {
      if (res.status) {
        let str = res.userSettings.photo_visiblity;
        if (str == "Visible to all members") {
          setTimeout(() => {
            $('#visible_to_all_members').prop('checked', true);
            $('#no_one').prop('checked', false);
            $('#visible_to_connected_members').prop('checked', false);
            this.photo_visiblityF.visible_to_connected.setValue(false);
            this.photo_visiblityF.visible_to_all.setValue(true);
            this.photo_visiblityF.no_one.setValue(false);
          }, 1000);
        } else if (str == "Visible to connected members") {
          setTimeout(() => {
            $('#visible_to_connected_members').prop('checked', true);
            $('#visible_to_all_members').prop('checked', false);
            $('#no_one').prop('checked', false);
            this.photo_visiblityF.visible_to_connected.setValue(true);
            this.photo_visiblityF.visible_to_all.setValue(false);
            this.photo_visiblityF.no_one.setValue(false);
          }, 1000);
        } else if (str == "No one") {
          setTimeout(() => {
            $('#no_one').prop('checked', true);
            $('#visible_to_connected_members').prop('checked', false);
            $('#visible_to_all_members').prop('checked', false);
            this.photo_visiblityF.no_one.setValue(true);
            this.photo_visiblityF.visible_to_connected.setValue(false);
            this.photo_visiblityF.visible_to_all.setValue(false);
          }, 1000);
        }
      }
    })
  }
  
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.notClickable = false;
  }

  getDetail(event: any) {
    // console.log(event, event.target.value, this.userPlanForm.value)
  }

  myPhotoGalleryAccess() {
    let data = {
      user_id: this.user_id
    }
    this.getGalleryAccess();
    this.myprofileService.myPhotoGalleryAccess(data).subscribe((result) => {
      let tt = 2
    //  console.log(tt);
    },
      (error) => {
        this.toastrService.error('error!', 'visibleToMembers Not work !');
      },
      () => {

      });
  }

  checkUncheck(str) {
    if (str == "Visible to all members") {
      setTimeout(() => {
        $('#visible_to_all_members').prop('checked', true);
        $('#no_one').prop('checked', false);
        $('#visible_to_connected_members').prop('checked', false);
        this.photo_visiblityF.visible_to_connected.setValue(false);
        this.photo_visiblityF.visible_to_all.setValue(true);
        this.photo_visiblityF.no_one.setValue(false);
      }, 50);
    } else if (str == "Visible to connected members") {
      setTimeout(() => {
        $('#visible_to_connected_members').prop('checked', true);
        $('#visible_to_all_members').prop('checked', false);
        $('#no_one').prop('checked', false);
        this.photo_visiblityF.visible_to_connected.setValue(true);
        this.photo_visiblityF.visible_to_all.setValue(false);
        this.photo_visiblityF.no_one.setValue(false);
      }, 30);
    } else if (str == "No one") {
      setTimeout(() => {
        $('#no_one').prop('checked', true);
        $('#visible_to_connected_members').prop('checked', false);
        $('#visible_to_all_members').prop('checked', false);
        this.photo_visiblityF.no_one.setValue(true);
        this.photo_visiblityF.visible_to_connected.setValue(false);
        this.photo_visiblityF.visible_to_all.setValue(false);
      }, 10);
    } else {
      $('#no_one').prop('checked', false);
      $('#visible_to_connected_members').prop('checked', false);
      $('#visible_to_all_members').prop('checked', false);
      this.photo_visiblityF.no_one.setValue(false);
      this.photo_visiblityF.visible_to_connected.setValue(false);
      this.photo_visiblityF.visible_to_all.setValue(false);
    }
  }

  loader() {
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1500);
  }

  userPhotoGallery() {
    this.photoLoader = true;
    let data = {
      user_id: this.user_id
    }
    this.myprofileService.userPhotoGallery(data).subscribe(
      (result) => {
        if (result.status) {
          this.myProfileGallery = result.myProfileGallery;
          this.file_array1 = '';
          this.multipleImage = false;
        } else {
          //this.notifService.error('Image not Uploaded', result.message, this.options);
          // free 5 (ID =1),premium 15(ID =2),elite 20 (ID =3)
        }
        this.photoLoader = false;

      }, (error) => {                              //Error callback
        this.toastrService.error('Some thing went wrong!!');
        this.photoLoader = false;

      })
  }

  getD() {
    // console.log("button click")
    $('#selectFile').click();
  }

  searchPartner() {
    let data = {
      user_id: this.user_id
    }
    this.myprofileService.searchPartner(data).subscribe(
      (result) => {
        if (result?.status) {
          // unused code
          // this.myProfileGallery = result.myProfileGallery;
         // console.log();
        } else {
           // unused code
          // this.notifService.error('Image not Uploaded', result.message, this.options);
        }
      })
  }

  getUserDetails() {
    let data = {
      user_id: this.user_id
    }
    this.registerService.getUserDetails(data)
      .subscribe((user_details: any) => {
        this.details = user_details.userDetails;
        if (this.details.package_id == 3) {
          this.totalImgCount = 20;
        } else if (this.details.package_id == 2) {
          this.totalImgCount = 10;
        } else if (this.details.package_id == 4) {
          this.totalImgCount = 5;
        }
        else {
          this.totalImgCount = 5;
        }
        if (this.details != '') {
          let register_id = this.details.register_done
          if (register_id == 0) {
            this.router.navigate(['register']);
          } 
          if (register_id == '') {
            this.router.navigate(['register']);
          } else if (register_id == 1) {
            this.router.navigate(['registerNxt']);
          } else if (register_id == 2) {
            this.router.navigate(['step']);
          } else if (register_id == 3) {
            this.router.navigate(['stepTwo']);
          } else if (register_id == 4) {
            this.router.navigate(['stepDone']);
          } else if (register_id == 5) {
            this.router.navigate(['ProfilePage1']);
          } else if (register_id == 6) {
            this.router.navigate(['PartnerPreferences']);
          } else {
            this.router.navigate(['myPhotoGallery']);
          }

          if (this.details.profile_photo) {
            this.croppedImage = this.details.profile_photo;
          } else {
             // unused code
            //  this.havePicture = false;
          }

          if (this.details?.cover_photo) {
            this.coverCroppedImage = this.details.cover_photo;
          }

          if (this.details?.profile_photo) {
            this.is_uploaded = true;
            this.imgSelected = true;
            this.croppedImage = this.details.profile_photo;
          }
        }
      }
      );
  }

  submitForm() {
    const formData: any = new FormData();
    formData.append("image", this.croppedImage);
    formData.append("user_id", this.user_id);
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };
   // console.log(httpOptions)
    this.myprofileService.uploadImage(formData).subscribe(
      (result) => {
        if (result?.status) {
          this.registerNxtservice.getUserDetails({ user_id: this.user_id })
            .subscribe((data: any) => {
              if (data?.userDetails.profile_photo) {
                this.is_uploaded = true;
                this.Uploaded = true;
                this.croppedImage = data.userDetails.profile_photo;
                this.commonService.updateProfilePhoto(this.croppedImage);
                this.toastrService.success('Successfully!', 'Image Uploaded!');
              }
            })
          this.getUserDetails();
        } else {
          this.toastrService.error('Error!', 'Image not Uploaded!');
        }
      })
  }

  // upload timh code 
  uploadFile(event) {
    const selectedFiles: FileList = event.target.files;
    if (selectedFiles.length === 1) {
      this.imgSelected = true;
      this.imageOriginalName = event.target.files[0].name;
      this.fileType = event.target.files[0].type;
      this.imageChangedEvent = event;
    this.cimg = event ;
      this.is_uploaded = true;
      if ( event && event?.target && (event.target as HTMLInputElement).files) {
        const file_array = (event.target as HTMLInputElement).files;
        if ((event?.target as HTMLInputElement).files?.length == 1) {
          let fileDetails = file_array![0];
          this.totalMediaSize = fileDetails.size;
          if (this.totalMediaSize <= '5000000') {
            if (fileDetails.type == 'image/png' || fileDetails.type == 'image/jpeg' || fileDetails.type == 'image/webp' || fileDetails.type == 'image/x-icon' || fileDetails.type == 'image/bmp' || fileDetails.type == 'image/jpg') {
              let reader = new FileReader();
              reader.readAsDataURL(fileDetails);
              reader.onload = (evn: any) => {
                this.croppedImage = evn.target.result;
              }
              this.fileUpload = fileDetails;
            } else {
              this.is_uploaded = false;
              this.toastrService.error('Error!', 'Only jpg, gif, png, bmp files are allowed!');
            }
          } else {
            this.is_uploaded = false;
            this.clearImage();
            this.toastrService.error('Error!', 'Total File size should be smaller than 5 MB.!');
          }
        } else {
          this.is_uploaded = false;
        }
      } else {
        this.is_uploaded = false;
      }
    } else if (selectedFiles.length > 1) {
      this.multipleImage = true;
      $('#closeLoginModalMultiple').click();
      this.multipleImageUpload(event);
    } else {
      this.imgSelected = true;
      this.imageOriginalName = event.target.files[0].name;
      this.fileType = event.target.files[0].type;
      this.imageChangedEvent = event;
      event = this.cimg;
      this.is_uploaded = true;
      if (event?.target && (event.target as HTMLInputElement).files) {
        const file_array = (event.target as HTMLInputElement).files;
        if ((event.target as HTMLInputElement).files!.length == 1) {
          let fileDetails = file_array![0];
          this.totalMediaSize = fileDetails.size;
          if (this.totalMediaSize <= '5000000') {
            if (fileDetails.type == 'image/png' || fileDetails.type == 'image/jpeg' || fileDetails.type == 'image/webp' || fileDetails.type == 'image/x-icon' || fileDetails.type == 'image/bmp' || fileDetails.type == 'image/jpg') {
              let reader = new FileReader();
              reader.readAsDataURL(fileDetails);
              reader.onload = (evn: any) => {
                this.croppedImage = evn.target.result;
              }
              this.fileUpload = fileDetails;
            } else {
              this.is_uploaded = false;
              this.toastrService.error('Error!', 'Only jpg, gif, png, bmp files are allowed!');
            }
          } else {
            this.is_uploaded = false;
            this.toastrService.error('Error!', 'Total File size should be smaller than 5 MB.!');
          }
        } else {
          this.is_uploaded = false;
        }
      } else {
        this.is_uploaded = false;
      }
    }
  }

  fileType: any;
  imageCropped(event: ImageCroppedEvent) {
    let tempImage: any = event.base64;

    fetch(tempImage)
      .then(res => res.blob())
      .then(blob => {
        this.croppedImage = new File([blob], this.imageOriginalName, { type: this.fileType })
        this.coverCroppedImage = new File([blob], this.imageOriginalName, { type: this.fileType })
      })
  }

  fileType1: any;
  imageCropped1(event: ImageCroppedEvent) {
    let tempImage: any = event.base64;

    fetch(tempImage)
      .then(res => res.blob())
      .then(blob => {
        this.coverCroppedImage = new File([blob], this.imageOriginalName, { type: this.fileType1 })
      })
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.loading = true;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    this.loading = false;
  }

  loadImageFailed() {
    //  // console.log('Load failed');
  }

  rotateLeft() {
    this.loading = true;

  this.time = setTimeout(() => { // Use timeout because rotating image is a heavy operation and will block the ui thread
      this.canvasRotation++;
      this.flipAfterRotate();
    });
  }

  rotateRight() {
    this.loading = true;
    this.time = setTimeout(() => { // Use timeout because rotating image is a heavy operation and will block the ui thread
      this.canvasRotation--;
      this.flipAfterRotate();
    });
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  cancelImg() {
    this.imageUploadForm.reset();
    this.getUserDetails();
  }

  // cover photo img 
  uploadCover(event: Event) {
    this.coverImgSelected = true;
    this.imageChangedEvent = event;
    event = this.covercimg;
    this.is_uploaded = true;
    if (event?.target && (event.target as HTMLInputElement).files) {
      const file_array = (event.target as HTMLInputElement).files;
      if ((event?.target as HTMLInputElement).files!.length == 1) {
        let fileDetails = file_array![0];
        this.totalMediaSize = fileDetails.size;
        if (this.totalMediaSize <= '5000000') {
          if (fileDetails.type == 'image/png' || fileDetails.type == 'image/jpeg' || fileDetails.type == 'image/webp' || fileDetails.type == 'image/x-icon' || fileDetails.type == 'image/bmp' || fileDetails.type == 'image/jpg') {
            let reader = new FileReader();
            reader.readAsDataURL(fileDetails);
            reader.onload = (evn: any) => {
              this.coverCroppedImage = evn.target.result;
              this.coverImgSize = true;
            }
            this.fileUpload = fileDetails;
          } else {
            this.is_uploaded = false;
            this.coverImgSize = false;
            this.toastrService.error('Error!', 'Only jpg, gif, png, bmp files are allowed!');
          }
        } else {
          this.is_uploaded = false;
          this.coverImgSize = false;
          this.toastrService.error('Error!', 'Total File size should be smaller than 5 MB!');
        }
      } else {
        this.is_uploaded = false;
        this.coverImgSize = false;
        this.toastrService.error('Error!', 'Unable to upload more than one file at a time!');
      }
    } else {
      this.is_uploaded = false;
      this.coverImgSize = false;
    }
  }

  submitCoverPicture() {
    this.spinner.show();
    const formData: any = new FormData();
    formData.append("image", this.coverCroppedImage);
    formData.append("user_id", this.user_id);
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };
    if (this.coverCroppedImage.size <= '5000000') {
      this.myProfileService.uploadCoverImage(formData).subscribe(
        (result) => {
          if (result.status) {
            this.spinner.hide();
            this.coverImgSize = false;
            this.toastrService.success('cover photo uploaded successfully !  ');
          } else {
            this.spinner.hide();
            this.coverImgSize = false;
            this.toastrService.error('Error!', 'cover photo not Uploaded!');
          }
          this.getUserDetails();
        })
    } else {
      this.spinner.hide();
      this.toastrService.error('Error!', 'Total File size should be smaller than 5 MB!');
    }
  }
  // cover photo img


  // upload timh code 
  uploadGallary(event) {
    if (event?.target && (event.target as HTMLInputElement).files) {
      this.file_array1 = (event.target as HTMLInputElement).files;
      if (this.file_array1?.length + this.myProfileGallery.length > this.totalImgCount) {
        if (this.details.package_id == 3) {
          $('#openModal2').click();
        } else {
          $('#openModal1').click();
        }
      } else {
        if ((event?.target as HTMLInputElement).files!.length != 0) {
          for (const element of this.file_array1!) {
            let fileDetails = element;
            this.totalMediaSize = fileDetails.size;
            if (this.totalMediaSize <= '5000000') {
              if (fileDetails.type == 'image/png' || fileDetails.type == 'image/jpeg' || fileDetails.type == 'image/webp' || fileDetails.type == 'image/x-icon' || fileDetails.type == 'image/bmp' || fileDetails.type == 'image/jpg') {
                let reader = new FileReader();
                reader.readAsDataURL(fileDetails);
                reader.onload = (evn: any) => {
                }
                this.spinner.show();
                this.fileUpload = fileDetails;
                let files: File[] = event.target.files;
                const formData: any = new FormData();
                formData.append("user_id", this.user_id);
                for (const element of files) {
                  formData.append('file[]', element)
                }
                this.imgCount++
                this.frmData = formData;
              } else {
                this.is_uploaded = false;
                this.spinner.hide();
                this.toastrService.error('Error!', 'Only jpg, gif, png, bmp files are allowed!');
                return;
              }
            } else {
              this.is_uploaded = false;
              this.spinner.hide();
              this.toastrService.error('Error!', 'Total File size should be smaller than 5 MB!');
              return;
            }
          }
          this.frmData.append("file[]", this.croppedImage);
          if (this.imgCount >= 1 && this.totalMediaSize <= '5000000' || this.croppedImage) {
            this.myProfileService.uploadGallery(this.frmData).subscribe((res) => {
              if (res.status) {
                $('#closeModal2').click();
                this.toastrService.success('Successfully!', 'Image Uploaded!');

                this.spinner.hide();
                this.imgCount = 0;

                this.userPhotoGallery();
              } else {
                this.spinner.hide();
              }
            })
          } else {
            this.spinner.hide();
            this.is_uploaded = false;
            this.toastrService.error('Error!', 'you can not upload more photos ');
            this.imgCount = 0;
            $('#openModal1').click();
            return;

          }

        } else {
          this.is_uploaded = false;
          this.spinner.hide();
          this.toastrService.error('Error!', 'Unable to upload more than one file at a time!');
          return;
        }
      }
      //  // console.log("file_array",file_array)

    }
    else {
      this.is_uploaded = false;
      this.spinner.hide();
      return;

    }
    const formData: any = new FormData();
    formData.append("image", this.croppedImage);
    formData.append("user_id", this.user_id);
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };
    if (this.croppedImage) {
      if (this.totalMediaSize <= '5000000') {
        this.myProfileService.uploadGallery(formData).subscribe(
          (result) => {
            if (result.status) {
              $('#closeModal2').click();
              this.spinner.hide();
              this.coverImgSize = false;
              this.toastrService.success(' photos uploaded successfully !  ');
            } else {
              this.spinner.hide();
              this.coverImgSize = false;
              this.toastrService.error('Error!', 'photos not Uploaded!');
            }
            this.getUserDetails();
          })
      } else {
        this.spinner.hide();
        this.toastrService.error('Error !', 'Total File size should be smaller than 5 MB!');
      }
    }
  }

  uploadGallary2(event) {
    if (this.myProfileGallery.length == this.totalImgCount) {
      if (this.details.package_id == 3) {
        $('#openModal2').click();
      } else {
        $('#openModal1').click();
      }
    }

  }

  deletePhoto(event) {
    let frm = this.myProfileGallery[event]
    this.photoId = {
      user_gallery_id: frm.id
    }
  }

  yesDelete() {
    this.myProfileService.deletePhotoGallery(this.photoId).subscribe(
      (result) => {
        this.loader();
        this.userPhotoGallery()
        document.getElementById("confirmModalLabel")!.click();
        this.toastrService.success('Gallery image deleted successfully!');
      },
      (error) => {
      },
      () => {

      }
    );
  }

  RemoveElementFromStringArray3(element: string) {
    this.Plan.forEach((value: string, index: any) => {
      if (value == element) this.Plan.splice(index, 1);
    });
  }

  memberShip(option: any) {
    let data = {
      user_id: this.user_id,
      photo_visiblity: option
    }
    this.checkUncheck(option);
    if (this.userPlanForm.value.visible_to_all || this.userPlanForm.value.visible_to_connected || this.userPlanForm.value.no_one) {

      this.myProfileService.updateUserSettings(data).subscribe(
        (res) => {
          if (res.status) {
            this.toastrService.success(res.message);
          } else {
            this.toastrService.error(res.message);
          }
        });
    } else {
      // unused code
      // this.toastrService.error("At least one checkbox is required to check");
    }
  }

  get photo_visiblityF() {
    return this.userPlanForm.controls;
  }

  // edit image function.

  openCropper() {
    if (this.editphotoSrc) {
      fetch(this.editphotoSrc)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], ''); // Create a file from the blob
          const inputEvent = {
            target: {
              files: [file]
            }
          };
          this.editfileChangeEvent(inputEvent);
        })
        .catch(error => {
          // unused code
          // console.error('Failed to fetch image:', error);
        });
    }
  }

  editfileChangeEvent(event: any): void {
    this.editphotoSrc = '';
    this.editimageChangedEvent = event;
  }
  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  editimageLoaded() {
    // Image loaded event handler
  }

  editcropperReady(event: any) {
    // Cropper ready event handler
  }

  editloadImageFailed() {
    // Load image failed event handler
  }

  editNow(photo: string, id: number) {
    this.editphotoSrc = photo;
    this.photoId = id;
    this.openCropper();
  }
  editsubmitForm() {  
    try {
      const formData: any = new FormData();
      formData.append('file[]', this.croppedImage);
      formData.append('user_id', String(this.user_id));
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json'
        })
      };
    if (this.croppedImage) {
      if (this.croppedImage?.size <= '5000000') {
        this.myProfileService.uploadGallery(formData).subscribe(
          (result) => {
            if (result.status) {
              $('#closeModal2').click();
              this.toastrService.success(result.message);
              this.userPhotoGallery();
              this.spinner.hide();
              this.imgSelected = false;
              // Handle success case
            } else {
              this.spinner.hide();
              this.coverImgSize = false;
              this.toastrService.error('Error!', 'photos not Uploaded!');
            }
            this.getUserDetails();
          })
      } else {
        this.spinner.hide();
        this.toastrService.error('Error !', 'Total File size should be smaller than 5 MB!');
      }
    }
       // this.uploadFile(this.croppedImage);
      // this.spinner.show();
      // this.myprofileService.uploadGallery(formData).subscribe(
      //   (result) => {
      //     if (result.status) {
      //       $('#closeModal2').click();
      //       this.toastrService.success(result.message);
      //       this.userPhotoGallery();
      //       this.spinner.hide();
      //       this.imgSelected = false;
      //       // Handle success case
      //     } else {
      //       // Handle error case
      //       this.toastrService.error('Some thing went wrong!!');
      //     }
      //   },
      //   (error) => {
      //     // Handle error case
      //     this.toastrService.error('Some thing went wrong!!');
      //     this.imgSelected = false;
      //     this.spinner.hide();
      //   }
      // );
    } catch (error) {
      // console.error('Failed to decode base64 string:', error);
    }
  }
  resetImg() {
    this.imgSelected = false;
    this.imageOriginalName = null;
    this.fileType = null;
    this.imageChangedEvent = null;
  }

  resetFile() {
    $('#resetimgfield').click();
    this.resetImage();
    this.resetFileUploader();
    this.imgSelected = false;
    this.imageOriginalName = null;
    this.fileType = null;
    this.imageChangedEvent = null;
    this.fileInput.nativeElement.value = '';
  }

  editOpen(event) {
    let frm = this.myProfileGallery[event]
    this.photoId = {
      user_gallery_id: frm.id
    }
    $('#openmodal3').click();
    setTimeout(() => {
   //   console.log("imagse clear ")
      $('#resetimgfield11').click();
    }, 500);
  }

  clearImage() {
    setTimeout(() => {
      $('#resetimgfield').click();
      $('#resetimgfield22').click();
      $('#resetimgfield3').click();
    }, 500);
    clearTimeout(this.time);
    this.fileInput.nativeElement.value = '';
    this.resetImage();
    
    this.resetFileUploader();
    this.imgSelected = false;
    this.imageOriginalName = null;
    this.fileType = null;
    this.imageChangedEvent = null;
  }
  // edit image function end

  goOnPackeg() {
    localStorage.setItem('package_id', this.details.package_id);
    this.router.navigate(['/upgrade']);
  }

  openlight(data: any, index: number) {
    this.galleryPath = data.photo_url;
    this.showCaption = data.caption
    this.index = index;
  }

  nextSlides() {
    this.index = this.index + 1;
    this.indexNext = this.index;
    if (this.indexNext != this.myProfileGallery.length) {
      this.galleryPath = this.myProfileGallery[this.indexNext].photo_url;
      this.showCaption = this.myProfileGallery[this.indexNext].caption;
    } else if (this.indexNext == this.myProfileGallery.length) {
      this.galleryPath = this.myProfileGallery[0].photo_url;
      this.showCaption = this.myProfileGallery[0].caption;
      this.index = 0;
    }
  }

  previousSlides() {
    this.index = this.index - 1;
    this.indexPrevious = this.index;
    if (this.indexPrevious != -1) {
      this.galleryPath = this.myProfileGallery[this.indexPrevious].photo_url;
      this.showCaption = this.myProfileGallery[this.indexPrevious].caption;
    } else if (this.indexPrevious == -1) {
      this.galleryPath = this.myProfileGallery[(this.myProfileGallery.length - 1)].photo_url;
      this.showCaption = this.myProfileGallery[(this.myProfileGallery.length - 1)].caption;
      this.index = (this.myProfileGallery.length - 1)
    }
  }

  addCaption(photo) {
    this.photoDetail = photo
    if (this.photoDetail.caption != "" && this.photoDetail.caption != null) {
      this.caption = this.photoDetail.caption
    } else {
      this.caption = ''
    }
  }

  saveCaption() {
    let dd = {
      id: this.photoDetail.id,
      user_id: this.photoDetail.user_id,
      caption: this.caption.replace(/\s{2,}/g, ' ')
    }
    this.myprofileService.setPhotoCaption(dd).subscribe(
      (result) => {
        if (result.status) {
          this.toastrService.success(result.message);
          this.userPhotoGallery();
          this.caption = '';
        } else {
          // Handle error case
        }
      },
      (error) => {
        // Handle error case
        this.toastrService.error('Some thing went wrong!!');
      }
    );
  }

  wordCounter(event) {
    let para = this.wordCountForm.value.description;
    if (para.trim().length > 0) {
      this.wordCount = this.texts ? this.texts.nativeElement.value.split(/\s+/) : 0;
      this.words = this.wordCount ? this.wordCount.length : 0;
      if (this.words >= 11 && event.code != 'Backspace' && event.code != 'Delete') {
        this.wordError();
         // before sonarqube
        // const pattern = /[a-zA-Z0-9&_\.!,-\s()"']/g;
        const pattern = /[a-zA-Z0-9&_.!,\-\s()"']/g;
        if (!pattern.test(event.target.value)) {
          event.preventDefault();
          return false;
        } else { 
            // before sonarqube
          // event.target.value = event.target.value.replace(/[a-zA-Z0-9&_\.!,-\s()']/g, "");
          // this.tellUsMore = this.tellUsMore.replace(/  +/g, ' ');
          event.target.value = event.target.value.replace(/[a-zA-Z0-9&_.,!\-\s()']/g, "");
          this.tellUsMore = this.tellUsMore.replace(/ {2,}/g, ' ');
          this.wordCountForm.patchValue({ 'description': this.tellUsMore.trim() + ' ' });
          this.words = this.tellUsMore.split(/\s+/).length
          event.preventDefault();
          return false;
        }
      } else {
        this.wordError();
        this.tellUsMore = this.wordCountForm.value.description;
      }
    } else {
      this.wordError();
      this.words = 0;
    }
  }

  wordError() {
    if (this.words > 10) {
      this.exceedLimit = true;
    } else {
      this.exceedLimit = false;
    }
  }

  pasteNumeric6(event) {
    setTimeout(() => {
      let para = this.wordCountForm.value.description;
      if (para.trim().length > 0) {
        this.wordCount = this.texts ? this.texts.nativeElement.value.split(/\s+/) : 0;
        this.words = this.wordCount ? this.wordCount.length : 0;
        if (this.words >= 11) {
          this.wordCount = this.wordCount.slice(0, 10);
          this.words = this.wordCount ? this.wordCount.length : 0;
          this.wordCountForm.patchValue({ 'description': this.wordCount.join(" ") });
          this.wordError();
        }
      } else {
        this.wordError();
        this.words = 0;
      }
    }, 500);
  }

  pos = "";
  compare() {
    let pre = this.wordCount.value.description;
    setTimeout(() => {
      this.pos = this.wordCount.value.description;
      if (pre == this.pos) {
      }
    }, 500);
    if (pre == this.pos) {
    }
  }


  // code for multiple images start
  multipleImageUpload(event) {
    if (event?.target && (event.target as HTMLInputElement).files) {
      this.file_array1 = (event.target as HTMLInputElement).files;
      if (this.file_array1?.length + this.myProfileGallery.length > this.totalImgCount) {
        if (this.details.package_id == 3) {
          $('#openModal2').click();
        } else {
          $('#openModal1').click();
        }
      } else {
        if ((event?.target as HTMLInputElement).files!.length != 0) {
          for (const element of this.file_array1!) {
            let fileDetails1 = element;
            this.totalMediaSize = fileDetails1.size;
            if (this.totalMediaSize <= '5000000') {
              if (fileDetails1.type == 'image/png' || fileDetails1.type == 'image/jpeg' || fileDetails1.type == 'image/webp' || fileDetails1.type == 'image/x-icon' || fileDetails1.type == 'image/bmp' || fileDetails1.type == 'image/jpg') {
                let reader = new FileReader();
                reader.readAsDataURL(fileDetails1);
                reader.onload = (evn: any) => {
                }
                this.spinner.show();
                this.fileUpload = fileDetails1;
                let files: File[] = event.target.files;
                const formData: any = new FormData();
                formData.append("user_id", this.user_id);
                for (const element of files) {
                  formData.append('file[]', element)
                }
                this.imgCount++
                this.frmData = formData;

              } else {
                this.is_uploaded = false;
                this.spinner.hide();
                this.toastrService.error('Error!', 'Only jpg, gif, png, bmp files are allowed!');
                return;
              }
            } else {
              this.is_uploaded = false;
              this.spinner.hide();
              this.toastrService.error('Error!', 'Total File size should be smaller than 5 MB!');
              return;
            }
          }
          if (this.imgCount >= 1 && this.totalMediaSize <= '5000000' || this.croppedImage) {
            this.myProfileService.uploadGallery(this.frmData).subscribe((res) => {
              if (res.status) {
                $('#closeModal2').click();
                $('#closeLoginModal').click();
                this.toastrService.success('Successfully!', 'Gallery images Uploaded!');
                this.spinner.hide();
                this.imgCount = 0;
                this.userPhotoGallery();
                this.multipleImage = false;
              } else {
                this.spinner.hide();
              }
            })
          } else {
            this.spinner.hide();
            this.is_uploaded = false;
            this.toastrService.error('Error!', 'you can not upload more photos ');
            this.imgCount = 0;
            $('#openModal1').click();
            return;
          }

        } else {
          this.is_uploaded = false;
          this.spinner.hide();
          this.toastrService.error('Error!', 'Unable to upload more than one file at a time!');
          return;
        }
      }
    }
    else {
      this.is_uploaded = false;
      this.spinner.hide();
      return;

    }
  }

  // code for multiple images end
  addNew() {
    this.addNewImg = true;
  }
}
