<section class="px-3 mt-120">
  <div class="container mx-auto border border-1 shadow-sm my-4 p-3 bg-white rounded-5">

    <div class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
      <div class="row mb-3">
        <div class="col-6">
          <h5>
            Compare Matches </h5>
        </div>
        <div class="col-6 text-end" *ngIf="ShowFavouriteBtn">
          <button type="button" style="white-space: nowrap;" class="w-170 btn btn-danger w-210" (click)="goNow()">
            Go to My Favourite
          </button>

        </div>
      </div>
    </div>
    <div>
      <!-- .....content loader code start...... -->
      <table class="table animated-background" *ngIf="compareLoader">
        <caption style="display: none;">compare</caption>
        <thead class="animated-background">
          <tr>
            <th class="border-0" scope="row">
              <div class="ratio-11 center flex-column border border-1 ">
                <div class="p-3 rounded-circle w-5-h-5 ">
                  <i class="fas fa-plus fs-4"></i>
                </div>
                <p class="py-1 mb-0">Add Matches</p>

              </div>
            </th>
            <th class="border-0 " scope="row" *ngFor="let number of [0,1,2]">
              <div class="ratio-1 position-relative">
                <img src="assets/images/default-profile-picture.svg" alt="" class="image-cover animated-background-name">
                <div placement="top" class="position-absolute
                  top-0 right-0 w-25-h-25 ">
                  <i class="fas fa-times-circle fs-5"></i>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="table1 animated-background">
          <tr>
            <th scope="row">Name</th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row">Age</th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row">Height</th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row">Weight</th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row"> Living With Family </th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row"> Family Type </th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row"> Marital Status </th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row"> Have Children </th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row"> Religious Views </th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row">Preferred Language</th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row">Country Living In</th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row">State/Province</th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row"> Residency Status </th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row"> Education </th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row"> Nature of Work </th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row"> Profession Area </th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row">Annual Income</th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row">Food Preference</th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row">Drinking Habit </th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row">Smoking </th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row">Pets </th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>
            <th scope="row">City Living In</th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox mt-4 animated-background-name"></span></td>
          </tr>
          <tr>

            <th scope="row">
            </th>
            <td *ngFor="let number of [0,1,2]"><span class="footerBlankBox1 mt-4 animated-background-name"></span></td>
          </tr>
        </tbody>
      </table>

      <!-- .....content loader code end...... -->
    </div>
    <div class="table-responsive">

      <table class="table" *ngIf="!compareLoader">
        <caption style="display: none;">compare2</caption>
        <thead>
          <tr>
            <th class="border-0" scope="row">
              <div class="ratio-1 center flex-column border border-1 border-danger" routerLink="/matches-shortlist">
                <div
                  class="p-3 rounded-circle w-50-h-50 center border-dashed border border-1 border-danger btn-light flex-column">
                  <i class="fas fa-plus fs-4"></i>
                </div>
                <p class="py-1 mb-0">Add Matches</p>

              </div>
            </th>
            <th class="border-0" scope="row" *ngFor="let profile of camparedProfiles;let i= index">
              <div class="ratio-1 position-relative">
                <img *ngIf="!camparedProfiles[i]?.profile_photo || camparedProfiles[i]?.profile_photo_approved!=1  "
                  src="assets/images/default-profile-picture.svg" class="image-cover" alt>
                <div
                  *ngIf="camparedProfiles[i]?.profile_photo != null && !!camparedProfiles[i]?.profile_photo && camparedProfiles[i]?.profile_photo_approved==1  ">
                  <ng-container
                    *ngIf="profile?.photo_visiblity == 'No one' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage != 5)">
                    <img class="image-cover" src="assets/images/default-profile-picture.svg" alt=""
                      alt="assets/images/men-avtar-2.svg">
                  </ng-container>
                  <ng-container
                    *ngIf="profile?.photo_visiblity == 'Visible to all members' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage == 5)">
                    <app-progressive-image class="progressive-image">
                      <img appProgressiveImage [src]="camparedProfiles[i]?.profile_photo" alt="" class="img-fluid image-cover">

                      <div appProgressiveImagePlaceholder class="placeholderOneOrThree" style="min-height:100%;">
                        <img appProgressiveImageFallback class="img-fluid image-cover"
                          src="assets/images/default-profile-picture.svg" alt="">

                      </div>
                      <div appProgressiveImageFallback class="fallback">
                        <img appProgressiveImageFallback class="img-fluid image-cover"
                          src="assets/images/default-profile-picture.svg" alt="">
                      </div>
                    </app-progressive-image>
                  </ng-container>
                </div>
                <div type="button" (click)="removeItem(profile)" placement="top" ngbTooltip="Remove Match" class="position-absolute
                  top-0 right-0 w-25-h-25 btn-light center rounded-circle cursor-pointer border border-1 border-danger"
                  style="transform: translate(8px, -8px);">
                  <i class="fas fa-times-circle fs-5"></i>
                </div>
                <div type="button" placement="top" ngbTooltip="Add to My Favourite"
                  class="position-absolute btn-danger w-40-h-40 rounded-circle center bottom-0" style="right: 50%;
                  transform: translate(50%, 50%)" (click)="addToFavoriteList(profile.user_id)">
                  <i class="fas fa-heart fs-4"></i>
                </div>
              </div>
            </th>
          </tr>
          <tr class="pt-4 d-block">
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Name</th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.display_name}}
              <span class="user_email_verified"
                [ngbTooltip]="camparedProfiles[i]?.is_user_verified ==1 ? 'Verified user': 'Verified email'"
                placement="bottom">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                  class="bi bi-shield-fill-check mb-1 " [ngClass]="{'text-muted':camparedProfiles[i]?.is_user_verified != 1,
                                               'text-success':camparedProfiles[i]?.is_user_verified == 1}"
                  viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0
                                    0-1.044
                                    1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517
                                    2.453c.386.273.744.482
                                    1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159
                                    7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195
                                    3.061-5.513
                                    2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0
                                    0-2.887-.87C9.843.266
                                    8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708
                                    0l-1.5-1.5a.5.5 0
                                    1 1 .708-.708L7.5 7.793l2.646-2.647z" />
                </svg>
              </span>

              <span class="user_email_verified1 " *ngIf="camparedProfiles[i]?.is_user_verified== 0 " placement="bottom"
                ngbTooltip="Verified email"><i class="fa fa-check fs-5" style="color:green"></i>
              </span>
              <span class="user_email_verified1 " *ngIf="camparedProfiles[i]?.is_user_verified== 1 " placement="bottom"
                ngbTooltip="Verified user"><i class="fa fa-check-double fs-5 " style="color:green"></i>
              </span>
            </td>
          </tr>
          <tr>
            <th>Age</th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.age }} yrs
            </td>
          </tr>
          <tr>
            <th scope="row">Height</th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.height}} cm
              ({{camparedProfiles[i]?.height
              |heightConverter:"Ft"}})</td>
          </tr>
          <tr>
            <th scope="row">Weight</th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.weight | weightConverter :
              "Kgs"}} kg
              ({{camparedProfiles[i]?.weight}} lb)</td>
          </tr>
          <tr>
            <th scope="row"> Living With Family </th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.living_with_family}}</td>
          </tr>

          <tr>
            <th scope="row"> Family Type </th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.family_type}}</td>
          </tr>

          <tr>
            <th scope="row"> Marital Status </th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.marital_status }}</td>
            <!-- <td>Single</td>
                        <td>Single</td>
                        <td>Single</td>
                        <td>Single</td> -->
          </tr>

          <tr>
            <th scope="row"> Have Children </th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.have_children }}</td>
            <!-- <td> No </td>
                        <td> No </td>
                        <td> No </td>
                        <td> No </td> -->
          </tr>

          <tr>
            <th scope="row"> Religious Views </th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.religion}}</td>
          </tr>

          <tr>
            <th scope="row">Preferred Language</th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.languages }}</td>
            <!-- <td>Hindi</td>
                        <td>Punjabi</td>
                        <td>English</td>
                        <td>Hindi</td> -->
          </tr>

          <tr>
            <th scope="row">Country Living In</th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.current_country }}</td>
            <!-- <td>India</td>
                        <td>Canada</td>
                        <td>USA</td>
                        <td>UAE</td> -->
          </tr>

          <tr>
            <th scope="row">State/Province</th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.current_state }}</td>
            <!-- <td>M.p</td>
                        <td>Toronto</td>
                        <td>Chicago</td>
                        <td>Dubai</td> -->
          </tr>

          <tr>
            <th scope="row"> Residency Status </th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.residency_status }}</td>
            <!-- <td>Citizen </td>
                        <td>Citizen </td>
                        <td>Citizen </td>
                        <td>Citizen </td> -->
          </tr>

          <tr>
            <th scope="row"> Education </th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.qualification }}</td>
            <!-- <td>Some University</td>
                        <td>Some University</td>
                        <td> Some University </td>
                        <td>Some University</td> -->
          </tr>

          <tr>
            <th scope="row"> Nature of Work </th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.nature_of_work }}</td>
            <!-- <td>Business </td>
                        <td>Government Company </td>
                        <td> Private Company </td>
                        <td>Self Employed </td> -->
          </tr>

          <tr>
            <th scope="row"> Profession Area </th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.working_as }}</td>
            <!-- <td>Accounting </td>
                        <td> Banking & Finance </td>
                        <td> Aviation </td>
                        <td> Beauty & Fashion </td> -->
          </tr>

          <tr>
            <th scope="row">Annual Income</th>
            <td *ngFor="let profile of camparedProfiles;let i= index"> {{camparedProfiles[i]?.currency }}
              {{camparedProfiles[i]?.annual_income | number
              }}</td>
          </tr>

          <tr>
            <th scope="row">Food Preference</th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.food_preference}}</td>
          </tr>

          <tr>
            <th scope="row">Drinking Habit </th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.do_drink}}</td>
          </tr>

          <tr>
            <th scope="row">Smoking </th>
            <td *ngFor="let profile of camparedProfiles;let i= index"> {{camparedProfiles[i]?.do_smoke}} </td>
          </tr>

          <tr>
            <th scope="row">Pets </th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.have_pet}} </td>
          </tr>

          <tr>
            <th scope="row">City Living In</th>
            <td *ngFor="let profile of camparedProfiles;let i= index">{{camparedProfiles[i]?.current_city}}</td>
          </tr>

          <tr>

            <th scope="row">

            </th>

            <th scope="row" *ngFor="let profile of camparedProfiles;let i= index">
              <button class="btn btn-danger" (click)="addToFavoriteList(profile.user_id)">Add Favourite</button>
            </th>
          </tr>

        </tbody>
      </table>

    </div>

  </div>
</section>

<app-login-footer></app-login-footer>
