import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StepService {
  private registerThreeUrl =`${environment.apiURL}/auth/registerThree`;
  private getUserdetailsUrl =`${environment.apiURL}/auth/getUserDetails`;
 
  constructor(
    private http: HttpClient
  ) { }

  registerThree(user: any): Observable<any> {
    return this.http.post(this.registerThreeUrl, user);
  }
  getUserDetails(user:any): Observable<any> {
    return this.http.post(this.getUserdetailsUrl, user);
  }
  
}