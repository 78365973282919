import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gMTDate'
})
export class GMTDatePipe implements PipeTransform {

  transform(value: string): string {
    // Check if the input value is a valid date string
    if (!value || isNaN(Date.parse(value))) {
      return value;
    }

    // Create a Date object from the input string
    const localDate = new Date(value);

    // Get the UTC values from the local date
    const utcDate = new Date(
      localDate.getUTCFullYear(),
      localDate.getUTCMonth(),
      localDate.getUTCDate(),
      localDate.getUTCHours(),
      localDate.getUTCMinutes(),
      localDate.getUTCSeconds()
    );

    // Format the UTC date string as per your requirements
    const formattedDate = utcDate.toISOString().replace('T', ' ').replace(/\.\d+Z$/, '');

    return formattedDate;
  }

}
