import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private issuer = {
    login: `${environment.apiURL}/auth/login`,
    register: `${environment.apiURL}/auth/register`,
  };
  constructor(
    private cookieService: CookieService
  ) { }
  handleData(token: any, user_id: any, remember_me: boolean) {
    if (remember_me) {
      this.cookieService.set('auth_token', token, 15, '/');
      this.cookieService.set('user_id', user_id, 15, '/');
      this.cookieService.set('remember_me', 'true', 15, '/');
    } else {
      this.cookieService.set('auth_token', token, null,'/');
      this.cookieService.set('user_id', user_id, null,'/');
      this.cookieService.set('remember_me', 'false', null,'/');
      sessionStorage.setItem('is_remember', token)
    }
  }
  getToken() {
    return this.cookieService.get('auth_token');
  }
  // Verify the token
  isValidToken() {
    const token = this.getToken();
    if (token) {
      const payload = this.payload(token);
      if (payload) {
        return Object.values(this.issuer).indexOf(payload.iss) > -1
          ? true
          : false;
      }
    } else {
      return false;
    }
  }
  payload(token: any) {
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }
  // User state based on valid token
  isLoggedIn() {
    // if(this.cookieService.get('remember_me') == 'false' && sessionStorage.getItem('is_remember') == null) {
    //   return false;
    // }
    return this.isValidToken();
  }
  // Remove token
  removeToken() {
    this.cookieService.delete('auth_token');
    this.cookieService.delete('user_id');
    this.cookieService.delete('remember_me');
  }
}