import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private approvalStageMessage = new BehaviorSubject(true);
  currentApprovalStageMessage = this.approvalStageMessage.asObservable();

  private approvalStageMessage1 = new BehaviorSubject('');
  currentApprovalStageMessage1 = this.approvalStageMessage1.asObservable();

  private approvalStageChat = new BehaviorSubject(0);
  currentApprovalStageChat = this.approvalStageChat.asObservable();

  private registerDoneStep = new BehaviorSubject(0);
  subscribeRegisterDoneStep = this.registerDoneStep.asObservable();

  userDetails: any;
  previousUrl: string = '';
  currentUrl: string = '';
  countryList: any = [];
  stateListByCountry: any = {}
  lookingFor: any = '';
  filterData: any;
  // httpClient: any;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  updateLoginStatus(status: any) {
    this.approvalStageMessage.next(status)
  }
  updateProfilePhoto(profile_photo) {
    this.approvalStageMessage1.next(profile_photo)
  }
  setChatUserId(user_id) {
    this.approvalStageChat.next(user_id)
  }
  setRegisterDoneStep(step) {
    this.registerDoneStep.next(step)
  }

  setLookingFor(looking_for) {
    this.lookingFor = looking_for
  }
  getLookingFor() {
    return this.lookingFor;
  }
  public getPreviousUrl() {
    return this.currentUrl;
  }

  formatDate(date: any, delimiter: any = '-') {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join(delimiter);
  }


  encryptData(data: any) {
    try {
      if (data) {
        return CryptoJS.AES.encrypt(JSON.stringify(data), '1234509876encryptdecrypt1234567890').toString();
      }
    } catch (e) {
      console.log(e);
    }
  }

  //End encrypt Data(n)
  decryptData(data: any) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, '1234509876encryptdecrypt1234567890');
      if (bytes.toString()) {
        if (CryptoJS.enc.Utf8) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
      }
      if (data) {
        return data;
      }

    } catch (e) {
      //console.log(e);y
    }
  }
  setUserDetails(data: any) {
    this.userDetails = data;
  }
  getMyDetails() {
    return this.userDetails;
  }
  getUser(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getUser`, data);
  }

  getUserDetails(data: any): Observable<any> {
    if (this.userDetails) {
      return of({ userDetails: this.userDetails });
    } else {
      return this.http.post(`${environment.apiURL}/auth/getUserDetails`, data);

    }
  }

  getCountryList(data: any): Observable<any> {
      return this.http.get(`${environment.apiURL}/auth/getCountryList`);
  }
  updateCountryList(countryList: any){
    this.countryList = countryList;
  }
  getCountries(){
    return this.countryList;
  }
  
  //  getStateList(country) {
  //   if(this.stateListByCountry[country] && this.stateListByCountry[country].length > 0) {
  //     this.stateListByCountry[country]
  //   } else {
  //     this.stateListByCountry[country] =this.http.get(`${environment.apiURL}/auth/getStateList`);
  //     this.stateListByCountry[country]
  //   }
  //  }

  setFilterData(data: any) {
    this.filterData = data;
    return true;
  }

  getFilterData() {
    if (this.filterData) {
      var details = this.filterData;
      return details;
    } else {
      return false;
    }
  }

  index(): Observable<any> {
    return this.http.get(`${environment.apiURL}/auth/index`);
  }

  getIp(): Observable<any> {
    return this.http.get(`${environment.apiURL}/auth/index`);
  }




}
