<div class="w-100 bg-maroon" *ngIf="!isLoggedIn">
  <app-header></app-header>
</div>
<div class="page-header">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-caption">
        </div>
      </div>
    </div>
  </div>
</div>


<section class="container my-4 px-3">
 
  <div class="card p-4 bg-white rounded-5 min_content_height_202 mt-120">
    <div class="my-3">
      <h3 class="text-center fw-bold text-danger">DISCLAIMER</h3>
    </div>
    <h6 class=" fw-bold mb-5">Last updated March 6, 2023</h6>

    <p class="lh-lg font-9-rem mb-3">
      THE JUB WE MET AND OR ETERNAL STRINGS APP, SITE, OUR CONTENT, AND MEMBER CONTENT ARE AVAILABLE TO YOU “AS IS” AND WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT.
    </p>

    <p class="lh-lg font-9-rem mb-3">
      IF THE INCLUSION OF WARRANTEES (EXPRESSED OR IMPLIED) ARE REQUIRED BY LAW, THEN WE GRANT THE MINIMUM EXPRESSED OR IMPLIED WARRANTY REQUIRED BY APPLICABLE LAW. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, SHALL CREATE ANY WARRANTY, REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN THIS SECTION.
    </p>


    <p class="lh-lg font-9-rem mb-3">
      WE DO NOT GUARANTEE THAT THE APP OR SITE WILL BE UNINTERRUPTED, SECURE OR ERROR FREE. NOR DO WE MAKE ANY WARRANTEES THAT YOUR USE OF THE APP OR SITE WILL MEET YOUR EXPECTATIONS, OR THAT THE APP, SITE, OUR CONTENT, ANY MEMBER CONTENT, OR ANY PORTION THEREOF, IS CORRECT, ACCURATE, OR RELIABLE. YOUR USE OF THE APP OR SITE IS AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS. JUB WE MET AND OR ETERNAL STRINGS AND ITS AFFILIATES CLAIM NO RESPONSIBILITY FOR THE CONDUCT OF ANY USER. JUB WE MET AND OR ETERNAL STRINGS DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS MEMBERS OR AFFILIATES.
    </p>

  </div>


</section>


<app-footer></app-footer>