import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-success-story',
  templateUrl: './success-story.component.html',
  styleUrls: ['./success-story.component.css']
})

export class SuccessStoryComponent implements OnInit {
  // sanitizedContent: SafeHtml;
  carouselItems1: any = [
    {
      blog_title: 'Hethin & Kamal',
      location: 'Vancouver, Canada',
      success_story_image: 'assets/images/success-wedding/success-wedding-1.JPG',
      blog_content:  "<p>A huge thank you to our matchmaker Gail Bains for introducing me to my soul mate. She set us up on a blind date and we instantly connected. We had so much in common and just felt so comfortable being around each other. We dated for awhile and both came to the realization that we couldn&#39;t live without one another, so we ended up getting married. Without Gail&#39;s matchmaking skills and intuition we wouldn&#39;t be where we are today, in a strong and loving relationship. Our foundation for the rest of our lives has been laid and it&rsquo;s all thanks to Gail. Thank you again for changing our lives and helping us find happiness, true love and joy.<\/p>",
    },
    {
      blog_title: 'Mariah & Karnvir',
      location: 'Surrey, Canada',
      success_story_image: 'assets/images/success-wedding/success-wedding-2.JPG',
      blog_content: "<p>Gail Bains is very kind and caring, and direct. She calls things out, which I appreciate, and we talk through real-life situations, applying the principals I am learning. I trust that I can share anything with Gail, including insecurities I might have. She is supportive, nonjudgmental, and encouraging. Gail Bains approach is very different than what I expected. She helped me understand and realize the importance of what it was that I was looking for in a life partner. I highly recommend working with Gail as she was able to help find my soul mate. I truly can&rsquo;t thank you enough.<\/p>\r\n\r\n<p>Mariah<\/p>\r\n\r\n<p>Gail, thank you so much for making me believe again that the love of my life is somewhere out there. I honestly at some points felt hopeless until I met and worked with you. She made me believe in connection, love, partnership, and companionship again. By the time I found Gail I was hopelessly thinking that there is no one out there that could be a good match for me. She has changed that- she gave me confidence, put a smile on my face and made me believe in love again! She is an incredible woman. I would highly recommend her for anyone who values their time and energy.<\/p>\r\n\r\n<p>Karnvir<\/p>",    },
    {
      blog_title: 'Rupdesh & Kirat',
      location: 'Morgan Town, USA',
      success_story_image: 'assets/images/success-wedding/success-wedding-3.JPG',
      blog_content: "<p>My husband and I are so grateful to Gail Bains for bringing us together. I am from Uttarakhand in India, while he is from Virginia in the US, and we never would have met if it weren&#39;t for Gail&#39;s thoughtful matchmaking skills. She introduced us and within a couple of months, we were happily married. Gail&#39;s kindness and generosity made a difference in our lives, and we highly recommend her as a trustworthy and caring matchmaker. Thank you, Gail, for changing our lives and helping us find happiness together.<\/p>",
    },
    {
      blog_title: 'Harminder & Harkirat',
      location: 'Surrey, Canada',
      success_story_image: 'assets/images/success-wedding/success-wedding-4.jpeg',
      blog_content: "<p>Our journey to find true love and companionship has been a dream come true, and we owe a heartfelt thank you to Eternal String. This incredible platform, brought to our attention through the dedicated and insightful service of Gail Bains, has been a cornerstone in our love story. From the moment we engaged with Eternal String, we realized the uniqueness of their approach to matchmaking &ndash; it&#39;s personalized, thoughtful, and genuinely focused on creating meaningful connections. The journey with them was not just about finding a partner, but about understanding ourselves and what we truly sought in a life companion. Today, as we reflect on our blissful marital life, we recognize how instrumental Eternal String has been. It&#39;s more than a matrimonial site; it&#39;s a gateway to finding a soulmate. For anyone who is on the quest for love, we wholeheartedly recommend Eternal String. It&#39;s not just a platform; it&#39;s a catalyst for finding lifelong happiness and love.<\/p>",
    }
  ]; // Add your slide content here

  // carouselItems: any = []

  carouselItems: any[] = [];
  sanitizedContents: SafeHtml[] = [];
  blogId: any;

  customOptions: OwlOptions = {

    navText: ["<span class='owl-nav-arrow-left '> <i class='fas fa-arrow-left btn-danger' style='font-size:48px'></i>  </span>",
    "<span class='owl-nav-arrow-right '> <i class='fas fa-arrow-right btn-danger' style='font-size:48px;'></i> </span>"],
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoplay: false,
    navSpeed: 700,
    items: 3,
    autoWidth:true,
    // navText: ['', ''],
    responsive: {
      // 0: {
      //   items: 1
      // },
      // 400: {
      //   items: 1
      // },
      // 740: {
      //   items: 2
      // },
      // 940: {
      //   items: 3
      // }

      // 0: {
      //   items: 1
      // },
      // 400: {
      //   items: 1
      // },
      // 740: {
      //   items: 3
      // },
      // 940: {
      //   items: 3
      // }
    },
    nav: true,
   
  }


  prevOptions: OwlOptions | null = null;

  // customOptions: OwlOptions = {
  //   nav: true,
  //   navText: ["<span class='owl-nav-arrow-left '> <i class='fas fa-arrow-left btn-danger' style='font-size:48px'></i>  </span>",
  //     "<span class='owl-nav-arrow-right '> <i class='fas fa-arrow-right btn-danger' style='font-size:48px;'></i> </span>"],
  //   loop: true,
  //   autoplay: false,
  //   autoplayTimeout: 4000,
  //   autoplayHoverPause: true,
  //   items: 3, // Display two slides
  //   dots: false,
  //   slideTransition: 'linear',
  //   responsive: {
  //     0: {
  //       items: 1 // Display one slide on small screens
  //     },
  //     768: {
  //       items: 3 // Display two slides on medium screens
  //     }
  //   }


  //   // loop: true,
  //   // nav: true, // Add this line to enable arrow navigation
  //   // items: 1,
  //   // dots: false,
  //   // autoplay: true,
  //   // autoplayTimeout: 3000,
  //   // autoplayHoverPause: true

  // };

  couple: number = 0;
  title: any = '';
  story: any = '';
  coupleImg: any = '';
  coupleImg2: any = '';
  limitStory: number = 1000;
  defaultStoryLen: number = 1000;
  screenWidth: number;
  location: any;
  itemPerPage: number = 10;
  currentPage: number = 1;
  categoryId: number = 19;
  // sanitizedContents: any;

  inboxLoader: boolean = false;
  message: string = '';

  constructor(

    // private notifService: NotifService,
    // private cookieService: CookieService,
    // private router: Router,
    // private titleService: Title,
    private myProfileService: MyProfileService,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer
    // private commonService: CommonService,
    // private registerService: RegisterService,
    // private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if(`${environment.baseURL}`=='https://eternalstrings.com/'){
      this.categoryId= 23;
    }
    this.getBlogsList();
    
    this.screenWidth = window.innerWidth;
    if (window.innerWidth > 1024) {
      this.defaultStoryLen = 1300;
      this.limitStory = 3000;

    // crowsel refresh code on chaged screen
    const newOptions: OwlOptions = { ...this.customOptions, items: 3 };
    // Check if options have changed
    if (this.prevOptions !== newOptions) {
      // Perform actions if options have changed
      // console.log('Options have changed. Refreshing carousel...on > 1024');
      // Update prevOptions to newOptions
      this.prevOptions = newOptions;
      // Apply new options to customOptions
      this.customOptions = newOptions;
    } else {
      console.log('Options have not changed. No need to refresh carousel. on > 1024');
    }

    } else if (window.innerWidth > 767 && window.innerWidth <= 1024) {
      this.defaultStoryLen = 700;
      this.limitStory = 3000;

      // crowsel refresh code on chaged screen
     const newOptions: OwlOptions = { ...this.customOptions, items: 3 };
     // Check if options have changed
     if (this.prevOptions !== newOptions) {
      // Perform actions if options have changed
      console.log('Options have changed. Refreshing carousel...on > 767 && <= 1024');
      // Update prevOptions to newOptions
      this.prevOptions = newOptions;
      // Apply new options to customOptions
      this.customOptions = newOptions;
    } else {
      console.log('Options have not changed. No need to refresh carousel..on > 767 && <= 1024');
    }

    } else if (window.innerWidth <= 767) {
      this.defaultStoryLen = 500;
      this.limitStory = 3000;


      const newOptions: OwlOptions = { ...this.customOptions, items: 1 };
      // Check if options have changed
      if (this.prevOptions !== newOptions) {
       // Perform actions if options have changed
       console.log('Options have changed. Refreshing carousel...on<= 767');
       // Update prevOptions to newOptions
       this.prevOptions = newOptions;
       // Apply new options to customOptions
       this.customOptions = newOptions;
     } else {
       console.log('Options have not changed. No need to refresh carousel...on<= 767');
     }
    }
    this.limitStory = this.defaultStoryLen;
    // console.log(this.defaultStoryLen, 'defaultlen')



    // // crowsel refresh code on chaged screen
    // const newOptions: OwlOptions = { ...this.customOptions, loop: false };
    // // Check if options have changed
    // if (this.prevOptions !== newOptions) {
    //   // Perform actions if options have changed
    //   console.log('Options have changed. Refreshing carousel...');
    //   // Update prevOptions to newOptions
    //   this.prevOptions = newOptions;
    //   // Apply new options to customOptions
    //   this.customOptions = newOptions;
    // } else {
    //   console.log('Options have not changed. No need to refresh carousel.');
    // }

  }

  openStory(i: number) {
    this.couple = i;
    this.title = this.carouselItems[i].blog_title;
    this.story = this.carouselItems[i].blog_content;
    this.coupleImg = this.carouselItems[i].blog_media[0].media_path;
    //  if (this.carouselItems[i].success_story_image)
    this.coupleImg2 = this.carouselItems[i].success_story_image;
    this.location = this.carouselItems[i].location;
    this.limitStory = this.defaultStoryLen;
  }
  readMore() {
    this.limitStory = 20000;
  }
  readLess() {
    this.limitStory = this.defaultStoryLen;
  }


  getBlogsList() {
    let data = {
      itemPerPage: this.itemPerPage,
      page: this.currentPage,
      search: '',
      tag: '',
      platform_id: 1, //this.platform_id,
      category_id: '', // this.categoryId,
      id: this.blogId,
      categoryName: "Success Stories"
    };
    this.inboxLoader = true;
    this.myProfileService.getBlogsList(data).subscribe((res: any) => {
      if (res?.['status_code'] && res['status_code'] == 200) {
        this.carouselItems = res['blogs_list'];
        // console.log(this.carouselItems , res['blogs_list'])
          this.carouselItems.forEach((element) => {
            if (element.blog_content) {
              let sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(element.blog_content);
              element.sanitizedContent = sanitizedContent; // Add sanitizedContent property to element
            }
          if (element.published_date != "" && element.published_date != null) {
            let convertedDate = this.convertDateFormat(element.published_date);
            const outputDate = this.addMinutesToDateString(convertedDate);
            element['msgSent'] = outputDate;
          }
        });
      } else {
        this.toastrService.error(res['message'], 'Error!');
        this.message = res.message
        this.carouselItems = this.carouselItems1;
        this.inboxLoader = false;
      }
      this.inboxLoader = false;
    }, (err) => {
      this.inboxLoader = false;
      this.carouselItems = this.carouselItems1;
      if (err?.['message']) {

      }
    });
  }





//   getBlogsList() {
//     let data = {
//       itemPerPage: this.itemPerPage,
//       page: this.currentPage,
//       search: '',
//       tag: '',
//       platform_id: 1, //this.platform_id,
//       category_id: 19,
//     };

//     this.myProfileService.getStories(data).subscribe((res: any) => {
//       if (res?.['status'] && res['status'] == true) {
// // before sonar
//         // for (let i = 0; i < res['StoryList'].length; i++) {
//         //   if (res && res['StoryList'] && res['StoryList'][i] && res['StoryList'][i]['story']) {
//         //     var blog_content = this.removeTags(res['StoryList'][i]['story']);
//         //     res['StoryList'][i]['story'] = blog_content;
//         //   }
//         // }

// // After sonar
//         for (const element of res['StoryList']) {
//           if (res?.['StoryList'] && element?.['story']) {
//             let blog_content = this.removeTags(element['story']);
//             element['story'] = blog_content;
//           }
//         }

//         this.carouselItems = res['StoryList'];
//         // for convert in local time
//         this.carouselItems.forEach(element => {
//           if (element.published_date != "" && element.published_date != null) {
//             let convertedDate = this.convertDateFormat(element.published_date);
//             const outputDate = this.addMinutesToDateString(convertedDate);
//             element['msgSent'] = outputDate;
//           }
//         });
//       } else {
//         this.toastrService.error(res['message'], 'Error!');
//       }
//     }, (err) => {
//       if (err?.['message']) {
//         // unused code
//         //this.toastr.error(err['message'], 'Error!');
//         //  this.bloglistLoader = false;
//       }
//     });
//   }

  // removeTags(str) {
  //   if ((str === null) || (str === ''))
  //     return false;
  //   else
  //     str = str.toString();
  //   return str.replace(/(<([^>]+)>)/ig, '');
  // }

     // after sonar
     removeTags(str: string): string {
      if (str === null || str === '') {
        return '';
      }
    
      const parser = new DOMParser();
      const doc = parser.parseFromString(str, 'text/html');
    
      return doc.body.textContent || '';
    }

  convertUTCToLocal(utcTimestamp) {
    const date = new Date(utcTimestamp);
    const localDateString = date.toLocaleString();
    return localDateString;
  }

  addMinutesToDateString(dateString: string): string {
    const date = new Date(dateString);
    let newMin = date.getTimezoneOffset();
    // var newMin = 300
    date.setMinutes(date.getMinutes() - newMin);
    return date.toISOString();
  }

  convertDateFormat(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    const hours = `0${date.getUTCHours()}`.slice(-2);
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000000Z`;
  }
}
