import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerPreferenceService {
  private parrtnerprefernceUrl = `${environment.apiURL}/auth/setPartnerPreference`;
  private getPartnerDetialsUrl = `${environment.apiURL}/auth/getPartnerDetails`;
  private getPartnerDetialsUrl1 = `${environment.apiURL}/auth/sendEmailForTest`;
  pri
  constructor(
    private http: HttpClient
  ) { }

  PartnerPreference(user: any): Observable<any> {
    return this.http.post(this.parrtnerprefernceUrl, user);
  }
  getPartnerDetails(user: any): Observable<any> {
    return this.http.post(this.getPartnerDetialsUrl, user);
  }

  sendEmailForTest(user: any): Observable<any> {
    return this.http.post(this.getPartnerDetialsUrl1, user);
  }
}