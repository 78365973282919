import { Component, OnInit, ViewChild/*, ViewEncapsulation, HostListener */ } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegisterService } from '../services/register.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('resetPasswordNgForm') resetPasswordNgForm: NgForm;
  submitted = false;

  resetPasswordForm = new FormGroup({
    newPassword: new FormControl('', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{6,15}'), Validators.minLength(8)]),
    ConfirmPassword: new FormControl('', [Validators.required]),
  })
  errors: any = null;
  countries: any;
  passmatch: boolean = true;
  sBtn: boolean = false;
  id: string;
  pageValid: boolean = true;
  errmsg: string;
  errmsg1: string;
  passvalid: boolean;
  showPassword: boolean = false;
  showPassword1: boolean = false;
  errorMessage: string;
  err: boolean;
  errormsg: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private registerService: RegisterService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);

    let url = window.location.href
    let urlArray = url.split('reset-password/')
    let data = urlArray[urlArray.length - 1]
    let dataValues = data.split(/([]+)/)
    this.id = dataValues[0];
    // // console.log(this.id,"urlid")

    this.checkvalid();

  }

  matchPassword(event: any) {
    // // console.log(event.target.value,"sagar")
    if (this.resetPasswordForm.value.newPassword) {
      let np = (this.resetPasswordForm.value.newPassword);
      let cp = (this.resetPasswordForm.value.ConfirmPassword);

      if (np == cp) {
        this.passmatch = true;
        this.sBtn = true;
        return true;
      } else {
        this.passmatch = false;
        this.sBtn = false;
        return false;
      }
    }
  }
  showHidePassword1() {
    this.showPassword1 = !this.showPassword1;
  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  checkvalid() {
    let data = {
      id: this.id,
    }
    this.authService.getIsUserVaild(data)
      .subscribe((result: any) => {
        if (result.status) {
          this.pageValid = true;
        } else {
          // // console.log(result.active_url)
          this.pageValid = false;
          this.errmsg = "you are not valid user";
        }
      });
  }

  validatePassword(event: any) {
    if (event.target.value.length >= 8) {
       //before sonarqube
      // let regex = new RegExp('(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}');
       //after sonarqube
       let regex = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}/;
      let ch1 = regex.test(event.target.value);
        //before sonarqube
      // if (ch1 == true) {
        if (ch1) {
        this.passvalid = true;
        this.errormsg = ""
        this.matchPassword(ch1)
      } else {
        this.passvalid = false;
        this.err = false;
        this.errormsg = "Password must have a minimum of 8 characters including at least one Capital, one number, and one special character"
      }
    }
    else {
      this.errormsg = "Password must have a minimum of 8 characters including at least one Capital, one number, and one special character"
      this.passvalid = false;
    }
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }

  resetPasswordDone() {
    // before sonar
    // if (this.passvalid == true) {
    //   if (this.passmatch == true) {
    //     if (this.sBtn == true) {
          if (this.passvalid) {
            if (this.passmatch) {
              if (this.sBtn) {

          this.submitted = true;
          let data = {
            id: this.id,
            password: this.resetPasswordForm.value.newPassword
          }
          this.authService.resetPassword(data)
            .subscribe((res: any) => {
              if (res.status) {
                this.errmsg = "Password Changed Successfully"
                this.errmsg1 = "";
                this.toastrService.success(res.message);
                this.resetPasswordForm.reset();
                window.location.href = `${environment.baseURL}`;
              } else {
                this.errmsg = "";
                this.errmsg1 = "Password Not Changed";

              }
            });
        }

      } else {
        this.submitted = false;
      }
    } else {
      this.submitted = false;
      this.resetPasswordForm.reset();
    }
  }
}
