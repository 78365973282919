<section class="my-5 mx-auto mx-width-900 min-screen-height">

  <div class="row mt-136">
    <div class="col-12 my-3">
      <h2 class="text-center">
        <a *ngIf="!frompackages" href="javascript:void(0);" (click)="backLocation()">
          <div class="btn btn-danger w-35-h-35_rm mx-2" ngbTooltip="Back">Back
          </div>
        </a>

        <a *ngIf="!!frompackages" href="javascript:void(0);" (click)="backLocation2()">
          <div class="btn btn-danger w-35-h-35_rm mx-2" ngbTooltip="Back">Back
          </div>
        </a>
        Upgrade Now To Find Your Special Someone
      </h2>

      <h4 class=" mt-2 text-center myDIV" *ngIf="CurrentPlanDetail" style="width: fit-content;margin-left: 36%;">Current
        package :
        <span class="text-danger ">{{CurrentPlanDetail.package_name}} </span>

      </h4>
    </div>
  </div>

  <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 px-3 g-4 justify-content-center" style="display: none;">
    <div class="col">
      <div class="card h-100 border border-1 shadpw-sm rounded-10 card-hover">
        <div class="card-body text-center">
          <h4 class="card-title fw-bold mb-4 text-danger">Free
          </h4>
          <button class="btn btn-danger rounded-pill px-4 my-3 py-2 fw-bold fs-5" (click)="selectPackages(1)">
            Continue</button>
          <div class="mx-auto text-start lh-lg mt-3">

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"> </span>
              <span>Create a Profile </span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"> </span>
              <span>Add Contact Details
              </span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"></span>
              <span>Add Partner Preference</span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"> </span>
              <span>Upload upto 5 photos in
                the Photo Gallery </span>
            </p>

          </div>

        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100 border border-1 shadpw-sm rounded-10 card-hover">
        <div class="card-body text-center">
          <h4 class="card-title fw-bold mb-4 text-danger">Premium
          </h4>
          <button class="btn btn-danger rounded-pill px-4 my-3 py-2 fw-bold fs-5" (click)="selectPackages(2)">
            Continue </button>
          <div class="mx-auto text-start lh-lg mt-3">

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"> </span>
              <span>Create a Profile </span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"> </span>
              <span>Add Contact Details
              </span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"></span>
              <span>Add Partner Preference</span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"> </span>
              <span>Upload upto 10 photos in
                the Photo Gallery </span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"> </span>
              <span>View Profile </span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"> </span>
              <span>International Search</span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"></span>
              <span>Chat</span>
            </p>

          </div>

        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100 border border-1 shadpw-sm rounded-10 card-hover">
        <div class="card-body text-center">
          <h4 class="card-title fw-bold mb-4 text-danger">Elite
          </h4>
          <button class="btn btn-danger rounded-pill px-4 my-3 py-2 fw-bold fs-5" (click)="selectPackages(3)">
            Continue </button>

          <div class="mx-auto text-start lh-lg mt-3">

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"> </span>
              <span>Create a Profile </span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"> </span>
              <span>Add Contact Details
              </span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"></span>
              <span>Add Partner Preference</span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"> </span>
              <span>Upload upto 20 photos in
                the Photo Gallery </span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"> </span>
              <span>View Profile </span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"> </span>
              <span>International Search</span>
            </p>

            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"></span>
              <span>Chat</span>
            </p>
            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"></span>
              <span>Video Chat (Coming Soon)</span>
            </p>
            <p class="mb-2 lh-base d-flex p-sm font-weight-600 align-items-center"><span class="min-width-30"></span>
              <span>Dedicated expert from
                Eternal Strings team for bespoke services</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 px-3 g-4 justify-content-center">
    <ng-container *ngFor="let package of packages; let i= index">
      <div class="col" *ngIf="(package?.id>=packageid ||packageid==4 )">
        <div class="card h-100 border border-1 shadpw-sm rounded-10 card-hover">
          <div class="card-body text-center">
            <h4 class="card-title fw-bold mb-4 text-danger">{{package?.package_name}}
            </h4>
            <button class="btn btn-danger rounded-pill px-4 my-3 py-2 fw-bold fs-5"
              (click)="selectPackages(package?.id)">
              Continue </button>

            <div [innerHTML]="package?.features" class="mx-auto text-start lh-lg mt-3">

            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</section>
<button style="display:none;" id="openModal" data-bs-target="#captionBox" data-bs-toggle="modal"></button>
<div class="w-100 bg-maroon">
  <app-footer></app-footer>
</div>

<div class="modal fade" id="captionBox" tabindex="-1" aria-labelledby="captionModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">

        <div href="javascript:void(0)" routerLink="/registerDone">
          <a href="javascript:void(0)" routerLink="/registerDone" class="text-decoration-none" style="color:black">

            <h4 class="mt-2"> Sign Up for free</h4>
            <h5 class=" ">Start finding your life partner</h5>

          </a>
        </div>
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-danger me-2" id="confirmModalLabel"
            data-bs-dismiss="modal">Cancel</button>
          <button type="submit" class="btn btn-light border border-1 border-danger me-2"
            data-bs-dismiss="modal">Add</button>
        </div>
      </div>

    </div>
  </div>
</div>