import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limit'
})
export class LimitPipe implements PipeTransform {

  constructor() {}
   transform(value: string='', stringLimit: number=0): any {
     //console.log('test',stringLimit,'---',value);
     if(value && value!=null){
      return value.length > stringLimit ? value.slice(0, stringLimit - 1) + "…" : value; 
     }else{
      value = '-';
      return value;
     }
     
   }

}
