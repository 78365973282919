import { Component, OnInit } from '@angular/core';
import { MyProfileService } from '../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute/*, RouterLink */ } from '@angular/router';
import { CommonService } from '../services/common.service';
import { PartnerPreferenceService } from '../services/partner-preference.service';
import { AuthService } from '../shared/auth.service';


@Component({
  selector: 'app-biodata',
  templateUrl: './biodata.component.html',
  styleUrls: ['./biodata.component.css']
})
export class BiodataComponent implements OnInit {
  myProfileDetail: any;
  myPhotos: any = 'assets/images/banner-4.jpg';
  myProfileGallery: any = [];
  profilePhoto: any = 'assets/images/men-avtar-2.svg';
  age: any;
  user_id: any;
  displayName: any;
  showTime: any = [];
  detailLoader: boolean = false;
  pageId: any;
  partnerDetail: any;

  printBtn: HTMLElement = document.getElementById('print');
  printLogo: boolean = false;

  showFamilyType: any;
  showResidency: any;
  showNatureOfWork: any;
  showProfession: any;
  showEducation: any;
  showReligion: any;
  showLanguage: any;
  showFood: any;
  showMarital: any;
  partnerLocationCountryName: string;
  partnerCurrencyCode: number;
  partnerLocationStateName: string;
  countries: any = [];
  countries5: any = [];
  countries1: any = [];
  partnerLocationStates: any;
  partnerFamilyStateName: string;
  partnerFamilyStates: any;
  partnerFamilyCountryName: any;
  partnerFamilyPhoneCode: number;
  croppedImage: any;



  constructor(
    private myProfileService: MyProfileService,
    private cookieService: CookieService,
    private toastrService: ToastrService,
    private CommonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private partnerPreferenceService: PartnerPreferenceService,
    private authService: AuthService,




  ) { }

  ngOnInit(): void {
    this.user_id = this.cookieService.get('user_id');
    let pageId = this.route.snapshot.params['id'];
    // console.log(this.route.snapshot.routeConfig.path.split('/')[0])
    this.getCountryList();
    let dd = this.route.snapshot.routeConfig.path.split('/')
    console.log(dd)
    if (pageId.toString().length > 10) {
      pageId = pageId.toString().replaceAll('Ar1t', '=').replaceAll('FGH', '/').replaceAll('THD', '+');
      this.pageId = this.CommonService.decryptData(pageId)
    } else {
      this.pageId = pageId;
    }
    this.userProfilePreview()
    this.partnerProfilePreview()
  }

  getCountryList() {
    let countries = this.CommonService.getCountries();
    if (this.countries.length > 0) {
      let country = countries;
      this.countries5 = countries;
      this.countries1 = countries;
      this.countries = countries;
      this.countries1.push({
        "fullnumber": "Any",
        "id": 0,
        "country_code": "",
        "country_name": "Any",
        "short_name": "ANY",
        "currency": "",
        "currency_name": ""
      })
      this.countries = country.filter(item => item.id !== 0);
    } else {
      this.CommonService.getCountryList({}).subscribe((result) => {
        let country = result.data;
        this.countries5 = result.data;
        this.countries1 = result.data;
        this.countries1.push({
          "fullnumber": "Any",
          "id": 0,
          "country_code": "",
          "country_name": "Any",
          "short_name": "ANY",
          "currency": "",
          "currency_name": ""
        })

        this.countries = country.filter(item => item.id !== 0);
      }, (error: { error: any; }) => {
        // console.log("error in get country")
      });
    }
  }

  partnerProfilePreview() {
    let data = {
      user_id: this.user_id,
      // detail_of: 'Self'
    }
    this.partnerPreferenceService.getPartnerDetails(data)
      .subscribe((res: any) => {
        //before sonarqube
        // if (res && res.userDetails) {
        if (res?.userDetails) {
          this.partnerDetail = res.userDetails
          let re = /,/gi;
          this.showLanguage = this.partnerDetail.languages.replace(re, ', ');
          this.showFood = this.partnerDetail.food_preference.replace(re, ', ');
          this.showReligion = this.partnerDetail.religion.replace(re, ', ');
          this.showMarital = this.partnerDetail.marital_status.replace(re, ', ');
          this.showProfession = this.partnerDetail.profession_area.replace(re, ', ');
          this.showEducation = this.partnerDetail.education.replace(re, ', ');
          let str = this.partnerDetail.nature_of_work.toString();
          this.showNatureOfWork = str.replace(re, ', ');

          //// console.log("sagar", this.partnerDetail)

          if (this.partnerDetail.country_name != '' || this.partnerDetail.country_name != undefined) {

            if (this.partnerDetail.country_name == 0 || this.partnerDetail.country_name == "Any") {
              this.partnerLocationCountryName = "Any";
              this.partnerCurrencyCode = 0;
              this.partnerLocationStateName = 'Any';
              if (this.partnerDetail.state_name != '') {
                // document why this block is empty
              }
            } else if (this.countries1 != '' && this.countries1 != undefined) {
              this.countries1.forEach(
                (country: { id: any; country_name: any }) => {
                  if (this.partnerDetail.country_name == country.id) {
                    this.partnerLocationCountryName = country.country_name;
                    this.partnerCurrencyCode = country.id;
                  } else if (this.partnerDetail.country_name == "Any") {
                    console.log(this.partnerDetail.country_name)
                    this.partnerLocationCountryName = country.country_name;
                    this.partnerCurrencyCode = country.id;
                  }
                }
              );
              //// console.log(this.partnerLocationCountryName, "after Fun")
              if (this.partnerDetail.state_name == 'Any') {
                this.partnerLocationStateName = 'Any';
                this.authService
                  .getStateList({ id: this.partnerDetail.country_name })
                  .subscribe((result) => {
                    this.partnerLocationStates = result.data;
                  });
              } else {
                this.authService
                  .getStateList({ id: this.partnerDetail.country_name })
                  .subscribe((result) => {
                    this.partnerLocationStates = result.data;
                    this.partnerLocationStates.forEach((state) => {
                      if (this.partnerDetail.state_name == state.id) {
                        this.partnerLocationStateName = state.state_name;
                      }
                    });
                  });
              }
            }
          } else {
            //  // console.log("helo 0...11 else part")
            if (this.countries != '' && this.countries != undefined) {
              this.countries.forEach(
                (country: { id: any; country_name: any }) => {

                  if (this.partnerDetail.country_name == country.id) {
                    this.partnerLocationCountryName = country.country_name;
                    this.partnerCurrencyCode = country.id;
                  } else if (this.partnerDetail.country_name == "Any") {
                    console.log(this.partnerDetail.country_name,"Any")
                    this.partnerLocationCountryName = country.country_name;
                    this.partnerCurrencyCode = country.id;
                    //   // console.log(this.partnerLocationCountryName ,"after Fun 122")
                  }
                }
              );
            }
            // else part Any
            // if(this.partnerDetail.country_name == 0 ||this.partnerDetail.country_name == "Any" ) {
            //      this.partnerLocationCountryName = "Any";
            //         this.partnerCurrencyCode = 0;
            //         if (this.partnerDetail.state_name != '') {
            //           this.partnerLocationF.state_name.setValue(this.partnerDetail.state_name);
            //             this.partnerLocationStateName = 'Any';
            //         }
            //           // // console.log(this.partnerLocationCountryName ,"after Fun 011")
            // }
            // // // console.log( this.partnerLocationStateName, this.partnerLocationCountryName,"undef")
          }

          if (this.partnerDetail.family_living_in_country != '' && this.partnerDetail.family_living_in_country != null) {
            this.partnerFamilyPhoneCode = parseInt(this.partnerDetail.family_living_in_country);

            if (this.countries1) {
              this.countries.forEach((country) => {
                if (this.partnerDetail.family_living_in_country == country.id) {
                  this.partnerFamilyCountryName = country.country_name;
                }
              });
              if (this.partnerDetail.family_state_name == 'Any') {
                this.partnerFamilyStateName = 'Any';
              } else {
                this.authService
                  .getStateList({
                    id: this.partnerDetail.family_living_in_country,
                  })
                  .subscribe((result) => {
                    this.partnerFamilyStates = result.data;
                    this.partnerFamilyStates.forEach((state) => {
                      if (this.partnerDetail.family_state_name == state.id) {
                        this.partnerFamilyStateName = state.state_name;
                      }
                    });
                  });
              }
            }

            // // // console.log( this.partnerFamilyCountryName,this.partnerFamilyStateName,"kalu")
          } else {
            this.partnerFamilyPhoneCode = null
          }

          if (this.partnerDetail.family_state_name == 'Any') {
            this.partnerFamilyStateName = 'Any';
          } else {
            this.authService
              .getStateList({
                id: this.partnerDetail.family_living_in_country,
              })
              .subscribe((result) => {
                this.partnerFamilyStates = result.data;
                this.partnerFamilyStates.forEach((state) => {
                  if (this.partnerDetail.family_state_name == state.id) {
                    this.partnerFamilyStateName = state.state_name;
                  }
                });

              });
          }
        }
      })
  }

  userProfilePreview() {
    this.detailLoader = true;
    let data = {
      profile_id: this.user_id,
      // detail_of: 'Self'
    }

    this.myProfileService.userProfilePreview(data).subscribe((res) => {
      //before sonarqube
      // if (res.status == true) {
      if (res.status) {
        this.myProfileDetail = res['userDetails'][0];
        if (this.myProfileDetail.dob && this.myProfileDetail.dob != '') {
          const date = moment(this.myProfileDetail.dob, 'YYYY-MM-DD');
          const years = moment().diff(date, 'years');
          this.age = years;
        }
        if (this.myProfileDetail?.profile_photo && this.myProfileDetail.profile_photo_approved == 1) {
          this.croppedImage = this.myProfileDetail.profile_photo;
        } else {
          this.croppedImage = "assets/images/default-profile-picture.svg";
        }

        this.displayName = this.myProfileDetail.first_name[0].toUpperCase() + " " + this.myProfileDetail.last_name;

        if (this.myProfileDetail.profile_photo && this.myProfileDetail.profile_photo_approved == 1) {
          this.profilePhoto = this.myProfileDetail.profile_photo;
        }
        if (this.myProfileDetail.cover_photo && this.myProfileDetail.cover_photo_approved == 1) {
          this.myPhotos = this.myProfileDetail.cover_photo;
        }
        //unused code
        // this.detailLoader = false;
        // if (
        //   this.myProfileDetail.convenient_time_to_call != '' &&
        //   this.myProfileDetail.convenient_time_to_call != null
        // ) {
        //   // time to call
        //   if (this.myProfileDetail.convenient_time_to_call == "Any-Time") {
        //     this.showTime = [];
        //     this.showTime.push("Any Time");
        //   } else {
        //     this.showTime = [];
        //     var std = this.myProfileDetail.convenient_time_to_call.split(',');
        //     std.forEach((element: any) => {
        //       //  // console.log(element," sagar ", )
        //       // this.showTime.push(element);
        //       if (element != '') {
        //         var daytime = element.split(' - ');
        //         daytime.forEach((element: any) => {
        //           if (element != '') {
        //             var s1 = daytime[0] + " " + daytime[1] + " " + daytime[2] + " " + daytime[3]
        //             if (element == 'Sunday') {
        //               this.showTime.push(s1);
        //             } else if (element == 'Monday') {
        //               this.showTime.push(s1);
        //             } else if (element == 'Tuesday') {
        //               this.showTime.push(s1);
        //             } else if (element == 'Wednesday') {
        //               this.showTime.push(s1);
        //             } else if (element == 'Thursday') {
        //               this.showTime.push(s1);
        //             } else if (element == 'Friday') {
        //               this.showTime.push(s1);
        //             } else if (element == 'Saturday') {
        //               this.showTime.push(s1);
        //             } else if (element == 'Anytime') {
        //               this.showTime = [];
        //               this.showTime.push("Any Time");
        //             } else {
        //               // // // console.log(element,"jay bhai time")
        //             }
        //           }
        //           this.detailLoader = false;
        //         });
        //       }
        //     });
        //   }
        // }
        //  // console.log(this.showTime ,"sagar ")
        this.detailLoader = false;
      } else {
        this.toastrService.warning('Some thing went wrong!!');
        this.detailLoader = false;
      }
    }, (error) => {
      this.toastrService.error('Api error!!');
      this.detailLoader = false;
    });
  }

  printPage() {
    // console.log("document.body hello ")
    this.p1();
  }
  p2() {
    // console.log("op2")

    let divContents = document.getElementById("printDiv11").innerHTML;
    let divContentsTwo = document.getElementById("printDiv12").innerHTML;

    let logoContents = document.getElementById("logoDiv").innerHTML;
    console.log(logoContents)
    //unused code
    // var a = window.open('', '', 'height=500, width=500');
    // a.document.write('<html>');
    // a.document.write('<body >  <br>');
    // a.document.write(divContents);
    // a.document.write('</body></html>');
    // a.document.close();
    // a.print();
    let b = window
    b.document.write('<html>');
    b.document.write('<body > ');
    // b.document.write(logoContents)
    b.document.write(divContents);
    b.document.write(divContentsTwo);
    b.document.write('</body></html>');
    b.print();
    //unused code
    // window.location.reload();
  }

  p1() {
    // console.log("document.body hello 0")

    let printContents = document.getElementById('printDiv').innerHTML;
    // console.log("document.body hello 1")

    // console.log("document.body hello 2")

    document.body.innerHTML = printContents;
    // console.log("document.body hello 3")

    window.print();
    // console.log("document.body hello 4")

    // // console.log("document.body hello ", originalContents)
    // console.log("document hello ", printContents)

  }


  testMail() {
    let data = {
      user_id: this.user_id,
      // detail_of: 'Self'
    }
    this.partnerPreferenceService.sendEmailForTest(data)
      .subscribe((res: any) => {

        // console.log("sagar", this.partnerDetail)
      })
  }

}

