import { Injectable, Inject, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class VideoService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
  ) { }

  // public promptForVideo(mediaFile): Promise<File> {
  //   return new Promise<File>((resolve, reject) => {
  //     // make file input element in memory
  //   //   const fileInput: HTMLInputElement = this.document.createElement('input');
  //     const fileInput = mediaFile
  //     fileInput.type = 'file';
  //     fileInput.accept = 'video/*';
  //     fileInput.setAttribute('capture', 'camera');
  //     // fileInput['capture'] = 'camera';
  //     fileInput.addEventListener('error', event => {
  //       reject(event.error);
  //     });
  //   //   fileInput.addEventListener('change', event => {
  //   //     resolve(fileInput.files[0]);
  //   //   });
  //     // prompt for video file
  //     fileInput.click();
  //   });
  // }

  // Old funtion for genrating preview(D)
  // public generateThumbnail(videoFile: Blob): Promise<string> {
  //   const video: HTMLVideoElement = this.document.createElement('video');
  //   const canvas: HTMLCanvasElement = this.document.createElement('canvas');
  //   const context: CanvasRenderingContext2D = canvas.getContext('2d');
  //   return new Promise<string>((resolve, reject) => {
  //     canvas.addEventListener('error',  reject);
  //     video.addEventListener('error',  reject);
  //     video.addEventListener('canplay', event => {
  //       canvas.width = video.videoWidth;
  //       canvas.height = video.videoHeight;
  //       video.currentTime = 3;
  //       context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  //       resolve(canvas.toDataURL());
  //     });
  //     if (videoFile.type) {
  //       video.setAttribute('type', videoFile.type);
  //     }
  //     video.preload = 'auto';
  //     video.src = window.URL.createObjectURL(videoFile);
  //     video.load();
  //   });
  // }

  // New funtion ofr genrating the preview(D)
  public generateThumbnail(file, seekTo = 0.0) {
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', (ex) => {
        reject("error when loading video file");
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject("video is too short.");
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari



        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            videoPlayer.currentTime = seekTo;
          }, 200);
        })
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener('seeked', () => {
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL());
        });
      });
    });
  }

}



//calling the video service for making thumbnail(D)
// this.videoService.promptForVideo(mediaFile).then(videoFile => {
// 	return this.videoService.generateThumbnail(videoFile);
//   }).then(thumbnailData => {
// 	this.imgURL.push({ url: thumbnailData, size: mediasize });
//   })