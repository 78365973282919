<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fall" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>


<!-- <app-header *ngIf="!isLoggedIn"></app-header> -->
<div class="bg-maroon w-100" *ngIf="isLoggedIn && currentRoute != '/Home' && !isAdminRoute && routeInitialized">
  <app-login-header (onDatePicked)="doSomething($event)"></app-login-header>
</div>
<router-outlet></router-outlet>

<!-- <app-footer *ngIf="!isLoggedIn"></app-footer>
<app-login-footer *ngIf="isLoggedIn"></app-login-footer> -->
