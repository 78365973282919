import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild, Renderer2, NgZone } from '@angular/core';
import { Title, Meta, SafeHtml } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../shared/auth.service';
import { MyProfileService } from '../services/my-profile.service';
import { NotifService } from '../services/notif.service';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm, } from '@angular/forms';
import { ViewportScroller } from '@angular/common';
import { interval } from 'rxjs/internal/observable/interval';
import { CommonService } from '../services/common.service';
import { EmojiService } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { ReplaceEmojisPipe } from '../pipes/emoji.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { UtcConverterService } from '../services/utc-converter.service';
import { UtcToLocalTimePipe } from '../pipes/dateTime.pipe';
import { Socket } from 'ngx-socket-io';
import { SocketService } from '../services/socket.service';   // stop because of repelDry code
// import { setInterval } from 'timers';
import { map } from 'rxjs/operators';
import * as $ from 'jquery';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoService } from '../services/video.service';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { LimitPipe } from '../pipes/limit.pipe';
// import { debounceTime } from 'rxjs';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  providers: [ReplaceEmojisPipe, UtcConverterService]
})
export class ChatComponent implements OnInit {
  // @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef;
  @ViewChild('scrollMe') myScrollContainer: any;
  @ViewChild('wordCountForm') wordCountNgForm: NgForm;
  @ViewChild("texts") texts: ElementRef;
  wordCountForm = new FormGroup({
    user_id: new FormControl(''),
    description: new FormControl('', [Validators.required]),
  })
  wordCount: any;
  words: any;
  tellUsMore: any;
  exceedLimit: boolean;
  caption: string = '';

  vide = "https://demoprojects.itsabacus.net/jub_we_met_back_end/public/chat-media/video%20(2160p)-20230714102753.mp4"
  msgReset = "";
  user_id: any;
  chatUserList: any = [];
  activeUserId: any = 0;
  windowInnerWidth: any = window.innerWidth;
  activeUser: any;
  user_chatting: any = {};
  // user_chatting: any = [];
  chatWatcher: any;
  subscribeInterval: any;
  userDetails: any;
  searchUser: string = '';
  activeUserIndex: any = -1;
  userListRes: boolean = false;
  chatListRes: boolean = false;
  closeResult: string = '';
  myChats: any = [];
  forwardTo: any = [];
  ChatId: number = 0
  unRead: boolean = true;
  searchUserForwardTo: string;
  chatUserListForwardTo: any = [];
  messageOf: any;
  searchChatWord: any = '';
  searched_chatting: any = [];
  action: any = '';
  chatFilter: any = '';
  currentPage: any = 1;
  showMoreUserChatting: boolean = false;
  nextPage: any = 1;
  allowDragover: boolean;
  totalMediaLength: number = 0;
  totalMediaSize: number = 0;
  file_upload_loader: boolean;
  imgURL: any = [];	//For storing the image URL(D)
  videoURL: any = []; 	//For storing the video URL(D)
  // Files Upload variables(a)
  fileUpload: any;
  captionUpload: any
  fileUploadLoader: boolean = false;
  // End Files Upload variables(a)

  fileUploadCount: any;
  videoPlayButton: number;
  pasteEvnetStatus: boolean = true;

  //send message data variable(n)
  sendData: any;
  sendMessageButton: any;
  textLimitMessage: any;
  isSentMessage: boolean = false;

  chatType: number;
  clicked: boolean;
  msgData: string = "";
  messageId: any = {};

  //Post Text With media upload variable(a)
  activeSlideIndex: number = 0;
  post_media_slider: any = [];
  // videoPlayButton: any;
  chatMedia: any;
  currentChatKey: any;
  copyLinkType: any
  copyText: string;
  chatTextifLink: any;
  chatWithSingleLink: any;
  openChatMedia: any = "";

  //Meta Deta Url Variable (D)
  messageUrlArr: any;
  countUrlMeta: any;
  postTextWithLink: any;
  postUrlButtonHide: any;
  postButtonHide: any;

  urlTitle: any;
  urlImage: any;
  urlDescription: any;
  urlLink: any;
  iframe: any;
  urlMetaDataArr: any;
  //chatType: number;
  // metaUrlLinkString = new Subject<string>();
  forwordMessageTemp: any;
  //Meta Deta Url Variable (D)
  urlMetaDataSearch: boolean = false;
  noChatOpen: boolean;

  // emoji 
  showGifBox: boolean = false;
  showStickerBox: boolean = false;
  showMoreGif: boolean = true;
  showMoreSticker: boolean = true;
  showEmojiPicker: any = false;

  // customEmojis = customEmojisAll;
  sheet: any = 'apple';
  size = 24;
  sheetSize = 64;
  controls: any;
  sheetId: any = 100;
  message: any;
  increase: number = 500;
  connection_id: any;
  callUpdateMsg: boolean = false;
  display: string = 'none';
  bgColor: string = '#000000';
  photo_visibility: boolean = false;
  selectedCaptionIndex: number = 0;
  SkeltonLoader(i: number) {
    return new Array(i);
  }

  sendMessageForChatForm = new FormGroup({
    attachment: new FormControl(''),
    message: new FormControl(''),
  });

  message1 = '';
  //showEmojiPicker = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set = 'apple';
  currentDate: any;
  otherReason: boolean = false;
  reportReason: string = ""
  reportReasonsList: any = [
    { id: 1, label: "Harassment or Abuse" },
    { id: 2, label: "Unwanted Attention" },
    { id: 3, label: "Spam" },
    { id: 4, label: "Scam or Fraud" },
    { id: 5, label: "Disrespectful Behavior" },
    { id: 6, label: "Privacy Concerns" },
    { id: 7, label: "Fake or Suspicious Profiles" },
    { id: 8, label: "Abusive or Threatening Behavior" },
    { id: 9, label: "Explicit Content" },
    { id: 10, label: "Violation of Platform Rules" },
    { id: 11, label: "Other" }

  ];
  sender: number;
  constructor(
    private titleService: Title,
    private myProfileService: MyProfileService,
    private authService: AuthService,
    private http: HttpClient,
    private notifService: NotifService,
    private toastrService: ToastrService,
    private cookieService: CookieService,
    private scroller: ViewportScroller,
    private commonService: CommonService,
    private modalService: NgbModal,
    private emojiService: EmojiService,
    private renderer: Renderer2,
    private emojPipe: ReplaceEmojisPipe,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
    private datePipe: DatePipe,
    private VideoService: VideoService,
    private spinner: NgxSpinnerService,
    // private limitPipe: LimitPipe,
    private socket: SocketService
    // private dateTimepipe :  UtcToLocalTimePipe 

  ) {
    this.msgContent = this.sanitizer.bypassSecurityTrustHtml('');
  }

  ngOnInit(): void {
    this.titleService.setTitle('Chat | eternalstrings.com');
    this.user_id = parseInt(this.cookieService.get('user_id'));
    this.commonService.currentApprovalStageChat.subscribe(status => {
      this.activeUserId = status;
    });
    this.getUserDetails();
    this.getChatUserList();
    this.socket.getRequestSocket().subscribe(status => {
      if (status.id == this.user_id) {
        if (status.msg == 'Send') {
          this.getChatUserList();
        } else if (status.msg == 'Update') {
          this.getChatUserDetails();
          let index = this.chatUserList.findIndex(obj => obj.id === this.sender);
          if (this.chatUserList[0].id == this.activeUserId) {
            this.chatUserList[index]['unread_message'] = 0;
            this.chatUserList[this.activeUserIndex]['unread_message'] = 0;

          }
        }
        if (status.sender == this.activeUserId) {
          this.sender = status.sender;
        } else {
          this.sender = status.sender;
        }
      } else {
      }
    });

    this.fileUpload = [];
    this.captionUpload = [];
    this.fileUploadLoader = false;
    this.videoPlayButton = 0;
    this.file_upload_loader = false;
    this.fileUploadCount = 0;
    this.sendData = {};
    this.sendMessageButton = false;
    this.textLimitMessage = '';
    this.sendData.message = '';

    var dd = new Date();
    this.currentDate = this.datePipe.transform(dd, 'MM/dd/yyyy')
  }


  ngOnDestroy() {
    if (this.subscribeInterval) {
      this.subscribeInterval.unsubscribe();
    }
  }
  getUserDetails() {
    var data = {
      user_id: this.user_id,
    };
    this.commonService.getUserDetails(data).subscribe(
      (result: any) => {
        if (result.userDetails) {
          this.userDetails = result.userDetails;
        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        // this.toastrService.error();
      }
    );
  }
  filterChat() {
    this.chatUserList = [];
    if (this.unRead) {
      this.chatFilter = 'unread';
      this.unRead = false;
    } else {
      this.chatFilter = '';
      this.unRead = true;
    }
    this.getChatUserList();
  }
  convertToLocalTime(dateString: string, timezone: string): string {
    const date = new Date(dateString);
    const localTime = this.datePipe.transform(date, 'medium', '+0000', timezone);
    return localTime;
  }


  convertToAMPM(utcTimeString) {
    const utcDate = new Date(utcTimeString);

    // Get hours, minutes, and seconds
    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();
    const seconds = utcDate.getUTCSeconds();

    // Convert to AM/PM format
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

    // Add leading zeros to minutes and seconds
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    // Construct the final formatted time string
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

    return formattedTime;
  }




  getChatUserList() {
    var data = {
      user_id: this.user_id,
      search: this.searchUser,
      filter: this.chatFilter
    };
    this.userListRes = false;
    this.myProfileService.getChatUserList(data).subscribe(
      (result: any) => {
        this.userListRes = true;
        if (result.status) {
          this.chatUserList = [];
          this.chatUserList = result.chatUserList;
          this.chatUserListForwardTo = this.chatUserList;
          if (this.chatUserList.length > 0 && this.activeUserId >= 0) { // what is the work of Active user id sir
            let userFound = false;
            this.chatUserList.forEach(element => {
              if (element && element.last_chat && element.last_chat.updated_at != "" && element.last_chat.updated_at != null) {
                var convertedDate = this.convertDateFormat(element.last_chat.updated_at);
                var Tdate = new Date()
                var eDate = Tdate.toISOString()
                const startDate = new Date(convertedDate); // result date 
                const endDate = new Date(eDate); /// todays date 
                const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
                const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
                const seconds = Math.floor(timeDiff / 1000);
                const minutes = Math.floor(seconds / 60);
                const hours = Math.floor(minutes / 60);
                var fd1 = this.datePipe.transform(startDate, 'medium');
                var fd2 = this.datePipe.transform(endDate, 'medium');
                if (daysDiff < 8) {
                  var msgDate = '' //element['UTC'] = convertedTime;
                  var Gtime = this.addMinutesToDateString(element.last_chat.updated_at);
                  const formattedTime = this.convertToAMPM(Gtime);
                  element['msgTime'] = formattedTime
                  if (daysDiff == 1) {
                    const forstart = this.datePipe.transform(convertedDate, 'dd');
                    const forend = this.datePipe.transform(eDate, 'dd');
                    var checkD = parseInt(forend) - parseInt(forstart)
                    if (checkD == 0) {
                      msgDate = "Today"
                      element['msgDate'] = msgDate
                    } else {
                      msgDate = "Yesterday"
                      element['msgDate'] = msgDate
                    }
                  } else if (daysDiff == 2) {
                    msgDate = "Yesterday"
                    element['msgDate'] = msgDate

                  } if (daysDiff > 2) {
                    const dayOfWeek = startDate.getDay();
                    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                    msgDate = days[dayOfWeek];
                    element['msgDate'] = msgDate
                  }
                } else {
                  const forDate = this.datePipe.transform(convertedDate, 'dd/MM/yyyy');
                  element['msgDate'] = forDate;
                }
              }
              if (this.activeUserId == element.user_id) {
                this.activeUser = element;
                userFound = true;
                if (element.unread_message > 0) {
                }
                if (this.sender == this.activeUserId) {
                  this.getChatUserDetails();
                }
              }
            });
            if (!userFound) {
              this.activeUserId = 0
            }
          } else if (this.chatUserList.length > 0) {
            this.activeSlideIndex = this.search_array(this.chatUserList, this.activeUserId);
            this.openMyChat(this.activeUserId, this.activeSlideIndex);
          }
        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        this.userListRes = true;
      }
    );
  }
  search_array(array, user_id) {
    for (var i = 0; i < array.length; i++) {
      if (array[i]['user_id'] === user_id) {
        return i;
      }
    }
    return -1;
  }

  searchChatUserList() {
    if (this.searchUser != '') {
      this.getChatUserList();
    }
  }

  removeSearch() {
    this.searchUser = '';
    this.getChatUserList();
  }

  searchChatUsersOnKeyPress(event: KeyboardEvent) {
    var keypressed = event.keyCode;
    if (keypressed || this.searchUser == '') {
      this.getChatUserList();
    } else if (keypressed == 8 && this.searchUser == '') {
      this.getChatUserList();
    }
  }
  searchChatUsers() {
    this.getChatUserList();
  }
  goBack() {
    this.activeUserId = 0;
  }

  openChatFromList(user_id, index, user) {
    this.connection_id = user.connection_id;
    if (this.activeUserId != user_id) {
      this.openMyChat(user_id, index)
    } 
  }
  openMyChat(user_id, index) {
    if (this.activeUserId == 0) {
      this.activeUserId = user_id;
      this.activeUserIndex = index;
      this.activeUser = this.chatUserList[index];
      this.user_chatting[this.activeUserId] = [];
      this.currentPage = 1;
      this.showMoreUserChatting = false;
      this.getChatUserDetails();
    } else if (this.activeUserId > 0 && this.chatListRes) {
      this.activeUserId = user_id;
      this.activeUserIndex = index;
      this.activeUser = this.chatUserList[index];
      this.user_chatting[this.activeUserId] = [];
      this.currentPage = 1;
      this.showMoreUserChatting = false;
      this.getChatUserDetails();
    } else {
      this.toastrService.warning('Please wait till chat loaded!')
    }
  }
  onKeypressEvent(event: any) {
    if (event.charCode == 13) {
    }
  }

  sendMessageForChat() {
    var dd = {
      id: this.activeUserId,
      msg: "Send",
      sender: this.user_id
    }

    this.showEmojiPicker = false;
    if (this.sendMessageForChatForm.value.message != '' && this.sendMessageForChatForm.value.message != null && this.sendMessageForChatForm.value.message != '\n' || this.sendData.message != "") {
      var data = {
        user_from: this.user_id,
        user_to: this.activeUserId,
        message: this.sendData.message,
        type: 0,
        connection_id: this.connection_id
      };
      this.myProfileService.sendMessageForChat(data).subscribe(
        (result: any) => {
          if (result.status) {
            this.socket.add_chat_messages(dd);
            this.sendMessageForChatForm.reset();
            this.sendData.message = '';
            this.sendData = {};
            this.msgContent = ""
            this.clearField();
            this.isSentMessage = true;
            this.getChatUserDetails();
            setTimeout(() => {
              this.getChatUserList();
            }, 500);
          } else {
            this.toastrService.error(result.message);
          }
        },
        (error) => {
        }
      );
    } 
  }

  onScrollMe(event) {
    var total = this.myScrollContainer.nativeElement.scrollHeight - this.myScrollContainer.nativeElement.offsetHeight - 1;
    if (event.target.scrollTop > total) {
     
    }
  }

  scrollToBottom(haveReadMessage): void {
    try {
      this.myScrollContainer.nativeElement.scrollTo({ left: 0, top: this.myScrollContainer.nativeElement.scrollHeight, behavior: 'smooth' });
      if (haveReadMessage) {
        this.updateMessageStatus();
      }
    } catch (err) {
    }
  }
  updateMessageStatus() {
    var data = {
      user_from: this.user_id,
      user_to: this.activeUserId
    };
    if (this.callUpdateMsg == false) {
      this.callUpdateMsg = true
      if (this.activeUserId == this.activeUser.user_id) {
        this.myProfileService.updateMessageStatus(data).subscribe(
          (result: any) => {
            if (result.status) {
              this.updateChatUserList();
              this.chatUserList[this.activeUserIndex]['unread_message'] = 0;
              var dd = {
                id: this.activeUserId,
                msg: "Update",
                sender: this.user_id
              }
              this.socket.add_chat_messages(dd);
              setTimeout(() => {
                this.callUpdateMsg = false
              }, 3000);
            } 
          },
          (error) => {
          }
        );
      } 
    }
  }

  openSearchChatModal(content: any) {
    this.searchChatWord = ''
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  removeSearchChatMessages() {
    this.searchChatWord = '';
    this.searchChat();
  }

  searchChatMessages() {
    if (this.searchChatWord != '') {
      this.searchChat();
    }
  }

  searchChat() {
    if (this.searchChatWord != '' && this.searchChatWord.length > 1) {
      var data = {
        user_from: this.user_id,
        user_to: this.activeUserId,
        search: this.searchChatWord
      };
      this.myProfileService.searchChat(data).subscribe(
        (result: any) => {
          if (result.status) {
            this.searched_chatting = result.user_chatting;
            this.searched_chatting.forEach(element => {
              if (element.created_at != "" && element.created_at != null) {
                var convertedDate = this.convertDateFormat(element.created_at);
                const outputDate = this.addMinutesToDateString(convertedDate);
                element['msgSent'] = outputDate;
              }
              if (element.created_at != "" && element.created_at != null) {
                var date1 = this.convertDateFormat(element.created_at);
                const inputDate = this.addMinutesToDateString(date1);
                element['msgRecived'] = inputDate;
              }
              if (element.mime_type) {
                element["videoUrl"] = encodeURIComponent(element.message)
                if (element.mime_type != "") {
                }
              }
            });
          } else {
            this.toastrService.error(result.message);
          }
        },
        (error) => {
         this.toastrService.error();
        }
      );
    }
  }

  removeChatUsersForwardTo() {
    this.searchUserForwardTo = '';
    this.searchChatUsersForwardTo();
  }

  searchChatUsersForwardTo() {
    var data = {
      user_id: this.user_id,
      search: this.searchUserForwardTo
    };
    this.myProfileService.getChatUsersForwardTo(data).subscribe(
      (result: any) => {
        if (result.status) {
          this.chatUserListForwardTo = result.chatUserList;
        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
      }
    );
  }
  confirmBlockReport(action: any) {
    this.action = action;
  }
  blockReportNow() {
    var data = {
      user_id: this.user_id,
      profile_id: this.activeUserId,
      action: this.action.replaceAll(' ', '_'),
    };
    this.myProfileService.doActionOnRequests(data).subscribe(
      (result: any) => {
        if (result.status) {
          this.toastrService.success(result.message);
          this.getChatUserList();
        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        this.ChatId = 0;
      }
    );
  }



  clearMessages(message_of: any = '') {
    this.messageOf = message_of;
  }

  clearMessagesNow() {
    if (this.user_chatting[this.activeUserId].length > 0) {
      var data = {
        user_from: this.user_id,
        user_to: this.activeUserId,
      };
      this.myProfileService.clearMessagesNow(data).subscribe(
        (result: any) => {
          this.ChatId = 0;
          this.user_chatting[this.activeUserId] = [];
          if (result.status) {
            this.toastrService.success(result.message);
            this.getChatUserList()
            // this.getChatUserDetails();
          } else {
            this.toastrService.error(result.message);
          }
          // this.toastrService.success('Successfully!', 'Astro Details Update!');
        },
        (error) => {
          this.ChatId = 0;
          // this.toastrService.error();
        }
      );
    } else {
      this.toastrService.warning('No chat present to be clear')
    }
  }

  deleteMessage(chat_id: number, message_of: any = '') {
    this.ChatId = chat_id;
    this.messageOf = message_of;
    //  console.log(this.ChatId, this.messageOf, "sagar")
  }

  deleteMessageNow(from) {
    if (from == 'everyone') {
      this.messageOf = from;
      var dd = {
        id: this.activeUserId,
        msg: "Update",
        sender: this.user_id
      };
      this.socket.add_chat_messages(dd);
    }
    if (this.ChatId > 0) {
      var data = {
        chat_id: this.ChatId,
        delete_for: from,
        type: this.messageOf
      };
      this.myProfileService.deleteMessageNow(data).subscribe(
        (result: any) => {
          this.ChatId = 0;
          // this.user_chatting = [];
          if (result.status) {
            this.toastrService.success(result.message);
            this.user_chatting[this.activeUserId] = this.user_chatting[this.activeUserId].filter(obj => obj.id !== this.ChatId);
            this.getChatUserList();
            console.log(this.ChatId, 'this.user_chatting[this.activeUserId]', this.user_chatting[this.activeUserId])
            // this.user_chatting[this.activeUserId] = [];
            // this.getChatUserDetails();
          } else {
            this.toastrService.error(result.message);
          }
          // this.toastrService.success('Successfully!', 'Astro Details Update!');
        },
        (error) => {
          this.ChatId = 0;
          // this.toastrService.error();
        }
      );
    } else {
      this.toastrService.warning('Message to be deleted not selected')
    }
  }

  forwardMessage(chat_id: any, content: any) {
    this.searchUserForwardTo = '';
    this.myChats = [];
    this.forwardTo = [];
    this.searchChatUsersForwardTo();

    //  console.log('forward_message_modal')
    // $('#forward_message_modal').modal('show');
    this.ChatId = chat_id;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  forwardMessageNow() {
    if (this.forwardTo.length > 0) {
      var data = {
        user_from: this.user_id,
        forward_to: this.forwardTo,
        forward_chat_id: this.ChatId,
        message: '',
        // type: 'text',
      };
      this.myProfileService.forwardMessageNow(data).subscribe(
        (result: any) => {
          this.ChatId = 0;
          if (result.status) {
            this.getChatUserList();
            // this.getChatUserDetails();
            this.toastrService.success(result.message);
            this.forwardTo = [];
            this.myChats = [];
          } else {
            this.toastrService.error(result.message);
          }
          // this.toastrService.success('Successfully!', 'Astro Details Update!');
        },
        (error) => {
          this.ChatId = 0;
          // this.toastrService.error();
        }
      );
    } else {
      this.toastrService.warning('Please check user to forward message')
    }
  }

  CompareId(event, i, user_to) {
    var Sid = parseInt(event.target.id)
    if (this.forwardTo.indexOf(user_to) > -1 && !this.myChats[i]) {
      this.forwardTo.splice(this.forwardTo.indexOf(user_to), 1);
    } else if (this.forwardTo.indexOf(user_to) == -1 && this.myChats[i]) {
      this.forwardTo.push(user_to);
    }
    //  console.log('forwardTo', this.forwardTo)

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  showMoreUserChattingFun(nextPage) {
    this.currentPage = nextPage;
    this.getChatUserDetails();
  }

  convertDateFormat(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    const hours = `0${date.getUTCHours()}`.slice(-2);
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000000Z`;
  }

  convertUTCToLocal(utcTimestamp) {
    const date = new Date(utcTimestamp);
    const localDateString = date.toLocaleString();
    return localDateString;
  }


  getGMTOffset(timezone: string): string {
    const now = new Date();
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      timeZoneName: 'short'
    });

    const parts = formatter.formatToParts(now);
    const offsetPart = parts.find(part => part.type === 'timeZoneName');
    if (offsetPart) {

      let offset = offsetPart.value;
      // var offset = '';
      if (timezone.includes("America")) {
        if (offset == "ET" || offset == "EST") {
          offset = "GMT-5:00"
        } else if (offset == "EDT") {
          offset = "GMT-4:00"
        } else if (offset == "CT" || offset == "CST") {
          offset = "GMT-6:00"
        } else if (offset == "CDT") {
          offset = "GMT-5:00"
        } else if (offset == "MT" || offset == "MST") {
          offset = "GMT-7:00"
        } else if (offset == "MDT") {
          offset = "GMT-6:00"
        } else if (offset == "PT" || offset == "PST") {
          offset = "GMT-8:00"
        } else if (offset == "PDT") {
          offset = "GMT-7:00"
        }
      } else {
        offset = offsetPart.value;
      }
      return offset;
    }
    return '';
  }

  checkGMT(inputString: string): any {
    if (inputString.includes('+')) {
      return "GMT+";
    } else if (inputString.includes('-')) {
      return "GMT-";
    } else {
      return 3;
    }
  }

  convertTimeZoneToMinutes(offset: string): number {
    const parts = offset.split(':').map(part => part.trim());
    var partStr = JSON.stringify(parts);
    const hours = parseInt(parts[0].replace(this.checkGMT(partStr), ''), 10);
    const minutes = parseInt(parts[1], 10);
    var totalMinutes = (hours * 60) + minutes;
    if (this.checkGMT(partStr) == "GMT-") {
      totalMinutes = totalMinutes * -1
    }
    //  console.log(parts, "parts of offset", typeof (partStr), "hours", hours, "minutes", minutes, "totalMinutes", totalMinutes, "GMT type", this.checkGMT(partStr), offset)
    return totalMinutes;
  }



  convertToGMT(timeString) {
    // Convert the given time string to a JavaScript Date object
    const date = new Date(timeString);
    // Get the current time in UTC
    const utcTime = date.getTime() + (date.getTimezoneOffset() * 60000);
    //  console.log(utcTime, "timenn")
    // Calculate the time in the target timezone (GMT +5:30)
    var timeZone = localStorage.getItem("Time-Zone")
    const gmtOffset = this.getGMTOffset(timeZone);
    const minutes = this.convertTimeZoneToMinutes(gmtOffset);
    const targetTimezoneOffset = minutes * 60000; // 5 hours 30 minutes = 330 minutes
    const targetTime = new Date(utcTime + targetTimezoneOffset);
    // Format the target time as a string

    const formattedTime = targetTime.toLocaleString();
    return formattedTime;
  }
  addMinutesToDateString(dateString: string): string {
    const date = new Date(dateString);
    var newMin = date.getTimezoneOffset();
    // var newMin = 300
    date.setMinutes(date.getMinutes() - newMin);
    //  console.log(minutes, "minutes ", newMin, "newMin", dateString, "dateString", date.toISOString(), "date.toISOString()");
    //  console.log(date.toISOString())
    return date.toISOString();
  }
  // Example usage
  // const timeString = "Jun 19, 2023, 6:05:41 AM";
  // const convertedTime = convertToGMT(timeString);
  //
  // convertToUrl() {
  //   this.convertedUrl = encodeURIComponent(this.stringToConvert);
  // }

  // readMore(id) {
  //   //  console.log(id, "have id of chat ", this.messageId, "before...............", this.messageId[id])
  //   if (this.messageId.hasOwnProperty(id)) {
  //     this.messageId[id].limit = this.messageId[id].limit + 50;
  //     //  console.log('Object has key1', this.messageId[id]);

  //     // this.mess = mess 
  //   } else {
  //     //  console.log('Object does not have key1');
  //   }
  //   //  console.log(id, "have id of chat ", this.messageId, "..after ............", this.messageId[id])
  // }

  readMore(id, i) {
    //  console.log('this.user_chatting[this.activeUserId][id]', this.user_chatting[this.activeUserId][i])
    if (this.messageId.hasOwnProperty(id)) {
      this.messageId[id].limit = this.messageId[id].limit + 50;
      this.user_chatting[this.activeUserId][i].message = this.user_chatting[this.activeUserId][i].splitmsg.slice(0, this.messageId[id].limit).join(' ');
    }
  }
  readLess(id, i) {
    if (this.messageId.hasOwnProperty(id)) {
      this.messageId[id].limit = 50;
      this.user_chatting[this.activeUserId][i].message = this.user_chatting[this.activeUserId][i].splitmsg.slice(0, this.messageId[id].limit).join(' ');
      // this.user_chatting[this.activeUserId][i].message = this.user_chatting[this.activeUserId][i].splitmsg.slice(0,120).join(' ');
    }
  }
  getChatUserDetails() {
    var data = {
      user_from: this.user_id,
      user_to: this.activeUserId,
      page: this.currentPage
    };
    this.chatListRes = false;
    this.searched_chatting = [];
    this.myProfileService.getChatUserDetails(data).subscribe(
      (result: any) => {
        if (this.isSentMessage) {
          this.user_chatting[this.activeUserId] = [];
          this.isSentMessage = false;
        }
        this.chatListRes = true;
        var photo_status = result.photo_visibilty[0].photo_visiblity;
        //  console.log(result.photo_visibilty[0], "sagar")
        if (photo_status == 'Visible to all members' || photo_status == 'Visible to connected members') {
          this.photo_visibility = true
        } else if (photo_status == 'No one') {
          this.photo_visibility = false
        }
        //  console.log("hello ", result,)
        // this.user_chatting = [];
        if (result.status) {
          this.currentPage = 1;
          // console.log("time calls this time before", this.user_chatting[this.activeUserId])

          // this.getChatUserDetails();
          this.user_chatting[this.activeUserId] = [];
          let oldChat = result.user_chatting.reverse();
          this.user_chatting[this.activeUserId] = oldChat.concat(this.user_chatting[this.activeUserId]);
          //  console.log('oldChat', this.user_chatting[this.activeUserId], "before reduced filter")
          // code for time conversion
          // console.log("time calls this time", this.user_chatting[this.activeUserId])
          // delete dublicate new 
          var a = this.user_chatting[this.activeUserId];
          var b = a.reduce(function (p, c) {
            var existingIndex = p.findIndex(function (item) {
              return item.id === c.id;
            });
            if (existingIndex === -1) {
              p.push(c);
            } else {
              if (c.message_status > p[existingIndex].message_status) {
                p[existingIndex] = c;
              }
            }
            return p;
          }, []);
          this.user_chatting[this.activeUserId] = b;
          // delete dublicate new 
          // console.log("time calls this time after", this.user_chatting[this.activeUserId])

          //  console.log('oldChat', this.user_chatting[this.activeUserId], "after reduced filter")

          let haveReadMessage = false;
          this.user_chatting[this.activeUserId].forEach(element => {

            //  console.log(this.countWords(element.message))
            if (element.mime_type == "" && (this.countWords(element.message) > 50)) {
              if (this.messageId.hasOwnProperty(element.id)) {
                //  console.log('Object has key1');
              } else {
                this.messageId[element.id] = {
                  msg: element.message,
                  limit: 50,
                  encryption: {}
                }
                this.encryptEmoji(element.id, element.message, 10);
                let encrypted_msg = this.messageId[element.id].msg;
                //  console.log('encryption:: ',this.messageId[element.id]['encryption']);
                //  console.log('encrypted_msg:: ',encrypted_msg);
                let splitmsg = encrypted_msg.split(/\s+/);
                if (splitmsg.length > 49) {

                  encrypted_msg = splitmsg.slice(0, 50).join(' ');
                  element.splitmsg = splitmsg;
                  for (var key in this.messageId[element.id]['encryption']) {
                    //  console.log('object loop')
                    encrypted_msg = encrypted_msg.replace(key, this.messageId[element.id]['encryption'][key]);
                  }
                  //  console.log('encrypted_msg after:: ',encrypted_msg);
                  element.message = encrypted_msg;
                  // if(element.message.indexOf('ACS') >= 0) {
                  //   element.message = element.message.replace('ACS',slice_emooji);
                  // }
                }
              }
            }
            // if()

            if (element.created_at != "" && element.created_at != null) {
              var convertedDate = this.convertDateFormat(element.created_at);
              const outputDate = this.addMinutesToDateString(convertedDate);
              //  console.log(outputDate, "outdate", element.id,)
              //  console.log(minutes, "minutes ", outputDate, convertedDate, "dateString", element.id);
              //  console.log(convertedDate, typeof (convertedDate), "|convertdate and minutes |", minutes, typeof (minutes), "valluuess  ", element.updated_at)
              element['msgSent'] = outputDate;
            }
            if (element.created_at != "" && element.created_at != null) {
              // var localTime = this.convertUTCToLocal(convertedDate);
              var date1 = this.convertDateFormat(element.created_at);
              const inputDate = this.addMinutesToDateString(date1);
              //  console.log(minutes, "minutes ", inputDate, date1, "dateString", element.id);

              element['msgRecived'] = inputDate;
            }
            // && element.mime_type == "video/mp4" apply when element type is comming video 
            if (element.mime_type) {
              //  console.log(element.message, "url", encodeURIComponent(element.message))
              if (element.mime_type == "") {
                this.searched_chatting.push(element)
              }
              element["videoUrl"] = encodeURIComponent(element.message)
            }
            if (element.message_status != 3) {
              haveReadMessage = true;
            }

          });


          // end code for time conversion
          // this.user_chatting .push(oldChat)
          // this.searched_chatting = this.user_chatting[this.activeUserId];  // search object 
          // this.currentPage = result.nextPage;
          this.showMoreUserChatting = result.showMore;
          this.nextPage = result.nextPage
          setTimeout(() => {
            this.scrollToBottom(haveReadMessage);
          }, 400);
          // if (haveReadMessage) {
          //   //  console.log("send message have unreadmssage  ")

          // } else {
          //   this.scrollToBottom();
          //   //  console.log("send message have no unread message ")
          // }
          // if (this.user_chatting[this.activeUserId].length > 0 && this.myScrollContainer.nativeElement.offsetHeight == this.myScrollContainer.nativeElement.scrollHeight) {
          //   this.updateMessageStatus()
          // }
        } else {
          this.toastrService.error(result.message);
        }
        // this.toastrService.success('Successfully!', 'Astro Details Update!');
      },
      (error) => {
        this.chatListRes = true;
        // this.toastrService.error();
      }
    );
    //  console.log(this.messageId, "Hello message ")
  }

  encryptEmoji(id, message, i) {
    //  console.log('encryptEmoji callingg times: ' + i)
    if (message.indexOf('<span') >= 0) {
      let start = message.indexOf('<span');
      let end = message.indexOf('span>');
      var slice_emooji = message.slice(start, end + 5);
      var randstr = 'ES-' + Math.floor(Math.random() * 9) + '-' + i;
      i++;
      message = message.replace(slice_emooji, randstr);
      this.messageId[id].encryption[randstr] = slice_emooji;
      this.messageId[id].msg = message;
      //  console.log('encryptEmoji message', message)
      this.encryptEmoji(id, message, i);
    }
    return message;
  }

  countWords(inputText: string): number {
    const words = inputText.split(/\s+/).filter(word => word !== '');
    return words.length;
  }

  //Calling the change event whent the media is selected in the local file (n) edit(D)
  fileChangeEvent(event: any) {
    //  console.log(event ,"saksham")
    var file_array = [];
    this.allowDragover = false;

    if (event && event.target && event.target.files) {
      file_array = event.target.files;
      this.totalMediaLength += file_array.length;
      // this.toggleEmojiPicker();
      if (this.showEmojiPicker) {
        this.showEmojiPicker = false
        this.showGifBox = false;
        this.showStickerBox = false;
      }
      // this.showGifBox = false;
      // this.showStickerBox = false;
      // this.showEmojiPicker = !this.showEmojiPicker;
      //  console.log(event ,"saksham 0.25",this.totalMediaLength , file_array.length)

      if (this.totalMediaLength <= 10) {
        //   $('#openmodal').click();
        var x = 0;
        //  console.log(event ,"saksham11")

        while (x < file_array.length) {

          var fileDetails = file_array[x];

          this.totalMediaSize += fileDetails.size;
          //  console.log("saksham 12.1", fileDetails, x, file_array.length, fileDetails.size)
          if (fileDetails.size <= 5942880) { // file uploading less then 5 mb 

            if (fileDetails.type == 'image/png' || fileDetails.type == 'image/jpeg' || fileDetails.type == 'image/tiff' || fileDetails.type == 'image/webp' || fileDetails.type == 'image/gif' || fileDetails.type == 'image/x-icon' || fileDetails.type == 'image/bmp') {
              //  console.log("saksham 12.12" ,fileDetails, x, file_array.length)

              this.file_upload_loader = true;
              var $this_func = this;
              //  console.log("saksham 12.13" ,fileDetails, x, file_array.length)

              var getCustomerSyncFlag = function (callback) {
                //  console.log("saksham 12.14" ,fileDetails, x, file_array.length)

                $this_func.setupReader(fileDetails, x, file_array.length);
                var SyncFlag = x + 1;
                callback(null, SyncFlag);
                //  console.log("saksham 12.1111" ,fileDetails, x, file_array.length)

              }

              getCustomerSyncFlag(function (err, SyncFlag) {
                x = SyncFlag;
              });
              //  console.log(event ,"saksham12")

            } else if (fileDetails.type == 'video/mp4' || fileDetails.type == 'video/webm' || fileDetails.type == 'video/ogg' || fileDetails.type == 'video/quicktime') {

              this.file_upload_loader = true;
              var $this_func = this;
              var getCustomerSyncFlag = function (callback) {
                $this_func.setupReader(fileDetails, x, file_array.length);
                var SyncFlag = x + 1;
                callback(null, SyncFlag);
              }

              getCustomerSyncFlag(function (err, SyncFlag) {
                x = SyncFlag;
              });

            } else if (fileDetails.type == 'audio/ogg' || fileDetails.type == 'audio/mpeg' || fileDetails.type == 'audio/vnd.dlna.adts'
            ) {
              this.file_upload_loader = true;
              var $this_func = this;
              var getCustomerSyncFlag = function (callback) {
                if (fileDetails.type.includes("audio/")) {
                  switch (fileDetails.type) {
                    case 'audio/ogg':
                      $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/file_icons/ogg.png', size: fileDetails.size, type: 'image', name: fileDetails.name });
                      break;

                    case 'audio/mpeg':
                      $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/file_icons/mp3.png', size: fileDetails.size, type: 'image', name: fileDetails.name });
                      break;

                    case 'audio/vnd.dlna.adts':
                      $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/file_icons/aac.png', size: fileDetails.size, type: 'image', name: fileDetails.name });
                      break;

                    default:
                      $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/format/audio.webp', size: fileDetails.size, type: 'image', name: fileDetails.name });
                      break;
                  }
                }
                $this_func.fileUpload.push(fileDetails);
                $this_func.fileUploadCount = $this_func.fileUpload.length;
                var SyncFlag = x + 1;
                callback(null, SyncFlag);
              }

              getCustomerSyncFlag(function (err, SyncFlag) {
                x = SyncFlag;
                if (x == file_array.length) {
                  $this_func.file_upload_loader = false;
                }
              });
              //  console.log(event ,"saksham 13")

            } else if (


              fileDetails.type == 'application/vnd.ms-excel' ||
              fileDetails.type == 'application/msword' ||
              fileDetails.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
              fileDetails.type == 'application/vnd.oasis.opendocument.spreadsheet' ||
              fileDetails.type == 'application/vnd.oasis.opendocument.spreadsheet' ||
              fileDetails.type == 'application/pdf' ||
              fileDetails.type == 'application/vnd.ms-powerpointapplication/vnd.ms-powerpoint' ||
              fileDetails.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
              fileDetails.type == 'application/vnd.ms-word.document.macroEnabled.12' ||
              fileDetails.type == 'text/plain' ||
              fileDetails.type == 'application/vnd.ms-powerpoint') {
              this.file_upload_loader = true;
              var $this_func = this;
              var getCustomerSyncFlag = function (callback) {
                //  console.log(event ,"saksham 14")

                switch (fileDetails.type) {
                  case 'application/vnd.ms-excel':
                    $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/file_icons/csv.png', size: fileDetails.size, type: 'image', name: fileDetails.name });
                    //  console.log(event ,event.target.value,"saksham 14.1" ,fileDetails)

                    break;

                  case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/file_icons/excel.png', size: fileDetails.size, type: 'image', name: fileDetails.name });
                    //  console.log(event ,event.target.value,"saksham 14.2" ,fileDetails)
                    break;

                  case 'application/vnd.oasis.opendocument.spreadsheet':
                    $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/file_icons/excel.png', size: fileDetails.size, type: 'image', name: fileDetails.name });
                    //  console.log(event ,event.target.value,"saksham 14.3" ,fileDetails)
                    break;

                  case 'application/pdf':
                    $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/file_icons/pdf.png', size: fileDetails.size, type: 'image', name: fileDetails.name });
                    //  console.log(event ,event.target.value,"saksham 14.4" ,fileDetails)
                    break;

                  case 'application/vnd.ms-powerpointapplication/vnd.ms-powerpoint':
                    $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/file_icons/ppt.png', size: fileDetails.size, type: 'image', name: fileDetails.name });
                    //  console.log(event ,event.target.value,"saksham 14.5" ,fileDetails)
                    break;

                  case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/file_icons/word.png', size: fileDetails.size, type: 'image', name: fileDetails.name });
                    //  console.log(event ,event.target.value,"saksham 14.6" ,fileDetails ,$this_func.imgURL)
                    break;

                  case 'application/msword':
                    $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/file_icons/word.png', size: fileDetails.size, type: 'image', name: fileDetails.name });
                    //  console.log(event ,event.target.value,"saksham 14.7" ,fileDetails,$this_func.imgURL)
                    break;

                  case 'application/vnd.ms-word.document.macroEnabled.12':
                    $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/file_icons/word.png', size: fileDetails.size, type: 'image', name: fileDetails.name });
                    //  console.log(event ,event.target.value,"saksham 14.8" ,fileDetails,$this_func.imgURL)
                    break;

                  case 'text/plain':
                    $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/file_icons/txt.png', size: fileDetails.size, type: 'image', name: fileDetails.name });
                    //  console.log(event ,event.target.value,"saksham 14.9" ,fileDetails,$this_func.imgURL)
                    break;

                  case 'application/vnd.ms-powerpoint':
                    $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/file_icons/ppt.png', size: fileDetails.size, type: 'image', name: fileDetails.name });
                    //  console.log(event ,event.target.value,"saksham 14.10" ,fileDetails)
                    break;

                  default:
                    $this_func.imgURL.push({ url: 'https://demoprojects.itsabacus.net/2757_JubWeMet/assets/images/file_icons/file.png', size: fileDetails.size, type: 'image', name: fileDetails.name });
                    //  console.log(event ,event.target.value,"saksham 14.11" ,fileDetails)
                    break;
                }
                $this_func.fileUpload.push(fileDetails);
                $this_func.fileUploadCount = $this_func.fileUpload.length;
                var SyncFlag = x + 1;
                callback(null, SyncFlag);
              }

              getCustomerSyncFlag(function (err, SyncFlag) {
                x = SyncFlag;
                if (x == file_array.length) {
                  $this_func.file_upload_loader = false;
                }
              });
              //  console.log(event.target, event.target.value, "saksham 14.122", fileDetails)
              // this.chatMediaUpload();

            }
            else {
              //  console.log(event ,"saksham 15")

              this.totalMediaSize -= fileDetails.size;
              this.totalMediaLength = this.totalMediaLength - 1;
              if (x + 1 == file_array.length) {
                this.file_upload_loader = false;
              }
              if (fileDetails.type.includes("image/")) {
                this.toastrService.error("Images can't be uploaded. Only jpg, jpeg, png, webp, ico, bmp and gif files are allowed!", 'Error!');
              } else if (fileDetails.type.includes("video/")) {
                this.toastrService.error("Video can't be uploaded. Only MP4, Mov webm and ogg files are allowed!", 'Error!');
              } else if (fileDetails.type.includes("audio/")) {
                this.toastrService.error("Audio can't be uploaded. Only mp3, aac and ogg files are allowed!", 'Error!');
              } else if (fileDetails.type.includes("application/") || fileDetails.type.includes("text/")) {
                this.toastrService.error("File can't be uploaded. Only CSV,XLS,XLSX,DOC,DOCX,DOCM,PDF,TXT,PPT files are allowed!", 'Error!');
              } else if (fileDetails.type == '' || fileDetails.type == null) {
                this.toastrService.error("File can't supported", 'Error!');
              } else {
                this.toastrService.error("File can't be uploaded. Only Image, Audio, Video and Doc files are allowed!", 'Error!');
              }
              x++;
            }
          } else {
            //  console.log(event ,"saksham 16")

            this.totalMediaSize -= fileDetails.size;
            this.toastrService.error("File can't be uploaded. Total File size should be smaller than 5 MB.", 'Error!');
            if (x + 1 == file_array.length) {
              this.file_upload_loader = false;
            }
            x++;
          }
        }
      } else {
        //  console.log(event ,"saksham 16")

        this.totalMediaLength -= file_array.length;
        this.toastrService.error("Unable to upload more than 10 files at a time.", 'Error!');
      }
    } else {
      this.toastrService.error("File can't be uploaded. Only Image, Audio, Video and Doc files are allowed!", 'Error!');
    }
  };
  //End Calling the change event whent the media is selected in the local file (n) edid (D)


  // Onclick the remove tage remove the current image(D)
  RemoveImage(data, key) {
    if (this.selectedCaptionIndex > key) {
      this.selectedCaptionIndex = this.selectedCaptionIndex - 1;
      this.removeImageCaption(data, key);
    } else if (this.selectedCaptionIndex < key) {
      this.removeImageCaption(data, key);
    }
    else if (key == this.selectedCaptionIndex) {
      if (key === 0 && this.totalMediaLength > 1) {
        //  console.log(this.totalMediaLength, "shift right", key ,this.captionUpload)
        // this.selectedCaptionIndex = key + 1;
        this.removeImageCaption(data, key);
        if (this.hasIndex(this.captionUpload, this.selectedCaptionIndex)) {
          var val = this.getValueAtIndex(this.captionUpload, this.selectedCaptionIndex)
          this.f.description.setValue(val);
          //  console.log(this.totalMediaLength, "shift right", key, this.captionUpload, val)
        } else {
          this.setValIndex('');
          this.f.description.setValue('');
        }
      } else if (key === 0 && this.totalMediaLength == 1) {
        //  console.log(this.totalMediaLength, "no shift ", key)
        this.removeImageCaption(data, key);
        this.setValIndex('');
        this.f.description.setValue('');
      }
      else if (key === this.totalMediaLength - 1 || key < this.totalMediaLength) {
        //  console.log(this.totalMediaLength, "shift left", key)
        this.selectedCaptionIndex = key - 1;
        if (this.hasIndex(this.captionUpload, this.selectedCaptionIndex)) {
          var val = this.getValueAtIndex(this.captionUpload, this.selectedCaptionIndex)
          this.f.description.setValue(val);
        } else {
          this.setValIndex('');
          this.f.description.setValue('');
        }
        this.removeImageCaption(data, key);
      }
    }
  }
  //End  Onclick the remove tage remove the current image(D)

  removeImageCaption(data, key) {

    this.totalMediaSize = (+this.totalMediaSize) - (+data.size);
    this.totalMediaLength = this.totalMediaLength - 1;
    this.pasteEvnetStatus = true;
    this.imgURL.splice(key, 1);
    this.fileUpload.splice(key, 1);
    this.captionUpload.splice(key, 1);
    this.file_upload_loader = false;
    this.allowDragover = false;
  }
  //Setup Reader method for converting img into base64 for preview(D)
  setupReader(file, num, file_length) {
    try {
      var fileReader = new FileReader();
      //reader.readAsDataURL(details);
      var $this = this;
      if (file.type.match('image')) {
        //  console.log(file, num, file_length ,"saksham 22.1")

        if (file.size < '31457280') {
          //  console.log(file, num, file_length ,"saksham 22.2")

          fileReader.onload = function () {
            //  console.log(file, num, file_length ,"saksham 22.25")

            $this.imgURL.push({ url: fileReader.result, size: file.size, type: 'image', name: file.name });

            $this.fileUpload.push(file);
            $this.fileUploadCount = $this.fileUpload.length;
            ///  console.log($this.fileUpload ,"file - Upload" , $this.imgURL)
            if (num + 1 == file_length) {
              $this.file_upload_loader = false;
            }
            // var img = document.createElement('img');
            // img.src = fileReader.result;
            // document.getElementsByTagName('div')[0].appendChild(img);
            $this.pasteEvnetStatus = true;
          };
          fileReader.readAsDataURL(file);
        } else {
          $this.imgURL.push({ url: '/assets/images/image_preview.jpg', size: file.size, type: 'image', name: file.name });
          $this.fileUpload.push(file);
          $this.fileUploadCount = $this.fileUpload.length;

          if (num + 1 == file_length) {
            $this.file_upload_loader = false;
          }
          $this.pasteEvnetStatus = true;
        }
      } else {
        try {
          this.VideoService.generateThumbnail(file).then(thumbnailData => {

            if (thumbnailData && thumbnailData != 'data:,') {
              this.imgURL.push({ url: thumbnailData, size: file.size, type: 'image', name: file.name });
            } else {
              this.imgURL.push({ url: '/assets/images/video_preview.png', size: file.size, type: 'image', name: file.name });
            }
            this.fileUpload.push(file);
            this.fileUploadCount = this.fileUpload.length;

            if (num + 1 == file_length) {
              this.file_upload_loader = false;
            }

          }, (err) => {

            this.imgURL.push({ url: '/assets/images/video_preview.png', size: file.size, type: 'image', name: file.name });
            this.fileUpload.push(file);
            this.fileUploadCount = this.fileUpload.length;

            if (num + 1 == file_length) {
              this.file_upload_loader = false;
            }

          })
        } catch (error) {
          this.imgURL.push({ url: '/assets/images/video_preview.png', size: file.size, type: 'image', name: file.name });
          this.fileUpload.push(file);
          this.fileUploadCount = this.fileUpload.length;

          if (num + 1 == file_length) {
            this.file_upload_loader = false;
          }
        }
      }
    } catch (error) {
      this.imgURL.push({ url: '/assets/images/no_preview.jpg', size: file.size, type: 'image', name: file.name });
      this.fileUpload.push(file);
      this.fileUploadCount = this.fileUpload.length;

      if (num + 1 == file_length) {
        this.file_upload_loader = false;
      }
      this.pasteEvnetStatus = true;
    }
  }
  //End Setup Reader method for converting img into base64 for preview(D) 

  //Add show url details variables(n)
  urlifyPostOnlyLink(event: any) {
    if (event.isTrusted) { 		//This method is checking if event is inputType or event is only text(D)
      event.target.value = event.target.value.replace(/^\s+/g, "");
      event.target.value = event.target.value.replace(/\<br>/g, '\r\n');
      var text = event.target.value;
      this.sendData.message = text;
    } else {
      event = event.replace(/^\s+/g, "");
      var text = event;
      this.sendData.message = text;
    }		//End This method is checking if event is inputType or event is only text(D)

    // var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-zA-Z0-9_%\-\!\.~]+)*(\/([a-zA-Z0-9_%\-\!\.]*)(\?[a-zA-Z0-9+_\-\!\/\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=!-_~:@/?]*)?)(\s+|$)/gi;
    var includeRegex = /(([a-z]+:\/\/)?(([a-z0-9/:@\-\,\'\`]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|watch))(:[0-9]{1,5})?(\/[a-zA-Z0-9/:@.-_%\-\!\,\.~\'\`]+)*(\/([a-zA-Z0-9_%\-\!\,\.\'\`]*)(\?[a-zA-Z0-9+_\-\!\,\.%=&amp;:]*)?)?(#[a-zA-Z0-9!$&'()*+.=!-_~:@/?]*)?)(\s+|$)/g;
    var excludeRegex = /.*@.*/;


    this.chatTextifLink = text.replace(includeRegex, function (url) {
      if (excludeRegex.test(url)) {
        return url;
      } else {
        return '<a class="truncate_post_url truncate_anchor" target=" _blank" href="' + url + '">' + url + '</a>'
      }
    });
    this.chatTextifLink = this.chatTextifLink.replace(/^\s+/g, "");

  }

  //Url show only for post
  urlifyPost(event: any, callback) {
    // event.target.value = event.target.value.replace(/^\s+/g, "");
    // event.target.value = event.target.value.replace(/\<br>/g, '\r\n');
    // var text = event.target.value;
    // this.addPostArr.postText = data;

    event = event.replace(/^\s+/g, "");
    // event = event.replace(/\<br>/g, '\r\n');
    event = event.replace(/<(?!\/?(iframe)(>|\s))[^<]+?>/gi, '');
    // var lineBreakReg = /\n\s*\n/g;
    var lineBreakReg = /\n*\n/g;
    event = event.replace(lineBreakReg, '\n\n');
    var text = event;


    var $this = this;
    $this.countUrlMeta = 1;
    $this.postButtonHide = true;
    $this.postUrlButtonHide = false;
    $this.urlTitle = '';
    $this.urlDescription = '';
    $this.urlImage = '';
    $this.urlLink = '';
    $this.iframe = '';
    $this.urlMetaDataSearch = false;
    var includeRegex = /(([a-z]+:\/\/)?(([a-z0-9/:@\-\,\'\`]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|watch))(:[0-9]{1,5})?(\/[a-zA-Z0-9/:@.-_%\-\!\,\.~\'\`]+)*(\/([a-zA-Z0-9_%\-\!\,\.\'\`]*)(\?[a-zA-Z0-9+_\-\!\,\.%=&amp;:]*)?)?(#[a-zA-Z0-9!$&'()*+.=!-_~:@/?]*)?)(\s+|$)/g;
    var excludeRegex = /.*@.*/;
    function f1(url) {

      return new Promise<void>((resolve, reject) => {
        $this.messageUrlArr = [];
        url.replace(includeRegex, function (finalurl) {
          $this.messageUrlArr.push(finalurl);
        });
        resolve();
      });
    }

    function f2() {
      var x = 0;
      $this.postButtonHide = true;
      $this.postUrlButtonHide = false;

      var loopArray = function (arr) {
        customAlert(arr[x], function () {
          // set x to next item
          x++;
          // any more items in array? continue loop 
          if (x < arr.length && $this.countUrlMeta == 1) {
            loopArray(arr);
          }
        });
      }

      function customAlert(msg, callback) {
        msg = msg.replace(/(\r\n|\n|\r)/gm, "");
        var postUrlData = {
          meta: msg
        };
        $this.urlTitle = '';
        $this.urlDescription = '';
        $this.urlImage = '';
        $this.urlLink = '';
        $this.iframe = '';
        $this.urlMetaDataArr = [];
        $this.urlMetaDataSearch = true;
        //     //  console.log(postUrlData ,"postrldata")
        // $this._IndexService.urlmetadata(postUrlData).subscribe(res => {
        //   $this.urlMetaDataSearch = false;
        //   if (res && res['metadata']) {

        //     if (res['metadata']['title']) {
        //       $this.urlTitle = res['metadata']['title'];
        //     } else {
        //       $this.urlTitle = '';
        //     }
        //     if (res['metadata']['description']) {
        //       $this.urlDescription = res['metadata']['description'];
        //     } else {
        //       $this.urlDescription = '';
        //     }

        //     // var imgRegex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg)/;
        //     if (res['metadata']['image']) {
        //       $this.urlImage = res['metadata']['image'];
        //       // }
        //     } else {
        //       $this.urlImage = '';
        //     }

        //     if (res['metadata']['url']) {
        //       $this.urlLink = res['metadata']['url'];
        //     } else {
        //       $this.urlLink = '';
        //     }
        //     if (res['metadata']['iframe']) {
        //       $this.iframe = res['metadata']['iframe'];
        //       var $res = $this.iframe.match(/<iframe/g);
        //       if ($res == '<iframe') {
        //         $this.iframe = $this.iframe.replace(/width=["'\D+]?([^"'\D+]*)["'\d+]/g, 'width="100%"');
        //         $this.iframe = $this.iframe.replace(/height=["'\D+]?([^"'\D+]*)["'\d+]/g, 'height="100%"');
        //       }
        //     } else {
        //       $this.iframe = '';
        //     }

        //     $this.countUrlMeta = $this.countUrlMeta + 1;
        //     $this.imgURL = [];
        //     $this.fileUpload = [];
        //     $this.urlMetaDataSearch = false;
        //     $this.chatType = 5;
        //   }
        //   $this.urlMetaDataSearch = false;
        //   $this.postButtonHide = true;
        //   $this.postUrlButtonHide = false;
        //   callback();
        // });
      }


      if ($this.messageUrlArr.length > 0) {
        $this.postButtonHide = false;
        $this.postUrlButtonHide = true;
        loopArray($this.messageUrlArr);
      } else {
        $this.urlTitle = '';
        $this.urlDescription = '';
        $this.urlImage = '';
        $this.urlLink = '';
        $this.iframe = '';
        $this.urlMetaDataSearch = false;
        $this.postButtonHide = true;
        $this.postUrlButtonHide = false;
      }

    }

    this.chatTextifLink = text.replace(includeRegex, function (url) {
      if (excludeRegex.test(url)) {
        return url;
      } else {
        f1(url).then(res => f2());
        $this.chatWithSingleLink = '';
        $this.chatWithSingleLink = '<a class="truncate_post_url truncate_anchor text-info" target=" _blank" href="' + url + '">' + url + '</a>';
        return '<a class="truncate_post_url truncate_anchor text-info" target=" _blank" href="' + url + '">' + url + '</a>';
      }
    });

    this.chatTextifLink = this.chatTextifLink.replace(/^\s+/g, "");
    return callback(this.chatTextifLink);
    //
  }
  //End Add Post show url details variables(n)


  //Send message click on function to send message(n)
  sendMessageFun(sendData) {
    //  console.log('sendMessageFun')
    var dd = {
      id: this.activeUserId,
      msg: "Send",
      sender: this.user_id
    }
    this.socket.add_chat_messages(dd);
    //  console.log('sendMessageFun sendMessageForChat')

    this.toggleEmojiPicker()
    // if (this.sendData.message) {
    //   //  console.log("hello")
    //   this.sendMessageForChat();
    // }
    // this.chatMediaUpload();
    // this.sendData.message = document.getElementById('chatTextarea').innerText;
    this.showEmojiPicker = false;
    this.clicked = true;

    if (this.imgURL.length > 0 || this.videoURL.length > 0) {
      this.msgData = (this.sendData.message || "").trim();
      this.chatMediaUpload();
      this.clicked = false;
      if (this.sendData.message) {
        //  console.log("hello")
        // this.sendMessageForChat();
      }
      //  console.log("hello 222.22" ,sendData , this.imgURL , this.imgURL.length)

    }
    if (this.textLimitMessage && this.textLimitMessage != '') {
      return;
    }
    else {
      this.textLimitMessage = '';
      var $this = this;
      $this.urlifyPost($this.sendData.message, function (res) {
        // $this.chatUserInfo.id && $this.loginUserInfo.id && If condition
        if ($this.sendData.message.trim() && $this.chatTextifLink && $this.chatTextifLink.length > 0) {
          if ($this.chatWithSingleLink == $this.chatTextifLink) {
            $this.sendData.message = $this.chatTextifLink;
          } else {
            $this.sendData.message = $this.chatTextifLink;
          }

          if ($this.sendData && $this.sendData.message != "") {
            var msgtext = ($this.sendData.message || "").trim();
            var res = msgtext.match(/<iframe/g);
            if (msgtext.match(/\n/g)) {
              // msgtext = msgtext.replace(/\n/g, "<br>")
            }
          } else {
            msgtext = "";
          }

          if (res == '<iframe') {
            msgtext = msgtext.replace(/width=["'\D+]?([^"'\D+]*)["'\d+]/g, 'width="100%"');
            msgtext = msgtext.replace(/height=["'\D+]?([^"'\D+]*)["'\d+]/g, 'height="100%"');
            $this.chatType = 5;
            $this.iframe = msgtext;
          }
          if (res == '<iframe' || $this.urlTitle != '' || ($this.urlLink != '' && $this.urlImage != '')) {
            $this.chatType = 5;
          } else {
            $this.chatType = 0;
            msgtext = $this.chatTextifLink;
          }

          // $this.sendData.message = '';
          setTimeout(() => {
            document.getElementById('chatTextarea').innerHTML = "";
            // this.sendData.message = ""

          }, 1000);
          var removeHtmlFromMsg = msgtext.replace(/<(?!\/?(iframe|span|a)(>|\s))[^<]+?>/gim, '');

          var sendMessagePost = {
            // login_user_id: "45",
            // chat_user_id: "78",
            user_from: this.user_id,
            user_to: this.activeUserId,
            message: removeHtmlFromMsg.trim(),
            mime_type: '',
            type: $this.chatType,
            urlTitle: $this.urlTitle,
            urlDescription: $this.urlDescription,
            urlImage: $this.urlImage,
            urlLink: $this.urlLink,
            iframe: $this.iframe,
            connection_id: this.connection_id

          }
          //  console.log(sendMessagePost, "sendMessagaePost")
          $this.myProfileService.sendMessageForChat(sendMessagePost).subscribe(
            res => {
              $this.sendMessageButton = false;
              $this.sendData.message = '';
              this.msgContent = "";
              this.clearField();
              this.getChatUserList();
              this.getUserDetails();
              //  console.log("not clear 11111")
              // chat_section.scrollBy(0,0);
              if (res && res['status_code'] && res['status_code'] == 200) {
                // Socket(n)
                var socketData = {
                  responce: sendMessagePost,
                  type: 'message',
                  id: 164
                };

                //  $this._ChatService.sendMessageSocket(socketData);
                // Socket(n)
                //  $this.getChattingByActiveChatuser($this.chatUserInfo.id);
                // $this.getcontactListFunction();
                $this.clicked = false;
                $this.chatType = 0;
                //  $this.replyKey[$this.replyTempKey] = false;
              } else {
                $this.toastrService.error(res['message'], 'Error!');
                $this.noChatOpen = true;
                // $this.getcontactListFunction();
              }
            }, (err) => {
              if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
                $this.toastrService.error(err['error']['message'], 'Error!');
                // localStorage.clear();
                sessionStorage.removeItem('fullcomponent');

                localStorage.removeItem('package_id')
                localStorage.removeItem('register_id')
                localStorage.removeItem('Time-Zone')
                localStorage.removeItem('profile_ids')
                localStorage.removeItem('matchedProfilesCount')
                //   $this.route.navigate(['/login']);
                $this.clicked = false;
                $this.chatType = 0;
              } else {
                if (err && err['message']) {
                  //$this.toastr.error(err['message'], 'Error!');
                  $this.clicked = false;
                }
              }
            });
        }
      });
    }
  }
  //End Send message click on function to send message(n)

  chatMediaUpload() {
    this.sendFilesWithCaptionForChat();
    //  console.log('chatMediaUpload start', this.sendData.message)
    this.totalMediaSize = 0;
    this.totalMediaLength = 0;
    this.fileUploadLoader = true;
    this.imgURL = [];
    this.videoURL = [];
    var frmData = new FormData();

    var sendMessagePost = {

      message: this.sendData.message,
    }
    var dd = frmData;
    frmData.append("user_from", this.user_id);
    frmData.append("connection_id", this.connection_id);
    frmData.append("user_to", this.activeUserId);
    if (this.sendData.message) {
      //  console.log("hello")
    }
    var capUp = ['hello', 'user', "working", "fine"];
    frmData.append("type", '1');
    for (var i = 0; i < this.fileUpload.length; i++) {
      frmData.append("file[]", this.fileUpload[i]);
      if (this.captionUpload && this.captionUpload[i] != '' && this.captionUpload[i] != undefined) {

        frmData.append("caption[]", this.captionUpload[i]);
      } else {
        frmData.append("caption[]", '');
      }
      //  console.log(this.captionUpload[i], "captons added ")

      // if (this.fileUpload[i].size <= 5942880) { // file uploading less then 5 mb 
      // } else {
      //  console.log(this.fileUpload[i], "sendmsgPost")
      // }
    }
    //  console.log(frmData, "hello check ")
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        var chat_section = document.getElementById("chat_section");
        chat_section.scrollTop = chat_section.scrollHeight;
      }, 1000);
    });

    //  check file size 
    this.spinner.show();
    //  console.log('chatMediaUpload upload file now', this.sendData.message)

    this.myProfileService.sendFilesForChat(frmData).subscribe(
      res => {
        //  console.log('this.sendData.message', this.sendData.message)

        this.fileUploadLoader = false;
        if (res && res.status) {
          this.fileUploadCount = 0;
          this.fileUploadLoader = false;
          this.isSentMessage = true;
          this.fileUpload = [];
          this.captionUpload = [];
          this.f.description.setValue('');
          this.spinner.hide();
          // Socket(n)
          var socketData = {
            responce: sendMessagePost,
            type: 'message',
          };
          if (this.sendData.message) {
            //  console.log("hello")
            // this.sendMessageForChat();
          } else {
            this.getChatUserList();

          }
          this.getChatUserDetails();
          // this._ChatService.sendMessageSocket(socketData);
          // Socket(n)
          // this.fileUploadLoader = false;
          // this.fileUpload = [];
          // if (this.msgData == '') {
          //   this.getChattingByActiveChatuser(this.chatUserInfo.id);
          //   this.getcontactListFunction();
          // }
        } else {
          //   this.toastrService.error(res['message'], 'Error!');
          this.noChatOpen = true;
          this.getcontactListFunction();
        }
      }, (err) => {
        this.fileUploadLoader = false;
        if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
          this.toastrService.error(err['error']['message'], 'Error!');
          // localStorage.clear();
          sessionStorage.removeItem('fullcomponent');

          localStorage.removeItem('package_id')
          localStorage.removeItem('register_id')
          localStorage.removeItem('Time-Zone')
          localStorage.removeItem('profile_ids')
          localStorage.removeItem('matchedProfilesCount')
          //     this.route.navigate(['/login']);
        } else {
          if (err && err['message']) {
            this.toastrService.error("File not uploaded. You may have slow internet speed or uploaded media size is too large.", 'Error!');
          }
        }
      });
    //   check file size 
    this.clicked = false;

  }

  increaseLimit(chat) {
    //  console.log(chat, "chat")
    this.increase = this.increase + 500

  }

  toggleEmojiPicker() {
    this.showGifBox = false;
    this.showStickerBox = false;
    this.showEmojiPicker = !this.showEmojiPicker;
    //  console.log(this.showGifBox, "emoji", this.showEmojiPicker, " picker", this.showStickerBox)
  }


  addEmoji(event) {
    //  console.log(event, event.target, "emoji ")
    // var regex = /(<([^>]+)>)/ig;
    // var result = this.sendData.message.replace(regex, "");
    // var chatTextAreaHtml = document.getElementById("chatTextarea").innerHTML;
    // var regEx = /(?:<span[^>]*)(?:(?:\/>)|(?:>.*?))*<\/span>/ig;
    // if (regEx.test(chatTextAreaHtml)) {
    //    var tempmsglength = (chatTextAreaHtml.replace(/(?:<span[^>]*)(?:(?:\/>)|(?:>.*?))*<\/span>/ig, '11')).length;
    //   var tempMsgVlaue = chatTextAreaHtml.replace(/(?:<span[^>]*)(?:(?:\/>)|(?:>.*?))*<\/span>/ig, '11')
    //   tempMsgVlaue = tempMsgVlaue.replace(/(&nbsp;)|(&amp;nbsp;)/gm, ' ');
    //   var tempmsglength = tempMsgVlaue.length;

    //   if (tempmsglength <= 1998 && tempmsglength > 0) {
    //     this.textLimitMessage = ''
    //     this.sendMessageButton = true;
    //     this.clicked = false;
    //   } else {
    //     if (chatTextAreaHtml.length == 0) {
    //       this.textLimitMessage = '';
    //       this.sendMessageButton = false;
    //       this.clicked = false;
    //     } else {
    //       this.textLimitMessage = 'Only 2000 characters are allowed.';
    //       this.sendMessageButton = false;
    //       this.clicked = false;
    //       // return;
    //     }
    //   }
    // } else {
    //   if (chatTextAreaHtml.length <= 2000 && chatTextAreaHtml.length > 0) {
    //     this.textLimitMessage = ''
    //     this.sendMessageButton = true;
    //     this.clicked = false;
    //   } else {
    //     if (chatTextAreaHtml.length == 0) {
    //       this.textLimitMessage = '';
    //       this.sendMessageButton = false;
    //       this.clicked = false;
    //     } else {
    //       this.textLimitMessage = 'Only 2000 characters are allowed.'
    //       this.sendMessageButton = false;
    //       this.clicked = false;
    //       // return;
    //     }

    //   }
    // }
    // if (event && event.emoji && event.emoji.native && event.emoji.sheet && this.sheet) {
    //   const styles = this.emojiService.emojiSpriteStyles(event.emoji.sheet, this.sheet);
    //   var span = <HTMLElement>this.renderer.createElement('span');
    //   Object.assign(span.style, styles, {
    //     overflow: 'hidden',
    //     textIndent: '-1000px',
    //     position: 'relative',
    //     top: '7px',
    //     display: 'inline-block',
    //   });
    //   span.className = 'emoji-pipe-image';
    //   span.textContent = event.emoji.native;
    //   if (/Android/i.test(navigator.userAgent)) {
    //     span.setAttribute('contenteditable', 'false');
    //     span.setAttribute('selectable', 'true');
    //     span.setAttribute('inline', 'true');
    //     span.setAttribute('group', 'inline');
    //     span.setAttribute('dragable', 'true');
    //   } else {
    //     span.setAttribute('contenteditable', 'false');
    //   }

    //   var regNewline = (/^(\n{3,})$/gm).test(document.getElementById('chatTextarea').innerHTML);
    //   if (regNewline) {
    //     var chatMsgTemp = (document.getElementById('chatTextarea').innerHTML).replace(/^(\n{3,})$/gm, '\n\n' + span.outerHTML + ' ');
    //     document.getElementById('chatTextarea').innerHTML = chatMsgTemp;
    //   } else {
    //     document.getElementById('chatTextarea').innerHTML += span.outerHTML + '&nbsp;';
    //   }

    //   var $this = this;
    //   this.addCursorAfterEmoji('chatTextarea', function (res) {
    //     $this.sendMessageButton = true;
    //     $this.sendData.message += span.outerHTML + '&nbsp;';
    //   });
    // } else {
    //   if (event && event.emoji && event.emoji.custom && event.emoji.imageUrl && event.emoji.text && this.size) {
    //     var span = <HTMLElement>this.renderer.createElement('span');
    //     Object.assign(span.style, {
    //       overflow: 'hidden',
    //       textIndent: '-1000px',
    //       position: 'relative',
    //       top: '7px',
    //       display: 'inline-block',
    //       height: this.size + 'px',
    //       width: this.size + 'px',
    //       backgroundImage: 'url(' + event.emoji.imageUrl + ')',
    //       backgroundSize: 'cover'
    //     });
    //     span.className = 'emoji-pipe-image';
    //     span.textContent = event.emoji.text;
    //     if (/Android/i.test(navigator.userAgent)) {
    //       // span.setAttribute('contenteditable', 'true');
    //       span.setAttribute('contenteditable', 'false');
    //       span.setAttribute('selectable', 'true');
    //       span.setAttribute('inline', 'true');
    //       span.setAttribute('group', 'inline');
    //       span.setAttribute('dragable', 'true');
    //     } else {
    //       span.setAttribute('contenteditable', 'false');
    //     }
    //     // document.getElementById('chatTextarea').innerHTML += span.outerHTML + ' '
    //     var regNewline = (/^(\n{3,})$/gm).test(document.getElementById('chatTextarea').innerHTML);
    //     if (regNewline) {
    //       var chatMsgTemp = (document.getElementById('chatTextarea').innerHTML).replace(/^(\n{3,})$/gm, '\n\n' + span.outerHTML + ' ');
    //       document.getElementById('chatTextarea').innerHTML = chatMsgTemp;
    //     } else {
    //       document.getElementById('chatTextarea').innerHTML += span.outerHTML + '&nbsp;';
    //     }
    //     var $this = this;
    //     this.addCursorAfterEmoji('chatTextarea', function (res) {
    //       $this.sendMessageButton = true;
    //       $this.sendData.message += span.outerHTML + '&nbsp;';
    //     });
    //   }
    // }

    // same copy
    var regex = /(<([^>]+)>)/ig;
    var result = this.sendData.message.replace(regex, "");
    var chatTextAreaHtml = document.getElementById("chatTextarea").innerHTML;
    var regEx = /(?:<span[^>]*)(?:(?:\/>)|(?:>.*?))*<\/span>/ig;
    if (regEx.test(chatTextAreaHtml)) {
      // var tempmsglength = (chatTextAreaHtml.replace(/(?:<span[^>]*)(?:(?:\/>)|(?:>.*?))*<\/span>/ig, '11')).length;
      var tempMsgVlaue = chatTextAreaHtml.replace(/(?:<span[^>]*)(?:(?:\/>)|(?:>.*?))*<\/span>/ig, '11')
      tempMsgVlaue = tempMsgVlaue.replace(/(&nbsp;)|(&amp;nbsp;)/gm, ' ');
      var tempmsglength = tempMsgVlaue.length;

      if (tempmsglength <= 1998 && tempmsglength > 0) {
        this.textLimitMessage = ''
        this.sendMessageButton = true;
        this.clicked = false;
      } else {
        if (chatTextAreaHtml.length == 0) {
          this.textLimitMessage = '';
          this.sendMessageButton = false;
          this.clicked = false;
        } else {
          this.textLimitMessage = 'Only 2000 characters are allowed.';
          this.sendMessageButton = false;
          this.clicked = false;
          // return;
        }
      }
    } else {
      if (chatTextAreaHtml.length <= 2000 && chatTextAreaHtml.length > 0) {
        this.textLimitMessage = ''
        this.sendMessageButton = true;
        this.clicked = false;
      } else {
        if (chatTextAreaHtml.length == 0) {
          this.textLimitMessage = '';
          this.sendMessageButton = false;
          this.clicked = false;
        } else {
          this.textLimitMessage = 'Only 2000 characters are allowed.'
          this.sendMessageButton = false;
          this.clicked = false;
          // return;
        }

      }
    }
    if (event && event.emoji && event.emoji.native && event.emoji.sheet && this.sheet) {
      const styles = this.emojiService.emojiSpriteStyles(event.emoji.sheet, this.sheet);
      var span = <HTMLElement>this.renderer.createElement('span');
      Object.assign(span.style, styles, {
        overflow: 'hidden',
        textIndent: '-1000px',
        position: 'relative',
        top: '7px',
        display: 'inline-block',
      });
      span.className = 'emoji-pipe-image';
      span.textContent = event.emoji.native;
      if (/Android/i.test(navigator.userAgent)) {
        span.setAttribute('contenteditable', 'false');
        span.setAttribute('selectable', 'true');
        span.setAttribute('inline', 'true');
        span.setAttribute('group', 'inline');
        span.setAttribute('dragable', 'true');
      } else {
        span.setAttribute('contenteditable', 'false');
      }

      var regNewline = (/^(\n{3,})$/gm).test(document.getElementById('chatTextarea').innerHTML);
      if (regNewline) {
        var chatMsgTemp = (document.getElementById('chatTextarea').innerHTML).replace(/^(\n{3,})$/gm, '\n\n' + span.outerHTML + ' ');
        document.getElementById('chatTextarea').innerHTML = chatMsgTemp;
      } else {
        document.getElementById('chatTextarea').innerHTML += span.outerHTML + '&nbsp;';
      }

      var $this = this;
      this.addCursorAfterEmoji('chatTextarea', function (res) {
        $this.sendMessageButton = true;
        $this.sendData.message += span.outerHTML + '&nbsp;';
      });
    } else {
      if (event && event.emoji && event.emoji.custom && event.emoji.imageUrl && event.emoji.text && this.size) {
        var span = <HTMLElement>this.renderer.createElement('span');
        Object.assign(span.style, {
          overflow: 'hidden',
          textIndent: '-1000px',
          position: 'relative',
          top: '7px',
          display: 'inline-block',
          height: this.size + 'px',
          width: this.size + 'px',
          backgroundImage: 'url(' + event.emoji.imageUrl + ')',
          backgroundSize: 'cover'
        });
        span.className = 'emoji-pipe-image';
        span.textContent = event.emoji.text;
        if (/Android/i.test(navigator.userAgent)) {
          // span.setAttribute('contenteditable', 'true');
          span.setAttribute('contenteditable', 'false');
          span.setAttribute('selectable', 'true');
          span.setAttribute('inline', 'true');
          span.setAttribute('group', 'inline');
          span.setAttribute('dragable', 'true');
        } else {
          span.setAttribute('contenteditable', 'false');
        }
        // document.getElementById('chatTextarea').innerHTML += span.outerHTML + ' '
        var regNewline = (/^(\n{3,})$/gm).test(document.getElementById('chatTextarea').innerHTML);
        if (regNewline) {
          var chatMsgTemp = (document.getElementById('chatTextarea').innerHTML).replace(/^(\n{3,})$/gm, '\n\n' + span.outerHTML + ' ');
          document.getElementById('chatTextarea').innerHTML = chatMsgTemp;
        } else {
          document.getElementById('chatTextarea').innerHTML += span.outerHTML + '&nbsp;';
        }
        var $this = this;
        this.addCursorAfterEmoji('chatTextarea', function (res) {
          $this.sendMessageButton = true;
          $this.sendData.message += span.outerHTML + '&nbsp; ';
        });
      }
    }


  }

  addCursorAfterEmoji(contenteditableId, callback) {
    var input = document.createElement("input");
    input.type = "text";
    input.className = "contenteditableInput";
    input.style.width = '0.8px';
    input.style.height = '12px';
    input.style.border = 'none';
    input.style.margin = '0';
    input.style.padding = '0';
    input.tabIndex = -1;
    input.id = 'tempIdELement';
    if (this.controls) {
      document.getElementById(contenteditableId).appendChild(input).blur();
    } else {
      document.getElementById(contenteditableId).appendChild(input).focus();
    }
    var p = document.getElementById('tempIdELement'),
      s = window.getSelection(),
      r = document.createRange();
    r.setStart(p, 0);
    r.setEnd(p, 0);
    s.removeAllRanges();
    s.addRange(r);
    input.remove();

    return callback(true);
  }



  backspaceClick(event) {
    var $this = this;
    var regex: RegExp;
    if (event.inputType === 'deleteContentBackward') {
      var contentText = event.target.innerText;
      var firstHalfText = contentText.slice(0, $this.currentPostion);
      var secondHalfText = contentText.slice($this.currentPostion);

      var emojiRegex = /<span class="emoji">[^<]+<\/span>$/;

      var match = firstHalfText.match(emojiRegex);
      if (match) {
        var updatedFirstHalfText = firstHalfText.replace(emojiRegex, '');
        var updatedContentText = updatedFirstHalfText + secondHalfText;
        event.target.innerText = updatedContentText;

        // Set the caret position after the removed emoji
        $this.currentPostion = updatedFirstHalfText.length;
        $this.addCursorAfterEmoji('chatTextarea', function (res) {
          // Callback logic
        });
      }
    }
  }

  // backspaceClick(event) {
  //   var $this = this;
  //   var regex: RegExp;
  //  console.log( "hello 1.1", event)
  // this.caretPosition('chatTextarea', function (res) {
  //   if (event.inputType == 'deleteContentBackward') {
  //     regex = /<\/span>( |&nbsp;)?$/gm;

  //     var contentHtml = event.target.innerHTML;
  //     var contentText = event.target.innerText;

  //     var firstHalfText = contentText.slice(0, $this.currentPostion);
  //     var secondHalfText = contentText.slice($this.currentPostion);

  //     var firstHalfHtml = <any>($this.emojPipe.transform(firstHalfText, $this.sheet, $this.size, 64, $this.sheetId, false, $this.backgroundImageFn));
  //     var secondHalfHtml = <any>($this.emojPipe.transform(secondHalfText, $this.sheet, $this.size, 64, $this.sheetId, false, $this.backgroundImageFn));

  //     if (regex.test(firstHalfHtml)) {
  //       let result = firstHalfHtml.lastIndexOf("<span");
  //       let updateContent = firstHalfHtml.substring(0, result);
  //       if (!($this.currentPostion < event.target.innerText.length)) {
  //         event.target.innerHTML = updateContent + secondHalfHtml;
  //         $this.addCursorAfterEmoji('chatTextarea', function (res) {
  //         })
  //       } else {
  //         event.target.innerHTML = updateContent;
  //         $this.addCursorAfterEmoji('chatTextarea', function (res) {
  //           var tempnode = document.createTextNode(secondHalfText);
  //           document.getElementById('chatTextarea').insertAdjacentHTML('beforeend', secondHalfHtml);
  //         })
  //       }
  //     }
  //   }
  // });

  // this.caretPosition('chatTextarea', function (res) {
  //   if ((/Android/i.test(navigator.userAgent)) && (event.inputType == 'insertText') && (event.data.length >= 2)) {
  //     var tempData = event.target.innerText;

  //     var tempDataHtml = <any>($this.emojPipe.transform(tempData, $this.sheet, $this.size, 64, $this.sheetId, false, $this.backgroundImageFn));

  //     if (!($this.currentPostion < event.target.innerText.length)) {
  //       event.target.innerHTML = '';
  //       event.target.innerHTML = tempDataHtml;
  //       $this.addCursorEndInContenteditable('chatTextarea', function (res) {
  //       })
  //     } else {
  //       event.target.innerHTML = '';
  //       event.target.innerHTML = tempDataHtml;
  //       $this.addCursorEndInContenteditable('chatTextarea', function (res) {
  //       });
  //     }
  //   }
  //   if (event && event.type && event.type == 'paste') {
  //   } else {
  //     $this.sendData.message = event.target.innerText;
  //   }
  // });
  //}

  loadNewChat: boolean = true;
  onEnterPress(msg, event) {
    this.loadNewChat = true;
    if (!this.controls) {
      if ((event.key == 'Enter' || event.keyCode == 13 || event.which == 13) && !event.shiftKey) {
        // this.sendMessageFun(this.sendData);
        // this.sendMessageForChat()   //cmnt as enter is sending message 
        return false;
      } else {
        event.preventDefault();
      }
    }
  }

  currentPostion: number;
  caretPosition(element, callback) {
    var caretOffset = 0;
    var doc = (<any>document.getElementById('chatTextarea')).ownerDocument || (<any>document.getElementById('chatTextarea')).document;
    var win = doc.defaultView || doc.parentWindow;
    var sel;
    if (typeof win.getSelection != "undefined") {
      sel = win.getSelection();
      if (sel.rangeCount > 0) {
        var range = win.getSelection().getRangeAt(0);
        var preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents((<any>document.getElementById('chatTextarea')));
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;
      }
    } else if ((sel = doc.selection) && sel.type != "Control") {
      var textRange = sel.createRange();
      var preCaretTextRange = doc.body.createTextRange();
      preCaretTextRange.moveToElementText((<any>document.getElementById('chatTextarea')));
      preCaretTextRange.setEndPoint("EndToEnd", textRange);
      caretOffset = preCaretTextRange.text.length;
    }
    this.currentPostion = caretOffset;
    return callback(true);
    // return { start: start, end: end };
  }

  // backgroundImageFn = (set: string, sheetSize: number) => {
  //   return `${environment.emoji_path}${this.sheet}/${sheetSize}.png`;
  // }
  backgroundImageFn() {
    return (set: string, sheetSize: number): string =>
      `${environment.emoji_path}${this.sheet}/${sheetSize}.png`;
  }
  //Main Contact list search user(n) 
  getcontactListFunction() {

    // var contact_list = document.getElementById("contacts");
    // contact_list.scrollTo(0, 0);
    // var searchNamePost = {
    //   user_list_section_type: this.user_list_section_type,
    //   search: '',
    //   page: ''
    // }
    // this.UserDetailsLoaderFirstTime = true;
    // this.UserDetailsLoader = true;

    // if (this.getConnactUserListSub) {
    //   this.getConnactUserListSub.unsubscribe();
    // } else {
    //   this.getConnactUserListSub = '';
    // }

    // this.getConnactUserListSub = this._ChatService.getConnactUserListForChat(searchNamePost).subscribe(
    //   res => {
    //     this.UserDetailsLoaderFirstTime = false;
    //     this.UserDetailsLoader = false;
    //     this.connectUserListArr = [];
    //     if (res && res['connect_user_list']) {
    //       for (var i = 0; i < res['connect_user_list'].length; i++) {
    //         this.connectUserListArr = this.connectUserListArr.filter(item => item.id != res['connect_user_list'][i].id);
    //         this.connectUserListArr.push(res['connect_user_list'][i]);
    //       }
    //     }

    //     this.showMoreUserConnectList = res['showMore'];
    //     if (res && res['nextPage']) {
    //       this.nextPageConnectUserList = res['nextPage'];
    //     }

    //   }, (err) => {
    //     this.UserDetailsLoaderFirstTime = false;
    //     this.UserDetailsLoader = false;

    //     if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
    //       this.toastr.error(err['error']['message'], 'Error!');
    //       localStorage.clear();
    //       sessionStorage.removeItem('fullcomponent');
    //      // this.route.navigate(['/login']);
    //     } else {
    //       if (err && err['message']) {
    //         //this.toastr.error(err['message'], 'Error!');
    //       }
    //     }
    //   });
  }
  //End Main Contact list search user(n) 

  //Main Contact list search user(n) 
  // contactListSearchFunction(event) {
  //   this.contactSearch = event.target.value;

  //   var searchNamePost = {
  //     user_list_section_type: this.user_list_section_type,
  //     search: this.contactSearch,
  //     page: ''
  //   }
  //   this.UserDetailsLoaderFirstTime = true;
  //   this.UserDetailsLoader = true;

  //   if (this.getConnactUserListSub) {
  //     this.getConnactUserListSub.unsubscribe();
  //   } else {
  //     this.getConnactUserListSub = '';
  //   }

  //   this.getConnactUserListSub = this._ChatService.getConnactUserListForChat(searchNamePost).subscribe(
  //     res => {
  //       this.UserDetailsLoaderFirstTime = false;
  //       this.UserDetailsLoader = false;
  //       this.connectUserListArr = [];
  //       if (res && res['connect_user_list']) {
  //         for (var i = 0; i < res['connect_user_list'].length; i++) {
  //           this.connectUserListArr = this.connectUserListArr.filter(item => item.id != res['connect_user_list'][i].id);
  //           this.connectUserListArr.push(res['connect_user_list'][i]);
  //         }
  //       }

  //       this.showMoreUserConnectList = res['showMore'];
  //       if (res && res['nextPage']) {
  //         this.nextPageConnectUserList = res['nextPage'];
  //       }

  //     }, (err) => {
  //       this.UserDetailsLoaderFirstTime = false;
  //       this.UserDetailsLoader = false;

  //       if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
  //         this.toastr.error(err['error']['message'], 'Error!');
  //         localStorage.clear();
  //         sessionStorage.removeItem('fullcomponent');
  //         this.route.navigate(['/login']);
  //       } else {
  //         if (err && err['message']) {
  //           //this.toastr.error(err['message'], 'Error!');
  //         }
  //       }
  //     });
  // }
  //End Main Contact list search user(n) 


  onFocus() {
    // this.showEmojiPicker = false;
    // this.showGifBox = false;
    // this.showStickerBox = false;
  }
  onBlur() {
  }
  /*End Add emoji feature(n)*/

  //Input message text field keyup/focus/focusout(n)
  sendMessageChange(message) {
    var reg = /<(?!\/?(iframe|span)(>|\s))[^<]+?>/gi;
    message = message.replace(reg, '');
    // var lineBreakReg = /\n\s*\n/g;
    var lineBreakReg = /\n*\n/g;
    message = message.replace(lineBreakReg, '\n\n');
    this.sendData.message = message;
    var regEx = /(?:<span[^>]*)(?:(?:\/>)|(?:>.*?))*<\/span>/ig;
    var chatHtml = document.getElementById('chatTextarea').innerHTML;
    if (regEx.test(chatHtml)) {
      // var tempmsglength=(chatHtml.replace(/(?:<span[^>]*)(?:(?:\/>)|(?:>.*?))*<\/span>/ig, '11')).length;
      var tempMsgVlaue = chatHtml.replace(/(?:<span[^>]*)(?:(?:\/>)|(?:>.*?))*<\/span>/ig, '11')
      tempMsgVlaue = tempMsgVlaue.replace(/(&nbsp;)|(&amp;nbsp;)/gm, ' ');
      var tempmsglength = tempMsgVlaue.length;

      if (tempmsglength <= 2000 && tempmsglength > 0) {
        this.textLimitMessage = '';
        this.sendMessageButton = true;
        this.clicked = false;
      } else {
        if (chatHtml.length == 0) {
          this.textLimitMessage = '';
        } else {
          this.textLimitMessage = 'Only 2000 characters are allowed.';
        }
        this.sendMessageButton = false;
        this.clicked = false;
      }
    } else {
      if (message && message.length <= 2000 && message.length > 0) {
        this.textLimitMessage = '';
        this.sendMessageButton = true;
        this.clicked = false;
      } else {
        if (message.length == 0) {
          this.textLimitMessage = '';
        } else {
          this.textLimitMessage = 'Only 2000 characters are allowed.';
        }
        this.sendMessageButton = false;
        this.clicked = false;
      }

    }
    // if (message && message.trim() && message.length && message.length <= 2000) {

    //   this.textLimitMessage = '';
    //   this.sendMessageButton = true;
    //   this.clicked = false;
    //   var sendMessagePost = {
    //     login_user_id: this.loginUserInfo.id,
    //     chat_user_id: this.chatUserInfo.id,
    //     message: message.trim()
    //   }
    // } else {

    //   if (message.length && message.length > 2000) {
    //     this.textLimitMessage = 'You cannot enter more than 2000 characters.';
    //   }
    //   this.sendMessageButton = false;
    //   this.clicked = false;
    // }
  }
  addCursorEndInContenteditable(contenteditableId, callback) {
    var input = document.createElement("input");
    input.type = "text";
    input.className = "contenteditableInput";
    input.style.width = '0.8px';
    input.style.height = '12px';
    input.style.border = 'none';
    input.style.margin = '0';
    input.style.padding = '0';
    input.tabIndex = -1;
    input.id = 'tempIdELement';
    document.getElementById(contenteditableId).appendChild(input).focus();
    var p = document.getElementById('tempIdELement'),
      s = window.getSelection(),
      r = document.createRange();
    r.setStart(p, 0);
    r.setEnd(p, 0);
    s.removeAllRanges();
    s.addRange(r);
    input.remove();

    return callback(true);
  }



  textPaste(msg, event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    event.preventDefault();
    var $this = this;
    var clipboarddata = clipboardData.getData('text/plain');
    this.caretPosition('chatTextarea', function (res) {
      clipboarddata = [(document.getElementById('chatTextarea').innerText).slice(0, $this.currentPostion), clipboarddata, (document.getElementById('chatTextarea').innerText).slice($this.currentPostion)].join('');

      var tempVal = <any>($this.emojPipe.transform(clipboarddata, $this.sheet, $this.size, 64, $this.sheetId, false, $this.backgroundImageFn));
      document.getElementById('chatTextarea').innerHTML = tempVal.replace(/<\/span>( |^&nbsp;)/gm, '</span>&nbsp;');
      clipboarddata = document.getElementById('chatTextarea').innerHTML;
    });

    //This code use only for add cursor end in contenteditable(n)
    this.addCursorEndInContenteditable('chatTextarea', function (res) {
      var evetnValue = '';
      if (event && event.target && event.target.value) {
        evetnValue = event.target.value;
      } else if (clipboarddata) {
        evetnValue = clipboarddata
      }
      if (event.isTrusted && evetnValue) {

        $this.sendMessageButton = true;
        $this.clicked = false;
        var $thiss = $this;
        $this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            // var reg = /(<([^>]+)>)/ig;
            var reg = /<(?!\/?(iframe)(>|\s))[^<]+?>/gi;
            evetnValue = (evetnValue).replace(reg, '');
            // var lineBreakReg = /\n\s*\n/g;
            var lineBreakReg = /\n*\n/g;
            evetnValue = (evetnValue).replace(lineBreakReg, '\n\n');
            $thiss.sendData.message = evetnValue;

            var chatTextAreaHtml = document.getElementById("chatTextarea").innerHTML;
            var regEx = /(?:<span[^>]*)(?:(?:\/>)|(?:>.*?))*<\/span>/ig;
            if (regEx.test(chatTextAreaHtml)) {
              // var tempmsglength = (chatTextAreaHtml.replace(/(?:<span[^>]*)(?:(?:\/>)|(?:>.*?))*<\/span>/ig, '11')).length;
              var tempMsgVlaue = chatTextAreaHtml.replace(/(?:<span[^>]*)(?:(?:\/>)|(?:>.*?))*<\/span>/ig, '11')
              tempMsgVlaue = tempMsgVlaue.replace(/(&nbsp;)|(&amp;nbsp;)/gm, ' ');
              var tempmsglength = tempMsgVlaue.length;

              if (tempmsglength <= 2000 && tempmsglength > 0) {
                $thiss.textLimitMessage = '';
                $thiss.sendMessageButton = true;
                $thiss.clicked = false;
              } else {
                if (chatTextAreaHtml.length == 0) {
                  $thiss.textLimitMessage = '';
                } else {
                  $thiss.textLimitMessage = 'Only 2000 characters are allowed.';
                }
                // $thiss.textLimitMessage = 'Only 200 characters are allowed.';
                $thiss.sendMessageButton = false;
                $thiss.clicked = false;
              }

            } else {
              if (evetnValue.length <= 2000 && evetnValue.length > 0) {
                $thiss.textLimitMessage = '';
                $thiss.sendMessageButton = true;
                $thiss.clicked = false;
              } else {
                if ($thiss.sendData.message.length && $thiss.sendData.message.length > 200) {
                  $thiss.textLimitMessage = 'Only 2000 characters are allowed.';
                } else {
                  $this.textLimitMessage = '';
                }
                $thiss.sendMessageButton = false;
                $thiss.clicked = false;
              }
            }
          }, 100);
        });
      }
    });

    // if (event.isTrusted) {
    //   this.sendMessageButton = true;
    //   this.clicked = false;
    //   this.ngZone.runOutsideAngular(() => {
    //     setTimeout(() => {
    //       var chatTextArea = document.getElementById("chatTextarea");
    //       chatTextArea.scrollTo(0, 0);
    //       var reg = /<(?!\/?(iframe)(>|\s))[^<]+?>/gi;
    //       event.target.value = (event.target.value).replace(reg, '');
    //       // var lineBreakReg = /\n\s*\n/g;
    //       var lineBreakReg = /\n*\n/g;
    //       event.target.value = (event.target.value).replace(lineBreakReg, '\n\n');
    //       this.sendData.message = event.target.value;
    //     }, 100);
    //   });
    // }
  }
  blockReportId(user) {
    if (user.label == "Other") {
      this.otherReason = true;
      this.reportReason = ""
    } else {
      this.otherReason = false
      this.reportReason = user.label
    }
    //  console.log(user, "id and label", this.reportReason)
  }

  blockAndReportNow() {
    var data = {
      user_id: this.user_id,
      profile_id: this.activeUserId,
      action: this.action.replaceAll(' ', '_'),
      reason: this.reportReason
    };
    //  console.log("api called", data)
    this.myProfileService.doActionOnRequests(data).subscribe(
      (result: any) => {
        if (result.status) {
          this.toastrService.success(result.message);
          //this.activeUserId = 0;
          this.getChatUserList();
          // this.getChatUserDetails();
        } else {
          this.toastrService.error(result.message);
        }
        // this.toastrService.success('Successfully!', 'Astro Details Update!');
      },
      (error) => {
        this.ChatId = 0;
        // this.toastrService.error();
      }
    );
  }
  focusMessageFunction() {
    // Socket(n)
    // var sendMessagePost = {
    //   login_user_id: this.loginUserInfo.id,
    //   chat_user_id: this.chatUserInfo.id
    // }
    // this._ChatService.TypingMessageSocket(sendMessagePost);
    // Socket(n)
  }

  //End Input message text field keyup/focus/focusout(n)
  msgContent: SafeHtml;
  urlServiceCall(msg, event) {
    var $this = this;
    if (event && event.type && event.type == 'paste') {
    } else {
      $this.sendData.message = event.target.innerText;
    }
  }

  getF(data, event) {
    this.message = data;
    // this.msgContent = this.message
    //  console.log("data", data, "msgContent", this.msgContent, event)

  }
  clearField() {
    // var dd = document.getElementById('chatTextarea').innerHTML;
    //  console.log(this.message, this.sendData, this.sendData.message, "before", dd),
    //this.message = "" ,
    this.sendMessageForChatForm.reset();
    this.sendData.message = "";
    this.msgContent = "";
    this.message = "";
    var dd = document.getElementById('chatTextarea').innerHTML;
    document.getElementById('chatTextarea').innerHTML = "";
    //  console.log(this.message, "||", typeof (this.message), this.sendData, this.sendData.message, "after ", dd)
  }

  openMedia(data) {
    //  console.log("data on click", data, this.display, this.bgColor)
    this.openChatMedia = data.message;
    document.getElementById("myModal").style.display = "block";
    // if(this.)
    // document.getElementById("myModal");
    // this.display = 'block';
    // this.bgColor = '#000000;'
    //  console.log(this.display, this.bgColor, "pop open chat")

  }
  openModal() {
    // this.openChatMedia = "https://www.w3schools.com/howto/img_nature.jpg"
    //  console.log("modal open lightbox ts ")
    document.getElementById("myModal").style.display = "block";
  }

  closeModal() {
    //  console.log("modal close lightbox ts")
    // this.display = 'none';
    // this.bgColor = '#000000;'
    document.getElementById("myModal").style.display = "none";
  }

  sendFilesWithCaptionForChat() {
    //  console.log('chatMediaUpload start', this.sendData.message)
    // this.totalMediaSize = 0;
    // this.totalMediaLength = 0;
    // this.fileUploadLoader = true;
    // this.imgURL = [];
    // this.videoURL = [];
    // var frmData = new FormData();

    // var sendMessagePost = {

    //   message: this.sendData.message,
    // }
    // var dd = frmData;
    // frmData.append("user_from", this.user_id);
    // frmData.append("connection_id", this.connection_id);
    // frmData.append("user_to", this.activeUserId);
    // frmData.append("caption", this.sendData.message);
    // frmData.append("type", '1');
    // for (var i = 0; i < this.fileUpload.length; i++) {
    //   frmData.append("file[]", this.fileUpload[i]);

    // }

    // this.ngZone.runOutsideAngular(() => {
    //   setTimeout(() => {
    //     var chat_section = document.getElementById("chat_section");
    //     chat_section.scrollTop = chat_section.scrollHeight;
    //   }, 1000);
    // });

    //  check file size 
    // this.spinner.show();
    //  console.log('chatMediaUpload upload file now', this.sendData.message)

    // this.myProfileService.sendFilesWithCaptionForChat(frmData).subscribe(
    //   res => {
    //     //  console.log('this.sendData.message', this.sendData.message)
    //     this.fileUploadLoader = false;
    //     if (res && res.status) {
    //       this.fileUploadCount = 0;
    //       this.fileUploadLoader = false;
    //       this.isSentMessage = true;
    //       this.fileUpload = [];
    //       this.spinner.hide();
    //       // Socket(n)
    //       var socketData = {
    //         responce: sendMessagePost,
    //         type: 'message',
    //       };

    //       this.getChatUserList();

    //     } else {

    //       this.noChatOpen = true;
    //       this.getcontactListFunction();
    //     }
    //   }, (err) => {
    //     this.fileUploadLoader = false;
    //     if (err && err['error'] && err['error']['status_code'] == 401 && err['error']['message']) {
    //       this.toastrService.error(err['error']['message'], 'Error!');

    //       sessionStorage.removeItem('fullcomponent');

    //       localStorage.removeItem('package_id')
    //       localStorage.removeItem('register_id')
    //       localStorage.removeItem('Time-Zone')
    //       localStorage.removeItem('profile_ids')
    //       localStorage.removeItem('matchedProfilesCount')

    //     } else {
    //       if (err && err['message']) {
    //         this.toastrService.error("File not uploaded. You may have slow internet speed or uploaded media size is too large.", 'Error!');
    //       }
    //     }
    //   });
    //   check file size 
    // this.clicked = false;
  }

  showImage(image, index) {
    //  console.log("show image called");

    this.selectedCaptionIndex = index;
    if (this.hasIndex(this.captionUpload, index)) {
      var val = this.getValueAtIndex(this.captionUpload, index)
      this.f.description.setValue(val);
    } else {
      this.setValIndex('');
      this.f.description.setValue('');
    }
    // this.caption = '';
    //  
  }

  get f() {
    return this.wordCountForm.controls;
  }
  wordCounter(event) {
    let para = this.wordCountForm.value.description;
    if (para && para.trim().length >= 0) {
      this.wordCount = this.texts ? this.texts.nativeElement.value.split(/\s+/) : 0;
      this.words = this.wordCount ? this.wordCount.length : 0;
      if (this.words > 10 && event.code != 'Backspace' && event.code != 'Delete') {
        this.wordError();
        const pattern = /[a-zA-Z0-9&_\.!,-\s()"']/g;
        if (!pattern.test(event.target.value)) {
          event.preventDefault();
          return false;
        } else {
          this.wordCountForm.value.description + event.key
          event.target.value = event.target?.value.replace(/[a-zA-Z0-9&_\.!,-\s()']/g, "");
          this.tellUsMore = this.wordCountForm.value.description;
          this.tellUsMore = this.tellUsMore?.replace(/  +/g, ' ');
          this.wordCountForm.patchValue({ 'description': this.tellUsMore?.trim() + ' ' });
          this.words = this.tellUsMore?.split(/\s+/).length;
          this.wordError();
          event.preventDefault();
          return false;
        }
      } else if (this.words <= 10) {
        this.wordError();

        this.wordCountForm.value.description = this.wordCountForm.value.description + event.key;
        this.setValIndex(this.wordCountForm.value.description);
        //  console.log("this.wordCountForm.value.description 13", this.wordCountForm.value.description)
      } else {
        this.wordError();
        this.tellUsMore = this.wordCountForm.value.description;
      }
    } else {
      this.wordError();
      if (this.words <= 10 || this.words == undefined) {
        //  console.log("blur63 ", this.wordCountForm.value.description);
        this.wordCountForm.value.description = this.wordCountForm.value.description + event.key;
        this.setValIndex(this.wordCountForm.value.description);
        //  console.log("this.wordCountForm.value.description 12", this.wordCountForm.value.description)

      }
      this.words = 0;
    }



  }

  setValIndex(data) {
    this.updateOrSetAtIndex(this.captionUpload, this.selectedCaptionIndex, data);
  }
  wordError() {
    if (this.words > 10) {
      this.exceedLimit = true;
    } else {
      this.exceedLimit = false;
    }
  }
  pasteNumeric6(event) {
    setTimeout(() => {
      let para = this.wordCountForm.value.description;
      // if (this.selectedCaptionIndex) {
      //   this.captionUpload[this.selectedCaptionIndex] = this.caption
      // } else {
      //   this.captionUpload[0] = this.caption
      // }
      if (para.trim().length > 0) {
        this.wordCount = this.texts ? this.texts.nativeElement.value.split(/\s+/) : 0;
        this.words = this.wordCount ? this.wordCount.length : 0;
        if (this.words >= 10) {
          // event.target.value = "paste";
          this.wordCount = this.wordCount.slice(0, 10);
          this.words = this.wordCount ? this.wordCount.length : 0;
          this.wordCountForm.patchValue({ 'description': this.wordCount.join(" ") });
          this.wordError();
        }

      } else {
        this.wordError();
        this.words = 0;
      }
    }, 50);
  }
  pos = "";
  compare() {
    var pre = this.wordCount?.value?.description;
    //  console.log(pre, this.wordCount.value.description)
    setTimeout(() => {
      this.pos = this.wordCount?.value?.description;
      if (pre == this.pos) {


      }
    }, 50);
    if (pre == this.pos) {
      //  console.log("change", "out-time")
    }
  }


  saveCaption() {
    // var dd = {
    //   id: this.photoDetail.id,
    //   user_id: this.photoDetail.user_id,
    //   caption: this.caption.replace(/\s{2,}/g, ' ')
    // }
    //  console.log(this.caption, dd)

  }

  updateOrSetAtIndex(array: any[], index: number, value: any) {
    if (index >= 0 && index < array.length) {
      // If there's already a value at the specified index, update it
      array[index] = value;
    } else {
      // If the index is out of bounds, extend the array to include the index
      while (array.length <= index) {
        array.push('');
      }
      // Set the value at the specified index
      array[index] = value;
    }
  }

  hasIndex(array: any[], index: number): boolean {
    return index >= 0 && index < array.length;
  }

  getValueAtIndex(array: any[], index: number): any {
    if (index >= 0 && index < array.length) {
      return array[index];
    } else {
      return ''; // or handle out of bounds case as needed
    }
  }


  updateChatUserList() {
    var data = {
      user_id: this.user_id,
      search: this.searchUser,
      filter: this.chatFilter
    };
    this.userListRes = false;
    this.myProfileService.getChatUserList(data).subscribe(
      (result: any) => {
        this.userListRes = true;
        if (result.status) {
          this.chatUserList = [];
          this.chatUserList = result.chatUserList;
          this.chatUserListForwardTo = this.chatUserList;
          //  console.log("hello user 112", this.chatUserList.length, "this.chatUserList.length", this.activeUserId, "&& this.activeUserId == 0");

          if (this.chatUserList.length > 0 && this.activeUserId >= 0) { // what is the work of Active user id sir
            //  console.log("hello user ");
            let userFound = false;
            this.chatUserList.forEach(element => {

              if (element && element.last_chat && element.last_chat.updated_at != "" && element.last_chat.updated_at != null) {

                var convertedDate = this.convertDateFormat(element.last_chat.updated_at);
                var Tdate = new Date()
                var eDate = Tdate.toISOString()
                // Convert the date strings to Date objects
                const startDate = new Date(convertedDate); // result date 
                const endDate = new Date(eDate); /// todays date 
                //  console.log(startDate, "startDate", endDate, "endDate", Tdate, "tdate")
                // Calculate the time difference in milliseconds
                const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());

                // Convert the time difference to days
                const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
                // Calculate the difference in seconds, minutes, hours, and days
                const seconds = Math.floor(timeDiff / 1000);
                const minutes = Math.floor(seconds / 60);
                const hours = Math.floor(minutes / 60);
                var fd1 = this.datePipe.transform(startDate, 'medium');
                var fd2 = this.datePipe.transform(endDate, 'medium');

                //  console.log(hours ,"Hours ||" ,daysDiff ,"daysdiff||",  fd1 ,"Fd1 || fd2", fd2 )

                if (daysDiff < 8) {
                  var msgDate = '' //element['UTC'] = convertedTime;
                  var Gtime = this.addMinutesToDateString(element.last_chat.updated_at);
                  const formattedTime = this.convertToAMPM(Gtime);
                  element['msgTime'] = formattedTime

                  //  console.log(msgTime, "msgTime", element.id)
                  if (daysDiff == 1) {
                    const forstart = this.datePipe.transform(convertedDate, 'dd');
                    const forend = this.datePipe.transform(eDate, 'dd');
                    var checkD = parseInt(forend) - parseInt(forstart)
                    //  console.log(checkD) 
                    if (checkD == 0) {
                      msgDate = "Today"
                      element['msgDate'] = msgDate
                    } else {
                      msgDate = "Yesterday"
                      element['msgDate'] = msgDate
                    }
                  } else if (daysDiff == 2) {
                    msgDate = "Yesterday"
                    element['msgDate'] = msgDate

                  } if (daysDiff > 2) {
                    // Get the day of the week (0-6 where 0 is Sunday)
                    const dayOfWeek = startDate.getDay();
                    // Convert the day of the week to a string representation
                    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                    msgDate = days[dayOfWeek];
                    element['msgDate'] = msgDate
                  }
                  //  console.log(msgDate, "msg||", msgTime, "mstTime");
                } else {

                  // Format the timestamp
                  const forDate = this.datePipe.transform(convertedDate, 'dd/MM/yyyy');
                  //  console.log(, "fordate",timeDiff,'Days Difference: is more ', daysDiff ,startDate,"startDate.getTime() ||" ,endDate);
                  element['msgDate'] = forDate;

                }
              }
              if (this.activeUserId == element.user_id) {
                //  console.log('this.activeUserId', this.activeUserId)
                this.activeUser = element;
                userFound = true;
                //  console.log('this.activeUserId', this.activeUserId, "element.user_id =")

                if (element.unread_message > 0) {
                  //  console.log('getChatUserDetails element.user_id', element.user_id)
                }

              }
            });
            if (!userFound) {
              this.activeUserId = 0
            }
          } else if (this.chatUserList.length > 0) {
            this.activeSlideIndex = this.search_array(this.chatUserList, this.activeUserId);
            this.openMyChat(this.activeUserId, this.activeSlideIndex);
          }
        } else {
          this.toastrService.error(result.message);
        }
        // this.toastrService.success('Successfully!', 'Astro Details Update!');
      },
      (error) => {
        this.userListRes = true;
        // this.toastrService.error();
      }
    );
  }


}
