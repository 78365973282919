import { Injectable,Inject,  } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from './environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class CanonicalService {

  constructor(@Inject(DOCUMENT) private dom) { }

  baseURL:any;
//for canonical tage for every page by deeksha
full_roure_link: any = '';
setCanonicalURL(url?: string) {

  this.baseURL = environment.baseURL;

  var uC = this.dom.querySelectorAll("link[rel='canonical']")[0];

  const canURL = url == undefined ? this.dom.URL : url;
  const link: HTMLLinkElement = this.dom.createElement('link');

  this.full_roure_link = canURL.replace("http://", "https://");

  link.setAttribute('href', this.full_roure_link);
  link.setAttribute('rel', 'canonical');

  if (uC == undefined) {
    this.dom.head.appendChild(link);
  } else {
    uC.setAttribute("href", this.full_roure_link);
  }
  // console.log('Setting canonical URL');
}

}
