import { Component, OnInit, ViewChild/*, ViewEncapsulation */} from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm, Validators, FormControl } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('signInNgForm') signInNgForm: NgForm;


  errorMessage: string;
  errors: any;
  signInForm= new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required),
  })
  
  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    ) { }

  ngOnInit(): void {
    // Create the form
    // this.signInForm = this.formBuilder.group({
    //   email: ['', [Validators.required, Validators.email]],
    //   password: ['', Validators.required],
    // });
  }

  get email() { return this.signInForm.get('email'); }
  get password() { return this.signInForm.get('password'); }

  login() {
    this.errors = false;
    this.authService.login(this.signInForm.value.email, this.signInForm.value.password)
      .subscribe((res: any) => {
        // Store the access token in the localstorage
        localStorage.setItem('access_token', res.access_token);
        // store value for free member upgrade popup
        // localStorage.setItem('Member', 'Free');
        // Navigate to home page
        this.router.navigate(['myProfile']);
      }, (err: any) => {
        // console.log(err);
        // This error can be internal or invalid credentials
        this.errors = true;
        this.errorMessage = err.error.message;
      });
  }

}
