import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RegisterNxtService {
  private registerTwoUrl =`${environment.apiURL}/auth/registerTwo`;
  private getUserdetailsUrl =`${environment.apiURL}/auth/getUserDetails`;


  constructor(
    private http: HttpClient
  ) { }
  registerTwo(user: any): Observable<any> {
    return this.http.post(this.registerTwoUrl, user);
  }
  getUserDetails(user:any): Observable<any> {
    return this.http.post(this.getUserdetailsUrl, user);
  }
}
