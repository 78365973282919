import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

// User interface
export class User {
  name!: String;
  email!: String;
  password!: String;
  password_confirmation!: String;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {


  private loginUrl = `${environment.apiURL}/auth/login`;
  private logoutUrl = `${environment.apiURL}/auth/logout`;
  private profileUserUrl =`${environment.apiURL}/auth/user-profile`;
  private registerUrl =`${environment.apiURL}/auth/register`;
  private getCountryListUrl= `${environment.apiURL}/auth/getCountryList`;
  private getStateListUrl= `${environment.apiURL}/auth/getStateList`;
  private getIsUserVaildUrl= `${environment.apiURL}/auth/isUrlActive`;
  private isChangeActiveURL= `${environment.apiURL}/auth/isChangeActiveURL`;
  private changepasswordUrl= `${environment.apiURL}/auth/changePassword`;
  private resetpasswordUrl= `${environment.apiURL}/auth/forgotPassword`;
  private forgotPasswordUrl= `${environment.apiURL}/auth/forgotPasswordSendMail`;//forgotPasswordSendSMS
  private forgotPasswordSendSMSUrl= `${environment.apiURL}/auth/forgotPasswordSendSMS`;


  constructor(private http: HttpClient) {}
  // User registration
  register(user: User): Observable<any> {
    return this.http.post(this.registerUrl, user);
  }
  // Login
  signin(user: User): Observable<any> {
    return this.http.post<any>(this.loginUrl, user);
  }
   // Logout
   logout(): Observable<any> {
    return this.http.post<any>(this.logoutUrl, '');
  }
  // Access user profile
  profileUser(): Observable<any> {
    return this.http.get(this.profileUserUrl);
  }

  // get Country list
  getCountryList(): Observable<any> {
    return this.http.get(this.getCountryListUrl);
  }
   // get State list
   getStateList(user:any): Observable<any> {
    return this.http.post<any>(this.getStateListUrl,user);
  }
  // get url is valid or not 
  getIsUserVaild(user:any): Observable<any> {
    return this.http.post<any>(this.getIsUserVaildUrl,user);
  }
  isChangeActive(user:any): Observable<any> {
    return this.http.post<any>(this.isChangeActiveURL,user);
  }

  changePassword(user:any): Observable<any> {
    return this.http.post(this.changepasswordUrl,user);
  }
  resetPassword(user:any): Observable<any> {
    return this.http.post(this.resetpasswordUrl,user);
  }
  forgotPasswordSendMail(user: User): Observable<any> {
    return this.http.post(this.forgotPasswordUrl,user);
  }
  
  forgotPasswordSendSMS(user: User): Observable<any> {
    return this.http.post(this.forgotPasswordSendSMSUrl,user);
  }
  
  contactUs(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/contactUs`, user);
  }

  verifyByOtp(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/verifyByOtp`, user);
  }

  ResendOneTimePassword(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/ResendOneTimePassword`, user);
  }

  tryAnotherWay(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/tryAnotherWay`, user);
  }

  searchRecords(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/searchRecords`, user);
  }


  contactAdmin(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/contactAdmin`, user);
  }

}