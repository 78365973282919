import { Component, OnInit, ViewChild/*, ViewEncapsulation*/, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm, } from '@angular/forms';
import { AuthService } from '../shared/auth.service';
import { PartnerPreferenceService } from '../services/partner-preference.service';
import { CookieService } from 'ngx-cookie-service';
import { RegisterService } from '../services/register.service';
import * as $ from 'jquery';
import { ViewportScroller } from "@angular/common";
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-partner-preferences',
  templateUrl: './partner-preferences.component.html',
  styleUrls: ['./partner-preferences.component.css']
})
export class PartnerPreferencesComponent implements OnInit {
  @ViewChild('partnerPreferenceForm')
  partnerPreferenceNgForm: NgForm;

  package_id: any;
  submitted = false;
  buttonDisable: boolean = false;
  @ViewChild("text") text: ElementRef;
  wordCount: any;
  words: any;
  partnerPreferenceForm = new FormGroup({
    user_id: new FormControl(''),
    looking_for: new FormControl('', [Validators.required]),
    age_from: new FormControl('', Validators.required),
    age_to: new FormControl('', Validators.required),
    height_from: new FormControl('', Validators.required),
    height_to: new FormControl('', Validators.required),
    weight_from: new FormControl(''),
    weight_to: new FormControl(''),
    living_with_family: new FormControl(''),
    family_type: new FormControl(''),
    marital_status: new FormControl(''),
    have_children: new FormControl(''),
    boy_child: new FormControl(''),
    boy_child_not_married: new FormControl(''),
    boy_child_married: new FormControl(''),
    girl_child: new FormControl(''),
    girl_child_not_married: new FormControl(''),
    girl_child_married: new FormControl(''),
    religion: new FormControl(''),
    otherReligion: new FormControl(''),
    languages: new FormControl(''),
    languagesOther: new FormControl(''),
    country_name: new FormControl(''),
    state_name: new FormControl(''),
    city_name: new FormControl(''),
    residency_status: new FormControl(''),
    residencyOther: new FormControl(''),
    education: new FormControl(''),
    educationOther: new FormControl(''),
    nature_of_work: new FormControl(''),
    workOther: new FormControl(''),
    profession_area: new FormControl(''),
    professionOther: new FormControl(''),
    hide_income: new FormControl(''),
    annual_income: new FormControl('', Validators.required),
    income_currency: new FormControl('', Validators.required),
    income_range_from: new FormControl('', Validators.required),
    income_range_to: new FormControl('', Validators.required),
    food_preference: new FormControl(''),
    otherFood: new FormControl(''),
    hobbies: new FormControl(''),
    description: new FormControl('', Validators.required),
  })

  errorMessage: string;
  errors: any;
  dropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings = {};

  dropdownList2: any = [];
  selectedItems2: any = [];
  dropdownSettings2 = {};
  countries: any;
  states: any;
  status_single = false;
  haveChildren = false;
  boyChild = true;
  girlChild = true;
  jsonObj: any;
  incomeType: boolean = false;
  foodPreference: boolean = false;
  otherReligion: boolean = false;
  otherLanguage: boolean = false;
  otherProfessionArea: boolean = false;
  phoneCode: any;
  currencyCode: any;
  country: any;
  age_from: any = [];
  age_to: any = [];
  notWorking: boolean;
  weight_from: any = [];
  weight_to: any = [];
  height_from: any = [];
  height_to: any = [];
  name: any;
  incomeRange: boolean;
  languages: any = [];
  languagesOther: any = [];

  requiredLanguage: boolean = false;
  showState: boolean = false;
  checkLanguages: boolean = false;
  religion: any = [];
  requiredreligion: boolean = false;
  checkreligion: boolean = false;
  maritalStatus: any = [];
  requiredMaritalStatus: boolean = false;
  maritalStatusCheck: boolean = false;
  rStatus: any = [];
  requiredRStatus: boolean = false;
  rStatusCheck: boolean = false;
  risedencyStatusRequired: boolean = false;
  natureOfWorkRequired: boolean = false;
  maritalStatusRequired: boolean = false;
  residencyCheck: boolean = false;
  education: any = [];
  educationCheck: boolean = false;
  educationRequired: boolean = false;
  natureOfWork: any = [];
  workRequired: boolean = false;
  workCheck: boolean = false;
  profession: any = [];
  professionRequired: boolean = false;
  professionCheck: boolean = false;
  familyType: any = [];
  isFamilyType: boolean = false;
  foodPreferences: any = [];
  foodRequired: boolean = false;
  foodCheck: boolean = false;
  exceedLimit: boolean = false;
  disable_looking_for: boolean = true;

  fT1: boolean = false;
  fT2: boolean = false;
  fT3: boolean = false;
  fT4: boolean = false;
  relOther: any = [];
  resOther: any = [];
  eduOther: any = [];
  natOther: any = [];
  proOther: any = []
  fodAr: any = [];
  tellUsMore: any;
  looking_for: string = '';

  constructor(

    private _formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private partnerPreferenceService: PartnerPreferenceService,
    private cookieService: CookieService,
    private registerService: RegisterService,
    private scroller: ViewportScroller,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    for (let i = 18; i <= 90; i++) {
      this.age_from.push({ id: i });
    }
    for (let i = 100; i <= 230; i++) {
      this.height_from.push({ i });
    }
    for (let i = 32; i <= 114; i++) {
      this.weight_from.push({ i });
    }
    this.authService.getCountryList().subscribe(
      (result) => {
        this.countries = result.data;
        this.countries.push({
          "fullnumber": "Any",
          "id": 0,
          "country_code": "",
          "country_name": "Any",
          "short_name": "ANY",
          "currency": "",
          "currency_name": ""
        })
        // before sonar
        // if (this.countries && this.countries[0] && this.countries[0].id) {
        // after sonar
        if (this.countries?.[0]?.id) {
          this.phoneCode = this.countries[0].id;
          this.currencyCode = this.countries[0].id;
        }

        let dd = this.cookieService.get('user_id');
        this.f.user_id.setValue(dd);
        let data = {
          user_id: dd
        }
        this.registerService.getUserDetails(data)
          .subscribe((result: any) => {
            if (result?.userDetails) {
              let register_val = result.userDetails;
              let register_id = register_val.register_done;
              this.package_id = result.userDetails.package_id;
              console.log(this.package_id, 'this.package_id');
              localStorage.setItem('register_id', register_id);
              localStorage.setItem('package_id1', this.package_id);

              if (register_val == "Self") {
                this.name = "";
              } else {
                this.name = "for " + register_val.first_name + " " + register_val.last_name
              }
              if (register_val.gender == "Male") {
                this.looking_for = "Female";
              } else if (register_val.gender == "Female") {
                this.looking_for = "Male";
              } else {
                this.looking_for = "";
              }
              if (this.looking_for != "") {
                this.disable_looking_for = true
                this.f.looking_for.setValue(this.looking_for);
              } else {
                this.disable_looking_for = false
              }

              if (register_val.register_done == 7) {
                this.partnerPreferenceService.getPartnerDetails(data)
                  .subscribe((res: any) => {
                    if (res.error == "User details not registered") {
                      // document why this block is empty
                    } else {
                      if (res?.userDetails) {
                        let detail = res.userDetails;
                        // detail = ''; remove blank for testing

                        if (detail.country_name != null) {
                          if (detail.country_name != 'Any') {
                            this.showState = true;
                          } else {
                            this.showState = false;
                            this.authService.getStateList({ id: detail.country_name }).subscribe(
                              (result) => {
                                this.states = result.data;
                                if (result.data.length > 0) {
                                  this.country = result.data[0]['country_id'];
                                }
                              });
                          }
                          this.f.user_id.setValue(dd);
                          this.f.looking_for.setValue(this.looking_for);
                          this.f.age_from.setValue(detail.age_from);
                          this.f.age_to.setValue(detail.age_to);
                          this.f.height_from.setValue(detail.height_from);
                          this.f.height_to.setValue(detail.height_to);
                          this.f.weight_from.setValue(detail.weight_from);
                          this.f.weight_to.setValue(detail.weight_to);
                          if (this.looking_for != '') {
                            this.f.looking_for.setValue(this.looking_for);
                          } else if (detail.looking_for && detail.looking_for != null) {
                            this.f.looking_for.setValue(detail.looking_for);
                          }
                          for (let i = detail.age_from; i <= 90; i++) {
                            this.age_to.push({ id: i });
                          }
                          for (let i = detail.height_from; i <= 230; i++) {
                            this.height_to.push({ i });
                          }
                          for (let i = detail.weight_from; i <= 114; i++) {
                            this.weight_to.push({ i });
                          }

                          if (detail.living_with_family != "") {
                            if (detail.living_with_family == "Yes") {
                              this.f.living_with_family.setValue("Yes")
                              this.isFamilyType = true;
                            } else if (detail.living_with_family == "No") {
                              this.f.living_with_family.setValue("No")
                            } else {
                              this.f.living_with_family.setValue(detail.living_with_family)
                            }
                          }
                          if (detail?.family_type) {
                            let FTArr = detail.family_type.split(',');
                            FTArr.forEach((element: any) => {

                              if (element != '') {
                                setTimeout(() => {

                                  if (element == "Extended Family") {
                                    this.familyType.push("Extended Family");

                                    $('#ExtendedFamily').prop('checked', true);
                                    this.fT1 = true
                                    // this.f.family_type.setValue("ExtendedFamily")
                                  } else if (element == "Immediate Family") {
                                    this.familyType.push("Immediate Family");
                                    $('#ImmediateFamily').prop('checked', true);
                                    this.fT2 = true
                                  } else if (element == "Joint Family") {
                                    this.familyType.push("Joint Family");
                                    $('#JointFamily').prop('checked', true);
                                    this.fT3 = true
                                  } else if (element == "Nuclear Family") {
                                    this.familyType.push(element);
                                    $('#NuclearFamily').prop('checked', true);
                                    this.fT4 = true
                                  } else {
                                    // unused code
                                    //   console.log(this.familyType,"esle condition");
                                  }
                                }, 50);

                              }
                            });
                          }
                          if (detail?.religion) {
                            let religionArr = detail.religion.split(',');
                            this.religion = [];
                            religionArr.forEach((element: any) => {
                              //// // console.log(element);
                              if (element != '') {
                                if (element == "Buddhist") {
                                  this.religion.push("Buddhist");
                                  $('#Buddhist').prop('checked', true);
                                } else if (element == "Christian") {
                                  this.religion.push("Christian");
                                  $('#Christian').prop('checked', true);
                                } else if (element == "Hindu") {
                                  this.religion.push("Hindu");
                                  $('#Hindu').prop('checked', true);
                                } else if (element == "Parsi") {
                                  this.religion.push("Parsi");
                                  $('#Parsi').prop('checked', true);
                                } else if (element == "Jain") {
                                  this.religion.push("Jain");
                                  $('#Jain').prop('checked', true);
                                } else if (element == "Muslim") {
                                  this.religion.push("Muslim");
                                  $('#Muslim').prop('checked', true);
                                } else if (element == "Sikh") {
                                  this.religion.push("Sikh");
                                  $('#Sikh').prop('checked', true);
                                } else if (element == "No Religion") {
                                  this.religion.push("No Religion");
                                  $('#NoReligion').prop('checked', true);
                                } else if (element == "Any Religion") {
                                  this.religion.push("Any Religion");
                                  $('#AnyReligion').prop('checked', true);
                                } else {
                                  this.relOther.push(element);
                                  this.checkreligion = true;
                                }
                              }
                            });
                            let other1 = this.relOther.toString()
                            this.f.otherReligion.setValue(other1);
                          }
                          if (detail?.residency_status) {
                            let rStatusArr = detail.residency_status.split(',');
                            this.rStatus = [];
                            rStatusArr.forEach((element: any) => {
                              if (element != '') {
                                if (element == "Citizen") {
                                  this.rStatus.push("Citizen");
                                  $('#Citizen').prop('checked', true);
                                } else if (element == "Work Permit") {
                                  this.rStatus.push("Work Permit");
                                  $('#WorkPermit').prop('checked', true);
                                } else if (element == "Permanent Resident") {
                                  this.rStatus.push("Permanent Resident");
                                  $('#PermanentResident').prop('checked', true);
                                } else if (element == "Student Visa") {
                                  this.rStatus.push("Student Visa");
                                  $('#StudentVisa').prop('checked', true);
                                } else if (element == "Visitor Visa") {
                                  this.rStatus.push("Visitor Visa");
                                  $('#VisitorVisa').prop('checked', true);
                                } else {
                                  this.residencyCheck = true;
                                  this.resOther.push(element);
                                }
                              }
                              let other01 = this.resOther.toString()
                              this.f.residencyOther.setValue(other01);
                            });

                          }
                          if (detail?.languages) {
                            let languagesArr = detail.languages.split(',');
                            this.languages = [];
                            this.languagesOther = [];

                            languagesArr.forEach((element: any) => {
                              if (element != '') {
                                if (element == "Bengali") {
                                  this.languages.push("Bengali");
                                  $('#Bengali').prop('checked', true);
                                } else if (element == "English") {
                                  this.languages.push("English");
                                  $('#English').prop('checked', true);
                                } else if (element == "Gujarati") {
                                  this.languages.push("Gujarati");
                                  $('#Gujarati').prop('checked', true);
                                } else if (element == "Hindi") {
                                  this.languages.push("Hindi");
                                  $('#Hindi').prop('checked', true);
                                } else if (element == "Marathi") {
                                  this.languages.push("Marathi");
                                  $('#Marathi').prop('checked', true);
                                } else if (element == "Punjabi") {
                                  this.languages.push("Punjabi");
                                  $('#Punjabi').prop('checked', true);
                                } else if (element == "Telugu") {
                                  this.languages.push("Telugu");
                                  $('#Telugu').prop('checked', true);
                                } else if (element == "Tamil") {
                                  this.languages.push("Tamil");
                                  $('#Tamil').prop('checked', true);
                                } else if (element == "Any Language") {
                                  this.languages.push("Any Language");
                                  $('#AnyLanguage').prop('checked', true);
                                } else {
                                  this.languagesOther.push(element);
                                  this.checkLanguages = true;
                                }
                              }
                            });
                            let otherD = this.languagesOther.toString()
                            this.f.languagesOther.setValue(otherD.trim());
                            //console.log(otherD)
                          }

                          if (detail?.food_preference) {
                            let food_preferenceArr = detail.food_preference.split(',');
                            this.foodPreferences = [];
                            food_preferenceArr.forEach((element: any) => {
                              //// // console.log(element);
                              if (element != '') {
                                if (element == "Veg") {
                                  this.foodPreferences.push(element);
                                  $('#Veg').prop('checked', true);
                                } else if (element == "Non Veg") {
                                  this.foodPreferences.push(element);
                                  $('#NonVeg').prop('checked', true);
                                } else if (element == "Occasionally Non-Veg") {
                                  this.foodPreferences.push(element);
                                  $('#Occasionally').prop('checked', true);
                                } else if (element == "Eggetarian") {
                                  this.foodPreferences.push(element);
                                  $('#Eggetarian').prop('checked', true);
                                } else if (element == "Pescatarian") {
                                  this.foodPreferences.push(element);
                                  $('#Pescatarian').prop('checked', true);
                                } else if (element == "Jain") {
                                  this.foodPreferences.push(element);
                                  $('#JainFood').prop('checked', true);
                                } else if (element == "Vegan") {
                                  this.foodPreferences.push(element);
                                  $('#Vegan').prop('checked', true);
                                } else if (element == "Does Not Matter") {
                                  this.foodPreferences.push(element);
                                  $('#AnyFood').prop('checked', true);
                                  this.foodPreferences = [];
                                  this.foodPreferences.push(element);
                                } else {
                                  this.foodCheck = true;
                                  this.foodPreference = true;
                                  this.fodAr.push(element);
                                }
                              }
                              let other12 = this.fodAr.toString()
                              this.f.otherFood.setValue(other12);
                            });
                          }
                          if (detail?.marital_status) {
                            let maritalsArr = detail.marital_status.split(',');
                            this.maritalStatus = [];
                            maritalsArr.forEach((element: any) => {
                              if (element != '') {
                                if (element == "Annulled") {
                                  this.maritalStatus.push("Annulled");
                                  $('#Annulled').prop('checked', true);
                                  this.status_single = false;
                                } else if (element == "Awaiting Divorce") {
                                  this.maritalStatus.push("Awaiting Divorce");
                                  $('#AwaitingDivorce').prop('checked', true);
                                } else if (element == "Divorced") {
                                  this.maritalStatus.push("Divorced");
                                  $('#Divorced').prop('checked', true);
                                } else if (element == "Single") {
                                  this.maritalStatus.push("Single");
                                  $('#Single').prop('checked', true);
                                } else if (element == "Widowed") {
                                  this.maritalStatus.push("Widowed");
                                  $('#Widowed').prop('checked', true);
                                } else if (element == "Any Of These") {
                                  this.maritalStatus.push("Any Of These");
                                  $('#AnyOfThese').prop('checked', true);
                                }
                              }
                            });
                          }
                          this.f.have_children.setValue(detail.have_children);
                          if (detail.have_children == "Yes") {
                            this.haveChildren = true;
                            if (detail.boy_child == 1) {
                              this.f.boy_child.setValue(detail.boy_child);
                              this.boyChild = false;
                              this.f.boy_child_not_married.setValue(detail.boy_child_not_married);
                              this.f.boy_child_married.setValue(detail.boy_child_married);
                            }
                            if (detail.girl_child == 1) {
                              this.f.girl_child.setValue(detail.girl_child);
                              this.girlChild = false;
                              this.f.girl_child_not_married.setValue(detail.girl_child_not_married);
                              this.f.girl_child_married.setValue(detail.girl_child_married);
                            }
                          } else {
                            this.haveChildren = false;
                            if (detail.have_children == 'does not matter') {
                              this.f.have_children.setValue('Does Not Matter');
                            }
                          }


                          this.f.country_name.setValue(detail.country_name);
                          this.f.state_name.setValue(detail.state_name);
                          this.f.city_name.setValue(detail.city_name);
                          if (detail?.education) {
                            let educationArr = detail.education.split(',');
                            this.education = [];
                            educationArr.forEach((element: any) => {
                              if (element != '') {
                                if (element == "High School") {
                                  this.education.push("High School");
                                  $('#HighSchool').prop('checked', true);
                                } else if (element == "Some College") {
                                  this.education.push("Some College");
                                  $('#SomeCollege').prop('checked', true);
                                } else if (element == "Some University") {
                                  this.education.push("Some University");
                                  $('#SomeUniversity').prop('checked', true);
                                } else if (element == "Associate`s Degree") {
                                  this.education.push("Associate`s Degree");
                                  $('#AssociateDegree').prop('checked', true);
                                } else if (element == "Bachelor`s Degree") {
                                  this.education.push("Bachelor`s Degree");
                                  $('#BachelorDegree').prop('checked', true);
                                } else if (element == "Master`s Degree") {
                                  this.education.push("Master`s Degree");
                                  $('#MasterDegree').prop('checked', true);
                                } else if (element == "Graduate Degree") {
                                  this.education.push("Graduate Degree");
                                  $('#GraduateDegree').prop('checked', true);
                                } else if (element == "PhD/Post Doctorate") {
                                  this.education.push("PhD/Post Doctorate");
                                  $('#PostDoctoral').prop('checked', true);
                                } else if (element == "Does Not Matter") {
                                  this.education.push("Does Not Matter");
                                  $('#NotMatter').prop('checked', true);
                                } else {
                                  this.educationCheck = true;
                                  this.eduOther.push(element);
                                  this.f.educationOther.setValue(element);
                                }
                              }
                            });
                            let other03 = this.eduOther.toString()
                            this.f.educationOther.setValue(other03);
                          }
                          if (detail?.nature_of_work) {
                            let workArr = detail.nature_of_work.split(',');
                            this.natureOfWork = [];
                            workArr.forEach((element: any) => {
                              //// // console.log(element);
                              if (element != '') {
                                if (element == "Business / Self Employed" || element == "Business/Self Employed") {
                                  this.natureOfWork.push("Business/Self Employed");
                                  $('#BusinessSelf').prop('checked', true);
                                } else if (element == "Government Company / Public Sector" || element == "Government Company/Public Sector") {
                                  this.natureOfWork.push("Government Company/Public Sector");
                                  $('#GovernmentPublic').prop('checked', true);
                                } else if (element == "Private Company") {
                                  this.natureOfWork.push("Private Company");
                                  $('#PrivateCompany').prop('checked', true);
                                } else if (element == "Not Working") {
                                  this.natureOfWork.push("Not Working");
                                  $('#NotWorking').prop('checked', true);
                                } else if (element == "Does Not Matter") {
                                  this.natureOfWork.push(element);
                                  $('#AnyWork').prop('checked', true);
                                } else {
                                  this.workCheck = true;
                                  this.natOther.push(element);
                                  this.f.workOther.setValue(element);
                                }
                              }
                            }); let other04 = this.natOther.toString()
                            this.f.workOther.setValue(other04);
                          }
                          if (detail?.profession_area) {
                            let professionArr = detail.profession_area.split(',');
                            this.profession = [];
                            professionArr.forEach((element: any) => {
                              //// // console.log(element);
                              if (element != '') {
                                if (element == "Accounting - Banking & Finance" || element == "Accounting") {
                                  this.profession.push("Accounting - Banking & Finance");
                                  $('#Accounting').prop('checked', true);
                                } else if (element == "Administration & HR") {
                                  this.profession.push("Administration & HR");
                                  $('#Administration').prop('checked', true);
                                } else if (element == "Airline & Aviation") {
                                  this.profession.push("Airline & Aviation");
                                  $('#Airline').prop('checked', true);
                                } else if (element == "Agriculture") {
                                  this.profession.push("Agriculture");
                                  $('#Agriculture').prop('checked', true);
                                } else if (element == "Beauty - Fashion & Jewellery Designers" || element == "Beauty & Fashion") {
                                  this.profession.push("Beauty - Fashion & Jewellery Designers");
                                  $('#Jewellery').prop('checked', true);
                                } else if (element == "Sales & Marketing" || element == "Sales Marketing") {
                                  this.profession.push("Sales & Marketing");
                                  $('#SalesMarketing').prop('checked', true);
                                } else if (element == "Merchant Navy") {
                                  this.profession.push("Merchant Navy");
                                  $('#MerchantNavy').prop('checked', true);
                                } else if (element == "Does Not Matter") {
                                  this.profession.push("Merchant Navy");
                                  $('#AnyProfession').prop('checked', true);
                                } else {
                                  this.professionCheck = true;
                                  this.proOther.push(element);
                                }
                              }
                            }); let other05 = this.proOther.toString()
                            this.f.professionOther.setValue(other05);
                          }
                          if (detail?.hide_income) {

                            this.f.hide_income.setValue(detail.hide_income);
                          }
                          if (detail?.annual_income) {
                            this.f.annual_income.setValue(detail.annual_income);
                            // income
                            if (detail.annual_income == "SpecifyRange") {
                              this.incomeType = true;
                              if (detail?.income_currency != '') {
                                if (this.countries) {
                                  this.countries.forEach(country => {
                                    if (detail.income_currency == country.id) {
                                      this.currencyCode = country.id;
                                    }
                                  }
                                  )
                                }
                                else {
                                  this.authService.getCountryList().subscribe(
                                    (result) => {
                                      this.countries = result.data;
                                      this.countries.push({
                                        "fullnumber": "Any",
                                        "id": 0,
                                        "country_code": "",
                                        "country_name": "Any",
                                        "short_name": "ANY",
                                        "currency": "",
                                        "currency_name": ""
                                      })
                                      this.countries.forEach(country => {
                                        if (detail.income_currency == country.id) {
                                          this.currencyCode = country.id;
                                        }
                                      })
                                    }
                                  )
                                }
                              }
                              this.f.income_range_from.setValue(detail.income_range_from);
                              this.f.income_range_to.setValue(detail.income_range_to);
                            } else {

                              this.f.income_currency.setValue("Other");
                              this.f.income_range_from.setValue(0);
                              this.f.income_range_to.setValue(0);
                            }
                          }
                          if (detail.description != " ") {
                            this.f.description.setValue(detail.description);
                            this.words = this.partnerPreferenceForm.value.description.split(/\s+/).length;
                          }
                        } else {
                          console.log('detail.country_name', detail.country_name)
                        }
                      }
                    }
                  });
              } else /*{ before sonar uncomment*/ if (this.countries?.[0]?.id) {
                  this.authService.getStateList({ id: this.countries[0].id }).subscribe(
                    (result) => {
                      this.states = result.data;
                      this.country = result.data[0]['country_id']
                    }
                  );
                }

             // }
            }
          })


      });
  }


  getCountryList() {
    this.authService.getCountryList().subscribe(
      (result) => {
        this.countries = result.data;
        this.countries.push({
          "fullnumber": "Any",
          "id": 0,
          "country_code": "",
          "country_name": "Any",
          "short_name": "ANY",
          "currency": "",
          "currency_name": ""
        })
        console.log(this.countries);
      },
      (error) => {

      },
      () => {
      }
    );
  }


  onChangeCountry(event: any) {
    const cid = this.partnerPreferenceForm.value.country_name;
    let data = {
      id: cid,
    }
    // console.log(cid,"cid")
    if (cid != 0) {
      this.getStateList(data);
      this.showState = true;
    } else {
      this.f.state_name.setValue("Any");
      this.showState = false;
      this.states = [];
      //this.country =result.data[0]['country_id']
    }
  }

  getStateList(data) {

    this.authService.getStateList(data).subscribe(
      (result) => {
        this.states = result.data;
        this.country = result.data[0]['country_id']
      }
    );
  }

  checkProfessionArea() {
    if (this.partnerPreferenceForm.value.profession_area == "Other") {
      this.f.profession_area.setValue("");
      this.otherProfessionArea = true;
    } else {
      this.otherProfessionArea = false;
    }
  }
  checkLanguage() {
    if (this.partnerPreferenceForm.value.languages == "OtherLanguages") {
      this.f.languages.setValue("");
      this.otherLanguage = true;
    } else {
      this.otherLanguage = false;
    }
  }

  checkReligion() {
    if (this.partnerPreferenceForm.value.religion == "Other") {
      this.f.religion.setValue("");
      this.otherReligion = true;
    } else {
      this.otherReligion = false;
    }
  }
  checkMaritalStatus() {
    // // // console.log('hello')
    if (this.partnerPreferenceForm.value.marital_status == "Single" || this.partnerPreferenceForm.value.marital_status == "Annulled") {
      this.status_single = false;
      this.haveChildren = false;
      this.f.have_children.setValue("");
      this.f.boy_child.setValue("");
      this.f.girl_child.setValue("");
      this.f.boy_child_not_married.setValue("");
      this.f.boy_child_married.setValue("");
      this.f.girl_child_not_married.setValue("");
      this.f.girl_child_married.setValue("");

    } else {
      this.status_single = true;

    }
  }
  checkHaveChildren() {
    // // // console.log('hello')
    if (this.partnerPreferenceForm.value.have_children == "Yes") {
      this.haveChildren = true;
    } else {
      this.haveChildren = false;
      this.f.boy_child.setValue("");
      this.f.girl_child.setValue("");
      this.f.boy_child_not_married.setValue("");
      this.f.boy_child_married.setValue("");
      this.f.girl_child_not_married.setValue("");
      this.f.girl_child_married.setValue("");
    }
  }

   //before  sonar
  // checkBoyChild() {
  //   if (this.partnerPreferenceForm.value.boy_child == true) {
  //     this.boyChild = false;
  //   } else {
  //     this.boyChild = true;
  //     this.f.boy_child_not_married.setValue("");
  //     this.f.boy_child_married.setValue("");
  //   }
  // }

   //after sonar
  checkBoyChild() {
    const boyChildValue = this.partnerPreferenceForm.value.boy_child 
    if (boyChildValue === true) {
      this.boyChild = false;
    } else {
      this.boyChild = true;
      this.f.boy_child_not_married.setValue("");
      this.f.boy_child_married.setValue("");
    }
  }

  // before sonar
  // checkGirlChild() {
  //   if (this.partnerPreferenceForm.value.girl_child == true) {
  //     this.girlChild = false;
  //   } else {
  //     this.girlChild = true;
  //     this.f.girl_child_not_married.setValue("");
  //     this.f.girl_child_married.setValue("");
  //   }
  // }

  // after sonar
  checkGirlChild() {
    const girlChildValue = this.partnerPreferenceForm.value.girl_child;
    if (girlChildValue === true) {
      this.girlChild = false;
    } else {
      this.girlChild = true;
      this.f.girl_child_not_married.setValue("");
      this.f.girl_child_married.setValue("");
    }
  }


  get f() {
    return this.partnerPreferenceForm.controls;
  }


  CheckFood() {
    if (this.partnerPreferenceForm.value.food_preference == "Other") {
      this.f.food_preference.setValue("");
      this.foodPreference = true;
    } else {
      this.foodPreference = false;
    }
  }

  checkIncomeType() {
    if (this.partnerPreferenceForm.value.annual_income == "OpenToAll" || this.partnerPreferenceForm.value.annual_income == "Open To All") {
      this.incomeType = false;
      this.f.income_currency.setValue("other");
      this.f.income_range_from.setValue(0);
      this.f.income_range_to.setValue(0);

    } else {
      this.incomeType = true;
      this.f.income_currency.setValue('');
      this.f.income_range_from.setValue('');
      this.f.income_range_to.setValue('');
    }
  }
  checkAge() {

    if (this.partnerPreferenceForm.value.age_to <= this.partnerPreferenceForm.value.age_from) {
      this.f.age_from.setValue(18);
    }
  }
  checkHeight() {
    if (this.partnerPreferenceForm.value.height_to <= this.partnerPreferenceForm.value.height_from) {
      this.f.height_from.setValue(152)
    }

  }
  checkWeight() {
    if (this.partnerPreferenceForm.value.weight_to <= this.partnerPreferenceForm.value.weight_from) {
      this.f.weight_from.setValue(50);
    }
  }

  NatureOfWork() {
    let workVal = this.f.nature_of_work.value
    // // // console.log()
    if (workVal == "Not Working") {
      // // // console.log('hello not work');
      this.notWorking = false;
      this.f.profession_area.setValue("");
      this.f.annual_income.setValue("");
      this.f.income_currency.setValue("other");
      this.f.income_range_from.setValue(0);
      this.f.income_range_to.setValue(0);
      this.f.hide_income.setValue(true);
    } else {
      this.notWorking = true;
    }
  }

  fieldsCheck() {
    // unused code
    //  if(this.maritalStatus ==''){
    //    this.maritalStatusRequired = true;
    //    if(this.religion==""){
    //     this.requiredreligion=true;
    //     if(this.languages==""){
    //       this.requiredLanguage=true;
    //       if(this.rStatus == ""){
    //         this.risedencyStatusRequired =true;
    //         return false;
    //        }else {
    //         return true;
    //        }
    //      }else {
    //       return false;
    //      }
    //    }else {
    //     return false;
    //    }
    //  }else {
    //   return false;
    //  }
  }

  submit() {
    this.submitted = true;
    if (this.partnerPreferenceForm.invalid) {
      this.checkOtherOption()
      this.scrollToFirstInvalidControl();
      console.log()
      return;
    }

    if (this.checkLanguages) {
      if (this.partnerPreferenceForm.value.languagesOther != '') {
        this.languages.push(this.partnerPreferenceForm.value.languagesOther.trim());
      }
    }
    if (this.checkreligion) {
      if (this.partnerPreferenceForm.value.otherReligion != '') {
        this.religion.push(this.partnerPreferenceForm.value.otherReligion.trim());
      }
    }
    if (this.workCheck) {
      if (this.partnerPreferenceForm.value.workOther != '') {
        this.natureOfWork.push(this.partnerPreferenceForm.value.workOther.trim());
      }
    }
    if (this.educationCheck) {
      if (this.partnerPreferenceForm.value.educationOther != '') {
        this.education.push(this.partnerPreferenceForm.value.educationOther.trim());
      }
    }
    if (this.professionCheck) {
      if (this.partnerPreferenceForm.value.educationOther != '') {
        this.profession.push(this.partnerPreferenceForm.value.professionOther.trim());
      }
    }
    if (this.foodCheck) {
      if (this.partnerPreferenceForm.value.otherFood != '') {
        this.foodPreferences.push(this.partnerPreferenceForm.value.otherFood.trim());
      }
    }
    if (this.residencyCheck) {
      if (this.partnerPreferenceForm.value.residencyOther != '') {
        this.rStatus.push(this.partnerPreferenceForm.value.residencyOther.trim());
      }
    }

    let allcheck:boolean = true;

    if (this.maritalStatus.toString() == "") {
      this.maritalStatusRequired = true
      this.scroller.scrollToAnchor("marital_status");
      allcheck = false;
      console.log("marital_status",allcheck);
      return;
    }

    if (this.religion.toString() == "") {
      this.requiredreligion = true;
      allcheck = false;
      this.scroller.scrollToAnchor("religionview");
      console.log("religionview",allcheck);
      return;
    }

    if (this.languages.toString() == "") {
      this.requiredLanguage = true
      allcheck = false;
      this.scroller.scrollToAnchor("Preferredlanguage");
      console.log("Preferredlanguage",allcheck);
      return;
    }

    if (this.rStatus.toString() == "") {
      this.requiredRStatus = true
      allcheck = false;
      this.scroller.scrollToAnchor("residencystatus");
      console.log("residencystatus",allcheck);
      return;
    }

    if (this.education.toString() == "") {
      this.educationRequired = true
      allcheck = false;
      this.scroller.scrollToAnchor("education");
      console.log("education",allcheck);
      return;
    }

    if (this.natureOfWork.toString() == "") {
      this.natureOfWorkRequired = true
      allcheck = false;
      this.scroller.scrollToAnchor("worknature");
      console.log("worknature",allcheck);
      return;
    }

    if (this.foodPreferences.toString() == "") {
      this.foodRequired = true
      allcheck = false;
      this.scroller.scrollToAnchor("food");
      console.log("food",allcheck);
      return;
    }

    // languages 
    const langArr = this.languages.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
    }
      , []
    );
    //relisgion
    const relArr = this.religion.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
    }
      , []
    );
    //work check 
    const workArr = this.natureOfWork.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
    }
      , []
    );
    //education 
    const educationArr = this.education.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
    }
      , []
    );

    // profession 
    const proffesionArr = this.profession.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
    }
      , []
    );

    // food check 
    const foodArr = this.foodPreferences.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
    }
      , []
    );

    // residency check 
    const resArr = this.rStatus.reduce((uniqArr, item) => {
      return uniqArr.includes(item) ? uniqArr : [...uniqArr, item]
    }
      , []
    );
    if (this.partnerPreferenceForm.invalid) {
      this.requiredLanguage = true;
      this.requiredreligion = true;
      this.scrollToFirstInvalidControl();
      return;
    }

    this.errors = false;
    let frmdata = {
      user_id: this.partnerPreferenceForm.value.user_id,
      looking_for: this.partnerPreferenceForm.value.looking_for,
      age_from: this.partnerPreferenceForm.value.age_from,
      age_to: this.partnerPreferenceForm.value.age_to,
      height_from: this.partnerPreferenceForm.value.height_from,
      height_to: this.partnerPreferenceForm.value.height_to,
      weight_from: this.partnerPreferenceForm.value.weight_from,
      weight_to: this.partnerPreferenceForm.value.weight_to,
      living_with_family: this.partnerPreferenceForm.value.living_with_family,
      family_type: this.familyType.toString(),
      religion: relArr.toString(),
      languages: langArr.toString(),
      marital_status: this.maritalStatus.toString(),
      have_children: this.partnerPreferenceForm.value.have_children,
      state_name: this.partnerPreferenceForm.value.state_name,
      country_name: this.partnerPreferenceForm.value.country_name,
      city_name: this.partnerPreferenceForm.value.city_name,
      residency_status: resArr.toString(),
      education: educationArr.toString(),
      profession_area: proffesionArr.toString(),
      nature_of_work: workArr.toString(),
      hide_income: this.partnerPreferenceForm.value.hide_income,
      annual_income: this.partnerPreferenceForm.value.annual_income,
      income_currency: this.partnerPreferenceForm.value.income_currency,
      income_range_from: this.partnerPreferenceForm.value.income_range_from,
      income_range_to: this.partnerPreferenceForm.value.income_range_to,
      food_preference: foodArr.toString(),
      description: this.partnerPreferenceForm.value.description
    }

    this.partnerPreferenceService.PartnerPreference(frmdata).subscribe(
      (result) => {
        if (result?.status) {
          this.partnerPreferenceForm.reset();
          this.commonService.setRegisterDoneStep(7);
          this.router.navigate(['myInfo']);
          this.buttonDisable = true;
        }
      },
      (error) => {
        this.buttonDisable = false;
        this.errors = error.error;
      },
    );
  }

  onChangeAgeFrom(age: any) {
    this.age_to = [];
    for (let i = age.target.value; i <= 90; i++) {
      this.f.age_to.setValue('');
      this.age_to.push({ id: i });
    }
  }

  onChangeWeightFrom(weight: any) {
    this.weight_to = [];
    for (let i = weight.target.value; i <= 114; i++) {
      this.f.weight_to.setValue('');
      this.weight_to.push({ i });
    }
  }

  onChangeHeightFrom(height: any) {
    this.height_to = [];
    for (let i = height.target.value; i <= 230; i++) {
      this.f.height_to.setValue('');
      this.height_to.push({ i });
    }
  }

  keyPressNumeric1(event) {
    const pattern = /\D/g;
    if (!pattern.test(event.target.value)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  checkIncomeRange() {
    let from = Number(this.partnerPreferenceForm.value.income_range_from);
    let to = Number(this.partnerPreferenceForm.value.income_range_to);
    if (from <= to) {
      this.incomeRange = false;
      this.buttonDisable = false;
    } else {
      this.incomeRange = true;
      this.buttonDisable = true;
    }
  }

  pasteNumeric1(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.partnerPreferenceForm.patchValue({ 'boy_child_not_married': newText });
  }

  keyPressNumeric2(event) {
    console.log(event)
    const pattern = /\D/g;
    if (!pattern.test(event.target.value)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumeric2(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.partnerPreferenceForm.patchValue({ 'boy_child_married': newText });
  }

  keyPressNumeric3(event) {
    const pattern = /\D/g;
    console.log(event, pattern)
    if (!pattern.test(event.target.value)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumeric3(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.partnerPreferenceForm.patchValue({ 'girl_child_not_married': newText });
  }

  keyPressNumeric4(event) {
    console.log(event, 'pattern')
    const pattern = /\D/g;
    if (!pattern.test(event.target.value)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumeric4(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.partnerPreferenceForm.patchValue({ 'girl_child_married': newText });
  }

  keyPressNumeric5(event) {
    const pattern = /\D/g;
    console.log(event, pattern, 'event');
    if (!pattern.test(event.target.value)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumeric5(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.partnerPreferenceForm.patchValue({ 'income_range_to': newText });
  }

  keyPressNumeric6(event) {
    console.log(123)
    const pattern = /\D/g;
    if (!pattern.test(event.target.value)) {
      event.preventDefault();
      return true;
    } else {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.preventDefault();
      return false;
    }
  }

  pasteNumeric6(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    let newText = pastedText.replace(/\D/g, "");
    this.partnerPreferenceForm.patchValue({ 'income_range_from': newText });
  }

  private scrollToFirstInvalidControl() {
    let form = document.getElementById('partnereference'); // <-- your formID
    let firstInvalidControl = form!.getElementsByClassName('ng-invalid')[0];
    console.log("In", firstInvalidControl);
    let str1 = ["Male", "Female", "age_from", "age_to", "height_from", "height_to1"]
    let str = ["income_range_to", "income_range_from", "income_currency", "AnnualIncomeAll"]
    console.log(firstInvalidControl, "firstInvalidControl", firstInvalidControl?.id, str1.includes(firstInvalidControl?.id))
    if (str1.includes(firstInvalidControl?.id)) {
      if (firstInvalidControl?.id == 'age_from' || firstInvalidControl?.id == 'age_to') {
        window.scroll(0, 230);
      } else if (firstInvalidControl?.id == 'height_from' || firstInvalidControl?.id == 'height_to1') {
        window.scroll(0, 330);
      } else {
        firstInvalidControl.scrollIntoView();
        (firstInvalidControl as HTMLElement).focus();
        setTimeout(() => {
          window.scrollBy(0, -100);
        }, 1000);
      }
    } else if (str.includes(firstInvalidControl?.id)) {
      if (this.maritalStatusRequired) {
        firstInvalidControl = document.getElementById("marital_status");
        console.log( firstInvalidControl )
        window.scroll(0, 670);
        return;
      }
      if (this.requiredreligion) {
        firstInvalidControl = document.getElementById("religionview");
        firstInvalidControl.scrollIntoView();
        return;
      }
      if (this.requiredLanguage) {
        firstInvalidControl = document.getElementById("Preferredlanguage")
        firstInvalidControl.scrollIntoView();
        return;
      }

      if (this.requiredRStatus) {
        firstInvalidControl = document.getElementById("residencystatus")
        firstInvalidControl.scrollIntoView();
        return;
      }
      if (this.educationRequired) {
        firstInvalidControl = document.getElementById("education")
        firstInvalidControl.scrollIntoView();
        return;
      }
      if (this.natureOfWorkRequired) {
        firstInvalidControl = document.getElementById("worknature")
        console.log(firstInvalidControl)
        window.scroll(0, 1900);
        return;
      } else {
        window.scroll(0, 2230);
      }
    } else {
      let allcheck:boolean = true;
      if (this.maritalStatus.toString() == "") {
        this.maritalStatusRequired = true
        window.scroll(0, 670);
        allcheck = false;
        console.log("marital_status",allcheck);
        return;
      }
      if (this.religion.toString() == "") {
        this.requiredreligion = true;
        allcheck = false;
        this.scroller.scrollToAnchor("religionview");
        console.log("religionview",allcheck);
        return
      }
      if (this.languages.toString() == "") {
        this.requiredLanguage = true
        allcheck = false;
        this.scroller.scrollToAnchor("Preferredlanguage");
        console.log("Preferredlanguage",allcheck);
        return
      }
      if (this.rStatus.toString() == "") {
        this.requiredRStatus = true
        allcheck = false;
        this.scroller.scrollToAnchor("residencystatus");
        console.log("residencystatus",allcheck);
        return
      }
      if (this.education.toString() == "") {
        this.educationRequired = true
        allcheck = false;
        this.scroller.scrollToAnchor("education");
        console.log("education",allcheck);
        return
      }
      if (this.natureOfWork.toString() == "") {
        this.natureOfWorkRequired = true
        allcheck = false;
        window.scroll(0, 1900);
        console.log("worknature",allcheck);
        return
      }
      if (this.foodPreferences.toString() == "") {
        this.foodRequired = true
        allcheck = false;
        this.scroller.scrollToAnchor("food");
        console.log("food",allcheck);
        return
      } else {
        window.scroll(0, 2520);
      }
    }
  }

  RemoveElementFromStringArray1(element: string) {
    this.languages.forEach((value: string, index: any) => {
      if (value == element) this.languages.splice(index, 1);
    });
  }

  language(event: any) {
    if (this.partnerPreferenceForm.value.languages == true) {
      if (event.target.id == "AnyLanguage") {
        this.languages = [];
        this.f.languagesOther.setValue("");
        $('#Bengali').prop('checked', false);
        $('#English').prop('checked', false);
        $('#Gujarati').prop('checked', false);
        $('#Hindi').prop('checked', false);
        $('#Marathi').prop('checked', false);
        $('#Punjabi').prop('checked', false);
        $('#Telugu').prop('checked', false);
        $('#Tamil').prop('checked', false);
        this.checkLanguages = false;
        setTimeout(() => {
          $('#LanguagesOther').prop('checked', false);
        }, 50);

        this.requiredLanguage = false;
        this.languages.push(event.target.value)

      } else {
        $('#AnyLanguage').prop('checked', false);
        this.RemoveElementFromStringArray1("Any Language");
        if (event.target.id != "LanguagesOther") {
          this.requiredLanguage = false;
          this.languages.push(event.target.value)
        }
        else {
          this.checkLanguages = true;
        }
      }
    } else {
      this.languages.forEach((element: any, index: any) => {
        if (element == event.target.value) {
          this.RemoveElementFromStringArray1(event.target.value);
        }
      });
    }
    if (this.languages.length == 0) {
      if (this.f.languagesOther.value == '') {
        this.requiredLanguage = true;
        // this.f.language.setValue('')
      } else {
        this.requiredLanguage = false;
      }
    } else {
      this.requiredLanguage = false;
    }
    this.checkOtherOption();
  }

  checkOtherOption() {
    // language
    if (this.f.languagesOther.value == '' && this.languages.length == 0) {
      this.requiredLanguage = true;
    } else {
      this.requiredLanguage = false
    }

    // religion 
    if (this.f.otherReligion.value == '' && this.religion.length == 0) {
      this.requiredreligion = true;
      // this.f.religion.setValue('')
    } else {
      this.requiredreligion = false;
    }

    //education
    if (this.f.educationOther.value == '' && this.education.length == 0) {
      this.educationRequired = true;
      // this.f.education.setValue('')
    } else {
      this.educationRequired = false;
    }

    //residency
    if (this.f.residencyOther.value == '' && this.rStatus.length == 0) {
      this.requiredRStatus = true;
      // this.f.residency_status.setValue('')
    } else {
      this.requiredRStatus = false;
    }


    if (this.f.otherFood.value == '' && this.foodPreferences.length == 0) {
      this.foodRequired = true;
    } else {
      this.foodRequired = false;
    }

    if (this.f.workOther.value == '' && this.natureOfWork.length == 0) {
      this.natureOfWorkRequired = true;
    } else {
      this.natureOfWorkRequired = false;
    }

    if (this.maritalStatus.length == 0) {
      this.maritalStatusRequired = true;
    } else {
      this.maritalStatusRequired = false;
    }
  }

  keyPressAlphaNumeric(event) {
    let inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9&,-]/.test(inp)) {
      this.requiredLanguage = false;
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  RemoveElementFromStringArray2(element: string) {
    this.religion.forEach((value: string, index: any) => {
      if (value == element) this.religion.splice(index, 1);
    });
  }

  Religion(event: any) {
    if (this.partnerPreferenceForm.value.religion == true) {
      if (event?.target.id == "AnyReligion") {
        this.religion = [];
        this.f.otherReligion.setValue("");
        $('#Buddhist').prop('checked', false);
        $('#Parsi').prop('checked', false);
        $('#Sikh').prop('checked', false);
        $('#Muslim').prop('checked', false);
        $('#Jain').prop('checked', false);
        $('#Christian').prop('checked', false);
        $('#Hindu').prop('checked', false);
        $('#NoReligion').prop('checked', false);
        $('#Other').prop('checked', false);
        setTimeout(() => {
          $('#Other').prop('checked', false);
        }, 50);
        this.checkreligion = false;
        this.requiredreligion = false;
        this.religion.push(event.target.value)

      } else {
        $('#AnyReligion').prop('checked', false);
        this.RemoveElementFromStringArray2("Any Religion");
        if (event.target.id != "Other") {
          this.requiredreligion = false;
          this.religion.push(event.target.value)
        } else {
          this.checkreligion = true;
        }
      }
    } else {
      this.religion.forEach((element: any, index: any) => {
        if (element == event.target.value) {
          this.RemoveElementFromStringArray2(event.target.value);
        }
      });
    }
    if (this.religion.length == 0) {
      this.requiredreligion = true;
      // this.f.religion.setValue('')
    } else {
      this.requiredreligion = false;
    }
    this.checkOtherOption();
  }

  RemoveElementFromStringArray3(element: string) {
    this.maritalStatus.forEach((value: string, index: any) => {
      if (value == element) this.maritalStatus.splice(index, 1);
    });
  }

  marital_status(event: any) {
    if (this.partnerPreferenceForm.value.marital_status == true) {
      this.maritalStatusRequired = false;
      if (event.target.id == "AnyOfThese") {
        this.maritalStatus = [];
        this.f.otherReligion.setValue("");
        $('#Widowed').prop('checked', false);
        $('#Single').prop('checked', false);
        $('#Divorced').prop('checked', false);
        $('#AwaitingDivorce').prop('checked', false);
        $('#Annulled').prop('checked', false);
        this.maritalStatus.push(event.target.value)
      } else {
        $('#AnyOfThese').prop('checked', false);
        this.RemoveElementFromStringArray3("AnyOfThese");
        this.maritalStatus.push(event.target.value)
      }
    } else {
      this.maritalStatus.forEach((element: any, index: any) => {
        if (element == event.target.value) {
          this.RemoveElementFromStringArray3(event.target.value);
        }
      });
    }
    if (this.maritalStatus.length == 0) {
      this.maritalStatusRequired = true;
    } else {
      this.maritalStatusRequired = false;
    }
    this.checkOtherOption();
  }

  RemoveElementFromStringArray4(element: string) {
    this.rStatus.forEach((value: string, index: any) => {
      if (value == element) this.rStatus.splice(index, 1);
    });
  }

  r_status(event: any) {
    if (this.partnerPreferenceForm.value.residency_status == true) {
      this.requiredRStatus = false;
      if (event.target.id != 'OtherResidency') {
        this.risedencyStatusRequired = false;
        this.rStatus.push(event.target.value);
      } else {
        this.residencyCheck = true;
      }
    } else {
      this.rStatus.forEach((element: any, index: any) => {
        if (element == event.target.value) {
          this.RemoveElementFromStringArray4(event.target.value);
        }
      });
    }

    if (this.rStatus.length == 0) {
      this.requiredRStatus = true;
    } else {
      this.requiredRStatus = false;
    }
    this.checkOtherOption();
  }

  RemoveElementFromStringArray5(element: string) {
    this.education.forEach((value: string, index: any) => {
      if (value == element) this.education.splice(index, 1);
    });
  }

  CheckEducation(event) {
    if (this.partnerPreferenceForm.value.education == true) {
      if (event.target.id == "NotMatter") {
        this.education = [];
        this.f.educationOther.setValue("");
        $('#HighSchool').prop('checked', false);
        $('#SomeCollege').prop('checked', false);
        $('#SomeUniversity').prop('checked', false);
        $('#AssociateDegree').prop('checked', false);
        $('#BachelorDegree').prop('checked', false);
        $('#MasterDegree').prop('checked', false);
        $('#GraduateDegree').prop('checked', false);
        $('#PostDoctoral').prop('checked', false);
        $('#OtherEducation').prop('checked', false);
        this.educationCheck = false;
        setTimeout(() => {
          $('#OtherEducation').prop('checked', false);
        }, 50);
        this.educationRequired = false;
        this.education.push(event.target.value)
      } else {
        $('#NotMatter').prop('checked', false);
        this.RemoveElementFromStringArray5("Does Not Matter");
        if (event.target.id != "OtherEducation") {
          this.educationRequired = false;
          this.education.push(event.target.value)
        }
        else {
          this.educationCheck = true;
        }
      }
    } else {
      this.education.forEach((element: any, index: any) => {
        if (element == event.target.value) {
          this.RemoveElementFromStringArray5(event.target.value);
        }
      });
    }
    if (this.education.length == 0) {
      this.educationRequired = true;
      // this.f.education.setValue('')
    } else {
      this.educationRequired = false;
    }
    this.checkOtherOption();
  }

  CheckResidency() {
    if (this.partnerPreferenceForm.value.f.residency_status == "Other") {
      this.f.residency_status.setValue("");
      this.residencyCheck = true;
    } else {
      this.residencyCheck = false;
    }
  }

  // food prefference
  RemoveElementFromStringArray9(element: string) {
    this.foodPreferences.forEach((value: string, index: any) => {
      if (value == element) this.foodPreferences.splice(index, 1);
    });
  }

  food_prefernce(event: any) {
    if (this.partnerPreferenceForm.value.food_preference == true) {
      if (event.target.id == "AnyFood") {
        this.foodPreferences = [];
        // console.log("sagar")
        $('#Veg').prop('checked', false);
        $('#NonVeg').prop('checked', false);
        $('#Occasionally').prop('checked', false);
        $('#Eggetarian').prop('checked', false);
        $('#Pescatarian').prop('checked', false);
        $('#JainFood').prop('checked', false);
        $('#Vegan').prop('checked', false);
        this.foodCheck = false;
        this.f.otherFood.setValue("");
        setTimeout(() => {
          $('#OtherFood').prop('checked', false);
        }, 50);
        this.foodPreferences.push(event.target.value);
      } else {
        $('#AnyFood').prop('checked', false);
        this.RemoveElementFromStringArray9("Does Not Matter");
        if (event.target.id != 'OtherFood') {
          this.foodRequired = false;
          this.foodPreferences.push(event.target.value);
        } else {
          this.foodCheck = true;
        }
      }

    } else {
      this.foodPreferences.forEach((element: any, index: any) => {
        if (element == event.target.value) {
          this.RemoveElementFromStringArray9(event.target.value);
        }
      });
    }
    if (this.foodPreferences.length == 0) {
      this.foodRequired = true;
    } else {
      this.foodRequired = false;
    }

    this.checkOtherOption();
  }

  // nature of work 
  RemoveElementFromStringArray6(element: string) {
    this.natureOfWork.forEach((value: string, index: any) => {
      if (value == element) this.natureOfWork.splice(index, 1);
    });
  }

  work(event: any) {
    if (this.partnerPreferenceForm.value.nature_of_work == true) {
      if (event.target.id == "AnyWork") {
        this.natureOfWork = [];
        // console.log("sagar")
        $('#BusinessSelf').prop('checked', false);
        $('#GovernmentPublic').prop('checked', false);
        $('#PrivateCompany').prop('checked', false);
        $('#NotWorking').prop('checked', false);
        this.workCheck = false;
        this.f.workOther.setValue("");
        setTimeout(() => {
          $('#OtherWork').prop('checked', false);
        }, 50);
        this.natureOfWork.push(event.target.value);
      } else {
        this.RemoveElementFromStringArray6("Does Not Matter")
        $('#AnyWork').prop('checked', false);
        if (event.target.id != 'OtherWork') {
          this.workRequired = false;
          this.natureOfWorkRequired = false;
          this.natureOfWork.push(event.target.value);
        } else {
          this.workCheck = true;
        }
      }
    } else {
      this.natureOfWork.forEach((element: any, index: any) => {
        if (element == event.target.value) {
          this.RemoveElementFromStringArray6(event.target.value);
        }
      });
    }

    if (this.natureOfWork.length == 0) {
      this.natureOfWorkRequired = true;
    } else {
      this.natureOfWorkRequired = false;
    }
    this.checkOtherOption();
  }

  RemoveElementFromStringArray7(element: string) {
    this.profession.forEach((value: string, index: any) => {
      if (value == element) this.profession.splice(index, 1);
    });
  }

  professionArea(event: any) {
    if (this.partnerPreferenceForm.value.profession_area == true) {
      if (event.target.id == "AnyProfession") {
        this.profession = [];
        $('#Accounting').prop('checked', false);
        $('#Administration').prop('checked', false);
        $('#Airline').prop('checked', false);
        $('#Agriculture').prop('checked', false);
        $('#Jewellery').prop('checked', false);
        $('#SalesMarketing').prop('checked', false);
        $('#MerchantNavy').prop('checked', false);
        this.professionCheck = false;
        this.f.professionOther.setValue("");
        setTimeout(() => {
          $('#OtherProfession').prop('checked', false);
        }, 50);
        this.profession.push(event.target.value);
      } else {
        this.RemoveElementFromStringArray7("Does Not Matter")
        $('#AnyProfession').prop('checked', false);
        if (event.target.id != 'OtherProfession') {
          this.professionRequired = false;
          this.profession.push(event.target.value);
        } else {
          this.professionCheck = true;
        }
      }

    } else {
      this.profession.forEach((element: any, index: any) => {
        if (element == event.target.value) {
          this.RemoveElementFromStringArray7(event.target.value);
        }
      });
    }
  }

  wordCounter(event) {
    this.exceedLimit = false;
    let para = this.partnerPreferenceForm.value.description;
    if (para.trim().length > 0) {
      this.wordCount = this.text ? this.text.nativeElement.value.split(/\s+/) : 0;
      this.words = this.wordCount ? this.wordCount.length : 0;

      if (this.words > 100) {
        this.exceedLimit = true;
      } else {
        this.exceedLimit = false;
      }
      if (this.words >= 101 && event.code != 'Backspace' && event.code != 'Delete') {
         // before sonarqube
        // const pattern = /[a-zA-Z0-9&_\.!,-\s()"']/g;
        const pattern = /[a-zA-Z0-9&_.!,\-\s()"']/g;
        if (!pattern.test(event.target.value)) {
          event.preventDefault();
          return false;
        } else {
           // before sonarqube
          // event.target.value = event.target.value.replace(/[a-zA-Z0-9&_\.!,-\s()']/g, "");
          // this.tellUsMore = this.tellUsMore.replace(/  +/g, ' ');
          event.target.value = event.target.value.replace(/[a-zA-Z0-9&_.,!\-\s()']/g, "");
          this.tellUsMore = this.tellUsMore.replace(/ {2,}/g, ' ');
          this.partnerPreferenceForm.patchValue({ 'description': this.tellUsMore.trim() + ' ' });
          this.words = this.tellUsMore.split(/\s+/).length
          event.preventDefault();
          return false;
        }
      } else {
        this.tellUsMore = this.partnerPreferenceForm.value.description;
      }
    } else {
      this.words = 0;
    }
  }

  keyPressNumeric8(event) {
    if (this.words >= 101) {
       // before sonarqube
        // const pattern = /[a-zA-Z0-9&_\.!,-\s()"']/g;
        const pattern = /[a-zA-Z0-9&_.!,\-\s()"']/g;
      if (!pattern.test(event.target.value)) {
        event.preventDefault();
        return false;
      } else {
        // before sonarqube
        // event.target.value = event.target.value.replace(/[a-zA-Z0-9&_\.!,-\s()']/g, "");
        event.target.value = event.target.value.replace(/[a-zA-Z0-9&_.,!\-\s()']/g, "");
        event.preventDefault();
        return false;
      }
    } else {
      // unused code
      //this.f.description.setValue(fdg); 
    }
  }

  pasteNumeric8(event) {
    setTimeout(() => {
      this.wordCount = this.text ? this.text.nativeElement.value.split(/\s+/) : 0;
      this.words = this.wordCount ? this.wordCount.length : 0;
      if (this.words >= 101) {
        this.wordCount = this.wordCount.slice(0, 100);
        this.words = this.wordCount ? this.wordCount.length : 0;
        this.partnerPreferenceForm.patchValue({ 'description': this.wordCount.join(" ") });
        if (this.words > 100) {
          this.exceedLimit = true;
        } else {
          this.exceedLimit = false;
        }
      }
    }, 500);
  }

  checkFamilyType() {
    if (this.partnerPreferenceForm.value.living_with_family == "Yes") {
      this.isFamilyType = true;
    } else {
      this.isFamilyType = false;
      this.f.family_type.setValue("");
    }
  }

  RemoveElementFromStringArray8(element: string) {
    this.familyType.forEach((value: string, index: any) => {
      if (value == element) this.familyType.splice(index, 1);
    });
  }

  familyTypeCheck(event: any) {
    if (this.partnerPreferenceForm.value.family_type == true) {
      this.familyType.push(event.target.value);
    } else {
      this.familyType.forEach((element: any, index: any) => {
        if (element == event.target.value) {
          this.RemoveElementFromStringArray8(event.target.value);
        }
      });
    }
  }

  OnchangeCountryCurrency(event) {
    this.partnerPreferenceForm.patchValue({ 'income_currency': event.id });
  }

  // .......content managment code start....
  //before sonarqube
  // NameSpaceNotAllowed(event: any) {
  //   let RegEx = new RegExp(/^([a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"])*$/g);
  //   let str = event.target.value
  //   if (RegEx.test(str)) {
  //     return event.target.value
  //   } else {
  //     var nameString = str.replace(/\s+/g, ' ');
  //     var nameString = str.replace(/[^a-zA-Z0-9\d!@#$&()\\-`.+,\%\*\-\=\|\~\[\]\{\}/\\<\>\?\'\"\;\:"]+/g, ' ');
  //     event.target.value = nameString.replace(/^\s+/g, "");
  //   }
  // }

  //after sonarqube
  NameSpaceNotAllowed(event: any) {
    let RegEx = new RegExp(/^([a-zA-Z\d!@#$&()\-.+,%*=~[\]{}\\<>?'":])*$/g);
    let str = event.target.value;

    if (RegEx.test(str)) {
      return event.target.value;
    } else {
      let nameString = str.replace(/\s+/g, ' ');
      nameString = nameString.replace(/[^a-zA-Z\d!@#$&()\-.+,%*=\|~[\]{}\\<>\?'";]+/g, ' ');
      event.target.value = nameString.replace(/^\s+/g, "");
    }
  }

  pasteText(event) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedNumber = clipboardData.getData('text');
    let newNumber = pastedNumber.replace(/[^a-zA-Z]/g, "");
    this.partnerPreferenceForm.patchValue({ 'city_name': newNumber });
    setTimeout(() => {
      this.f.city_name.setValue(newNumber);
    }, 100);
  }

  OnlyLetterbetweenSpace(event: any) {
    let RegEx = new RegExp(/^([a-zA-Z])*$/g);
    let str = event.target.value
    if (RegEx.test(str)) {
      return event.target.value
    } else {
      var nameString = str.replace(/\s+/g, ' ');
      var nameString = str.replace(/[^a-zA-Z]+/g, ' ');
      event.target.value = nameString.replace(/^\s+/g, "");
    }
  }
  // .......content managment code end....
}
