<div class="overflow-x-hidden mt-93">

    <!-- bg-white border rounded-10 shadow-sm  -->
    <div class="container p-3 mx-width-800">
        <form #stepTwoNgForm="ngForm" id="stepTwo" [formGroup]="stepTwoForm" (submit)="stepTwo()">
            <div *ngIf="errors?.qualification" class="alert alert-danger mt-3">
                {{ errors?.qualification }}
            </div>
            <div *ngIf="errors?.collageName" class="alert alert-danger mt-3">
                {{ errors?.collageName }}
            </div>
            <div *ngIf="errors?.nature_of_work" class="alert alert-danger mt-3">
                {{ errors?.nature_of_work }}
            </div>
            <div *ngIf="errors?.companyName" class="alert alert-danger mt-3">
                {{ errors?.companyName }}
            </div>
            <div *ngIf="errors?.currencyType" class="alert alert-danger mt-3">
                {{ errors?.currencyType }}
            </div>
            <div *ngIf="errors?.annualIncome" class="alert alert-danger mt-3">
                {{ errors?.annualincome }}
            </div>

            <div class="col-12">
                <h5 class="fw-bold text-center">More Details to Enhance The Search</h5>
                <h1 class="fw-bold text-center display-2"> Education and Professional Details </h1>
            </div>
            <div class="row py-2">
                <div class="col-12 mt-3">
                    <h6 class="fw-bold"> Education <span class="text-danger"> *</span></h6>
                    <div class="select-box" [ngClass]="{ 'is-invalid': submitted && f.qualification.errors }">
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="hidden" id="Self1" [formControlName]="'user_id'">

                            <input class="form-check-input" type="radio" [formControlName]="'qualification'"
                                id="HighSchool" [formControlName]="'qualification'" value="High School"
                                (change)="educationCheck()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="HighSchool">
                                High
                                School </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'qualification'"
                                id="SomeCollege" value="Some College" (change)="educationCheck()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="SomeCollege">
                                Some College </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'qualification'"
                                id="SomeUniversity" value="Some University" (change)="educationCheck()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="SomeUniversity"> Some University </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'qualification'"
                                id="AssociateDegree" value="Associate`s Degree" (change)="educationCheck()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="AssociateDegree"> Associate's Degree </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'qualification'"
                                id="BachelorDegree" value="Bachelor`s Degree" (change)="educationCheck()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="BachelorDegree"> Bachelor's Degree </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'qualification'"
                                id="MasterDegree" value="Master`s Degree" (change)="educationCheck()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="MasterDegree">
                                Master's Degree </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'qualification'"
                                id="GraduateDegree" value="Graduate Degree" (change)="educationCheck()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="GraduateDegree"> Graduate Degree </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'qualification'"
                                id="PostDoctoral" value="PhD/Post
                                Doctorate" (change)="educationCheck()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="PostDoctoral">
                                PhD/Post Doctorate </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!otherQualification">
                            <input class="form-check-input" type="radio" [formControlName]="'qualification'"
                                id="OtherQualification" value="Other" (change)="educationCheck()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="OtherQualification">Other</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="otherQualification">
                            <input type="text" class="form-control" id="caste" placeholder="Enter education "
                                maxlength="50" [formControlName]="'qualification'"
                                (input)="NameSpaceNotAllowed($event)">
                        </div>
                        <div *ngIf="submitted && f.qualification.errors">
                            <div class="form-label mb-2 py-1 fw-bold text-danger">Education is required</div>
                        </div>
                    </div>
                </div>


                <div class="col-12 mt-3">
                    <h6 class="fw-bold"> College/University Name </h6>
                    <input type="text" class="form-control bg-light" id="Currentcity" placeholder="Enter"
                        [formControlName]="'college_name'" maxlength="100" (input)="NameSpaceNotAllowed($event)">
                </div>
                <div class="col-12 mt-3">
                    <h6 class="fw-bold"> Nature of Work <span class="text-danger"> *</span></h6>
                    <div class="select-box" [ngClass]="{ 'is-invalid': submitted && f.nature_of_work.errors }">
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'nature_of_work'"
                                id="Business" value="Business" (change)="NatureOfWork()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="Business">
                                Business </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'nature_of_work'"
                                id="GovernmentCompany" value="Government Company" (change)="NatureOfWork()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="GovernmentCompany">Government
                                Company</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'nature_of_work'"
                                id="PrivateCompany" value="Private Company" (change)="NatureOfWork()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="PrivateCompany">Private Company
                            </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'nature_of_work'"
                                id="SelfEmployed" value="Self Employed" (change)="NatureOfWork()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="SelfEmployed">Self Employed
                            </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3">
                            <input class="form-check-input" type="radio" [formControlName]="'nature_of_work'"
                                id="NotWorking" value="Not Working" (change)="NatureOfWork()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1" for="NotWorking">Not
                                Working
                            </label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!otherWork">
                            <input class="form-check-input" type="radio" [formControlName]="'nature_of_work'"
                                id="OtherWork" value="Other" (click)="hider()" (change)="NatureOfWork()">
                            <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                for="OtherWork">Other</label>
                        </div>
                        <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="otherWork">
                            <input type="text" class="form-control" id="caste" placeholder="Enter nature of work"
                                maxlength="50" [formControlName]="'nature_of_work'"
                                (input)="NameSpaceNotAllowed($event)">
                        </div>
                        <div *ngIf="submitted && f.nature_of_work.errors">
                            <div class="form-label mb-2 py-1 fw-bold text-danger">Nature of work is required</div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-12 mt-3" *ngIf="notWorking">
                        <h6 class="fw-bold"> Profession Area </h6>
                        <div class="select-box">
                            <div class="form-check form-check-inline p-0 me-3 mb-3">
                                <input class="form-check-input" type="radio" [formControlName]="'working_as'"
                                    id="Accounting" value="Accounting - Banking & Finance"
                                    (change)="checkProfessionArea()">
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                    for="Accounting"> Accounting - Banking &
                                    Finance </label>
                            </div>
                            <div class="form-check form-check-inline p-0 me-3 mb-3">
                                <input class="form-check-input" type="radio" [formControlName]="'working_as'"
                                    id="Administration" value="Administration & HR" (change)="checkProfessionArea()">
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                    for="Administration">Administration & HR
                                </label>
                            </div>
                            <div class="form-check form-check-inline p-0 me-3 mb-3">
                                <input class="form-check-input" type="radio" [formControlName]="'working_as'"
                                    id="Airline" value="Airline & Aviation" (change)="checkProfessionArea()">
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                    for="Airline">Airline &
                                    Aviation</label>
                            </div>
                            <div class="form-check form-check-inline p-0 me-3 mb-3">
                                <input class="form-check-input" type="radio" [formControlName]="'working_as'"
                                    id="Agriculture" value="Agriculture" (change)="checkProfessionArea()">
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                    for="Agriculture">Agriculture</label>
                            </div>
                            <div class="form-check form-check-inline p-0 me-3 mb-3">
                                <input class="form-check-input" type="radio" [formControlName]="'working_as'"
                                    id="Jewellery" value="Beauty - Fashion & Jewellery Designers"
                                    (change)="checkProfessionArea()">
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                    for="Jewellery">Beauty -
                                    Fashion
                                    & Jewellery Designers</label>
                            </div>
                            <div class="form-check form-check-inline p-0 me-3 mb-3">
                                <input class="form-check-input" type="radio" [formControlName]="'working_as'"
                                    id="SalesMarketing" value="Sales & Marketing" (change)="checkProfessionArea()">
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                    for="SalesMarketing">Sales &
                                    Marketing</label>
                            </div>
                            <div class="form-check form-check-inline p-0 me-3 mb-3">
                                <input class="form-check-input" type="radio" [formControlName]="'working_as'"
                                    id="MerchantNavy" value="Merchant Navy" (change)="checkProfessionArea()">
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                    for="MerchantNavy">Merchant
                                    Navy</label>
                            </div>
                            <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="!otherProfessionArea">
                                <input class="form-check-input" type="radio" [formControlName]="'working_as'"
                                    id="OtherProfession" value="Other" (change)="checkProfessionArea()">
                                <label class="form-check-label p-2 rounded-3 shadow-sm border border-1"
                                    for="OtherProfession">Other</label>
                            </div>
                            <div class="form-check form-check-inline p-0 me-3 mb-3" *ngIf="otherProfessionArea">
                                <input type="text" class="form-control" id="caste" placeholder="Enter profession "
                                    maxlength="50" [formControlName]="'working_as'"
                                    (input)="NameSpaceNotAllowed($event)">
                            </div>
                        </div>
                    </div>


                    <div class="col-12 mt-3" *ngIf="notWorking">
                        <h6 class="fw-bold"> Company Name </h6>
                        <input type="text " class="form-control bg-light " id="Company_name " placeholder="Enter "
                            [formControlName]=" 'company_name'
                            " maxlength="50" (input)="NameSpaceNotAllowed($event)">
                    </div>



                    <div class="col-12 mt-3 " *ngIf="notWorking">
                        <div class="row ">
                            <h6 class="fw-bold"> Annual
                                Income <span class="fw-normal
                                        text-muted ">
                                    (Optional) </span></h6>
                            <div class="input-group runded-0 ">
                                <span class="bg-light input-group-text p-0 min-w-220" id="basic-addon1 ">

                                    <ng-select [items]="this.countries" bindLabel="fullnumber" bindValue="id"
                                        [formControlName]="'income_currency'" [(ngModel)]="currencyCode"
                                        placeholder="Select Country " dropdownPosition="auto" [virtualScroll]="true"
                                        (change)="OnchangeCountryCode($event)" class="w-100">
                                        <ng-template ng-label-tmp let-item="item">
                                            <img height="20" width="20" src="assets/images/flag/Flag_of_{{item.country_name |
                                                replaceSpaceWithUnderscore}}.svg" alt="" style="margin-right:5px;" />
                                            {{item.country_name}} <b>{{item.currency}}</b>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-index="index">
                                            <img height="20" width="20" src="assets/images/flag/Flag_of_{{item.country_name |
                                                replaceSpaceWithUnderscore}}.svg" alt="" style="margin-right:5px;" />
                                            {{item.country_name}} <b> {{item.currency}}</b>
                                        </ng-template>
                                    </ng-select>

                                </span>
                                <input *ngIf="!!this.f.income_currency.value" type="number "
                                    class="form-control bg-light rounded-0 " placeholder="Enter "
                                    [formControlName]=" 'annual_income' " maxlength="12"
                                    (input)="keyPressNumeric($event)" (paste)="pasteNumeric($event)">
                            </div>
                        </div>
                    </div>


                    <div class="col-6 mt-5 ">
                        <a href="javascript:void(0); " routerLink="/step">
                            <button type="button "
                                class="btn w-120 py-2 rounded-3 fs-5 fw-bold btn-danger ">Previous</button></a>
                    </div>
                    <div class="col-6 text-end mt-5 ">
                        <a href="javascript:void(0); ">
                            <button type="submit " class="btn w-120 py-2 rounded-3 fs-5 fw-bold btn-danger "
                                [disabled]="buttonDisable ">Next</button></a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<app-login-footer></app-login-footer>