import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../shared/auth.service';
import { ActivatedRoute/*, NavigationEnd*/, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { MyProfileService } from '../services/my-profile.service';
// unused import
// import * as $ from 'jquery';
// import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css']
})
export class PackagesComponent implements OnInit {
  user_id: number;
  packages: any;
  package_id: any;
  packages_obj = {
    '1': 'Free',
    '2': 'Premium',
    '3': 'Elite',
  }
  last_page: string;
  contentLoader: boolean = false;
  amount: any;
  currency: any;
  CurrentPlanDetail: any;

  // ploadData: {
  //   user_id: number; package_id: any; stripe_item_id: any; ucp_id: any; last_page: string;
  //   amount: any; currency_code: any; total_amount: any, plan_type: any
  // };
  ploadData: any;
  discountAmount: any;
  perDayCost: number;
  remainingAmount: number;
  planDetails: any;
  purchasePlan: any;
  usedDays: number;
  previousPlanAmount: any;
  remainingDays: number;
  usedDaysAmount: number;
  endDate: Date;
  constructor(
    private titleService: Title,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private cookieService: CookieService,
    private myProfileService: MyProfileService,

  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);

    this.titleService.setTitle('Packages | eternalstrings.com');
    this.user_id = parseInt(this.cookieService.get('user_id'));

    let pageId = this.route.snapshot.params['id'];
    pageId = pageId.toString().replaceAll('Ar1t', '=').replaceAll('FGH', '/').replaceAll('THD', '+');
    let data = this.commonService.decryptData(pageId)
    if (data.length > 1) {
      let pkgId = data.split("-");
      this.package_id = pkgId[0]
      this.purchasePlan = pkgId[1]
    } else {
      this.package_id = data
      this.purchasePlan = "upgrade"
    }
    this.last_page = localStorage.getItem('currentUrl');
    //  console.log(this.last_page, 'balram')
    this.getPackages();
    this.getUserPackages()
  }

  getPackages() {
    this.contentLoader = true;
    let pload = {
      user_id: this.user_id,
      package_id: this.package_id
    };
    this.myProfileService.getPackages(pload).subscribe(
      (result) => {
        if (result.status) {
          this.packages = result.packages;
        }
        this.contentLoader = false;
      },
      (error) => {
        this.contentLoader = false;
        this.toastrService.error('Some thing want wrong info!');
      }
    );
  }

  getUserPackages() {
    let pload = {
      user_id: this.user_id,
    };
    this.myProfileService.getUserPackages(pload).subscribe(
      (result) => {
        if (result.status) {
          result.packages.forEach(element => {
            if (element.active_plan_id) {
              console.log(element, "Amount details ")
              this.CurrentPlanDetail = element;
              const newDate = new Date(this.CurrentPlanDetail.start_date);
              let month = 0
              if (this.CurrentPlanDetail.subscription_plan == "3 month") {
                month = 3;
              } else if (this.CurrentPlanDetail.subscription_plan == "6 month") {
                month = 6;
              } else if (this.CurrentPlanDetail.subscription_plan == "9 month") {
                month = 9;
              }
              newDate.setMonth(newDate.getMonth() + month);
              const formattedDate = newDate.toISOString().slice(0, 19).replace("T", " ");
              this.endDate = new Date(formattedDate)
              console.log(formattedDate, "hello ", this.endDate, "hello ")
              this.getPreviousPlanDetail();
              this.discountRemainingDays()
            }
          });
        }
      },
      (error) => {
        this.toastrService.error('Some thing want wrong info!');
      }
    );
  }

  discountRemainingDays() {
    console.log(this.CurrentPlanDetail, "currthis.CurrentPlanDetail ssd")
    const dateTime1 = new Date(this.CurrentPlanDetail.start_date); // 3 / 6 / 9 extend

    //  console.log(dateTime1, this.CurrentPlanDetail)
    const dateTime2 = new Date(this.endDate);
    const currentDate = new Date();

    // Calculate the difference days month
    const differenceInMilliseconds = dateTime2.getTime() - dateTime1.getTime();
    const dayDifference = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    // Calculate the remaining days, 
    const timeDifference = dateTime2.getTime() - currentDate.getTime();
    const remainingDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    this.remainingDays = remainingDays
    const usedPlanDays = dayDifference - remainingDays
    this.usedDays = Math.floor(usedPlanDays)
    //  console.log(dayDifference, "total", remainingDays, "remainingDays", usedPlanDays, "usedPlanDays")

    if (this.CurrentPlanDetail && this.planDetails && this.previousPlanAmount) {
      this.perDayCost = this.previousPlanAmount / dayDifference;
      this.usedDaysAmount = Math.floor(this.perDayCost * usedPlanDays)
      this.remainingAmount = (this.previousPlanAmount - this.usedDaysAmount)
      //  console.log("hello time out")
    }
  }

  getPreviousPlanDetail() {
    let pload = {
      user_id: this.user_id,
      package_id: this.CurrentPlanDetail.package_id
    };

    this.myProfileService.getPackages(pload).subscribe(
      (result) => {
        if (result.status) {
          let previousPlanDetails = result.packages;
          previousPlanDetails.forEach(element => {
            if (element.duration == this.CurrentPlanDetail.subscription_plan) {
              this.previousPlanAmount = element.amount
            }
          });
        }
        this.contentLoader = false;
      },
      (error) => {
        this.contentLoader = false;
        this.toastrService.error('Some thing want wrong info!');
      }
    );
  }

  startPayment(id: any, package_id: any, stripe_item_id: any, planDetail: any) {
    //  console.log(this.CurrentPlanDetail, planDetail)
    this.spinner.show();
    if (this.CurrentPlanDetail != '' && this.CurrentPlanDetail != undefined) {
      //  console.log(this.CurrentPlanDetail)
      this.planDetails = planDetail
      this.discountRemainingDays();
      let total = planDetail.amount
      if (this.remainingAmount >= planDetail.amount) {
        this.amount = planDetail.amount
      } else {
        this.amount = Math.floor(total - this.remainingAmount)
      }
      this.amount = this.amount.toFixed(2);
      this.ploadData = {
        user_id: this.user_id,
        package_id: package_id,
        stripe_item_id: stripe_item_id,
        ucp_id: id,
        last_page: this.last_page,
        amount: this.amount,
        currency_code: planDetail.currency,
        total_amount: planDetail.amount,
        plan_type: this.purchasePlan
      }
      this.spinner.hide();
    } else {
      this.ploadData = {
        user_id: this.user_id,
        package_id: planDetail.package_id,
        stripe_item_id: planDetail.stripe_item_id,
        ucp_id: planDetail.id,
        last_page: this.last_page,
        amount: planDetail.amount,
        currency_code: planDetail.currency,
        total_amount: planDetail.amount,
        plan_type: this.purchasePlan
      }
      this.spinner.hide();
      this.confirmPayment();
    }
    // unused data
    //  console.log(this.ploadData, "pload")
    //  console.log(planDetail, " plan amonut ...................................", this.ploadData)
  }

  startPayment1(planDetail: any) {
    this.planDetails = planDetail
    this.discountRemainingDays();
    let total = planDetail.amount
    if (this.remainingAmount >= planDetail.amount) {
      this.amount = planDetail.amount
    } else {
      this.amount = Math.floor(total - this.remainingAmount)
    }

    this.ploadData = {
      user_id: this.user_id,
      package_id: planDetail.package_id,
      stripe_item_id: planDetail.stripe_item_id,
      ucp_id: planDetail.id,
      last_page: this.last_page,
      amount: planDetail.amount,
      currency_code: planDetail.currency,
      total_amount: planDetail.amount,
      plan_type: this.purchasePlan
    }
    // unused data
    //  console.log(this.ploadData, "pload")
    //  console.log(planDetail, " plan amonut ...................................", this.ploadData, planDetail.currency)
    this.confirmPayment();
  }


  confirmPayment() {
    this.spinner.show();

    this.myProfileService.startPayment(this.ploadData).subscribe(
      (result) => {
        this.spinner.hide();
        if (result.status) {
          window.location.href = result.url.url;
        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastrService.error('Some thing went wrong info!');
      }
    );
  }
  backLocation() {
    localStorage.setItem('frompackages', 'frompackages');
    console.log("Back");
  }


}
