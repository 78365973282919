<section class="container px-0 mt-93" *ngIf="!detailLoader">

  <div class="row mx-0">
    <div class="col-12 position-relative px-0 profile-banner overflow-hidden"
      style="max-height: 485px; border-radius: 0 0 10px 10px;">
      <app-progressive-image class="progressive-image">
        <img appProgressiveImage [src]="myPhotos" alt="" class="img-fluid w-100">

        <div appProgressiveImagePlaceholder class="placeholderOneOrThree">
          <div id="loaderProgressive" class="centerProgressive"></div>
        </div>
        <div appProgressiveImageFallback class="fallback">
          <img appProgressiveImageFallback class="img-fluid" src="assets/images/banner-4.jpg" alt="">
        </div>
      </app-progressive-image>

      <a href="javascript:void(0)" routerLink="/myInfo">
        <div class="w-25-h-25 btn btn-danger position-absolute top-0 right-0 rounded-circle center m-3">
          <i class="fas fa-pencil-alt"></i>
        </div>
      </a>
    </div>
  </div>

  <div class="row position-relative mx-0">
    <div class="col-12 col-sm-4 col-lg-3">
      <div class="w-75 ratio-1 profile-box rounded-circle position-relative mx-auto bg-white p-1">

        <app-progressive-image class="progressive-image">
          <img appProgressiveImage [src]="profilePhoto" alt=""
            class="img-fluid rounded-circle border image-cover overflow-hidden">

          <div appProgressiveImagePlaceholder class="placeholderOneOrThree-suggest">
            <div id="loaderProgressive" class="centerProgressive"></div>
          </div>
          <div appProgressiveImageFallback class="fallback">
            <img appProgressiveImageFallback class="img-fluid rounded-circle border image-cover overflow-hidden"
              src="assets/images/men-avtar-2.svg" alt="">
          </div>
        </app-progressive-image>
        <a href="javascript:void(0)" routerLink="/myInfo">
          <div class="w-25-h-25 btn btn-danger position-absolute top-0 right-0 rounded-circle center m-3">
            <i class="fas fa-pencil-alt"></i>
          </div>
        </a>

      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-9 py-3 mb-3">
      <div class="row py-3">
        <div class="col-12 col-md-6 py-1 lh-lg">
          <p class="mb-0 fw-bold"> {{displayName}} <small class="p-sm"></small>
            <span class="user_email_verified"
              [ngbTooltip]="myProfileDetail?.is_user_verified ==1 ? 'Verified user': 'Verified email'"
              placement="bottom">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                class="bi bi-shield-fill-check mb-1 " [ngClass]="{'text-muted':myProfileDetail?.is_user_verified != 1,
                                               'text-success':myProfileDetail?.is_user_verified == 1}"
                viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0
                                    0-1.044
                                    1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517
                                    2.453c.386.273.744.482
                                    1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159
                                    7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195
                                    3.061-5.513
                                    2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0
                                    0-2.887-.87C9.843.266
                                    8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708
                                    0l-1.5-1.5a.5.5 0
                                    1 1 .708-.708L7.5 7.793l2.646-2.647z" />
              </svg>
            </span>

            <span class="user_email_verified1 mx-1" *ngIf="myProfileDetail?.is_user_verified== 0 " placement="bottom"
              ngbTooltip="Verified email"><i class="fa fa-check fs-5" style="color:green"></i>
            </span>
            <span class="user_email_verified1 mx-1" *ngIf="myProfileDetail?.is_user_verified== 1 " placement="bottom"
              ngbTooltip="Verified user"><i class="fa fa-check-double fs-5 " style="color:green"></i>
            </span>
            <img src="assets/images/icon/elite.svg" class="width-30" alt placement="right" ngbTooltip="Elite Member"
              *ngIf="myProfileDetail.package_id == 3" />
            <img src="assets/images/icon/premium.svg" class="width-30" alt placement="right" ngbTooltip="Premium Member"
              *ngIf="myProfileDetail.package_id == 2" />
          </p>

          <p class="mb-0 py-1 p-sm"> <strong>Age:</strong> {{age}} <strong>Height:</strong> {{myProfileDetail?.height
            |heightConverter:"Ft"}} </p>
          <p class="mb-0 py-1 p-sm"> {{myProfileDetail?.marital_status}} </p>
          <p class="mb-0 py-1 p-sm"> {{myProfileDetail?.religion | limit :18}}, {{myProfileDetail?.caste | limit :18}}
          </p>
        </div>
        <div class="col-12 col-md-6 py-1 lh-lg">
          <p class="mb-0 py-1 p-sm"> {{myProfileDetail?.working_as | limit :28}}</p>
          <p class="mb-0 py-1 p-sm"> {{myProfileDetail?.current_city | limit :25 }}
          </p>
          <p class="mb-0 py-1 p-sm"> {{myProfileDetail?.state_name}}
          </p>
          <p class="mb-0 py-1 p-sm"> {{myProfileDetail?.living_in_country}}
          </p>
        </div>
      </div>

    </div>

  </div>

  <div class="row mb-3 mx-auto">

    <div class="col-12 col-md-6 ps-md-0 pe-md-2 px-2">

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2 position-relative">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            About {{displayName}} </h5>

          <a href="javascript:void(0)" (click)="EditAboutYou()">
            <div class="w-25-h-25 btn btn-danger position-absolute top-0 right-0 rounded-circle center m-2">
              <i class="fas fa-pencil-alt"></i>
            </div>
          </a>

          <p class="mb-0 text-muted mb-2"> <strong> First Name : </strong> {{myProfileDetail?.first_name}} </p>

          <p class="mb-0 text-muted mb-2"> <strong> Last Name : </strong> {{myProfileDetail?.last_name}} </p>

          <p class="mb-0 text-muted mb-2"> <strong> Display Name : </strong> {{displayName}} </p>

          <p class="mb-0 text-muted mb-2"> <strong> Age : </strong> {{age}} <small class="p-sm">Years</small> </p>

          <p class="mb-0 text-muted mb-2"> <strong> Height : </strong> {{ myProfileDetail?.height }} cm ({{
            myProfileDetail?.height | heightConverter
            : "Ft"
            }})</p>

          <p class="mb-0 text-muted mb-2"> <strong> Weight : </strong> {{ myProfileDetail?.weight
            }} kg ({{
            myProfileDetail?.weight | weightConverter : "Pounds" }} lb)
            <!-- {{ details.weight
            }} kg ({{ details.weight | weightConverter : "Pounds" }} lb) -->
          </p>

          <p class="mb-0 text-muted mb-2"> <strong> Preferred Language : </strong> {{myProfileDetail?.languages | addSpaceAfterComma}}</p>

          <p class="mb-0 text-muted mb-2"> <strong> Marital Status : </strong>{{myProfileDetail?.marital_status}} </p>
          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.have_children =='Yes' || myProfileDetail?.have_children =='No'"> <strong> Have
              Children : </strong>{{myProfileDetail?.have_children}} </p>

          <p class="mb-0 text-muted mb-2"> <strong> Personality Description : </strong> {{myProfileDetail?.description}}
          </p>

        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2 position-relative">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Basics & Lifestyle </h5>

          <a href="javascript:void(0)" (click)="EditBasicsLifestyle()">
            <div class="w-25-h-25 btn btn-danger position-absolute top-0 right-0 rounded-circle center m-2">
              <i class="fas fa-pencil-alt"></i>
            </div>
          </a>

          <p class="mb-0 text-muted mb-2" hidden> <strong> Date of Birth : </strong> {{myProfileDetail?.dob | date:
            'dd-MM-yyyy'}}
          </p>
          <p class="mb-0 text-muted mb-2"> <strong> Food Preference : </strong> {{myProfileDetail?.food_preference | addSpaceAfterComma }}
          </p>
          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.grew_up_in !='' && myProfileDetail?.grew_up_in != null"> <strong> Grew Up In :
            </strong> {{myProfileDetail?.grew_up_in}}
          </p>
          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.personal_values !='' && myProfileDetail?.personal_values != null"> <strong> Personal
              Values : </strong> {{myProfileDetail?.personal_values}}
          </p>
          <p class="mb-0 text-muted mb-2" *ngIf="myProfileDetail?.do_smoke !='' && myProfileDetail?.do_smoke != null">
            <strong> Do you smoke? :
            </strong> {{myProfileDetail?.do_smoke}}
          </p>
          <p class="mb-0 text-muted mb-2" *ngIf="myProfileDetail?.do_drink !='' && myProfileDetail?.do_drink != null">
            <strong> Do you Drink? :
            </strong> {{myProfileDetail?.do_drink}}
          </p>
          <p class="mb-0 text-muted mb-2" *ngIf="myProfileDetail?.have_pet !='' && myProfileDetail?.have_pet != null">
            <strong> Do you have pets? :
            </strong> {{myProfileDetail?.have_pet}}
          </p>
          <p class="mb-0 text-muted mb-2" *ngIf="myProfileDetail?.sun_sign !='' && myProfileDetail?.sun_sign != null">
            <strong> Sun Sign : </strong>{{myProfileDetail?.sun_sign}}
          </p>
          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.blood_group !='' && myProfileDetail?.blood_group != null"> <strong> Blood Group :
            </strong> {{myProfileDetail?.blood_group}}
          </p>
          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.health_info !='' && myProfileDetail?.health_info != null"> <strong> Health
              Information : </strong>{{myProfileDetail?.health_info}}
          </p>
          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.disability !='' && myProfileDetail?.disability != null"> <strong> Disability :
            </strong>{{myProfileDetail?.disability}}
          </p>
        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2 position-relative">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Astro Details</h5>

          <a href="javascript:void(0)" (click)="EditAstroDetails()">
            <div class="w-25-h-25 btn btn-danger position-absolute top-0 right-0 rounded-circle center m-2">
              <i class="fas fa-pencil-alt"></i>
            </div>
          </a>

          <p class="mb-0 text-muted mb-2" *ngIf="myProfileDetail?.manglik !='' && myProfileDetail?.manglik != null">
            <strong> Manglik : </strong>
            {{myProfileDetail?.manglik}}
          </p>

          <p class="mb-0 text-muted mb-2"> <strong> Date of Birth : </strong> {{myProfileDetail?.dob | date:
            'dd-MM-yyyy'}} </p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.time_of_birth !='' && myProfileDetail?.time_of_birth != null"> <strong> Time of
              Birth : </strong> {{myProfileDetail?.time_of_birth}} </p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.country_of_birth !='' && myProfileDetail?.country_of_birth != null"> <strong>
              Country of Birth : </strong> {{myProfileDetail?.country_of_birth}} </p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.city_of_birth !='' && myProfileDetail?.city_of_birth != null"> <strong> City/State
              of Birth : </strong> {{myProfileDetail?.city_of_birth}}</p>
        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2 position-relative">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Education & Professional Details </h5>

          <a href="javascript:void(0)" (click)="EditEducationProfessionalDetails()">
            <div class="w-25-h-25 btn btn-danger position-absolute top-0 right-0 rounded-circle center m-2">
              <i class="fas fa-pencil-alt"></i>
            </div>
          </a>

          <p class="mb-0 text-muted mb-2"> <strong> Education : </strong> {{myProfileDetail?.qualification | addSpaceAfterComma }} </p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.college_name !='' && myProfileDetail?.college_name != null"> <strong>
              College/University Name : </strong> {{myProfileDetail?.college_name}} </p>

          <p class="mb-0 text-muted mb-2"> <strong> Nature of Work : </strong> {{myProfileDetail?.nature_of_work | addSpaceAfterComma }}</p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.working_as !='' && myProfileDetail?.working_as != null"> <strong> Profession Area :
            </strong> {{myProfileDetail?.working_as | addSpaceAfterComma }}</p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.company_name !='' && myProfileDetail?.company_name != null"> <strong> Company Name :
            </strong> {{myProfileDetail?.company_name}}</p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.annual_income !='' && myProfileDetail?.annual_income != null"> <strong> Annual
              Income : </strong>{{myProfileDetail?.income_currency}} {{myProfileDetail?.annual_income |
            number}}</p>

        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2 position-relative">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            My Photo Gallery </h5>

          <a href="javascript:void(0)" (click)="EditMyPhotos()">
            <div class="w-25-h-25 btn btn-danger position-absolute top-0 right-0 rounded-circle center m-2">
              <i class="fas fa-pencil-alt"></i>
            </div>
          </a>
          <div class="row row-cols-3 row-cols-sm-4 g-2">
            <div class="col-md-3" *ngFor="let galleryPhoto of myProfileGallery, let i= index  ">
              <div class="border-3 border rounded-3 border-warning">
                <div class=" ratio-1_rm rounded-1 overflow-hidden cursor-pointer "
                  *ngIf="galleryPhoto?.photo_status==1">
                  <img *ngIf="galleryPhoto.photo_thumb_url != ''" (click)="openlight(galleryPhoto,i)"
                    ngbTooltip="{{galleryPhoto?.caption}}" placement="auto"
                    class="image-cover image-cover-2 rounded-3  " alt data-bs-toggle="modal"
                    data-bs-target="#exampleModal" src="{{ galleryPhoto.photo_thumb_url }}" />
                  <img *ngIf="galleryPhoto.photo_thumb_url == ''" (click)="openlight(galleryPhoto,i)" placement="auto"
                    class="image-cover image-cover-2 rounded-3  " alt data-bs-toggle="modal"
                    data-bs-target="#exampleModal" src="{{ galleryPhoto.photo_url }}" />

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="col-12 col-md-6 pe-md-0 ps-md-2 px-2">

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2 position-relative">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Contact Details </h5>

          <a href="javascript:void(0)" (click)="EditContactDetails()">
            <div class="w-25-h-25 btn btn-danger position-absolute top-0 right-0 rounded-circle center m-2">
              <i class="fas fa-pencil-alt"></i>
            </div>
          </a>

          <p class="mb-0 text-muted mb-2"> <strong> Profile Created By : </strong>
            {{myProfileDetail?.profile_created_by_name}}
            {{myProfileDetail?.profile_created_by_lastname}}</p>

          <p class="mb-0 text-muted mb-2"><strong> Mobile No. : </strong>{{myProfileDetail?.profile_created_by_phone}}

          </p>

          <p class="mb-0 text-muted mb-2"><strong> Email : </strong> {{myProfileDetail?.profile_created_by_email}} </p>

          <p class="mb-0 text-muted mb-2" *ngIf="myProfileDetail?.profile_for!='Self'"> <strong> Contact Person :
            </strong>
            {{myProfileDetail?.contact_person}} </p>

          <p class="mb-0 text-muted mb-2" *ngIf="myProfileDetail?.profile_for!='Self'"> <strong> Relationship With The
              Member : </strong>
            {{myProfileDetail?.profile_for}} </p>

          <div class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.convenient_time_to_call !='' && myProfileDetail?.convenient_time_to_call != null">
            <strong> Convenient Time To Call : </strong>
            <p *ngFor="let mes of showTime; let i= index" class="mb-0 text-muted mb-2"> {{mes}}</p>
          </div>

        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2 position-relative">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Religious Views </h5>
          <a href="javascript:void(0)" (click)="EditReligiousViews()">
            <div class="w-25-h-25 btn btn-danger position-absolute top-0 right-0 rounded-circle center m-2">
              <i class="fas fa-pencil-alt"></i>
            </div>
          </a>

          <p class="mb-0 text-muted mb-2"> <strong> Religion : </strong> {{myProfileDetail?.religion | addSpaceAfterComma }} </p>

          <p class="mb-0 text-muted mb-2" *ngIf="myProfileDetail?.community !='' && myProfileDetail?.community != null">
            <strong> Community :
            </strong> {{myProfileDetail?.community}}
          </p>

          <p class="mb-0 text-muted mb-2" *ngIf="myProfileDetail?.caste !='' && myProfileDetail?.caste != null">
            <strong> Caste : </strong>
            {{myProfileDetail?.caste}}
          </p>

          <p class="mb-0 text-muted mb-2" *ngIf="myProfileDetail?.gothra !='' && myProfileDetail?.gothra != null">
            <strong> Gothra/Gothram : </strong>
            {{myProfileDetail?.gothra}}
          </p>

        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2 position-relative">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Family Details
          </h5>
          <a href="javascript:void(0)" (click)="EditFamilyDetails()">
            <div class="w-25-h-25 btn btn-danger position-absolute top-0 right-0 rounded-circle center m-2">
              <i class="fas fa-pencil-alt"></i>
            </div>
          </a>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.father_status !='' && myProfileDetail?.father_status != null"> <strong> Father's
              Status : </strong> {{myProfileDetail?.father_status}} </p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.mother_status !='' && myProfileDetail?.mother_status != null"> <strong> Mother's
              Status : </strong> {{myProfileDetail?.mother_status}} </p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.no_of_brother !='' && myProfileDetail?.no_of_brother != null"> <strong> No. of
              Brothers : </strong> {{myProfileDetail?.no_of_brother}} </p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.no_of_sister !='' && myProfileDetail?.no_of_sister != null"> <strong> No. of Sisters
              : </strong> {{myProfileDetail?.no_of_sister}} </p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.family_living_in_country !='' && myProfileDetail?.family_living_in_country != null">
            <strong> Country : </strong> {{myProfileDetail?.family_living_in_country}}
          </p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.family_state_name !='' && myProfileDetail?.family_state_name != null"> <strong>
              State/Province : </strong> {{myProfileDetail?.family_state_name}} </p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.family_current_city !='' && myProfileDetail?.family_current_city != null"> <strong>
              City : </strong> {{myProfileDetail?.family_current_city}} </p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.family_zip_code !='' && myProfileDetail?.family_zip_code != null"> <strong>
              Zip/Pin/Postal Code : </strong> {{myProfileDetail?.family_zip_code}}</p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.living_with_family !='' && myProfileDetail?.living_with_family != null"> <strong>
              Living with family :
            </strong> {{myProfileDetail?.living_with_family}}</p>
          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.family_type !='' && myProfileDetail?.family_type != null"> <strong> Family Type :
            </strong> {{myProfileDetail?.family_type}}</p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.family_values !='' && myProfileDetail?.family_values != null"> <strong> Family
              Values : </strong> {{myProfileDetail?.family_values}}</p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.native_place !='' && myProfileDetail?.native_place != null"> <strong> Native Place :
            </strong> {{myProfileDetail?.native_place}}</p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.family_affluence !='' && myProfileDetail?.family_affluence != null"> <strong> Family
              Affluence : </strong> {{myProfileDetail?.family_affluence}}</p>

        </div>
      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2 position-relative">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Hobbies, Interests & more </h5>
          <a href="javascript:void(0)" (click)="EditHobbiesInterests()">
            <div class="w-25-h-25 btn btn-danger position-absolute top-0 right-0 rounded-circle center m-2">
              <i class="fas fa-pencil-alt"></i>
            </div>
          </a>

          <p class="mb-0 text-muted mb-2" *ngIf="myProfileDetail?.hobbies !='' && myProfileDetail?.hobbies != null">
            <strong> Hobbies : </strong>
            {{myProfileDetail?.hobbies | replaceSpaceWithComa}}
          </p>

          <p class="mb-0 text-muted mb-2" *ngIf="myProfileDetail?.interests !='' && myProfileDetail?.interests != null">
            <strong> Interests :
            </strong> {{myProfileDetail?.interests}}
          </p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.preferred_movie !='' && myProfileDetail?.preferred_movie != null"> <strong>
              Preferred Movies : </strong> {{myProfileDetail?.preferred_movie}}</p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.favorite_music !='' && myProfileDetail?.favorite_music != null"> <strong> Favourite
              Music : </strong> {{myProfileDetail?.favorite_music}}</p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.sports_fitness !='' && myProfileDetail?.sports_fitness != null"> <strong>
              Sports/Fitness Activities : </strong> {{myProfileDetail?.sports_fitness}}</p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.favorite_cuisine !='' && myProfileDetail?.favorite_cuisine != null"> <strong>
              Favourite Cuisine : </strong> {{myProfileDetail?.favorite_cuisine}}</p>

          <p class="mb-0 text-muted mb-2"
            *ngIf="myProfileDetail?.preferred_dress_style !='' && myProfileDetail?.preferred_dress_style != null">
            <strong> Preferred Dress Style : </strong> {{myProfileDetail?.preferred_dress_style}}
          </p>
        </div>

      </div>

      <div class="row mt-3 mx-0">
        <div class="col-12 card p-2 position-relative">
          <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
            Your Location </h5>
          <a href="javascript:void(0)" (click)="EditYourLocation()">
            <div class="w-25-h-25 btn btn-danger position-absolute top-0 right-0 rounded-circle center m-2">
              <i class="fas fa-pencil-alt"></i>
            </div>
          </a>

          <p class="mb-0 text-muted mb-2"> <strong> Country : </strong> {{myProfileDetail?.living_in_country}} </p>

          <p class="mb-0 text-muted mb-2"> <strong> State/Province : </strong> {{myProfileDetail?.state_name}}</p>

          <p class="mb-0 text-muted mb-2"> <strong> Current City : </strong> {{myProfileDetail?.current_city}}</p>

          <p class="mb-0 text-muted mb-2"> <strong> Zip/Pin/Postal Code : </strong> {{myProfileDetail?.postal_code}}
          </p>

          <p class="mb-0 text-muted mb-2"> <strong> Residency Status : </strong>{{myProfileDetail?.residency_status | addSpaceAfterComma  }}
          </p>

        </div>

      </div>

    </div>

  </div>

</section>
<!-- ......content loader code start....... -->
<section class="container px-0 mt-93" *ngIf="detailLoader">
  <div class="row mx-0">
    <div class="col-12 position-relative px-0 profile-banner overflow-hidden"
      style="max-height: 300px; border-radius: 0 0 10px 10px">
      <img src="assets/images/default_coverphoto_for conetent loader.png" alt class="img-fluid w-100" />
    </div>
  </div>
  <div class="row position-relative mx-0">
    <div class="col-12 col-sm-4 col-lg-3">
      <div class="w-75 ratio-1 profile-box rounded-circle position-relative mx-auto bg-gray p-1">
        <img class="rounded-circle border image-cover overflow-hidden animated-background-name"
          src="assets/images/default_profile_picture.png" alt />
        <div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-8 col-lg-9 py-3 mb-3">
      <div class="row py-3">
        <div class="col-12 col-md-6 py-1 lh-lg">
          <p class="mb-0 fw-bold"><small class="p-sm"></small></p>
          <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span></p>
          <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span></p>
          <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span></p>
          <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span></p>
        </div>
        <div class="col-12 col-md-6 py-1 lh-lg">
          <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span></p>
          <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span></p>
          <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span>
          <p class="mb-0 py-1 p-sm"><span class="footerBlankBox mt-4 animated-background-name"></span></p>
        </div>
      </div>
      <div class="position-absolute top-0 right-0 m-2 d-flex me-2">
        <span class="row">
          <a placement="left" class="w-30-h-30 center btn-danger rounded-circle border-0 me-1">
            <span class="shared-dom">
              <div class="sub-rect pure-background"></div>
            </span>
          </a>
          <a placement="center" class="w-30-h-30 center btn-danger rounded-circle border-0 me-1 ">
            <i class="fas fa-heart"></i>
            <span class="shared-dom">
              <div class="sub-rect pure-background"></div>
            </span>
          </a>
          <a placement="right" class="ms-1 w-30-h-30 center rounded-circle btn-danger border-0">
            <i class="fas fa-ellipsis-v"></i>
            <span class="shared-dom">
              <div class="sub-rect pure-background"></div>
            </span>
          </a>
        </span>
      </div>
    </div>
  </div>
  <section class="container">
    <div class="row mb-3">
      <div class="col-12 col-md-6 ps-md-0 pe-md-2 px-2">
        <div class="row mt-3 mx-0">
          <div class="col-12 card p-2 position-relative animated-background">
            <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
              About
            </h5>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> First Name : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Last Name : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Display Name : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Age : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Height : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Weight : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Preferred Language : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Marital Status : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Have Children : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Personality Description : </strong>
            </p>
          </div>
        </div>
        <div class="row mt-3 mx-0">
          <div class="col-12 card p-2 animated-background">
            <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
              Basics & Lifestyle
            </h5>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Food Preference : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Grew Up In : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Personal Values : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Do you prefer a partner who smokes? : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Do you prefer a partner who drinks alcohol? : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Do you prefer a partner who has pets? : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Sun Sign : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Blood Group : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Health Information : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Disability : </strong>
            </p>
          </div>
        </div>
        <div class="row mt-3 mx-0">
          <div class="col-12 card p-2 position-relative animated-background" id="astrocard">
            <h5 class="mb-0 fw-bold">Astro Details</h5>
            <div
              class="px-0 position-relative my-3 border border-2 border-danger border-end-0 border-start-0 border-bottom-0">
              <div class="card-footer animated-background">
                <p class="mb-0 text-center animated-background-name">
                  <span class="fw-bold"> Request Access </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3 mx-0">
          <div class="col-12 card p-2 position-relative animated-background">
            <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
              Education & Professional Details
            </h5>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Education : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> College/University Name : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Nature of Work : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Profession Area : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Company Name : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Annual Income : </strong>
            </p>
          </div>
        </div>
        <div class="row mt-3 mx-0">
          <div class="col-12 card p-2 animated-background">
            <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
              My Photo Gallery
            </h5>
            <div class="row row-cols-3 row-cols-sm-4 g-2 justify-content-center">
              <div class="col">
                <div class="ratio-1 rounded-1 overflow-hidden animated-background-name">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 pe-md-0 ps-md-2 px-2">
        <div class="row mt-3 mx-0">
          <div class="col-12 card p-2 animated-background" id="contactDeatilcard">
            <h5 class="mb-0 fw-bold">Contact Details</h5>
            <div
              class="px-0 position-relative my-3 border border-2 border-danger border-end-0 border-start-0 border-bottom-0">
              <div class="card-footer animated-background">
                <p class="mb-0 text-center animated-background-name">
                  <span class="fw-bold "> Request Access </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3 mx-0">
          <div class="col-12 card p-2 animated-background">
            <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
              Religious Views
            </h5>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Religion : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Community : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Caste : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Gothra / Gothram : </strong>
            </p>
          </div>
        </div>
        <div class="row mt-3 mx-0">
          <div class="col-12 card p-2 animated-background">
            <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
              Family Details
            </h5>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Father's Status : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Mother's Status : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> No. of Brothers : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> No. of Sisters : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Country : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> State/Province : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> City : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Zip/Pin/Postal Code : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Living with family : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Family Type : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Family Values : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Native Place : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Family Affluence : </strong>
            </p>
          </div>
        </div>
        <div class="row mt-3 mx-0">
          <div class="col-12 card p-2 animated-background">
            <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
              Hobbies, Interests & more
            </h5>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Hobbies : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Interests : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Preferred Movies : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Favourite Music : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Sports/Fitness Activities : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Favourite Cuisine : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Preferred Dress Style : </strong>
            </p>
          </div>
        </div>
        <div class="row mt-3 mx-0 an">
          <div class="col-12 card p-2 position-relative animated-background">
            <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0">
              Your Location
            </h5>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Country : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> State/Province : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Current City : </strong>
            </p>

            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Zip/Pin/Postal Code : </strong>
            </p>
            <p class="mb-0 text-muted mb-2 animated-background-name">
              <strong> Residency Status : </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
<!-- ......content loader code end....... -->

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog mymodel  modal-dialog-centered">
    <div class="modal-content modal-content-trans">

      <div class="modal-body text-center">
        <div class="ratio-2_rm mb-3">
          <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-25-h-25 "
            data-bs-dismiss="modal" aria-label="Close"> <i class="fas fa-times fs-5 "></i> </button>
          <img src="{{galleryPath}}"
            class="image-cover-light gallery-image-cover rounded-5 border border-1 border-success" alt>
          <a *ngIf="this.myProfileGallery.length>1" class="pre-button btn btn-danger py-1 px-2 mt-2"
            (click)="previousSlides()">&#10094;</a>
          <a *ngIf="this.myProfileGallery.length>1" class="next-button btn btn-danger py-1 px-2 ms-2  mt-2"
            (click)=" nextSlides() ">&#10095;</a>
        </div>
        <div class="mt-3 lightBox-overlay">
          <h4 *ngIf="showCaption?.length <= 100" class="text-center"> {{showCaption }}</h4>
          <div class="marquee-container text-center" *ngIf="showCaption?.length > 100">
            <h4>{{ showCaption }}</h4>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>

<app-login-footer></app-login-footer>