<div class="row shadow-sm border border-1 bg-light h-100 mx-0 overflow-hidden position-relative">
    <div class="col-12 col-lg-4 px-0">
      <div class="ratio-1 card-image">
        <img class="image-cover" *ngIf="!profile?.profile_photo || profile.profile_photo_approved!=1 "
          src="assets/images/default-profile-picture.svg" alt="default img">
        <div
          *ngIf="profile?.profile_photo != null && !!profile?.profile_photo && profile.profile_photo_approved==1 ">
          <ng-container
            *ngIf="profile?.photo_visiblity == 'No one' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage != 5)">
            <img class="image-cover" src="assets/images/default-profile-picture.svg"
              alt="default img">
          </ng-container>
          <ng-container
            *ngIf="profile?.photo_visiblity == 'Visible to all members' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage == 5)">
            <app-progressive-image class="progressive-image">
              <img appProgressiveImage [src]="profile?.profile_photo" alt=""
                class="img-fluid all_members margin-top-10" width="100%">
              <div appProgressiveImagePlaceholder class="placeholderOneOrThree-matches">
                <div id="loaderProgressive" class="centerProgressive1"></div>
              </div>
              <div appProgressiveImageFallback class="fallback">
                <img appProgressiveImageFallback class="img-fluid"
                  src="assets/images/default-profile-picture.svg" alt="">
              </div>
            </app-progressive-image>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-8 py-2">
      <div class="row h-1001">
        <div class="col-12 h-100 d-flex flex-column">
          <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0 card-nav">
            <div class="col-6 px-0">
              <h6 class="text-danger fw-bold mb-1" *ngIf="!!profile.display_name">
                {{ profile.display_name }}
              </h6>
              <h6 class="text-danger fw-bold mb-1" *ngIf="!profile.display_name">
                {{ profile.unique_key }}
              </h6>
              <div class="d-flex align-items-center">

                <span class="user_email_verified"
                  [ngbTooltip]="profile?.is_user_verified ==1 ? 'Verified user': 'Verified email'"
                  placement="bottom">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                    class="bi bi-shield-fill-check mb-1 " [ngClass]="{'text-muted':profile?.is_user_verified != 1,
                                       'text-success':profile?.is_user_verified == 1}" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0
                            0-1.044
                            1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517
                            2.453c.386.273.744.482
                            1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159
                            7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195
                            3.061-5.513
                            2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0
                            0-2.887-.87C9.843.266
                            8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708
                            0l-1.5-1.5a.5.5 0
                            1 1 .708-.708L7.5 7.793l2.646-2.647z" />
                  </svg>
                </span>

                <span class="user_email_verified1 mx-1" *ngIf="profile?.is_user_verified== 0 "
                  placement="bottom" ngbTooltip="Verified email"><i class="fa fa-check fs-5"
                    style="color:green"></i>
                </span>
                <span class="user_email_verified1 mx-1" *ngIf="profile?.is_user_verified== 1 "
                  placement="bottom" ngbTooltip="Verified user"><i class="fa fa-check-double fs-5 "
                    style="color:green"></i>
                </span>
                <div class="p-xsm mb-0 text-success" *ngIf="profile.is_logged_in">
                  <div class="flash d-inline-block me-1">
                    <div class>
                      <i class="fas fa-globe"></i>
                    </div>
                  </div>
                  <div class="d-inline-block">
                    Online
                  </div>
                </div>
                <div class="p-xsm mb-0 text-default" *ngIf="!profile.is_logged_in">
                  <div class="d-inline-block me-1">
                    <div class>
                      <i class="fas fa-globe"></i>
                    </div>
                  </div>
                  <div class="d-inline-block">
                    Offline
                  </div>
                </div>

                <div class="ms-2">
                  <img src="assets/images/icon/{{
                    profile.package_icon
                    }}" class="width-30" alt placement="top" ngbTooltip="Elite Member"
                    *ngIf="profile.package_id == 3" />
                  <img src="assets/images/icon/{{
                    profile.package_icon
                    }}" class="width-30" alt placement="top" ngbTooltip="Premium Member"
                    *ngIf="profile.package_id == 2" />
                </div>
              </div>
            </div>
            <div class="col-6 px-0 d-flex justify-content-end">
              <div class="me-2 stage_wrp ">
               
              </div>
              <div class="dropdown center btn w-30-h-30 rounded-circle btn-danger border-0 pt-1">
                <div class="ms-auto center btn w-20-h-20 btn-danger text-center border border-0
                  rounded-circle border-danger p-1" type="button" id="{{profile.user_id}}"
                  (click)="getId($event)" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="fas fa-caret-down h4 mt-2" id="{{profile.user_id}}" (click)="getId($event)"></i>
                </div>
                <ul class="dropdown-menu py-1 border border-1 border-danger"
                  aria-labelledby="dropdownMenuButton1" style="z-index:1;">
                  <li class="dropdown-item p-sm px-2"
                    (click)="connectNow(profile.user_id,profile.display_name,profile.profile_photo, profile?.profile_photo_approved)">
                    <i class="fas fa-handshake"></i> Connect
                    Now
                  </li>
                  <button style="display:none;" id="openModal2" data-bs-toggle="modal"
                    data-bs-target="#ConnectModal"></button>

                  <li class="dropdown-item p-sm px-2" id="back_to_shortlist"
                    (click)="actionSelected($event,profile.user_id)"><i class="fas fa-arrow-left"></i> Back to
                    Shortlist
                  </li>
                 
                  <li class="dropdown-item p-sm px-2" id="block_profile"
                    (click)="actionSelected($event,profile.user_id)"><i class="fas fa-user-lock"></i> Block
                  </li>
                 
                  <li class="dropdown-item p-sm px-2" id="block_and_report_profile" data-bs-toggle="modal"
                    data-bs-target="#blockReportModal" (click)="userBlockReport(profile)"><i
                      class="fas fa-user-clock"></i> Block &
                    Report
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 py-1 lh-lg">
              
              <p class="text-muted mb-0 py-1 p-sm"> {{ profile.age}} yrs, {{
                profile?.height|heightConverter:"Ft"}} </p>
              <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.marital_status}} </p>
              <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.religion | limit : 18}}<span
                  *ngIf="profile.caste">, {{
                  profile?.caste | limit
                  : 18}}</span>
              </p>
            </div>
            <div class="col-12 col-md-6 py-1 lh-lg">
              <p class="text-muted mb-0 py-1 p-sm" *ngIf="!!profile?.working_as"> {{ profile?.working_as | limit
                : 20}}</p>
              <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.current_city | limit : 20}}
              </p>
              <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.state_name | limit : 20}}
              </p>
              <p class="text-muted mb-0 py-1 p-sm"> {{ profile?.living_in_country | limit : 20}}
              </p>

            </div>
            <div class="col-12 py-1 lh-lg">
              <p class="text-muted p-sm mb-0">{{profile.description | limit : 75}} <a href="javascript:void(0)"
                  (click)="ViewProfile(profile.user_id,profile.display_name)" class="text-danger">View
                  Profile</a>
              </p>
              <button style="display:none;" id="openModal1" data-bs-target="#upgradeModal"
                data-bs-toggle="modal"></button>
             
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-12 mt-auto">
      
    </div>
  </div>